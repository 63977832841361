import React from 'react'
import style from './style.module.scss'

const SupportChat = () => {
  return (
    <div className={style.chat}>
      <button
        onClick={event =>
          window.open(
            'https://api.whatsapp.com/send?phone=918121335436&text=Hello%20Swipe! I need help with',
          )
        }
        type="button"
        className={`${style.toggleButton} d-none d-sm-inline t-20`}
      >
        <i className="fa-brands font-weight-bold fa-whatsapp" />
        {/*<span>Help?</span>*/}
      </button>
      {/*<div
        className={classNames(style.container, {
          [style.containerToggled]: isSupportChatOpen,
        })}
      >
        <div className="d-flex flex-wrap mb-2">
          <div className="text-dark font-size-18 font-weight-bold mr-auto">Support Chat</div>
          <button onClick={toggleSupportChat} type="button" className="btn btn-link p-0 border-0">
            <i className="fe fe-x-square font-size-21 align-middle text-gray-6" />
          </button>
        </div>
      </div>*/}
    </div>
  )
}

export default SupportChat
