import React, { Fragment } from 'react'
import { Text, View, Image } from '@react-pdf/renderer'
import { getStateName } from '../export_condition'
import { styles as getStyles } from './styles'
import { EnhancedQRCode } from 'components/other/qrCodeFile'

const InvoiceTitle = ({ invoice, type, color, title }) => {
  var styles = getStyles(invoice)
  var data = invoice.gst_details.id == undefined ? invoice.company : invoice.gst_details
  var tax_id_label = invoice.company.tax_id_label
  return (
    <Fragment>
      {/*<View>
        {invoice.company.is_composite == 1 &&
          (invoice.document_type == 'sales' || invoice.document_type == 'pos') && (
            <Text style={[styles.invoiceTitle_dec]}>
              Composition taxable person, not eligible to collect tax on supplies
            </Text>
          )}
      </View>*/}

      {/*<View style={styles.invoiceTitle_row}>
        <Text style={{ ...styles.invoiceTitle_labels }}>{title}</Text>
        <Text style={[styles.invoiceTitle_info]}>{type}</Text>
      </View>*/}
      {/*{invoice.company.is_composite == 1 &&
        (invoice.document_type == 'sales' || invoice.document_type == 'pos') && (
          <Text style={[styles.invoiceTitle_dec]}>
            Composition taxable person, not eligible to collect tax on supplies
          </Text>
        )}*/}

      <View
        style={styles.invoiceTitle_headerContainer}
        fixed={invoice.invoice_settings.repeat_header == 1}
      >
        {invoice.invoice_settings.show_company_details == 1 && (
          <View style={styles.invoiceTitle_blockC}>
            {invoice.company.logo != null && invoice.company.logo != '' && (
              <View>
                <Image
                  style={styles.invoiceTitle_logo}
                  src={{
                    uri: invoice.company.logo,
                    method: 'GET',
                    headers: { 'Cache-Control': 'no-cache' },
                  }}
                  crossorigin="anonymous"
                />
              </View>
            )}

            <View style={styles.invoiceTitle_blockC2}>
              <Text
                style={[
                  data.company_name.length > 50
                    ? { fontSize: (11.7 * 40) / data.company_name.length }
                    : styles.invoiceTitle_large,
                  styles.invoiceTitle_bold,
                ]}
              >
                {data.company_name}
              </Text>

              {/*//Address*/}
              <Text style={styles.company_address}>
                {data.address_1} {data.address_2 != '' && data.address_2}
              </Text>
              <Text style={styles.company_address}>
                {(data.city == '' ? '' : data.city) +
                  (data.state == '' ||
                  data.state == undefined ||
                  data.state == null ||
                  getStateName(data.state) == 'OTHERTERRITORY' ||
                  getStateName(data.state) == '97-OTHERTERRITORY' ||
                  getStateName(data.state) == 'OTHER TERRITORY'
                    ? ''
                    : ',')}{' '}
                {(data.state == '' ||
                getStateName(data.state) == 'OTHERTERRITORY' ||
                getStateName(data.state) == '97-OTHERTERRITORY' ||
                getStateName(data.state) == 'OTHER TERRITORY'
                  ? ''
                  : getStateName(data.state)) +
                  (invoice.is_export == 1 ? ` ${invoice.company.billing_address.country}` : '') +
                  (data.pincode == '' || data.pincode == undefined || data.pincode == null
                    ? ''
                    : '-')}{' '}
                {data.pincode}{' '}
              </Text>

              <Text>
                {data.gstin.length == 15 && (
                  <>
                    <Text style={styles.invoiceTitle_label}>{tax_id_label}: </Text>
                    <Text style={[styles.invoiceTitle_invoiceDate, styles.invoiceTitle_bold]}>
                      {data.gstin} {'   '}
                    </Text>
                  </>
                )}
                {data.pan_number != '' && (
                  <>
                    <Text style={styles.invoiceTitle_label}>PAN: </Text>
                    <Text style={[styles.invoiceTitle_invoiceDate, styles.invoiceTitle_bold]}>
                      {data.pan_number}
                    </Text>
                  </>
                )}
              </Text>
              {'custom_fields' in data && data.custom_fields.length > 0 && (
                <>
                  {data.custom_fields.map((field, index) => {
                    if (field.value != '' && field.value != null && field.value != undefined) {
                      return (
                        <Text key={index}>
                          <Text style={styles.invoiceTitle_label}>{field.field_name}: </Text>
                          <Text style={[styles.invoiceTitle_invoiceDate, styles.invoiceTitle_bold]}>
                            {field.value}
                          </Text>
                        </Text>
                      )
                    }
                  })}
                </>
              )}

              <Text>
                {data.mobile != '' && data.mobile != 'None' && (
                  <>
                    <Text style={styles.invoiceTitle_label}>Mobile: </Text>
                    <Text style={styles.invoiceTitle_invoiceDate}>
                      {`+${data.dial_code} ${data.mobile}`}

                      {data.alt_contact == '' ? '   ' : ', '}
                      {data.alt_contact}
                      {data.alt_contact != '' && '   '}
                    </Text>
                  </>
                )}
                {data.email != '' && (
                  <>
                    <Text style={styles.invoiceTitle_label}>Email: </Text>
                    <Text style={styles.invoiceTitle_invoiceDate}>{data.email}</Text>
                  </>
                )}
              </Text>

              {data.website != '' && (
                <>
                  <Text>
                    <Text style={styles.invoiceTitle_label}>Website </Text>
                    <Text style={styles.invoiceTitle_invoiceDate}>{data.website}</Text>
                  </Text>
                </>
              )}

              {Object.keys(invoice).includes('einvoice') && invoice.einvoice.length > 0 && (
                <View style={styles.global_mt}>
                  <Text style={styles.company_irn}>IRN: {invoice.einvoice[0].irn}</Text>
                  <Text style={styles.company_irn}>
                    Acknowledgement Number: {invoice.einvoice[0].ack_no}
                  </Text>
                </View>
              )}
            </View>
          </View>
        )}

        {invoice.invoice_settings.show_company_details == 0 &&
          Object.keys(invoice).includes('einvoice') &&
          invoice.einvoice.length > 0 && (
            <View style={styles.invoiceTitle_blockC}>
              <View style={styles.invoiceTitle_blockC2}>
                <Text style={styles.company_irn}>IRN: {invoice.einvoice[0].irn}</Text>
                <Text style={styles.company_irn}>
                  Acknowledgement Number: {invoice.einvoice[0].ack_no}
                </Text>

                <View style={styles.invoiceTitle_qr_header_off}>
                  <EnhancedQRCode value={`${invoice.einvoice[0].qrcode}`} level="L" />
                </View>
              </View>
            </View>
          )}

        <View style={styles.invoiceTitle_blockI}>
          <View style={styles.invoiceTitle_blockC2}>
            <Text
              style={{
                ...styles.invoiceTitle_reportTitle,
                color: color,
                textAlign: invoice.invoice_settings.show_company_details == 0 ? 'left' : 'right',
              }}
            >
              {title}
            </Text>
            <Text
              style={{
                ...styles.invoiceTitle_reportType,
                textAlign: invoice.invoice_settings.show_company_details == 0 ? 'left' : 'right',
              }}
            >
              {type}
            </Text>
            {invoice.invoice_settings.show_company_details == 1 &&
              Object.keys(invoice).includes('einvoice') &&
              invoice.einvoice.length > 0 && (
                <View>
                  {/*<Text style={styles.company_irn}>IRN: {invoice.einvoice[0].irn}</Text>
                <Text style={styles.company_irn}>
                  Acknowledgement Number: {invoice.einvoice[0].ack_no}
                </Text>*/}

                  <View style={styles.invoiceTitle_qrcode}>
                    <EnhancedQRCode value={`${invoice.einvoice[0].qrcode}`} level="L" />
                  </View>
                </View>
              )}
          </View>

          {/*<View>

            <Text>
              <Text
                style={{
                  ...styles.invoiceTitle_labels,
                  textAlign: invoice.invoice_settings.show_company_details == 0 ? 'left' : 'right',
                }}
              >
                {invoice.document_title} #:{' '}
              </Text>
              <Text style={[styles.invoiceTitle_info, styles.invoiceTitle_bold]}>
                {invoice.serial_number}
              </Text>
            </Text>*/}

          {/*<Text>
              <Text
                style={{
                  ...styles.invoiceTitle_labels,
                  textAlign: invoice.invoice_settings.show_company_details == 0 ? 'left' : 'right',
                }}
              >
                {invoice.document_title} Date:{' '}
              </Text>
              <Text style={[styles.invoiceTitle_info, styles.invoiceTitle_bold]}>
                {invoice.document_date}
              </Text>
            </Text>*/}
          {/*{invoice.invoice_settings.show_due_date == 1 && (
              <Text>
                <Text
                  style={{
                    ...styles.invoiceTitle_labels,
                    textAlign:
                      invoice.invoice_settings.show_company_details == 0 ? 'left' : 'right',
                  }}
                >
                  Due Date:{' '}
                </Text>
                <Text style={styles.invoiceTitle_info}>{invoice.document_due_date}</Text>
              </Text>
            )}*/}

          {/*{invoice.document_type == 'purchases' && invoice.supplier_invoice_serial_number != '' && (
              <Text>
                <Text>
                  <Text
                    style={{
                      ...styles.invoiceTitle_labels,
                      textAlign:
                        invoice.invoice_settings.show_company_details == 0 ? 'left' : 'right',
                    }}
                  >
                    Supplier Invoice #:
                  </Text>
                  <Text style={[styles.invoiceTitle_info, styles.invoiceTitle_bold]}>
                    {invoice.supplier_invoice_serial_number}
                  </Text>
                </Text>

                <Text>
                  <Text
                    style={{
                      ...styles.invoiceTitle_labels,
                      textAlign:
                        invoice.invoice_settings.show_company_details == 0 ? 'left' : 'right',
                    }}
                  >
                    Supplier Invoice Date:
                  </Text>
                  <Text style={[styles.invoiceTitle_info, styles.invoiceTitle_bold]}>
                    {invoice.supplier_invoice_date_string}
                  </Text>
                </Text>
              </Text>
            )}
          </View>*/}
        </View>
      </View>
    </Fragment>
  )
}

export default InvoiceTitle
