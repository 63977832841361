import firebase, { performance } from 'pages/firebasejs'

export const startTrace = (type: string, name: string) => {
  if (process.env.REACT_APP_STAGE === 'production') {
    const trace = performance.trace(type + ':' + name)
    trace.start()
    return trace
  }
  return undefined
}
export const stopTrace = (trace: firebase.performance.Trace | undefined) => {
  if (process.env.REACT_APP_STAGE === 'production' && trace) {
    try {
      trace.stop()
    } catch (e) {}
  }
}
