import OptionalBadge from 'components/badges/optional'
import TutorialVideoModal from 'components/modal/tutorial'
import React from 'react'
import PlayIcon from './PlayIcon'

SectionHeader.defaultProps = {
  icon: 'fa-circle-plus',
}

export default function SectionHeader({
  title,
  optional,
  link,
  right,
  rightText,
  linkTo,
  linkText,
  video_name,
  ...props
}) {
  const tutorialRef = React.createRef()
  return (
    <div className="d-flex justify-content-between align-items-center mb-2">
      <h6 className="mb-0">
        <span className="pl-1 mr-2 text-gray-500 font-weight-bold">
          {title}
          {video_name && (
            <span onClick={() => tutorialRef.current.showModal()}>
              <PlayIcon />
            </span>
          )}
        </span>
        {optional == true && <OptionalBadge />}
      </h6>

      {right && <span className="pr-2 text-gray-400">{rightText}</span>}

      {link && (
        <a_link
          onClick={linkTo}
          className="font-weight-medium pr-2 "
          data-beacon-id={props.link_beacon_id ? props.link_beacon_id : ''}
        >
          <i className={`fa-sharp fa-regular ${props.icon} mr-2`} />
          <span className="">{linkText}</span>
        </a_link>
      )}
      {video_name && <TutorialVideoModal ref={tutorialRef} video_name={video_name} />}
    </div>
  )
}
