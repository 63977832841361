import { Card } from 'antd'
import React, { useEffect, useState } from 'react'
const FooterComponent = () => {
  const [randomImage, setRandomImage] = useState('')
  useEffect(() => {
    let random_Image =
      'https://raw.githubusercontent.com/nishanta96/images/main/team/team/' +
      (Math.floor((Math.random() * 100) % 13) + 1) +
      '.png'
    setRandomImage(random_Image)
  }, [])
  return (
    <>
      <Card className="bg-gray-75 border-radius-none card-no-padding" bordered={false}>
        <div className="d-flex flex-column align-items-center mt-2 mb-2">
          <span className="mb-2">
            <img
              src={randomImage}
              alt="loading..."
              style={{ borderRadius: '50%', objectFit: 'cover', height: '50x', width: '50px' }}
            />
          </span>
          <span className="font-size-13 lh-normal">Have more questions?</span>
          <span className="font-size-13 lh-normal">
            <a
              href="https://api.whatsapp.com/send?phone=918121335436&text=Hello%20Swipe! I want to upgrade"
              className="text-primary"
            >
              Ask a Swipe specialist
            </a>
          </span>
        </div>
      </Card>
    </>
  )
}
export default FooterComponent
