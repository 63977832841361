import { Button, Modal } from 'antd'
import { default as EnhancedFrom, default as ProductForm } from 'components/forms/productForm'
import CopyText from 'components/other/CopyText'
import ProductAnalysis from 'components/other/productAnalysis'
import React, { Component } from 'react'
import { FormattedMessage } from 'react-intl'
import { connect } from 'react-redux'
import { getAPI } from 'services/jwt'

class EnhancedProductAnalysis extends Component {
  form = React.createRef()
  constructor(props) {
    super(props)
    this.state = {
      flag: false,
      loading: false,
      dataOnload: false,
      product_updated: false,
    }
  }

  componentDidMount() {
    this.props.onRef(this)
  }

  componentWillUnmount() {
    this.props.onRef(null)
  }

  showModal = async (data, product_id, selectedVariant = {}, partyData = {}) => {
    if (!(selectedVariant?.variant_id && selectedVariant?.variant_id !== 0)) {
      selectedVariant = {}
    } else {
      selectedVariant = {
        ...selectedVariant,
        id: selectedVariant?.variant_id,
        name: selectedVariant?.variant_name || selectedVariant?.name,
      }
    }
    const analysis = await getAPI('v2/product', 'analysis', {
      product_id: product_id,
      variant_id: selectedVariant?.variant_id,
      party: {
        page: 0,
        num_records: 5,
      },
      inventory: {
        page: 0,
        num_records: 5,
      },
      transactions: {
        page: 0,
        num_records: 5,
      },
      start_date: '',
      end_date: '',
      request_type: 'Inventory',
      transaction_type: 'All',
    })

    if (!analysis.success) {
      return
    }
    this.setState({
      product_id: product_id,
      data: data,
      selectedVariant: selectedVariant,
      modalTitle:
        data.product_name +
          (selectedVariant?.variant_name
            ? ' - ' + selectedVariant?.variant_name
            : selectedVariant?.name
            ? ' - ' + selectedVariant?.name
            : '') || '',
      loading: true,
      dataOnload: true,
      flag: true,
      selectedParty: partyData,
      product_analytics: analysis.product_details,
    })
  }

  handleCancel = () => {
    this.setState({ flag: false, loading: false, dataOnload: false }, () => {
      if (this.state.product_updated) {
        this.setState({ product_updated: false })
        this.props.onProductUpdate(this.state.data)
      }
    })
  }

  render() {
    return (
      <>
        <Modal
          title={
            <>
              <div className="d-flex justify-content-between align-items-center">
                <div>
                  <h4 className="mb-0">
                    {' '}
                    <CopyText
                      text={this.state.modalTitle}
                      successMessage={'Copied Product Name'}
                      label="Product Name"
                    />
                  </h4>
                </div>
                <div>
                  <Button
                    type="warning"
                    size="default"
                    onClick={event => {
                      event.stopPropagation()
                      this.form.onFill(this.state.data)
                    }}
                    className="mr-4"
                  >
                    <i className="fa-solid fa-edit mr-1" />
                    <span className="d-none d-sm-inline font-weight-bold">
                      <FormattedMessage id="button.edit" />
                    </span>
                  </Button>
                </div>
              </div>
            </>
          }
          open={this.state.flag}
          onCancel={this.handleCancel}
          closeIcon={<i className="fa-solid  fa-xmark"></i>}
          wrapStyle={
            this.state.dataOnload
              ? { display: 'none' }
              : { display: 'block', backdropFilter: 'blur(3px)' }
          }
          mask={this.state.dataOnload ? false : true}
          width={'80%'}
          destroyOnClose
          footer={[]}
          centered
          // loading={this.state.loading || this.state.dataOnload}
        >
          <ProductAnalysis
            onRef={ref => (this.product_analysis = ref)}
            product_id={this.state.product_id}
            productDetails={this.state.data}
            selectedVariant={this.state.selectedVariant}
            selectedParty={this.state.selectedParty}
            dataOnload={flag =>
              this.setState({
                dataOnload: false,
                // flag: flag
              })
            }
            product_analytics={this.state.product_analytics}
          />
        </Modal>
        <EnhancedFrom
          onRef={ref => (this.form = ref)}
          onAPICalled={() => console.log('')}
          dispatch={this.props.dispatch}
        />
        <ProductForm
          onRef={ref => (this.form = ref)}
          onAPICalled={productData => {
            if (productData?.variants?.length == 0) {
              this.setState(
                {
                  product_updated: true,
                },
                () => {
                  this.showModal(productData, productData.product_id)
                },
              )
            }
          }}
          dispatch={this.props.dispatch}
        />
      </>
    )
  }
}

const mapStateToProps = state => {
  return {
    permissions: state.permissions.permission,
  }
}

export default connect(mapStateToProps)(EnhancedProductAnalysis)
