import { Button, DatePicker, Input, Popover, Table } from 'antd'
import { EnhancedSubscriptionModal } from 'components/modal/subscription'
import SubscriptionPlanModal from 'components/modal/subscription/subscriptionPlan'
import * as dayjs from 'dayjs'
import utc from 'dayjs/plugin/utc'
import React, { Component } from 'react'
import { downloadAPI, getAPI } from 'services/jwt'
import store from 'store'

const { RangePicker } = DatePicker
dayjs.extend(utc)

import { LoadingOutlined } from '@ant-design/icons'
import BetaBadge from 'components/badges/beta'
import InfoComponent from 'components/badges/InfoComponent'
import { connect } from 'react-redux'

const { Search } = Input
// check if mobile device
const isMobile = window.innerWidth <= 500
class DayBook extends Component {
  dateFormat = 'DD-MM-YYYY'
  subscriptionPlanRef = React.createRef()
  constructor(props) {
    super(props)

    this.state = {
      transactions: [],
      num_records: 10,
      page: 0,
      total: 0,
      date: dayjs().format(this.dateFormat),
      data: [],
      columns: [],
      filters: {},
      sorter: {},
      loading: false,
      is_search: false,
      total_amounts: {},
    }
  }

  callData = async () => {
    this.setState({ loading: true })
    var req = {
      page: this.state.page,
      num_records: this.state.num_records,
      date: this.state.date,
      download: 0,
      party_search: this.state.party_search,
      filters: this.state.filters,
      sorter: this.state.sorter.order == 'ascend' ? 'asc' : 'desc',
      sort_type: this.state.sorter.field,
    }

    const data = await getAPI('v2/reports', 'day_book', req)
    if (data) {
      if (data.success) {
        this.setState({
          data: data.transactions,
          total: data.total_records,
          columns: data.columns,
          total_amounts: data.totals,
        })
      }
    }
    this.setState({ loading: false })
  }

  handleDownload = async download => {
    if (!store.get('paid')) {
      this.subscriptionPlanRef.current.openModal('reports')
    } else {
      var req = {
        date: this.state.date,
        download: download,
      }

      const data = await downloadAPI('v2/reports', 'day_book', req)
    }
  }

  async componentDidMount() {
    await this.callData()
  }

  tableChangeHandler = async (pagination, filters, sorter) => {
    if ('doc_type' in filters) {
      if (filters.doc_type == null) {
        filters.doc_type = []
      }
      this.setState({ filters: filters })
    }

    if ('order' in sorter && sorter.order) {
      this.setState({ sorter: sorter }, () => {
        this.callData()
      })
    } else {
      this.setState({ sorter: {} }, () => {
        this.callData()
      })
    }
  }

  partySearch = value => {
    this.setState({ party_search: value }, () => {
      this.callData()
    })
  }

  render() {
    const dotOptions = { minimumFractionDigits: 2, maximumFractionDigits: 2 }
    // const columns = [
    //   {
    //     title: 'Time',
    //     dataIndex: 'record_time',
    //     render: (text, currentData, index) => (
    //       <span>
    //         {/* <p className="mt-0 mb-0">{currentData.record_date}</p> */}
    //         <p className="mt-0 mb-0 ">{currentData.record_time}</p>
    //       </span>
    //     ),
    //   },
    //   {
    //     title: (
    //       <div>
    //         {this.state.is_search ? (
    //           <div className="d-flex justify-content-space">
    //             <Input
    //               placeholder="Search Party Name"
    //               onPressEnter={e =>
    //                 this.partySearch(e.target.value) &&
    //                 this.setState({ is_search: !this.state.is_search })
    //               }
    //               onChange={e => setTimeout(() => this.partySearch(e.target.value), 1000)}
    //               bordered={false}
    //               style={{ width: '90%' }}
    //               size="small"
    //               autoFocus
    //               defaultValue={this.state.party_search}
    //             />
    //             <span
    //               onClick={() =>
    //                 this.setState({
    //                   is_search: !this.state.is_search,
    //                 })
    //               }
    //               className="cursor-pointer align-self-center ml-2"
    //             >
    //               <i className="fa fa-times-circle font-size-18" aria-hidden="true"></i>
    //             </span>
    //           </div>
    //         ) : (
    //           <div className="d-flex justify-content-between">
    //             <span>Party Name</span>

    //             <span
    //               onClick={() =>
    //                 this.setState({
    //                   is_search: !this.state.is_search,
    //                 })
    //               }
    //               className="cursor-pointer align-self-center ml-2"
    //             >
    //               <i className="fa fa-search font-size-16" aria-hidden="true"></i>
    //             </span>
    //           </div>
    //         )}
    //       </div>
    //     ),
    //     dataIndex: 'party_name',
    //     width: '20%',

    //     render: (text, currentData, index) => (
    //       <span>
    //         <p className="mt-0 mb-0">
    //           {currentData.party_company ? currentData.party_company : currentData.party_name}
    //         </p>
    //         <p className="mt-1 mb-0 font-size-11 text-gray-400 pl-2">
    //           {currentData.party_gst_number}
    //         </p>
    //       </span>
    //     ),
    //   },
    //   {
    //     title: 'Transaction Type',
    //     dataIndex: 'doc_type',
    //     filters: [
    //       { text: 'Invoice', value: 'invoice' },
    //       { text: 'Purchase', value: 'purchase' },
    //       { text: 'Sale Return', value: 'sales_return' },
    //       { text: 'Purchase Return', value: 'purchase_return' },
    //       { text: 'Estimate', value: 'estimate' },
    //       { text: 'Delivery Challan', value: 'delivery_challan' },
    //       { text: 'Proforma Invoice', value: 'pro_forma_invoice' },
    //       { text: 'Expense', value: 'expense' },
    //       { text: 'Indirect Income', value: 'indirect_income' },
    //       { text: 'Payment', value: 'payment' },
    //     ],
    //     filterIcon: filtered => (
    //       <i className="fa-solid fa-lg fa-filter" style={{ color: filtered ? 'purple' : '' }} />
    //     ),
    //   },
    //   {
    //     title: 'Serial Number',
    //     dataIndex: 'serial_number',
    //   },
    //   {
    //     title: 'Amount',
    //     dataIndex: 'total_amount',
    //     sorter: (a, b) => a.total_amount - b.total_amount,
    //     render: (text, currentData, index) => <>{currentData.total_amount}</>,
    //   },
    // ]

    let columns = this.state.columns.map(col => {
      if (col.dataIndex === 'party_name') {
        return {
          ...col,
          title: (
            <div>
              {this.state.is_search ? (
                <div className="d-flex justify-content-space">
                  <Input
                    placeholder="Search Party Name"
                    onPressEnter={e =>
                      this.partySearch(e.target.value) &&
                      this.setState({ is_search: !this.state.is_search })
                    }
                    onChange={e => setTimeout(() => this.partySearch(e.target.value), 1000)}
                    bordered={false}
                    style={{ width: '90%' }}
                    size="small"
                    autoFocus
                    defaultValue={this.state.party_search}
                  />
                  <span
                    onClick={() => {
                      this.setState({
                        is_search: !this.state.is_search,
                      })
                      this.partySearch('')
                    }}
                    className="cursor-pointer align-self-center ml-2"
                  >
                    <i className="fa fa-times-circle font-size-18" aria-hidden="true"></i>
                  </span>
                </div>
              ) : (
                <div className="d-flex justify-content-between">
                  <span>Party Name</span>

                  <span
                    onClick={() =>
                      this.setState({
                        is_search: !this.state.is_search,
                      })
                    }
                    className="cursor-pointer align-self-center ml-2"
                  >
                    <i className="fa fa-search font-size-16" aria-hidden="true"></i>
                  </span>
                </div>
              )}
            </div>
          ),
          render: (text, currentData, index) => (
            <span>
              <p className="mt-0 mb-0">
                {currentData.party_company ? currentData.party_company : currentData.party_name}
              </p>
              <p className="mt-1 mb-0 font-size-11 text-gray-400 pl-2">
                {currentData.party_gst_number}
              </p>
            </span>
          ),
        }
      }

      if (col.dataIndex === 'doc_type') {
        return {
          ...col,
          filters: [
            { text: 'Invoice', value: 'invoice' },
            { text: 'Purchase', value: 'purchase' },
            { text: 'Sale Return', value: 'sales_return' },
            { text: 'Purchase Return', value: 'purchase_return' },
            { text: 'Estimate', value: 'estimate' },
            { text: 'Delivery Challan', value: 'delivery_challan' },
            { text: 'Proforma Invoice', value: 'pro_forma_invoice' },
            { text: 'Expense', value: 'expense' },
            { text: 'Indirect Income', value: 'indirect_income' },
            { text: 'Payment', value: 'payment' },
          ],
          filterIcon: filtered => (
            <i className="fa-solid fa-lg fa-filter" style={{ color: filtered ? 'purple' : '' }} />
          ),
        }
      }
      if (col.dataIndex === 'total_amount') {
        return {
          ...col,
          sorter: (a, b) => a.total_amount - b.total_amount,
        }
      }

      return col
    })

    return (
      <>
        <div className="mt-2 mb-2 font-size-22 font-weight-bold">
          Day Book <BetaBadge />
        </div>
        <div className="w-100 d-flex mb-4 justify-content-between">
          <div className="d-inline-flex">
            <Input.Group compact>
              <DatePicker
                style={{
                  borderRadius: '5px',
                  cursor: 'pointer',
                  width: 300,
                }}
                allowClear={false}
                format={this.dateFormat}
                value={dayjs(this.state.date, this.dateFormat)}
                onChange={(date, dateString) => {
                  this.setState(
                    {
                      date: dateString,
                    },
                    () => this.callData(),
                  )
                }}
              />
            </Input.Group>
          </div>
          {!this.state.loading &&
            (window.innerWidth >= 1600 ? (
              <div>
                <Button
                  data-beacon-id="day_book_report_excel_download"
                  // type="text"
                  className="ml-2 "
                  onClick={() => this.handleDownload(1)}
                >
                  <i className="fa-duotone fa-file-excel mr-2" />
                  Download Excel
                </Button>
                <Button
                  data-beacon-id="day_book_report_pdf_download"
                  // type="text"
                  className="ml-2 "
                  onClick={() => this.handleDownload(2)}
                >
                  <i className="fa-duotone fa-file-pdf mr-2" />
                  Download PDF
                </Button>
              </div>
            ) : (
              <>
                <div className=" ml-auto d-flex-inline flex-column">
                  <>
                    <Popover
                      content={
                        <>
                          <div className="font-weight-bold">Download</div>
                          <div className="d-flex flex-column">
                            <Button
                              // type="action"
                              className="mx-2 my-2"
                              onClick={() => this.handleDownload(1)}
                            >
                              <i className="fa-duotone fa-file-excel mr-2" />
                              Download Excel
                            </Button>
                            <Button
                              // type="action-3"
                              className="mx-2 my-2"
                              onClick={() => this.handleDownload(2)}
                            >
                              <i className="fa-duotone fa-file-pdf mr-2" />
                              Download PDF
                            </Button>
                          </div>
                        </>
                      }
                      trigger={['click']}
                      placement="bottom"
                    >
                      <Button className="  mr-2">
                        <span className="d-none d-sm-inline mr-2">Download</span>
                        <span>
                          <i class="fa-duotone fa-down-to-line d   mt-1" />
                        </span>
                      </Button>
                    </Popover>
                  </>
                </div>
              </>
            ))}
        </div>
        <hr />
        <div>
          <Table
            bordered
            data-beacon-id="day_book_report_data"
            columns={columns}
            dataSource={this.state.data.map((value, index) => {
              if (value.is_bold) {
                return {
                  ...value,
                  key: index,
                  total_amount: (
                    <span className="font-weight-bold">
                      {value.total_amount.toLocaleString('en-US', dotOptions)}
                    </span>
                  ),
                  serial_number: <span className="font-weight-bold">{value.serial_number}</span>,
                }
              } else {
                return {
                  ...value,
                  key: index,
                  total_amount: value.total_amount.toLocaleString('en-US', dotOptions),
                }
              }
            }, this)}
            rowClassName={(record, index) => (index % 2 === 0 ? 'bg-white' : 'bg-light')}
            size="small"
            className="mb-2"
            pagination={{
              disabled: store.get('paid') != 1,
              current: this.state.page + 1,
              showSizeChanger: true,
              total: this.state.total,
              defaultPageSize: 10,
              pageSizeOptions: ['10', '15', '20', '25', '50'],
              onChange: (page, num_records) => {
                this.setState(
                  {
                    page: page - 1,
                    num_records: num_records,
                  },
                  () => this.callData(),
                )
              },
            }}
            onChange={this.tableChangeHandler}
            loading={{
              spinning: this.state.loading,
              indicator: (
                <LoadingOutlined
                  style={{
                    fontSize: 35,
                  }}
                  spin
                />
              ),
            }}
            footer={() => (
              <>
                {/* {console.log(this.state.data.length, this.state.total_amounts)} */}
                <div>
                  {this.state.data.length != 0 && (
                    <>
                      <div className="row mx-2 ml-2 text-gray-500 font-size-12">
                        {/* <div className="col-12 col-lg-12">
                          <strong> Note: </strong> The below totals are calculated based on the
                          selected range and filters.
                        </div> */}
                      </div>
                      {/* <div className="row">
                        <div className="col-12 col-lg-12">
                          {this.state.total_amounts.map((obj, index) => (
                            <Button type="action-o" className="float-left mr-2 mt-2">
                              <span>
                                {obj['doc_type']}
                                {':   '}
                              </span>
                              <span className="ml-2 mr-1">{this.props?.user?.selectedCompany?.currency_symbol}</span>
                              <span className="font-weight-bold">
                                {obj['amount'].toLocaleString('en-US', dotOptions)}
                              </span>
                            </Button>
                          ))}
                        </div>
                      </div> */}
                      <div className="row mb-2 mt-2">
                        <div className="col-6 col-md-10 text-right">
                          {this.state.total_amounts.map((obj, index) => (
                            <h6>
                              <span className="text-gray-500 font-size-16">{obj['doc_type']}</span>
                            </h6>
                          ))}
                        </div>
                        <div className="col-6 col-md-2 text-right pr-4">
                          {this.state.total_amounts.map((obj, index) => (
                            <h6>
                              <span className="text-gray-500 ">
                                <span className="mr-1 ">
                                  {this.props?.user?.selectedCompany?.currency_symbol}
                                </span>
                                <span className="font-size-16">
                                  {obj['amount'].toLocaleString('en-US', dotOptions)}
                                </span>
                              </span>
                            </h6>
                          ))}
                        </div>
                      </div>
                    </>
                  )}
                </div>
              </>
            )}
          />
          <InfoComponent
            title="Note:"
            description="Day Book is a record of all transactions in a day. It is a book of original entry where all transactions are recorded in the order of their occurrence in the given date. It is a chronological record of all transactions."
          />
        </div>
        <EnhancedSubscriptionModal onRef={ref => (this.subscription = ref)} />
        <SubscriptionPlanModal ref={this.subscriptionPlanRef} />
      </>
    )
  }
}

function mapStateToProps(state) {
  return {
    user: state.user,
  }
}

export default connect(mapStateToProps)(DayBook)
