import {
  Button,
  Card,
  Col,
  Collapse,
  DatePicker,
  Descriptions,
  Drawer,
  Input,
  InputNumber,
  message,
  Modal,
  Popover,
  Row,
  Select,
  Space,
  Table,
  Tabs,
  Timeline,
  Tooltip,
  Upload,
} from 'antd'
const { Dragger } = Upload
import InfoIcon from 'components/info/info_icon'
import { CommentsAndHistory } from 'components/forms/commentsAndHistory'
import { Loader } from 'components/loaders'
import EnhancedShiprocketModal from 'components/modal/shiprocket'
import { EnhancedSubscriptionModal } from 'components/modal/subscription'
import EnhancedAddresses from 'components/other/addresses'
import CopyText from 'components/other/CopyText'
import EnhancedPartyAnalysis from 'components/other/PartyAnalysis'
import SectionHeader from 'components/other/sectionHeader'
import { UserAvatar } from 'components/other/utility'
import ProductsList from 'components/productslist'
import * as dayjs from 'dayjs'
import utc from 'dayjs/plugin/utc'
import { MyDocument } from 'pages/ewayBills/ewayBillView'
import React, { Component, Suspense } from 'react'
import { isMobile } from 'react-device-detect'
import { FormattedMessage } from 'react-intl'
import { connect } from 'react-redux'
import { getAPI, getAPIData, getMultipartAPI } from 'services/jwt'
import store from 'store'
import { CustomerForm } from './customerForm'
import EditPayInForm from './paymentEditForm'
import PaymentFrom from './paymentForm'
import BetaBadge from 'components/badges/beta'
import { PlusOutlined, LoadingOutlined, InboxOutlined, UploadOutlined } from '@ant-design/icons'
import { getBase64 } from 'components/other/utility'
import EwaybillPdf from 'components/modal/ewaybillPdf'
const { TextArea } = Input
const { Option } = Select
dayjs.extend(utc)

const { Panel } = Collapse
const { TabPane } = Tabs

export class InvoiceForm extends Component {
  dateFormat = 'DD-MM-YYYY'
  dateFormatRegex = /^\d{2}-\d{2}-\d{4}$/
  formRef = React.createRef()
  bankRef = React.createRef()
  signatureRef = React.createRef()
  exclusiveNotesRef = React.createRef()
  inputNumberFieldRef = React.createRef()
  textAreaRef = React.createRef()
  inputTextFieldRef = React.createRef()
  datePickerRef = React.createRef()
  ewaybillPdfRef = React.createRef()
  constructor(props) {
    super(props)
    this.state = {
      viewDetails: false,
      visible: false,
      formFlag: false,
      type: '',
      loading: false,
      use_balance: false,
      bal: 0,
      payment_types: [],
      signatures: [],
      name: '',
      banks: [],
      utils: {},
      paymentData: '',
      paymentFlag: false,
      document: '',
      document_name: 'Loading',
      serial_number: '',
      party_type: 'customer',
      menu_name: '',
      document_type: 'Invoice',
      external: true,
      selected_bank: '',
      selectedSignature: { signature_name: '', id: '', image: '' },
      value: '',
      exclusive_notes: '',
      edit: false,
      reminderTimelineLoading: false,
      reminderTimeline: [],
      upcoming_reminder_date: '',
      custom_headers: {},
      edit_bank: false,
      edit_signature: false,
      edit_exclusive_notes: false,
      editing_header_id: null,
      is_einvoice: 0,
      digital_signature_logs_data: [],
      digital_signature_loading: false,
      attachments: [],
    }
  }

  async componentDidMount() {
    this.props.onRef(this)
  }

  componentWillUnmount() {
    this.props.onRef(null)
  }
  get_invoice_data = async () => {
    const req = {
      new_hash_id: this.state.new_hash_id,
      document_type: this.state.document_type,
      is_pdf: false,
    }
    const data = await getAPI('v2/doc', 'get_invoice', req)

    if (data) {
      this.setState(
        {
          document: data.invoice_details,
          external: false,
          exclusive_notes: data.invoice_details.exclusive_notes,
          edit: data.edit,
          delete: data.delete,
          is_einvoice: data.invoice_details.is_einvoice,
          attachments: data.invoice_details.attachments,
        },
        async () => {
          this.setState({
            document: {
              ...data.invoice_details,
              document_custom_additional_charges:
                data.invoice_details.document_custom_additional_charges,
            },
          })
          if (!this.state.external) {
            const bank_details = await getAPIData('user', 'bank_details')
            if (bank_details) {
              let selected_bank_details = bank_details.bank_details.filter(
                item => item.id == data.invoice_details.bank_id,
              )
              if (selected_bank_details.length == 0) {
                selected_bank_details = [{ bank_name: '', bank_no: '' }]
              }
              this.setState({
                banks: bank_details.bank_details,
                selected_bank: data.invoice_details.bank_id,
                selected_bank_details: selected_bank_details[0],
              })
            }
            const company_details = await getAPIData('signature', 'get')
            if (company_details) {
              let signature = company_details.signatures.filter(
                item => item.image == data.invoice_details.signature,
              )
              if (signature.length > 0) {
                signature = signature[0]
              } else {
                signature = { signature_name: '', id: '', image: '' }
              }
              this.setState({
                signatures: company_details.signatures,
                selectedSignature: signature,
              })
            }
          }
          if (data.invoice_details.has_reminders > 0) {
            this.getreminderTimeline(data.invoice_details)
          } else {
            this.setState({ reminderTimeline: [] })
          }
        },
      )
    }
  }

  showDrawer = async (menuName, record) => {
    var document_name = 'Invoice'
    var document_type = 'invoice'
    var party_type = ''
    if (menuName == 'sales') {
      document_name = 'Invoice'
      document_type = 'invoice'
      party_type = 'customer'
    }
    if (menuName == 'purchases') {
      document_name = 'Purchase'
      document_type = 'purchase'
      party_type = 'vendor'
    }
    if (menuName == 'purchase_orders') {
      document_name = 'Purchase Order'
      document_type = 'purchase_order'
      party_type = 'vendor'
    }
    if (menuName == 'estimates') {
      document_name = 'Quotation'
      document_type = 'estimate'
      party_type = 'customer'
    }
    if (menuName == 'sales_returns') {
      document_name = 'Credit Note'
      document_type = 'sales_return'
      party_type = 'customer'
    }
    if (menuName == 'purchase_returns') {
      document_name = 'Debit Note'
      document_type = 'purchase_return'
      party_type = 'vendor'
    }
    if (menuName == 'delivery_challans') {
      document_name = 'Delivery Challan'
      document_type = 'delivery_challan'
      party_type = 'customer'
    }
    if (menuName == 'pro_forma_invoices') {
      document_name = 'Pro Forma Invoice'
      document_type = 'pro_forma_invoice'
      party_type = 'customer'
    }

    this.setState(
      {
        viewDetails: true,
        new_hash_id: record.new_hash_id,
        document_type: document_type,
        document_name: document_name,
        party_type: party_type,
        menu_name: menuName,
      },
      async () => {
        this.get_invoice_data()
      },
    )
  }
  showDocumentDrawer = async (document_type, new_hash_id) => {
    this.setState(
      {
        viewDetails: true,
        new_hash_id: new_hash_id,
        document_type: document_type,
      },
      () => {
        let menu_name = 'sales'
        let document_name = 'Invoice'
        let party_type = 'customer'
        if (document_type == 'invoice') {
          menu_name = 'sales'
          document_name = 'Invoice'
          party_type = 'customer'
        }
        if (document_type == 'purchase') {
          menu_name = 'purchases'
          document_name = 'Purchase'
          party_type = 'vendor'
        }
        if (document_type == 'purchase_order') {
          menu_name = 'purchase_orders'
          document_name = 'Purchase Order'
          party_type = 'vendor'
        }
        if (document_type == 'estimate') {
          menu_name = 'estimates'
          document_name = 'Quotation'
          party_type = 'customer'
        }
        if (document_type == 'sales_return') {
          menu_name = 'sales_returns'
          document_name = 'Credit Note'
          party_type = 'customer'
        }
        if (document_type == 'purchase_return') {
          menu_name = 'purchase_returns'
          document_name = 'Debit Note'
          party_type = 'vendor'
        }
        if (document_type == 'delivery_challan') {
          menu_name = 'delivery_challans'
          document_name = 'Delivery Challan'
          party_type = 'customer'
        }
        if (document_type == 'pro_forma_invoice') {
          menu_name = 'pro_forma_invoices'
          document_name = 'Pro Forma Invoice'
          party_type = 'customer'
        }
        this.setState(
          {
            menu_name: menu_name,
            document_name: document_name,
            party_type: party_type,
          },
          () => {
            this.get_invoice_data()
          },
        )
      },
    )
  }

  onOpen = async (menuName, record) => {
    this.setState({ viewDetails: true }, async () => {
      var document_name = 'Invoice'
      var document_type = 'invoice'
      var party_type = ''
      if (menuName == 'sales') {
        document_name = 'Invoice'
        document_type = 'invoice'
        party_type = 'customer'
      }
      if (menuName == 'purchases') {
        document_name = 'Purchase'
        document_type = 'purchase'
        party_type = 'vendor'
      }
      if (menuName == 'purchase_orders') {
        document_name = 'Purchase Order'
        document_type = 'purchase_order'
        party_type = 'vendor'
      }
      if (menuName == 'estimates') {
        document_name = 'Quotation'
        document_type = 'estimate'
        party_type = 'customer'
      }
      if (menuName == 'sales_returns') {
        document_name = 'Credit Note'
        document_type = 'sales_return'
        party_type = 'customer'
      }
      if (menuName == 'purchase_returns') {
        document_name = 'Debit Note'
        document_type = 'purchase_return'
        party_type = 'vendor'
      }
      if (menuName == 'delivery_challans') {
        document_name = 'Delivery Challan'
        document_type = 'delivery_challan'
        party_type = 'customer'
      }
      if (menuName == 'pro_forma_invoices') {
        document_name = 'Pro Forma Invoice'
        document_type = 'pro_forma_invoice'
        party_type = 'customer'
      }

      var req = {
        new_hash_id: record.new_hash_id,
        document_type: document_type,
        is_pdf: false,
      }

      const data = await getAPI('v2/doc', 'get_view_invoice', req)

      if (data) {
        this.setState(
          {
            document: data.invoice_details,
            document_name: document_name,
            party_type: party_type,
            menu_name: menuName,
            document_type: document_type,
            external: true,
          },
          () => {
            let updated = data.invoice_details.document_custom_additional_charges.map(item => {
              return {
                ...item,
                name:
                  data.invoice_details.custom_additional_charges[item.additional_charge_id - 1]
                    .name,
              }
            })
            this.setState({
              document: {
                ...data.invoice_details,
                document_custom_additional_charges: updated,
              },
            })
          },
        )
      }
    })
  }

  onClose = () => {
    this.setState(
      {
        viewDetails: false,
      },
      () => {
        this.setState({ document: '' })
      },
    )
  }

  getEWayBill = async (object, flag) => {
    const req = {
      invoice_serial_number: object.invoice_serial_number,
      serial_number: object.serial_number,
      document_type: object.type,
    }

    const data = await getAPI('ewaybills', 'get_ewaybill', req)

    if (data) {
      this.setState({ invoiceData: data.invoice_details }, () => {
        this.setState({ flag: true })
      })
    }
  }

  getEInvoice = async (object, flag) => {
    const req = {
      invoice_serial_number: object.invoice_serial_number,
    }

    const data = await getAPI('einvoice', 'get_einvoice', req)

    if (data) {
      this.setState({ einvoiceData: data.invoice_details }, () => {
        this.setState({ flag: true })
      })
    }
  }

  sendInvoiceSMS = async data => {
    var data1 = await getAPI('sms', 'share_doc', {
      serial_number: data.serial_number,
      doc_type: this.state.menu_name,
    })
    if (data1.success) {
      message.success('SMS Sent')
    }
  }

  showAddressModal = type => {
    this.setState({ type }, () => {
      this.address.showModal(
        this.state.document.customer,
        this.state.document.customer_shipping_addr_id,
        'to',
      )
    })
  }

  onAddressChange = async addr_id => {
    const req = {
      document_type: this.state.document_type,
      doc_count: this.state.document.doc_count,
      type: 'to',
      addr_id: addr_id,
    }

    const data = await getAPI('v2/doc', 'update_document_shipping_address', req)
    if (data.success) {
      message.success('Address Updated Successfully')
      this.showDrawer(this.state.menu_name, this.state.document)
      this.address.closeModal()
    } else {
      message.error(data.message)
      this.address.closeModal()
    }
  }

  onDocumentEdit = async (value, type) => {
    if ((value == '' || !value) && type.includes('date')) {
      return
    }
    if (this.props.permissions.edit_doc_in_n_days == 1) {
      let diff_days = dayjs().diff(dayjs(this.state.document.record_time)) / 86400000
      if (diff_days > this.props.permissions.edit_doc_days) {
        message.error(
          `You can edit this document only within ${this.props.permissions.edit_doc_days} days`,
        )
        return
      }
    }
    const req = {
      document_type: this.state.document_type,
      doc_count: this.state.document.doc_count,
      type: type,
      value: value,
    }
    const data = await getAPI('v2/doc', 'edit_document_field', req)
    if (data.success) {
      message.success(data.message)
      this.showDrawer(this.state.menu_name, this.state.document)
    }
  }

  getPaidClass = data => {
    switch (data.toLowerCase()) {
      case 'cash':
        return 'cash'
      case 'card':
        return 'card-payment'
      case 'upi':
        return 'upi'
      case 'emi':
        return 'emi'
      case 'net banking':
        return 'netbanking'
      case 'cheque':
        return 'cheque'
      case 'tds':
        return 'tds'
      case 'tcs':
        return 'tcs'
      default:
        return 'card'
    }
  }

  getStatusClass = data => {
    switch (data) {
      case 'pending':
        return 'warning'
      case 'paid':
        return 'success'
      case 'cancelled':
        return 'danger'
      case 'partially paid':
        return 'partial'
      default:
        return 'danger'
    }
  }

  getreminderTimeline = async data => {
    var req = {
      doc_count: data.doc_count,
      due_date:
        data.payment_status == 'paid' ? '' : dayjs(data.document_due_date).format('YYYY-MM-DD'),
    }
    const data2 = await getAPI('user', 'get_reminder_timeline', req)
    if (data) {
      if (data2.success) {
        this.setState({
          reminderTimeline: data2.reminder_timeline,
          upcoming_reminder_date: data2.upcoming_reminder_date,
        })
      }
    }
    this.setState({ reminderTimelineLoading: false })
  }

  getDigitalSignatureLogs = async data => {
    this.setState({ digital_signature_loading: true })
    const req = {
      document_type: data.document_type,
      doc_count: data.doc_count,
    }

    const data2 = await getAPI('digital/sign', 'logs', req)
    if (data2) {
      if (data2.success) {
        this.setState({ digital_signature_logs_data: data2.logs, digital_signature_loading: false })
      }
    }
  }

  handleInputChange = (headerId, value) => {
    this.setState(prevState => ({
      custom_headers: {
        ...prevState.custom_headers,
        [headerId]: value,
      },
    }))
  }
  handleSave = headerId => {
    const value = this.state.custom_headers[headerId]
    this.onDocumentEdit(value, `custom_header_${headerId}`)
  }
  allow_document_edit = () => {
    return this.state.is_einvoice == 0 && !this.state.external
  }

  DownloadSignedDoc = data => {
    fetch(data.url, {
      method: 'GET',
      mode: 'cors',
      headers: {
        'Content-Type': 'application/octet-stream',
      },
    })
      .then(response => {
        response.blob().then(blob => {
          let url = window.URL.createObjectURL(blob)
          let a = document.createElement('a')
          a.href = url
          a.download = this.state.document.serial_number + '.pdf'
          a.click()
        })
      })
      .then(() => {
        if (response?.success != false) {
          message.success('Download Successful')
        }
      })
      .catch(err => {
        console.log(err)
      })
  }
  beforeUpload = file => {
    const isLt3M = file.size / 1024 / 1024 < 3
    if (!isLt3M) {
      message.error('File must smaller than 3MB!')
    }
    return isLt3M
  }
  customRequest = async ({ onSuccess, onError, file }) => {
    if (this.state.attachments.length >= 3) {
      message.warning('Maximum of 3 files uploaded already!')
      return
    }
    if (!file.url && !file.preview) {
      file.preview = await getBase64(file)
    }

    const form = new FormData()
    form.append('file', file)
    const data = await getMultipartAPI('utils', 'attach_files', form)
    if (data.success) {
      this.setState(
        { attachments: [...this.state.attachments.slice(-2), ...data.attachments] },
        () => {
          this.onDocumentEdit(this.state.attachments, 'attachments')
        },
      )

      onSuccess(null, file)
    } else {
      message.error(data.message)
      onError()
    }
  }
  handleChange = file => {
    const newFiles = this.state.attachments.filter(file1 => file1.uid !== file.uid)
    this.setState({ attachments: newFiles }, () => {
      this.onDocumentEdit(this.state.attachments, 'attachments')
    })
  }
  render() {
    const dotOptions = { minimumFractionDigits: 2, maximumFractionDigits: 2 }
    var { document, document_name, party_type } = this.state
    var { document_custom_headers } = document
    const default_date = dayjs(
      dayjs(document.document_date).format(this.dateFormat),
      this.dateFormat,
    )
    const default_due_date = dayjs(
      dayjs(document.document_due_date).format(this.dateFormat),
      this.dateFormat,
    )

    const email_status_colors = {
      Processing: 'warning',
      dropped: 'danger',
      bounced: 'danger',
      delivered: 'success',
      deferred: 'danger',
      open: 'success',
      click: 'success',
      processed: 'warning',
      blocked: 'danger',
    }
    const email_columns = [
      {
        title: 'To',
        dataIndex: 'email',
        key: 'email',
        render: text => <span className="font-size-14">{text}</span>,
      },
      {
        title: 'Status',
        dataIndex: 'status',
        key: 'status',
        render: (_, record) => (
          <Button
            type={email_status_colors[record.status]}
            size="small"
            className="mb-0 font-size-14 font-weight-bold mr-1"
          >
            {record.status}
          </Button>
        ),
      },
      {
        title: 'Updated Time',
        dataIndex: 'updated_time',
        key: 'Updated_time',
        render: (_, record) => (
          <span className="font-size-14">
            {dayjs(record.updated_time, 'ddd, DD MMM YYYY HH:mm:ss')
              .add(5.5, 'hours')
              .format('MMM DD YYYY, h:mm:ss a')}
          </span>
        ),
      },
    ]
    const uploadButton = (
      <div>
        {this.state.loading ? <LoadingOutlined /> : <PlusOutlined />}
        <div style={{ marginTop: 8 }}>Upload</div>
      </div>
    )
    return (
      <>
        <Drawer
          title={
            document_name +
            (document.serial_number == undefined ? '' : ' #' + document.serial_number)
          }
          extra={
            <Space>
              {isMobile == false &&
                (document.document_type == 'invoice' ||
                  document.document_type == 'purchase' ||
                  document.document_type == 'purchase_return' ||
                  document.document_type == 'sales_return') &&
                !this.state.external &&
                document.payment_status != 'paid' &&
                document.payment_status != 'cancelled' && (
                  <Tooltip title="Record Payment">
                    {this.props.permissions.record_payment == 1 && (
                      <Button
                        type="warning"
                        size=""
                        className="font-weight-bold"
                        onClick={event => {
                          event.stopPropagation()
                          this.payment.onFill(
                            document,
                            party_type == 'customer',
                            this.state.document_type,
                          )
                        }}
                      >
                        Record{' '}
                        <span className="mr-1 ml-1">
                          {this.props?.user?.selectedCompany?.currency_symbol}
                        </span>
                        <i className="fa fa-arrow-down mr-1" />
                        <span className="d-none d-sm-inline font-size-12"></span>
                      </Button>
                    )}
                  </Tooltip>
                )}

              {(document.document_type == 'invoice' ||
                document.document_type == 'purchase' ||
                document.document_type == 'purchase_return' ||
                document.document_type == 'sales_return') && (
                <Button
                  type={this.getStatusClass(document.payment_status)}
                  size=""
                  className="font-weight-bold ml-2 mr-2"
                  onClick={event => {
                    event.stopPropagation()
                  }}
                >
                  {document.payment_status == 'pending' ||
                  document.payment_status == 'partially paid' ? (
                    <>{document.payment_status}</>
                  ) : (
                    <>{document.payment_status}</>
                  )}
                </Button>
              )}

              {/* {document != '' && !this.state.external && (
                <TransactionsDropdown
                  data={{
                    ...document,
                    document_type: document.document_type,
                    menu_name: document.menu_name,
                    document_title: document.document_title,
                  }}
                  permissions={this.props.permissions}
                  integrations={this.props.integrations}
                  is_edit={this.state.edit}
                  is_delete={this.state.delete}
                  page={store.get('page') ? store.get('page') : 0}
                  dropDowncontent={
                    <Button
                      type="info"
                      size="small"
                      className="dropdown-toggle dropdown-toggle-noarrow"
                    >
                      <i className="fa-solid fa-ellipsis-vertical "></i>
                    </Button>
                  }
                  callData={() => this.get_invoice_data}
                />
              )} */}
            </Space>
          }
          width={isMobile ? '80%' : '54%'}
          height={isMobile ? '90%' : '100%'}
          placement={isMobile ? 'top' : 'right'}
          closeIcon={<i className="fa-solid fa-lg fa-xmark"></i>}
          closable={true}
          onClose={this.onClose}
          open={this.state.viewDetails}
          footer={null}
        >
          <div className="warehouses">
            <Tabs
              defaultActiveKey={'document_details'}
              destroyInactiveTabPane={true}
              onChange={activeKey => {
                if (activeKey == 'document_details') {
                } else {
                }
              }}
            >
              <TabPane tab={<span>Details </span>} key="document_details">
                {document == '' && (
                  <Loader text={'Loading...'} visibleText={true} className="ml-2" />
                )}
                {document != '' && (
                  <>
                    {'shiprocket_order_status' in document &&
                      store.get('shiprocket_paid') == 1 &&
                      document.shiprocket_order_status != '' && (
                        <>
                          <SectionHeader title="Delivery Status" />
                          <Card size="drawer" bordered={true} className="border-radius-small mb-4">
                            <div className="">
                              <h6>
                                Shiprocket Order Status:{' '}
                                <span className="text-forest font-weight-bold">
                                  {document.shiprocket_order_status.awb_number != 0
                                    ? 'AWB Assigned'
                                    : 'Order Created'}
                                </span>
                              </h6>
                              {document.shiprocket_order_status.awb_number != 0 ? (
                                <div className="m-0">
                                  <span>
                                    AWB Number:{' '}
                                    <a
                                      href={
                                        'https://shiprocket.co/tracking/' +
                                        document.shiprocket_order_status.awb_number
                                      }
                                      className="text-primary"
                                      target="_blank"
                                    >
                                      {document.shiprocket_order_status.awb_number}
                                    </a>{' '}
                                  </span>
                                  {'document_urls' in document.shiprocket_order_status && (
                                    <div className="mt-2">
                                      <Button
                                        className=""
                                        onClick={
                                          document.shiprocket_order_status.document_urls.label_url
                                        }
                                      >
                                        <i className="fa-regular fa-arrow-down mr-2"></i> Download
                                        Label
                                      </Button>
                                      <Button
                                        className="ml-2"
                                        onClick={
                                          document.shiprocket_order_status.document_urls
                                            .manifest_url
                                        }
                                      >
                                        <i className="fa-regular fa-arrow-down mr-2"></i> Download
                                        Manifest
                                      </Button>
                                    </div>
                                  )}
                                </div>
                              ) : (
                                <Button
                                  type="primary"
                                  className="font-weight-bold text-white"
                                  style={{ width: '150px', color: 'white' }}
                                  onClick={e => {
                                    this.shiprocket.showModal({
                                      doc_count: document.doc_count,
                                      new_hash_id: document.new_hash_id,
                                    })
                                    this.onClose()
                                  }}
                                >
                                  Create Shipment
                                </Button>
                              )}
                            </div>
                          </Card>
                        </>
                      )}
                    <Collapse
                      defaultActiveKey={[]}
                      bordered={false}
                      expandIcon={({ isActive }) => (
                        <span className="text-right mb-0 font-weight-bolder">
                          {isActive ? '' : 'More'}
                          <i
                            className={`fa-solid fa-lg fa-chevron-down ml-1 ${!isActive &&
                              'fa-rotate-270'}`}
                          />
                        </span>
                      )}
                      expandIconPosition="right"
                      className="mb-4 site-collapse-custom-collapse bg-white pl-0 pr-0"
                    >
                      <Panel
                        header={
                          <>
                            <Row>
                              <Col span={24} className="mr-3">
                                <div className="d-flex align-items-center mb-2">
                                  {document.customer.name != '' && (
                                    <span className="mr-2">
                                      <UserAvatar
                                        size="large"
                                        name={
                                          document.customer.company_name != ''
                                            ? document.customer.company_name
                                            : document.customer.name
                                        }
                                      />
                                    </span>
                                  )}
                                  <div className="d-flex flex-column">
                                    <span className="text-gray-900 font-size-16 font-weight-medium">
                                      {document.customer.company_name != ''
                                        ? document.customer.company_name
                                        : document.customer.name}
                                    </span>

                                    <p className="mb-0">
                                      <span className="text-gray-500 mr-2 font-weight-medium">
                                        {document_name} Date
                                      </span>
                                      <span className="edit-popover">
                                        <span className="text-gray-900 font-weight-bold mt-0 mb-0 ls-2">
                                          {dayjs(document.document_date).format(this.dateFormat)}
                                        </span>
                                        {!this.state.external && this.state.edit && (
                                          <Popover
                                            title="Change Document Date"
                                            trigger={['click']}
                                            placement="right"
                                            className="popover-child"
                                            content={
                                              <div
                                                onClick={event => {
                                                  event.stopPropagation()
                                                }}
                                                className="d-flex align-items-center"
                                              >
                                                <span>
                                                  <DatePicker
                                                    style={{
                                                      borderRadius: '5px',
                                                      cursor: 'pointer',
                                                      width: '100%',
                                                    }}
                                                    allowClear={false}
                                                    format={this.dateFormat}
                                                    defaultValue={default_date}
                                                    onChange={date =>
                                                      this.setState({
                                                        value: date.format(this.dateFormat),
                                                      })
                                                    }
                                                  />
                                                </span>
                                                <span className="ml-2">
                                                  <Button
                                                    type="primary"
                                                    size="medium"
                                                    onClick={() =>
                                                      this.onDocumentEdit(
                                                        this.state.value,
                                                        'document_date',
                                                      )
                                                    }
                                                  >
                                                    Save
                                                  </Button>
                                                </span>
                                              </div>
                                            }
                                            onClick={e => e.stopPropagation()}
                                          >
                                            <span>
                                              <i className="fa-solid fa-pen-to-square text-gray-500 ml-2" />
                                            </span>
                                          </Popover>
                                        )}
                                      </span>

                                      <span className="text-gray-500 mr-2 ml-3 font-weight-medium">
                                        Due Date
                                      </span>
                                      <span className="edit-popover">
                                        <span className="text-gray-900 font-weight-bold mt-0 mb-0 ls-2">
                                          {dayjs(document.document_due_date).format(
                                            this.dateFormat,
                                          )}
                                        </span>
                                        {!this.state.external && this.state.edit && (
                                          <Popover
                                            title="Change Due Date"
                                            trigger={['click']}
                                            placement="right"
                                            className="popover-child"
                                            content={
                                              <div
                                                onClick={event => {
                                                  event.stopPropagation()
                                                }}
                                                className="d-flex align-items-center"
                                              >
                                                <span>
                                                  <DatePicker
                                                    style={{
                                                      borderRadius: '5px',
                                                      cursor: 'pointer',
                                                      width: '100%',
                                                    }}
                                                    allowClear={false}
                                                    format={this.dateFormat}
                                                    defaultValue={default_due_date}
                                                    onChange={date =>
                                                      this.setState({
                                                        value: date.format(this.dateFormat),
                                                      })
                                                    }
                                                  />
                                                </span>
                                                <span className="ml-2">
                                                  <Button
                                                    type="primary"
                                                    size="medium"
                                                    onClick={() =>
                                                      this.onDocumentEdit(
                                                        this.state.value,
                                                        'due_date',
                                                      )
                                                    }
                                                  >
                                                    Save
                                                  </Button>
                                                </span>
                                              </div>
                                            }
                                            onClick={e => e.stopPropagation()}
                                          >
                                            <span>
                                              <i className="fa-solid fa-pen-to-square text-gray-500 ml-2" />
                                            </span>
                                          </Popover>
                                        )}
                                      </span>
                                    </p>
                                  </div>
                                </div>

                                {/* <div className="mt-2">
                            <span className="text-gray-500 mr-2 font-weight-medium">
                              {document_name} Date
                            </span>
                            <span className="edit-popover">
                              <span className="text-gray-900 font-weight-bold mt-0 mb-0 ls-2">
                                {dayjs(document.document_date).format(this.dateFormat)}
                              </span>
                              {!this.state.external && this.state.edit && (
                                <Popover
                                  title="Change Document Date"
                                  trigger={['click']}
                                  placement="right"
                                  className="popover-child"
                                  content={
                                    <div
                                      onClick={event => {
                                        event.stopPropagation()
                                      }}
                                      className="d-flex align-items-center"
                                    >
                                      <span>
                                        <DatePicker
                                          style={{
                                            borderRadius: '5px',
                                            cursor: 'pointer',
                                            width: '100%',
                                          }}
                                          allowClear={false}
                                          format={this.dateFormat}
                                          defaultValue={dayjs(
                                            document.document_date,
                                            this.dateFormat,
                                          )}
                                          onChange={(date, dateString) => {
                                            this.setState({
                                              value: dayjs(dateString).format('DD-MM-YYYY'),
                                            })
                                          }}
                                        />
                                      </span>
                                      <span className="ml-2">
                                        <Button
                                          type="primary"
                                          size="medium"
                                          onClick={() =>
                                            this.onDocumentEdit(this.state.value, 'document_date')
                                          }
                                        >
                                          Save
                                        </Button>
                                      </span>
                                    </div>
                                  }
                                  onClick={e => e.stopPropagation()}
                                >
                                  <span>
                                    <i className="fa-solid fa-pen-to-square text-gray-500 ml-2" />
                                  </span>
                                </Popover>
                              )}
                            </span>

                            <span className="text-gray-500 mr-2 ml-3 font-weight-medium">
                              Due Date
                            </span>
                            <span className="edit-popover">
                              <span className="text-gray-900 font-weight-bold mt-0 mb-0 ls-2">
                                {dayjs(document.document_due_date).format(this.dateFormat)}
                              </span>
                              {!this.state.external && this.state.edit && (
                                <Popover
                                  title="Change Due Date"
                                  trigger={['click']}
                                  placement="right"
                                  className="popover-child"
                                  content={
                                    <div
                                      onClick={event => {
                                        event.stopPropagation()
                                      }}
                                      className="d-flex align-items-center"
                                    >
                                      <span>
                                        <DatePicker
                                          style={{
                                            borderRadius: '5px',
                                            cursor: 'pointer',
                                            width: '100%',
                                          }}
                                          allowClear={false}
                                          format={this.dateFormat}
                                          defaultValue={dayjs(
                                            document.document_due_date,
                                            this.dateFormat,
                                          )}
                                          onChange={(date, dateString) => {
                                            this.setState({
                                              value: dayjs(dateString).format('DD-MM-YYYY'),
                                            })
                                          }}
                                        />
                                      </span>
                                      <span className="ml-2">
                                        <Button
                                          type="primary"
                                          size="medium"
                                          onClick={() =>
                                            this.onDocumentEdit(this.state.value, 'due_date')
                                          }
                                        >
                                          Save
                                        </Button>
                                      </span>
                                    </div>
                                  }
                                  onClick={e => e.stopPropagation()}
                                >
                                  <span>
                                    <i className="fa-solid fa-pen-to-square text-gray-500 ml-2" />
                                  </span>
                                </Popover>
                              )}
                            </span>
                          </div>*/}
                                <div className="mt-2">
                                  {document.hide_totals == 0 && (
                                    <h6 className="font-weight-bolder text-gray-7 font-size-32 mb-0">
                                      {document.export_details.length > 0 &&
                                      document.invoice_settings.show_both_currencies != true ? (
                                        <span className="font-size-25 ">
                                          {document.export_details[0].currency_symbol}
                                        </span>
                                      ) : (
                                        <span className="font-size-21 mr-1">
                                          {this.props?.user?.selectedCompany?.currency_symbol}
                                        </span>
                                      )}
                                      {document.export_details.length > 0 &&
                                      document.invoice_settings.show_both_currencies != true
                                        ? (
                                            document.total_amount /
                                            document.export_details[0].conversion_factor
                                          ).toFixed(2)
                                        : document.total_amount.toLocaleString('en-IN', dotOptions)}
                                    </h6>
                                  )}
                                </div>
                              </Col>

                              <Col span={24}>
                                {document.reference != '' && (
                                  <>
                                    <div className="mt-2 mr-3 edit-popover">
                                      <span className="text-gray-500 font-weight-medium mr-1">
                                        Reference
                                        {!this.state.external && this.state.edit && (
                                          <Popover
                                            title="Change Reference"
                                            trigger={['click']}
                                            placement="right"
                                            className="popover-child"
                                            content={
                                              <div
                                                onClick={event => {
                                                  event.stopPropagation()
                                                }}
                                                className="d-flex align-items-center"
                                              >
                                                <span>
                                                  <Input
                                                    onChange={e =>
                                                      this.setState({ value: e.target.value })
                                                    }
                                                    defaultValue={document.reference}
                                                  />
                                                </span>
                                                <span className="ml-2">
                                                  <Button
                                                    type="primary"
                                                    size="medium"
                                                    onClick={() =>
                                                      this.onDocumentEdit(
                                                        this.state.value,
                                                        'reference',
                                                      )
                                                    }
                                                  >
                                                    Save
                                                  </Button>
                                                </span>
                                              </div>
                                            }
                                            onClick={e => e.stopPropagation()}
                                          >
                                            <span>
                                              {' '}
                                              <i className="fa-solid fa-pen-to-square text-gray-500 ml-2" />
                                            </span>
                                          </Popover>
                                        )}
                                      </span>
                                      <p className="mb-0">
                                        <span className="text-gray-900 font-weight-normal">
                                          {document.reference}
                                        </span>
                                      </p>
                                    </div>
                                  </>
                                )}
                              </Col>
                            </Row>
                          </>
                        }
                        key="1"
                        className="font-weight-bold pl-0"
                      >
                        <Descriptions size="small" bordered column={{ lg: 2, xs: 1 }}>
                          {document.customer.name != document.customer.company_name && (
                            <Descriptions.Item
                              label={party_type == 'customer' ? 'Customer' : 'Vendor'}
                              span={3}
                            >
                              {/* {document.customer.name} */}
                              <CopyText
                                text={document.customer.name}
                                successMessage={'Copied Customer Name'}
                                label="Customer Name"
                              />
                            </Descriptions.Item>
                          )}

                          {document.customer.name == document.customer.company_name && (
                            <Descriptions.Item label="Company" span={3}>
                              {/* {document.customer.company_name} */}
                              <CopyText
                                text={document.customer.company_name}
                                successMessage={'Copied Company Name'}
                                label="Company Name"
                              />
                            </Descriptions.Item>
                          )}

                          {document.customer.gstin != '' && document.customer.gstin != 'NA' && (
                            <Descriptions.Item
                              label={this.props?.user?.selectedCompany?.tax_id_label}
                              span={3}
                            >
                              <CopyText
                                text={document.customer.gstin}
                                successMessage={`Copied ${this.props?.user?.selectedCompany?.tax_id_label}`}
                                label={this.props?.user?.selectedCompany?.tax_id_label}
                              />
                            </Descriptions.Item>
                          )}

                          {document.customer.phone != '' && (
                            <Descriptions.Item label="Phone" span={3}>
                              {' '}
                              <CopyText
                                text={document.customer.phone}
                                successMessage={'Copied Phone #'}
                                label="Phone Number"
                              />
                            </Descriptions.Item>
                          )}

                          {document.customer.email != '' && (
                            <Descriptions.Item label="Email" span={3}>
                              <CopyText
                                text={document.customer.email}
                                successMessage={'Copied Email ID'}
                                label="Email"
                              />
                            </Descriptions.Item>
                          )}
                          {document_custom_headers.map((item, index) => {
                            return (
                              <Descriptions.Item
                                key={index}
                                label={
                                  <div className="d-flex justify-content-between">
                                    <span>{item.label}</span>
                                    {this.allow_document_edit && (
                                      <span>
                                        <i
                                          className="fa-solid fa-pen-to-square text-gray-500 ml-2 cursor-pointer"
                                          onClick={() => {
                                            this.setState(
                                              {
                                                editing_header_id: item.header_id,
                                                custom_headers: {
                                                  ...this.state.custom_headers,
                                                  [item.header_id]: item.value,
                                                },
                                              },
                                              () => {
                                                if (item.field_type == 'number') {
                                                  this.inputNumberFieldRef.current?.focus()
                                                } else if (item.field_type == 'text') {
                                                  this.inputTextFieldRef.current?.focus()
                                                } else if (item.field_type == 'date') {
                                                  this.datePickerRef.current?.focus()
                                                } else {
                                                  this.textAreaRef.current?.focus()
                                                }
                                              },
                                            )
                                          }}
                                        />
                                      </span>
                                    )}
                                  </div>
                                }
                                span={3}
                              >
                                <div className="d-flex justify-content-between">
                                  {this.state.editing_header_id &&
                                  this.state.editing_header_id == item.header_id ? (
                                    <div className="d-flex justify-content-between">
                                      {item.field_type == 'number' ? (
                                        <div className="d-flex">
                                          <InputNumber
                                            ref={this.inputNumberFieldRef}
                                            placeholder={`Enter ${item.label}`}
                                            defaultValue={item.value}
                                            onChange={e =>
                                              this.handleInputChange(item.header_id, e)
                                            }
                                            // onBlur={() => {
                                            //   this.setState(
                                            //     {
                                            //       editing_header_id: null,
                                            //     },
                                            //     () => {
                                            //       this.handleSave(item.header_id)
                                            //     },
                                            //   )
                                            // }}
                                            style={{
                                              width: '200px',
                                            }}
                                          />
                                          <div className="ml-2 d-flex align-items-center">
                                            <Tooltip title="Cancel" placement="top">
                                              <span
                                                onClick={e => {
                                                  e.stopPropagation()
                                                  this.setState({
                                                    editing_header_id: null,
                                                  })
                                                }}
                                                className="cursor-pointer"
                                              >
                                                <i className="fa-regular fa-lg fa-circle-xmark text-danger" />
                                              </span>
                                            </Tooltip>
                                            <Tooltip title="Save" placement="top">
                                              <span
                                                onClick={e => {
                                                  e.stopPropagation()
                                                  this.setState(
                                                    {
                                                      editing_header_id: null,
                                                    },
                                                    () => {
                                                      this.handleSave(item.header_id)
                                                    },
                                                  )
                                                }}
                                                className="ml-2 cursor-pointer"
                                              >
                                                <i className="fa-regular fa-lg fa-circle-check text-success" />
                                              </span>
                                            </Tooltip>
                                          </div>
                                        </div>
                                      ) : item.field_type == 'date' ? (
                                        <div className="d-flex">
                                          <DatePicker
                                            ref={this.datePickerRef}
                                            placeholder={'Select' + item.label}
                                            defaultValue={
                                              item.value != '' &&
                                              this.dateFormatRegex.test(item.value)
                                                ? dayjs(item.value, 'DD-MM-YYYY')
                                                : undefined
                                            }
                                            format={this.dateFormat}
                                            onChange={value =>
                                              this.handleInputChange(
                                                item.header_id,
                                                value.format(this.dateFormat),
                                              )
                                            }
                                            // onBlur={e => {
                                            //   this.setState(
                                            //     {
                                            //       editing_header_id: null,
                                            //     },
                                            //     () => {
                                            //       this.handleSave(item.header_id)
                                            //     },
                                            //   )
                                            // }}
                                            style={{
                                              width: '200px',
                                            }}
                                          />
                                          <div className="ml-2 d-flex align-items-center">
                                            <Tooltip title="Cancel" placement="top">
                                              <span
                                                onClick={e => {
                                                  e.stopPropagation()
                                                  this.setState({
                                                    editing_header_id: null,
                                                  })
                                                }}
                                                className="cursor-pointer"
                                              >
                                                <i className="fa-regular fa-lg fa-circle-xmark text-danger" />
                                              </span>
                                            </Tooltip>
                                            <Tooltip title="Save" placement="top">
                                              <span
                                                onClick={e => {
                                                  e.stopPropagation()
                                                  this.setState(
                                                    {
                                                      editing_header_id: null,
                                                    },
                                                    () => {
                                                      this.handleSave(item.header_id)
                                                    },
                                                  )
                                                }}
                                                className="ml-2 cursor-pointer"
                                              >
                                                <i className="fa-regular fa-lg fa-circle-check text-success" />
                                              </span>
                                            </Tooltip>
                                          </div>
                                        </div>
                                      ) : item.field_type == 'text' ? (
                                        <div className="d-flex">
                                          <Input
                                            ref={this.inputTextFieldRef}
                                            placeholder={`Enter ${item.label}`}
                                            defaultValue={item.value}
                                            onChange={e => {
                                              this.handleInputChange(item.header_id, e.target.value)
                                            }}
                                            // onBlur={(e) => {
                                            //   e.stopPropagation();
                                            //   this.setState(
                                            //     {
                                            //       editing_header_id: null,
                                            //     },
                                            //     () => {
                                            //       this.handleSave(item.header_id)
                                            //     },
                                            //   )
                                            // }}
                                            style={{
                                              width: '200px',
                                            }}
                                          />
                                          <div className="ml-2 d-flex align-items-center">
                                            <Tooltip title="Cancel" placement="top">
                                              <span
                                                onClick={e => {
                                                  e.stopPropagation()
                                                  this.setState({
                                                    editing_header_id: null,
                                                  })
                                                }}
                                                className="cursor-pointer"
                                              >
                                                <i className="fa-regular fa-lg fa-circle-xmark text-danger" />
                                              </span>
                                            </Tooltip>
                                            <Tooltip title="Save" placement="top">
                                              <span
                                                onClick={e => {
                                                  e.stopPropagation()
                                                  this.setState(
                                                    {
                                                      editing_header_id: null,
                                                    },
                                                    () => {
                                                      this.handleSave(item.header_id)
                                                    },
                                                  )
                                                }}
                                                className="ml-2 cursor-pointer"
                                              >
                                                <i className="fa-regular fa-lg fa-circle-check text-success" />
                                              </span>
                                            </Tooltip>
                                          </div>
                                        </div>
                                      ) : (
                                        <div className="d-flex">
                                          <TextArea
                                            ref={this.textAreaRef}
                                            rows={4}
                                            placeholder={`Enter ${item.label}`}
                                            defaultValue={item.value}
                                            onChange={e => {
                                              this.handleInputChange(item.header_id, e.target.value)
                                            }}
                                            // onBlur={() => {
                                            //   this.setState(
                                            //     {
                                            //       editing_header_id: null,
                                            //     },
                                            //     () => {
                                            //       this.handleSave(item.header_id)
                                            //     },
                                            //   )
                                            // }}
                                            style={{
                                              width: '200px',
                                            }}
                                          />
                                          <div className="ml-2 d-flex align-items-center">
                                            <Tooltip title="Cancel" placement="top">
                                              <span
                                                onClick={e => {
                                                  e.stopPropagation()
                                                  this.setState({
                                                    editing_header_id: null,
                                                  })
                                                }}
                                                className="cursor-pointer"
                                              >
                                                <i className="fa-regular fa-lg fa-circle-xmark text-danger" />
                                              </span>
                                            </Tooltip>
                                            <Tooltip title="Save" placement="top">
                                              <span
                                                onClick={e => {
                                                  e.stopPropagation()
                                                  this.setState(
                                                    {
                                                      editing_header_id: null,
                                                    },
                                                    () => {
                                                      this.handleSave(item.header_id)
                                                    },
                                                  )
                                                }}
                                                className="ml-2 cursor-pointer"
                                              >
                                                <i className="fa-regular fa-lg fa-circle-check text-success" />
                                              </span>
                                            </Tooltip>
                                          </div>
                                        </div>
                                      )}
                                    </div>
                                  ) : (
                                    <>{item.value}</>
                                  )}
                                </div>
                                {/* <Popover
                            trigger={['click']}
                            title={`Change ${item.label}`}
                            content={
                              <div className="d-flex justify-content-between">
                                <Input
                                  placeholder={`Enter ${item.label}`}
                                  onChange={e =>
                                    this.handleInputChange(item.header_id, e.target.value)
                                  }
                                />
                                <Button
                                  type="action"
                                  className="ml-2"
                                  onClick={() => {
                                    this.handleSave(item.header_id)
                                  }}
                                >
                                  Save
                                </Button>
                              </div>
                            }
                          >
                            <i className="fa-solid fa-pen-to-square text-gray-500 ml-2" />
                          </Popover> */}
                              </Descriptions.Item>
                            )
                          })}

                          {document.customer.billing.address_1 != '' && (
                            <Descriptions.Item label="Billing Address" span={3}>
                              {/* {document.customer.billing.address_1} <br />
                        {document.customer.billing.address_2 != '' && (
                          <>
                            {document.customer.billing.address_2} <br />
                          </>
                        )}
                        {document.customer.billing.city != '' && (
                          <>
                            {document.customer.billing.city} <br />
                          </>
                        )}
                        {document.customer.billing.state} {document.customer.billing.pincode} */}
                              <CopyText
                                label="Billing Address"
                                text={
                                  (document.customer.billing.address_1 != ''
                                    ? document.customer.billing.address_1 + ',$'
                                    : '') +
                                  (document.customer.billing.address_2 != ''
                                    ? document.customer.billing.address_2 + ',$'
                                    : '') +
                                  (document.customer.billing.city != ''
                                    ? document.customer.billing.city + ',$'
                                    : '') +
                                  document.customer.billing.state +
                                  '-' +
                                  document.customer.billing.pincode
                                }
                              />
                            </Descriptions.Item>
                          )}

                          {party_type == 'customer' && document.customer.shipping.address_1 != '' && (
                            <Descriptions.Item label="Shipping Address" span={3}>
                              {/* {document.customer.shipping.address_1} <br />
                        {document.customer.shipping.address_2 != '' && (
                          <>
                            {document.customer.shipping.address_2} <br />
                          </>
                        )}
                        {document.customer.shipping.city != '' && (
                          <>
                            {document.customer.shipping.city} <br />
                          </>
                        )}
                        {document.customer.shipping.state} {document.customer.shipping.pincode} */}
                              <CopyText
                                label="Shipping Address"
                                text={
                                  (document.customer.shipping.address_1 != ''
                                    ? document.customer.shipping.address_1 + ',$'
                                    : '') +
                                  (document.customer.shipping.address_2 != ''
                                    ? document.customer.shipping.address_2 + ',$'
                                    : '') +
                                  (document.customer.shipping.city != ''
                                    ? document.customer.shipping.city + ',$'
                                    : '') +
                                  document.customer.shipping.state +
                                  '-' +
                                  document.customer.shipping.pincode
                                }
                              />
                              <br />
                              {!this.state.external && (
                                <>
                                  {this.state.edit && (
                                    <a
                                      onClick={() => this.showAddressModal('to')}
                                      className="card-link font-weight-bold text-primary mr-1"
                                    >
                                      Change
                                    </a>
                                  )}
                                  {this.props.permissions.customer_edit == 1 && (
                                    <a
                                      onClick={e =>
                                        this.customer.onEdit(document.customer['customer_id'])
                                      }
                                      className="card-link font-weight-bold text-primary mr-3"
                                    >
                                      Edit
                                      <i className="fa fa-pencil-o" />
                                    </a>
                                  )}
                                </>
                              )}
                            </Descriptions.Item>
                          )}
                        </Descriptions>
                      </Panel>
                    </Collapse>
                    <div className="mb-4">
                      <ProductsList
                        isExternal={this.state.external}
                        data={document.items}
                        extraDiscount={document.extra_discount}
                        document_custom_additional_charges={
                          document.document_custom_additional_charges
                        }
                        document={document}
                        refresh={this.get_invoice_data}
                      />
                    </div>

                    {'signed_doc_url' in document &&
                      document.signed_doc_url != '' &&
                      !this.state.external && (
                        <Collapse
                          defaultActiveKey={[]}
                          bordered={false}
                          expandIcon={({ isActive }) => (
                            <span className="text-right mb-0 ">
                              {isActive ? '' : 'More'}
                              <i
                                className={`fa-solid fa-lg fa-angle-down ml-1 ${!isActive &&
                                  'fa-rotate-270'}`}
                              />
                            </span>
                          )}
                          expandIconPosition="right"
                          className="mb-4 site-collapse-custom-collapse  pl-0 pr-0"
                          onChange={key => {
                            if (key.length > 0) {
                              this.getDigitalSignatureLogs(document)
                            }
                          }}
                        >
                          <Panel
                            header={
                              <>
                                <Row>
                                  <Col span={24} className="mr-3">
                                    <div className="">
                                      <span className="text-gray-500 font-size-14 font-weight-bold mt-0 mb-2">
                                        Digital signature History
                                      </span>
                                    </div>
                                  </Col>
                                </Row>
                              </>
                            }
                            key="1"
                            className="pl-0"
                            ghost
                            loading={this.state.digital_signature_loading}
                          >
                            <Card
                              size="small"
                              bordered={true}
                              className="border-radius-small mb-4 bg-white"
                              loading={this.state.digital_signature_loading}
                            >
                              <Table
                                size="small"
                                columns={[
                                  {
                                    title: 'Record Time',
                                    dataIndex: 'record_time',
                                    key: 'record_time',
                                    render: text => <span>{text}</span>,
                                  },
                                  {
                                    title: 'Signed By',
                                    dataIndex: 'user_name',
                                    key: 'user_name',
                                    render: text => <span>{text}</span>,
                                  },
                                  {
                                    title: 'Action',
                                    dataIndex: 'action',
                                    key: 'action',
                                    render: (_, data) => (
                                      <>
                                        <Button
                                          type="primary"
                                          size="small"
                                          className="mr-2"
                                          onClick={() => this.DownloadSignedDoc(data)}
                                        >
                                          <i className="fa-solid fa-arrow-down mr-2" /> Download
                                        </Button>
                                      </>
                                    ),
                                  },
                                ]}
                                dataSource={this.state.digital_signature_logs_data}
                              />
                            </Card>
                          </Panel>
                        </Collapse>
                      )}

                    <span>
                      <Descriptions
                        size="small"
                        bordered
                        column={{ lg: 1, xs: 1 }}
                        className="mb-4"
                      >
                        {document.payments.length > 0 && !this.state.external && (
                          <Descriptions.Item label="Payments" span={3}>
                            {document.payments.map((each_payment, index) => (
                              <span key={index}>
                                <Button
                                  type={this.getPaidClass(each_payment['payment_mode'])}
                                  size="default"
                                  onClick={event => {
                                    event.stopPropagation()
                                    if (this.props.permissions.record_payment == 1) {
                                      this.edit.onAdd(
                                        this.props.utils,
                                        each_payment,
                                        this.state.document_type,
                                      )
                                    }
                                  }}
                                  //onClick={() => this.getPayment(each_payment)}
                                  className={`mr-2 mb-1 mt-1`}
                                >
                                  {each_payment['rzp_order_id'] && (
                                    <Tooltip title="Instant Payment through Payment Gateway">
                                      <span>
                                        <i className="fa-solid fa-bolt mr-1"></i>
                                      </span>
                                    </Tooltip>
                                  )}
                                  {each_payment['phonpe_transaction_id'] &&
                                    each_payment['phonpe_transaction_id'] != '' && (
                                      <Tooltip title="Paid through PhonePe">
                                        <span>
                                          <i className="fak fa-brand-phonepe mr-1"></i>
                                        </span>
                                      </Tooltip>
                                    )}

                                  <Tooltip
                                    title={
                                      <>
                                        <span className="text-center">
                                          <span className="text-gray-100 mt-0 mb-0">
                                            {each_payment['serial_number']}
                                          </span>
                                          <span className="font-size-18 mb-2 mt-0 font-weight-bolder">
                                            <p
                                              className="mt-0 mb-0"
                                              style={{ color: 'lightgreen' }}
                                            >
                                              <span className="mr-1">
                                                {this.props?.user?.selectedCompany?.currency_symbol}
                                              </span>
                                              {each_payment['amount']}
                                            </p>
                                          </span>
                                        </span>
                                      </>
                                    }
                                  >
                                    <span className="font-weight-bold">
                                      {each_payment['payment_mode']}
                                    </span>
                                  </Tooltip>
                                </Button>
                              </span>
                            ))}
                          </Descriptions.Item>
                        )}
                        {this.state.banks.length > 0 &&
                          this.state.document_type != 'purchase' &&
                          this.state.document_type != 'purchase_order' &&
                          this.state.document_type != 'purchase_return' && (
                            <Descriptions.Item
                              label={
                                <div className="d-flex justify-content-between">
                                  <span>Bank</span>
                                  <span>
                                    <i
                                      className="fa-solid fa-pen-to-square text-gray-500 ml-2 cursor-pointer"
                                      onClick={() => {
                                        this.setState(
                                          {
                                            edit_bank: !this.state.edit_bank,
                                            edit_signature: false,
                                            edit_exclusive_notes: false,
                                          },
                                          () => this.bankRef.current?.focus(),
                                        )
                                      }}
                                    />
                                  </span>
                                </div>
                              }
                              span={3}
                            >
                              <div className="edit-popover">
                                <Select
                                  ref={this.bankRef}
                                  placeholder="Select Bank"
                                  optionFilterProp="children"
                                  style={{ minWidth: '360px' }}
                                  onChange={e => {
                                    this.setState(
                                      { value: e, selected_bank: e, edit_bank: false },
                                      () => {
                                        this.onDocumentEdit(this.state.value, 'bank_id')
                                      },
                                    )
                                  }}
                                  value={this.state.selected_bank}
                                  filterOption={(input, option) =>
                                    option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                  }
                                  disabled={this.state.edit && !this.state.edit_bank}
                                >
                                  {this.state.banks.map((item, i) => (
                                    <Option key={i} value={item.id}>
                                      {item.bank_name == 'Cash'
                                        ? item?.bank_name
                                        : item?.bank_name + ' (' + item.bank_no + ')'}
                                    </Option>
                                  ))}
                                </Select>
                              </div>
                            </Descriptions.Item>
                          )}
                        {!this.state.external && (
                          <Descriptions.Item
                            label={
                              <div className="d-flex justify-content-between">
                                <span>Signature</span>
                                <span>
                                  <i
                                    className="fa-solid fa-pen-to-square text-gray-500 ml-2 cursor-pointer"
                                    onClick={() =>
                                      this.setState(
                                        {
                                          edit_signature: !this.state.edit_signature,
                                          edit_bank: false,
                                          edit_exclusive_notes: false,
                                        },
                                        () => this.signatureRef.current?.focus(),
                                      )
                                    }
                                  />
                                </span>
                              </div>
                            }
                            span={3}
                          >
                            <div className="edit-popover d-flex">
                              <img
                                src={this.state.selectedSignature.image}
                                className="height-100 border-radius-small mr-3"
                              />{' '}
                              {this.state.edit_signature && (
                                <span>
                                  {this.state.signatures.length > 0 && (
                                    <div className="d-flex flex-column align-content-center justify-content-center height-100">
                                      <Select
                                        ref={this.signatureRef}
                                        placeholder="Select Signature"
                                        optionFilterProp="children"
                                        style={{ width: '100px' }}
                                        onChange={e =>
                                          this.setState(
                                            {
                                              selectedSignature: this.state.signatures.find(
                                                ({ id }) => id === e,
                                              ),
                                              edit_signature: false,
                                            },
                                            () =>
                                              this.onDocumentEdit(
                                                this.state.selectedSignature.image,
                                                'signature',
                                              ),
                                          )
                                        }
                                        value={this.state.selectedSignature.signature_name}
                                        defaultValue={this.state.selectedSignature.id}
                                        filterOption={(input, option) =>
                                          option.children
                                            .toLowerCase()
                                            .indexOf(input.toLowerCase()) >= 0
                                        }
                                      >
                                        {this.state.signatures.map((item, i) => (
                                          <Option key={i} value={item.id}>
                                            {item.signature_name}
                                          </Option>
                                        ))}
                                      </Select>
                                    </div>
                                  )}
                                </span>
                              )}
                              {/* {this.state.edit && (
                          <Popover
                            title="Change Signature"
                            trigger={['click']}
                            className="cursor-pointer popover-child"
                            placement="right"
                            content={
                              <div
                                onClick={event => {
                                  event.stopPropagation()
                                }}
                              >
                                <span>
                                  {this.state.signatures.length > 0 && (
                                    <Select
                                      placeholder="Select Signature"
                                      optionFilterProp="children"
                                      style={{ width: '100%' }}
                                      onChange={e =>
                                        this.setState(
                                          {
                                            selectedSignature: this.state.signatures.find(
                                              ({ id }) => id === e,
                                            ),
                                          },
                                          () =>
                                            this.onDocumentEdit(
                                              this.state.selectedSignature.image,
                                              'signature',
                                            ),
                                        )
                                      }
                                      value={this.state.selectedSignature.signature_name}
                                      defaultValue={this.state.selectedSignature.id}
                                      filterOption={(input, option) =>
                                        option.children
                                          .toLowerCase()
                                          .indexOf(input.toLowerCase()) >= 0
                                      }
                                    >
                                      {this.state.signatures.map((item, i) => (
                                        <Option key={i} value={item.id}>
                                          {item.signature_name}
                                        </Option>
                                      ))}
                                    </Select>
                                  )}
                                </span>
                              </div>
                            }
                            onClick={e => e.stopPropagation()}
                          >
                            <i className="fa-solid fa-pen-to-square text-gray-500 ml-2" />
                          </Popover>
                        )} */}
                            </div>
                          </Descriptions.Item>
                        )}
                        {/* {document.attachments.length > 0 && !this.state.external && (
                          <Descriptions.Item label="Attachments" span={3}>
                            {document.attachments.map((item, index) => (
                              <p className="mb-1">
                                <a
                                  key={item.uid}
                                  href={item.url}
                                  target="_blank"
                                  className="text-gray-700"
                                  style={{ textDecoration: 'underline' }}
                                >
                                  <i className="fa-solid fa-link mr-2"></i>
                                  <span className="font-size-16 font-weight-medium">
                                    {item.name}
                                  </span>

                                </a>
                              </p>
                            ))}
                          </Descriptions.Item>
                        )} */}
                        {document.notes != '' && document.notes != 'None' && (
                          <Descriptions.Item label="Notes" span={3}>
                            {document.notes}
                          </Descriptions.Item>
                        )}
                        {document.terms != '' && document.terms != 'None' && (
                          <Descriptions.Item label="Terms" span={3}>
                            {document.terms}
                          </Descriptions.Item>
                        )}
                        {!this.state.external && (
                          <Descriptions.Item
                            label={
                              <div className="d-flex justify-content-between">
                                <span>Internal Notes</span>
                                <span>
                                  <i
                                    className="fa-solid fa-pen-to-square text-gray-500 ml-2 cursor-pointer"
                                    onClick={() => {
                                      this.setState(
                                        {
                                          edit_exclusive_notes: !this.state.edit_exclusive_notes,
                                          edit_bank: false,
                                          edit_signature: false,
                                        },
                                        () => this.exclusiveNotesRef.current?.focus(),
                                      )
                                    }}
                                  />
                                </span>
                              </div>
                            }
                            span={3}
                          >
                            <div className="edit-popover d-flex justify-content-between">
                              {this.state.edit_exclusive_notes ? (
                                <div
                                  onClick={event => {
                                    event.stopPropagation()
                                  }}
                                  className="d-flex align-items-center"
                                >
                                  <span>
                                    <TextArea
                                      ref={this.exclusiveNotesRef}
                                      rows={4}
                                      value={this.state.exclusive_notes}
                                      onChange={e => {
                                        this.setState({ exclusive_notes: e.target.value })
                                      }}
                                      // onBlur={() => {
                                      //   this.setState(
                                      //     {
                                      //       edit_exclusive_notes: false,
                                      //     },
                                      //     () => {
                                      //       this.onDocumentEdit(
                                      //         this.state.exclusive_notes,
                                      //         'exclusive_notes',
                                      //       )
                                      //     },
                                      //   )
                                      // }}
                                    />
                                  </span>
                                  <div className="ml-2 d-flex align-items-center">
                                    <Tooltip title="Cancel" placement="top">
                                      <span
                                        onClick={e => {
                                          e.stopPropagation()
                                          this.setState({
                                            edit_exclusive_notes: false,
                                          })
                                        }}
                                        className="cursor-pointer"
                                      >
                                        <i className="fa-regular fa-lg fa-circle-xmark text-danger" />
                                      </span>
                                    </Tooltip>
                                    <Tooltip title="Save" placement="top">
                                      <span
                                        onClick={e => {
                                          e.stopPropagation()
                                          this.setState(
                                            {
                                              edit_exclusive_notes: false,
                                            },
                                            () => {
                                              this.onDocumentEdit(
                                                this.state.exclusive_notes,
                                                'exclusive_notes',
                                              )
                                            },
                                          )
                                        }}
                                        className="ml-2 cursor-pointer"
                                      >
                                        <i className="fa-regular fa-lg fa-circle-check text-success" />
                                      </span>
                                    </Tooltip>
                                  </div>
                                </div>
                              ) : (
                                <span>{document.exclusive_notes}</span>
                              )}
                            </div>
                          </Descriptions.Item>
                        )}
                      </Descriptions>
                    </span>

                    {(document.eway_bill || document.einvoice) && (
                      <Descriptions
                        size="small"
                        bordered
                        column={{ lg: 1, xs: 1 }}
                        className="mb-4"
                      >
                        {document.eway_bill && document.eway_bill.length > 0 && (
                          <Descriptions.Item label="E-way Bill" span={3}>
                            {document.eway_bill.map((item, index) => (
                              <span className="font-size-16 text-gray-700 font-weight-bold">
                                <CopyText
                                  text={item.serial_number}
                                  successMessage={'Copied'}
                                  label={'Serial No.'}
                                  color="blue"
                                />
                                <Button
                                  type="info"
                                  size="small"
                                  onClick={() =>
                                    this.ewaybillPdfRef.current.getEwayBillDocument(item)
                                  }
                                  className="d-none d-sm-inline font-weight-bold ml-2"
                                >
                                  <i className="fe fe-eye mr-1" />
                                  <FormattedMessage id="button.view" />
                                </Button>
                              </span>
                            ))}
                          </Descriptions.Item>
                        )}
                        {document.einvoice && document.einvoice.length > 0 && (
                          <Descriptions.Item label="E-invoice" span={3}>
                            {document.einvoice.map((item, index) => (
                              <>
                                <span className="font-size-16 text-gray-700 font-weight-bold">
                                  IRN:
                                  {/* {item.irn} */}
                                  <CopyText
                                    text={item.irn}
                                    successMessage={'Copied'}
                                    label={'IRN'}
                                    color="blue"
                                  />
                                </span>
                                <div className="mt-2">
                                  <span className="font-size-16 text-gray-700 font-weight-bold">
                                    ACK.No:
                                    {/* {item.ack_no} */}
                                    <CopyText
                                      text={item.ack_no}
                                      successMessage={'Copied'}
                                      label={'ACK.No'}
                                      color="blue"
                                    />
                                  </span>
                                </div>
                              </>
                            ))}
                          </Descriptions.Item>
                        )}
                      </Descriptions>
                    )}

                    {!this.state.external &&
                      'email_status' in document &&
                      document.email_status != '' && (
                        <div className="mb-4">
                          <SectionHeader title="Email" />
                          <Table
                            columns={email_columns}
                            dataSource={document.email_status}
                            size="small"
                          />
                        </div>
                      )}

                    {'convert_timeline' in document && document.convert_timeline.length > 0 && (
                      <>
                        <SectionHeader title=" Edit History" />
                        <Card size="drawer" bordered={true} className="border-radius-small mb-4">
                          <Timeline className="mt-3">
                            {document.convert_timeline.map((each_convert, index) => (
                              <Timeline.Item key={index}>
                                <span className="font-weight-medium">{each_convert.text}</span>
                                <span className="font-size-12 text-gray-700">
                                  <p className="mt-0 mb-0">
                                    <span className="">{each_convert.record_time}</span>
                                  </p>
                                </span>
                              </Timeline.Item>
                            ))}
                          </Timeline>
                        </Card>
                      </>
                    )}
                    {'has_reminders' in document &&
                      document.has_reminders > 0 &&
                      !this.state.external && (
                        <Collapse
                          defaultActiveKey={[]}
                          bordered={false}
                          expandIcon={({ isActive }) => (
                            <span className="text-right mb-0 font-weight-bolder">
                              {isActive ? '' : 'see history'}
                              <i
                                className={`fa-solid fa-lg fa-angle-down ml-1 ${!isActive &&
                                  'fa-rotate-270'}`}
                              />
                            </span>
                          )}
                          expandIconPosition="right"
                          className="mb-4 site-collapse-custom-collapse  pl-0 pr-0"
                        >
                          <Panel
                            header={
                              <>
                                <Row>
                                  <Col span={24} className="mr-3">
                                    <div className="">
                                      <span className="text-gray-500 font-size-14 font-weight-bold mt-0 mb-2">
                                        Reminder History
                                      </span>
                                    </div>
                                  </Col>
                                </Row>
                              </>
                            }
                            key="1"
                            className="font-weight-bold pl-0"
                            ghost
                          >
                            <Card
                              size="drawer"
                              bordered={true}
                              className="border-radius-small mb-4 bg-white"
                            >
                              <Timeline className="mt-3">
                                {this.state.reminderTimeline.map((Timeline, index) => (
                                  <Timeline.Item
                                    key={index}
                                    dot={
                                      Timeline.upcoming == true ? (
                                        <i className="fa-solid fa-bell text-success fa-sm"></i>
                                      ) : (
                                        <i className="fa-solid fa-bell-on text-primary fa-sm"></i>
                                      )
                                    }
                                  >
                                    <span className="font-weight-medium">
                                      {Timeline.reminder_name}
                                    </span>
                                    <span className="font-size-12 text-gray-700">
                                      <p className="mt-0 mb-0">
                                        <span
                                          className={
                                            Timeline.sent_sms == 1 ? 'text-success' : 'text-danger'
                                          }
                                        >
                                          SMS
                                        </span>
                                        <span className="ml-2">
                                          <span
                                            className={
                                              Timeline.sent_email == 1
                                                ? 'text-success'
                                                : 'text-danger'
                                            }
                                          >
                                            Email
                                          </span>
                                        </span>
                                      </p>
                                      <p className="mt-0 mb-0">
                                        <span className="">{Timeline.created_time}</span>
                                      </p>
                                    </span>
                                  </Timeline.Item>
                                ))}
                              </Timeline>
                              {/* icone descriptions */}
                              <div className="d-flex justify-content-end">
                                <div className="mx-2">
                                  <i className="fa-solid fa-bell text-success fa-sm "></i>
                                  <span className="ml-1 font-size-12 text-gray-500">Upcoming</span>
                                </div>
                                <div className="mx-2">
                                  <i className="fa-solid fa-bell-on text-primary fa-sm"></i>
                                  <span className="ml-1 font-size-12 text-gray-500">Completed</span>
                                </div>
                              </div>
                            </Card>
                          </Panel>
                        </Collapse>
                      )}

                    {/*<Divider />
              <div className="mb-2">
                <Button type="danger" className="font-weight-bold" onClick={this.onClose}>
                </Button>
              </div>*/}

                    {this.state.invoiceData != '' && (
                      <Modal
                        title="E-way Bill"
                        open={this.state.flag}
                        style={{ top: 20 }}
                        width={900}
                        onOk={() => this.setState({ flag: false })}
                        onCancel={() => this.setState({ flag: false, invoiceData: '' })}
                        closeIcon={<i className="fa-solid fa-lg fa-xmark"></i>}
                        footer={[
                          <Button
                            key="back"
                            type="default"
                            onClick={() => this.setState({ flag: false })}
                          >
                            Cancel
                          </Button>,
                        ]}
                      >
                        <MyDocument
                          data={this.state.invoiceData}
                          menuName={'ewaybills'}
                          type={['']}
                        />
                      </Modal>
                    )}
                    {!this.state.external && (
                      <div className="mt-2">
                        <SectionHeader title="Attachments" />
                        <Dragger
                          customRequest={this.customRequest}
                          maxCount={1}
                          beforeUpload={this.beforeUpload}
                          onRemove={this.handleChange}
                          multiple={false}
                          fileList={this.state.attachments}
                        >
                          <div className="d-flex justify-content-center align-items-center">
                            <div className="mr-4">
                              <UploadOutlined className="font-size-32 text-gray-500" />
                            </div>
                            <div>
                              <p className="ant-upload-text">
                                Drag and drop here or click to upload
                              </p>
                              <p className="ant-upload-hint">
                                You may upload PDF, PNG, or JPEG files
                              </p>
                            </div>
                          </div>
                        </Dragger>
                      </div>
                    )}
                  </>
                )}
              </TabPane>
              {!this.state.external && this.props.permissions.history == 1 && (
                <TabPane
                  tab={
                    <span>
                      Activity
                      <BetaBadge />
                    </span>
                  }
                  key="comments_history"
                >
                  {document == '' && (
                    <Loader text={'Loading...'} visibleText={true} className="ml-2 mt-4 pt-4" />
                  )}
                  {document != '' && (
                    <CommentsAndHistory
                      document_type={this.state.document_type}
                      doc_count={document.doc_count}
                      new_hash_id={document.new_hash_id}
                      menu_name={this.state.menu_name}
                    />
                  )}
                </TabPane>
              )}
            </Tabs>
          </div>
          <EditPayInForm onRef={ref => (this.edit = ref)} onApicalled={() => console.log('')} />
          <EnhancedAddresses
            onRef={ref => (this.address = ref)}
            onAddressChange={addr_id => this.onAddressChange(addr_id)}
            onAPICalled={() => this.showDrawer(this.state.menu_name, this.state.document)}
            dispatch={this.props.dispatch}
          />

          <EnhancedSubscriptionModal onRef={ref => (this.subscription = ref)} />
          <EnhancedPartyAnalysis onRef={ref => (this.analysis = ref)} />

          {!this.state.external && (
            <Suspense>
              <CustomerForm
                onRef={ref => (this.customer = ref)}
                onAPICalled={(a, b, c) =>
                  this.showDrawer(this.state.menu_name, this.state.document)
                }
              />
              <PaymentFrom onRef={ref => (this.payment = ref)} onApicalled={() => console.log()} />
              <EnhancedShiprocketModal onRef={ref => (this.shiprocket = ref)} />
            </Suspense>
          )}
        </Drawer>
        <EwaybillPdf ref={this.ewaybillPdfRef} />
      </>
    )
  }
}

class EnhancedInvoiceForm extends React.Component {
  constructor(props) {
    super(props)
  }
  render() {
    return <InvoiceForm {...this.props} />
  }
}

function mapStateToProps(state, ownProps) {
  return {
    permissions: state.permissions.permission,
    integrations: state.permissions.integrations,
    utils: {
      payment_modes: state.document.paymentModes,
      bank_details: state.document.bankDetails,
    },
    user: state.user,
  }
}

export default connect(mapStateToProps)(EnhancedInvoiceForm)
