import { Button, Card } from 'antd'
import CustomPricing from 'components/modal/pricing'
import SubscriptionPlanModal from 'components/modal/subscription/subscriptionPlan'
import React, { useEffect, useRef, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { getAPIData } from 'services/jwt'
import store from 'store'
import { displayRazorpay } from './utility'

const Visibility = ({ type, className, visible }) => {
  const { subscription_features } = useSelector(state => state.user)
  const [featuresList, setFeaturesList] = useState([])
  const [subscriptionPlan, setSubscriptionPlan] = useState({})
  const customPricingRef = useRef()
  const subscriptionPlanRef = useRef()

  const dispatch = useDispatch()

  const loadScript = src => {
    return new Promise(resolve => {
      const script = document.createElement('script')
      script.src = src
      script.onload = () => {
        resolve(true)
      }
      script.onerror = () => {
        resolve(false)
      }
      document.body.appendChild(script)
    })
  }
  useEffect(() => {
    if (visible) {
      const fetchSubscriptionFeatures = async () => {
        const subscription_features = await getAPIData('v2/leads', 'subscription_features')
        if (subscription_features.success) {
          setFeaturesList(subscription_features.data)
          const plan = subscription_features.data.filter(plan => plan.feature_key == type)[0] || {}
          setSubscriptionPlan({
            ...plan,
          })

          dispatch({
            type: 'user/SET_STATE',
            payload: {
              subscription_features: subscription_features.data,
            },
          })
        }
      }
      if (!subscription_features || subscription_features.length == 0) {
        fetchSubscriptionFeatures()
      } else {
        setFeaturesList(subscription_features)
        const plan = subscription_features?.filter(plan => plan.feature_key == type)[0] || {}
        setSubscriptionPlan({ ...plan })
      }
    }
  }, [visible])

  return (
    <>
      {store.get('paid') == 0 && (
        <div className={className}>
          <Card size="visibility" className="bg-card-azure width-100p">
            <div className="d-flex flex-row justify-content-between align-items-center width-100p">
              <div className="">
                <div className="d-flex flex-row align-items-center ">
                  <div className="lh-large">
                    <span className="font-weight-bolder font-size-18 text-black">
                      {subscriptionPlan?.short_title}
                      {/*Easily create invoices in 10 Seconds 🤩*/}
                    </span>
                    <p className="mt-0 mb-0 font-size-14 text-black">
                      {subscriptionPlan?.tagline}
                      {/*and share them with your customers!*/}
                    </p>
                  </div>
                </div>
              </div>

              <div className="">
                {window.location.hostname != 'mac.getswipe.in' && (
                  <Button
                    type="sign-up"
                    size=""
                    onClick={() =>
                      subscriptionPlanRef.current.openModal(
                        subscriptionPlan?.feature_key,
                        subscriptionPlan?.plan_id,
                      )
                    }
                    className="font-weight-bolder float-right"
                  >
                    Upgrade 🚀
                  </Button>
                )}

                <Button
                  type="text"
                  size=""
                  onClick={event => {
                    event.stopPropagation()
                    window.open(
                      `https://api.whatsapp.com/send?phone=918121335436&text=${subscriptionPlan?.contact_text}`,
                      '_blank',
                    )
                  }}
                  className="font-weight-bolder float-right mr-2"
                >
                  <i class="fa-solid fa-headset mr-2"></i> Talk to a specialist
                </Button>
              </div>
            </div>
          </Card>
        </div>
      )}
      <CustomPricing
        ref={customPricingRef}
        onSelectPlan={(plan, years, addons) => displayRazorpay(plan, years, addons)}
      />
      <SubscriptionPlanModal ref={subscriptionPlanRef} />
    </>
  )
}

export default Visibility
