import React from 'react'

export const getNetAmount = record => {
  var price_with_tax =
    record.price_with_tax -
    parseFloat(record.cess_on_qty && record.cess_on_qty_value ? record.cess_on_qty : 0)

  var net_amount =
    price_with_tax * record.qty - (price_with_tax * record.qty * record.discount) / 100

  net_amount =
    net_amount +
    parseFloat(record.cess_on_qty && record.cess_on_qty_value ? record.cess_on_qty : 0) * record.qty
  return Number(net_amount)
}

export const getNewNetAmount = record => {
  var price_with_tax =
    record.price_with_tax -
    parseFloat(record.cess_on_qty && record.cess_on_qty_value ? record.cess_on_qty : 0)

  var net_amount = price_with_tax * record.qty

  if (record.show_discount_in == 1) {
    net_amount -= record.discount_amount * record.qty
  } else {
    net_amount -= (price_with_tax * record.qty * record.discount) / 100
  }
  net_amount =
    net_amount +
    parseFloat(record.cess_on_qty && record.cess_on_qty_value ? record.cess_on_qty : 0) * record.qty
  return Number(net_amount)
}

export const getNetAmount_unit_price = record => {
  return (
    parseFloat(record.price) * record.qty -
    (parseFloat(record.price) * record.qty * record.discount) / 100
  )
}
export const getItemNetAmount = record => {
  let net_amount = Object.keys(record).includes('net_amount')
    ? record.net_amount
    : getNetAmount_unit_price(record)
  return Number(parseFloat(net_amount))
}

export const getPriceWithTax = record => {
  var price_with_tax =
    parseFloat(record.price) +
    parseFloat((record.price * (record.tax + (record.cess ? record.cess : 0))) / 100) +
    parseFloat(record.cess_on_qty && record.cess_on_qty_value ? record.cess_on_qty : 0)

  return price_with_tax
  // return Number(Math.round(price_with_tax + 'e2') + 'e-2')
}

export const getPrice = record => {
  var price =
    record.price_with_tax -
    parseFloat(record.cess_on_qty && record.cess_on_qty_value ? record.cess_on_qty : 0)

  price = price / (1 + (record.tax + (record.cess ? record.cess : 0)) / 100)

  return price
  // return Number(Math.round(price + 'e2') + 'e-2')
}

export const getDiscountPriceWithTaxValue = record => {
  var discount_value = (record.price_with_tax * record.discount) / 100
  return discount_value
}

export const getDiscountValue = record => {
  var discount_value = (record.price_with_tax * record.qty * record.discount) / 100
  return discount_value
}

export const getDiscountUnitPriceValue = record => {
  var discount_value = (record.price * record.discount) / 100
  return discount_value
}

export const getDiscountNetValue = record => {
  var discount_value = (record.price * record.qty * record.discount) / 100
  return discount_value
}

export const getDiscount = record => {
  var discount = (record.discount_value * 100) / (record.price_with_tax * record.qty)
  return discount
}

export const getDiscount_with_tax = (record, with_tax, name) => {
  let qty =
    name == 'discount_unit_price_value' || name == 'discount_price_with_tax_value' ? 1 : record.qty
  let w_tax = name == 'discount_unit_price_value' || name == 'discount_net_value' ? 0 : with_tax
  var discount = (record[name] * 100) / ((w_tax == 1 ? record.price_with_tax : record.price) * qty)
  return discount
}

export const getPriceWithTaxPos = record => {
  return Number(Number(record.price) + Number(record.price * (record.tax / 100)))
}

export const getTaxAmount = record => {
  var total_unit_price = getNetAmount_unit_price(record)
  var total_tax = total_unit_price * ((record.tax + (record.cess ? record.cess : 0)) / 100)
  total_tax += parseFloat(record.cess_on_qty_value ? record.cess_on_qty_value : 0)
  return Number(total_tax)
}
export const getTaxAmountV2 = record => {
  var total_unit_price = getNetAmount_unit_price(record)
  var total_tax = total_unit_price * ((record.tax + (record.cess ? record.cess : 0)) / 100)
  // total_tax += record.cess_amount

  total_tax += parseFloat(record.cess_on_qty_value ? record.cess_on_qty_value : 0)
  total_tax = Object.keys(record).includes('tax_amount') ? record.tax_amount : total_tax

  return Number(parseFloat(total_tax))
  // return Number(Math.round(total_tax + 'e2') + 'e-2')
}
export const getCessAmount = record => {
  var total_unit_price = getNetAmount_unit_price(record)
  var total_tax =
    (total_unit_price * (record.cess ? record.cess : 0)) / 100 +
    parseFloat(record.cess_on_qty_value ? record.cess_on_qty_value : 0)
  return Number(total_tax)
  // return Number(Math.round(total_tax + 'e2') + 'e-2')
}
export const getCessAmountV2 = record => {
  var total_unit_price = getNetAmount_unit_price(record)
  var total_tax = (total_unit_price * (record.cess ? record.cess : 0)) / 100
  total_tax = Object.keys(record).includes('net_amount')
    ? (parseFloat(record.net_amount) * record.cess) / 100
    : total_tax

  total_tax += parseFloat(record.cess_on_qty_value ? record.cess_on_qty_value : 0)
  return Number(total_tax)
  // return Number(Math.round(total_tax + 'e2') + 'e-2')
}

export const getCessOnQtyValue = data => {
  var sum = 0
  data.map(item => {
    sum = sum + parseFloat(item.cess_on_qty_value ? item.cess_on_qty_value : 0)
  })
  return Math.round(Number(Math.round(sum + 'e2') + 'e-2'))
}

export const getRowTotal = record => {
  return Number(getNetAmount(record))
}
export const getRowTotalV2 = record => {
  if (Object.keys(record).includes('total_amount')) {
    return Number(parseFloat(record.total_amount))
  }
  return getRowTotal(record)
}

export const getCustomRowTotal = (record, discount_type) => {
  var total = 0
  if (discount_type == 'unit_price') {
    total =
      record.price *
      (1 - record.discount / 100) *
      (1 + (record.tax + record.cess) / 100) *
      record.qty
  } else if (discount_type == 'price_with_tax') {
    total = record.price_with_tax * (1 - record.discount / 100) * record.qty
  } else {
    total = record.price_with_tax * record.qty
  }

  total = total + parseFloat(record.cess_on_qty_value ? record.cess_on_qty_value : 0)
  return total
}

export const getCustomRowNetAmount = (record, discount_type) => {
  return record.price * (1 - record.discount / 100) * record.qty
}

export const getTotal = data => {
  var sum = 0
  data.map(item => {
    sum = sum + getRowTotal(item)
  })
  return Math.round(Number(Math.round(sum + 'e2') + 'e-2'))
}

//without rounding
export const getTotal_POS = data => {
  var sum = 0
  data.map(item => {
    sum = sum + getRowTotal(item)
  })
  return Number(sum)
}

export const getTotalQty = data => {
  var sum = 0
  data.map(item => {
    sum = sum + Number(item.qty / item.conversion_rate)
  })
  return sum.toFixed(3)
}

export const extraChargesAmount = (charges, name) => {
  let result = charges.map(a => a[name] * a.type)
  if (result.length) {
    return result.reduce((previous_value, current_value) => previous_value + current_value)
  } else return 0
}

// export const getTotalQty = data => {
//   var sum = 0
//   console.log(data)
//   data.map(item => {
//     sum = sum + Number(item.qty)
//   })
//   return sum;
// }

export const getTotalDiscount = data => {
  var sum = 0
  data.map(item => {
    sum = sum + (item.qty * item.price_with_tax * item.discount) / 100
  })
  return sum
}
export const getTotalDiscountV2 = (data, discount_type) => {
  var sum = 0
  let discount_key =
    discount_type == 'total_amount'
      ? 'discount_value'
      : discount_type == 'net_amount'
      ? 'discount_net_value'
      : discount_type == 'unit_price'
      ? 'discount_unit_price_value'
      : 'discount_price_with_tax_value'

  data.map(item => {
    let qty = discount_type == 'total_amount' ? 1 : discount_type == 'net_amount' ? 1 : item['qty']
    sum = sum + (isNaN(Number(item[discount_key])) ? 0 : Number(item[discount_key])) * qty
  })
  return sum
}

export const getTotalTax = data => {
  var sum = 0
  data.map(item => {
    sum = sum + getTaxAmount(item)
  })
  return Number(sum)
}

export const getTotalNetAmount = data => {
  var sum = 0
  data.map(item => {
    sum = sum + getNetAmount_unit_price(item)
  })
  return Number(sum)
}
export const getTotalNetAmountV2 = data => {
  var sum = 0
  data.map(item => {
    let a =
      Object.keys(item).includes('net_amount') && item.net_amount != undefined
        ? Number(parseFloat(item.net_amount))
        : getNetAmount_unit_price(item)
    sum = sum + a
  })
  // console.log(sum)
  return parseFloat(Number(sum))
}
// export const getRowNetAmountV2 = record => {
//   if(Object.keys(record).includes('net_amount')){
//     return Number(parseFloat(record.net_amount))
//   }
//   return getNetAmount_unit_price(record)
// }
export const getTotalCessAmount = data => {
  var sum = 0
  data.map(item => {
    sum =
      sum +
      (item.qty * item.price * (item.cess ? item.cess : 0) * (100 - item.discount)) / (100 * 100) +
      parseFloat(item.cess_on_qty_value ? item.cess_on_qty_value : 0)
  })
  return Number(sum)
}
export const getTotalCessAmountV2 = data => {
  var sum = 0
  data.map(item => {
    let a = Object.keys(item).includes('net_amount')
      ? (item.net_amount * item.cess) / 100
      : (item.qty * item.price * item.cess * (100 - item.discount)) / (100 * 100)

    a += parseFloat(item.cess_on_qty_value ? item.cess_on_qty_value : 0)
    sum = sum + a
  })
  return Number(sum)
}
export const hashCode = str => {
  let hash = 0
  for (let i = 0, len = str.length; i < len; i++) {
    let chr = str.charCodeAt(i)
    hash = (hash << 5) - hash + chr
    hash |= 0 // Convert to 32bit integer
  }
  return hash
}

export default function calculation() {
  return <div></div>
}

export function formatNumber(num) {
  const formatter = new Intl.NumberFormat('en-US', {
    minimumFractionDigits: 1,
    maximumFractionDigits: 8,
  })
  return formatter.format(num).replace(/,/g, '')
}
