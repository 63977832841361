export const export_terms = {
  'Export under bond/LUT':
    'Supply meant for Export under bond or letter of undertaking without Payment of Integrated Tax',
  'Export with IGST': 'Supply Meant for Export on Payment of Integrated Tax',
  'SEZ with IGST Payment': 'Supply meant for SEZ with Payment of Integrated Tax',
  'SEZ without IGST Payment':
    'Supply meant for SEZ under LUT without Payment of Integrated Tax',
}
export const get_invoice_title = (doc_type, document_title, with_tax, gstn = '', item = '') => {
  if (doc_type == 'purchase_returns') {
    return 'PURCHASE RETURN/ DEBIT NOTE'
  } else if (doc_type == 'pro_forma_invoices') {
    return 'PRO FORMA INVOICE'
  } else if (doc_type == 'sales_returns') {
    return 'SALES RETURN/ CREDIT NOTE'
  } else if (doc_type == 'delivery_challans') {
    return 'DELIVERY CHALLAN'
  } else if (doc_type == 'purchase_orders') {
    return 'PURCHASE ORDER'
  } else if (doc_type == 'purchases') {
    return 'PURCHASE'
  } else if (doc_type == 'sales' || doc_type == 'pos') {
    if (document_title == 'Bill of Supply') {
      return document_title
    } else if (gstn.length == 15 && with_tax) {
      return item != 'Delivery Challan' ? 'TAX INVOICE' : 'Delivery Challan'
    } else {
      return item != 'Delivery Challan' ? 'INVOICE' : 'Delivery Challan'
    }
  } else if (doc_type == 'estimates') {
    return document_title
  }
}

export const get_invoice_type = (doc_type, item) => {
  if (doc_type == 'sales' || doc_type == 'pos') {
    return item != 'Delivery Challan' ? item : ''
  } else {
    return item
  }
}

export const getStateName = state => {
  state = state.split('-')
  if (state.length > 1) {
    state[0] = state[1]
  }
  if (state[0] == 'OTHERTERRITORY') {
    return ''
  }
  return state[0]
}

export const has_multiple_units = items => {
  let has_multiple_units = false
  items.map(item => {
    if (item.conversion_rate != 1) has_multiple_units = true
  })
  return has_multiple_units
}
