import { StyleSheet, Text, View } from '@react-pdf/renderer'
import React from 'react'

const getStyles = (FONT_FACTOR, PAPER_SIZE) => {
  return StyleSheet.create({
    container: {
      '@media max-width: 400': {
        flexDirection: 'column',
      },
      '@media min-width: 400': {
        flexDirection: 'row',
      },
      marginTop: 9,
      marginBottom: 9,
      lineHeight: 1.5,
      padding: 2,
    },

    batch_details: {
      fontSize: 7 * FONT_FACTOR,
      color: '#212020',
      display: 'block',
    },
  })
}
const ItemBatchDetails = ({ invoice, item }) => {
  var styles = getStyles(
    invoice.invoice_settings.font_size,
    invoice.invoice_settings.paper_size == 'A4' ? 1 : 0.8,
  )

  return (
    invoice.batch_settings.show_as_separate_cols == 0 && (
      <View style={styles.batch_details}>
        {item.batch_no != '' && (
          <Text>
            {item.has_batches == 2
              ? invoice.batch_settings.serial_number_label
                ? invoice.batch_settings.serial_number_label + ': \n'
                : ''
              : 'Batch No: '}{' '}
            {item.has_batches == 2 ? (
              <>
                {item.batch_no.split(',').map((sno, index) => {
                  return (
                    <Text>
                      {sno}
                      {'\n'}
                    </Text>
                  )
                })}
              </>
            ) : (
              item.batch_no
            )}
          </Text>
        )}
        {item.has_batches == 1 && item.mfg_date != '' && <Text>{'Mfg Dt: ' + item.mfg_date}</Text>}
        {item.has_batches == 1 && item.expiry_date != '' && (
          <Text>{'Exp Dt: ' + item.expiry_date}</Text>
        )}
      </View>
    )
  )
}

export default ItemBatchDetails
