import { Radio } from 'antd'
import React, { Component } from 'react'
import store from 'store'
const cash = '#e0fcee'
const tds = '#FEF7E0'
const upi = '#e5e6fc'
const card = '#fbe2fa'
const netbanking = '#fce6e0'
const paylater = '#fee7e9'
const emi = '#f2ffcf'
const cheque = '#e3fafe'
class PaymentModes extends Component {
  constructor(props) {
    super(props)
    // this.state = {paymentMethod:''}
  }
  getPaymentClass = data => {
    switch (data.toLowerCase()) {
      case 'cash':
        return 'cash'
      case 'card':
        return 'card-payment'
      case 'upi':
        return 'upi'
      case 'emi':
        return 'emi'
      case 'net banking':
        return 'netbanking'
      case 'cheque':
        return 'cheque'
      case 'tds':
        return 'tds'
      case 'tcs':
        return 'tcs'
      default:
        return 'card'
    }
  }

  render() {
    return (
      <div className="payment-modes mt-2">
        <Radio.Group
          onChange={e => {
            this.props.onChange(e)
            store.set('paymentMethod', e.target.value)
          }}
          value={this.props.value}
          disabled={this.props.disabled == true ? true : false}
        >
          {this.props.data.map((e, i) => (
            <Radio.Button
              key={i}
              value={e.value}
              className={`${this.getPaymentClass(e.value)}  ${
                this.props.value != e.value ? 'bg-gray-75' : window[this.getPaymentClass(e.value)]
              } `}
              style={{
                borderRadius: '30px',
                border: 'none',
              }}
            >
              {e.value}
            </Radio.Button>
          ))}
        </Radio.Group>
      </div>
    )
  }
}

export default PaymentModes
