import { Button, Modal } from 'antd'
import React, { forwardRef, useEffect, useImperativeHandle, useState } from 'react'
import { getAPIPDF } from 'services/jwt'
import { arrayBufferToBase64 } from './utility'
const GetPaymentDocument = forwardRef((props, ref) => {
  const [loading, setLoading] = useState(false)
  const [obj, setObj] = useState(null)
  const [serial_number, setSerialNumber] = useState(null)
  useImperativeHandle(ref, () => ({
    showModal,
  }))
  useEffect(() => {
    if (obj) {
      const oldObj = document.getElementById('paymentReceiptObject')
      if (oldObj) {
        document.getElementById('paymentpdf')?.removeChild(oldObj)
      }

      document.getElementById('paymentpdf')?.appendChild(obj)
    }
  }, [obj])
  const showModal = async (serial_number, hash_id) => {
    if (!loading) {
      setLoading(true)
      setSerialNumber(serial_number)
      let url = ''
      if (serial_number) {
        url = 'get_payment_receipt'
      } else if (hash_id) {
        url = 'get_view_payment_receipt'
      }
      const req = {
        serial_number,
        hash_id,
      }

      const data = await getAPIPDF('payments', url, req)
      if (data) {
        let base64Str = arrayBufferToBase64(data.data)
        var obj = document.createElement('object')
        obj.id = 'paymentReceiptObject'
        obj.style.width = '100%'
        obj.style.height = '842pt'
        obj.type = 'application/pdf'
        obj.data = 'data:application/pdf;base64,' + base64Str
        setObj(obj)
      }
      setLoading(false)
    }
  }
  return (
    <div>
      <Modal
        title={'Payment Receipt ' + serial_number}
        open={!!obj}
        style={{ top: 20 }}
        width={800}
        height={1024}
        onOk={() => setObj(null)}
        onCancel={() => setObj(null)}
        closeIcon={<i className="fa-solid  fa-xmark"></i>}
        footer={[
          <Button key="back" type="default" onClick={() => setObj(null)}>
            Cancel
          </Button>,
        ]}
      >
        <div id="paymentpdf" className="h-100"></div>
      </Modal>
    </div>
  )
})
export default GetPaymentDocument
