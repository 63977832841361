import { ConfigProvider, theme } from 'antd'
import React from 'react'
import { IntlProvider } from 'react-intl'
import { connect } from 'react-redux'

import english from './locales/english'
import hindi from './locales/hindi'
import telugu from './locales/telugu'
// import tamil from './locales/zh-CN'

const locales = {
  English: english,
  Hindi: hindi,
  Telugu: telugu,
  // 'Tamil': tamil,
}

const mapStateToProps = ({ settings }) => ({ settings })

const Localization = ({ children, settings }) => {
  const currentLocale = locales[settings.locale]
  return (
    <ConfigProvider
      locale={currentLocale.localeAntd}
      theme={{
        algorithm: settings.theme == 'default' ? theme.defaultAlgorithm : theme.darkAlgorithm,
        // components: {
        //   select: {
        //     optionActiveBg: '#f5f5f7',
        //     optionSelectedBg: '#2754ff',
        //     algorithm: true,
        //   },
        //   table: {
        //     headerBg: '#2754ff',
        //     cellFontSize: 25,
        //     algorithm: true,
        //   },
        // },
        token:
          settings.theme == 'default'
            ? {
                colorPrimary: '#2754ff',
                colorInfo: '#2754ff',
                colorError: '#e11900',
                colorTextBase: '#000000',
                colorBorder: '#e8e8ed', //e8e8ed
                colorBorderSecondary: '#F4F4F9',
                colorBgSpotlight: '#1d1d1f',
                //colorBorderSecondary: '#f7fafc',
                boxShadowSecondary: '0 0 9px rgba(0,0,0,0.1)',
                colorText: '#1d1d1f',
                colorTextSecondary: '#6e6e73',
                colorTextTertiary: '#86868b',
                colorTextQuaternary: '#AEAEB2',
                colorBgLayout: '#f5f5f7',
                fontSize: 13.5,
                fontFamily: '$base-font-family',
              }
            : {
                colorPrimary: '#5276ff',
                colorInfo: '#5276ff',
                colorError: '#e11900',
                // colorTextBase: '#1d1d1f',
                // colorBorder: '#E8E8ED',
                // colorBorderSecondary: '#f7fafc',
                boxShadowSecondary: '0 0 9px rgba(0,0,0,0.1)',
                // colorText: '#1d1d1f',
                // colorTextSecondary: '#6e6e73',
                // colorTextTertiary: '#86868b',
                // colorTextQuaternary: '#AEAEB2',
                // colorBgLayout: '#f5f5f7',
                colorBgContainer: '#212121',
                colorBgSpotlight: '#2D2D2E',
                colorTextLightSolid: '#f5f5f7',
                colorBgElevated: '#2D2D2E',
                fontSize: 13.5,
                fontFamily: '$base-font-family',
                components: {
                  Card: {},
                },
              },
      }}
    >
      <IntlProvider locale={currentLocale.locale} messages={currentLocale.messages}>
        {children}
      </IntlProvider>
    </ConfigProvider>
  )
}

export default connect(mapStateToProps)(Localization)
