import { Button, Checkbox, Col, DatePicker, Modal, Row } from 'antd'
import ShowDateString from 'components/other/dateString'
import dayjs from 'dayjs'
import React, { forwardRef, useImperativeHandle, useState } from 'react'
import { FormattedMessage } from 'react-intl'
import { ranges } from 'services/axios'
const { RangePicker } = DatePicker

const PartyLedgerOptions = forwardRef((props, ref) => {
  const [visible, setVisible] = useState(false)
  const [showPendingDocs, setShowPendingDocs] = useState(false)
  const [showCustomDateRange, setShowCustomDateRange] = useState(false)
  const [page, setPage] = useState(0)
  const [numRecords, setNumRecords] = useState(10)
  const dateFormat = 'DD-MM-YYYY'
  const { type } = props
  const [isLoading, setIsLoading] = useState(false)
  const [dates, setDates] = useState(
    dayjs()
      .month(0)
      .startOf('month')
      .format(dateFormat) +
      ' - ' +
      dayjs()
        .month(11)
        .endOf('month')
        .format(dateFormat),
  )

  const showShareModal = (show_pending_docs, num_records, page, show_custom_date_range) => {
    setVisible(true)
    setShowPendingDocs(show_pending_docs ? show_pending_docs : false)
    setNumRecords(num_records)
    setPage(page)
    setShowCustomDateRange(show_custom_date_range ? show_custom_date_range : false)
  }
  useImperativeHandle(ref, () => ({
    openShareModal: (show_pending_docs, num_records, page) =>
      showShareModal(show_pending_docs, num_records, page),
  }))
  const onClose = () => {
    setVisible(false)
  }

  return (
    <>
      <Modal
        visible={visible}
        onCancel={onClose}
        title="Ledger Settings"
        footer={
          <div>
            <Button type="danger" className="" onClick={onClose}>
              Close
            </Button>
            <Button
              className="ml-3"
              onClick={async () => {
                setVisible(false)
                setIsLoading(true)
                await props.callData(page, numRecords)
                props.changeShareVisible()
                // setVisible(false)
              }}
            >
              <i class="fa-regular fa-paper-plane fa-fw mr-2"></i>
              <span className="d-none d-sm-inline font-weight-bold">
                <FormattedMessage id="button.share" />
              </span>
            </Button>
          </div>
        }
      >
        <Row gutter={24}>
          <Col span={24}>
            {type == 'customer' && (
              <div className="mb-2 ">
                <Checkbox.Group>
                  <Checkbox
                    onChange={e => {
                      setShowPendingDocs(e.target.checked)
                      props?.changePendingDocs(e.target.checked)
                    }}
                    className="mt-4 text-gray-600"
                    checked={showPendingDocs}
                  >
                    Share Pending {type == 'customer' ? 'Invoices' : 'Purchases'} Only
                  </Checkbox>
                  <div className="ml-4 pl-2 text-gray-400 font-size-12">
                    Display only pending {type == 'customer' ? 'Invoices' : 'Purchases'} those are
                    not yet settled.
                  </div>
                </Checkbox.Group>
              </div>
            )}
          </Col>
          <Col span={24}>
            <Checkbox.Group>
              <Checkbox
                onChange={e => {
                  setShowCustomDateRange(e.target.checked)
                  props?.changeCustomDateRange(e.target.checked)
                }}
                className="mt-4 text-gray-600"
                checked={showCustomDateRange}
              >
                Share Records in a Custom Date Range
              </Checkbox>
              <div className="ml-4 pl-2 mb-4 text-gray-400 font-size-12">
                Only data of selected date range will be shown to the party.
              </div>
            </Checkbox.Group>
          </Col>
          {showCustomDateRange && (
            <Col span={24} className="mt-2">
              <RangePicker
                allowClear={false}
                size=""
                ranges={ranges}
                format={dateFormat}
                value={
                  dates != ''
                    ? [
                        dayjs(dates.split(' - ')[0], dateFormat),
                        dayjs(dates.split(' - ')[1], dateFormat),
                      ]
                    : ''
                }
                onChange={(dates, dateStrings) => {
                  setDates(dateStrings[0] + ' - ' + dateStrings[1])
                  props?.changeDates(dateStrings[0] + ' - ' + dateStrings[1])
                }}
              />
              <ShowDateString dateString={dates} showInputWidth={false} />
            </Col>
          )}
        </Row>
      </Modal>
    </>
  )
})

export default PartyLedgerOptions
