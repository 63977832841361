import { Text, View } from '@react-pdf/renderer'
import React from 'react'
import { styles as getStyles } from './styles'

const InvoiceTitle = ({ title, type, color, invoice }) => {
  var styles = getStyles(invoice)

  return (
    <>
      <View style={styles.invoiceTitle_row}>
        <Text style={{ ...styles.invoiceTitle_labels, color: color }}>{title}</Text>
        <Text style={[styles.invoiceTitle_info]}>{type}</Text>
      </View>
      {invoice.company.is_composite == 1 &&
        (invoice.document_type == 'sales' || invoice.document_type == 'pos') && (
          <Text style={[styles.invoiceTitle_dec]}>
            Composition taxable person, not eligible to collect tax on supplies
          </Text>
        )}
    </>
  )
}

export default InvoiceTitle
