import { PlusOutlined } from '@ant-design/icons'
import {
  Button,
  Card,
  Col,
  Drawer,
  Form,
  Input,
  InputNumber,
  Modal,
  Row,
  Select,
  Space,
  Switch,
  Table,
  Upload,
  message,
  notification,
  AutoComplete,
  Tag,
} from 'antd'
import BetaBadge from 'components/badges/beta'
import NewBadge from 'components/badges/new'
import ProductBarcode from 'components/modal/barcode'
import { EnhancedSubscriptionModal } from 'components/modal/subscription'
import { hashCode } from 'components/other/calculation'
import SectionHeader from 'components/other/sectionHeader'
import { formItemLayout, getBase64 } from 'components/other/utility'
import CustomColumnsForm from 'components/forms/customColumns'
import MyEditor from 'components/other/editor'
import { EditorState } from 'draft-js'
import { stateToHTML } from 'draft-js-export-html'
import { stateFromHTML } from 'draft-js-import-html'
import React, { Component } from 'react'
import { isMobile } from 'react-device-detect'
import { FormattedMessage } from 'react-intl'
import { connect } from 'react-redux'
import { getAPI, getAPIData } from 'services/jwt'
import SubscriptionPlanModal from 'components/modal/subscription/subscriptionPlan'
import store from 'store'
import { set_custom_columns, set_product_options } from 'redux/document/actions'
import ProductPriceList from 'components/PriceList'
import { setCompanyDetails } from 'redux/permissions/actions'

const { TextArea } = Input
const { Option } = Select

const emptyData = {
  name: '',
  qty: 0,
  price: '',
  barcode_id: '',
  show_online: true,
  discount: 0,
  order_num: 1,
  is_price_with_tax: true,
  price_with_tax: 0,
  purchase_price: 0,
  purchase_unit_price: 0,
  tax: 0,
  cess: 0,
  type: '',
  unit_id: 0,
  discountAmount: 0,
  show_discount_in: 0,
  opening_purchase_price: 0,
  opening_qty: 0,
  opening_value: 0,
  has_batches: 0,
}

const options = { maximumFractionDigits: 2, minimumFractionDigits: 2 }
export class VariantForm extends Component {
  // formRef = React.createRef()
  priceListForm = React.createRef()
  formRefEditVariant = React.createRef()
  subscriptionPlanRef = React.createRef()
  constructor(props) {
    super(props)
    this.state = {
      visible: false,
      product_name: '',
      dataSource: [],
      data: '',
      selectedData: '',
      visibleEditVariantForm: false,
      formData: { categories: undefined, product_types: [], taxes: [] },
      companyDetails: { gstin: '' },
      price_lists: [],
      item_prices: [],
      edit: false,
      primary_unit: '',
      price_list_drawer: false,
      fileList: [],
      previewImage: '',
      previewVisible: false,
      previewTitle: '',
      loading: false,
      is_outer: false,
      is_price_with_tax: true,
      discount: 0,
      discountAmount: 0,
      show_discount_in: 0,
      price_with_tax: 0,
      purchase_price: 0,
      purchase_unit_price: 0,
      tax: 0,
      cess: 0,
      is_purchase_price_with_tax: false,
      has_batches: 0,
      batch_option_disabled: false,
      not_for_sale: false,
      editorState: EditorState.createEmpty(),
    }
  }

  async componentDidMount() {
    this.props.onRef(this)
    const data = await getAPIData('product', 'options')
    if (data) {
      this.props.dispatch(set_product_options(data))
      this.props.dispatch(setCompanyDetails(data.company_details))
      this.setState({
        formData: data,
        companyDetails: data.company_details,
        price_lists: data.price_lists,
      })
    }
  }
  generateBarCode = () => {
    var barcode = hashCode(Math.floor(Date.now() / 1000).toString())
    if (barcode < 0) {
      barcode = '1' + barcode
    } else {
      barcode = '' + barcode
    }
    // this.formRef.current?.setFieldsValue({ barcode_id: barcode })
    this.formRefEditVariant.current?.setFieldsValue({ barcode_id: barcode })
  }

  onFinish = async values => {
    this.setState({ loading: true })
    var form_data = new FormData()
    values = {
      ...values,
      price_with_tax: values.price_with_tax || 0,
      is_price_with_tax: this.state.is_price_with_tax,
      is_purchase_price_with_tax: this.state.is_purchase_price_with_tax,
      discount: this.state.discount,
      discount_amount: this.state.discountAmount,
      purchase_price: this.state.purchase_price,
      purchase_unit_price: this.state.purchase_unit_price,
      has_batches: this.state.has_batches,
      not_for_sale: this.state.not_for_sale,
      show_discount_in: this.state.show_discount_in ?? 0,
    }
    if (values.purchase_price == null || isNaN(values.purchase_price)) {
      values.purchase_price = 0
    }
    if (values.purchase_unit_price == null || isNaN(values.purchase_unit_price)) {
      values.purchase_unit_price = 0
    }
    if (
      isNaN(values.opening_purchase_price) ||
      values.opening_purchase_price == null ||
      values.opening_purchase_price == ''
    ) {
      values.opening_purchase_price = 0
    }
    if (isNaN(values.opening_value) || values.opening_value == null || values.opening_value == '') {
      values.opening_value = 0
    }

    if (isNaN(values.opening_qty) || values.opening_qty == null || values.opening_qty == '') {
      values.opening_qty = 0
    }
    let custom_fields = {}
    this.props.custom_columns_active?.map(item => {
      if (item.applicable_for == 'variant') {
        if (item.field_type == 'date' || item.field_type == 'datetime') {
          custom_fields[this.props.column_id_dict[item.name]] = values[item.name]
            ? values[item.name].format(
                item.field_type == 'date' ? this.dateFormat : 'DD-MM-YYYY HH:mm:ss',
              )
            : ''
        } else {
          values[item.name] = values[item.name] ? values[item.name] : ''
          custom_fields[this.props.column_id_dict[item.name]] = values[item.name]
        }
      }
    })
    values.custom_fields = JSON.stringify(custom_fields)
    var req =
      this.state.type == 'edit'
        ? {
            ...values,
            product_id: this.state.selectedData.product_id,
            company_id: this.state.selectedData.company_id,
            show_online: values.show_online == true ? 1 : 0,
            id: this.state.selectedData.id,
          }
        : {
            ...values,
            product_id: this.state.data.product_id,
            company_id: this.state.data.company_id,
            show_online: values.show_online == true ? 1 : 0,
          }

    //iterate object and set to formdata
    Object.keys(req).forEach(key => {
      form_data.append(key, req[key])
    })
    form_data.set('description', stateToHTML(this.state.editorState.getCurrentContent()))

    if ('file' in values) {
      var file = values['file']
      if (file != undefined) {
        await Promise.all(
          values['file']['fileList'].map(item =>
            (async _ => {
              if ('url' in item) {
                //do nothing
              } else {
                form_data.append('files[]', item.originFileObj)
              }
              return
            })(),
          ),
        )
      }
    }

    var data =
      this.state.type == 'edit'
        ? await getAPI('product', 'edit_variant', form_data)
        : await getAPI('product', 'add_variant', form_data)
    if (data.success) {
      this.formRefEditVariant.current.resetFields()
      this.state.type == 'edit'
        ? message.success('Variant Updated')
        : message.success('Variant Added')
      req.unit_price = parseFloat((req.price_with_tax * 100) / (100 + parseFloat(req.tax)))
      var newDataSource = this.state.dataSource.map(u => (u.id !== req.id ? u : req))
      this.setState({
        dataSource: newDataSource,
        visibleEditVariantForm: false,
        loading: false,
      })
      this.handleDone()
    }
    this.setState({ loading: false })
  }

  handleDelete = async (id, company_id, product_id) => {
    var req = {
      id: id,
      company_id: company_id,
      product_id,
    }
    var data = await getAPI('product', 'delete_variant', req)
    if (data.success) {
      const newData = this.state.dataSource.filter(item => item.id !== id)
      this.setState({ dataSource: newData }, () => {
        message.success('Variant Deleted')
      })
    }
    this.handleDone()
  }

  handleDone = () => {
    this.props.onAPICalled()
    this.setState({ visible: false, is_outer: false })
  }

  handleEdit = async data => {
    const type = Object.keys(data).length > 0 ? 'edit' : 'add'
    this.setState(
      {
        visibleEditVariantForm: true,
        selectedData: {
          ...data,
          price_with_tax: data.is_price_with_tax ? data.price_with_tax : data.price,
        },
        editorState:
          type == 'add'
            ? EditorState.createEmpty()
            : EditorState.createWithContent(
                stateFromHTML(data.description) || EditorState.createEmpty(),
              ),
        type: type,
        fileList: data.variant_images,
        price_with_tax: data.is_price_with_tax ? data.price_with_tax : data.price,
        has_batches: type == 'add' ? 0 : data.has_batches,
        batch_option_disabled: type == 'edit' ? data.has_batches != 0 : false,
        not_for_sale: data.not_for_sale,
      },
      async () => {
        this.state.type == 'add' && this.formRefEditVariant.current.setFieldsValue(emptyData)
        if (type == 'edit') {
          var qty = data.qty
          let unit = this.state.data.units.filter(u => u.unit_id == data.unit_id)

          if (unit.length > 0) {
            qty = qty * unit[0].conversion_rate
          }
        }
        this.formRefEditVariant.current.setFieldsValue({
          ...data,
          purchase_unit_price: data['purchase_unit_price'],
          tax: this.state.tax,
          cess: this.state.cess,
          qty: qty,
          discount: data.show_discount_in ? data.discount_amount : data.discount,
          description: this.state.editorState.getCurrentContent(),
        })
      },
    )
  }

  onEditClick = async (data, product) => {
    const type = Object.keys(data).length > 0 ? 'edit' : 'add'
    this.setState(
      {
        visibleEditVariantForm: true,
        selectedData: {
          ...data,
          price_with_tax: data.is_price_with_tax ? data.price_with_tax : data.price,
        },
        type: type,
        fileList: data.variant_images,
        data: product,
        tax: product.tax,
        cess: product.cess,
        show_discount_in: data.show_discount_in,
        is_outer: true,
        product_name: product.product_name,
        primary_unit: product.unit,
        is_price_with_tax: data.is_price_with_tax != undefined ? data.is_price_with_tax : true,
        is_purchase_price_with_tax: data.is_purchase_price_with_tax == 1,
        price_with_tax: data.is_price_with_tax ? data.price_with_tax : data.price,
        discount: data.discount,
        discountAmount: data.discount_amount,
        purchase_price: data.purchase_price,
        purchase_unit_price: data.purchase_unit_price,
        has_batches: product.product_type == 'Service' || type == 'add' ? 0 : data.has_batches,
        batch_option_disabled: type == 'edit' ? data.has_batches != 0 : false,
        not_for_sale: data.not_for_sale,
        editorState:
          type == 'add'
            ? EditorState.createEmpty()
            : EditorState.createWithContent(stateFromHTML(data.description)),
      },
      async () => {
        this.state.type == 'add' && this.formRefEditVariant.current.setFieldsValue(emptyData)
        if (type == 'edit') {
          var qty = data.qty
          let unit = this.state.data.units.filter(u => u.unit_id == data.unit_id)

          if (unit.length > 0) {
            qty = qty * unit[0].conversion_rate
          }
        }

        if (data.purchase_unit_price == undefined) {
          data.purchase_price = 0
        }
        let custom_fields = {}
        data.custom_fields?.map(item => {
          if (item.value == '') {
            item.value = undefined
          }
          if (item.field_type == 'date' || item.field_type == 'datetime') {
            const dateFormatRegex = /^\d{2}-\d{2}-\d{4}/
            if (item.value && !dateFormatRegex.test(item.value)) {
              item.value = undefined
            } else {
              item.value = item.value ? dayjs(item.value, 'DD-MM-YYYY') : undefined
            }
          }
          custom_fields[item.name] = item.value
        })

        //PREFILL PRODUCT CUSTOM FIELDS
        // if (type == 'add') {
        //   product.custom_fields?.map(item => {
        //     if (item.value == '') {
        //       item.value = undefined
        //     }
        //     if (item.field_type == 'date' || item.field_type == 'datetime') {
        //       const dateFormatRegex = /^\d{2}-\d{2}-\d{4}/
        //       if (item.value && !dateFormatRegex.test(item.value)) {
        //         item.value = undefined
        //       } else {
        //         item.value = item.value ? dayjs(item.value, 'DD-MM-YYYY') : undefined
        //       }
        //     }
        //     custom_fields[item.name] = item.value
        //   })
        // }

        this.formRefEditVariant.current.setFieldsValue({
          ...data,
          ...custom_fields,
          purchase_unit_price: this.state.is_purchase_price_with_tax
            ? data.purchase_price
            : data.purchase_unit_price,
          price_with_tax: data.is_price_with_tax ? data.price_with_tax : data.price,
          discount: data.show_discount_in ? data.discount_amount : data.discount,
          tax: this.state.tax,
          cess: this.state.cess,
          qty: qty,
          description: this.state.editorState.getCurrentContent(),
        })
      },
    )
  }

  handlePreview = async file => {
    if (!file.url && !file.preview) {
      file.preview = await getBase64(file.originFileObj)
    }

    this.setState({
      previewImage: file.url || file.preview,
      previewVisible: true,
      previewTitle: file.name || file.url.substring(file.url.lastIndexOf('/') + 1),
    })
  }

  handleChange = ({ fileList }) => {
    this.setState({ fileList: fileList })
  }
  handleRemoveProductImage = async file => {
    if (this.state.selectedData.product_id && file.url) {
      const req = {
        product_id: this.state.selectedData.product_id,
        variant_id: this.state.selectedData.id,
        id: file.uid,
        url: file.url,
      }
      const data = await getAPI('product', 'delete_product_image', req)
      if (data.success) {
        message.success('Image deleted successfully')
      } else {
        message.error('Something went wrong! Try again later')
      }
    }
  }

  setDiscountAmount(value) {
    if (this.state.price_with_tax) {
      if (!this.state.show_discount_in) {
        this.setState({
          discount: value,
          discountAmount: ((value * this.state.price_with_tax) / 100).toFixed(2),
        })
      } else {
        this.setState({
          discount: ((value / this.state.price_with_tax) * 100).toFixed(2),
          discountAmount: value,
        })
      }
    }
  }

  purchasePriceChange = e => {
    let value = parseFloat(e.target.value)
    let purchase_price = 0
    let purchase_unit_price = 0
    let tax = this.state.tax + this.state.cess
    if (this.state.is_purchase_price_with_tax) {
      purchase_price = e.target.value
      purchase_unit_price = parseFloat((value / (1 + tax / 100)).toFixed(2))
    } else {
      purchase_unit_price = e.target.value
      purchase_price = parseFloat((value * (1 + tax / 100)).toFixed(2))
    }
    this.setState(
      {
        purchase_unit_price: purchase_unit_price,
        purchase_price: purchase_price,
      },
      () => {
        purchase_price = this.state.is_purchase_price_with_tax
          ? this.state.purchase_price
          : this.state.purchase_unit_price

        this.formRefEditVariant.current.setFieldsValue({
          purchase_unit_price: purchase_price,
        })
      },
    )
  }

  getSuggestedColumns = async () => {
    const data = await getAPIData('v2/custom_fields', 'item')
    if (data.success) {
      this.props.dispatch(
        set_custom_columns(
          data.custom_columns.map(item => {
            return {
              ...item,
              is_active: item.default_key != '' ? false : item.is_active,
              show_in_pdf: item.default_key != '' ? false : item.show_in_pdf,
            }
          }),
        ),
      )
    }
  }

  onChangeInventory = e => {
    notification.destroy()
    if (
      ((this.state.selectedData.qty < 0 && e == 1) ||
        (this.state.selectedData.qty != 0 && e == 2)) &&
      this.state.type == 'edit'
    ) {
      notification.open({
        type: 'error',

        message: 'Error',
        description:
          e == 1
            ? 'The current stock of the product is in negative. Please Adjust the stock to 0 or positive and try again.'
            : 'The Current stock of the product is not 0. Please Adjust the stock to 0 and try again.',
        duration: 30,
      })
      return false
    }
    if (e == 1 && this.state.type == 'edit' && this.state.selectedData.qty > 0) {
      notification.open({
        type: 'warning',

        message: 'Warning',
        description:
          'The Current Stock will be automatically added in one batch and it will be added to your product batches list on selecting this option.',
        duration: 30,
      })
    }

    this.setState({
      has_batches: e,
    })
  }
  renderCustomColumnOptions = header_id => {
    let options = []
    this.props.custom_column_suggestions?.[header_id]?.map((item, index) => {
      options.push({
        value: item,
        label: (
          <div
            style={{
              display: 'flex',
              justifyContent: 'space-between',
            }}
          >
            {item}
          </div>
        ),
      })
    })
    return options
  }
  render() {
    const uploadButton = (
      <div>
        <PlusOutlined />
        <div style={{ marginTop: 8 }}>Upload</div>
      </div>
    )

    let batchOptions = [
      {
        title: 'Default',
        key: '0',
        value: 0,
        hidden: !this.props.integrations.batching && !this.props.integrations.serial_number,
      },
      {
        title: 'Batches',
        key: '1',
        value: 1,
        hidden: !this.props.integrations.batching,
      },
      {
        title: 'Serial Number or IMEI',
        key: '2',
        value: 2,
        hidden: !this.props.integrations.serial_number,
      },
    ]
    batchOptions = batchOptions.filter(item => !item.hidden)

    return (
      <>
        <Drawer
          title={
            <>
              <div className="d-flex align-items-center">
                <div className="mr-auto">
                  <span className="font-weight-bolder text-gray-900 font-size-21 mr-2">
                    {this.state.type == 'add' ? 'Add' : 'Edit'} Variant
                  </span>
                </div>
              </div>
            </>
          }
          extra={
            <Space>
              <Button
                type="primary"
                onClick={() => this.formRefEditVariant.current.submit()}
                loading={this.state.loading}
              >
                {this.state.type == 'add' ? 'Add Variant' : 'Update'}
              </Button>
            </Space>
          }
          footer={
            <Button
              type="primary"
              onClick={() => this.formRefEditVariant.current.submit()}
              loading={this.state.loading}
            >
              {this.state.type == 'add' ? 'Add Variant' : 'Update'}
            </Button>
          }
          width={isMobile ? '100%' : '50%'}
          closable={true}
          onClose={() =>
            this.setState(
              {
                visibleEditVariantForm: false,
                has_batches: 0,
                batch_option_disabled: false,
              },
              () => this.formRefEditVariant.current.resetFields(),
            )
          }
          closeIcon={<i className="fa-solid fa-lg fa-xmark"></i>}
          open={this.state.visibleEditVariantForm}
        >
          <Form
            {...formItemLayout}
            labelAlign="left"
            ref={this.formRefEditVariant}
            name="variantForm"
            onFinish={this.onFinish}
          >
            <SectionHeader title="Basic Details" />
            <Card className="mb-4">
              <Row gutter={18}>
                <Col span={24}>
                  <Form.Item
                    name="name"
                    label="Name"
                    rules={[{ required: true, message: 'Please fill variant name' }]}
                  >
                    <Input placeholder="Enter Variant Name" autoFocus />
                  </Form.Item>
                </Col>
                {(this.props.integrations.batching == 1 ||
                  this.props.integrations.serial_number == 1) &&
                  this.state.data.product_type == 'Product' && (
                    <Col span={24} clas>
                      <Form.Item
                        name="has_batches"
                        label={
                          <>
                            Manage Inventory Using
                            <BetaBadge />
                          </>
                        }
                        extra={
                          this.state.batch_option_disabled
                            ? 'Your Inventory is managed using above selected option and it cannot be changed once updated.'
                            : ''
                        }
                      >
                        {/* <Radio.Group
                            name="radiogroup"
                            defaultValue={0}
                            optionType="default"
                            value={this.state.has_batches}
                            onChange={e => {
                              this.onChangeInventory(e)
                            }}
                            disabled={this.state.batch_option_disabled}
                          >
                            {batchOptions.map((e, i) => (
                              <Radio key={i} value={e.value}>
                                {e.title}
                              </Radio>
                            ))} */}
                        {this.props.integrations.batching == 1 && (
                          <Switch
                            disabled={this.state.batch_option_disabled}
                            checked={this.state.has_batches == 1}
                            checkedChildren="Batches"
                            unCheckedChildren="Batches"
                            onChange={checked => this.onChangeInventory(checked ? 1 : 0)}
                            className="mr-3"
                          />
                        )}
                        {this.props.integrations.serial_number == 1 && (
                          <Switch
                            disabled={this.state.batch_option_disabled}
                            checkedChildren="Serial Number/ IMEI"
                            unCheckedChildren="Serial Number/ IMEI"
                            checked={this.state.has_batches == 2}
                            onChange={checked => this.onChangeInventory(checked ? 2 : 0)}
                          />
                        )}

                        {/* </Radio.Group> */}
                      </Form.Item>
                    </Col>
                  )}
                {this.state.has_batches != 1 && (
                  <Col span={12}>
                    <Form.Item
                      name="price_with_tax"
                      label={
                        <>
                          <span className="mr-2">Selling Price</span>
                          {this.state.companyDetails.gstin.length == 15 && (
                            <Switch
                              onChange={e =>
                                this.setState({ is_price_with_tax: e }, () => {
                                  this.setDiscountAmount(
                                    this.state.show_discount_in
                                      ? this.state.discountAmount
                                      : this.state.discount,
                                  )
                                })
                              }
                              checked={this.state.is_price_with_tax}
                              checkedChildren="with Tax"
                              unCheckedChildren="without Tax"
                              size="small"
                            />
                          )}
                        </>
                      }
                    >
                      <Input
                        placeholder="100"
                        type="number"
                        style={{ borderRadius: '5px', fontSize: '1.08rem', fontWeight: 700 }}
                        addonBefore={this.props.user.selectedCompany.currency_symbol}
                        onChange={e => {
                          this.setState({ price_with_tax: e.target.value }, () => {
                            this.setDiscountAmount(
                              this.state.show_discount_in
                                ? this.state.discountAmount
                                : this.state.discount,
                            )
                          })
                        }}
                      />
                    </Form.Item>
                  </Col>
                )}
                {this.state.companyDetails.gstin.length == 15 && (
                  <Col span={12}>
                    <Form.Item name="tax" label="Tax">
                      <Select
                        disabled={true}
                        showSearch
                        className="font-weight-bold"
                        placeholder="Select Tax"
                        optionFilterProp="children"
                        filterOption={(input, option) =>
                          option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                        }
                        onChange={e => {
                          this.formRefEditVariant.current.setFieldsValue({
                            purchase_price:
                              (this.formRefEditVariant.current.getFieldValue(
                                'purchase_unit_price',
                              ) *
                                e) /
                                100 +
                              this.formRefEditVariant.current.getFieldValue('purchase_unit_price'),
                          })
                          this.setState({ tax: parseFloat(e) })
                        }}
                      >
                        {this.state.formData.taxes.map((e, i) => (
                          <Option key={i} value={e}>
                            {e}
                          </Option>
                        ))}
                      </Select>
                    </Form.Item>
                  </Col>
                )}
                <Col span={12}>
                  <Form.Item
                    name="unit_id"
                    label="Unit"
                    extra={
                      this.state.primary_unit == '' ||
                      this.state.primary_unit == 'NA' ||
                      this.state.primary_unit == 'OTH'
                        ? 'Please add unit for parent product to select unit.'
                        : ''
                    }
                  >
                    <Select
                      placeholder="Select Unit"
                      defaultActiveFirstOption={true}
                      defaultValue={0}
                      disabled={
                        this.state.primary_unit == '' ||
                        this.state.primary_unit == 'NA' ||
                        this.state.primary_unit == 'OTH'
                      }
                    >
                      {this.state.data?.units?.map((item, index) => (
                        <Option key={index} value={item.unit_id}>
                          {item.alternative_unit}{' '}
                          {this.state.primary_unit != '' &&
                          this.state.primary_unit != item.alternative_unit
                            ? ` - (1 ${this.state.primary_unit} = ${item.conversion_rate} ${item.alternative_unit})`
                            : ''}
                        </Option>
                      ))}
                    </Select>
                  </Form.Item>
                </Col>
                <Col span={24}>
                  <Form.Item
                    name="description"
                    label={<span>Description</span>}
                    extra="This description will be applied to all documents if it is not empty; otherwise, the description from the parent product will be used."
                  >
                    {this.state.editorState ? (
                      <MyEditor
                        editorState={this.state.editorState}
                        onChange={(text, editorState) =>
                          this.setState({ editorState: editorState })
                        }
                      />
                    ) : (
                      <span>Loading...</span>
                    )}
                  </Form.Item>
                </Col>
              </Row>
            </Card>
            {this.props.custom_columns_active.length > 0 &&
              this.props.integrations?.variant_custom_columns == 1 && (
                <>
                  <div
                    className={`d-flex justify-content-between ${
                      this.state.has_batches != 0 ? 'mb-2' : ''
                    }`}
                  >
                    <div>
                      <SectionHeader title="Custom Fields" optional={true} />
                    </div>
                  </div>
                  <Card bordered={false} size="form" className="border-radius-small mb-4">
                    <Row gutter={18}>
                      {this.props.custom_columns_active?.map(
                        (item, index) =>
                          (item.applicable_for == 'variant' ||
                            (this.state.has_batches == 0 && item.applicable_for == 'batch')) && (
                            <Col span={12}>
                              <Form.Item name={item.name} key={index} label={item.name}>
                                {item.field_type == 'number' || item.field_type == 'currency' ? (
                                  <InputNumber style={{ width: '100%' }} placeholder={item.name} />
                                ) : item.field_type == 'date' ? (
                                  <DatePicker
                                    style={{ width: '100%' }}
                                    placeholder={item.name}
                                    format={this.dateFormat}
                                  />
                                ) : item.field_type == 'datetime' ? (
                                  <DatePicker
                                    showTime
                                    style={{ width: '100%' }}
                                    placeholder={item.name}
                                    format={this.dateTimeFormat}
                                  />
                                ) : item.field_type == 'bigtext' ? (
                                  <TextArea
                                    placeholder={item.name}
                                    type="text"
                                    rows={1}
                                    className="pr-4 mr-2"
                                    style={{ width: '100%', borderRadius: '5px' }}
                                  />
                                ) : (
                                  <AutoComplete
                                    popupClassName="certain-category-search-dropdown"
                                    popupMatchSelectWidth={250}
                                    style={{
                                      width: '100%',
                                    }}
                                    placeholder={item.name}
                                    options={
                                      this.renderCustomColumnOptions(item.id).length > 0
                                        ? [
                                            {
                                              label: <span>Recently Used </span>,
                                              options: [...this.renderCustomColumnOptions(item.id)],
                                            },
                                          ]
                                        : []
                                    }
                                  ></AutoComplete>
                                )}
                              </Form.Item>
                            </Col>
                          ),
                      )}
                    </Row>
                    <Button
                      type="secondary"
                      className=""
                      onClick={() => {
                        store.get('paid') == 0
                          ? this.subscriptionPlanRef.current.openModal('custom_fields')
                          : this.custom_column.onAddFromProducts()
                      }}
                    >
                      <i className="fa-sharp fa-solid fa-circle-plus mr-2" />
                      <span className="font-weight-bold">Add Custom Fields</span>
                    </Button>
                  </Card>
                </>
              )}

            <SectionHeader title="Additional Information" optional={true} />
            <Card className="mb-4">
              <Row gutter={18}>
                <Col span={12}>
                  <Form.Item name="barcode_id" label="Barcode">
                    <Input
                      placeholder="2273546838467"
                      addonAfter={
                        <span style={{ cursor: 'pointer' }} onClick={this.generateBarCode}>
                          Generate Barcode
                        </span>
                      }
                    />
                  </Form.Item>
                </Col>
                <Col span={12}>
                  <Form.Item
                    name="show_online"
                    label="Show in Online Store"
                    valuePropName="checked"
                  >
                    <Switch checkedChildren="Show" unCheckedChildren="Don't show" />
                  </Form.Item>
                </Col>

                {this.state.has_batches != 1 && (
                  <Col span={12}>
                    <Form.Item label="Purchase Price" name="purchase_unit_price">
                      <Input
                        type="number"
                        addonAfter={
                          <Select
                            style={{ width: 120 }}
                            onChange={e => {
                              this.setState({ is_purchase_price_with_tax: e }, () => {
                                let purchase_price = this.state.is_purchase_price_with_tax
                                  ? this.state.purchase_price
                                  : this.state.purchase_unit_price

                                this.formRefEditVariant.current.setFieldsValue({
                                  purchase_unit_price: purchase_price,
                                })
                              })
                            }}
                            value={this.state.is_purchase_price_with_tax}
                          >
                            <Option value={true}>with Tax</Option>
                            <Option value={false}>without Tax</Option>
                          </Select>
                        }
                        className="mr-2"
                        onChange={this.purchasePriceChange}
                      />
                    </Form.Item>
                  </Col>
                )}
                <Col span={12}>
                  <Form.Item label="Discount" name="discount" className="">
                    <Input
                      type="number"
                      addonAfter={
                        <Select
                          style={{ width: 150 }}
                          onChange={e => {
                            this.setState({ show_discount_in: e }, () => {
                              let discount = this.state.show_discount_in
                                ? this.state.discountAmount
                                : this.state.discount

                              this.formRefEditVariant.current.setFieldsValue({
                                discount: discount,
                              })
                            })
                          }}
                          value={this.state.show_discount_in}
                        >
                          <Option value={0}>Percentage (%)</Option>
                          <Option value={1}>
                            Amount ({this.props.user.selectedCompany.currency_symbol})
                          </Option>
                        </Select>
                      }
                      className="mr-2"
                      onChange={e => this.setDiscountAmount(e.target.value)}
                    />
                  </Form.Item>
                </Col>
                <Col span={12}>
                  <Form.Item
                    name="file"
                    label={
                      <span>
                        Variant Images <NewBadge />
                      </span>
                    }
                    valuePropName="file"
                    extra="Variant images must be PNG or JPEG, recommended 1024 px by 1024 px or 1:1 aspect ratio."
                  >
                    <Upload
                      beforeUpload={() => false}
                      name="avatar"
                      listType="picture-card"
                      maxCount={5}
                      fileList={this.state.fileList}
                      multiple
                      onPreview={this.handlePreview}
                      onChange={this.handleChange}
                      onRemove={this.handleRemoveProductImage}
                      accept={'image/*'}
                    >
                      {this.state.fileList?.length >= 5 ? null : uploadButton}
                    </Upload>
                  </Form.Item>
                </Col>
                <Col span={12}>
                  <Form.Item
                    name="not_for_sale"
                    label="Not For sale"
                    valuePropName="checked"
                    extra={
                      'Hides the item for sale and shows only while making a purchase. eg. Office equipment'
                    }
                  >
                    <Switch
                      onChange={e => this.setState({ not_for_sale: e })}
                      checked={this.state.not_for_sale}
                    />
                  </Form.Item>
                </Col>
              </Row>
            </Card>
            {this.props.warehouses?.length == 0 && (
              <>
                {this.state.data.product_type == 'Product' && this.state.has_batches == 0 && (
                  <SectionHeader title="Opening Stock" optional={true} />
                )}
                {this.state.has_batches == 0 && (
                  <Card className="mb-4">
                    <Row gutter={18}>
                      <Col span={12}>
                        <Form.Item
                          name="opening_qty"
                          label="Opening Quantity"
                          hidden={this.state.data.product_type != 'Product'}
                          rules={[
                            {
                              validator: (rule, value) => {
                                if (value < 0) {
                                  return Promise.reject('Quantity cannot be negative')
                                }
                                return Promise.resolve()
                              },
                            },
                          ]}
                          onChange={e => {
                            var opening_purchase_price = this.formRefEditVariant.current.getFieldValue(
                              'opening_purchase_price',
                            )
                              ? parseFloat(
                                  this.formRefEditVariant.current.getFieldValue(
                                    'opening_purchase_price',
                                  ),
                                )
                              : 0

                            var opening_qty = this.formRefEditVariant.current.getFieldValue(
                              'opening_qty',
                            )
                              ? parseFloat(
                                  this.formRefEditVariant.current.getFieldValue('opening_qty'),
                                )
                              : 0
                            this.formRefEditVariant.current.setFieldsValue({
                              opening_value: parseFloat(opening_purchase_price * opening_qty),
                            })
                          }}
                        >
                          <Input placeholder="eg. 10" type="number" />
                        </Form.Item>
                      </Col>
                      <Col span={12}>
                        <Form.Item
                          name="opening_purchase_price"
                          label="Opening Purchase Price (with tax)"
                          hidden={this.state.data.product_type != 'Product'}
                          rules={[
                            {
                              validator: (rule, value) => {
                                if (value < 0) {
                                  return Promise.reject('Purchase Price cannot be negative')
                                }
                                return Promise.resolve()
                              },
                            },
                          ]}
                          onChange={e => {
                            var opening_purchase_price = this.formRefEditVariant.current.getFieldValue(
                              'opening_purchase_price',
                            )
                              ? parseFloat(
                                  this.formRefEditVariant.current.getFieldValue(
                                    'opening_purchase_price',
                                  ),
                                )
                              : 0

                            var opening_qty = this.formRefEditVariant.current.getFieldValue(
                              'opening_qty',
                            )
                              ? parseFloat(
                                  this.formRefEditVariant.current.getFieldValue('opening_qty'),
                                )
                              : 0
                            this.formRefEditVariant.current.setFieldsValue({
                              opening_value: parseFloat(opening_purchase_price * opening_qty),
                            })
                          }}
                        >
                          <Input placeholder="eg. 100.00" type="number" />
                        </Form.Item>
                      </Col>
                      <Col span={12}>
                        <Form.Item
                          name="opening_value"
                          label="Opening Stock Value (with tax)"
                          hidden={this.state.data.product_type != 'Product'}
                          rules={[
                            {
                              validator: (rule, value) => {
                                if (value < 0) {
                                  return Promise.reject('Opening Value cannot be negative')
                                }
                                return Promise.resolve()
                              },
                            },
                          ]}
                          onChange={e => {
                            var opening_purchase_price = this.formRefEditVariant.current.getFieldValue(
                              'opening_value',
                            )
                              ? parseFloat(
                                  this.formRefEditVariant.current.getFieldValue('opening_value'),
                                )
                              : 0

                            var opening_qty = this.formRefEditVariant.current.getFieldValue(
                              'opening_qty',
                            )
                              ? parseFloat(
                                  this.formRefEditVariant.current.getFieldValue('opening_qty'),
                                )
                              : 0
                            this.formRefEditVariant.current.setFieldsValue({
                              opening_purchase_price: parseFloat(
                                opening_purchase_price / opening_qty,
                              ),
                            })
                          }}
                        >
                          <Input placeholder="eg. 100.00" type="number" />
                        </Form.Item>
                      </Col>
                    </Row>
                  </Card>
                )}
              </>
            )}
            {this.props.price_lists?.length > 0 && this.state.type == 'edit' && (
              <>
                <SectionHeader title="Price List" optional={true} />
                <Card className="mb-4">
                  <>
                    <div className="mt-3">
                      <ProductPriceList
                        product_id={this.state.data.product_id}
                        variant_id={this.state.selectedData.id}
                      />
                    </div>
                  </>
                </Card>
              </>
            )}
          </Form>
        </Drawer>

        <ProductBarcode onRef={ref => (this.barcode = ref)} menu="barcode" />
        <EnhancedSubscriptionModal onRef={ref => (this.subscription = ref)} />
        <CustomColumnsForm
          onRef={ref => (this.custom_column = ref)}
          onAPICalled={this.getSuggestedColumns}
        />
        <SubscriptionPlanModal ref={this.subscriptionPlanRef} />

        <Modal
          open={this.state.previewVisible}
          title={this.state.previewTitle}
          footer={null}
          onCancel={() => this.setState({ previewVisible: false })}
          closeIcon={<i className="fa-solid fa-lg fa-xmark"></i>}
        >
          <img alt="Variant Image" style={{ width: '100%' }} src={this.state.previewImage} />
        </Modal>
      </>
    )
  }
}

function mapStateToProps(state, ownProps) {
  return {
    companyDetailsAdded: state.welcome.company_details_added,
    bankDetailsAdded: state.welcome.bank_details_added,
    productsAdded: state.welcome.products_added,
    integrations: state.permissions.integrations,
    warehouses: state.warehouse.warehouses,
    custom_columns: state.document.custom_column_data.custom_columns_not_default,
    custom_columns_active: state.document.custom_column_data.custom_columns_not_default_active,
    custom_column_suggestions: state.document.productCustomColumnsSuggestion,
    column_id_dict: state.document.custom_column_data.column_id_dict,
    price_lists: state.document.price_lists,
    user: state.user,
  }
}

export default connect(mapStateToProps, null, null, { forwardRef: true })(VariantForm)
