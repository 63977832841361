import { PDFViewer } from '@react-pdf/renderer'
import EwayBill from 'components/ewaybill/index'
import React, { useEffect, useState } from 'react'

export function MyDocument({ data }) {
  const [isReady, setIsReady] = useState(false)

  useEffect(() => {
    setIsReady(true)
  }, [])

  return (
    <>
      {isReady ? (
        <PDFViewer className="col-12" style={{ height: '900px' }}>
          <EwayBill invoice={{ ...data }} />
        </PDFViewer>
      ) : (
        ''
      )}
    </>
  )
}
