import {
  Affix,
  Button,
  Drawer,
  Form,
  Input,
  Select,
  Space,
  Steps,
  Tag,
  Typography,
  message,
} from 'antd'
import React, { Component } from 'react'
import { isMobile } from 'react-device-detect'
import { Link } from 'react-router-dom'
import { getAPI } from 'services/jwt'
const { TextArea } = Input

const { Text } = Typography

const { Step } = Steps

const { Option } = Select

const formItemLayout = {
  labelCol: {
    xs: { span: 18 },
    sm: { span: 9 },
  },
  wrapperCol: {
    xs: { span: 24 },
    sm: { span: 12 },
  },
}

const steps = [
  {
    title: 'Add Ship Rocket Details',
  },
  {
    title: 'Ship Rocket API Login',
  },
]

export default class ShipRocketCred extends Component {
  dateFormat = 'DD-MM-YYYY'
  formRef = React.createRef()
  pincodeRef = React.createRef()
  constructor(props) {
    super(props)
    this.state = {
      visible: false,
      loading: false,
      show_otp: false,
      txn: '',
      current: 0,
    }
  }

  async componentDidMount() {
    this.props.onRef(this)
  }

  componentWillUnmount() {
    this.props.onRef(null)
  }

  showDrawer = visible => {
    this.setState({ visible: visible })
  }
  closeDrawer = () => {
    this.setState({ visible: false })
  }

  showDrawerLogin = visible => {
    this.setState({ visible: visible })
  }

  next = () => {
    this.setState({ current: this.state.current + 1 })
  }

  prev = () => {
    this.setState({ current: this.state.current - 1 })
  }

  onFinish = async values => {
    // console.log(values)

    var req = {
      ...values,
    }
    // console.log(req)

    const data = await getAPI('user', 'shiprocket_authenticate', req)
    this.setState({ loading: true })

    if (data.success) {
      message.success(data.message)
      this.closeDrawer()
    } else {
      message.error(data.message)

      this.setState({ loading: false })
    }
  }

  render() {
    const { current } = this.state
    return (
      <Drawer
        title="Connect to Ship Rocket account"
        width={isMobile ? '100%' : '55%'}
        height={isMobile ? '90%' : '100%'}
        placement={isMobile ? 'top' : 'right'}
        closable={true}
        closeIcon={<i className="fa-solid fa-lg fa-xmark" />}
        onClose={() => this.setState({ visible: false })}
        open={this.state.visible}
      >
        <Steps current={this.state.current}>
          {steps.map(item => (
            <Step key={item.title} title={item.title} />
          ))}
        </Steps>

        <div className="steps-content pl-2">
          {this.state.current == 0 && (
            <>
              <Space direction="vertical" className="mt-4 pl-2 font-size-16">
                <Text className="font-size-18">
                  Follow below steps to register
                  <Text type="success" className="font-size-18 font-weight-bold">
                    {' '}
                    ShipRocket
                  </Text>{' '}
                </Text>
                <br />
                <Text type="warning" className="mt-4 pt-4 pb-0">
                  STEP - 1
                </Text>

                <hr className="mt-0 pt-0" />
                <Text>
                  1. Go to ShipRocket website{' '}
                  <Link
                    to={{ pathname: 'https://www.shiprocket.in/' }}
                    target="_blank"
                    className="font-weight-bold"
                  >
                    https://www.shiprocket.in/
                  </Link>
                </Text>
                <Text>
                  2. Click on <b>Try it Free</b> in the top right
                </Text>
                <Text>3. Enter all the details and click on Sign up for free</Text>
                <Text>
                  4. <Text className="font-weight-bold">Verify Mobile number with OTP</Text>
                </Text>

                <br />
                <Text type="warning" className="mt-4 pt-4 pb-0">
                  STEP - 2
                </Text>

                <hr className="mt-0 pt-0" />
                <Text>
                  1. Click on <b>Settings</b> in the left menu
                </Text>
                <Text>
                  2. Click on{' '}
                  <Text type="success" className="font-size-18 font-weight-bold">
                    {' '}
                    Configure
                  </Text>{' '}
                  in the API section
                </Text>
                <Text>
                  3. Click on <b>Create an API User</b>
                </Text>
                <Text>
                  4. Enter E-mail id , Password , Confirm Password then click on Generate API
                  Credentials
                </Text>
                <Text>
                  5. Click on <b>Generate API Credentials</b>
                </Text>
                <Text>5. Activate The user by Clicking on the toggle button</Text>
                <br />
                <Text type="warning" className="mt-4 pt-4 pb-0">
                  Complete KYC Process
                </Text>

                <hr className="mt-0 pt-0" />
                <Text>
                  1. Click on <b>Settings</b> in the left menu
                </Text>
                <Text>
                  2. Click on{' '}
                  <Text type="success" className="font-size-18 font-weight-bold">
                    {' '}
                    KYC
                  </Text>{' '}
                  in the Company section
                </Text>
                <Text>
                  3. Now Complete the <b>KYC Process</b>
                </Text>
                <br />
                <Text type="warning" className="mt-4 pt-4 pb-0">
                  Add Pickup Location
                </Text>

                <hr className="mt-0 pt-0" />
                <Text>
                  1. Click on <b>Settings</b> in the left menu
                </Text>
                <Text>
                  2. Click on{' '}
                  <Text type="success" className="font-size-18 font-weight-bold">
                    {' '}
                    Manage Pickup Addresses
                  </Text>{' '}
                  in the Pickup Address Section
                </Text>
                <Text>
                  3. Add at least one <b>pickup location</b>
                </Text>
                <Text>
                  3. After Creating the pickup address <b>activate</b> it by clicking on the toggle
                  button
                </Text>
              </Space>
            </>
          )}

          {this.state.current == 1 && (
            <div className="mt-4 font-size-18">
              <Tag
                color="orange"
                className="mb-4 font-size-18 font-weight-bold"
                style={{ borderRadius: '5px' }}
              >
                Enter ShipRocket API Username and Password
              </Tag>

              <Form
                {...formItemLayout}
                labelAlign="left"
                ref={this.formRef}
                name="GSTCredsForm"
                onFinish={this.onFinish}
                size="large"
              >
                <Form.Item
                  name="username"
                  label="API User E-mail"
                  size="large"
                  rules={[{ required: true, message: 'Please fill E-mail' }]}
                >
                  <Input placeholder="API User E-mail" />
                </Form.Item>

                <Form.Item
                  name="password"
                  label="API User Password"
                  size="large"
                  rules={[{ required: true, message: 'Please fill Password' }]}
                >
                  <Input type="password" placeholder="API User Password" />
                </Form.Item>

                <button type="submit" size="large" className="btn btn-primary">
                  Login
                  <i className="fa fa-arrow-right ml-2" />
                </button>
              </Form>
            </div>
          )}
        </div>

        <div className="steps-action mt-4">
          {current < steps.length - 1 && (
            <Affix offsetBottom={10}>
              <Button type="action" size="large" onClick={() => this.next()}>
                Proceed to ShipRocket api credentials <i className="fa fa-arrow-right ml-2" />
              </Button>
            </Affix>
          )}
          {/*{current === steps.length - 1 && (
            <Button type="action" size="large" onClick={() => { this.onFinish(); message.success('Processing complete!')} }>
              Submit
            </Button>
          )}*/}
          {current > 0 && (
            <Button size="small" style={{ margin: '0 8px' }} onClick={() => this.prev()}>
              <i className="fa fa-arrow-left mr-2" /> Go Back
            </Button>
          )}
        </div>
      </Drawer>
    )
  }
}

export class EnhancedShipRocketCreds extends React.Component {
  constructor(props) {
    super(props)
  }
  render() {
    return <ShipRocketCred {...this.props} />
  }
}
