import { message } from 'antd'
import { history } from 'index'
import queryString from 'query-string'
import { all, call, put, select, takeLatest } from 'redux-saga/effects'
import { get_pos_settings } from 'redux/document/actions'
import {
  GET_CUSTOM_COLUMNS_SUGGESTION,
  GET_HSN_SUGGESTION,
  SET_CUSTOM_COLUMNS,
  SET_INVOICE_SETTINGS,
} from 'redux/document/types'
import {
  setAllUsers,
  setCompanyDetails,
  setDefaultUrl,
  setIntegration,
  setPermissions,
} from 'redux/permissions/actions'
import * as jwt from 'services/jwt'
import actions from './actions'
import { getCountryInfo } from 'redux/countries/actions'
import store from 'store'
import { set_warehouse_permissions } from 'redux/warehouses/actions'

const mapAuthProviders = {
  login: jwt.login,
  mobileLogin: jwt.mobileLogin,
  verifyOtp: jwt.verifyOtp,
  register: jwt.register,
  currentAccount: jwt.currentAccount,
  logout: jwt.logout,
  company: jwt.company,
  getCompanies: jwt.getCompanies,
}

export function* LOGIN({ payload }) {
  const { email, password } = payload
  yield put({
    type: 'user/SET_STATE',
    payload: {
      loading: true,
    },
  })
  const success = yield call(mapAuthProviders.login, email, password)
  if (success == 'Bank') {
    yield history.push('/list/sales')
  } else if (success) {
    yield put({
      type: 'user/LOAD_CURRENT_ACCOUNT',
      ...payload,
      role: success.user_details.role,
    })
    message.success('Welcome Back!')
  }

  yield put({
    type: 'user/SET_STATE',
    payload: {
      loading: false,
    },
  })
}

export function* MOBILE_LOGIN({ payload }) {
  // const { mobile, params } = payload

  yield put({
    type: 'user/SET_STATE',
    payload: {
      mobileLoading: true,
    },
  })
  const success = yield call(mapAuthProviders.mobileLogin, payload)

  yield put({
    type: 'user/SET_STATE',
    payload: {
      mobileLoading: false,
    },
  })
}

export function* VERIFY_OTP({ payload }) {
  console.log('called verify OTP')
  const { mobile, otp, params, email, accessToken } = payload
  yield put({
    type: 'user/SET_STATE',
    payload: {
      otpLoading: true,
    },
  })
  const response = yield call(mapAuthProviders.verifyOtp, mobile, otp, params, email, accessToken)
  if (response.success) {
    console.log('verify otp success')
    yield put({
      type: 'user/LOAD_CURRENT_ACCOUNT',
      role: response.user_details.role,
    })
    message.success('Welcome')
  }

  yield put({
    type: 'user/SET_STATE',
    payload: {
      otpLoading: false,
    },
  })
}

export function* COMPANY({ payload }) {
  const { company } = payload
  yield put({
    type: 'user/SET_STATE',
    payload: {
      loading: true,
    },
  })
  const success = yield call(mapAuthProviders.company, company)

  if (success) {
    yield put({
      type: 'user/LOAD_CURRENT_ACCOUNT',
    })
    message.success('Welcome')
  }
  if (!success) {
    yield put({
      type: 'user/SET_STATE',
      payload: {
        loading: false,
      },
    })
  }
}

export function* REGISTER({ payload }) {
  const { email, password, name, mobile } = payload
  yield put({
    type: 'user/SET_STATE',
    payload: {
      loading: true,
    },
  })
  const success = yield call(mapAuthProviders.register, email, password, mobile, name)
  if (success) {
    yield put({
      type: 'user/LOAD_CURRENT_ACCOUNT',
    })
    yield history.push('/list/sales')
  }
  if (!success) {
    yield put({
      type: 'user/SET_STATE',
      payload: {
        loading: false,
      },
    })
  }
}

export function* LOAD_CURRENT_ACCOUNT() {
  console.log('LOAD ACCOUNT')
  yield put({
    type: 'user/SET_STATE',
    payload: {
      loading: true,
      pageLoading: true,
    },
  })
  const response = yield call(mapAuthProviders.currentAccount)
  console.log('LOAD responnse')
  console.log('LOAD responnse', response)
  if (response && typeof response === 'object') {
    //Local storage changes
    //TODO: Remove this after 1/6/2024
    if (store.get('transactions_default_dates')) {
      store.set(
        'transactions_default_dates_' + response.company.company_id,
        store.get('transactions_default_dates'),
      )
      store.remove('transactions_default_dates')
    }
    if (store.get('reportsDateRange')) {
      store.set('reportsDateRange_' + response.company.company_id, store.get('reportsDateRange'))
      store.remove('reportsDateRange')
    }

    yield put(setCompanyDetails(response.company))
    const document_settings = yield call(
      async () => await jwt.getAPIData('utils', 'invoice_settings'),
    )
    yield put(get_pos_settings())

    if (document_settings.success) {
      yield put({
        type: 'user/SET_STATE',
        payload: {
          document_settings: {
            ...document_settings.invoice_settings,
            invoice_templates: document_settings.invoice_templates,
            num_invoices: document_settings.num_invoices,
            paid: document_settings.paid,
          },
        },
      })
      yield put({
        type: SET_INVOICE_SETTINGS,
        payload: {
          ...document_settings.invoice_settings,
          invoice_templates: document_settings.invoice_templates,
          num_invoices: document_settings.num_invoices,
          paid: document_settings.paid,
        },
      })
    }
    yield put({
      type: 'user/SET_STATE',
      payload: {
        authorized: true,
        role: response.role,
        paid: response.user_details?.paid,
        company_id: response.company.company_id,
        status_response: response,
      },
    })
    const data = yield call(async () => await jwt.getAPIData('v2/custom_fields', 'item'))
    if (data.success) {
      yield put({ type: SET_CUSTOM_COLUMNS, payload: data.custom_columns })
    }

    yield put(
      setPermissions({
        ...response.roles[0],
        ...response.integrations,
        id: response.roles[0].id,
        campaigns: response.roles[0].campaigns,
        trial_days: response.trial_days,
        is_pos: response.is_pos,
        additional_cess: response.company.additional_cess,
        bom: response.company.bom,
        reminders: response.company.reminders,
        payment_gateway_authorized:
          response.payment_gateway_settings?.payment_gateway_authorize == 1,
        role: response.role,
      }),
    )
    yield put(set_warehouse_permissions(response.warehouse_permissions))
    yield put(setIntegration(response.integrations))
    yield put(setDefaultUrl(response.url))
    yield put(setAllUsers(response.all_users))

    yield put({
      type: 'welcome/UPDATE_WELCOME_DETAILS',
      payload: {
        company_details_added: response.company_details_added,
        products_added: response.products_added,
        bank_details_added: response.bank_details_added,
        invoices_created: response.invoices_created,
      },
    })

    const response2 = yield call(mapAuthProviders.getCompanies)
    if (response2 != true) {
      if (response2.success) {
        yield put({
          type: 'user/SET_STATE',
          payload: {
            companies: response2.companies,
            selectedCompany: response.company,
          },
        })
      }
    }

    yield put({
      type: 'menu/GET_DATA',
    })

    yield put({
      type: GET_CUSTOM_COLUMNS_SUGGESTION,
    })
    yield put({
      type: GET_HSN_SUGGESTION,
    })

    yield put(
      getCountryInfo({
        country_code: response.company.country_code,
      }),
    )
    // TODO
    console.log('outer ln:288')
    const queryParams = queryString.parseUrl(window.location.href)?.query
    console.log('after query ln:290', queryParams)
    if (window.location.pathname.includes('/auth')) {
      console.log('if auth ln:292')

      const redirect = queryParams?.redirect ?? '/'
      console.log('got redirect', redirect)
      yield history.push(redirect)
    } else {
      console.log('if not auth ln:297')
      const paramsString = queryParams ? '?' + queryString.stringify(queryParams) : ''
      yield history.push(window.location.pathname + paramsString)
    }
  }
  yield put({
    type: 'user/SET_STATE',
    payload: {
      loading: false,
      pageLoading: false,
    },
  })

  yield put({
    type: 'user/SET_USER_DETAILS',
    payload: { ...response.user_details },
  })
}
export function* get_companies() {
  const prev_company_details = yield select(state => state.user.selectedCompany)
  const response2 = yield call(mapAuthProviders.getCompanies)
  if (response2 != true) {
    if (response2.success) {
      const new_selected_company_details = response2.companies.find(
        company => company.company_id == prev_company_details.company_id,
      )

      yield put({
        type: 'user/SET_STATE',
        payload: {
          companies: response2.companies,
          selectedCompany: { ...prev_company_details, ...new_selected_company_details },
        },
      })
    }
  }
}

export function* LOGOUT() {
  yield put({ type: actions.PAGE_LOADING, payload: true })

  yield call(mapAuthProviders.logout)
}

export default function* rootSaga() {
  yield all([
    takeLatest(actions.LOGIN, LOGIN),
    takeLatest(actions.MOBILE_LOGIN, MOBILE_LOGIN),
    takeLatest(actions.VERIFY_OTP, VERIFY_OTP),
    takeLatest(actions.REGISTER, REGISTER),
    takeLatest(actions.COMPANY, COMPANY),
    takeLatest(actions.LOAD_CURRENT_ACCOUNT, LOAD_CURRENT_ACCOUNT),
    takeLatest(actions.LOGOUT, LOGOUT),
    takeLatest(actions.GET_COMPANIES, get_companies),
    LOAD_CURRENT_ACCOUNT(), // run once on app load to check user auth
  ])
}
