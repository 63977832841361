import {
  AutoComplete,
  Button,
  Card,
  Checkbox,
  Collapse,
  DatePicker,
  Divider,
  Drawer,
  Form,
  Input,
  InputNumber,
  Radio,
  Select,
  Switch,
  message,
} from 'antd'
import PaymentModes from 'components/other/paymentModes'
import PendingTable from 'components/other/pendingTable'
import SectionHeader from 'components/other/sectionHeader'
import UploadFiles from 'components/other/uploadFiles'
import { formItemLayout } from 'components/other/utility'
import * as dayjs from 'dayjs'
import utc from 'dayjs/plugin/utc'
import _ from 'lodash'
import React, { Component } from 'react'
import { isMobile } from 'react-device-detect'
import { connect } from 'react-redux'
import { get_payment_modes } from 'redux/document/actions'
import { getAPI, getAPIData } from 'services/jwt'
import { EnhancedBankForm } from './onlyBankForm'

const { TextArea } = Input
const { Option } = Select
dayjs.extend(utc)
const dotOptions = { minimumFractionDigits: 2, maximumFractionDigits: 2 }

class PayInForm extends Component {
  dateFormat = 'DD-MM-YYYY'
  formRef = React.createRef()
  constructor(props) {
    super(props)
    this.state = {
      visible: false,
      autocompleteData: [],
      customerName: '',
      companyName: '',
      customerEmail: '',
      customerPhone: '',
      selectedCustomer: 0,
      selectedType: 'customer',
      balance: 0,
      formFlag: false,
      typeFlag: false,
      loading: false,
      type: '',
      amount: '',
      bank_id: 0,
      use_balance: false,
      data: [],
      originData: [],
      selectedData: {
        payment_date: dayjs(),
        customer_name: '',
        amount: '',
        notes: '',
        email: '',
        payment_type: '',
        party_type: 'customer',
      },
      is_advance_payment: false,
      settledInvoices: [],
      settlement_mode: 'auto',
      is_pending_list: false,
      collapseActiveKey1: '1',
      collapseActiveKey2: '',
      autocompleteData: [],
      attachments: [],
      minAmount: 0,
    }
    this.handleFirstCollapseChange = this.handleFirstCollapseChange.bind(this)
    this.handleSecondCollapseChange = this.handleSecondCollapseChange.bind(this)
    this._onFinish = this._onFinish.bind(this)
    this.onFinish = _.throttle(this._onFinish, 2000)
  }

  componentDidMount() {
    this.props.onRef(this)
    if (this.props?.payment_modes?.length == 0) {
      this.props.dispatch(get_payment_modes())
    }
  }

  componentWillUnmount() {
    this.props.onRef(null)
    this.onFinish.cancel()
  }

  dataUpdate = data => {
    this.setState({ data: data })
  }

  _onFinish = async values => {
    let settledInvoices =
      this.state.settlement_mode == 'auto' ? this.state.data : this.state.settledInvoices

    this.setState({ loading: true })
    const newData = settledInvoices.filter(item => item.amount_paying != 0)
    let resArray = newData.map(item => {
      return { serial_number: item.serial_number, amount_paying: item.amount_paying }
    })
    let document_name = 'invoices'
    if (this.state.selectedType == 'customer') {
      if (this.state.typeFlag) {
        document_name = 'invoices'
      } else {
        document_name = 'sales_returns'
      }
    } else {
      if (this.state.typeFlag) {
        document_name = 'purchase_returns'
      } else {
        document_name = 'pos'
      }
    }
    if (!this.state.is_advance_payment) {
      resArray = []
    }
    var req = {}
    var data = {}
    if (this.state.selectedType == 'journal') {
      req = {
        ...values,
        bank_id: this.state.bank_id,
        payment_mode: this.state.bank_id == 0 ? 'Cash' : this.state.type,
        payment_date: dayjs(values.payment_date).format(this.dateFormat),
        journal_id: this.state.selectedCustomer,
        document_type: 'journal',
        notes: values.notes,
        payment_type: this.state.typeFlag ? 'in' : 'out',
        is_credit: this.state.typeFlag ? true : false,
        attachments: this.state.attachments,
      }

      data = await getAPI('journals', 'create_journal_payment', req)
    } else {
      req = {
        ...values,
        [document_name]: resArray,
        bank_id: this.state.bank_id,
        payment_date: dayjs(values.payment_date).format(this.dateFormat),
        notes: values.notes,
        party_id: this.state.selectedCustomer ?? 0,
        party_type: this.state.selectedType,
        amount: values.amount,
        payment_mode: this.state.bank_id == 0 ? 'Cash' : this.state.type,
        payment_type: this.state.typeFlag ? 'in' : 'out',
        is_advance_payment: this.state.is_advance_payment,
        attachments: this.state.attachments,
      }

      data = await getAPI(
        'payments',
        this.state.typeFlag ? 'create_payment_in' : 'create_payment_out',
        req,
      )
    }

    if (data) {
      message.success(data.message)
      this.setState({ visible: false, loading: false, settledInvoices: [], attachments: [] })
      if (this.props?.onApicalled) {
        this.props.onApicalled({
          serial_number: data.serial_number,
          amount: values.amount,
          payment_date: values.payment_date,
        })
      }
    } else this.setState({ loading: false })
  }
  refreshBanks = async () => {
    var data = await getAPIData('user', 'bank_details')
    this.setState(
      {
        bank_id: data.bank_details[0].id,
      },
      () => {
        this.formRef.current.setFieldsValue({
          bank_id: data.bank_details[0].id,
        })
      },
    )
  }
  customerSearchAPI = async searchText => {
    this.setState({ customerName: searchText })
    const req = { query: searchText }
    const data = await getAPI(this.state.selectedType, 'search', req)
    if (data) {
      var allData = []
      if (this.state.selectedType == 'customer') {
        allData = data.customers.map(value => {
          return {
            value: (
              <span>
                <span>{value.name + (value.phone != '' ? ' (' + value.phone + ')' : '')}</span>
                {
                  <span
                    className="font-size-12 float-right font-weight-bold"
                    style={{ color: this.getStatusClass(value.balance + value.opening_balance) }}
                  >
                    <i className="fa fa-inr font-size-12 mr-1"></i>
                    {Math.round(Math.abs(value.balance + value.opening_balance)).toLocaleString(
                      'en-IN',
                      dotOptions,
                    )}
                  </span>
                }
                <p className="font-size-12 mb-0">
                  <span className="text-gray-500">{value.gstin}</span> {value.company_name}
                  <span className="float-right text-gray-500">
                    {value.tags && value.tags != '' ? 'Tags: ' + value.tags : ''}
                  </span>
                </p>
              </span>
            ),
            name: value.name,
            id: value.customer_id,
            key: value.customer_id,
            balance: value.balance,
            opening_balance: value.opening_balance,
          }
        })
      } else {
        allData = data.vendors.map(value => {
          return {
            value: (
              <span>
                <span>{value.name + (value.phone != '' ? ' (' + value.phone + ')' : '')}</span>
                {
                  <span
                    className="font-size-12 float-right font-weight-bold"
                    style={{ color: this.getStatusClass(value.balance + value.opening_balance) }}
                  >
                    <i className="fa fa-inr font-size-12 mr-1"></i>
                    {Math.round(Math.abs(value.balance + value.opening_balance)).toLocaleString(
                      'en-IN',
                      dotOptions,
                    )}
                  </span>
                }
                <p className="font-size-12 mb-0">
                  <span className="text-gray-500">{value.gstin}</span> {value.company_name}
                  <span className="float-right text-gray-500">
                    {value.tags && value.tags != '' ? 'Tags: ' + value.tags : ''}
                  </span>
                </p>
              </span>
            ),
            name: value.name,
            id: value.vendor_id,
            key: value.vendor_id,
            balance: value.balance,
            opening_balance: value.opening_balance,
          }
        })
      }
      this.setState({
        autocompleteData: [...allData],
        selectedCustomer: 0,
        balance: 0,
        data: [],
        settledInvoices: [],
        originData: [],
      })
    }
  }

  onAdd = (data, selectedUser, type) => {
    this.setState(
      {
        data: [],
        amount: selectedUser?.amount || '',
        minAmount: selectedUser?.minAmount || 0,
        autocompleteData: [],
        typeFlag: type,
        visible: true,
        formFlag: false,
        balance: selectedUser?.balance,
        opening_balance: selectedUser?.opening_balance,
        customerName: selectedUser?.name,
        companyName: selectedUser?.company_name,
        customerEmail: selectedUser?.email,
        customerPhone: selectedUser?.phone,
        selectedType: selectedUser ? selectedUser.type : 'customer',
        use_balance: false,
        bank_id:
          this.props.bank_id != undefined
            ? this.props.bank_id
            : data?.bank_details?.length > 0
            ? data?.bank_details[0]?.id
            : '',
        // type: data.payment_modes.length > 0 ? data.payment_modes[0].value : '',
        selectedCustomer: selectedUser ? selectedUser?.id : 0,
        collapseActiveKey1: '1',
      },
      () => {
        if (selectedUser) {
          this.getListPendings(selectedUser?.id)
        }
        this.formRef.current.setFieldsValue({
          customer_name: selectedUser?.name,
          amount: selectedUser?.amount || '',
          bank_id:
            this.props.bank_id != undefined
              ? this.props.bank_id
              : data?.bank_details?.length > 0
              ? data?.bank_details[0]?.id
              : 0,
          payment_date: selectedUser?.payment_date ? dayjs(selectedUser.payment_date) : dayjs(),
          notes: '',
          payment_type: data?.payment_modes?.length > 0 ? data?.payment_modes[0]?.value : '',
          use_balance: false,
          exclusive_notes: selectedUser?.exclusive_notes || '',
        })
        this.state.is_advance_payment && this.table?.onAddNew([])
      },
    )
  }

  onAmountChange = value => {
    if (value > 0) {
      value = value.toFixed(2)
    }
    this.setState({ amount: value }, () => {
      if (this.state.settlement_mode == 'auto') {
        this.state.is_advance_payment && this.table?.onAddNew(this.state.originData)
      } else {
        this.state.is_advance_payment && this.table?.onAddNewManual(this.state.originData)
      }
    })
  }

  getListPendings = async id => {
    if (id == 0) {
      return false
    }
    const req = { party_id: id, party_type: this.state.selectedType }
    if (
      (this.state.typeFlag && this.state.selectedType == 'customer') ||
      (!this.state.typeFlag && this.state.selectedType == 'vendor')
    ) {
      const data = await getAPI(
        'payments',
        this.state.typeFlag ? 'pending_invoices' : 'pending_pos',
        req,
      )

      if (data) {
        const results = data[this.state.typeFlag ? 'invoices' : 'pos'].map((row, index) => ({
          key: index,
          ...row,
          amount_paying: 0,
          disable: false,
        }))
        this.setState({ data: results, originData: results, type: data.recent_mode })
        this.state.is_advance_payment && this.table?.onAddNew(results)
      }
      this.setState({ is_pending_list: true })
    } else {
      this.setState({ type: 'UPI' })
    }
  }

  onPaymentModeChange = e => {
    this.setState({ type: e.target.value })
  }
  onSelectRow = data => {
    this.setState({ settledInvoices: data })
  }
  getStatusClass = data => {
    if (data < 0) {
      return '#e11900'
    } else if (data == 0) {
      return '#757575'
    } else {
      return '#09864A'
    }
  }
  getPaymentClass = data => {
    switch (data.toLowerCase()) {
      case 'cash':
        return 'btn-cash'
      case 'card':
        return 'btn-card'
      case 'upi':
        return 'btn-upi'
      case 'emi':
        return 'btn-amount'
      case 'net banking':
        return 'btn-info'
      case 'cheque':
        return 'btn-success'
      default:
        return 'btn-card'
    }
  }

  handleFirstCollapseChange(activeKey) {
    if (activeKey.length > 0) {
      this.setState({ collapseActiveKey1: '1' })
    } else {
      this.setState({ collapseActiveKey1: '' })
    }
  }

  handleSecondCollapseChange(activeKey) {
    if (activeKey.length > 0) {
      this.setState({ collapseActiveKey2: '2', is_advance_payment: true }, () => {
        setTimeout(() => {
          if (this.state.settlement_mode == 'auto') {
            this.state.is_advance_payment && this.table.onAddNew(this.state.originData)
          } else {
            this.state.is_advance_payment && this.table.onAddNewManual(this.state.originData)
          }
        }, 100)
      })
    } else {
      this.setState({ collapseActiveKey2: '', is_advance_payment: false })
    }
  }
  onPartySelect = (value, option) => {
    this.setState(
      {
        customerName: option.name,
        companyName: option.company_name,
        selectedCustomer: option.id,
        balance: option.balance + option.opening_balance,
      },
      () => this.getListPendings(this.state.selectedCustomer),
    )
  }

  render() {
    return (
      <>
        <Drawer
          title={
            <span>
              <span className="lh-normal">
                <span className="font-weight-bolder text-muted">
                  {this.state.typeFlag ? 'PayIn' : 'PayOut'}
                </span>
                {/*{capitalizeFirstLetters(this.state.document_type)} #*/}
              </span>
              <p className="font-size-13 text-muted font-weight-medium mt-0 mb-0 lh-normal">
                <span>
                  {this.state.companyName == ''
                    ? this.state.companyName
                    : this.state.customerName}
                </span>
              </p>
            </span>
          }
          width={isMobile ? '100%' : '60%'}
          height={isMobile ? '90%' : '100%'}
          placement={isMobile ? 'top' : 'right'}
          closeIcon={<i className="fa-solid fa-lg fa-xmark"></i>}
          onClose={() => this.setState({ visible: false, attachments: [] })}
          open={this.state.visible}
          destroyOnClose
          extra={
            <Button
              onClick={() => this.formRef.current.submit()}
              type="primary"
              loading={this.state.loading}
            >
              {this.state.formFlag ? 'Update Payment' : 'Add Payment'}
            </Button>
          }
          footer={
            <Button
              onClick={() => this.formRef.current.submit()}
              type="primary"
              loading={this.state.loading}
            >
              {this.state.formFlag ? 'Update Payment' : 'Add Payment'}
            </Button>
          }
        >
          {this.props.show_party_selection && (
            <Card size="medium" className="mb-4">
              <Form.Item name="party_type" label="Party Type" valuePropName="checked">
                <Radio.Group
                  onChange={e =>
                    this.setState({
                      selectedType: e.target.value,
                      customerName: '',
                      customerEmail: '',
                      customerPhone: '',
                      selectedCustomer: 0,
                      autocompleteData: [],
                      data: [],
                      settledInvoices: [],
                      originData: [],
                    })
                  }
                  value={this.state.selectedType}
                >
                  <Radio value={'customer'}>Customer</Radio>
                  <Radio value={'vendor'}>Vendor</Radio>
                </Radio.Group>
              </Form.Item>
              <AutoComplete
                options={this.state.autocompleteData}
                className="mr-2 mb-2"
                style={{ width: '100%', borderRadius: '5px' }}
                onSelect={this.onPartySelect}
                onSearch={this.customerSearchAPI}
                defaultOpen
                allowClear
                value={this.state.customerName}
                placeholder={`Search ${
                  this.state.selectedType == 'customer' || !this.state.selectedType
                    ? 'Customers'
                    : 'Vendors'
                }`}
              />
            </Card>
          )}
          {this.state.amount > 0 && this.state.amount !== 0 && this.state.data.length > 0 && (
            <Collapse
              className="bg-card-orange-dark mb-4"
              bordered={false}
              activeKey={this.state.collapseActiveKey2}
              onChange={this.handleSecondCollapseChange}
              expandIcon={({ isActive }) => {
                return (
                  <Checkbox
                    checked={this.state.is_advance_payment}
                    onChange={e => {
                      this.setState({ is_advance_payment: !this.state.is_advance_payment })
                    }}
                    style={{ fontSize: '0.9rem' }}
                    onClick={e =>
                      this.handleSecondCollapseChange(
                        this.state.collapseActiveKey2 == '2' ? [] : ['2'],
                      )
                    }
                  ></Checkbox>
                )
              }}
            >
              <Collapse.Panel
                header={
                  <>
                    <span className="font-size-18 font-weight-bolder">
                      Settle Pending Documents
                    </span>
                    <p className="text-gray-900 font-size-12 font-weight-normal mb-0">
                      You have Pending documents for this party. Click here to Settle Pending
                      Documents
                    </p>
                  </>
                }
                key="2"
              >
                <div>
                  {this.state.is_advance_payment && (
                    <Radio.Group
                      onChange={e => {
                        this.setState(
                          {
                            settlement_mode: e.target.value,
                          },
                          () => {
                            if (this.state.settlement_mode == 'manual') {
                              this.state.is_advance_payment &&
                                this.table.onAddNewManual(this.state.originData)
                            } else {
                              this.state.is_advance_payment &&
                                this.getListPendings(this.state.selectedCustomer)
                            }
                          },
                        )
                      }}
                      className="mt-2"
                      value={this.state.settlement_mode}
                    >
                      <Radio value={'auto'}>Auto</Radio>
                      <Radio value={'manual'}>Manual</Radio>
                    </Radio.Group>
                  )}

                  {this.state.is_advance_payment && (
                    <div className="col-md-12 mt-4 px-0">
                      <Divider />
                      {this.state.settlement_mode == 'manual' ? (
                        <div className="mt-2 mb-3">
                          <h5 className="font-weight-bold text-gray-700 font-size-18">
                            Select the invoices in the following table to settle with this payment
                          </h5>
                          <p className="text-danger font-size-14">
                            Note: Only Selected invoices will be settled with this payment. You can
                            select multiple invoices
                          </p>
                        </div>
                      ) : (
                        <div className="mt-2 mb-3">
                          <h5 className="font-weight-bold text-gray-700 font-size-18">
                            The following invoices will be settled with this payment
                          </h5>
                        </div>
                      )}

                      <PendingTable
                        onRef={ref => (this.table = ref)}
                        dataUpdate={this.dataUpdate}
                        onSelectRow={this.onSelectRow}
                        amount={this.state.amount}
                        type={this.state.typeFlag}
                        mode={this.state.settlement_mode}
                      />
                    </div>
                  )}
                </div>
              </Collapse.Panel>
            </Collapse>
          )}

          {
            <SectionHeader
              title={
                <>
                  <span>{this.state.customerName}</span>
                </>
              }
              right={true}
              rightText={
                <>
                  <p
                    className="mt-0 mb-0 pt-0 font-size-14"
                    style={{ color: this.getStatusClass(this.state.balance), fontWeight: 700 }}
                  >
                    <span className="font-size-14 mr-1 font-weight-bold">Balance: </span>
                    <span className="font-size-14 mr-1 font-weight-bold">
                      <span className="font-size-12 ml-1 mr-1">
                        {this.props?.user?.selectedCompany?.currency_symbol}
                      </span>
                      {this.state.balance}
                    </span>
                  </p>
                </>
              }
            />
          }
          <div>
            <Form
              {...formItemLayout}
              labelAlign="left"
              ref={this.formRef}
              name="payInForm"
              onFinish={this.onFinish}
              initialValues={{
                ...this.state.selectedData,
              }}
            >
              <Collapse
                className="bg-white "
                bordered={false}
                activeKey={this.state.collapseActiveKey1}
                expandIcon={({ isActive }) => (
                  <span className="">
                    <i
                      className={`fa-solid fa-chevron-down ml-1 ${!isActive && 'fa-rotate-270'}`}
                    />
                  </span>
                )}
                onChange={this.handleFirstCollapseChange}
                style={{ marginBottom: 25 }}
              >
                <Collapse.Panel
                  header={
                    <>
                      <span className="font-weight-bolder">Payment Details</span>
                    </>
                  }
                  key="1"
                  className="mb-4"
                >
                  <Form.Item
                    name="amount"
                    label="Enter Amount"
                    rules={[
                      { required: true, message: 'Fill Pay Amount' },
                      {
                        type: 'number',
                        min: this.state.minAmount,
                        message: 'Amount should be greater than ' + this.state.minAmount,
                      },
                    ]}
                  >
                    <InputNumber
                      placeholder="500"
                      size="large"
                      className="w-100 font-weight-bold"
                      precision={2}
                      onChange={e => this.onAmountChange(e)}
                      prefix={<span>{this.props?.user?.selectedCompany?.currency_symbol}</span>}
                      value={this.state.amount}
                    />
                  </Form.Item>

                  <Form.Item name="payment_date" label="Payment Date">
                    <DatePicker
                      allowClear={false}
                      format={this.dateFormat}
                      style={{
                        width: '100%',
                        borderRadius: '5px',
                        cursor: 'pointer',
                      }}
                    />
                  </Form.Item>

                  {this.state.type != 'Cash' &&
                    this.state.type != 'TDS' &&
                    this.props.bank_details?.length > 0 && (
                      <Form.Item name="bank_id" label="Bank">
                        {this.props.bank_details?.length > 1 && (
                          <Select
                            placeholder="Select Bank"
                            optionFilterProp="children"
                            style={{ width: '200' }}
                            onChange={e =>
                              this.setState({
                                bank_id: e,
                                type: e == 'Cash' ? 'Cash' : this.state.type,
                              })
                            }
                            value={this.state.bank_id}
                            filterOption={(input, option) =>
                              option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                            }
                          >
                            {this.props.bank_details.map((item, i) => (
                              <Option key={i} value={item.id}>
                                {item.bank_name + ' '}{' '}
                                {item.bank_no != '' &&
                                  item.bank_no != '-' &&
                                  '(' + item.bank_no + ')'}
                              </Option>
                            ))}
                          </Select>
                        )}
                        {this.props.bank_details.length == 1 && (
                          <>
                            {
                              <Button
                                type="info"
                                // size="small"
                                onClick={() => this.bankForm.onAddFromInvoicePage()}
                                className="font-weight-bold"
                                style={{ height: 27.1, fontSize: 11 }}
                                data-toggle="tooltip"
                                data-placement="top"
                                title="Add New Bank"
                                data-original-title="add"
                              >
                                Add New Bank
                              </Button>
                            }
                            <p className="font-size-12 mt-1 text-danger">
                              *By default the payment method is cash
                            </p>
                          </>
                        )}
                      </Form.Item>
                    )}

                  {this.state.bank_id != 0 && (
                    <Form.Item
                      name="payment_type"
                      label="Payment Type"
                      className="payments_perform"
                    >
                      {''}
                      <PaymentModes
                        data={this.props.payment_modes}
                        value={this.state.type}
                        onChange={this.onPaymentModeChange}
                      />
                    </Form.Item>
                  )}

                  <Form.Item name="notes" label="Notes" className="mb-2">
                    <TextArea placeholder="Enter your notes" />
                  </Form.Item>

                  {this.state.typeFlag && this.state.selectedType == 'customer' && (
                    <>
                      {this.props?.user?.selectedCompany?.country_code == 'IN' && (
                        <Form.Item
                          name="send_sms"
                          label={
                            this.state.selectedType == 'customer'
                              ? 'SMS to Customers'
                              : 'SMS to Vendors'
                          }
                          valuePropName="checked"
                          extra={
                            this.state.customerPhone != null && this.state.customerPhone != '' ? (
                              <span className="text-gray-500">
                                {`SMS will be sent to the ${this.state.selectedType} phone:`}
                                <span className="text-primary">{this.state.customerPhone}</span>
                              </span>
                            ) : (
                              <span className="text-gray-500">
                                <span className="text-danger">
                                  {`Please add ${this.state.selectedType} phone number to enable this option`}
                                </span>
                              </span>
                            )
                          }
                        >
                          <Switch
                            checkedChildren="Yes"
                            unCheckedChildren="No"
                            checked
                            disabled={
                              this.state.customerPhone == null || this.state.customerPhone == ''
                            }
                          />
                        </Form.Item>
                      )}

                      <Form.Item
                        name="send_email"
                        label={
                          this.state.selectedType == 'customer'
                            ? 'E-Mail to Customers'
                            : 'E-Mail to Vendors'
                        }
                        valuePropName="checked"
                        extra={
                          this.state.customerEmail != null && this.state.customerEmail != '' ? (
                            <span className="text-gray-500">
                              {`E-Mail will be sent to the ${this.state.selectedType} email:`}{' '}
                              <span className="text-primary">{this.state.customerEmail}</span>
                            </span>
                          ) : (
                            <span className="text-gray-500">
                              <span className="text-danger">
                                {`Please add ${this.state.selectedType} to enable this option`}
                              </span>
                            </span>
                          )
                        }
                      >
                        <Switch
                          checkedChildren="Yes"
                          unCheckedChildren="No"
                          checked
                          disabled={
                            this.state.customerEmail == null || this.state.customerEmail == ''
                          }
                        />
                      </Form.Item>
                    </>
                  )}
                  <UploadFiles
                    attachments={this.state.attachments}
                    maxCount={3}
                    multiple={true}
                    onChange={e => this.setState({ attachments: e })}
                    setLoading={e => this.setState({ loading: e })}
                  />
                </Collapse.Panel>
              </Collapse>

              <Form.Item
                name="exclusive_notes"
                label={
                  <span>
                    <i class="fa-solid fa-fingerprint mr-2"></i>Internal Notes
                  </span>
                }
                className="mt-4 pb-2"
                extra={
                  'This note is exclusively for internal reference and will not be shown elsewhere.'
                }
              >
                <TextArea placeholder="Enter notes here..." />
              </Form.Item>
            </Form>
          </div>
        </Drawer>
        <EnhancedBankForm refreshBanks={this.refreshBanks} onRef={ref => (this.bankForm = ref)} />
      </>
    )
  }
}

const mapStateToProps = state => {
  return {
    payment_modes: state.document.paymentModes.filter(item => item.value != 'TDS'),
    bank_details: state.document.bankDetails,
    user: state.user,
  }
}

export default connect(mapStateToProps, null, null, { forwardRef: true })(PayInForm)
