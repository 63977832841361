import React, { Fragment } from 'react'
import { Text, View, StyleSheet } from '@react-pdf/renderer'

const borderColor = '#1d1df'

const getStyles = invoice => {
  let FONT_FACTOR = invoice.invoice_settings.font_size
  let PAPER_SIZE = invoice.invoice_settings.paper_size == 'A4' ? 1 : 0.8
  let locale = invoice.company.locale
  let font_style = invoice.invoice_settings.font_style

  if (locale == 'English') {
    locale = font_style
  }
  return StyleSheet.create({
    container: {
      flexDirection: 'row',
      borderTopWidth: 0.5,
      borderBottomWidth: 0.5,
      borderColor: '#1d1d1f',
      color: '#141414',
      fontSize: 8.1 * FONT_FACTOR,
      textAlign: 'center',
      fontFamily: `helmed_${locale}`,
      flexWrap: 'wrap',
    },
    item: {
      flexDirection: 'row',
      fontSize: 8.1 * FONT_FACTOR,
      borderBottomWidth: 0.5,
      borderBottomColor: '#333333',
      textAlign: 'center',
      fontFamily: `helmed_${locale}`,
      flexWrap: 'wrap',
    },
    footer: {
      flexDirection: 'row',
      color: '#141414',
      fontSize: 8.1,
      textAlign: 'center',
      fontFamily: `helbold_${locale}`,
      flexWrap: 'wrap',
    },
    cell: {
      paddingVertical: 0.9,
      paddingHorizontal: 4.5,
      fontSize: 8.1 * FONT_FACTOR,
      borderRightWidth: 0.5,
      borderRightColor: '#333333',
      // borderBottomWidth: 0.36,
      // borderBottomColor: '#1d1d1f',
    },
  })
}

const InvoiceHsnTable = props => {
  const { invoice } = props
  const invoice2 = structuredClone(invoice)
  const options = { maximumFractionDigits: 2, minimumFractionDigits: 2 }
  const tax_options = { maximumFractionDigits: 2, minimumFractionDigits: 2 }
  var styles = getStyles(invoice)
  var FONT_FACTOR = invoice.invoice_settings.font_size

  const general_tax_header = (
    <View fixed>
      <View style={styles.container}>
        <Text
          style={{
            ...styles.cell,
            textAlign: 'left',
            width: `24%`,
          }}
        >
          {invoice.invoice_settings.labels.hsn_code}
        </Text>
        <Text
          style={{
            ...styles.cell,
            textAlign: 'right',
            width: `14%`,
          }}
        >
          Taxable Value
        </Text>
        <View style={{ flexDirection: 'column', width: '24%' }}>
          <View>
            <Text
              style={{
                ...styles.cell,
                textAlign: 'center',
                width: `100%`,
                borderBottomWidth: 0.36,
              }}
            >
              Central Tax
            </Text>
          </View>
          <View style={{ flexDirection: 'row' }}>
            <Text
              style={{
                ...styles.cell,
                width: `50%`,
              }}
            >
              Rate
            </Text>
            <Text
              style={{
                ...styles.cell,
                width: `50%`,
              }}
            >
              Amount
            </Text>
          </View>
        </View>
        <View style={{ flexDirection: 'column', width: '24%' }}>
          <View>
            <Text
              style={{
                ...styles.cell,
                textAlign: 'center',
                width: `100%`,
                borderBottomWidth: 0.36,
              }}
            >
              State/UT Tax
            </Text>
          </View>
          <View style={{ flexDirection: 'row' }}>
            <Text
              style={{
                ...styles.cell,
                width: `50%`,
              }}
            >
              Rate
            </Text>
            <Text
              style={{
                ...styles.cell,
                width: `50%`,
              }}
            >
              Amount
            </Text>
          </View>
        </View>
        <Text
          style={{
            ...styles.cell,
            textAlign: 'right',
            width: `14%`,
            borderRight: 'none',
          }}
        >
          Total Tax Amount
        </Text>
      </View>
      <View></View>
    </View>
  )

  const integrated_tax_header = (
    <View fixed>
      <View style={styles.container}>
        <Text
          style={{
            ...styles.cell,
            textAlign: 'left',
            width: `24%`,
          }}
        >
          {invoice.invoice_settings.labels.hsn_code}
        </Text>
        <Text
          style={{
            ...styles.cell,
            textAlign: 'right',
            width: `14%`,
          }}
        >
          Taxable Value
        </Text>
        <View style={{ flexDirection: 'column', width: '48%' }}>
          <View>
            <Text
              style={{
                ...styles.cell,
                textAlign: 'center',
                width: `100%`,
                borderBottomWidth: 0.36,
              }}
            >
              Integrated Tax
            </Text>
          </View>
          <View style={{ flexDirection: 'row' }}>
            <Text
              style={{
                ...styles.cell,
                width: `50%`,
              }}
            >
              Rate
            </Text>
            <Text
              style={{
                ...styles.cell,
                width: `50%`,
              }}
            >
              Amount
            </Text>
          </View>
        </View>

        <Text
          style={{
            ...styles.cell,
            textAlign: 'right',
            borderRight: 'none',
            width: `14%`,
          }}
        >
          Total Tax Amount
        </Text>
      </View>
      <View></View>
    </View>
  )

  let items = invoice2.items
  //insert additional charges as items into items
  if (invoice2.document_custom_additional_charges) {
    invoice2.document_custom_additional_charges.forEach(charge => {
      items.push({
        hsn_code: charge.sac_code,
        tax_amount: charge.tax_amount * charge.type,
        tax: charge.tax,
        net_amount: charge.net_amount * charge.type,
      })
    })
  }

  //merge all the duplicate hsn codes into one
  items = items.reduce((acc, item) => {
    const item_hsn = acc.find(
      item_hsn => item_hsn.hsn_code == item.hsn_code && item.tax == item_hsn.tax,
    )
    if (item_hsn) {
      item_hsn.tax_amount += item.tax_amount
      item_hsn.net_amount += item.net_amount
    } else {
      acc.push({
        hsn_code: item.hsn_code,
        tax_amount: item.tax_amount,
        tax: item.tax,
        net_amount: item.net_amount,
      })
    }
    return acc
  }, [])

  let net_amount = 0,
    tax_amount = 0
  items.forEach(item => {
    net_amount += item.net_amount
    tax_amount += item.tax_amount
  })

  const rows = items.map((item, index) => {
    const items = (
      <Fragment key={index}>
        <View style={{ ...styles.item }}>
          <Text style={{ ...styles.cell, textAlign: 'left', width: '24%' }}>
            {item.hsn_code == '' ? '-' : item.hsn_code}
          </Text>
          <Text style={{ ...styles.cell, textAlign: 'right', width: '14%' }}>
            {Number(item.net_amount)
              .toFixed(2)
              .toLocaleString('en-IN', options)}
          </Text>
          {invoice2.tax.length > 0 && invoice2.tax[0].tax_type != 'IGST' && (
            <>
              <Text style={{ ...styles.cell, width: '12%' }}>{Number(item.tax / 2)}%</Text>
              <Text style={{ ...styles.cell, width: '12%' }}>
                {Number(item.tax_amount / 2).toLocaleString('en-IN', tax_options)}
              </Text>
              <Text style={{ ...styles.cell, width: '12%' }}>{Number(item.tax / 2)}%</Text>
              <Text style={{ ...styles.cell, width: '12%' }}>
                {Number(item.tax_amount / 2).toLocaleString('en-IN', tax_options)}
              </Text>
            </>
          )}
          {invoice2.tax.length > 0 && invoice2.tax[0].tax_type == 'IGST' && (
            <>
              <Text style={{ ...styles.cell, width: '24%' }}>{Number(item.tax)}%</Text>
              <Text style={{ ...styles.cell, width: '24%' }}>
                {Number(item.tax_amount)
                  .toFixed(2)
                  .toLocaleString('en-IN', options)}
              </Text>
            </>
          )}
          <Text style={{ ...styles.cell, textAlign: 'right', borderRight: 'none', width: '14%' }}>
            {Number(item.tax_amount)
              .toFixed(2)
              .toLocaleString('en-IN', options)}
          </Text>
        </View>
      </Fragment>
    )
    return items
  })

  const footer = (
    <>
      <Fragment>
        <View style={styles.footer}>
          <Text style={{ ...styles.cell, width: '24%', textAlign: 'right' }}>{'TOTAL'}</Text>
          <Text style={{ ...styles.cell, textAlign: 'right', width: '14%' }}>
            {Number(net_amount.toFixed(2)).toLocaleString('en-IN', options)}
          </Text>
          {invoice2.tax.length > 0 && invoice2.tax[0].tax_type != 'IGST' && (
            <>
              <Text style={{ ...styles.cell, textAlign: 'center', width: '12%' }}>
                {/* {Number(invoice2.tax / 2).toFixed(2)}% */}
              </Text>
              <Text style={{ ...styles.cell, textAlign: 'center', width: '12%' }}>
                {Number(tax_amount / 2).toLocaleString('en-IN', tax_options)}
              </Text>
              <Text style={{ ...styles.cell, textAlign: 'center', width: '12%' }}>
                {/* {Number(item.tax / 2).toFixed(2)}% */}
              </Text>
              <Text style={{ ...styles.cell, textAlign: 'center', width: '12%' }}>
                {Number(tax_amount / 2).toLocaleString('en-IN', tax_options)}
              </Text>
            </>
          )}
          {invoice2.tax.length > 0 && invoice2.tax[0].tax_type == 'IGST' && (
            <>
              <Text style={{ ...styles.cell, width: '24%' }}>
                {/* {Number(invoice2.tax / 2).toFixed(2)}% */}
              </Text>
              <Text style={{ ...styles.cell, width: '24%' }}>
                {Number(tax_amount.toFixed(2)).toLocaleString('en-IN', options)}
              </Text>
            </>
          )}
          <Text style={{ ...styles.cell, textAlign: 'right', borderRight: 'none', width: '14%' }}>
            {Number(tax_amount)
              .toFixed(2)
              .toLocaleString('en-IN', options)}
          </Text>
        </View>
      </Fragment>
    </>
  )

  const header =
    invoice2.tax.length > 0 && invoice2.tax[0].tax_type == 'IGST'
      ? integrated_tax_header
      : general_tax_header
  return (
    <Fragment>
      <View wrap={false}>
        {header}
        {rows}
        {footer}
      </View>
    </Fragment>
  )
}

export default InvoiceHsnTable
