export const CONSTANTS = {
  // PRICING_BUTTON: 'Super Swipe Offer! 🚀',
  PRICING_BUTTON: 'Subscribe Now! 🚀',
  // GSTIN_REGEX: '^[0-9]{2}[A-Z]{5}[0-9]{4}[A-Z]{1}[0-9A-Z]{1}[0-9A-Z]{1}[0-9A-Z]{1}$',
  GSTIN_REGEX: '^[0-9A-Z]{15}$',
}

export const ADMIN = 'admin'

export const STOCK_IN_STOCK_OUT_DOC_TYPES = [
  'invoice',
  'sales_return',
  'purchase_return',
  'purchase',
]
export const FLOATOPTIONS = { maximumFractionDigits: 2, minimumFractionDigits: 2 }

export const HIDE_PRICES = 'Hide Prices'
