import { Button, Card, Divider, Modal, Select } from 'antd'
import FooterComponent from 'components/other/FooterComponent'
import React, { forwardRef, useEffect, useImperativeHandle, useState } from 'react'
import { isMobile } from 'react-device-detect'
import { useDispatch, useSelector } from 'react-redux'
import { get_pricing_plans } from 'redux/document/actions'
import { getAPI, getAPIData } from 'services/jwt'

const emptyPlan = {
  title: '',
  web_title: '',
  colors: ['#5578F8', '#2754FF'],
  sub_title: '',
  description: '',
  features: [],
  plan_id: -1,
  plan: 2,
  prices: [
    {
      year: 1,
      price: 0,
      strike_amount: 0,
      per: '1 Year',
      offer: '',
      discount: '',
    },
    {
      year: 3,
      price: 0,
      strike_amount: 0,
      per: '',
      offer: '',
      discount: '',
    },
  ],
  custom: false,
  show_in_web: false,
  button: {
    text: '',
    type: '',
    onclick: '',
  },
  badge: {
    text: '',
    type: '',
    icon: '',
  },
}

const CustomPricing = forwardRef((props, ref) => {
  const { pricing_details } = useSelector(state => state.document)
  const { user } = useSelector(state => state)

  const [visible, setVisible] = useState(false)
  const [years, setYears] = useState(1)
  const [selected_plan_id, setselected_plan_id] = useState(1)

  const [selectedAddons, setselectedAddons] = useState([])
  const [no_users, setNoUsers] = useState(1)
  // const [plans, setPlansData] = useState([])

  const [selectedPlan, setSelectedPlan] = useState(emptyPlan)
  const dispatch = useDispatch()

  useEffect(() => {
    if (Object.keys(pricing_details).length == 0) {
      dispatch(get_pricing_plans())
    }
  }, [visible])

  var user_pricing_details = {
    1: {
      base_price: 500,
      plans_included: [4, 5, 6],
      discounts: {
        '1': 0,
        '2': 10,
        '3': 20,
        '4': 30,
      },
    },
    3: {
      base_price: 1500,
      plans_included: [4, 5, 6],
      discounts: {
        '1': 25,
        '2': 30,
        '3': 40,
        '4': 50,
      },
    },
  }

  if (user?.selectedCompany?.country_code != 'IN') {
    user_pricing_details[1]['base_price'] = 100
    user_pricing_details[3]['base_price'] = 500
  }

  const online_store_price = 4200
  const online_store_custom_domain_price = 6200
  const recurring_invoices_price = 2200
  const dotOptions = { minimumFractionDigits: 2, maximumFractionDigits: 2 }
  const addOnsList = [
    // 'Online Store',
    // 'Online Store with Custom Domain',
    // 'Recurring Invoices',
    'Additional Users',
  ]

  const get_additional_users = (years, users) => {
    if (user_pricing_details[years].plans_included.includes(selected_plan_id)) {
      users -= 2
      return String(users)
    }
    return '0'
  }

  const get_discount_string = (years, no_of_users) => {
    let discount = '0'
    let users = parseInt(no_of_users)

    if (user_pricing_details[years].plans_included.includes(selected_plan_id)) {
      users -= 2
    }
    if (users > 0) {
      discount = user_pricing_details[years].discounts[users]
    }
    return String(discount)
  }

  const get_users_price = (years, no_users) => {
    if (no_users) {
      let users = parseInt(no_users)

      if (user_pricing_details[years].plans_included.includes(selected_plan_id)) {
        users -= 2
      }

      let discount = user_pricing_details[years].discounts[users] || 0

      return user_pricing_details[years].base_price * users * (1 - discount / 100)
    } else {
      return 0
    }
  }
  const addons_pricing = {
    'Online Store': years == 1 ? 4200 : 4200 * 2 + 500,
    'Online Store with Custom Domain': years == 1 ? 6200 : 6200 * 2 + 500,
    'Recurring Invoices': years == 1 ? 2200 : 2200 * 2 + 500,
    'Additional Users': get_users_price(years, no_users),
  }

  const showModal = (plan_id, num_years = 1) => {
    setVisible(true)
    setselected_plan_id(plan_id)
    setSelectedPlan(get_plan(plan_id))
    setYears(num_years)
    if (plan_id != 1) {
      setNoUsers(2)
    } else {
      setNoUsers(1)
    }
    setselectedAddons([])
    getAPI('pricing', 'get_interested_users', {
      plan_id,
      num_years,
    })
  }
  useImperativeHandle(ref, () => ({
    showModal,
  }))
  const get_plan = plan_id => {
    let plan = pricing_details.pricing_plans.filter(plan => plan.plan_id == plan_id)
    return plan ? plan[0] : emptyPlan
  }

  const get_total_amount = () => {
    let total_amount = 0
    if (selectedPlan.plan_id != -1) {
      total_amount += selectedPlan.prices.filter(each => each.year == years)[0].price
      if (selectedAddons.includes('Additional Users')) {
        total_amount += addons_pricing['Additional Users']
      }
      if (
        selectedAddons.includes('Online Store') ||
        selectedAddons.includes('Online Store with Custom Domain')
      ) {
        if (selectedAddons.includes('Online Store with Custom Domain')) {
          total_amount += addons_pricing['Online Store with Custom Domain']
        } else {
          total_amount += addons_pricing['Online Store']
        }
      }
    }

    if (selectedAddons.includes('Recurring Invoices')) {
      total_amount += addons_pricing['Recurring Invoices']
    }
    return total_amount
  }
  const handleChange = (addon, checked) => {
    const nextselectedAddons = checked
      ? [...selectedAddons, addon]
      : selectedAddons.filter(t => t !== addon)
    setselectedAddons(nextselectedAddons)
  }

  return (
    <Modal
      open={visible}
      className=""
      onCancel={() => {
        setVisible(false)
      }}
      width={'100%'}
      style={{ maxWidth: '1000px' }}
      centered
      footer={<FooterComponent />}
      closeIcon={<i className="fa-solid fa-close fa-lg" />}
      title={
        <div className="">
          {props.is_renewal ? (
            <div>
              <h4 className="font-weight-bolder mb-0 pb-0">
                Renew your plan
                <br />
                <span className="text-blue font-size-12">
                  {props.renewal_dates?.start_date} to{' '}
                  {years == 1
                    ? props.renewal_dates?.end_date_1_year
                    : props.renewal_dates?.end_date_3_years}
                </span>{' '}
                {!window.location.pathname.includes('pricing') && (
                  <a
                    href={window.location.origin + '/pricing'}
                    target="_blank"
                    className="text-blue font-size-12"
                    style={{ textDecoration: 'underline' }}
                  >
                    Check all Plans
                  </a>
                )}
              </h4>
            </div>
          ) : (
            <h4 className="font-weight-bolder">Upgrade to our premium plan</h4>
          )}
        </div>
      }
      wrapStyle={{ backdropFilter: 'blur(3px)' }}
    >
      <div className="">
        <Card className="p-0 card-no-padding">
          <div className="d-flex w-100">
            {!isMobile && (
              <div className="m-4 width-45p">
                <h3 className="font-weight-bolder">{selectedPlan.title}</h3>
                <p className="font-size-18">{selectedPlan.sub_title}</p>
                <p className="font-size-18 font-weight-bold">What's included?</p>
                {selectedPlan.features.map((item, index) => {
                  return (
                    <p key={index} className="font-size-14 text-gray-600 mb-3">
                      <i class="fa-solid fa-circle-check text-green font-size-18 mr-2"></i>
                      {item.title}
                      {item.description}
                    </p>
                  )
                })}
              </div>
            )}
            <div className="p-4  width-55p bg-gray-75 d-flex flex-column justify-content-between">
              <div className="flex-grow-1">
                {selectedPlan.prices.map((item, index) => {
                  return (
                    <Card
                      className={`cursor-pointer ${index > 0 ? 'mt-3' : ''} ${
                        years == item.year ? 'bg-lblue border-primary' : 'bg-white border-gray-500'
                      }`}
                      onClick={() => setYears(item.year)}
                    >
                      <div className="d-flex align-items-center">
                        {years == item.year ? (
                          <i
                            className="fa-solid fa-circle-check fa-fw fa-lg mr-2"
                            style={{ color: 'blue' }}
                          ></i>
                        ) : (
                          <i className="fa-regular fa-circle fa-fw fa-lg mr-2 text-gray-500"></i>
                        )}
                        <div className="flex-grow-1 d-flex flex-column">
                          <span className="d-flex justify-content-between w-100">
                            <span className="font-weight-bold">
                              {item.per}
                              {<span className="text-green font-weight-bold"> {item.offer}</span>}
                            </span>
                            <div className="d-flex ">
                              <span className="text-gray-400">
                                <del>{item.strike_amount}</del>
                              </span>

                              <span className="font-weight-bold ml-2">
                                {item.price.toLocaleString('en-IN', dotOptions)}
                              </span>
                            </div>
                          </span>

                          {item.year == 3 && (
                            <span className="font-size-14 text-green mt-1">
                              Most Subscribed! Buy 2 years & Get 1 year FREE! 🔥🚀
                            </span>
                          )}
                        </div>
                      </div>
                    </Card>
                  )
                })}
              </div>
              <div className="ml-2 ">
                <label className="font-size-14 font-weight-medium mt-5">Addons:</label>

                {addOnsList.map((addon, index) => {
                  return (
                    <>
                      <div className="d-flex justify-content-between w-100 mb-2 mb-0" key={index}>
                        <span className="font-size-13 text-gray-400">{addon}</span>
                        {selectedAddons.includes(addon) ? (
                          <h6>
                            <span
                              className="font-weight-normal cursor-pointer text-gray-400 mx-2"
                              onClick={() => {
                                handleChange(addon, false)
                              }}
                            >
                              Remove
                            </span>
                            {pricing_details.currency_symbol}{' '}
                            {addons_pricing[addon].toLocaleString('en-IN', dotOptions)}
                          </h6>
                        ) : (
                          <span
                            className="font-size-13 cursor-pointer"
                            style={{
                              textDecoration: 'underline',
                            }}
                            onClick={() => {
                              handleChange(addon, true)
                            }}
                          >
                            Add
                          </span>
                        )}
                      </div>
                      {selectedAddons.includes(addon) && addon == 'Additional Users' && (
                        <>
                          <span className="label mt-3 text-gray-400 font-size-13">
                            No of Users:
                          </span>
                          <Select
                            onChange={e => {
                              if (selected_plan_id != 1 && e >= 2) {
                                setNoUsers(parseInt(e))
                              }
                              if (selected_plan_id == 1) {
                                setNoUsers(parseInt(e))
                              }
                            }}
                            value={no_users}
                            className="w-100 mb-2"
                            dropdownRender={menu => (
                              <>
                                {menu}
                                <Divider />
                                <span className="text-gray-400 font-size-11 ml-2 mt-0">
                                  <a
                                    className="ml-2 text-blue"
                                    href="https://api.whatsapp.com/send?phone=918121335436&text=Hello%20Swipe!%20I%20need%20more%20than%204%20users"
                                    target="_blank"
                                  >
                                    Contact our support
                                  </a>{' '}
                                  for more than 4 users.
                                </span>
                              </>
                            )}
                          >
                            <Option value="1">
                              1
                              {get_discount_string(years, 1) != '0' && (
                                <span className="font-size-12 text-forest ml-1">
                                  ({get_discount_string(years, 1)}% OFF)
                                </span>
                              )}
                              {selected_plan_id != 1 && (
                                <span className="font-size-12 text-forest ml-2">
                                  included in plan
                                </span>
                              )}
                            </Option>
                            <Option value="2">
                              2
                              {get_discount_string(years, 2) != '0' && (
                                <span className="font-size-12 text-forest ml-1">
                                  ({get_discount_string(years, 2)}% OFF)
                                </span>
                              )}
                              {selected_plan_id != 1 && (
                                <span className="font-size-12 text-forest ml-2">
                                  included in plan
                                </span>
                              )}
                            </Option>
                            <Option value="3">
                              3
                              {get_discount_string(years, 3) != '0' && (
                                <span className="font-size-12 text-forest ml-1">
                                  ({get_discount_string(years, 3)}% OFF)
                                </span>
                              )}
                              {get_additional_users(years, 3) != '0' && (
                                <span className="float-right font-size-12 text-forest">
                                  + {get_additional_users(years, 3)}
                                </span>
                              )}
                            </Option>
                            <Option value="4">
                              4
                              {get_discount_string(years, 4) != '0' && (
                                <span className="font-size-12 text-forest ml-1">
                                  ({get_discount_string(years, 4)}% OFF)
                                </span>
                              )}
                              {get_additional_users(years, 4) != '0' && (
                                <span className="float-right font-size-12 text-forest">
                                  + {get_additional_users(years, 4)}
                                </span>
                              )}
                            </Option>
                          </Select>
                        </>
                      )}
                    </>
                  )
                })}
              </div>

              <Divider className="mt-2" />
              <div className="d-flex justify-content-between mt-2 ml-2">
                <h6 className="font-size-21 font-weight-bold">Total</h6>
                <h6 className="font-size-21 font-weight-bold">
                  {pricing_details.currency_symbol}{' '}
                  {get_total_amount().toLocaleString('en-IN', dotOptions)}
                </h6>
              </div>

              <Button
                type="primary"
                size="large"
                className="w-100 arrow-transition mt-4"
                onClick={() => {
                  let addons = {
                    additional_users: selectedAddons.includes('Additional Users') ? no_users : 0,
                    online_store: selectedAddons.includes('Online Store') ? 1 : 0,
                    online_store_with_custom_domain: selectedAddons.includes(
                      'online_store_with_custom_domain',
                    )
                      ? 1
                      : 0,
                    recurring_invoices: selectedAddons.includes('Recurring Invoices') ? 1 : 0,
                  }
                  props.onSelectPlan(selectedPlan.plan_id, years, addons)
                  setTimeout(() => {
                    setVisible(false)
                  }, 2000)
                }}
              >
                Subscribe Now <i className="fa-solid fa-arrow-right ml-2"></i>
              </Button>
              <span className="text-gray-400 mt-2 font-size-11 ml-2">
                It is OK to change your mind. Get a refund if you place the request within 15 days
                of purchase!{' '}
                <a href="https://getswipe.in/terms" target="_blank" className="text-blue">
                  T&C Apply.
                </a>
              </span>
            </div>
          </div>
        </Card>
      </div>
    </Modal>
  )
})

export default CustomPricing
