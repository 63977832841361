import { PlusOutlined } from '@ant-design/icons'
import { Button, Card, Drawer, Form, Input, Switch, Table, Tabs } from 'antd'
import CustomDeleteModal from 'components/modal/delete/customFieldDelete'
import { formItemLayout } from 'components/other/utility'
import React, { forwardRef, useEffect, useImperativeHandle, useState } from 'react'
import { useSelector } from 'react-redux'
import { getAPI } from 'services/jwt'
const { TabPane } = Tabs
const DocumentPrefixes = forwardRef((props, ref) => {
  const [document_type, setDocumentType] = useState('')
  const [all_document_prefixes, setAllDocumentPrefixes] = useState([])
  const [visible, setVisible] = useState(false)
  const [show_edit_prefix, setShowEditPrefix] = useState(false)
  const [prefix_data, setPrefixData] = useState({})
  const user = useSelector(state => state.user)
  const empty_prefix_data = {
    id: -1,
    prefix: '',
    document_type: document_type,
    is_default: false,
    is_delete: false,
  }
  const prefixFormRef = React.createRef()
  const deleteModalRef = React.createRef()

  const get_document_prefixes = async document_type => {
    const data = await getAPI('utils', 'get_document_prefix', { document_type })
    if (data) {
      if (document_type == 'all') {
        setAllDocumentPrefixes(data.prefixes)
      } else {
        let new_document_prefixes = all_document_prefixes.filter(
          item => item.document_type != document_type,
        )
        new_document_prefixes = [...new_document_prefixes, ...data.prefixes]
        setAllDocumentPrefixes(new_document_prefixes)
      }
    }
  }
  useImperativeHandle(ref, () => ({
    show_drawer,
  }))
  const show_drawer = document_type => {
    get_document_prefixes('all')
    setDocumentType(document_type)
    setVisible(true)
  }
  const update_prefix = async req => {
    const data = await getAPI('utils', 'document_prefix', req)
    if (data) {
      get_document_prefixes(document_type)
    }
  }
  // const edit_prefix = data => {
  //   setPrefixData(data)
  //   setShowEditPrefix(true)
  // }
  const closeDrawer = () => {
    setVisible(false)
    props?.onFinish()
  }
  const columns = [
    {
      title: 'Prefix',
      dataIndex: 'prefix',
      key: 'prefix',
    },
    {
      title: 'Default',
      dataIndex: 'is_default',
      render: (text, data) => {
        return (
          <Switch
            disabled={data.is_default}
            checked={data.is_default}
            onChange={() => update_prefix({ ...data, is_default: !data.is_default })}
          />
        )
      },
    },
    {
      title: 'Actions',
      render: (text, data) => {
        return (
          <div>
            <a
              onClick={e => prefixFormRef.current.show_prefix_form(data)}
              className="card-link font-weight-bold text-action font-size-14 mx-4"
            >
              <span>Edit</span>
              <i className="fa fa-pencil-o" />
            </a>
            {!data.is_default && (
              // <Popconfirm
              //   title="Sure to delete?"
              //   onConfirm={e => update_prefix({ ...data, is_delete: true })}
              // >
              //   <span className="text-danger font-weight-bold" style={{ cursor: 'pointer' }}>
              //     Delete
              //   </span>
              // </Popconfirm>
              <span
                className="text-danger font-weight-bold ml-5"
                style={{ cursor: 'pointer' }}
                onClick={() => deleteModalRef?.current?.showDeleteModal(data, true)}
              >
                Delete
              </span>
            )}
          </div>
        )
      },
    },
  ]
  return (
    <Drawer
      open={visible}
      onClose={() => closeDrawer()}
      width={'50%'}
      closeIcon={<i className="fa-solid fa-lg fa-xmark"></i>}
      title={'Document Prefixes'}
    >
      <Card className="mb-4">
        <Tabs
          activeKey={document_type}
          onChange={e => {
            setDocumentType(e)
          }}
        >
          <TabPane tab="Invoice" key="invoice"></TabPane>
          <TabPane tab="Purchase" key="purchase"></TabPane>
          <TabPane tab="Sales Return" key="sales_return"></TabPane>
          <TabPane tab="Purchase Return" key="purchase_return"></TabPane>
          <TabPane tab="Purchase Order" key="purchase_order"></TabPane>
          {user?.selectedCompany?.country_code == 'IN' && (
            <TabPane tab="Delivery Challan" key="delivery_challan"></TabPane>
          )}
          <TabPane tab="Quotation" key="estimate"></TabPane>
          <TabPane tab="Pro Forma Invoice" key="pro_forma_invoice"></TabPane>
          <TabPane tab="Subscription" key="subscription"></TabPane>
        </Tabs>
        <Table
          dataSource={all_document_prefixes.filter(item => item.document_type == document_type)}
          columns={columns}
          pagination={false}
        />

        <Button
          type="dashed"
          style={{ borderColor: 'blue' }}
          onClick={() => prefixFormRef.current.show_prefix_form(empty_prefix_data)}
          block
          icon={<PlusOutlined className="font-weight-bold" style={{ color: 'blue' }} />}
        >
          <span className="font-weight-bold" style={{ color: 'blue' }}>
            Add Prefix
          </span>
        </Button>
      </Card>
      <PrefixForm ref={prefixFormRef} onFinish={() => get_document_prefixes(document_type)} />
      <CustomDeleteModal ref={deleteModalRef} onFinish={update_prefix} />
    </Drawer>
  )
})

export const PrefixForm = forwardRef((props, ref) => {
  const [prefix, setPrefix] = useState('')
  const [visible, setVisible] = useState(false)
  const [prefix_data, setPrefixData] = useState({})
  const formRef = React.createRef()
  useImperativeHandle(ref, () => {
    return {
      show_prefix_form,
    }
  })
  useEffect(() => {
    if (visible) {
      formRef.current.setFieldsValue(prefix_data)
    }
  }, [visible])
  const show_prefix_form = prefix_data => {
    setPrefixData(prefix_data)
    setVisible(true)
  }
  const submitPrefix = async () => {
    const data = await getAPI('utils', 'document_prefix', { ...prefix_data, prefix: prefix })
    if (data.success) {
      setVisible(false)
      props.onFinish()
    }
  }
  return (
    <Drawer
      title={'Add/Edit Prefix'}
      open={visible}
      onClose={() => setVisible(false)}
      width={'50%'}
      footer={
        <>
          <Button type="primary" onClick={submitPrefix}>
            Submit
          </Button>
        </>
      }
      closeIcon={<i className="fa-solid fa-lg fa-xmark"></i>}
    >
      <Card className="mb-4">
        <Form {...formItemLayout} onFinish={submitPrefix} className="" ref={formRef}>
          <Form.Item
            label="Prefix"
            name="prefix"
            // rules={[
            //   {
            //     required: true,
            //     message: 'Please input prefix!',
            //   },
            // ]}
          >
            <Input placeholder="Prefix" onChange={e => setPrefix(e.target.value)} value={prefix} />
          </Form.Item>
        </Form>
      </Card>
    </Drawer>
  )
})
export default DocumentPrefixes
