import { Modal } from 'antd'
import React from 'react'

import EditableTable from 'pages/doc/create'

export default class EditableCreate extends React.Component {
  constructor(props) {
    super(props)

    this.state = {
      loaded: false,
      document_type: 'invoice',
      visible: false,
      action: 'create',
      loading: true,
    }
  }

  async componentDidMount() {
    this.props.onRef(this)
  }

  componentWillUnmount() {
    // fix Warning: Can't perform a React state update on an unmounted component
    this.setState = (state, callback) => {
      return
    }
  }

  showModal = action => {
    this.setState({
      visible: true,
      action: action,
    })
  }

  render() {
    return (
      <Modal
        open={this.state.visible}
        onCancel={() => this.setState({ visible: false, loaded: true }, () => this.props.onClose())}
        bodyStyle={{ height: '92vh', top: 10, overflowY: 'auto' }}
        footer={null}
        width={'90vw'}
        destroyOnClose={true}
        maskClosable={false}
        centered={true}
        closeIcon={<i className="fa-solid fa-lg fa-xmark"></i>}
      >
        <div className="pt-3">
          <EditableTable
            document_type={'invoice'}
            external_create={true}
            customer_id={this.props.customerId}
          />
        </div>
      </Modal>
    )
  }
}

export class EnhancedCreate extends React.Component {
  constructor(props) {
    super(props)
  }
  render() {
    return <EditableCreate {...this.props} />
  }
}
