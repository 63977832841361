// eslint-disable-next-line no-unused-vars
import { Affix, Button, Card, Divider, Layout } from 'antd'
import React, { useEffect } from 'react'
import { isMobile } from 'react-device-detect'
import { FormattedMessage } from 'react-intl'
import { Link, withRouter } from 'react-router-dom'
import { getAPI } from 'services/jwt'

const PublicLayout = ({ children }) => {
  const [is_paid, setIsPaid] = React.useState(true)
  const [company_id, setCompanyID] = React.useState(0)

  useEffect(() => {
    let url = window.location.href
    url = url.split('/')
    // if reports page
    if (url[url.length - 1] == 'reports') {
      setIsPaid(true)
      return
    } else {
      get_paid_status(url[url.length - 1])
    }
  }, [])
  const get_paid_status = async id => {
    if (id != '') {
      const data = await getAPI('utils', 'paid_status_public', { hash_id: id })
      if (data) {
        setIsPaid(data.paid_status == 1)
        setCompanyID(data.company_id)
      }
    }
  }

  return (
    <Layout className="bg-gray-50">
      <Layout.Content
        className="bg-gray-50 mt-0 mb-4 pt-md-5"
        style={{ position: 'relative', overflowX: 'hidden' }}
      >
        <div className="cui__utils__content">{children}</div>
      </Layout.Content>

      {is_paid == false && (
        <Affix offsetBottom={isMobile ? 9 : 18}>
          <div className="d-flex justify-content-center">
            {/*On Mobile*/}
            <Card className="bg-card-azure border-radius-medium mb-3 px-4 d-block d-sm-none">
              <div className="d-flex flex-column align-items-center">
                <div className="">
                  <span className="font-weight-bolder text-black font-size-18">
                    Create invoices in 10 seconds 🤩
                  </span>
                </div>

                <div className="">
                  <Button
                    type="sign-up"
                    size=""
                    onClick={event => window.open('https://getswipe.in?_campaignname=DocLinkFree')}
                    className="font-weight-bolder mt-3"
                  >
                    Try now for free 🚀
                  </Button>
                </div>
              </div>
            </Card>

            {/*On web*/}
            <Card className="mx-5 bg-card-azure border-radius-medium width-50p d-none d-sm-block">
              <div className="d-flex flex-row justify-content-between align-items-center">
                <div className="mr-4">
                  <div className="d-flex flex-row align-items-center">
                    <div className="mr-4">
                      <img
                        //src="/resources/images/swipe-white.svg"
                        src="/resources/images/swipe-black.svg"
                        className="height-32 pl-4"
                        alt="Swipe"
                      />
                    </div>

                    <div className="">
                      <span className="font-weight-bolder font-size-24 text-black">
                        Easily create invoices in 10 Seconds 🤩
                      </span>
                      <p className="mt-0 mb-0 font-size-16 text-black">
                        and share them with your customers!
                      </p>
                    </div>
                  </div>
                </div>

                <div className="mr-4">
                  <Button
                    type="sign-up"
                    size="large"
                    onClick={event => window.open('https://getswipe.in?_campaignname=DocLinkFree')}
                    className="font-weight-bolder float-right"
                  >
                    Try now for free 🚀
                  </Button>
                </div>
              </div>
            </Card>
          </div>
        </Affix>
      )}

      {/* removing this condition keeping for all companies. (is_paid == true && company_id > 400000))*/}

      {((is_paid == false && company_id != 0) || is_paid == true) && (
        <div className="d-flex justify-content-center align-items-center mb-5 mt-4">
          <Link
            to={{ pathname: 'https://getswipe.in?_campaignname=DocLinkPaid' }}
            target="_blank"
            className="p-0 font-size-13"
          >
            <span className="mr-1 text-gray-300 font-weight-medium">Powered by</span>
            <img
              src="/resources/images/logo_swipe_gray.svg"
              className="height-16 mr-1 align-text-bottom"
              alt="Swipe"
            />
          </Link>
          <Divider type="vertical" />
          <p className="font-size-13 mb-0 text-gray-300 ml-1">
            <Link
              to={{ pathname: 'https://getswipe.in/terms' }}
              target="_blank"
              className="font-weight-medium text-gray-300"
            >
              {' '}
              <FormattedMessage id="terms" />
            </Link>{' '}
            &{' '}
            <Link
              to={{ pathname: 'https://getswipe.in/policy' }}
              target="_blank"
              className="font-weight-medium text-gray-300"
            >
              <FormattedMessage id="Privacy" />
            </Link>
          </p>
        </div>
      )}
    </Layout>
  )
}

export default withRouter(PublicLayout)
