import { CountryActions } from './types'

export interface GetCountryPayload {
  country_code: string
}

export interface CountryInfo {
  states: string[]
}

export interface GetCountryAction {
  type: CountryActions.GET_COUNTRY_INFO
  payload: GetCountryPayload
}

export interface SetCountryAction {
  type: CountryActions.SET_COUNTRY_INFO
  payload: CountryInfo
}

export interface SetLaunchCountriesAction {
  type: CountryActions.SET_LAUNCH_COUNTRIES
  payload: CountryInfo[]
}

export type CountrySetActions = SetCountryAction | SetLaunchCountriesAction

export const getCountryInfo = (payload: GetCountryPayload) => {
  return {
    type: CountryActions.GET_COUNTRY_INFO,
    payload,
  }
}

export const setCountryInfo = (payload: SetCountryAction['payload']) => {
  return {
    type: CountryActions.SET_COUNTRY_INFO,
    payload,
  }
}

export const setLaunchCountries = (payload: SetLaunchCountriesAction['payload']) => {
  return {
    type: CountryActions.SET_LAUNCH_COUNTRIES,
    payload,
  }
}
