import { Image } from 'antd'
import { EventEmitter } from 'events'
import noServer from 'lotties/404.json'
import noInternet from 'lotties/no_internet.json'
import React, { Component } from 'react'
import Lottie from 'react-lottie'
import { connect } from 'react-redux'
import { config } from 'services/axios'
const eventEmitter = new EventEmitter()

let serverStatus = false

export default function(ComposedComponent) {
  class NetworkDetector extends Component {
    state = {
      isDisconnected: false,
      networkError: false,
      serverError: false,
      net_img: '',
      server_img: '',
    }

    componentDidMount() {
      // this.handleConnectionChange()
      window.addEventListener('online', this.handleServerStatusChange)
      window.addEventListener('offline', this.handleServerStatusChange)
      eventEmitter.on('serverStatus', this.handleServerStatusChange)

      // set timeout of 5 seconds to load the image and clear the interval
      this.img_load_delay = setTimeout(() => {
        this.setState({
          net_img: '/resources/images/no_internet.webp',
          server_img: '/resources/images/no_internet.webp',
        })
      }, 5000)
    }

    componentWillUnmount() {
      window.removeEventListener('online', this.handleServerStatusChange)
      window.removeEventListener('offline', this.handleServerStatusChange)
      eventEmitter.off('serverstatus', this.handleServerStatusChange)
      this.img_load_delay && clearTimeout(this.img_load_delay)
    }

    handleConnectionChange = () => {
      const condition = navigator.onLine ? 'online' : 'offline'
      if (condition === 'offline') {
        this.webPing = setInterval(async () => {
          await fetch('//google.com', {
            mode: 'no-cors',
          })
            .then(() => {
              clearInterval(this.webPing)
              return false
            })
            .catch(() => '')
        }, 2000)
        return true
      }
      return false
    }

    handleServerStatusChange = async () => {
      let retries = 0
      this.setState({ isDisconnected: false, serverError: false, networkError: false })

      while (retries < 3) {
        let serverStatus = await this.serverPing()
        if (serverStatus) {
          retries = retries + 1
        } else {
          break
        }
      }

      if (retries > 2) {
        let networkError = this.handleConnectionChange()
        this.setState({ isDisconnected: true, serverError: true, networkError: networkError })

        if (networkError != false) {
          console.log('networkError', networkError)
        } else {
          console.log('serverError')

          let count = 0
          while (true) {
            count = count + 1
            let serverError = await this.serverPing()
            if (!serverError) {
              this.setState({ isDisconnected: false, serverError: false })
              break
            }
            await wait(count < 10 ? 1000 * count : 60000)
          }

          async function wait(ms) {
            return new Promise(resolve => setTimeout(resolve, ms))
          }
          // check till the server is back
        }
      }
    }

    serverPing = async () => {
      try {
        await fetch(config.url.API_URL + '/', {
          mode: 'no-cors',
        })
        return false
      } catch (error) {
        return true
      }
    }

    componentWillUnmount() {
      clearInterval(this.webPing)
    }

    render() {
      const { isDisconnected } = this.state
      const defaultOptions = {
        loop: true,
        autoplay: true,
        animationData: this.state.networkError ? noInternet : noServer,
        rendererSettings: {
          preserveAspectRatio: 'xMidYMid slice',
        },
      }

      const img_path = this.state.networkError ? this.state.net_img : this.state.server_img

      return (
        <div>
          <div>
            {/* {isDisconnected && ( */}
            <div
              style={{
                minHeight: '50vh',
                display: isDisconnected ? 'flex' : 'none',
                justifyContent: 'center',
                alignItems: 'center',
                position: 'fixed',
                top: 0,
                left: 0,
                right: 0,
                bottom: 0,
                zIndex: 9999,
                backgroundColor: 'rgba(0,0,0,0.1)',
                backdropFilter: 'blur(18px)',
              }}
            >
              <div>
                {/* <Lottie options={defaultOptions} height={400} width={400} /> */}

                {/* <img
                  src={this.state.net_img}
                  alt="No Internet"
                  style={{
                    width: '60%',
                    height: 'auto',
                    display: this.state.networkError ? 'block' : 'none',
                  }}
                  className={`d-flex mx-auto`}
                />

                <img
                  src={this.state.server_img}
                  alt="No Internet"
                  style={{
                    width: '60%',
                    height: 'auto',
                    display: !this.state.networkError && this.state.serverError ? 'block' : 'none',
                  }}
                  className={`d-flex mx-auto`}
                /> */}

                <Image
                  visible={this.state.networkError || this.state.serverError}
                  src={this.state.net_img}
                  alt="No Internet"
                  style={{
                    width: '60%',
                    height: 'auto',
                    display: this.state.networkError || this.state.serverError ? 'flex' : 'none',
                  }}
                  preview={false}
                  className={`mx-auto`}
                />

                {/* <Image
                  visible={!this.state.networkError && this.state.serverError}
                  src={this.state.server_img}
                  alt="No Internet"
                  style={{
                    width: '60%',
                    height: 'auto',
                    display: !this.state.networkError && this.state.serverError ? 'flex' : 'none',
                  }}
                  preview={false}
                  className={`mx-auto`}
                /> */}

                <h3 className="text-center">
                  {this.state.networkError || this.state.serverError
                    ? 'Internet connection is lost. Please check your internet connection.'
                    : 'Looks like a DNS issue. Please contact your internet service provider.'}
                </h3>

                {/* close  */}

                <div
                  className="text-center text-primary cursor-pointer"
                  onClick={() => {
                    this.setState({ isDisconnected: false })
                  }}
                >
                  Continue working offline
                </div>
              </div>
            </div>
            {/* )} */}

            <ComposedComponent {...this.props} />
          </div>
        </div>
      )
    }
  }

  const mapStateToProps = (state, ownProps) => {
    return {
      invoice_settings: state.document.invoiceSettings,
    }
  }

  return connect(mapStateToProps)(NetworkDetector)
}

// Function to update the 'serverstatus' and emit the event
export const updateServerStatus = status => {
  eventEmitter.emit('serverStatus', status)
}
