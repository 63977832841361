import { Text, View } from '@react-pdf/renderer'
import InvoiceTableHeader from './invoiceTableHeader'
import InvoiceTableRow from './invoiceTableRow'
// import InvoiceTableRow from './tablerow'
import { getTotalQty } from 'components/other/calculation'
import { HIDE_PRICES } from '../../../utils/constants'
import { has_multiple_units } from '../export_condition'
import InvoiceHsnTable from '../invoiceHsnTable'
import InvoiceTableBlankSpace from './invoiceTableBlankSpace'
import InvoiceTableFooter from './invoiceTableFooter'
import { styles as getStyles } from './styles'

const InvoiceItemsTable = ({ invoice, type }) => {
  var styles = getStyles(invoice)
  let tableRowsCount = 9
  let currency_symbol = invoice.company.currency_symbol
  let conversion_factor = 1
  if (
    (invoice.is_export == 1 || invoice.is_multi_currency == 1) &&
    invoice.invoice_settings.show_both_currencies != true
  ) {
    currency_symbol = invoice.export_details[0].currency_symbol
    conversion_factor = invoice.export_details[0].conversion_factor
  }
  const has_multiple_unit_products = has_multiple_units(invoice.items)
  if (Object.keys(invoice).includes('einvoice') && invoice.einvoice.length > 0) {
    tableRowsCount = 7
  }

  return (
    <>
      <View style={{ ...styles.invoiceItemsTable_tableContainer, borderColor: '#1d1d1f' }}>
        <InvoiceTableHeader invoice={invoice} type={type} />
        <InvoiceTableRow items={invoice.items} data={invoice} invoice={invoice} type={type} />
        <InvoiceTableBlankSpace
          rowsCount={tableRowsCount - invoice.items.length}
          invoice={invoice}
          type={type}
        />
        {type != 'Delivery Challan' && type != HIDE_PRICES && (
          <>
            {invoice.document_type != 'estimates' && (
              <InvoiceTableFooter
                items={invoice.tax}
                total={invoice.total}
                netAmount={invoice.net_amount}
                invoice={invoice}
              />
            )}
            {invoice.document_type == 'estimates' && invoice.hide_totals == 0 && (
              <InvoiceTableFooter
                items={invoice.tax}
                total={invoice.total}
                netAmount={invoice.net_amount}
                invoice={invoice}
              />
            )}
          </>
        )}
      </View>

      {type != 'Delivery Challan' &&
        type != HIDE_PRICES &&
        invoice.is_export == 0 &&
        invoice.invoice_settings.show_hsn_details == 1 &&
        invoice.with_tax == 1 &&
        invoice.hide_totals == 0 && <InvoiceHsnTable invoice={invoice} type={type} />}

      {(type == 'Delivery Challan' || type == HIDE_PRICES) && !has_multiple_unit_products && (
        <View>
          <Text style={[styles.invoiceItemsTable_dc_totals, styles.invoiceItemsTable_bold]}>
            Total Items / Qty : {invoice.items.length} / {getTotalQty(invoice.items)}{' '}
          </Text>
        </View>
      )}
    </>
  )
}

export default InvoiceItemsTable
