import { Image, Text, View } from '@react-pdf/renderer'
import { breakName } from 'components/other/utility'
import { Fragment } from 'react'
import { HIDE_PRICES } from '../../../utils/constants'
import RichText from '../RichText'
import ItemBatchDetails from '../itemBatchDetails'
import { amountFormatter } from '../utils'
import { styles as getStyles } from './styles'

const InvoiceTableRow = ({ items, data, invoice, type }) => {
  var styles = getStyles(invoice)
  let locale = invoice.company.locale
  let font_style = invoice.invoice_settings.font_style
  if (locale == 'English') {
    locale = font_style
  }
  const hide_hsn = invoice.invoice_settings.hide_hsn
  var FONT_FACTOR = invoice.invoice_settings.font_size
  const options = { maximumFractionDigits: 2, minimumFractionDigits: 2 }
  const price_options = {
    maximumFractionDigits: invoice.invoice_settings.price_max_decimals,
    minimumFractionDigits: 2,
  }
  const MAX_DECIMALS = invoice.invoice_settings.price_max_decimals

  let conversion_factor = 1
  let currency_symbol = invoice.company.currency_symbol

  if (
    (invoice.is_export == 1 || invoice.is_multi_currency == 1) &&
    invoice.invoice_settings.show_both_currencies != true
  ) {
    currency_symbol = invoice.export_details[0].currency_symbol
    conversion_factor = invoice.export_details[0].conversion_factor
  }
  let no_of_default_cols = invoice.invoice_settings.hide_qty ? 8.2 : 9
  no_of_default_cols = hide_hsn ? no_of_default_cols - 0.7 : no_of_default_cols
  let no_of_with_out_tax_cols = invoice.invoice_settings.hide_qty ? 5 : 5.8
  no_of_with_out_tax_cols = hide_hsn ? no_of_with_out_tax_cols - 0.8 : no_of_with_out_tax_cols
  let no_of_with_out_tax_cols_gst = invoice.invoice_settings.hide_qty ? 5 : 6
  no_of_with_out_tax_cols_gst = hide_hsn
    ? no_of_with_out_tax_cols_gst - 1
    : no_of_with_out_tax_cols_gst
  let dc_col = (1 * 100) / (invoice.invoice_settings.hide_qty ? 2 : 3)
  var single_col = (1 * 100) / (no_of_default_cols + invoice.custom_col_names.length)
  var single_col2 = (1 * 100) / (no_of_with_out_tax_cols + invoice.custom_col_names.length)
  var single_col3 = (1 * 100) / (no_of_with_out_tax_cols_gst + invoice.custom_col_names.length)
  var font_size = (FONT_FACTOR * (9 * 9)) / (9 + invoice.custom_col_names.length)
  var font_size2 = (FONT_FACTOR * (10.8 * 15)) / (15 + invoice.custom_col_names.length)

  const rows = items.map((item, i) => (
    <Fragment key={i}>
      {(type == 'Delivery Challan' || type == HIDE_PRICES) && (
        <>
          {item.product_type == 'Product' && (
            <View>
              <View
                style={[styles.invoiceTableRow_row, styles.globalContainer]}
                key={(i + 1).toString()}
                wrap={false}
              >
                <Text
                  hyphenationCallback={e => breakName(e)}
                  style={{ ...styles.invoiceTableRow_slno_dc, width: `${0.2 * dc_col}%` }}
                >
                  {i + 1}
                </Text>
                <View
                  style={{
                    ...styles.invoiceTableRow_description_dc,
                    width: `${1.8 * dc_col}%`,
                  }}
                >
                  <Text style={styles.invoiceTableRow_bold}>
                    {item.name} {item.variant_name}
                  </Text>
                  <ItemBatchDetails item={item} invoice={invoice} />
                  {!hide_hsn && item.hsn_code != '' && (
                    <>
                      <RichText
                        locale={locale}
                        note={
                          item.product_type == 'Product'
                            ? 'HSN: ' + item.hsn_code
                            : 'SAC: ' + item.hsn_code
                        }
                        FONT_FACTOR={0.9 * font_size}
                      />
                    </>
                  )}
                  {item.description != '' &&
                    item.description &&
                    data.show_description &&
                    item.description != '<p><br></p>' && (
                      <RichText locale={locale} note={item.description} FONT_FACTOR={font_size} />
                    )}
                  {invoice.invoice_settings.show_images == 1 &&
                    item.product_images[0].is_empty == 0 && (
                      <View
                        style={{
                          flexDirection: 'row',
                        }}
                      >
                        {item.product_images.map((image, index) => (
                          <Image
                            style={styles.invoiceTableRow_image}
                            src={{
                              uri: image.url,
                              method: 'GET',
                              headers: { 'Cache-Control': 'no-cache' },
                            }}
                            crossorigin="anonymous"
                          />
                        ))}
                      </View>
                    )}
                </View>
                {invoice.invoice_settings.hide_qty == 0 && (
                  <Text style={{ ...styles.invoiceTableRow_qty_dc, width: `${dc_col}%` }}>
                    <Text>
                      {(item.qty * item.conversion_rate).toLocaleString('en-IN', {
                        maximumFractionDigits: 3,
                      })}
                    </Text>
                    {item.unit != '' &&
                      item.unit != 'OTH' &&
                      item.unit != 'NONE' &&
                      item.unit != 'UNDEFINED' && (
                        <Text> {item.item_unit == '' ? item.unit : item.item_unit}</Text>
                      )}
                    {invoice.invoice_settings.show_qty_conversion_rate == 1 &&
                      item.conversion_rate != 1 && (
                        <Text>
                          {'\n'}
                          <RichText
                            locale={locale}
                            note={'(' + parseFloat(item.qty).toFixed(3) + ' ' + item.unit + ')'}
                            FONT_FACTOR={font_size}
                          />
                        </Text>
                      )}
                  </Text>
                )}
              </View>
            </View>
          )}
        </>
      )}

      {type != 'Delivery Challan' && type != HIDE_PRICES && (
        <>
          {invoice.company.gstin.length == 15 ? (
            <Fragment key={i}>
              {invoice.with_tax == 0 ? (
                <View
                  style={[styles.invoiceTableRow_row, styles.globalContainer]}
                  key={(i + 1).toString()}
                  wrap={false}
                >
                  <Text
                    hyphenationCallback={e => breakName(e)}
                    style={{
                      ...styles.invoiceTableRow_cell,
                      textAlign: 'left',
                      fontSize: font_size2,
                      width: `${0.2 * single_col2}%`,
                    }}
                  >
                    {i + 1}
                  </Text>
                  <View
                    style={{
                      ...styles.invoiceTableRow_cell,
                      wordWrap: 'break-word',
                      fontSize: font_size2,
                      textAlign: 'left',
                      width: `${2 * single_col2}%`,
                    }}
                  >
                    <Text style={[styles.invoiceTableRow_bold, styles.invoiceTableRow_italic]}>
                      {item.name} {item.variant_name}
                    </Text>
                    <ItemBatchDetails item={item} invoice={invoice} />
                    {item.description != '' &&
                      item.description &&
                      data.show_description &&
                      item.description != '<p><br></p>' && (
                        <RichText locale={locale} note={item.description} FONT_FACTOR={font_size} />
                      )}
                    {invoice.invoice_settings.show_images == 1 &&
                      item.product_images[0].is_empty == 0 && (
                        <View
                          style={{
                            flexDirection: 'row',
                          }}
                        >
                          {item.product_images.map((image, index) => (
                            <Image
                              style={styles.invoiceTableRow_image}
                              src={{
                                uri: image.url,
                                method: 'GET',
                                headers: { 'Cache-Control': 'no-cache' },
                              }}
                              crossorigin="anonymous"
                            />
                          ))}
                        </View>
                      )}
                  </View>

                  {!hide_hsn && (
                    <Text
                      style={{
                        ...styles.invoiceTableRow_cell,
                        textAlign: 'right',
                        fontSize: font_size2,
                        width: `${0.8 * single_col2}%`,
                      }}
                      hyphenationCallback={e => breakName(e)}
                    >
                      <Text hyphenationCallback={e => breakName(e)}>{item.hsn_code}</Text>
                    </Text>
                  )}

                  {item.custom_col_values.map((each_item, index) => (
                    <Text
                      key={index}
                      style={{
                        ...styles.invoiceTableRow_cell,
                        whiteSpace: 'pre-wrap',
                        overflowWrap: 'break-word',
                        wordWrap: 'break-word',
                        textAlign: 'right',
                        fontSize: font_size2,
                        width: `${single_col2}%`,
                      }}
                    >
                      {each_item == '' ? '-' : each_item}
                    </Text>
                  ))}

                  <View
                    style={{
                      ...styles.invoiceTableRow_cell,
                      textAlign: 'right',
                      fontSize: font_size2,
                      width: `${single_col2}%`,
                    }}
                  >
                    <Text style={styles.invoiceTableRow_med}>
                      {(
                        (item.unit_price * (1 - item.discount / 100)) /
                        conversion_factor
                      ).toLocaleString('en-IN', price_options)}
                    </Text>
                    {invoice.invoice_settings.hide_discount == 0 && item.discount > 0 && (
                      <Text hyphenationCallback={e => breakName(e)}>
                        {(item.unit_price / conversion_factor).toLocaleString(
                          'en-IN',
                          price_options,
                        ) +
                          ' (-' +
                          amountFormatter(item.discount, MAX_DECIMALS) +
                          '%)'}
                      </Text>
                    )}
                  </View>
                  {invoice.invoice_settings.hide_qty == 0 && (
                    <Text
                      style={{
                        ...styles.invoiceTableRow_cell,
                        textAlign: 'right',
                        fontSize: font_size2,
                        width: `${0.8 * single_col2}%`,
                      }}
                    >
                      <Text style={styles.invoiceTableRow_unit}>
                        {(item.qty * item.conversion_rate).toLocaleString('en-IN', {
                          maximumFractionDigits: 3,
                        })}
                      </Text>
                      {item.unit != '' &&
                        item.unit != 'OTH' &&
                        item.unit != 'NONE' &&
                        item.unit != 'UNDEFINED' && (
                          <Text style={styles.invoiceTableRow_unit2}>
                            {' '}
                            {item.item_unit == '' ? item.unit : item.item_unit}
                          </Text>
                        )}
                      {invoice.invoice_settings.show_qty_conversion_rate == 1 &&
                        item.conversion_rate != 1 && (
                          <Text>
                            {'\n'}
                            <RichText
                              locale={locale}
                              note={'(' + parseFloat(item.qty).toFixed(3) + ' ' + item.unit + ')'}
                              FONT_FACTOR={font_size}
                            />
                          </Text>
                        )}
                    </Text>
                  )}
                  <Text
                    style={{
                      ...styles.invoiceTableRow_cell,
                      textAlign: 'right',
                      paddingRight: 4.5,
                      borderRight: 'none',
                      fontSize: font_size2,
                      width: `${single_col2}%`,
                    }}
                  >
                    {(item.net_amount / conversion_factor).toLocaleString('en-IN', options)}
                  </Text>
                </View>
              ) : (
                <View
                  style={[styles.invoiceTableRow_row, styles.globalContainer]}
                  key={(i + 1).toString()}
                  wrap={false}
                >
                  <Text
                    hyphenationCallback={e => breakName(e)}
                    style={{
                      ...styles.invoiceTableRow_cell,
                      textAlign: 'left',
                      fontSize: font_size,
                      width: `${0.4 * single_col}%`,
                    }}
                  >
                    {i + 1}
                  </Text>

                  <View
                    style={{
                      ...styles.invoiceTableRow_cell,
                      fontSize: font_size,
                      width: `${2.5 * single_col}%`,
                    }}
                  >
                    <Text style={[styles.invoiceTableRow_bold]}>
                      {item.name} {item.variant_name}
                    </Text>
                    <ItemBatchDetails item={item} invoice={invoice} />
                    {item.description != '' &&
                      item.description &&
                      data.show_description &&
                      item.description != '<p><br></p>' && (
                        <RichText locale={locale} note={item.description} FONT_FACTOR={font_size} />
                      )}
                    {invoice.invoice_settings.show_images == 1 &&
                      item.product_images[0].is_empty == 0 && (
                        <View
                          style={{
                            flexDirection: 'row',
                          }}
                        >
                          {item.product_images.map((image, index) => (
                            <Image
                              style={styles.invoiceTableRow_image}
                              src={{
                                uri: image.url,
                                method: 'GET',
                                headers: { 'Cache-Control': 'no-cache' },
                              }}
                              crossorigin="anonymous"
                            />
                          ))}
                        </View>
                      )}
                  </View>

                  {item.custom_col_values.map((each_item, index) => (
                    <Text
                      key={index}
                      style={{
                        ...styles.invoiceTableRow_cell,
                        wordWrap: 'break-all',
                        textAlign: 'right',
                        fontSize: font_size,
                        width: `${single_col}%`,
                      }}
                    >
                      {each_item == '' ? '-' : each_item}
                    </Text>
                  ))}

                  {!hide_hsn && (
                    <Text
                      style={{
                        ...styles.invoiceTableRow_cell,
                        textAlign: 'right',
                        fontSize: font_size,
                        width: `${0.7 * single_col}%`,
                      }}
                      hyphenationCallback={e => breakName(e)}
                    >
                      <Text hyphenationCallback={e => breakName(e)}>{item.hsn_code}</Text>
                    </Text>
                  )}

                  <View
                    style={{
                      ...styles.invoiceTableRow_cell,
                      textAlign: 'right',
                      fontSize: font_size,
                      width: `${1.5 * single_col}%`,
                    }}
                  >
                    <Text style={styles.invoiceTableRow_med}>
                      {(
                        (item.unit_price * (1 - item.discount / 100)) /
                        conversion_factor
                      ).toLocaleString('en-IN', price_options)}
                    </Text>
                    {invoice.invoice_settings.hide_discount == 0 && item.discount > 0 && (
                      <Text hyphenationCallback={e => breakName(e)}>
                        {(item.unit_price / conversion_factor).toLocaleString(
                          'en-IN',
                          price_options,
                        ) +
                          ' (-' +
                          amountFormatter(item.discount, MAX_DECIMALS) +
                          '%)'}
                      </Text>
                    )}
                  </View>

                  {invoice.invoice_settings.hide_qty == 0 && (
                    <Text
                      style={{
                        ...styles.invoiceTableRow_cell,
                        fontSize: font_size,
                        textAlign: 'right',
                        width: `${0.8 * single_col}%`,
                      }}
                    >
                      <Text>
                        {(item.qty * item.conversion_rate).toLocaleString('en-IN', {
                          maximumFractionDigits: 3,
                        })}
                      </Text>
                      {item.unit != '' &&
                        item.unit != 'OTH' &&
                        item.unit != 'NONE' &&
                        item.unit != 'UNDEFINED' && (
                          <Text> {item.item_unit == '' ? item.unit : item.item_unit}</Text>
                        )}
                      {invoice.invoice_settings.show_qty_conversion_rate == 1 &&
                        item.conversion_rate != 1 && (
                          <Text>
                            {'\n'}
                            <RichText
                              locale={locale}
                              note={'(' + parseFloat(item.qty).toFixed(3) + ' ' + item.unit + ')'}
                              FONT_FACTOR={font_size}
                            />
                          </Text>
                        )}
                    </Text>
                  )}

                  {/*<Text style={{...styles.invoiceTableRow_disc, fontSize: font_size, width: `${single_col}%`}}></Text>*/}

                  <Text
                    style={{
                      ...styles.invoiceTableRow_cell,
                      textAlign: 'right',
                      fontSize: font_size,
                      width: `${single_col}%`,
                    }}
                  >
                    {(item.net_amount / conversion_factor).toLocaleString('en-IN', options)}
                  </Text>

                  {/*<Text style={styles.invoiceTableRow_tax}>{invoice.company.gstin.length==15?item.tax+"%":"NA"}</Text>*/}

                  <Text
                    hyphenationCallback={e => breakName(e)}
                    style={{
                      ...styles.invoiceTableRow_cell,
                      fontSize: font_size,
                      textAlign: 'right',
                      width: `${1.1 * single_col}%`,
                    }}
                  >
                    {invoice.company.gstin.length == 15
                      ? ((item.tax_amount + item.cess_amount) / conversion_factor).toLocaleString(
                          'en-IN',
                          options,
                        ) +
                        ' (' +
                        item.tax +
                        '%)'
                      : 'NA'}
                    {item.cess != 0 && '\n+' + item.cess + '%'}
                    {item.cess_on_qty_value != 0 && '\n+' + item.cess_on_qty + '/' + item.item_unit}
                  </Text>

                  <Text
                    style={{
                      ...styles.invoiceTableRow_cell,
                      textAlign: 'right',
                      borderRight: 'none',
                      paddingRight: 4.5,
                      fontSize: font_size,
                      width: `${single_col}%`,
                    }}
                  >
                    {(item.total_amount / conversion_factor).toLocaleString('en-IN', options)}
                  </Text>
                </View>
              )}
            </Fragment>
          ) : (
            <View
              style={[styles.invoiceTableRow_row, styles.globalContainer]}
              key={(i + 1).toString()}
              wrap={false}
            >
              <Text
                hyphenationCallback={e => breakName(e)}
                style={{
                  ...styles.invoiceTableRow_cell,
                  textAlign: 'left',
                  fontSize: font_size2,
                  width: `${0.2 * single_col3}%`,
                }}
              >
                {i + 1}
              </Text>
              <Text
                style={{
                  ...styles.invoiceTableRow_cell,
                  textAlign: 'left',
                  fontSize: font_size2,
                  width: `${1.8 * single_col3}%`,
                }}
              >
                <Text style={[styles.invoiceTableRow_bold, styles.invoiceTableRow_italic]}>
                  {item.name} {item.variant_name}
                </Text>
                {/* {invoice.batch_settings.show_batch_name == 1 && (
                  <Text style={styles.invoiceItemsTable_BatchName}> {item.batch_no}</Text>
                )} */}
                {item.description != '' &&
                  item.description &&
                  data.show_description &&
                  item.description != '<p><br></p>' && (
                    <RichText locale={locale} note={item.description} FONT_FACTOR={font_size} />
                  )}
                {invoice.invoice_settings.show_images == 1 && item.product_images[0].is_empty == 0 && (
                  <View
                    style={{
                      flexDirection: 'row',
                    }}
                  >
                    {item.product_images.map((image, index) => (
                      <Image
                        style={styles.invoiceTableRow_image}
                        src={{
                          uri: image.url,
                          method: 'GET',
                          headers: { 'Cache-Control': 'no-cache' },
                        }}
                        crossorigin="anonymous"
                      />
                    ))}
                  </View>
                )}
              </Text>

              {item.custom_col_values.map((each_item, index) => (
                <Text
                  key={index}
                  style={{
                    ...styles.invoiceTableRow_cell,
                    whiteSpace: 'pre-wrap',
                    overflowWrap: 'break-word',
                    wordWrap: 'break-word',
                    textAlign: 'right',
                    fontSize: font_size2,
                    width: `${single_col3}%`,
                  }}
                >
                  {each_item == '' ? '-' : each_item}
                </Text>
              ))}

              {!hide_hsn && (
                <Text
                  style={{
                    ...styles.invoiceTableRow_cell,
                    paddingRight: 4.5,
                    textAlign: 'right',
                    fontSize: font_size2,
                    width: `${single_col3}%`,
                  }}
                  hyphenationCallback={e => breakName(e)}
                >
                  {item.hsn_code}
                </Text>
              )}

              <View
                style={{
                  ...styles.invoiceTableRow_cell,
                  textAlign: 'right',
                  fontSize: font_size2,
                  width: `${single_col3}%`,
                }}
              >
                <Text style={styles.invoiceTableRow_med}>
                  {(
                    (item.unit_price * (1 - item.discount / 100)) /
                    conversion_factor
                  ).toLocaleString('en-IN', price_options)}
                </Text>
                {invoice.invoice_settings.hide_discount == 0 && item.discount > 0 && (
                  <Text hyphenationCallback={e => breakName(e)}>
                    {(item.unit_price / conversion_factor).toLocaleString('en-IN', price_options) +
                      ' (-' +
                      amountFormatter(item.discount, MAX_DECIMALS) +
                      '%)'}
                  </Text>
                )}
              </View>

              {invoice.invoice_settings.hide_qty == 0 && (
                <Text
                  style={{
                    ...styles.invoiceTableRow_cell,
                    textAlign: 'right',
                    fontSize: font_size2,
                    width: `${single_col3}%`,
                  }}
                >
                  <Text style={styles.invoiceTableRow_unit}>
                    {(item.qty * item.conversion_rate).toLocaleString('en-IN', {
                      maximumFractionDigits: 3,
                    })}
                  </Text>
                  {item.unit != '' &&
                    item.unit != 'OTH' &&
                    item.unit != 'NONE' &&
                    item.unit != 'UNDEFINED' && (
                      <Text style={styles.invoiceTableRow_unit2}>
                        {' '}
                        {item.item_unit == '' ? item.unit : item.item_unit}
                      </Text>
                    )}
                  {invoice.invoice_settings.show_qty_conversion_rate == 1 &&
                    item.conversion_rate != 1 && (
                      <Text>
                        {'\n'}
                        <RichText
                          locale={locale}
                          note={'(' + parseFloat(item.qty).toFixed(3) + ' ' + item.unit + ')'}
                          FONT_FACTOR={font_size}
                        />
                      </Text>
                    )}
                </Text>
              )}

              <Text
                style={{
                  ...styles.invoiceTableRow_cell,
                  paddingRight: 4.5,
                  textAlign: 'right',
                  borderRight: 'none',
                  fontSize: font_size2,
                  width: `${single_col3}%`,
                }}
              >
                {(item.net_amount / conversion_factor).toLocaleString('en-IN', options)}
              </Text>
            </View>
          )}
        </>
      )}
    </Fragment>
  ))
  return <Fragment>{rows}</Fragment>
}

export default InvoiceTableRow
