import { updateServerStatus } from 'Hoc/NetworkDetector'
import { message, notification } from 'antd'
import { arrayBufferToBase64 } from 'components/other/utility'
import dayjs from 'dayjs'
import { history } from 'index'
import { GetVersion } from 'pages/firebasejs'
import IntercomSupportProvider from 'pages/support/interCom'
import apiClient, { allowedHostnames } from 'services/axios'
import { startTrace, stopTrace } from 'services/firebase/trace'
import store from 'store'
import { v4 as uuidv4 } from 'uuid'
import { store as reduxStore } from 'index'

const CLIENT_REQUEST_ID = 'X-Request-ID'
const REQUEST_TIMESTAMP = 'Request-Timestamp'
const WEB = 'web'
const CUSTOM_HEADERS = {
  source: WEB,
}

export async function downloadAPI(menuName, api, req, responseType = 'blob') {
  return apiClient
    .post(`/${menuName}/${api}`, req, { responseType: responseType })

    .then(async response => {
      if (response) {
        var data = await convertBlobToJson(response.data)

        if (data.success !== undefined) {
          response.data = data
          return { response, responseType: 'json' }
        } else {
          return { response, responseType: 'blob' }
        }
      }
    })
    .then(async ({ response, responseType }) => {
      if (response) {
        if (responseType === 'json') {
          if (response.data.logout) {
            onSessionExpired(response)
          }
          const { success } = response.data
          if (success) {
            return response.data
          } else {
            notification.open({
              message: <span className="text-danger">Error</span>,
              description: (
                <>
                  <p>{response.data.message} </p>
                  {store.get('paid') != 1 ? (
                    <a
                      className="font-weight-bold text-gray-900"
                      href={
                        'https://api.whatsapp.com/send/?phone=918121335436&text=Hello%20Swipe! I need help with %0a%0aError Message:' +
                        response.data.message +
                        '%0a' +
                        `/${menuName}/${api}`
                      }
                      target="_blank"
                    >
                      <i className="fa fa-whatsapp fa-lg mr-2"></i>
                      Click here for Help/Support
                    </a>
                  ) : (
                    <IntercomSupportProvider
                      type="error"
                      message={`Hello Swipe! I Need help with Error Message: ${response.data.message} - /${menuName}/${api}`}
                    />
                  )}
                </>
              ),
              icon: <i className="fa fa-exclamation-circle text-danger mr-2" />,
              duration: 10,
            })
            return response.data
          }
        } else {
          const url = window.URL.createObjectURL(new Blob([response.data]))
          const link = document.createElement('a')
          link.href = url
          link.setAttribute(
            'download',
            response.headers['content-disposition']
              .replace('attachment; filename=', '')
              .replace(/"/g, ''),
          ) //or any other extension
          document.body.appendChild(link)
          link.click()

          let base64FileName = response.headers['content-disposition']
            .replace('attachment; filename=', '')
            .replace(/"/g, '')

          if (window.Android) {
            try {
              let base64Str = arrayBufferToBase64(await response.data.arrayBuffer())
              window.Android.downloadfileResponse(base64Str, base64FileName)
            } catch (error) {
              console.log(error)
            }
          }

          return response
        }
      }
    })
    .catch(error => {
      console.log(error)
      if (error.response) {
        message.error({
          content: (
            <>
              {' '}
              Something went wrong. Please try again later.{' '}
              <a
                className="ml-2 font-weight-bold"
                href={
                  'https://api.whatsapp.com/send/?phone=918121335436&text=Hello%20Swipe! I need help with '
                }
                target="_blank"
              >
                <i className="fa fa-whatsapp fa-lg mr-2"></i>
                Click here for Help/Support
              </a>
            </>
          ),
        })
      } else {
        updateServerStatus(false)
        message.error('Please check your internet connection and try again.')
      }
    })
}

async function convertBlobToJson(blob) {
  try {
    const jsonString = await blob.text()
    const jsonData = JSON.parse(jsonString)
    return jsonData
  } catch (error) {
    return false
  }
}

export async function deleteAPI(menuName, api) {
  const config = {
    headers: {
      ...CUSTOM_HEADERS,
      [CLIENT_REQUEST_ID]: WEB + '-' + uuidv4(),
      [REQUEST_TIMESTAMP]: dayjs(),
      DeviceHash: store.get('device_hash'),
    },
  }
  return apiClient
    .delete(`/${menuName}/${api}`, config)
    .then(response => {
      if (response) {
        const { success } = response.data
        if (response.data.logout) {
          onSessionExpired(response)
        }
        if (success) {
          // message.success(response.data.message)
          return response.data
        } else {
          notification.open({
            message: <span className="text-danger">Error</span>,
            description: (
              <>
                <p>{response.data.message} </p>
                {store.get('paid') != 1 ? (
                  <a
                    className="ml-2 font-weight-bold"
                    href={
                      'https://api.whatsapp.com/send/?phone=918121335436&text=Hello%20Swipe! I need help with %0a%0aError Message:' +
                      response.data.message +
                      '%0a' +
                      `/${menuName}/${api}`
                    }
                    target="_blank"
                  >
                    <i className="fa fa-whatsapp fa-lg mr-2"></i>
                    Click here for Help/Support
                  </a>
                ) : (
                  <IntercomSupportProvider
                    type="error"
                    message={`Hello Swipe! I Need help with Error Message: ${response.data.message} - /${menuName}/${api}`}
                  />
                )}
              </>
            ),
            icon: <i className="fa fa-exclamation-circle text-danger mr-2" />,
            style: {
              borderRadius: '10px',
              backgroundColor: '#ffffff',
            },
            duration: 10,
          })

          return false
        }
      }
    })
    .catch(error => {
      if (error.response) {
        message.error({
          content: (
            <>
              {' '}
              Something went wrong. Please try again later.{' '}
              {store.get('paid') != 1 ? (
                <a
                  className="ml-2 font-weight-bold"
                  href={
                    'https://api.whatsapp.com/send/?phone=918121335436&text=Hello%20Swipe! I need help with '
                  }
                  target="_blank"
                >
                  <i className="fa fa-whatsapp fa-lg mr-2"></i>
                  Click here for Help/Support
                </a>
              ) : (
                <IntercomSupportProvider
                  type="error"
                  message={`Hello Swipe! I Need help with Error Message: Something went wrong. Please try again later. - /${menuName}/${api}`}
                />
              )}
            </>
          ),
        })
      } else {
        updateServerStatus(false)
        message.error('Please check your internet connection and try again.')
      }
    })
}

export async function getAPI(menuName, api, reqData) {
  notification.destroy()
  const config = {
    headers: {
      ...CUSTOM_HEADERS,
      [CLIENT_REQUEST_ID]: WEB + '-' + uuidv4(),
      [REQUEST_TIMESTAMP]: dayjs(),
      DeviceHash: store.get('device_hash'),
    },
  }
  const trace = startTrace('api-latency', `/${menuName}/${api}`)
  return apiClient
    .post(`/${menuName}/${api}`, reqData, config)
    .then(response => {
      stopTrace(trace)
      if (response) {
        // console.log(response.status)
        if (response.data.logout) {
          onSessionExpired(response)
        }
        const { success } = response.data
        if (success) {
          return response.data
        } else {
          notification.open({
            message: <span className="text-danger">Error</span>,
            description: (
              <>
                <p>{response.data.message} </p>
                {store.get('paid') != 1 ? (
                  <a
                    className="font-weight-bold"
                    href={
                      'https://api.whatsapp.com/send/?phone=918121335436&text=Hello%20Swipe! I need help with %0a%0aError Message:' +
                      response.data.message +
                      '%0a' +
                      `/${menuName}/${api}`
                    }
                    target="_blank"
                  >
                    <i className="fa fa-whatsapp fa-lg mr-2"></i>
                    Click here for Help/Support
                  </a>
                ) : (
                  <IntercomSupportProvider
                    type="error"
                    message={`Hello Swipe! I Need help with Error Message: ${response.data.message} - /${menuName}/${api}`}
                  />
                )}
              </>
            ),
            icon: <i className="fa fa-exclamation-circle text-danger mr-2" />,
            style: {
              borderRadius: '10px',
              backgroundColor: '#ffffff',
            },
            duration: 10,
          })
          return false
        }
      }
    })
    .catch(error => {
      stopTrace(trace)
      if (error.response) {
        notification.open({
          message: <span className="text-danger">Error</span>,
          description: (
            <>
              <p> Something went wrong. Please try again later. </p>
              {store.get('paid') != 1 ? (
                <a
                  className="ml-2 font-weight-bold"
                  href={
                    'https://api.whatsapp.com/send/?phone=918121335436&text=Hello%20Swipe! I need help with %0a%0aError Message:' +
                    'Something went wrong. Please try again later.' +
                    '%0a' +
                    `/${menuName}/${api}`
                  }
                  target="_blank"
                >
                  <i className="fa fa-whatsapp fa-lg mr-2"></i>
                  Click here for Help/Support
                </a>
              ) : (
                <IntercomSupportProvider
                  type="error"
                  message={`Hello Swipe! I Need help with Error Message: Something went wrong. Please try again later. - /${menuName}/${api}`}
                />
              )}
            </>
          ),
          icon: <i className="fa fa-exclamation-circle text-danger mr-2" />,
          style: {
            borderRadius: '10px',
            backgroundColor: '#ffffff',
          },
          duration: 10,
        })
      } else {
        updateServerStatus(false)
        message.error('Please check your internet connection and try again')
      }
    })
}
export async function getAPIPDF(menuName, api, req) {
  return apiClient
    .post(`/${menuName}/${api}`, req, { responseType: 'arraybuffer' })
    .then(response => {
      if (response) {
        if (response.data.logout) {
          onSessionExpired(response)
        }
        // const url = window.URL.createObjectURL(new Blob([response.data]))
        // const link = document.createElement('a')
        // link.href = url
        // link.setAttribute(
        //   'download',
        //   'ewaybill.pdf'
        // ) //or any other extension
        // document.body.appendChild(link)
        // link.click()
        return response
      }
    })
    .catch(error => {
      if (error.response) {
        message.error({
          content: (
            <>
              {' '}
              Something went wrong. Please try again later.{' '}
              <a
                className="ml-2 font-weight-bold"
                href={
                  'https://api.whatsapp.com/send/?phone=918121335436&text=Hello%20Swipe! I need help with '
                }
                target="_blank"
              >
                <i className="fa fa-whatsapp fa-lg mr-2"></i>
                Click here for Help/Support
              </a>
            </>
          ),
        })
      } else {
        message.error('Please check your internet connection and try again.')
      }
    })
}
export async function getAPIData(menuName, api) {
  const config = {
    headers: {
      ...CUSTOM_HEADERS,
      [CLIENT_REQUEST_ID]: WEB + '-' + uuidv4(),
      [REQUEST_TIMESTAMP]: dayjs(),
      DeviceHash: store.get('device_hash'),
    },
  }
  const trace = startTrace('api-latency', `/${menuName}/${api}`)
  return apiClient
    .get(`/${menuName}/${api}`, config)
    .then(response => {
      stopTrace(trace)
      // console.log(response.status)

      if (response) {
        if (response.data.logout) {
          onSessionExpired(response)
        }
        const { success } = response.data
        if (success) {
          return response.data
        } else {
          message.error({
            content: (
              <>
                {response.data.message}{' '}
                {store.get('paid') != 1 ? (
                  <a
                    className="ml-2 font-weight-bold"
                    href={
                      'https://api.whatsapp.com/send/?phone=918121335436&text=Hello%20Swipe! I need help with %0a%0aError Message:' +
                      response.data.message +
                      '%0a' +
                      `/${menuName}/${api}`
                    }
                    target="_blank"
                  >
                    <i className="fa fa-whatsapp fa-lg mr-2"></i>
                    Click here for Help/Support
                  </a>
                ) : (
                  <IntercomSupportProvider
                    type="error"
                    message={`Hello Swipe! I Need help with Error Message: ${response.data.message} - /${menuName}/${api}`}
                  />
                )}
              </>
            ),
          })
          return false
        }
      }
    })
    .catch(error => {
      stopTrace(trace)
      if (error.response) {
        message.error({
          content: (
            <>
              {' '}
              Something went wrong. Please try again later.{' '}
              {store.get('paid') != 1 ? (
                <a
                  className="ml-2 font-weight-bold"
                  href={
                    'https://api.whatsapp.com/send/?phone=918121335436&text=Hello%20Swipe! I need help with '
                  }
                  target="_blank"
                >
                  <i className="fa fa-whatsapp fa-lg mr-2"></i>
                  Click here for Help/Support
                </a>
              ) : (
                <IntercomSupportProvider
                  type="error"
                  message={`Hello Swipe! I Need help with Error Message: Something went wrong. Please try again later. - /${menuName}/${api}`}
                />
              )}
            </>
          ),
        })
      } else {
        updateServerStatus(false)
        message.error('Please check your internet connection and try again.')
      }
    })
}

export async function getMultipartAPI(menuName, api, reqData) {
  const config = {
    headers: {
      ...CUSTOM_HEADERS,
      [CLIENT_REQUEST_ID]: WEB + '-' + uuidv4(),
      [REQUEST_TIMESTAMP]: dayjs(),
      DeviceHash: store.get('device_hash'),
      'Content-Type': 'multipart/form-data',
    },
  }
  return apiClient
    .post(`/${menuName}/${api}`, reqData, config)
    .then(response => {
      if (response) {
        // console.log(response)
        const { success } = response.data
        if (success) {
          return response.data
        } else {
          if (response.data.logout) {
            onSessionExpired(response)
          }
          notification.open({
            message: <span className="text-danger">Error</span>,
            description: (
              <>
                <p>{response.data.message} </p>
                {store.get('paid') != 1 ? (
                  <a
                    className="font-weight-bold"
                    href={
                      'https://api.whatsapp.com/send/?phone=918121335436&text=Hello%20Swipe! I need help with %0a%0aError Message:' +
                      response.data.message +
                      '%0a' +
                      `/${menuName}/${api}`
                    }
                    target="_blank"
                  >
                    <i className="fa fa-whatsapp fa-lg mr-2"></i>
                    Click here for Help/Support
                  </a>
                ) : (
                  <IntercomSupportProvider
                    type="error"
                    message={`Hello Swipe! I Need help with Error Message: ${response.data.message} - /${menuName}/${api}`}
                  />
                )}
              </>
            ),
            icon: <i className="fa fa-exclamation-circle text-danger mr-2" />,
            style: {
              borderRadius: '10px',
              backgroundColor: '#ffffff',
            },
            duration: 10,
          })
          return false
        }
      }
    })
    .catch(error => {
      if (error.response) {
        message.error({
          content: (
            <>
              {' '}
              Something went wrong. Please try again later.{' '}
              <a
                className="ml-2 font-weight-bold"
                href={
                  'https://api.whatsapp.com/send/?phone=918121335436&text=Hello%20Swipe! I need help with '
                }
                target="_blank"
              >
                <i className="fa fa-whatsapp fa-lg mr-2"></i>
                Click here for Help/Support
              </a>
            </>
          ),
        })

        // message.error('Something went wrong. Please try again later.')
      } else {
        updateServerStatus(false)
        message.error('Please check your internet connection and try again.')
      }
    })
}

export async function register(email, password, mobile, name) {
  const req = {
    email,
    password,
    name,
    mobile,
  }
  const data = await getAPI('user', 'signup', req)

  if (data) {
    store.set('id', data.user_id)
    return data
  } else {
    return false
  }
}

export async function getCompanies() {
  // Get the current hostname
  const currentHostname = window.location.hostname

  // Check if the current hostname is in the allowed list
  const isHostnameAllowed = allowedHostnames.some(hostname => currentHostname.includes(hostname))

  if (
    window.location.pathname.includes('/store') ||
    window.location.pathname.includes('/terms') ||
    window.location.pathname.includes('/refund') ||
    window.location.pathname.includes('/privacy') ||
    window.location.pathname.includes('/view') ||
    !isHostnameAllowed
  )
    return true

  const data = await getAPIData('user', 'get_companies')

  if (data.success) {
    return data
  } else {
    return false
  }
}

function saveData(data) {
  store.set('accessToken', data.token)
  if (data.user_details.billing == 'Trial') {
    store.set('trial_days', data.trial_days)
  } else {
    store.set('trial_days', data.trial_days)
  }

  // permissions
  store.set('paid', data.user_details.paid)
  store.set('user_id', data.user_details.user_id)
  store.set('user_details', data.user_details)
  store.set('online_store', data.company.online_store)
  store.set('days_left', data.days_remaining)
  store.set('ewaybill', data.company.ewaybill)
  store.set('einvoice', data.company.einvoice)
  store.set('price_list', data.company.price_list)
  store.set('add_companies', data.company.add_companies)
  store.set('is_pos', data.company.is_pos)
  store.set('intercom', data.integrations?.intercom)

  // user details
  store.set('name', data.user_details.name)
  store.set('email', data.user_details.email)
  store.set('mobile', data.user_details.mobile)
  store.set('billing_status', data.user_details.billing)
  store.set('role', data.user_details.role)
  store.set('start_date', data.user_details.start_date)
  store.set('end_date', data.user_details.end_date)

  if ('invoices_created' in data) {
    store.set('invoices_created', data.invoices_created)
  } else {
    store.set('invoices_created', false)
  }

  store.set('company_details_added', data.company_details_added)
  store.set('bank_details_added', data.bank_details_added)
  store.set('products_added', data.products_added)
  if ('shiprocket_paid' in data) {
    store.set('shiprocket_paid', data.shiprocket_paid)
  } else {
    store.set('shiprocket_paid', 0)
  }
  if ('subscription_paid' in data) {
    store.set('subscription_paid', data.subscription_paid)
  } else {
    store.set('subscription_paid', 0)
  }
  if ('campaigns_paid' in data) {
    store.set('campaigns_paid', data.campaigns_paid)
  } else {
    store.set('campaigns_paid', 0)
  }
  if ('is_export' in data.company) {
    store.set('is_export', data.company.is_export)
  } else {
    store.set('is_export', 0)
  }
  if ('is_multi_currency' in data.company) {
    store.set('is_multi_currency', data.company.is_multi_currency)
  } else {
    store.set('is_multi_currency', 0)
  }
  if ('price_list' in data.company) {
    store.set('price_list', data.company.price_list)
  } else {
    store.set('price_list', 0)
  }
  if ('reminders' in data.company) {
    store.set('reminders', data.company.reminders)
  } else {
    store.set('reminders', 0)
  }
  if ('place' in data.company) {
    if (data.company.place.length > 0) {
      store.set('place_id', data.company.place[0].place_id)
    } else {
      store.set('place_id', '')
    }
  } else {
    store.set('place_id', '')
  }
  store.set('imports', data.company.imports)
  store.set('organization_name', data.company.organization_name)
  store.set('online_store_url', data.company.online_store_url)
  store.set('company_email', data.company.email)
  store.set('custom_domain', data.company.custom_domain)
  store.set('wallet_credits', data.company.wallet_credits)
  store.set('company_name', data.company.company_name)
  store.set('company_details', data.company)
  store.set('company_mobile', data.company.mobile)
  store.set('gstin', data.company.gstin)
  store.set('address_1', data.company.address_1)
  store.set('address_2', data.company.address_2)
  store.set('company_city', data.company.city)
  store.set('company_pincode', data.company.pincode)
  store.set('logo', data.company.logo)
  store.set('is_live', data.is_live)
  store.set('out_of_stock', data.out_of_stock)
  store.set('products_online', data.products_online)
  store.set('posFlag', data.is_pos)
  store.set('show_discount', data.pos_settings.show_discount)
  store.set('company_name_font_size', data.pos_settings.company_name_font_size)
  store.set('organization_name_font_size', data.pos_settings.organization_name_font_size)
  store.set('show_tendered_cash', data.pos_settings.show_tendered_cash)
  store.set('show_google_reviews_qr', data.pos_settings.show_google_reviews_qr)
  store.set('price_edit', data.pos_settings.price_edit)
  store.set('pos_logo', data.pos_settings.pos_logo)
  store.set('send_pos_sms', data.pos_settings.send_pos_sms)
  store.set('custom_barcode_qty', data.pos_settings.custom_barcode_qty)
  store.set('printer_type', data.pos_settings.printer_type)
  store.set('show_company_details', data.pos_settings.show_company_details)
  store.set('pos_notes', data.pos_settings.notes)
  store.set('pos_show_product_description', data.pos_settings.show_product_description)
  store.set('show_taxable_amount', data.pos_settings.show_taxable_amount)
  store.set('show_pkg_date', data.barcode_settings.show_pkg_date)
  store.set('product_name_font_size', data.barcode_settings.product_name_font_size)
  store.set('mrp_font_size', data.barcode_settings.mrp_font_size)
  store.set('show_mrp', data.barcode_settings.show_mrp)
  store.set('barcode_size', data.barcode_settings.barcode_size)
  store.set('noaccess', data.noaccess)
  store.set('tax_labels', data.tax_labels)
}

export async function login(email, password) {
  const req = {
    email,
    password,
  }
  const data = await getAPI('user', 'login', req)

  if (data) {
    if (!data.company_details) {
      store.set('id', data.user_id)
      return 'Bank'
    } else {
      if (data.success) {
        store.set('is_support_login', data.is_support_login)
      }
      saveData(data)
      return data
    }
  } else {
    return false
  }
}

export async function mobileLogin(mobile) {
  if (!store.get('device_hash')) {
    const device_hash = uuidv4()
    await store.set('device_hash', device_hash)
  }
  const req = {
    ...mobile,
  }

  const data = await getAPI('user', 'mobile_login', req)

  if (data.success) {
    message.success(data.message)
    return true
  } else {
    return false
  }
}

export async function verifyOtp(mobile, otp, params, email, accessToken = '') {
  const req = {
    mobile: mobile,
    otp: otp,
    params: params,
    selected_company: store.get('selectedCompany') ? store.get('selectedCompany').company_id : 0,
    // email: email,
    accessToken: accessToken,
  }
  if (!store.get('device_hash')) {
    const device_hash = uuidv4()
    await store.set('device_hash', device_hash)
  }
  const data = await getAPI('user', 'verify_otp', req)

  if (data.success) {
    saveData(data)

    const days_from_start_date = dayjs().diff(
      dayjs(data.company.record_time)
        .add(5, 'hours')
        .add(30, 'minutes'),
      'days',
    )

    if (data.new_user || days_from_start_date < 1) {
      store.set('new_user', true)
      loadClarity()
    }

    return data
  } else {
    message.error(data.message)
    return false
  }
}

export async function currentAccount() {
  // Get the current hostname
  const currentHostname = window.location.hostname

  // Check if the current hostname is in the allowed list
  //TODO: REMOVE THIS
  const isHostnameAllowed = allowedHostnames.some(hostname => currentHostname.includes(hostname))

  if (
    window.location.pathname.includes('/store') ||
    window.location.pathname.includes('/terms') ||
    window.location.pathname.includes('/privacy') ||
    window.location.pathname.includes('/refund') ||
    window.location.pathname.includes('/view') ||
    window.location.pathname.includes('/agent') ||
    !isHostnameAllowed
  )
    return true

  if (store.get('accessToken') == undefined) return false

  try {
    const data = await GetVersion()
    if (data.success) {
      if (store.get('version') != data.version) {
        store.set('version', data.version)
        window.location.reload(true)
      }
    }
  } catch (e) {
    console.log(e)
  }

  if (!store.get('device_hash')) {
    const device_hash = uuidv4()
    await store.set('device_hash', device_hash)
  }

  const data = await getAPIData('user', 'status')

  if (data) {
    saveData(data)

    reduxStore.dispatch({
      type: 'user/SET_STATE',
      payload: {
        selectedCompany: data.company,
      },
    })

    const days_from_start_date = dayjs().diff(
      dayjs(data.company.record_time)
        .add(5, 'hours')
        .add(30, 'minutes'),
      'days',
    )
    if (days_from_start_date < 1) {
      store.set('new_user', true)
      loadClarity()
    }

    return data
  } else {
    updateServerStatus(false)
    message.error(data.message)
    return false
  }
}

export async function company(company) {
  const req = {
    user_id: store.get('id'),
    ...company,
  }
  const data = await getAPI('user', 'company_details', req)

  if (data) {
    saveData(data)
    return data
  } else {
    return false
  }
}

async function onSessionExpired(response) {
  if (response.data.success) {
    message.success(response.data.message)
  } else {
    message.error(response.data?.message ? response.data.message : 'Session Expired')
  }
  await logout()
}

export async function logout() {
  let token_value = await store.get('accessToken')
  if (token_value != null && token_value != undefined) {
    const data = await getAPIData('user', 'logout')
  }
  store.remove('accessToken')
  store.remove('selectedCompany')
  store.remove('is_support_login')
  window.open('/auth/login', '_self')
  return true
}

const loadClarity = () => {
  const script = document.createElement('script')
  script.type = 'text/javascript'
  script.text = `
      (function(c,l,a,r,i,t,y){
          c[a]=c[a]||function(){(c[a].q=c[a].q||[]).push(arguments)};
          t=l.createElement(r);t.async=1;t.src="https://www.clarity.ms/tag/"+i;
          y=l.getElementsByTagName(r)[0];y.parentNode.insertBefore(t,y);
      })(window, document, "clarity", "script", "k57ataet06");
    `

  document.head.appendChild(script)

  return true
}

// Notification click handling

export async function onClickNotification(notification) {
  getAPI('notifications', 'update_notification/web', {
    notification_type: notification.notification_type,
    notification_uid: notification.notification_uid,
    notification_topic: notification.payload?.topic || notification?.topic,
  })

  return completeAction(getAction(notification))
}

function getAction(notification) {
  let redirection = { redirect: '' }

  if (notification?.payload?.web_action) {
    redirection = { redirect: notification.payload.web_action }
  } else if (notification?.web_action) {
    redirection = { redirect: notification.web_action }
  }
  return redirection
}

function completeAction(action) {
  if (action.redirect) {
    history.push(action.redirect)
  }
  return true
}
