import { LoadingOutlined, PlusOutlined } from '@ant-design/icons'
import { Button, Card, Drawer, Form, Input, Upload, message } from 'antd'
import { formItemLayout, getBase64 } from 'components/other/utility'
import React, { Component } from 'react'
import { isMobile } from 'react-device-detect'
import { FormattedMessage } from 'react-intl'
import { getAPIData, getMultipartAPI } from 'services/jwt'

export default class SignatureForm extends Component {
  formRef = React.createRef()
  constructor(props) {
    super(props)
    this.state = {
      signatureData: '',
      visible: false,
      selectedSignature: {},
      loading: false,
    }
  }

  onAdd = () => {
    this.setState({ visible: true })
  }

  async componentDidMount() {
    this.props.onRef(this)
    this.getAllSignatures()
  }

  getAllSignatures = async () => {
    var data = await getAPIData('signature', 'get')
    if (data) {
      const results = data.signatures.map((row, index) => ({
        key: index,
        ...row,
      }))
      this.setState({ signatureData: results })
    }
  }

  componentWillUnmount() {
    this.props.onRef(null)
  }

  customRequest = async ({ onSuccess, onError, file }) => {
    if (!file.url && !file.preview) {
      file.preview = await getBase64(file)
    }
    this.setState({
      file: file,
      selectedSignature: { ...this.state.selectedSignature, image: file.url || file.preview },
    })
  }

  onFinish = async values => {
    this.setState({ loading: true })
    var url = 'add'
    var reqData = {}
    var data = {}
    if (this.state.selectedSignature.id != undefined) {
      url = 'update'
    }

    reqData = { id: this.state.selectedSignature.id }
    const req = { ...values, ...reqData }
    var form_data = new FormData()

    for (var key in this.state.selectedSignature) {
      form_data.set(key, this.state.selectedSignature[key])
    }

    for (var key in values) {
      if (key == 'image') {
        const file = values[key]
        if (file != undefined) {
          form_data.set(key, this.state.file)
        } else {
          form_data.set(key, values[key])
        }
      } else {
        form_data.set(key, values[key])
      }
    }

    data = await getMultipartAPI('signature', url, form_data)
    if (data.success) {
      message.success(data.message)
      this.getAllSignatures()
      this.setState(
        { visible: false, loading: false, selectedSignature: {}, file: undefined },
        () => {
          try {
            this.props.refreshSignatures()
          } catch {}
        },
      )
    }
  }

  render() {
    const antIcon = <LoadingOutlined style={{ fontSize: 24 }} spin />

    const columns = [
      {
        title: 'Signature Name',
        dataIndex: 'signature_name',
      },
      {
        title: 'Image',
        dataIndex: 'image',
        render: (text, currentData) => (
          <span>
            <img src={text} style={{ height: 100 }} />
          </span>
        ),
      },
      {
        title: 'Actions',
        dataIndex: '',
        render: (text, currentData) => (
          <span>
            <a
              onClick={() => {
                this.setState(
                  { visible: true, selectedSignature: currentData, file: currentData.image },
                  () => {
                    this.formRef.current.setFieldsValue({
                      ...currentData,
                    })
                  },
                )
              }}
              className="btn btn-table-sm btn-warning mr-2"
            >
              <i className="fe fe-edit mr-2" />
              Edit
            </a>
          </span>
        ),
        className: 'text-left',
      },
    ]

    const uploadButton = (
      <div>
        {this.state.loading ? <LoadingOutlined /> : <PlusOutlined />}
        <div style={{ marginTop: 8 }}>Upload</div>
      </div>
    )

    return (
      <div>
        <div>
          <Drawer
            title={
              <div className="d-flex justify-content-between align-items-center">
                <span>Signature Details</span>
                <Button
                  type="primary"
                  className="btn btn-primary px-2 mt-2"
                  onClick={() => this.formRef.current.submit()}
                >
                  <FormattedMessage id="button.save&update" />
                </Button>
              </div>
            }
            placement="right"
            width={isMobile ? '90%' : '50%'}
            closeIcon={<i className="fa-solid fa-lg fa-xmark"></i>}
            closable={() => this.setState({ visible: false, selectedSignature: {} })}
            onClose={() => this.setState({ visible: false, selectedSignature: {} })}
            open={this.state.visible}
            footer={[
              <Button
                type="primary"
                className="btn btn-primary px-2 mt-2"
                onClick={() => this.formRef.current.submit()}
              >
                <FormattedMessage id="button.save&update" />
              </Button>,
            ]}
          >
            <Card className="mb-5">
              <Form
                {...formItemLayout}
                labelAlign="left"
                ref={this.formRef}
                name="signatureForm"
                onFinish={this.onFinish}
                onFinishFailed={() => message.error('Check All Details Again')}
                values={{
                  ...this.state.selectedSignature,
                }}
              >
                <Form.Item
                  name="signature_name"
                  rules={[{ required: true, message: 'Please fill Signature Name' }]}
                  extra="Signature Name is only for your reference and will not be shown on the documents."
                  label={'Signature Name'}
                >
                  <Input placeholder="Signature Name (This is only for your reference and will not be shown on the documents" />
                </Form.Item>
                <Form.Item name="image" label={<FormattedMessage id="form.field.signatureImage" />}>
                  <Upload
                    name="image"
                    listType="picture-card"
                    className="avatar-uploader"
                    showUploadList={false}
                    customRequest={this.customRequest}
                  >
                    {this.state.selectedSignature.image != '' &&
                    this.state.selectedSignature.image != undefined ? (
                      <img
                        src={this.state.selectedSignature.image}
                        alt="avatar"
                        style={{ width: '100%' }}
                      />
                    ) : (
                      uploadButton
                    )}
                  </Upload>
                </Form.Item>

                <p className="text-gray-500">
                  Images must be PNG or JPEG, recommended 1:1 (1024 x 1024 pixels) or 4:3 (640 x 480
                  Pixels) aspect ratios.
                </p>
              </Form>
            </Card>
            <span>
              <h4 className="font-weight-bolder font-size-36 mb-4">
                <span className="mr-2">Your Signature.</span>
                <span className="text-gray-10">
                  Your Authority. <i className="fa-solid fa-signature"></i>
                </span>
              </h4>

              <img
                src="/resources/images/signature.jpg"
                className="ml-1 pb-1 height-200"
                alt="Swipe"
              />
            </span>
          </Drawer>
        </div>
        {/* )} */}
      </div>
    )
  }
}

export class EnhancedSignatureForm extends React.Component {
  constructor(props) {
    super(props)
  }
  render() {
    return <SignatureForm {...this.props} />
  }
}
