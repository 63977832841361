import { Text, View } from '@react-pdf/renderer'
import { HIDE_PRICES } from '../../../utils/constants'
import { styles as getStyles } from './styles'

const borderColor = '#142328'

const InvoiceTableHeader = ({ invoice, type }) => {
  var styles = getStyles(invoice)
  var FONT_FACTOR = invoice.invoice_settings.font_size
  let no_of_default_cols = invoice.invoice_settings.hide_qty ? 6 : 7
  let dc_cols = invoice.invoice_settings.hide_qty ? 2 : 3
  var single_col = (1 * 100) / (no_of_default_cols + invoice.custom_col_names.length)
  var dc_col = (1 * 100) / (dc_cols + invoice.custom_col_names.length)

  var font_size = (FONT_FACTOR * (8.1 * 8)) / (8 + invoice.custom_col_names.length)
  const column_labels = invoice.column_labels

  const rows = (
    <>
      {(type == 'Delivery Challan' || type == HIDE_PRICES) && (
        <>
          <View style={styles.invoiceTableHeader_container}>
            <Text style={{ ...styles.invoiceTableHeader_slno_dc, width: `${0.2 * dc_col}%` }}>
              {column_labels.id}
            </Text>
            <Text
              style={{ ...styles.invoiceTableHeader_description_dc, width: `${1.8 * dc_col}%` }}
            >
              {column_labels.product_name}
            </Text>
            {invoice.invoice_settings.hide_qty == 0 && (
              <Text style={{ ...styles.invoiceTableHeader_qty_dc, width: `${dc_col}%` }}>Qty</Text>
            )}
          </View>
        </>
      )}

      {type != 'Delivery Challan' && type != HIDE_PRICES && (
        <View
          style={{
            ...styles.invoiceTableHeader_container,
            fontSize: font_size,
            borderBottomColor: invoice.company.color,
          }}
        >
          <Text
            style={{
              ...styles.invoiceTableHeader_cell,
              textAlign: 'left',
              paddingLeft: 4.5,
              width: `${0.4 * single_col}%`,
              color: '#000000',
            }}
          >
            {column_labels.id}
          </Text>
          <Text
            style={{
              ...styles.invoiceTableHeader_cell,
              textAlign: 'left',
              width: `${2.4 * single_col}%`,
            }}
          >
            {column_labels.product_name}
          </Text>
          {invoice.custom_col_names.map((item, index) => (
            <Text key={index} style={{ width: `${single_col}%` }}>
              {item}
            </Text>
          ))}
          <Text style={{ ...styles.invoiceTableHeader_cell, width: `${1.2 * single_col}%` }}>
            {column_labels.price_with_tax}
          </Text>
          <Text style={{ ...styles.invoiceTableHeader_cell, width: `${single_col}%` }}>
            {invoice.document_type == 'purchases' ||
            invoice.document_type == 'purchase_orders' ||
            invoice.document_type == 'purchase_returns' ||
            invoice.document_type == 'purchase_returns'
              ? 'Purchase Price'
              : 'Selling Price'}
          </Text>
          {invoice.invoice_settings.hide_qty == 0 && (
            <Text style={{ ...styles.invoiceTableHeader_cell, width: `${single_col}%` }}>
              {column_labels.qty}
            </Text>
          )}

          <Text style={{ paddingRight: 4.5, width: `${single_col}%` }}>
            {column_labels.total_amount}
          </Text>
        </View>
      )}
    </>
  )
  return <View fixed>{rows}</View>
}

export default InvoiceTableHeader
