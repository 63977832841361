import { View } from '@react-pdf/renderer'
import { HIDE_PRICES } from '../../../utils/constants'
import { has_multiple_units } from '../export_condition'
import InvoiceTableFooter from './invoiceTableFooter'
import InvoiceTableHeader from './invoiceTableHeader'
import InvoiceTableRow from './invoiceTableRow'
import { styles as getStyles } from './styles'

const tableRowsCount = 3

const InvoiceItemsTable = ({ invoice, type }) => {
  var styles = getStyles(invoice)
  const options = { maximumFractionDigits: 2, minimumFractionDigits: 2 }
  let conversion_factor = 1
  let currency_symbol = invoice.company.currency_symbol
  const is_export = invoice.is_export == 1
  if (
    (invoice.is_export == 1 || invoice.is_multi_currency == 1) &&
    invoice.invoice_settings.show_both_currencies != true
  ) {
    currency_symbol = invoice.export_details[0].currency_symbol
    conversion_factor = invoice.export_details[0].conversion_factor
  }
  const has_multiple_unit_products = has_multiple_units(invoice.items)
  return (
    <>
      <View
        style={{ ...styles.invoiceItemsTable_tableContainer, borderColor: invoice.company.color }}
      >
        <InvoiceTableHeader invoice={invoice} type={type} />
        <InvoiceTableRow items={invoice.items} data={invoice} invoice={invoice} type={type} />
        {type != 'Delivery Challan' && type != HIDE_PRICES && (
          <>
            {invoice.document_type != 'estimates' && (
              <InvoiceTableFooter
                items={invoice.tax}
                total={invoice.total}
                netAmount={invoice.net_amount}
                invoice={invoice}
              />
            )}

            {invoice.document_type == 'estimates' && invoice.hide_totals == 0 && (
              <InvoiceTableFooter
                items={invoice.tax}
                total={invoice.total}
                netAmount={invoice.net_amount}
                invoice={invoice}
              />
            )}
          </>
        )}
      </View>
    </>
  )
}

export default InvoiceItemsTable
