import { Document, Font, Image, Link, Page, Text, View } from '@react-pdf/renderer'
import { registerFont } from 'components/fonts'
import React from 'react'
import { get_invoice_title, get_invoice_type } from '../export_condition'
import BillTo from './billTo'
import CompanyDetails from './company'
import InvoiceBankDetails from './invoiceBankDetails'
import InvoiceItemsTable from './invoiceItemsTable'
import InvoiceNotes from './invoiceNotes'
import InvoiceTitle from './invoiceTitle'
import { styles as getStyles } from './styles'

export default function Invoice({ invoice }) {
  const hyphenationCallback = word => {
    return word.split('')
  }

  registerFont(invoice)

  Font.registerHyphenationCallback(hyphenationCallback)

  var styles = getStyles(invoice)

  var PAPER_SIZE = invoice.invoice_settings.paper_size == 'A4' ? 1 : 0.8
  return (
    <Document
      title={`${invoice.serial_number}_${invoice.company.company_name}`}
      author={invoice.company.company_name}
      creator={invoice.company.company_name}
      producer={invoice.company.company_name}
      pdfVersion="1.7"
    >
      {invoice.type.map((item, index) => (
        <Page
          orientation={invoice.invoice_settings.pdf_orientation}
          wrap
          size={invoice.invoice_settings.paper_size}
          key={index}
          style={{
            ...styles.index_page,
            paddingTop:
              24 * PAPER_SIZE +
              (!!invoice.invoice_settings.margin_top ? invoice.invoice_settings.margin_top : 0),
            paddingBottom:
              48 * PAPER_SIZE +
              (!!invoice.invoice_settings.margin_bottom
                ? invoice.invoice_settings.margin_bottom
                : 0) +
              (invoice.invoice_settings.invoice_footer_image != '' ? 72 : 0),
          }}
        >
          {invoice.invoice_settings.invoice_header_image != '' && (
            <View style={styles.index_brandingLogo}>
              <Image
                style={styles.index_banner}
                src={{
                  uri: invoice.invoice_settings.invoice_header_image,
                  method: 'GET',
                  headers: { 'Cache-Control': 'no-cache' },
                }}
                crossorigin="anonymous"
              />
            </View>
          )}

          <InvoiceTitle
            title={get_invoice_title(
              invoice.document_type,
              invoice.document_title,
              invoice.with_tax,
              invoice.company.gstin,
              item,
            )}
            type={get_invoice_type(invoice.document_type, item)}
            color={invoice.company.color}
            invoice={invoice}
          />
          <CompanyDetails invoice={invoice} type={item} />
          <BillTo invoice={invoice} />
          <InvoiceItemsTable invoice={invoice} type={item} />
          <InvoiceBankDetails invoice={invoice} type={item} />
          <InvoiceNotes invoice={invoice} type={item} />

          <Link
            href={invoice.company.website}
            target="_blank"
            fixed
            style={{
              ...styles.index_footer,
              width: invoice.user_details.paid == 1 ? '100%' : '80%',
              color: invoice.company.color,
              bottom:
                33.3 * PAPER_SIZE +
                (!!invoice.invoice_settings.margin_bottom != 0 &&
                  invoice.invoice_settings.margin_bottom) +
                (invoice.invoice_settings.invoice_footer_image != '' ? 72 : 0),
            }}
          >
            {invoice.company.invoice_footer}
          </Link>
          <Text
            fixed
            render={({ pageNumber, totalPages }) => `Page ${pageNumber} / ${totalPages}`}
            style={{
              ...styles.index_footerNote,
              bottom:
                24 * PAPER_SIZE +
                (!!invoice.invoice_settings.margin_bottom
                  ? invoice.invoice_settings.margin_bottom
                  : 0) +
                (invoice.invoice_settings.invoice_footer_image != '' ? 72 : 0),
            }}
          />
          <Text
            fixed
            style={{
              ...styles.index_footerNote,
              left: 72 * PAPER_SIZE,
              bottom:
                24 * PAPER_SIZE +
                (!!invoice.invoice_settings.margin_bottom
                  ? invoice.invoice_settings.margin_bottom
                  : 0) +
                (invoice.invoice_settings.invoice_footer_image != '' ? 72 : 0),
            }}
          >
            {' '}
            {invoice.signature == '' && invoice.invoice_settings.digitally_signed == 1 && (
              <>This is a computer generated document and requires no signature.</>
            )}
            {invoice.signature != '' && invoice.invoice_settings.digitally_signed == 1 && (
              <>This is a digitally signed document.</>
            )}
          </Text>
          {invoice.user_details.paid != 1 && (
            <View
              fixed
              style={{
                ...styles.index_brandingFooter,
                bottom:
                  18 * PAPER_SIZE +
                  (!!invoice.invoice_settings.margin_bottom
                    ? invoice.invoice_settings.margin_bottom
                    : 0) +
                  (invoice.invoice_settings.invoice_footer_image != '' ? 72 : 0),
              }}
            >
              <View style={styles.index_brandingLogo}>
                <Text style={styles.index_alignRight}>Powered By</Text>
                <Link src="https://getswipe.in" target="_blank">
                  <Image
                    style={styles.index_logo}
                    src={{
                      uri: 'https://vx-erp-logos.s3.ap-south-1.amazonaws.com/swipe_unpaid.png',
                      method: 'GET',
                      headers: { 'Cache-Control': 'no-cache' },
                    }}
                    crossorigin="anonymous"
                  />
                </Link>
              </View>
            </View>
          )}

          {invoice.invoice_settings.watermark != '' && invoice.user_details.paid != 0 && (
            <Image
              fixed
              style={[styles.index_watermark]}
              src={{
                uri: invoice.invoice_settings.watermark,
                method: 'GET',
                headers: { 'Cache-Control': 'no-cache' },
              }}
              crossorigin="anonymous"
            />
          )}

          {/* Footer Banner */}
          {invoice.invoice_settings.invoice_footer_image != '' && (
            <View
              fixed
              style={{
                ...styles.index_footer,
                bottom:
                  24 * PAPER_SIZE +
                  (!!invoice.invoice_settings.margin_bottom
                    ? invoice.invoice_settings.margin_bottom
                    : 0),
              }}
            >
              <Image
                style={styles.index_banner}
                src={{
                  uri: invoice.invoice_settings.invoice_footer_image,
                  //uri: '/resources/images/banner3.png',
                  method: 'GET',
                  headers: { 'Cache-Control': 'no-cache' },
                }}
                crossorigin="anonymous"
              />
            </View>
          )}
        </Page>
      ))}
    </Document>
  )
}
