// eslint-disable-next-line no-unused-vars
import { Layout } from 'antd'
import React, { useEffect } from 'react'
import { useHistory, withRouter } from 'react-router-dom'

import { capitalizeFirstLetters } from 'components/other/utility'
import store from 'store'
// import { useHistory } from 'react-router-dom'

const AgentLayout = ({ children }) => {
  const history = useHistory()

  useEffect(() => {
    let url = window.location.href
    url = url.split('/')
  }, [])

  const logout = e => {
    e.preventDefault()
    store.remove('agentAccessKey')
    history.push('/swipeteam/auth/login')
  }

  return (
    <Layout className="bg-gray-50">
      <Layout.Content
        className="bg-gray-50 mt-0 mb-4 "
        style={{ position: 'relative', overflowX: 'hidden' }}
      >
        <div className="container">
          <div className="row">
            <div className="col-lg-12"></div>
          </div>
        </div>
        {store.get('agentAccessKey') != null && (
          <div className="d-flex justify-content-between align-items-center m-3">
            <span className="font-size-22">
              Welcome <strong>{capitalizeFirstLetters(store.get('agentName'))}</strong>
            </span>

            <a onClick={logout}>
              <i className="fa-solid text-gray-900 fa-sign-out fa-fw mr-2" />
              Logout
            </a>
          </div>
        )}
        <div className="cui__utils__content">{children}</div>
      </Layout.Content>
    </Layout>
  )
}

export default withRouter(AgentLayout)
