import { Modal } from 'antd'
import React, { forwardRef, useImperativeHandle, useState } from 'react'
import ReactPlayer from 'react-player/lazy'

const videoLinks = {
  add_additional_charges:
    'https://vx-erp-logos.s3.ap-south-1.amazonaws.com/add_additional_charges.mp4',
  add_customer: 'https://vx-erp-logos.s3.ap-south-1.amazonaws.com/add_customer.mp4',
  add_expense: 'https://vx-erp-logos.s3.ap-south-1.amazonaws.com/add_expense.mp4',
  add_product: 'https://vx-erp-logos.s3.ap-south-1.amazonaws.com/add_product.mp4',
  add_signature: 'https://vx-erp-logos.s3.ap-south-1.amazonaws.com/add_signature.mp4',
  add_vendor: 'https://vx-erp-logos.s3.ap-south-1.amazonaws.com/add_vendor.mp4',
  cancel_invoice: 'https://vx-erp-logos.s3.ap-south-1.amazonaws.com/cancel_invoice.mp4',
  change_template: 'https://vx-erp-logos.s3.ap-south-1.amazonaws.com/change_template.mp4',
  company_custom_fields:
    'https://vx-erp-logos.s3.ap-south-1.amazonaws.com/company_custom_fields.mp4',
  create_custom_field_in_document:
    'https://vx-erp-logos.s3.ap-south-1.amazonaws.com/create_custom_field_in_document.mp4',
  create_delivery_challan:
    'https://vx-erp-logos.s3.ap-south-1.amazonaws.com/create_delivery_challan.mp4',
  'create_e-invoice': 'https://vx-erp-logos.s3.ap-south-1.amazonaws.com/create_e-invoice.mp4',
  'create_e-waybill': 'https://vx-erp-logos.s3.ap-south-1.amazonaws.com/create_e-waybill.mp4',
  create_invoice: 'https://vx-erp-logos.s3.ap-south-1.amazonaws.com/create_invoice.mp4',
  create_pro_forma: 'https://vx-erp-logos.s3.ap-south-1.amazonaws.com/create_pro_forma.mp4',
  create_purchase: 'https://vx-erp-logos.s3.ap-south-1.amazonaws.com/create_purchase.mp4',
  create_purchase_order:
    'https://vx-erp-logos.s3.ap-south-1.amazonaws.com/create_purchase_order.mp4',
  create_quotation: 'https://vx-erp-logos.s3.ap-south-1.amazonaws.com/create_quotation.mp4',
  create_sms_campaign: 'https://vx-erp-logos.s3.ap-south-1.amazonaws.com/create_sms_campaign.mp4',
  customer_custom_fields:
    'https://vx-erp-logos.s3.ap-south-1.amazonaws.com/customer_custom_fields.mp4',
  delete_payment: 'https://vx-erp-logos.s3.ap-south-1.amazonaws.com/delete_payment.mp4',
  pro_forma_to_sale: 'https://vx-erp-logos.s3.ap-south-1.amazonaws.com/pro_forma_to_sale.mp4',
  product_custom_fields:
    'https://vx-erp-logos.s3.ap-south-1.amazonaws.com/product_custom_fields.mp4',
  quotation_to_pro_forma:
    'https://vx-erp-logos.s3.ap-south-1.amazonaws.com/quotation_to_pro_forma.mp4',
  quotation_to_sale: 'https://vx-erp-logos.s3.ap-south-1.amazonaws.com/quotation_to_sale.mp4',
  record_payment: 'https://vx-erp-logos.s3.ap-south-1.amazonaws.com/record_payment.mp4',
  'setup_e-invoice': 'https://vx-erp-logos.s3.ap-south-1.amazonaws.com/setup_e-invoice.mp4',
  'setup_e-waybill': 'https://vx-erp-logos.s3.ap-south-1.amazonaws.com/setup_e-waybill.mp4',
  share_bills: 'https://vx-erp-logos.s3.ap-south-1.amazonaws.com/share_bills.mp4',
  vendor_custom_fields: 'https://vx-erp-logos.s3.ap-south-1.amazonaws.com/vendor_custom_fields.mp4',
  setup_online_store: 'https://vx-erp-logos.s3.ap-south-1.amazonaws.com/set_up_online_store.mp4',
  create_subscription: 'https://vx-erp-logos.s3.ap-south-1.amazonaws.com/create_subscription.mp4',
  create_expense_category:
    'https://vx-erp-logos.s3.ap-south-1.amazonaws.com/create_expense_categoty.mp4',
}
const TutorialVideoModal = forwardRef((props, ref) => {
  const [show_video, setShowVideo] = useState(false)
  const [video_link, setVideoLink] = useState('')
  const showModal = () => {
    setVideoLink(videoLinks[props.video_name])
    setShowVideo(true)
  }
  useImperativeHandle(ref, () => ({
    showModal,
  }))
  return (
    <Modal
      className="promotional-modal"
      open={show_video}
      footer={null}
      onCancel={() => setShowVideo(false)}
      width={'72vw'}
      destroyOnClose
      bodyStyle={{
        backgroundColor: '#000',
      }}
      closeIcon={<i className="fa-solid fa-lg fa-xmark"></i>}
      centered={true}
    >
      <ReactPlayer
        url={videoLinks[props.video_name]}
        playing={show_video}
        controls
        loop={show_video}
        playbackRate={0.75}
        width="100%"
        height="100%"
      />
    </Modal>
  )
})
export default TutorialVideoModal
