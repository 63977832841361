import { all, call, put, takeEvery } from 'redux-saga/effects'
import { getAPIData } from 'services/jwt'
import {
  set_all_users,
  set_default_warehouse,
  set_warehouse_permissions,
  set_warehouse_roles,
  set_warehouses,
} from './actions'
import { GET_WAREHOUSES, GET_WAREHOUSE_PERMISSIONS, GET_WAREHOUSE_ROLES } from './types'

export function* get_warehouses() {
  const data = yield call(async () => await getAPIData('warehouse', 'get'))
  if (data.success) {
    yield put(set_warehouses(data.warehouses))
    yield put(set_all_users(data.business_users))
    yield put(set_default_warehouse(data.default_warehouse_id))
  }
}
export function* get_warehouse_roles() {
  const data = yield call(async () => await getAPIData('warehouse', 'get_roles'))
  if (data.success) {
    yield put(set_warehouse_roles(data))
  }
}
export function* get_warehouse_permissions() {
  const data = yield call(async () => await getAPIData('warehouse', 'refresh_permissions'))
  if (data.success) {
    yield put(set_warehouse_permissions(data.warehouse_permissions))
  }
}

export default function* warehouseSaga() {
  yield all([takeEvery(GET_WAREHOUSES, get_warehouses)])
  yield all([takeEvery(GET_WAREHOUSE_ROLES, get_warehouse_roles)])
  yield all([takeEvery(GET_WAREHOUSE_PERMISSIONS, get_warehouse_permissions)])
}
