import {
  Button,
  Card,
  Drawer,
  Form,
  Input,
  Space,
  Steps,
  Tabs,
  Tag,
  Typography,
  message,
} from 'antd'
import React, { Component } from 'react'
import { isMobile } from 'react-device-detect'
import { Link } from 'react-router-dom'
import { getAPI, getAPIData } from 'services/jwt'

const { Text } = Typography

const { Step } = Steps

const formItemLayout = {
  labelCol: {
    xs: { span: 18 },
    sm: { span: 9 },
  },
  wrapperCol: {
    xs: { span: 24 },
    sm: { span: 12 },
  },
}

const steps = [
  {
    title: 'Add GSP Details',
  },
  {
    title: 'EInvoice GSP Login',
  },
  {
    title: 'Done',
  },
]

export default class EInvoiceNICCreds extends Component {
  dateFormat = 'DD-MM-YYYY'
  formRef = React.createRef()
  constructor(props) {
    super(props)
    this.state = {
      visible: false,
      loading: false,
      show_otp: false,
      txn: '',
      new_hash_id: '',
      document_type: 'invoice',
      current: 0,
      verified: false,
      is_backup: false,
      activeKey: '1',
    }
  }

  async componentDidMount() {
    this.props.onRef(this)
  }

  componentWillUnmount() {
    this.props.onRef(null)
  }

  verifyAccount = async () => {
    const data = await getAPIData('einvoice', 'is_verified')
    if (data.verified) {
      this.setState({ verified: data.verified })
    } else {
      message.error(
        'Your account is not verified. Please connect to E-Invoice Portal using top right button',
      )
    }
  }

  showDrawer = (visible, new_hash_id, document_type) => {
    this.setState({ visible: visible, new_hash_id: new_hash_id, document_type: document_type })
    this.verifyAccount()
  }

  showDrawerLogin = (visible, is_backup = false) => {
    this.setState({ visible: visible, is_backup, activeKey: is_backup ? '2' : '1' })
    this.verifyAccount()
  }

  next = () => {
    this.setState({ current: this.state.current + 1 })
  }

  prev = () => {
    this.setState({ current: this.state.current - 1 })
  }

  createEInvoiceNew = async (new_hash_id, document_type) => {
    let request = {
      new_hash_id: new_hash_id,
      document_type: document_type,
    }

    var data = await getAPI('einvoice', 'create_einvoice', request)
    if (data && data.success) {
      message.success(data.message)
      this.props.onApiCalled()
    } else {
      return false
    }
    return true
  }

  onFinish = async values => {
    var req = {
      ...values,
    }

    let module = this.state.is_backup ? 'einvoice' : 'user'
    let apiName = this.state.is_backup
      ? 'einvoice_authenticate_mastersindia'
      : 'einvoice_nic_authenticate'
    const data = await getAPI(module, apiName, req)

    if (data.success) {
      message.success(data.message)
      this.setState({ verified: true })

      if (this.state.new_hash_id != '') {
        this.setState({ visible: false })
        this.setState({ loading: false }, () => {
          this.createEInvoiceNew(this.state.new_hash_id, this.state.document_type)
        })
      } else {
        this.next()
      }
    } else {
      this.setState({ loading: false })
    }
  }

  render() {
    const { current } = this.state

    const renderTabs = () => (
      <>
        <Card>
          <Steps current={this.state.current} className="mb-4">
            {steps.map(item => (
              <Step key={item.title} title={item.title} />
            ))}
          </Steps>
        </Card>

        <Card>
          <div className="steps-content pl-2">
            {this.state.current == 0 && (
              <>
                <Space direction="vertical" className="font-size-16">
                  <Text className="font-size-18">
                    Two simple steps to get you started with E-Invoicing quickly.
                  </Text>
                  <br />
                  <Text type="text-gray-900" className="mt-4 pb-0 font-weight-bold">
                    STEP-1 <hr className="pt-2" />
                  </Text>
                  <Text>
                    1. Login to EInvoice Portal{' '}
                    <Link
                      to={{ pathname: 'https://einvoice1.gst.gov.in/' }}
                      target="_blank"
                      className="font-weight-bold"
                    >
                      https://einvoice1.gst.gov.in/
                    </Link>
                  </Text>
                  <Text>
                    2. On the
                    <Text className="font-weight-bold"> left Menu </Text>
                    click on API Registration {'>'} User Credentials {'>'} Create API User
                  </Text>
                  <Text>
                    3. Click Send OTP. (Please note that you will receive OTP to the registered
                    mobile number on your Einvoice Portal.)
                  </Text>
                  <Text>
                    4.
                    <Text className="font-weight-bold">Verify OTP</Text>
                  </Text>
                  <br />

                  <Text type="text-gray-900" className="mt-4 pt-2 pb-0 font-weight-bold">
                    STEP-2 (After Verfification of OTP)
                  </Text>

                  <hr className="mt-0 pt-0" />
                  <Text>
                    1. Click the <b>Add/New Button</b>
                  </Text>
                  <Text>
                    2. Select
                    <Text type="" className="font-size-18 font-weight-bold">
                      {' '}
                      {this.state.is_backup
                        ? 'Masters India Private Limited'
                        : 'Tera Software Limited'}
                    </Text>
                    , in the GSP Name dropdown and click on <b>Submit</b>.
                  </Text>
                  <Text>
                    3. Create you <b>3 letter Suffix ID</b> and a password
                  </Text>
                  <Text>
                    4. Remember to keep a screenshot of both the username and password as it could
                    come in handy down the line.
                  </Text>
                  <Text>5. Login with these credentials on this page in the next step.</Text>
                </Space>

                <Button type="action" size="large" onClick={() => this.next()} className="mt-4">
                  Proceed to EInvoice GSP Login <i className="fa fa-arrow-right ml-2" />
                </Button>
              </>
            )}

            {this.state.current == 1 && (
              <div className="mt-4 font-size-18">
                <Tag
                  color="orange"
                  className="mb-4 font-size-18 font-weight-bold"
                  style={{ borderRadius: '5px' }}
                >
                  Enter GSP Username and Password from the EInvoice Portal
                </Tag>

                <Form
                  {...formItemLayout}
                  labelAlign="left"
                  ref={this.formRef}
                  name="GSTCredsForm"
                  onFinish={this.onFinish}
                  size="large"
                >
                  <Form.Item
                    name="username"
                    label="GSP Username"
                    rules={[{ required: true, message: 'Please fill Username' }]}
                  >
                    <Input placeholder="eg. API_XXXXXXXXX" />
                  </Form.Item>

                  <Form.Item
                    name="password"
                    label="GSP Password"
                    rules={[{ required: true, message: 'Please fill Password' }]}
                  >
                    <Input.Password placeholder="GSP Password" />
                  </Form.Item>

                  <Button
                    size="large"
                    type="primary"
                    className="float-right"
                    onClick={() => this.formRef.current.submit()}
                  >
                    Proceed to Generate EInvoice <i className="fa fa-arrow-right ml-2" />
                  </Button>
                </Form>
              </div>
            )}

            {this.state.current == 2 && (
              <>
                <div className="d-flex flex-column justify-content-center align-items-center">
                  <div>
                    <span>
                      <i
                        className="fa fa-check-circle text-success"
                        style={{
                          fontSize: '5rem',
                        }}
                      />
                    </span>
                  </div>
                  <div className="mt-2">
                    <div>
                      <span className="font-size-22">
                        <span className="font-weight-bold text-gray-500 ">Congratulations!</span>{' '}
                        You have successfully connected to EInvoice Portal.
                      </span>
                    </div>
                  </div>
                  {!this.state.is_backup && (
                    <div>
                      <Button
                        type="primary"
                        size="medium"
                        className="mt-4"
                        onClick={() =>
                          this.setState({ current: 0, activeKey: '2', is_backup: true })
                        }
                      >
                        Add Backup GSP Provider
                      </Button>
                    </div>
                  )}
                  {!this.state.is_backup && (
                    <div>
                      <span className="font-size-12 text-gray-400">
                        Backup GSP Provider is required in case of any issues with the E-Invoice
                        creation with the primary GSP Provider.
                      </span>
                    </div>
                  )}
                </div>
              </>
            )}
          </div>

          <div className="steps-action mt-4">
            {current > 0 && current < 2 && (
              <Button size="medium" style={{ margin: '0 8px' }} onClick={() => this.prev()}>
                <i className="fa fa-arrow-left mr-2" /> Go Back
              </Button>
            )}
          </div>
        </Card>
      </>
    )
    return (
      <Drawer
        title="Connecting to EInvoice Portal"
        width={isMobile ? '100%' : '55%'}
        height={isMobile ? '90%' : '100%'}
        placement={isMobile ? 'top' : 'right'}
        closable={true}
        closeIcon={<i className="fa-solid fa-lg fa-xmark" />}
        onClose={() => this.setState({ visible: false })}
        open={this.state.visible}
        footer={[
          <Button type="danger" onClick={() => this.setState({ visible: false })}>
            {' '}
            Close
          </Button>,
        ]}
      >
        <Tabs
          defaultActiveKey="1"
          activeKey={this.state.activeKey}
          onChange={key => {
            this.setState({ activeKey: key, is_backup: key == '2', current: 0 })
          }}
          destroyInactiveTabPane
        >
          <Tabs.TabPane tab="E-way Bill GSP Login" key="1">
            {renderTabs()}
          </Tabs.TabPane>
          {this.state.verified && (
            <Tabs.TabPane tab="Back-up GSP Login" key="2">
              {renderTabs()}
            </Tabs.TabPane>
          )}
        </Tabs>
      </Drawer>
    )
  }
}

export class EnhancedEInvoiceNICCreds extends React.Component {
  constructor(props) {
    super(props)
  }
  render() {
    return <EInvoiceNICCreds {...this.props} />
  }
}
