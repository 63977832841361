import React, { Component } from 'react'
import { getAPI } from 'services/jwt'

import { AutoComplete, Button, Tooltip } from 'antd'
import { connect } from 'react-redux'
const dotOptions = { minimumFractionDigits: 2, maximumFractionDigits: 2 }

// check if mobile device
const isMobile = window.innerWidth <= 500

class AutoFinish extends Component {
  constructor(props) {
    super(props)

    this.state = {
      searchType: props.searchType,
      autocompleteData: [],
      recent_searches: [],
      searchText: props.searchText || '',
      show_new: props.showNew,
      selectedEntity: { name: '', id: -1 },
      page: 0,
      num_records: 10,
      prevReq: {},
    }
    this.autoCompleteType = {
      product: {
        type: 'product',
        search: this.onItemSearch,
        select: this.onItemSelect,
        searchAPI: this.itemSearchAPI,
        placeholder: 'Search Items',
      },
      customer: {
        type: 'customer',
        search: this.onCustomerSearch,
        select: this.onCustomerSelect,
        searchAPI: this.customerSearchAPI,
        placeholder: 'Search Customers',
      },
      vendor: {
        type: 'vendor',
        search: this.onCustomerSearch,
        select: this.onCustomerSelect,
        searchAPI: this.customerSearchAPI,
        placeholder: 'Search Vendors',
      },
    }
  }

  async componentDidMount() {
    this.props.onRef(this)
    this.autoCompleteType[this.props.searchType].searchAPI(this.props.searchText)
  }

  // async componentDidUpdate() {
  //   this.props.onRef(this)
  //   this.autoCompleteType[this.props.searchType].searchAPI(this.props.searchText)
  // }

  async refreshComponent(searchType) {
    this.setState(
      {
        searchType: searchType,
        autocompleteData: [],
        recent_searches: [],
        searchText: '',
        show_new: false,
        selectedEntity: { name: '', id: -1 },
        page: 0,
        num_records: 10,
        prevReq: {},
      },
      () => {
        this.autoCompleteType[this.props.searchType].searchAPI(this.props.searchText)
      },
    )
  }

  onCustomerSearch = async searchText => {
    clearTimeout(this.state.timeout)
    this.setState({
      searchText: searchText,
      timeout: setTimeout(() => {
        this.customerSearchAPI(searchText)
      }, 1200),
    })
  }

  onItemSearch = async searchText => {
    clearTimeout(this.state.timeout)
    this.setState({
      searchText: searchText,
      timeout: setTimeout(() => {
        this.itemSearchAPI(searchText)
      }, 1200),
    })
  }

  onCustomerSelect = (data, option) => {
    this.setState(
      {
        selectedEntity: option,
        autocompleteData: [option],
        searchText: option.name,
      },
      () => {
        this.props.callData(data, option)
      },
    )
  }

  itemSearchAPI = async (searchText, isSearch = true) => {
    this.setState({ selectedEntity: '' })

    const req = {
      query: searchText,
      selected_price_list: this.props.selectedPriceList,
      page: this.state.page,
      num_records: this.state.num_records,
      category: this.state.selected_category,
      document_type: this.props.document_type,
      products_for_sale:
        this.props.document_type != 'invoice' || this.props.document_type != 'sales_return'
          ? false
          : true,
    }
    const data = await getAPI('product', 'search', req)
    if (data) {
      const allData = data.products.map(value => {
        if (this.props.document_type?.includes('purchase') && value.discount != 0) {
          value.discount = 0
        }
        value.actual_price_with_tax = value.price_with_tax
        if (this.state.type == 'purchases' && value.purchase_price != 0) {
          value.price = value.purchase_price / (1 + (value.tax + value.cess) / 100)
          value.unit_price = value.price
          value.price_with_tax = value.purchase_price
        }
        value.tax = this.props.export_type?.includes('Deemed') ? 0.1 : value.tax
        let unitobj = this.getUnitDetails(value.units, value.unit, value.unit_id)
        return {
          ...value,
          name:
            value.product_name +
            (value.variant_name == '' || value.variant_name == null
              ? ''
              : ' ' + value.variant_name),
          // id: value.product_id,
          id:
            String(value.product_id) +
            '_' +
            String(value.variant_id == undefined ? 0 : value.variant_id),
          description: value.description,
          tax: value.tax,
          qty: 1,
          qtyinstock: value.qty,
          product_name: value.product_name,
          discount: value.discount,
          discount_value: ((value.price_with_tax * value.discount) / 100).toFixed(2),
          discount_price_with_tax_value: ((value.price_with_tax * value.discount) / 100).toFixed(2),
          discount_net_value: ((value.price * value.discount) / 100).toFixed(2),
          discount_unit_price_value: ((value.price * value.discount) / 100).toFixed(2),
          barcode_id: value.barcode_id,
          variant_name: value.variant_name,
          variant_id: value.variant_id ? value.variant_id : 0,
          unit_id: value.unit_id ? value.unit_id : 0,
          unit: unitobj.unit,
          conversion_rate: unitobj.conversion_rate,
          value: (
            <span className="d-flex flex-row justify-content-between">
              <span className="d-flex flex-inline flex-row align-items-center">
                {value.is_frequent && (
                  <Tooltip title="Frequently used">
                    <div>
                      <i class="fa-regular fa-clock ml-0 mr-2 text-gray-500"></i>
                    </div>
                  </Tooltip>
                )}
                <span className="d-flex flex-row">
                  {this.props.document_settings.show_images === 1 && value.image && (
                    <img
                      src={value.image}
                      alt={value.value}
                      style={{
                        width: '30px',
                        height: '30px',
                        marginRight: '5px',
                      }}
                    />
                  )}

                  <span className="d-flex flex-column">
                    <span className="mb-0 pb-0 font-weight-medium">
                      {value.product_name +
                        (value.variant_name == '' || value.variant_name == null
                          ? ''
                          : ' ' + value.variant_name)}
                    </span>
                    <p className="font-size-11 mt-0 mb-0 text-gray-500">
                      {(value['product_type'] == 'Service' &&
                        this.props.document_settings.track_inventory_for_services == 1) ||
                        (value['product_type'] != 'Service' && (
                          <>
                            {value.qty > 0 && (
                              <span
                                className="font-weight-bold font-size-10 mr-1"
                                style={{ color: 'green' }}
                              >
                                Avl. qty: {value.qty}{' '}
                              </span>
                            )}
                            {value.qty <= 0 && (
                              <span
                                className="font-weight-bold font-size-10 mr-1"
                                style={{ color: 'red' }}
                              >
                                Avl. qty: {value.qty}{' '}
                              </span>
                            )}
                          </>
                        ))}
                      {value.unit != 'OTH' && value.unit != 'NONE' && (
                        <span className="font-size-10 text-purple mr-2">{value.unit}</span>
                      )}
                      <span className="text-purple font-weight-bold mr-2">
                        {value.product_category}
                        {'  '}
                      </span>
                      <span className="text-gray-10">{value.hsn_code}</span>
                    </p>
                    {this.props.searchText != '' && !this.state.product_search_loading && (
                      <span className="font-size-11 text-gray-500">
                        <a className="mt-3  font-weight-medium">
                          {value.reason != '' && (
                            <>
                              {value.reason != '' ? (
                                <>
                                  {value.reason.split(':')[0] + ':'}
                                  <b>{value.reason.split(':')[1]}</b>
                                </>
                              ) : (
                                ''
                              )}
                            </>
                          )}
                        </a>
                      </span>
                    )}
                  </span>
                </span>
              </span>
              <span className="float-right font-weight-bold">
                {value.is_price_with_tax == 1 && (
                  <>
                    <span className="mr-1 text-gray-700">
                      {this.props.user.selectedCompany.currency_symbol}
                    </span>
                    <span className="text-gray-700">{value.price_with_tax.toFixed(2)}</span>
                    {value.tax > 0 && (
                      <p className="font-size-9 text-gray-500 mb-0 text-right">
                        incl tax {value.tax}%
                      </p>
                    )}
                  </>
                )}
                {value.is_price_with_tax == 0 && (
                  <>
                    <span className="mr-1 text-gray-700">
                      {this.props.user.selectedCompany.currency_symbol}
                    </span>
                    <span className="text-gray-700">{value.unit_price.toFixed(2)}</span>
                    {value.tax > 0 && (
                      <p className="font-size-9 text-gray-500 mb-0 text-right">
                        + tax {value.tax}%
                      </p>
                    )}
                  </>
                )}
              </span>
            </span>
          ),
        }
      })

      if (isSearch) {
        this.setState({
          autocompleteData: [...allData],
          page: 0,
          recent_searches: data.searches,
        })
      } else {
        this.setState({
          autocompleteData: [...this.state.autocompleteData, ...allData],
          recent_searches: data.searches,
        })
      }
      // this.setState({
      //   autocompleteData: [...allData],
      //   recent_searches: data.searches,
      // })
    }
  }
  getUnitDetails = (units, unit, unit_id) => {
    let final_unit = ''
    let conversion_rate = 1
    if (unit == null) {
      final_unit = ''
    } else {
      if (units.length > 0) {
        let unit_details = units.filter(item => item.unit_id == unit_id)
        if (unit_details.length > 0) {
          final_unit = unit_details[0].alternative_unit
        } else {
          final_unit = unit
        }
      }
    }

    if (unit_id == 0 || unit_id == null) {
      conversion_rate = 1
    } else {
      if (units.length > 0) {
        let unit_details = units.filter(item => item.unit_id == unit_id)
        if (unit_details.length > 0) {
          conversion_rate = unit_details[0].conversion_rate
        }
      }
    }

    return {
      conversion_rate: conversion_rate,
      unit: final_unit,
    }
  }
  onItemSelect = (data, option) => {
    option = { ...option, value: option.name }
    let selectedItem = this.state.autocompleteData.filter(item => item.id == option.id)
    this.setState(
      {
        selectedEntity: option,
        autocompleteData: selectedItem,
        searchText: option.name,
      },
      () => {
        this.props.callData(data, option)
      },
    )
  }

  getStatusClass = data => {
    if (data < 0) {
      return '#e11900'
    } else if (data == 0) {
      return '#757575'
    } else {
      return '#09864A'
    }
  }

  customerSearchAPI = async (searchText, isSearch = true) => {
    const { page, num_records } = this.state
    const req = {
      query: searchText,
      page: this.state.page,
      num_records: num_records,
    }
    // try {
    //   if (JSON.stringify(req) === JSON.stringify(this.state.prevReq)) {
    //     return
    //   } else {
    //     this.setState({ prevReq: req })
    //   }
    // } catch (error) {
    //   // console.log(error)
    // }

    const data = await getAPI(this.props.searchType, 'search', req)
    if (data) {
      var parties = this.props.searchType == 'customer' ? data.customers : data.vendors
      const allData = parties.map(value => {
        return {
          value: (
            <span className="d-flex flex-row justify-content-between">
              <span className="d-flex flex-inline flex-row align-items-center">
                {value.is_frequent && (
                  <Tooltip title="Frequently used">
                    <div>
                      <i class="fa-regular fa-clock ml-0 mr-2 text-gray-500"></i>
                    </div>
                  </Tooltip>
                )}
                <span className="d-flex flex-row">
                  <span className="d-flex flex-column">
                    <span className="mb-0 pb-0 font-weight-medium">
                      {value.name + (value.phone != '' ? ' (' + value.phone + ')' : '')}
                    </span>
                    <p className="font-size-12 mb-0">
                      <span className="text-gray-500">{value.gstin}</span> {value.company_name}
                      <span className="float-right text-gray-500">
                        {value.tags && value.tags != '' ? 'Tags: ' + value.tags : ''}
                      </span>
                    </p>
                  </span>
                </span>
              </span>
              <span
                className="font-size-12 float-right font-weight-bold"
                style={{ color: this.getStatusClass(value.balance + value.opening_balance) }}
              >
                <span className="font-size-12 mr-1">
                  {this.props.user.selectedCompany.currency_symbol}
                </span>
                {Math.round(Math.abs(value.balance + value.opening_balance)).toLocaleString(
                  'en-IN',
                  dotOptions,
                )}
              </span>
            </span>
          ),
          id: this.props.searchType == 'customer' ? value.customer_id : value.vendor_id,
          key: this.props.searchType == 'customer' ? value.customer_id : value.vendor_id,
          notes: value.name,
          discount: value.discount,
          ...value,
        }
      })

      if (isSearch) {
        this.setState({
          autocompleteData: [...allData],
          page: 0,
          recent_searches: data.searches,
        })
      } else {
        this.setState({
          autocompleteData: [...this.state.autocompleteData, ...allData],
        })
      }
    }
  }

  handleScroll = e => {
    let bottom =
      parseInt(e.target.scrollHeight - e.target.scrollTop) - parseInt(e.target.clientHeight) <= 1

    if (bottom) {
      this.setState({ page: this.state.page + 1 }, () => {
        this.autoCompleteType[this.props.searchType].searchAPI(this.props.searchText, false)
      })
    }
  }

  render() {
    return (
      <AutoComplete
        options={this.state.autocompleteData}
        className={this.props.className || ''}
        autoFocus={this.props.autoFocus || false}
        style={this.props.style || {}}
        dropdownMatchSelectWidth={this.props.dropdownMatchSelectWidth || false}
        dropdownMenuStyle={this.props.dropdownMenuStyle || {}}
        trigger="hover"
        dropdownRender={menu => (
          <div
            className="menu autofill-btn my-select "
            id="style-9"
            style={{
              overflowY: 'auto',
            }}
          >
            <>
              {/* show recent searches
              {this.state.recent_searches.length > 0 &&
                this.state.recent_searches.filter(col => col.search_type == this.props.searchType)
                  .length > 0 && (
                  <div className="px-4 py-2 text-gray-500 font-weight mb-1 ">
                    <div className="d-flex justify-content-between">
                      <div className="font-weight-bold font-size-12 ">
                        Recent Searches <i className="fa-solid fa-history ml-1"></i>
                      </div>
                      <div className="font-size-12 ">
                        <a_link onClick={() => ''} className="font-weight-bold">
                          Clear All
                        </a_link>
                      </div>
                    </div>
                    <div className="d-flex flex-inline flex-wrap">
                      {this.state.recent_searches
                        .filter(
                          col =>
                            col.search_type == this.props.searchType &&
                            col.search_word.includes(this.props.searchText),
                        )
                        .slice(0, 7)
                        .map((item, i) => {
                          if (item.search_type == this.props.searchType) {
                            return (
                              <Button
                                size="small"
                                className="font-weight-bolder text-left mr-1 my-2 arrow-transition"
                                onClick={() =>
                                  this.setState({ searchText: item.search_word }, () => {
                                    this.autoCompleteType[this.props.searchType].search(
                                      item.search_word,
                                    )
                                  })
                                }
                              >
                                {item.search_word}
                              </Button>
                            )
                          }
                        })}
                    </div>
                  </div>
                )} */}

              {menu}

              {this.state.show_new && (
                <div className="bg-gray-75">
                  {/*<Divider className="my-0" />*/}
                  <Button
                    size=""
                    type="link-2"
                    className="font-weight-bolder text-left my-2 arrow-transition"
                    onClick={() => this.props.addNew(this.props.searchText)}
                  >
                    <i1 className="fa-solid fa-circle-plus mr-2"></i1>
                    Add New{' '}
                    {this.props.searchType.charAt(0).toUpperCase() + this.props.searchType.slice(1)}
                    <i className="fa-solid fa-arrow-right ml-2 arrow-transition"></i>
                  </Button>
                </div>
              )}
            </>
          </div>
        )}
        wrapperStyle={this.props.wrapperStyle || {}}
        notFoundContent={
          <>
            <div className="px-4 py-2 text-gray-500 font-weight">
              {/*                              No {this.state.party_type === 'customer' ? ' customers' : ' vendors'}{' '}
              found.*/}
              <span className="font-weight-bold">No search results found</span>
              <p className="mb-0">Please try again with a different search query or </p>
            </div>
          </>
        }
        onSelect={(data, option) => {
          this.autoCompleteType[this.props.searchType].select(data, option)
        }}
        onSearch={this.autoCompleteType[this.props.searchType].search}
        value={this.props.searchText}
        placeholder={this.props.placeholder}
        ref={this.props.ref}
        prefix={<i className="fa-solid fa-magnifying-glass text-gray-300"></i>}
        onPopupScroll={this.handleScroll}
        onChange={value => {
          value = value || ''

          this.props.onChange(value || '')
        }}
      ></AutoComplete>
    )
  }
}

function mapStateToProps(state) {
  return {
    user: state.user,
    document_settings: state.user.document_settings,
  }
}

export default connect(mapStateToProps)(AutoFinish)
