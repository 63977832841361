import { Col, DatePicker, Form, Input, Modal, Row, Select, Switch, message } from 'antd'
import React, { useState } from 'react'
import { getAPI } from 'services/jwt'

const { forwardRef, useRef, useImperativeHandle } = React

import * as dayjs from 'dayjs'
import utc from 'dayjs/plugin/utc'
import { useSelector } from 'react-redux'
dayjs.extend(utc)
const emptyData = {
  coupon_id: -1,
  code: '',
  discount: '',
  type: 'percentage',
  amount: 0,
  end_date: '',
  start_date: '',
  minimum_order_value: 0,
  maximum_discount_amount: 0,
  customers_category: '1',
  maximum_uses: 0,
  maximum_uses_per_user: 0,
  show_to_customers: true,
}

const CouponModal = forwardRef((props, ref) => {
  const dateTimeFormat = 'YYYY-MM-DD hh:mm a'
  const { permission } = useSelector(state => state.permissions)
  const formRef = useRef(null)
  const [visible, setVisible] = useState(false)
  const [loading, setLoading] = useState(false)
  const [type, setCouponType] = useState('percentage')

  const showModal = async () => {
    setVisible(true)
  }
  useImperativeHandle(ref, () => ({
    showModal,
  }))

  const onFinish = async values => {
    var req = {
      ...values,
      end_date: dayjs(values.end_date).format(dateTimeFormat),
      start_date: dayjs(values.start_date).format(dateTimeFormat),
    }

    setLoading(true)
    var data = await getAPI('v2/company', 'add_coupon', req)
    if (data.success) {
      message.success('Coupon saved successfully')
      props.onApiCalled()
      setVisible(false)
    }
    setLoading(false)
  }
  return (
    <>
      <Modal
        title={
          <>
            <span className="text-center font-size-20 font-weight-bold gray-700">
              <i className="fa-solid fa-badge-percent" /> Create Coupon
            </span>
          </>
        }
        centered
        bodyStyle={{ minHeight: '50vh', overflowY: 'auto', borderRadius: 0 }}
        width={'45vw'}
        closeIcon={<i className="fa-solid fa-lg fa-xmark"></i>}
        open={visible}
        onCancel={() => {
          setVisible(false)
        }}
        destroyOnClose
        maskClosable={false}
        onOk={() => {
          formRef.current.submit()
        }}
      >
        <Form layout="vertical" onFinish={onFinish} initialValues={emptyData} ref={formRef}>
          <Row gutter={16}>
            <Col span={12}>
              <Form.Item
                label="Coupon Code"
                name="code"
                rules={[
                  { required: true, message: 'Please enter coupon code' },
                  { min: 3, message: 'Coupon code must be at least 3 characters' },
                  { max: 20, message: 'Coupon code must be at most 20 characters' },
                  // only capitals and numbers
                  {
                    pattern: /^[A-Z0-9]+$/,
                    message: 'Coupon code must be only capital letters and numbers',
                  },
                ]}
                extra="This code is visible to the customer"
              >
                <Input placeholder="Enter Coupon Code" />
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item
                label="Coupon Type"
                name="type"
                rules={[{ required: true, message: 'Please select coupon type' }]}
                extra="Select the type of coupon (percentage or flat)"
              >
                <Select
                  placeholder="Select Coupon Type"
                  onChange={value => {
                    setCouponType(value)
                  }}
                >
                  <Select.Option value="percentage">Percentage</Select.Option>
                  <Select.Option value="flat">Fixed</Select.Option>
                </Select>
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={16}>
            {type == 'percentage' && (
              <Col span={12}>
                <Form.Item
                  label="Discount (%)"
                  name="discount"
                  rules={[{ required: true, message: 'Please enter discount' }]}
                  extra="Discount percentage to be applied on the order"
                >
                  <Input placeholder="Enter Discount Percentage" addonAfter={<>%</>} />
                </Form.Item>
              </Col>
            )}
            {type == 'flat' && (
              <Col span={12}>
                <Form.Item
                  label="Amount"
                  name="amount"
                  rules={[{ required: true, message: 'Please enter amount' }]}
                  extra="Discount Amount to be applied"
                >
                  <Input placeholder="Enter Discount Amount" />
                </Form.Item>
              </Col>
            )}
            <Col span={12}>
              <Form.Item
                label="Minimum Order Value"
                name="minimum_order_value"
                extra="Coupon will be valid only if the order value is greater than or equal to this value. if it is 0, then it will be valid for all orders"
              >
                <Input placeholder="Minimum Order Value" />
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={16}>
            {type == 'percentage' && (
              <Col span={12}>
                <Form.Item
                  label="Maximum Amount"
                  name="maximum_discount_amount"
                  extra="Maximum discount amount to be applied on the order. if it is 0, then no limit"
                >
                  <Input placeholder="Enter Maximum Amount" />
                </Form.Item>
              </Col>
            )}
            <Col span={12}>
              <Form.Item
                label="Customers Category"
                name="customers_category"
                extra="Coupon will be valid only for the selected customers category"
              >
                <Select placeholder="Select Customers Category">
                  <Select.Option value="1">All Customers</Select.Option>
                  <Select.Option value="2">New Customers</Select.Option>
                  <Select.Option value="3">Customer with minimum 10 Orders</Select.Option>
                </Select>
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={16}>
            <Col span={12}>
              <Form.Item
                label="Start Date"
                name="start_date"
                extra="Coupon will be valid only from this date"
                rules={[{ required: true, message: 'Please select start date' }]}
              >
                <DatePicker
                  showTime
                  allowClear={false}
                  format={dateTimeFormat}
                  disabledDate={current => {
                    return dayjs().add(-1, 'days') >= current
                  }}
                  defaultValue={dayjs().format(dateTimeFormat)}
                  use12Hours={true}
                  className="mb-2 w-100"
                />
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item
                label="End Date"
                name="end_date"
                extra="Coupon will be valid only till this date"
                rules={[{ required: true, message: 'Please select end date' }]}
              >
                <DatePicker
                  showTime
                  allowClear={false}
                  format={dateTimeFormat}
                  disabledDate={current => {
                    return dayjs().add(-1, 'days') >= current
                  }}
                  defaultValue={dayjs()}
                  use12Hours={true}
                  className="mb-2 w-100"
                />
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={16}>
            <Col span={12}>
              <Form.Item
                label="Maximum Uses"
                name="maximum_uses"
                extra="Maximum number of times the coupon can be used. if it is 0, then no limit"
              >
                <Input placeholder="Enter Maximum Uses" />
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item
                label="Maximum Uses Per Customer"
                name="maximum_uses_per_user"
                extra="Maximum number of times the coupon can be used by a single customer. if it is 0, then no limit"
              >
                <Input placeholder="Enter Maximum Uses Per Customer" />
              </Form.Item>
            </Col>
          </Row>

          <Row gutter={16}>
            <Col span={24}>
              <Form.Item
                label="Show to Customers"
                name="show_to_customers"
                extra="If you want to give coupon to a specific customer manually, then deselect this option"
              >
                <Switch defaultChecked />
              </Form.Item>
            </Col>
          </Row>
        </Form>
      </Modal>
    </>
  )
})

export default CouponModal
