import React, { useEffect, useState, Component, useReducer } from 'react'
import { Button, Table, Collapse, Drawer, Row, Col, Divider, message } from 'antd'
import { pdf, PDFDownloadLink, View } from '@react-pdf/renderer'
import './style.module.scss'
import { EnhancedQRCode } from 'components/other/qrCodeFilePosBill'
import { isMobile } from 'react-device-detect'
import CopyToClipboard from 'react-copy-to-clipboard'
import { getAPI, getAPIData, getAPIPDF } from 'services/jwt'
import Invoice1 from 'components/new_invoice/1/index'
import Invoice2 from 'components/new_invoice/2/index'
import Invoice3 from 'components/new_invoice/3/index'
import Invoice4 from 'components/new_invoice/4/index'
import Invoice5 from 'components/new_invoice/5/index'
import Invoice6 from 'components/new_invoice/6/index'
import Invoice7 from 'components/new_invoice/7/index'
import Invoice8 from 'components/new_invoice/8/index'
import Invoice9 from 'components/new_invoice/9/index'
import Invoice10 from 'components/new_invoice/10/index'
import Invoice11 from 'components/new_invoice/11/index'
import Invoice12 from 'components/new_invoice/12/index'
import Invoice13 from 'components/new_invoice/13/index'
import EnhancedInvoiceForm from 'components/forms/invoiceDetails'
import dayjs from 'dayjs'
import utc from 'dayjs/plugin/utc'
import EwayBill from 'components/ewaybill/index'
import { registerFont } from 'components/fonts'
import { connect } from 'react-redux'
dayjs.extend(utc)
const { Panel } = Collapse

const Map = {
  Invoice1: Invoice1,
  Invoice2: Invoice2,
  Invoice3: Invoice3,
  Invoice4: Invoice4,
  Invoice5: Invoice5,
  Invoice6: Invoice6,
  Invoice7: Invoice7,
  Invoice8: Invoice8,
  Invoice9: Invoice9,
  Invoice10: Invoice10,
  Invoice11: Invoice11,
  Invoice12: Invoice12,
  Invoice13: Invoice13,
}

const columns = [
  {
    title: 'Product',
    dataIndex: 'name',
    render: (text, record) => (
      <span>
        {text}
        <br />
        {record.description}
      </span>
    ),
  },
  {
    title: 'Qty',
    dataIndex: 'qty',
  },
  {
    title: 'Disc',
    dataIndex: 'discount',
  },
  {
    title: 'Net Amount',
    dataIndex: 'net_amount',
  },
  {
    title: 'Tax Amount',
    dataIndex: 'tax_amount',
    render: (text, record) => (
      <span>
        {text} ({record.tax}%)
      </span>
    ),
  },
  {
    title: 'Amount',
    dataIndex: 'total_amount',
  },
]

const getStatusClass = data => {
  switch (data) {
    case 'pending':
      return 'warning'
    case 'paid':
      return 'success'
    case 'cancelled':
      return 'danger'
    case 'partially paid':
      return 'partial'
    default:
      return 'warning'
  }
}

export class Table8 extends Component {
  constructor(props) {
    super(props)
    this.state = {
      viewDetails: false,
      copied: false,
      loading: false,
      eway_bill_details: {},
    }
  }

  showDrawer = () => {
    this.setState({
      viewDetails: true,
    })
  }

  onClose = () => {
    this.setState({
      viewDetails: false,
    })
  }
  async downloadFile(url, name) {
    const res = await fetch(url)
    const blob = await res.blob()
    const url2 = window.URL.createObjectURL(blob)
    const link = document.createElement('a')
    link.href = url2
    link.setAttribute('download', name)
    document.body.appendChild(link)
    link.click()
    document.body.removeChild(link)
  }

  //don't remove this code

  // downloadDocument = async (document, menuName, document_name) => {
  //   await registerFont(document.company.locale)
  //   const blob = await pdf(<AllInvoice order={document} menuName={menuName} />).toBlob()
  //   this.downloadFile(
  //     URL.createObjectURL(blob),
  //     `${document_name}-${document.company.organization_name}-${document.serial_number}`,
  //   )
  //   if (document.eway_bill.length > 0) {
  //     const req = {
  //       document_type: document.document_type,
  //       invoice_serial_number: document.eway_bill[0].invoice_serial_number,
  //       new_hash_id: document.new_hash_id,
  //       serial_number: document.eway_bill[0].serial_number,
  //     }
  //     const data = await getAPI(`ewaybills`, 'get_ewaybill', req)
  //     console.log(data)
  //     const eway_bill_blob = await pdf(<EwayBill invoice={{ ...data.invoice_details }} />).toBlob()
  //     this.downloadFile(
  //       URL.createObjectURL(eway_bill_blob),
  //       `${'EwayBill'}-${document.company.organization_name}-${
  //         document.eway_bill[0].serial_number
  //       }`,
  //     )
  //   }
  // }
  download = async (pdfDataArrayBuffer, pdfName) => {
    try {
      const uint8Array = new Uint8Array(pdfDataArrayBuffer)
      const blob = new Blob([uint8Array], { type: 'application/pdf' })
      const url = window.URL.createObjectURL(blob)
      const link = document.createElement('a')
      link.href = url
      link.setAttribute('download', `${pdfName}`)
      document.body.appendChild(link)
      link.click()
    } catch (error) {
      console.error('Error generating or downloading PDF:', error)
    }
  }
  downloadEwayBill = async () => {
    const doc = this.props.order
    const req = {
      document_type: doc.document_type,
      invoice_serial_number: doc.eway_bill[0].invoice_serial_number,
      new_hash_id: doc.new_hash_id,
      serial_number: doc.eway_bill[0].serial_number,
    }
    const hide = message.loading({
      content: 'Downloading...',
      duration: 0,
    })
    const filename = `${'EwayBill'}-${doc.company.organization_name}-${
      doc.eway_bill[0].serial_number
    }`
    const data = await getAPIPDF(`ewaybills`, 'get_ewaybill_document', req)
    this.download(data.data, filename)
    hide()
  }
  render() {
    var document = this.props.order
    var menuName = this.props.menuName
    var paymentGateway = this.props.paymentGateway
    var displayRazorpay = this.props.displayRazorpay

    const onSelectChange = keys => {
      setSelectedRowKeys(keys)
    }

    var document_name = 'Invoice'
    if (menuName == 'sales') {
      document_name = 'Invoice'
    } else if (menuName == 'purchases') {
      document_name = 'Purchase'
    } else if (menuName == 'purchase_orders') {
      document_name = 'Purchase Order'
    } else if (menuName == 'estimates') {
      document_name = 'Estimate'
    } else if (menuName == 'pos') {
      document_name = 'Invoice'
    } else if (menuName == 'purchase_returns') {
      document_name = 'Purchase Return'
    } else if (menuName == 'sales_returns') {
      document_name = 'Sales Return'
    } else if (menuName == 'delivery_challans') {
      document_name = 'Delivery Challan'
    }

    const DescriptionItem = ({ title, content }) => (
      <div className="site-description-item-profile-wrapper" style={{ lineHeight: '2.25rem' }}>
        <div className="row p-0 m-0 justify-content-end">
          <div className="col-7 text-right">
            <span
              className="site-description-item-profile-p-label font-weight-bold text-gray-50000"
              style={{ display: 'inline-block', fontSize: '1.08rem' }}
            >
              {title}
            </span>
          </div>
          <div className="col-4 m-0 p-0 align-self-end">
            <span
              className="font-weight-bolder"
              style={{ display: 'inline-block', fontSize: '1.44rem' }}
            >
              {' '}
              {content}{' '}
            </span>
          </div>
        </div>
      </div>
    )

    const DescriptionItem1 = ({ title, content }) => (
      <div
        className="site-description-item-profile-wrapper"
        style={{ marginBottom: '1px', lineHeight: '1.35rem' }}
      >
        <div className="row p-0 m-0 justify-content-end">
          <div className="col-7 text-right">
            <span
              className="site-description-item-profile-p-label text-right font-weight-bold text-gray-10"
              style={{ display: 'inline-block', fontSize: '0.9rem' }}
            >
              {title}
            </span>
          </div>
          <div className="col-4 m-0 p-0 align-self-end">
            <span
              className="font-weight-bold text-gray-50000"
              style={{ float: 'right', minWidth: '2rem', fontSize: '1.08rem' }}
            >
              {content}
            </span>
          </div>
        </div>
      </div>
    )

    const NormalDescriptionItem = ({ title, content }) => (
      <div className=" font-weight-bold site-description-item-profile-wrapper">{content}</div>
    )

    const dotOptions = { minimumFractionDigits: 2, maximumFractionDigits: 2 }
    // console.log(dayjs(document.eway_bill[0].eway_bill_date).format("MMM D YYYY"))

    return (
      <div>
        <div className="row">
          <div className="col-12 col-md-12 text-center mt-2">
            {document.customer.name != '' && document.customer.name != null && (
              <h5 className="font-weight-bolder text-gray-900 mb-2">
                Hi, {document.customer.name}!
              </h5>
            )}
            <h6 className="font-weight-bolder text-gray-50000">
              {document_name} from {document.company.organization_name}
            </h6>
          </div>

          <div className="col-12 col-md-12 text-center mb-4">
            {menuName != 'estimates' && (
              <Button
                size=""
                onClick={() =>
                  this.invoiceForm.onOpen(menuName, { new_hash_id: this.props.new_hash_id })
                }
                type="link"
                className="font-weight-bolder font-size-21"
              >
                {document.payment_status == 'paid' && (
                  <>
                    <i className="fa-solid text-forest fa-circle-check mr-2"></i>
                    <span className="text-forest">Amount {document.payment_status}</span>
                  </>
                )}
                {document.payment_status != 'paid' && (
                  <>
                    <i className="fa-regular fa-circle-exclamation text-pending mr-2"></i>
                    <span className="text-pending">{document.payment_status}</span>
                  </>
                )}
              </Button>
            )}
          </div>

          <div className="col-12 col-md-12 mb-4 text-center">
            {document.hide_totals == 0 && (
              <>
                <p className="font-weight-bold font-size-12 mb-0">TOTAL AMOUNT</p>
                <h6 className="font-weight-bolder text-gray-900 font-size-36 mb-1">
                  {document.export_details.length > 0 &&
                  document.invoice_settings.show_both_currencies != true ? (
                    <span className="font-size-30 ">
                      {document.export_details[0].currency_symbol}
                    </span>
                  ) : (
                    <span className="font-size-24 mr-1">{document?.company?.currency_symbol}</span>
                  )}
                  {document.export_details.length > 0 &&
                  document.invoice_settings.show_both_currencies != true
                    ? (
                        document.total_amount / document.export_details[0].conversion_factor
                      ).toFixed(2)
                    : document.total_amount.toLocaleString('en-IN', dotOptions)}
                </h6>
              </>
            )}

            {document.amount_paid > 0 &&
              document.amount_paid < document.total_amount &&
              document.is_tds == 0 && (
                <div className="row mt-3">
                  <div className="col-6 col-md-6 mb-2 text-center">
                    <p className="text-gray-500 font-weight-bold font-size-10 mb-0">PAID</p>
                    <h6 className="font-weight-bolder text-green text-gray-900 font-size-24 mb-1">
                      <span className="font-size-18 mr-1">
                        {document?.company?.currency_symbol}
                      </span>
                      {document.amount_paid.toLocaleString('en-IN', dotOptions)}
                    </h6>
                  </div>

                  <div className="col-6 col-md-6 mb-2 text-center">
                    <p className="text-gray-500 font-weight-bold font-size-10 mb-0">PENDING</p>
                    <h6 className="font-weight-bolder text-card font-size-24 mb-1">
                      <span className="font-size-18 mr-1">
                        {document?.company?.currency_symbol}
                      </span>
                      {document.amount_pending.toLocaleString('en-IN', dotOptions)}
                    </h6>
                  </div>
                </div>
              )}
            {document.amount_paid > 0 &&
              document.amount_paid < document.total_amount &&
              document.is_tds == 1 && (
                <div className="row mt-3">
                  <div className="col-12 mb-2 text-center">
                    <p className="text-gray-500 font-weight-bold font-size-10 mb-0">PENDING</p>
                    <h6 className="font-weight-bolder text-card font-size-24 mb-1">
                      <span className="font-size-18 mr-1">
                        {document?.company?.currency_symbol}
                      </span>
                      {document.amount_pending.toLocaleString('en-IN', dotOptions)}
                    </h6>
                  </div>
                </div>
              )}

            {menuName != 'estimates' && document.document_due_date != document.document_date && (
              <p className="text-gray-500 font-size-14 mb-0">Due on {document.document_due_date}</p>
            )}
            {menuName == 'estimates' && (
              <p className="text-gray-500 font-size-14 mb-0">Thank you for your business!</p>
            )}
            {/*<Button type="link" className="font-weight-bolder" onClick={this.showDrawer}>
              View Details <i className="ml-2 fa fa-arrow-right"></i>
            </Button>*/}

            <Button
              type="link"
              className="font-weight-bolder arrow-transition"
              onClick={() =>
                this.invoiceForm.onOpen(menuName, { new_hash_id: this.props.new_hash_id })
              }
            >
              View Details <i className="ml-2 fa fa-arrow-right arrow-transition"></i>
            </Button>
            {/* <div className="element">Hover Me</div> */}
            {/* <div className="element">Hover Me <i className="fas fa-arrow-right"></i></div> */}
          </div>

          <div className="col-12 col-md-12">
            <div className="row justify-content-center">
              <div className="col-6 col-md-4 text-left">
                <h6 className="mb-2 text-gray-50000">{document_name} #</h6>
                <h6 className="mb-2 text-gray-50000">{document_name} Date</h6>
                {document.eway_bill.length > 0 && (
                  <h6 className="mb-2 text-gray-50000">Eway Bill #</h6>
                )}
                {document.eway_bill.length > 0 && (
                  <h6 className="mb-2 text-gray-50000">Eway Bill Date:</h6>
                )}
                {/*{document.customer.balance < 0 && <h6 className="mb-2 text-gray-50000">Balance :</h6>}*/}
              </div>
              <div className="col-6 col-md-4 text-right">
                <h6 className="mb-2 font-weight-bold">{document.serial_number}</h6>
                <h6 className="mb-2 font-weight-bold">{document.document_date}</h6>
                {document.eway_bill.length > 0 && (
                  <h6 className="mb-2 font-weight-bold">{document.eway_bill[0].serial_number}</h6>
                )}
                {document.eway_bill.length > 0 && (
                  <h6 className="mb-2 font-weight-bold">
                    {dayjs(document.eway_bill[0].eway_bill_date).format('MMM D YYYY')}
                  </h6>
                )}

                {/*{document.customer.balance < 0 && (
                  <h6 className="mb-2 font-weight-bold">{Math.abs(document.customer.balance)}</h6>
                )}*/}
              </div>
            </div>
            <div className="col-12 col-md-12 mt-1 mb-2 text-center"></div>
          </div>

          {document_name == 'Invoice' &&
            (document.payment_status == 'pending' || document.payment_status == 'partially paid') &&
            document.company.upi != '' && (
              <div className="col-12 col-md-12 mt-4 mb-2">
                {/*<h6 className="font-weight-bold text-gray-900 text-primary mb-2">Pay now using UPI</h6>*/}

                <div className="row align-items-center justify-content-center">
                  {/*<div className="col-12 col-md-12 text-center">*/}
                  <div className="col-5 col-md-6 text-center">
                    <EnhancedQRCode
                      className="mb-2"
                      onRef={ref => (document = ref)}
                      value={document.upi}
                    />
                  </div>
                  <div className="col-6 col-md-6 text-center">
                    <p className="font-size-12 mb-0">{document.company.upi}</p>
                    <CopyToClipboard
                      text={document.company.upi}
                      className="btn btn-sm btn-default btn-outline mt-2 font-size-12"
                      onCopy={() => message.success('Copied')}
                    >
                      <Button type="action">
                        <i className="fa fa-clone mr-1"></i> Copy UPI ID
                      </Button>
                    </CopyToClipboard>
                    {this.state.copied ? (
                      <span style={{ color: '#05A357' }} className="ml-2">
                        Copied.
                      </span>
                    ) : null}

                    <p className="font-size-12 mb-0 mt-4">{document.company.gpay_number}</p>
                    {document.company.gpay_number != '' && (
                      <CopyToClipboard
                        text={document.company.gpay_number}
                        className="btn btn-sm btn-default btn-outline mt-2 font-size-12"
                        onCopy={() => message.success('Copied')}
                      >
                        <Button type="action">
                          <i className="fa fa-clone green mr-1"></i> Copy GPay/PhonePe No.
                        </Button>
                      </CopyToClipboard>
                    )}
                  </div>
                </div>
              </div>
            )}

          {document.payment_status != 'cancelled' && (
            <div className="col-12 col-md-12 mb-2 text-center">
              <PDFDownloadLink
                style={{ color: '#ffffff' }}
                document={<AllInvoice order={document} menuName={menuName} />}
                fileName={`${document_name}-${document.company.organization_name}-${document.serial_number}.pdf`}
              >
                {({ blob, url, loading, error }) =>
                  loading ? (
                    <Button type="primary" className="mt-3 mr-2 ml-2">
                      Loading document ... <i className="ml-1 fa-solid fa-circle-notch fa-spin"></i>
                    </Button>
                  ) : (
                    <Button type="primary" className="mt-3 mr-2 ml-2">
                      <i className="mr-2 fa-solid fa-sharp font-weight-bold fa-arrow-down"></i>
                      Download {document_name}
                    </Button>
                  )
                }
              </PDFDownloadLink>

              {/* <Button
                type="primary"
                className="mt-3 mr-2 ml-2"
                onClick={() => this.downloadDocument(document, menuName, document_name)}
              >
                Download {document_name} <i className="ml-2 fa-solid fa-arrow-down-to-square"></i>
              </Button> */}

              {paymentGateway == true &&
                (document.payment_status == 'pending' ||
                  document.payment_status == 'partially paid') && (
                  <Button
                    key={0}
                    type="pay"
                    size="default"
                    className="px-5 mt-3 mr-2 ml-2"
                    loading={this.state.loading}
                    onClick={() => displayRazorpay()}
                  >
                    <span className="font-weight-bolder">
                      Pay Now
                      <i className="fa fa-angle-double-right font-weight-bold ml-1 fa-beat" />
                    </span>
                  </Button>
                )}

              {document.eway_bill.length > 0 && (
                <Button
                  type="primary"
                  className="mt-3 mr-2 ml-2"
                  onClick={() => this.downloadEwayBill()}
                >
                  <i className="mr-2 fa-solid fa-sharp font-weight-bold fa-arrow-down"></i>
                  Download EwayBill
                </Button>
              )}
            </div>
          )}

          <Drawer
            width={isMobile ? '100%' : '45%'}
            height={isMobile ? '90%' : '100%'}
            placement={isMobile ? 'top' : 'right'}
            title="Invoice Details"
            closable={true}
            closeIcon={<i className="fa-solid fa-lg fa-xmark" />}
            onClose={this.onClose}
            open={this.state.viewDetails}
            footer={
              <div
                style={{
                  textAlign: 'left',
                }}
              >
                <Button type="danger" onClick={this.onClose} style={{ marginLeft: 0 }}>
                  Close
                </Button>
              </div>
            }
          >
            <div className="mb-2" style={{ borderBottom: '1px solid #E3E8EE' }}>
              <div className="mb-2" style={{ padding: '0 1rem' }}>
                <span className="text-gray-50000">{document_name} # </span>
                <span className="mb-2 font-weight-bold">{document.serial_number}</span>
                <h6 className="font-weight-bolder text-gray-900 font-size-32 mb-1">
                  <span className="font-size-21 mr-1">{document?.company?.currency_symbol}</span>
                  {document.total_amount.toLocaleString('en-IN', dotOptions)}
                </h6>
              </div>
            </div>

            <Row className="text-gray-10" style={{ fontSize: '0.9rem', padding: '0 1rem' }}>
              <Col span={12} style={{ textAlign: 'left' }}>
                <span>Item</span>
              </Col>
              <Col span={6} style={{ textAlign: 'center' }}>
                <span>Qty</span>
              </Col>
              <Col span={6} style={{ textAlign: 'right' }}>
                <span>Price</span>
              </Col>
            </Row>

            {document.items.map((each, index) => (
              <Row className="text-gray-50000" style={{ fontSize: '1rem', padding: '0 1rem' }}>
                <Col span={12} style={{ textAlign: 'left' }}>
                  <NormalDescriptionItem content={each.name + ' ' + each.variant_name} />
                </Col>
                <Col span={6} style={{ textAlign: 'center' }}>
                  <NormalDescriptionItem
                    content={
                      <>
                        {each.qty}{' '}
                        {each.unit != '' &&
                          each.unit != 'OTH' &&
                          each.unit != 'NONE' &&
                          each.unit != 'UNDEFINED' &&
                          each.unit}
                      </>
                    }
                  />
                </Col>
                <Col span={6} style={{ textAlign: 'right' }}>
                  <NormalDescriptionItem
                    content={
                      <>
                        <span className="mr-1 font-size-10">
                          {document?.company?.currency_symbol}
                        </span>
                        {each.total_amount.toLocaleString('en-IN', dotOptions)}
                      </>
                    }
                  />
                </Col>
              </Row>
            ))}
            <Divider />

            <Row style={{ textAlign: 'right', padding: '0 1rem' }}>
              <Col span={24}>
                <DescriptionItem1
                  title="Subtotal"
                  content={
                    <>
                      <span className="font-size-13 mr-1">
                        {document?.company?.currency_symbol}
                      </span>{' '}
                      {document.net_amount.toLocaleString('en-IN', dotOptions)}
                    </>
                  }
                />
                {document.tax_amount > 0 && (
                  <DescriptionItem1
                    title="Tax Amount"
                    content={
                      <>
                        <span className="font-size-13 mr-1">
                          {document?.company?.currency_symbol}
                        </span>{' '}
                        {document.tax_amount.toLocaleString('en-IN', dotOptions)}
                      </>
                    }
                  />
                )}
                {document.packaging_charges > 0 && (
                  <DescriptionItem1
                    title="Packaging Charges"
                    content={
                      <>
                        <span className="font-size-13 mr-1">
                          {document?.company?.currency_symbol}
                        </span>{' '}
                        {document.packaging_charges.toLocaleString('en-IN', dotOptions)}
                      </>
                    }
                  />
                )}
                {document.transport_charges > 0 && (
                  <DescriptionItem1
                    title="Delivery Charges"
                    content={
                      <>
                        <span className="font-size-13 mr-1">
                          {document?.company?.currency_symbol}
                        </span>{' '}
                        {document.transport_charges.toLocaleString('en-IN', dotOptions)}
                      </>
                    }
                  />
                )}
              </Col>

              <Col span={24}>
                <DescriptionItem
                  title="Total Amount"
                  content={
                    <>
                      <span className="font-size-13 mr-1">
                        {document?.company?.currency_symbol}
                      </span>{' '}
                      {document.total_amount.toLocaleString('en-IN', dotOptions)}
                    </>
                  }
                />
              </Col>
            </Row>

            <Divider />

            <div className="mt-2 mb-2">
              <Collapse
                defaultActiveKey={[]}
                bordered={false}
                expandIcon={({ isActive }) =>
                  isActive ? (
                    <span className="text-right" style={{ color: '#535fcf' }}>
                      Close
                      <i className="fa fa-chevron-down ml-1" style={{ color: '#535fcf' }} />
                    </span>
                  ) : (
                    <span style={{ color: '#535fcf' }} className="text-right">
                      Details
                      <i className="fa fa-chevron-right ml-1" style={{ color: '#535fcf' }} />
                    </span>
                  )
                }
                expandIconPosition="right"
                className="mb-3 site-collapse-custom-collapse"
                style={{
                  borderRadius: '10px',
                  borderBottom: '0px',
                }}
              >
                <Panel
                  header={
                    <>
                      <span className="text-gray-10 mr-2">
                        {document_name == 'Purchase' ? 'Vendor Name' : 'Customer Name'}
                      </span>
                      <span className="text-gray-50000 font-weight-bold">
                        {document.customer.name}
                      </span>
                    </>
                  }
                  key="1"
                  className="font-weight-bold"
                  style={{ color: '#535fcf', fontSize: '1rem' }}
                >
                  {document_name != 'Purchase' && document_name != 'Purchase Order' && (
                    <div className="mb-2 font-weight-normal" style={{ fontSize: '1rem' }}>
                      <p>
                        {document.customer.company_name != '' && (
                          <p className="mb-0">
                            <span className="text-gray-10 mr-1">Company</span>
                            <span className="font-weight-bold text-gray-50000">
                              {document.customer.company_name}
                            </span>
                          </p>
                        )}

                        {document.customer.gstin != '' && (
                          <p className="mb-0">
                            <span className="text-gray-10 mr-1">
                              {document?.company?.labels?.gstin}
                            </span>
                            <span className="text-gray-50000">{document.customer.gstin}</span>
                          </p>
                        )}

                        {document.customer.billing.address_1 != '' && (
                          <>
                            <p className="text-gray-50000 mt-2 mb-0">
                              <span className="text-gray-11 mr-1">Bill to</span>
                            </p>
                            <p className="text-gray-50000 mb-0">
                              <span className="text-gray-50000">
                                {document.customer.billing.address_1}
                              </span>
                            </p>
                            <p className="text-gray-50000 mb-0">
                              {document.customer.billing.address_2}
                            </p>
                            <p className="text-gray-50000 mb-0">
                              <span className="mr-1">{document.customer.billing.city}</span>
                              <span className="mr-1">{document.customer.billing.state}</span>
                              <span>{document.customer.billing.pincode}</span>
                            </p>
                          </>
                        )}

                        {document.customer.shipping?.address_1 != '' && (
                          <>
                            <p className="text-gray-50000 mt-2 mb-0">
                              <span className="text-gray-11 mr-1">Ship to</span>
                            </p>
                            <p className="mb-0">
                              <span className="text-gray-50000">
                                {document.customer.shipping?.address_1}
                              </span>
                            </p>
                            <p className="text-gray-50000 mb-0">
                              {document.customer.shipping?.address_2}
                            </p>
                            <p className="text-gray-50000 mb-0">
                              <span className="mr-1">{document.customer.shipping?.city}</span>
                              <span className="mr-1">{document.customer.shipping?.state}</span>
                              <span>{document.customer.shipping?.pincode}</span>
                            </p>
                          </>
                        )}
                      </p>
                    </div>
                  )}

                  {(document_name == 'Purchase' || document_name == 'Purchase Order') && (
                    <div className="mb-2 font-weight-normal" style={{ fontSize: '1rem' }}>
                      <p>
                        {document.customer.company_name != '' && (
                          <p className="mb-0">
                            <span className="text-gray-10 mr-1">Company</span>
                            <span className="font-weight-bold text-gray-50000">
                              {document.customer.company_name}
                            </span>
                          </p>
                        )}

                        {document.customer.gstin != '' && (
                          <p className="mb-0">
                            <span className="text-gray-10 mr-1">
                              {document?.company?.labels?.gstin}
                            </span>
                            <span className="text-gray-50000">{document.customer.gstin}</span>
                          </p>
                        )}

                        {document.customer.billing.address_1 != '' && (
                          <>
                            <p className="text-gray-50000 mt-2 mb-0">
                              <span className="text-gray-11 mr-1">Bill to</span>
                            </p>
                            <p className="text-gray-50000 mb-0">
                              <span className="text-gray-50000">
                                {document.customer.billing.address_1}
                              </span>
                            </p>
                            <p className="text-gray-50000 mb-0">
                              {document.customer.billing.address_2}
                            </p>
                            <p className="text-gray-50000 mb-0">
                              <span className="mr-1">{document.customer.billing.city}</span>
                              <span className="mr-1">{document.customer.billing.state}</span>
                              <span>{document.customer.billing.pincode}</span>
                            </p>
                          </>
                        )}
                      </p>
                    </div>
                  )}
                </Panel>
              </Collapse>
            </div>
          </Drawer>
        </div>
        {document.attachments.length > 0 && (
          <div className="justify-content-center mt-4 ">
            <h6 className="text-center font-weight-bold">Invoice Attachments</h6>

            <div className="text-center">
              {document.attachments.map((attachment, index) => (
                <div
                  className="d-flex flex-row justify-content-between border my-2 p-2 rounded"
                  key={index}
                  onClick={e => {
                    e.stopPropagation()
                    this.downloadFile(attachment.url, attachment.name)
                  }}
                  style={{ cursor: 'pointer' }}
                >
                  <div className="">
                    <i className="fa fa-paperclip mr-1" aria-hidden="true"></i>

                    <span className="">{attachment.name}</span>
                  </div>
                  <div className="">
                    <a
                      onClick={e => {
                        e.stopPropagation()
                        this.downloadFile(attachment.url, attachment.name)
                      }}
                      className="text-blue"
                    >
                      <span>
                        {' '}
                        <i className="fa-regular fa-arrow-down" />{' '}
                      </span>
                    </a>
                  </div>
                </div>
              ))}
            </div>
          </div>
        )}
        <EnhancedInvoiceForm onRef={ref => (this.invoiceForm = ref)} />
      </div>
    )
  }
}

export default Table8

export function AllInvoice({ order, menuName, flag = false }) {
  const [state, setState] = useReducer((state, newState) => ({ ...state, ...newState }), {
    invoiceSettings: { ...order.invoice_settings },
  })
  let type = ['']

  var SelectedTemplate = Map['Invoice1']

  if (flag) {
    var SelectedTemplate = ChallanInvoice
  }
  if ((menuName == 'sales' || menuName == 'pos' || menuName == 'sales_returns') && flag == false) {
    var SelectedTemplate = Map['Invoice' + String(state.invoiceSettings.invoice_template)]
  }
  if (menuName == 'purchases' || menuName == 'purchase_orders' || menuName == 'purchase_returns') {
    var SelectedTemplate = Map['Invoice' + String(state.invoiceSettings.purchase_template)]
  }
  if (
    menuName == 'estimates' ||
    menuName == 'pro_forma_invoices' ||
    menuName == 'delivery_challans'
  ) {
    var SelectedTemplate = Map['Invoice' + String(state.invoiceSettings.estimate_template)]
  }
  if (menuName == 'delivery_challans') {
    type = ['Delivery Challan']
  }

  if (SelectedTemplate == undefined) {
    SelectedTemplate = Map['Invoice1']
  }

  return (
    <React.Fragment>
      {(menuName == 'sales' || menuName == 'pos') && flag == false && (
        <SelectedTemplate
          invoice={{
            ...order,
            document_type: menuName,
            type: [order.invoice_settings.labels.original_for_recipient],
            myKey: 0,
          }}
        />
      )}

      {menuName == 'sales_returns' && (
        <SelectedTemplate invoice={{ ...order, document_type: menuName, type: [''] }} />
      )}

      {(menuName == 'purchases' ||
        menuName == 'purchase_orders' ||
        menuName == 'purchase_returns') && (
        <SelectedTemplate invoice={{ ...order, document_type: menuName, type: [''] }} />
      )}

      {(menuName == 'estimates' ||
        menuName == 'delivery_challans' ||
        menuName == 'pro_forma_invoices') && (
        <SelectedTemplate invoice={{ ...order, document_type: menuName, type: type }} />
      )}
    </React.Fragment>
  )
}
