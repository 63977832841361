import { all, call, put, select, takeEvery } from 'redux-saga/effects'
import { getAPIData } from 'services/jwt'
import { CountryInfo, GetCountryAction, setCountryInfo } from './actions'
import { CountryActions } from './types'

interface CountryInfoAPIResponse {
  success: boolean
  country_info: CountryInfo
}

export function* getCountryInfo(action: GetCountryAction) {
  const countries: CountryInfo = yield select(state => state.countries.info)

  const { country_code } = action.payload
  let params_string = ''

  if (country_code in countries) {
    return
  }

  if (country_code !== '') {
    params_string = `?country_code=${country_code}`
  }

  try {
    const data: CountryInfoAPIResponse = yield call(() =>
      getAPIData('utils', `get_country_info${params_string}`),
    )
    if (data.success) {
      yield put(setCountryInfo(data.country_info))
    }
  } catch (error) {
    console.error('Error fetching country info:', error)
  }
}

export default function* countries() {
  yield all([takeEvery(CountryActions.GET_COUNTRY_INFO, getCountryInfo)])
}
