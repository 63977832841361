import { Image, Text, View } from '@react-pdf/renderer'
import { EnhancedQRCode } from 'components/other/qrCodeFile'
import React from 'react'
import { export_terms } from '../export_condition'
import { styles as getStyles } from './styles'

const invoiceNotes = ({ invoice, type }) => {
  var styles = getStyles(invoice)

  return (
    <View style={[styles.globalContainer, styles.invoiceNotes_borderTop]} wrap={false}>
      {/* {type != 'Delivery Challan' && ( */}
      <View style={styles.invoiceNotes_blockC}>
        <View style={[styles.invoiceNotes_blockCA, styles.invoiceNotes_borderBottom]}>
          <View style={[styles.invoiceNotes_blockCAA]}>
            <View style={styles.company_blockC}>
              <View style={styles.company_blockC2}>
                {Object.keys(invoice).includes('einvoice') && invoice.einvoice.length > 0 && (
                  <View>
                    <Text style={styles.company_irn}>IRN: {invoice.einvoice[0].irn}</Text>
                    <Text style={styles.company_irn}>
                      Acknowledgement Number: {invoice.einvoice[0].ack_no}
                    </Text>
                  </View>
                )}

                {invoice.rcm == 1 && (
                  <View style={styles.invoiceNotes_mb}>
                    <Text style={styles.invoiceNotes_label}>
                      *Amount of Tax subject to Reverse Charge
                    </Text>
                  </View>
                )}
                {invoice.immovable_tax_type == 1 && (
                  <View style={styles.invoiceNotes_mb}>
                    <Text style={styles.invoiceNotes_label}>
                      *Place of Supply is determined u/s 12(3) of the IGST Act 2017.
                    </Text>
                  </View>
                )}

                {invoice.is_export == 1 && invoice.company.gstin.length == 15 && (
                  <View style={styles.invoiceNotes_mb}>
                    <Text style={styles.invoiceNotes_label}>
                      *{export_terms[invoice.export_details[0].export_type]}
                    </Text>
                  </View>
                )}
                {invoice.notes != '' && (
                  <View style={styles.invoiceNotes_block}>
                    <Text style={styles.invoiceNotes_label}>Notes:</Text>
                    <Text style={styles.invoiceNotes_text}>{invoice.notes}</Text>
                  </View>
                )}
              </View>

              {Object.keys(invoice).includes('einvoice') && invoice.einvoice.length > 0 && (
                <View style={styles.invoiceNotes_qrcode}>
                  <EnhancedQRCode value={`${invoice.einvoice[0].qrcode}`} level="L" />
                </View>
              )}
            </View>
            {invoice.invoice_settings.show_receivers_signature == 1 && (
              <View style={styles.invoiceNotes_receiveSignBlock}>
                <Text
                  style={{
                    ...styles.invoiceNotes_receiveSignText,
                  }}
                >
                  Receiver's Signature{' '}
                </Text>
              </View>
            )}
          </View>
        </View>
        <View
          style={[
            styles.invoiceNotes_blockCC,
            styles.invoiceNotes_borderBottom,
            styles.invoiceNotes_borderLeft,
          ]}
        >
          {invoice.terms != '' && type != 'Delivery Challan' && (
            <View style={styles.invoiceNotes_block}>
              <Text style={styles.invoiceNotes_label}>Terms and Conditions:</Text>
              <Text style={styles.invoiceNotes_text}>{invoice.terms}</Text>
            </View>
          )}
        </View>

        <View
          style={[
            styles.invoiceNotes_blockCD,
            styles.invoiceNotes_borderBottom,
            styles.invoiceNotes_borderLeft,
          ]}
        >
          <View style={styles.invoiceNotes_block}>
            <Text
              style={
                invoice.company.company_name.length > 40
                  ? {
                      ...styles.invoiceNotes_label2,
                      fontSize: (6.66 * 40) / invoice.company.company_name.length,
                    }
                  : styles.invoiceNotes_label2
              }
            >
              For {invoice.company.company_name}{' '}
            </Text>

            {invoice.signature != '' && invoice.signature != null && (
              <Image
                style={styles.invoiceNotes_signature}
                src={{
                  uri: invoice.signature,
                  method: 'GET',
                  headers: { 'Cache-Control': 'no-cache' },
                }}
                crossorigin="anonymous"
              />
            )}

            <Text
              style={{
                ...styles.invoiceNotes_label3,
                marginTop: invoice.signature != '' ? 5 : 48,
              }}
            >
              Authorized Signatory{' '}
            </Text>
          </View>
        </View>
      </View>
      {/* )} */}

      {/*<View style={styles.invoiceNotes_blockC}>
      <View style={[styles.invoiceNotes_blockC40, styles.invoiceNotes_borderRight]}>
        {invoice.notes != '' && (
          <View style={styles.invoiceNotes_block}>
            <Text style={styles.invoiceNotes_label}>Notes:</Text>
            <Text style={styles.invoiceNotes_text}>{invoice.notes}</Text>
          </View>
        )}
      </View>
      <View style={[styles.invoiceNotes_blockC40]}>
        {invoice.terms != '' && type != 'Delivery Challan' && (
          <View style={styles.invoiceNotes_block}>
            <Text style={styles.invoiceNotes_label}>Terms and Conditions:</Text>
            <Text style={styles.invoiceNotes_text}>{invoice.terms}</Text>
          </View>
        )}
      </View>
    </View>*/}
    </View>
  )
}

export default invoiceNotes
