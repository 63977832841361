import { SET_LEAD_OPTIONS } from './types'

const initialState = {
  agentsList: [],
  leadStatusList: [],
  intrestedInList: [],
  campaignsList: [],
  resourcesList: [],
}

export default function userReducer(state = initialState, action) {
  switch (action.type) {
    case SET_LEAD_OPTIONS:
      return {
        ...state,
        agentsList: action.payload.leads_agents,
        leadStatusList: action.payload.leads_status,
        intrestedInList: action.payload.leads_features,
        campaignsList: action.payload.leads_campaigns,
        resourcesList: action.payload.leads_resources,
      }

    default:
      return state
  }
}
