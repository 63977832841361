import {
  Button,
  Card,
  Checkbox,
  Empty,
  Modal,
  Select,
  Table,
  Tabs,
  Tooltip,
  Upload,
  message,
} from 'antd'
import React, { Component, useState } from 'react'

import { ArrowDownOutlined, ArrowUpOutlined, InboxOutlined } from '@ant-design/icons'
import { FormattedMessage } from 'react-intl'
import { connect } from 'react-redux'
import { downloadAPI, getAPI, getMultipartAPI } from 'services/jwt'
import ProductBarcode from '../barcode'
import { EnhancedBulkUpload } from '../upload'
import BetaBadge from 'components/badges/beta'

const { Dragger } = Upload
const { TabPane } = Tabs
const dotOptions = { minimumFractionDigits: 2, maximumFractionDigits: 2 }

const getStatusClass = data => {
  if (data < 0) {
    return '#e11900'
  } else if (data == 0 && data < 5) {
    return '#757575'
  } else {
    return '#09864A'
  }
}
const priceListColumns = [
  {
    title: <FormattedMessage id="table.product" />,
    dataIndex: 'product_name',
    key: 'product_name',
  },
  {
    title: <FormattedMessage id="table.price" />,
    dataIndex: 'price',
    key: 'price',
  },
  {
    title: <FormattedMessage id="table.priceWithTax" />,
    dataIndex: 'price_with_tax',
    key: 'price_with_tax',
  },
  {
    title: <FormattedMessage id="table.discount" />,
    dataIndex: 'discount',
    key: 'discount',
    render: text => (
      <a className="" href="#" onClick={e => e.preventDefault()}>
        {text}
      </a>
    ),
    responsive: ['md'],
  },
  {
    title: <FormattedMessage id="table.tax" />,
    dataIndex: 'tax',
    key: 'product_id',
    render: text => (
      <a className="" href="#" onClick={e => e.preventDefault()}>
        {text}
      </a>
    ),
    responsive: ['md'],
  },
]
let productColumns = [
  {
    title: <FormattedMessage id="table.item" />,
    dataIndex: 'product_name',
    key: 'product_id',
    width: '25%',
    render: (text, data) => (
      <>
        <span>
          <a>
            <span className="text-gray-900 font-weight-medium">{text}</span>
          </a>
          <span className="font-size-10">
            <p className="mt-0 mb-0">
              <span className="text-gray-500 mr-1">{data.product_type}</span>
              <span className="text-orangeproduct mr-1">{data.product_category}</span>
              {data.hsn_code != '' && <span className="text-purple">{data.hsn_code}</span>}
              {/* {data.barcode_id != '' && (
                <a
                  onClick={() => {
                    this.barcode.showModal(data)
                  }}
                  className="ml-2"
                >
                  <i className="fa fa-barcode fa-lg" />
                </a>
              )} */}
            </p>
          </span>
        </span>
      </>
    ),
  },
  Table.EXPAND_COLUMN,
  {
    title: <FormattedMessage id="table.quantity" />,
    dataIndex: 'qty',
    key: 'qty',
    sorter: (a, b) => a.qty - b.qty,
    render: (text, data) => (
      <span className="font-weight-bolder font-size-16" style={{ color: getStatusClass(text) }}>
        {text}
        {data.unit != 'OTH' && (
          <span className="font-size-10 font-weight-bold text-purple ml-2">{data.unit}</span>
        )}
      </span>
    ),
  },
  {
    title: <FormattedMessage id="table.sellingPrice" />,
    dataIndex: 'price',
    key: 'product_id',
    render: (text, data) => (
      <>
        <p className="mb-0">
          <span className="font-weight-bold ls-2">
            <span className="mr-1">{this.props?.user?.selectedCompany?.currency_symbol}</span>
            {parseFloat(text).toLocaleString('en-IN', dotOptions)}
          </span>
          {data.discount > 0 && <span className="text-green ml-1">({data.discount}%)</span>}
        </p>
        {data.is_price_with_tax == 1 ? (
          <p className="font-size-10 mb-0 mt-0">
            <FormattedMessage id="text.withTax" />{' '}
          </p>
        ) : (
          <p className="font-size-10 mb-0 mt-0">
            <FormattedMessage id="text.withoutTax" />
          </p>
        )}
      </>
    ),
  },
  {
    title: <FormattedMessage id="table.purchasePrice" />,
    dataIndex: 'purchase_price',
    key: 'purchase_price',
    render: text => (
      <>
        <span className="ls-2">
          <span className="mr-1">{this.props?.user?.selectedCompany?.currency_symbol}</span>
          {parseFloat(text ? text : 0).toLocaleString('en-IN', dotOptions)}
        </span>
        <p className="font-size-10 mb-0 mt-0">
          <FormattedMessage id="text.withTax" />{' '}
        </p>
      </>
    ),
  },
  {
    title: <FormattedMessage id="table.tax" />,
    dataIndex: 'tax',
    key: 'product_id',
    render: text => <span className="ls-2">{text}</span>,
    responsive: ['md'],
  },
]
let customerColumns = [
  {
    title: <FormattedMessage id="table.id" />,
    dataIndex: 'id',
  },
  {
    title: <FormattedMessage id="table.name" />,
    dataIndex: 'name',
    width: '25%',
    render: (text, data) => (
      <a onClick={() => this.form.onFill(data.customer_id)}>
        <span className="font-weight-medium font-size-14">{text}</span>

        {(data.company_name || data.gstin) != '' && (
          <span className="font-weight-medium font-size-10">
            <p className="mt-0 mb-0 text-gray-10">
              {data.company_name} <span className="ml-1 text-gray-10">{data.gstin}</span>
            </p>
          </span>
        )}
      </a>
    ),
  },
  {
    title: 'Tags',
    dataIndex: 'tags',
    responsive: ['xl'],
    render: (text, data) => (
      <div>
        {data.tags != null && data.tags.split(',').map((tag, index) => <Tag>{tag}</Tag>)}
        {/* {data.tags} */}
      </div>
    ),
  },
  {
    title: <FormattedMessage id="table.contactInfo" />,
    dataIndex: 'phone',

    render: (text, data) => (
      <span>
        {data.phone != '' && data.phone != null && (
          <>
            <span className="ls-2">{text}</span>
            <span>
              <a
                className="btn-whatsapp ml-1 mb-2"
                href={
                  'https://api.whatsapp.com/send?' +
                  (data.phone != null && data.phone.length == 10 ? 'phone=91' + data.phone : '') +
                  '&text=Hello%20*' +
                  data.name +
                  '*'
                }
                target="_blank"
              >
                <i className="fa fa-whatsapp fa-lg" style={{ color: 'green' }} />
              </a>
            </span>
          </>
        )}
        <span>
          <p className="mt-0 mb-0 font-size-12">
            {data.email == null || data.email == '' ? '' : data.email}
          </p>
        </span>
      </span>
    ),
  },
  {
    title: <FormattedMessage id="text.notes" />,
    dataIndex: 'notes',
    width: '15%',
    responsive: ['xl'],
  },
  {
    title: <FormattedMessage id="table.closingBalance" />,
    dataIndex: 'balance',
    align: 'right',
    sorter: (a, b) => a.balance + a.opening_balance - (b.balance + b.opening_balance),
    render: (text, data) => (
      <>
        <span
          className={`font-weight-bolder font-size-14 ${
            text + data['opening_balance'] >= 0 ? 'text-success' : 'text-danger'
          }`}
        >
          {' '}
          {`${this.props.user.selectedCompany.currency_symbol} ` +
            Math.abs(text + data['opening_balance']).toLocaleString('en-IN', dotOptions)}
        </span>
        <p
          className={`font-size-9 text-uppercase mb-0 ${
            text + data['opening_balance'] >= 0 ? 'text-success' : 'text-danger'
          }`}
        >
          {text + data['opening_balance'] >= 0 ? (
            <>
              <span className="mr-1">
                <FormattedMessage id="payments.text.youPay" />
              </span>{' '}
              <ArrowUpOutlined />
            </>
          ) : (
            <>
              {' '}
              <span className="mr-1">
                <FormattedMessage id="payments.text.youCollect" />
              </span>{' '}
              <ArrowDownOutlined />
            </>
          )}{' '}
        </p>
      </>
    ),
  },
]
let vendorColumns = [
  {
    title: <FormattedMessage id="table.id" />,
    dataIndex: 'id',
    key: 'number',
  },
  {
    title: <FormattedMessage id="table.name" />,
    dataIndex: 'name',
    render: (text, data) => (
      <p>
        <span className="font-weight-medium font-size-14">{text}</span>
        {(data.company_name || data.gstin) != '' && (
          <span className="font-weight-medium font-size-10">
            <p className="mt-1 mb-0 text-gray-10">
              {data.company_name} <span className="ml-1 text-gray-10">{data.gstin}</span>
            </p>
          </span>
        )}
      </p>
    ),
  },
  {
    title: <FormattedMessage id="table.contactInfo" />,
    dataIndex: 'phone',
    render: (text, data) => (
      <span>
        {data.phone != '' && data.phone != null && (
          <>
            <span>{text}</span>
            <span>
              <a
                className="btn-whatsapp ml-1 mb-2"
                href={
                  'https://api.whatsapp.com/send?' +
                  (data.phone != null && data.phone.length == 10 ? 'phone=91' + data.phone : '') +
                  '&text=Hello%20*' +
                  data.name +
                  '*'
                }
                target="_blank"
              >
                <i className="fa fa-whatsapp fa-lg" style={{ color: 'green' }} />
              </a>
            </span>
          </>
        )}
        <span>
          <p className="mt-0 mb-0 font-size-12">
            {data.email == null || data.email == '' ? '' : data.email}
          </p>
        </span>
      </span>
    ),
  },
  {
    title: <FormattedMessage id="text.notes" />,
    dataIndex: 'notes',
    responsive: ['xl'],
  },
  // {
  //   title: 'GST',
  //   dataIndex: 'gst',
  //   key: 'gst',
  //   render: text => (
  //     <a className="" href="#" onClick={e => e.preventDefault()}>
  //       {text ? 'Yes' : 'No'}
  //     </a>
  //   ),
  // },
  {
    title: <FormattedMessage id="table.closingBalance" />,
    dataIndex: 'balance',
    align: 'right',
    sorter: (a, b) => a.balance + a.opening_balance - (b.balance + b.opening_balance),
    render: (text, data) => (
      <>
        <span
          className={`font-weight-medium ls-1 font-size-14 ${
            text + data['opening_balance'] >= 0 ? 'text-success' : 'text-danger'
          }`}
        >
          {' '}
          {`${this.props.user.selectedCompany.currency_symbol} ` +
            Math.abs(text + data['opening_balance']).toLocaleString('en-IN', dotOptions)}
        </span>
        <p
          className={`font-size-9 mt-1 text-uppercase text-right mb-0 ${
            text + data['opening_balance'] >= 0 ? 'text-success' : 'text-danger'
          }`}
        >
          {text + data['opening_balance'] >= 0 ? (
            <>
              <span className="mr-1">You Pay</span> <ArrowUpOutlined />
            </>
          ) : (
            <>
              {' '}
              <span className="mr-1">You Collect</span> <ArrowDownOutlined />
            </>
          )}{' '}
        </p>
      </>
    ),
  },
]
let inventoryColumns = [
  {
    title: <FormattedMessage id="table.id" />,
    dataIndex: 'product_id',
    responsive: ['md'],
    hidden: false,
  },
  {
    title: <FormattedMessage id="table.item" />,
    dataIndex: 'product_name',
    width: '20%',
    render: (text, data) => (
      <span>
        <span className="font-weight-bold">{text}</span>
        {data.product_category && data.product_category != '' && (
          <span className="font-weight-bold font-size-10">
            <p className="mt-0 mb-0">
              {/*                  <span className="text-purple mr-1">{data.product_type}</span>
               */}{' '}
              <span className="text-orangeproduct mr-1">{data.product_category}</span>
            </p>
          </span>
        )}
      </span>
    ),
    hidden: false,
  },
  {
    title: <FormattedMessage id="table.batch" />,
    dataIndex: 'batch_no',
    render: text => (
      <a className="" href="#" onClick={e => e.preventDefault()}>
        {text}
      </a>
    ),
    hidden: true,
  },
  {
    title: <FormattedMessage id="table.quantity" />,
    dataIndex: 'qty',
    // editable: !this.state.confirmFlag,
    sorter: (a, b) => a.qty - b.qty,
    render: (text, data) => (
      <span
        className="font-weight-bolder font-size-16"
        style={{ color: getStatusClass(text) }}
        href="#"
        onClick={e => e.preventDefault()}
      >
        {text}
        {data.unit != 'OTH' && (
          <span className="font-size-10 font-weight-bold text-purple ml-2">{data.unit}</span>
        )}
      </span>
    ),
    hidden: false,
  },
  {
    title: <FormattedMessage id="table.status" />,
    dataIndex: 'status',
    render: text => (
      <a className="" href="#" onClick={e => e.preventDefault()}>
        {text == 1 ? 'Stock Out' : 'Stock In'}
      </a>
    ),
    hidden: false,
  },
]

export class Import extends Component {
  constructor(props) {
    super(props)
    this.state = {
      flag: false,
      ops: null,
      error: '',
      value: 0,
      confirmFlag: false,
      errorFalg: false,
      errors: [],
      importData: [],
      menu: '',
      key: 1,
      custom_columns: [],
      confirmError: '',
      showSkip: false,
      data: {
        records: [],
        columns: [],
        have_error: false,
        other_data: {},
      },
      showConfimationModal: false,
      loading: false,
      filter: 'all',
      confirmModal: false,
      expandedKeys: [],
      warehouse_id: -1,
    }
    this.onTabChange = this.onTabChange.bind(this)
    this.customRequest = this.customRequest.bind(this)
  }

  componentDidMount() {
    this.props.onRef(this)
    this.setState({ menu: this.props.menu })
    const menu = this.props.menu
    if (menu == 'product') {
      this.setState({ key: '1' })
    } else if (menu == 'customer') {
      this.setState({ key: '2' })
    } else if (menu == 'vendor') {
      this.setState({ key: '3' })
    } else if (menu == 'invoices') {
      this.setState({ key: '6' })
    }
  }

  componentWillUnmount() {
    this.props.onRef(null)
  }

  showModal = (type = 2) => {
    this.setState({ flag: true, value: type })
    if (type == 0) {
      this.setState({ key: '4' })
    }
    if (type == 1) {
      this.setState({ key: '5' })
    }
  }

  handleCancel = () => {
    this.setState({ flag: false })
  }

  onChange = e => {
    this.setState({ value: e.target.value })
  }
  onImportDataChange = data => {
    if ('is_error' in data && data.is_error) {
      this.setState({ errors: data.errors, errorFalg: true })
      return
    }
    let results = []
    if (this.state.menu == 'inventory') {
      results = data.stock.map((row, index) => ({
        ...row,
        key: index,
      }))
    }
    if (this.state.menu == 'product') {
      results = data.products.map((row, index) => ({
        ...row,
        key: index,
      }))
    }
    if (this.state.menu == 'customer') {
      results = data.customers.map((row, index) => ({
        key: index,
        ...row,
      }))
    }
    if (this.state.menu == 'vendor') {
      results = data.vendors.map((row, index) => ({
        key: index,
        ...row,
      }))
    }
    this.setState({ importData: results })
    if ('custom_columns' in data) {
      this.setState({ custom_columns: data.custom_columns })
    } else {
      this.setState({ custom_columns: [] })
    }
    if ('message' in data) {
      this.setState({ confirmError: data.message })
      if (data.message == '') {
        this.setState({ confirmFlag: true })
      } else {
        this.setState({ showSkip: true })
      }
    } else {
      this.setState({ confirmFlag: true })
    }
  }
  customRequest = async ({ onSuccess, onError, file }, warehouse_id) => {
    const form = new FormData()
    form.append('file', file)
    if (this.state.menu == 'inventory') {
      form.append('transaction_type', this.state.value)
      this.setState({ warehouse_id: warehouse_id })
      form.append('warehouse_id', warehouse_id)
    }
    if (this.state.menu != 'invoices') {
      form.append('upload_type', this.state.menu)
    }
    let data = {}
    // if (this.state.menu == 'inventory') {
    //   data = await getMultipartAPI(this.state.menu, 'upload', form)
    // } else {
    if (this.state.menu != 'invoices') {
      data = await getMultipartAPI('upload', 'uploads', form)
    } else {
      form.append('document_type', 'invoice')
      data = await getMultipartAPI('imports/swipe/docs', 'upload', form)
    }
    // }
    if (data) {
      if (data.success == undefined) {
        this.setState({ error: 'Something went wrong. Please try again later.' })
        onError()
      } else if (data.success) {
        onSuccess(null, file)
        this.setState({ error: '', flag: false })
        // if (this.state.menu == 'inventory') {
        //   this.onImportDataChange(data)
        // } else {
        this.showRecords(data)
        // }

        // this.onImportDataChange(data)

        // this.props.onDataChange(data)
      } else {
        this.setState({ error: data.message })
        onError()
      }
    }
  }

  showRecords = data => {
    let columns = data['columns'].map(item => {
      if (item == 'Product') {
        return {
          title: 'Product',
          dataIndex: 'Product',
          key: 'product_id',
          width: '150px',
          render: (text, data) => (
            <>
              <span>
                {/* <a>
                  <Tooltip title={data.message} className="mr-2">
                    <i
                      className={'fa fa-info-circle ml-1'}
                      style={{
                        color: data.error
                          ? '#e11900'
                          : data.status == 'new'
                          ? '#09864A'
                          : '#757575',
                      }}
                    />
                  </Tooltip>
                  <span className="text-gray-900 font-weight-medium">
                    {text}
                  </span>
                </a> */}
                <span className="text-gray-900 font-weight-medium">{text}</span>
                <span className="font-size-10">
                  <p className="mt-0 mb-0">
                    <span className="text-gray-500 mr-1">{data['Type']}</span>
                    <span className="text-orangeproduct mr-1">{data['Category']}</span>
                    {data.hsn_code != '' && (
                      <span className="text-purple">{data['HSN/SAC Code']}</span>
                    )}
                    {/* {data.barcode_id != '' && (
                        <a
                          onClick={() => {
                            this.barcode.showModal(data)
                          }}
                          className="ml-2"
                        >
                          <i className="fa fa-barcode fa-lg" />
                        </a>
                      )} */}
                  </p>
                </span>
              </span>
            </>
          ),
        }
      } else if (item == 'Qty') {
        return {
          title: 'Qty',
          dataIndex: 'Qty',
          key: 'product_id',
          width: '150px',
          sorter: (a, b) => a.Qty - b.Qty,
          render: (text, data) => (
            <span
              className="font-weight-bolder font-size-16"
              style={{ color: getStatusClass(text) }}
            >
              {text}
              {data.Units != 'OTH' && (
                <span className="font-size-10 font-weight-bold text-purple ml-2">{data.Units}</span>
              )}
            </span>
          ),
        }
      } else if (item == 'Price') {
        return {
          title: 'Selling Price',
          dataIndex: 'Price',
          key: 'product_id',
          width: '150px',
          render: (text, data) => (
            <>
              <p className="mb-0">
                <span className="font-weight-bold ls-2">
                  <span className="mr-1">{this.props?.user?.selectedCompany?.currency_symbol}</span>
                  {parseFloat(text).toLocaleString('en-IN', dotOptions)}
                </span>
                {data['Discount'] > 0 && (
                  <span className="text-green ml-1">({data['Discount']}%)</span>
                )}
              </p>
              {data.is_price_with_tax == 1 ? (
                <p className="font-size-10 mb-0 mt-0">
                  <FormattedMessage id="text.withTax" />{' '}
                </p>
              ) : (
                <p className="font-size-10 mb-0 mt-0">
                  <FormattedMessage id="text.withoutTax" />
                </p>
              )}
            </>
          ),
        }
      } else if (item == 'Purchase Price') {
        return {
          title: 'Purchase Price',
          dataIndex: 'Purchase Price',
          key: 'product_id',
          width: '150px',
          render: text => (
            <>
              <span className="ls-2">
                <span className="mr-1">{this.props?.user?.selectedCompany?.currency_symbol}</span>
                {parseFloat(text ? text : 0).toLocaleString('en-IN', dotOptions)}
              </span>
            </>
          ),
        }
      } else if (item == 'Tax') {
        return {
          title: 'Tax',
          dataIndex: 'Tax',
          key: 'product_id',
          width: '150px',
          render: text => <span className="ls-2">{text}</span>,
          responsive: ['md'],
        }
      } else {
        return {
          title: item,
          dataIndex: item,
          key: 'product_id',
          width: '150px',
          render: (text, data) => (
            <span>
              {' '}
              <p dangerouslySetInnerHTML={{ __html: data[item] }}></p>
            </span>
          ),
        }
      }
    })

    columns.splice(1, 0, Table.EXPAND_COLUMN)
    data['columns'] = columns
    this.setState({ data: data, showConfimationModal: true })
  }

  handleDownload = async () => {
    this.setState({ loading: true })
    var req =
      this.props.menu == 'inventory'
        ? { type: this.state.value == 0 ? 'stock_in' : 'stock_out' }
        : {}
    let data = []
    if (this.props.menu == 'product') {
      data = await downloadAPI(this.props.menu, 'download_template', req)
    } else if (this.props.menu == 'invoices') {
      data = await downloadAPI(this.props.menu, 'download_template', req)
    } else {
      data = await downloadAPI(this.props.menu, 'download', req)
    }
    this.setState({ loading: false })
  }
  onTabChange(key) {
    if (key == 5 || key == 4) {
      this.setState({ menu: 'inventory' })
      this.setState({ value: key == 5 ? 1 : 0 })
    } else if (key == 1) {
      this.setState({ menu: 'product' })
    } else if (key == 2) {
      this.setState({ menu: 'customer' })
    } else if (key == 3) {
      this.setState({ menu: 'vendor' })
    } else if (key == 6) {
      this.setState({ menu: 'invoices' })
    }
    this.setState({ key: key })
  }
  async callConfirmAPI(flag) {
    if (flag) this.setState({ loading: true })
    else this.setState({ loadingCancel: true, confirmFlag: false })
    const req = {
      is_confirm: flag,
      upload_type: this.state.menu,
      warehouse_id: this.state.menu == 'inventory' ? this.state.warehouse_id : undefined,
    }
    // let data = {}
    // if (this.state.menu == 'inventory') {
    //   data = await getAPI(this.state.menu, 'confirm', req)
    // } else {
    let data = {}
    if (this.state.menu != 'invoices') {
      data = await getAPI('upload', 'confirm', req)
    } else if (this.state.menu == 'invoices') {
      req['document_type'] = 'invoice'
      data = await getAPI('imports/swipe/docs', 'confirm', req)
    }
    // }
    this.setState({ loading: false, loadingCancel: false })
    if (data && data.success) {
      // this.callData(this.state.page, this.state.num_records)
      if (flag) {
        if (this.state.menu == 'product') {
          window.open('/products', '_self')
        } else if (this.state.menu == 'customer') {
          window.open('/customer', '_self')
        } else if (this.state.menu == 'vendor') {
          window.open('/vendor', '_self')
        } else if (this.state.menu == 'invoices') {
          window.open('/list/sales', '_self')
        } else {
          window.open('/inventory', '_self')
        }
      }
    }
    this.setState({
      loading: false,
      loadingCancel: false,
      confirmFlag: false,
      flag: false,
      showConfimationModal: false,
    })
  }
  getcolumns = () => {
    let columns = []

    if (this.state.menu == 'product') {
      columns = []
      columns = [...productColumns]
    }
    if (this.state.menu == 'customer') {
      columns = []
      columns = [...customerColumns]
    }
    if (this.state.menu == 'vendor') {
      columns = []
      columns = [...vendorColumns]
    }
    if (this.state.menu == 'inventory') {
      columns = []
      columns = [
        ...inventoryColumns.filter(
          item => !item.hidden || (item.hidden && this.props.permissions.batching == 1),
        ),
      ]
    }
    if (this.state.custom_columns.length > 0 && this.state.confirmFlag) {
      this.state.custom_columns.map(item => {
        columns.push({
          title: item.name,
          dataIndex: item.name,
          key: item.name,
          render: (text, data) => <span>{data[item.name]}</span>,
        })
      })
    }
    return columns
  }

  render() {
    let title = ''
    if (this.state.key == '4' || this.state.key == '5') {
      title = 'Confirm Uploading' + ' Stock'
    } else {
      title = 'Confirm Uploading ' + this.state.menu + 's'
    }
    let windowHeight = window.innerHeight

    const expandedRowRender = record => {
      // console.log(record)
      return <div className="p-3 pb-2 ">{record.message}</div>
    }

    return (
      <>
        <Modal
          title="Skip Data"
          width={800}
          closeIcon={<i className="fa-solid fa-lg fa-xmark"></i>}
          open={this.state.showSkip}
          footer={
            <div className="">
              <Button
                loading={this.props.loadingCancel}
                onClick={() => this.setState({ showSkip: false })}
                className="mr-2"
                type="danger"
              >
                <i className="fa fa-times mr-2" /> <FormattedMessage id="button.cancel" />
              </Button>
              <Button
                loading={this.props.loading}
                onClick={() => this.setState({ confirmFlag: true, showSkip: false })}
                type="success"
                className="font-weight-bold"
              >
                <i className="fa-solid fa-circle-check mr-2" /> Skip
              </Button>
            </div>
          }
        >
          <p>{this.state.confirmError}</p>
        </Modal>

        {this.state.confirmFlag ? (
          <EnhancedBulkUpload
            columns={this.getcolumns()}
            data={this.state.importData}
            title={title}
            callConfirmAPI={flag => this.callConfirmAPI(flag)}
            loading={this.state.loading}
            loadingCancel={this.state.loadingCancel}
            confirmFlag={this.state.confirmFlag}
            onClose={() => {
              this.setState({ confirmFlag: false })
            }}
          />
        ) : this.state.errorFalg ? (
          <>
            <Modal
              open={this.state.errorFalg}
              title={'Error Uploading File'}
              width={'40%'}
              footer={
                <>
                  <Button onClick={() => this.setState({ errorFalg: false, flag: true })}>
                    Close
                  </Button>
                </>
              }
            >
              <Card className="">
                {this.state.errors.map(error => (
                  <p className="text-danger">{error}</p>
                ))}
              </Card>
            </Modal>
          </>
        ) : (
          <Modal
            title="Upload Data"
            width={810}
            closeIcon={<i className="fa-solid fa-lg fa-xmark"></i>}
            open={this.state.flag}
            onCancel={this.handleCancel}
            footer={[]}
            destroyOnClose
          >
            <Tabs
              onChange={this.onTabChange}
              defaultActiveKey={this.state.key}
              key={this.state.key}
            >
              {/* {this.props.permissions.products_upload == 1 && (
                <TabPane tab="Products" key="1"></TabPane>
              )} */}
              {this.props.permissions.customers_upload == 1 && (
                <TabPane tab="Customers" key="2"></TabPane>
              )}
              {this.props.permissions.vendors_upload == 1 && (
                <TabPane tab="Vendors" key="3"></TabPane>
              )}
              {this.props.permissions.stock_in_upload == 1 && (
                <TabPane tab="Inventory Stock In" key="4"></TabPane>
              )}
              {this.props.permissions.stock_out_upload == 1 && (
                <TabPane tab="Inventory Stock Out" key="5"></TabPane>
              )}
              {this.props.integrations.docs_bulk_upload == 1 && (
                <TabPane tab="Bulk Invoices" key="6"></TabPane>
              )}
            </Tabs>

            <UploadData
              menu={this.state.menu}
              value={this.state.value}
              customRequest={this.customRequest}
              error={this.state.error}
              warehouses={this.props.warehouses}
            />
          </Modal>
        )}
        <ProductBarcode onRef={ref => (this.barcode = ref)} menu="barcode" />

        {/* New confirmation modal  */}
        <Modal
          open={this.state.showConfimationModal}
          maskClosable={false}
          title={'Approve values?'}
          description
          width={'75vw'}
          closeIcon={<i className="fa-solid fa-lg fa-xmark"></i>}
          onCancel={() =>
            this.setState({
              showConfimationModal: false,
            })
          }
          destroyOnClose
          footer={
            <div className="d-flex justify-content-between">
              <div className="d-flex align-items-center ml-2 font-size-12">
                <div className="mr-auto">
                  <span className=" text-gray-700 1 mr-2">
                    <div
                      className="bg-lgreen mr-2 d-inline-block border-gray-2"
                      style={{ width: '10px', height: '10px' }}
                    />{' '}
                    {'New (' +
                      this.state.data.records.filter(item => item.status == 'new').length +
                      ')'}
                  </span>
                  <span className=" text-gray-700 1 mr-2 ">
                    <div
                      className="bg-warning mr-2 d-inline-block border-gray-2"
                      style={{ width: '10px', height: '10px' }}
                    />{' '}
                    {'Updated (' +
                      this.state.data.records.filter(item => item.status == 'update').length +
                      ')'}
                  </span>
                  <span className=" text-gray-700 1 mr-2 ">
                    <div
                      className="bg-negative mr-2 d-inline-block border-gray-2"
                      style={{ width: '10px', height: '10px' }}
                    />{' '}
                    {'Deleted (' +
                      this.state.data.records.filter(item => item.status == 'delete').length +
                      ')'}
                  </span>
                  <span className=" text-gray-700 1 mr-2 ">
                    <div
                      className="bg-danger mr-2 d-inline-block border-gray-2"
                      style={{ width: '10px', height: '10px' }}
                    />{' '}
                    {'Error (' +
                      this.state.data.records.filter(item => item.status == 'error').length +
                      ')'}
                  </span>
                </div>
              </div>
              <div className="float-right">
                <Button
                  loading={this.state.loading}
                  onClick={e => this.callConfirmAPI(false)}
                  className="mr-2"
                  type="danger"
                >
                  <i className="fa fa-times mr-2" /> <FormattedMessage id="button.cancel" />
                </Button>
                {this.state.data.records.length != 0 && (
                  <Button
                    loading={this.state.loading}
                    type="success"
                    className="font-weight-bold"
                    onClick={e =>
                      this.state.data.have_error
                        ? this.setState({ confirmModal: true })
                        : this.callConfirmAPI(true)
                    }
                  >
                    <i className="fa-solid fa-circle-check mr-2" /> Confirm
                  </Button>
                )}
              </div>
            </div>
          }
        >
          <>
            {/* Notes
            <div className="mb-4">
              <h6>Notes</h6>
              <div className="text-gray-500">
                <ol>
                  <li>
                    {' '}
                    If you have any errors in the file, please correct them and upload again, or you
                    can skip them and upload.
                  </li>
                  <li>
                    {' '}
                    If you have any new records, please add them in '<b>Products</b>' section and
                    retry uploading.
                  </li>
                </ol>
              </div>
            </div> */}
            <Tabs
              defaultActiveKey="0"
              tabBarExtraContent={
                <Select
                  defaultValue={this.state.filter}
                  style={{ width: 150 }}
                  onChange={value => this.setState({ filter: value })}
                  bordered={false}
                >
                  <Select.Option value="all">All records</Select.Option>
                  <Select.Option value="error">Error records</Select.Option>
                  <Select.Option value="new">Success records</Select.Option>
                  {this.state.data.records.filter(item => item.status == 'delete').length > 0 && (
                    <Select.Option value="delete">Delete records</Select.Option>
                  )}
                  {this.state.data.records.filter(item => item.status == 'skip').length > 0 && (
                    <Select.Option value="skip">Existing records</Select.Option>
                  )}
                </Select>
              }
            >
              <TabPane tab="Records" key="0">
                {this.state.data.records.length == 0 ? (
                  //  show no data found illustration

                  <Empty
                    className="m-4"
                    image="https://gw.alipayobjects.com/zos/antfincdn/ZHrcdLPrvN/empty.svg"
                    imageStyle={{
                      height: 256,
                    }}
                    description={
                      <span className="font-size-16 text-gray-500 font-weight-bold">
                        You have no records.
                      </span>
                    }
                  />
                ) : (
                  <div id="style-9">
                    <Table
                      columns={this.state.data.columns}
                      dataSource={
                        this.state.filter == 'all'
                          ? this.state.data.records
                          : this.state.data.records.filter(item =>
                              this.state.filter == 'new'
                                ? item.status == 'new' || item.status == 'update'
                                : item.status == this.state.filter,
                            )
                      }
                      scroll={{ x: 'max-content', y: windowHeight - 400 }}
                      rowClassName={(record, index) =>
                        record.statue == 'error'
                          ? 'bg-danger'
                          : record.status == 'update'
                          ? 'bg-warning'
                          : record.status == 'new'
                          ? 'bg-lgreen'
                          : record.status == 'skip'
                          ? ''
                          : 'bg-negative'
                      }
                      pagination={{ size: 'small', showSizeChanger: true, showQuickJumper: true }}
                      size="small"
                      expandable={{
                        expandedRowRender: expandedRowRender,
                        expandedRowKeys: this.state.expandedKeys,
                        onExpand: (expanded, record) => {
                          //expand only one row at a time
                          if (expanded) {
                            this.setState({
                              expandedKeys: [record.key],
                            })
                          } else {
                            this.setState({
                              expandedKeys: [],
                            })
                          }
                        },
                      }}
                      expandIcon={({ expanded, onExpand, record }) =>
                        expanded ? (
                          <Button
                            size="small"
                            type="transparent"
                            className="text-danger"
                            onClick={e => {
                              onExpand(record, e)
                              e.stopPropagation()
                            }}
                          >
                            <span className="text-danger">
                              <i className="fa-solid fa-chevron-up"></i>
                            </span>
                          </Button>
                        ) : (
                          <Button
                            type="transparent"
                            size="small"
                            onClick={event => {
                              event.stopPropagation()
                            }}
                            className="mr-2"
                          >
                            <span className="d-none d-sm-inline font-weight-bold">
                              <a>
                                <Tooltip title={record.message} className="mr-2">
                                  <i
                                    className={'fa fa-info-circle ml-1'}
                                    style={{
                                      color: record.error
                                        ? '#e11900'
                                        : record.status == 'new'
                                        ? '#09864A'
                                        : '#757575',
                                    }}
                                  />
                                </Tooltip>
                              </a>
                            </span>
                          </Button>
                        )
                      }
                    />
                  </div>
                )}
              </TabPane>
            </Tabs>
            <Modal
              title={'Confirm Moditification? '}
              open={this.state.confirmModal}
              onCancel={() => this.setState({ confirmModal: false })}
              width={640}
              centered
              closeIcon={<i className="fa-solid fa-xmark"></i>}
              footer={
                <>
                  <Button key="back" onClick={() => this.setState({ confirmModal: false })}>
                    Close
                  </Button>
                  <Button
                    className="font-weight-bold"
                    type="danger-3"
                    onClick={() => {
                      this.callConfirmAPI(true)
                    }}
                    // loading={this.state.loading}
                  >
                    Confirm
                  </Button>
                </>
              }
            >
              <div className="">
                <p>
                  There are some errors in the file. Error records will be skipped and successful
                  records will be modified.
                </p>
                <p>Do you want to modify successful records?</p>
              </div>
            </Modal>
          </>
        </Modal>
      </>
    )
  }
}
const UploadData = props => {
  const [loading, setLoading] = useState(false)
  const [is_variant, setIsVariant] = useState(false)
  const [warehouse_id, setWarehouseId] = useState(undefined)
  // const [error,set]
  const handleDownload = async () => {
    // this.setState({ loading: true })
    if (
      props.menu == 'inventory' &&
      props.warehouses != undefined &&
      props.warehouses.length > 0 &&
      warehouse_id == undefined
    ) {
      message.error('Please select warehouse')
      return
    }
    setLoading(true)
    var req =
      props.menu == 'inventory'
        ? { type: props.value == 0 ? 'stock_in' : 'stock_out', warehouse_id: warehouse_id }
        : {}
    if (props.menu == 'product') {
      req = { is_variant: is_variant }
    }
    let data = []
    if (props.menu == 'product') {
      data = await downloadAPI(props.menu, 'download_template', req)
    } else if (props.menu == 'invoices') {
      req = { document_type: 'invoice' }
      data = await downloadAPI('imports/swipe/docs', 'download_template', req)
    } else {
      data = await downloadAPI(props.menu, 'download', req)
    }
    if (data) {
      // this.setState({ loading: false })
      setLoading(false)
    }
  }

  return (
    <>
      {(props.menu === 'inventory' ||
        props.menu == 'product' ||
        props.menu == 'customer' ||
        props.menu == 'vendor' ||
        props.menu == 'invoices') && (
        <div className="col-12 mb-4 text-center">
          {props.menu == 'product' && (
            <div className="mb-2">
              <Checkbox className="mr-2" onChange={e => setIsVariant(e.target.checked)} autoFocus>
                Products has Variants
                <Tooltip title="If you want to upload products with variants then check this box.">
                  <i className="fa-solid fa-info-circle ml-2" />
                </Tooltip>
              </Checkbox>
            </div>
          )}
          {props.menu == 'inventory' &&
            props.warehouses != undefined &&
            props.warehouses.length > 0 && (
              <div className="mb-2">
                <span className="custom-form-label text-black mr-2">Select Warehouse:</span>
                <Select
                  onChange={value => setWarehouseId(value)}
                  autoFocus
                  style={{ width: 200 }}
                  placeholder="Select Warehouse"
                >
                  {props.warehouses.map(item => (
                    <Select.Option value={item.warehouse_id}>{item.name}</Select.Option>
                  ))}
                </Select>
              </div>
            )}
          <Button
            type="action"
            onClick={handleDownload}
            loading={loading}
            // disabled={
            //   props.menu == 'inventory' &&
            //   props.warehouses != undefined &&
            //   props.warehouses.length > 0 &&
            //   warehouse_id == undefined
            // }
          >
            <i className="fa fa-arrow-down mr-1 fa-fw" /> Download{' '}
            {props.menu === 'inventory'
              ? props.value == 0
                ? 'Stock In'
                : 'Stock Out'
              : props.menu}{' '}
            Template {props.menu == 'invoices' ? <BetaBadge /> : ''}
          </Button>
        </div>
      )}
      <div className="col-12" style={{ height: '300px' }}>
        <Dragger
          customRequest={data => props.customRequest(data, warehouse_id)}
          accept=".xlsx"
          multiple={false}
          maxCount={1}
          className="mb-3"
          loading={loading}
          disabled={
            props.menu == 'inventory' &&
            props.warehouses != undefined &&
            props.warehouses.length > 0 &&
            warehouse_id == undefined
          }
        >
          {props.menu == 'invoices' && (
            <p className="text-primary">
              This feature is currently in beta and only allows for the uploading of new invoices.
              If you encounter any issues, please reach out to us.
            </p>
          )}
          <p className="ant-upload-drag-icon">
            <InboxOutlined />
          </p>
          <p className="ant-upload-text">Click or drag file to this area to upload</p>
          <p className="ant-upload-hint mt-2">
            Note: Please upload only .xlsx file (Other formats are not supported)
          </p>
        </Dragger>

        {props.error != '' && <span className="btn-danger mt-2">{props.error}</span>}
      </div>
      {props.menu != 'invoices' && (
        <div className="mt-5 col-12">
          Watch Tutorial Here
          <a
            href="https://www.youtube.com/watch?v=1BYNUhuMvhA"
            className="text-action ml-2"
            target="_BLANK"
          >
            Watch Now
          </a>
        </div>
      )}
    </>
  )
}

class EnhancedImport extends React.Component {
  constructor(props) {
    super(props)
  }
  render() {
    return <Import {...this.props} />
  }
}

function mapStateToProps(state, ownProps) {
  return {
    permissions: state.permissions.permission,
    integrations: state.permissions.integrations,
    warehouses: state.warehouse.warehouses,
    user: state.user,
  }
}

export default connect(mapStateToProps)(EnhancedImport)
