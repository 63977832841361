import { LoadingOutlined } from '@ant-design/icons'
import { Button, Drawer, Image, Radio, Select, Tabs, message } from 'antd'
import { EnhancedSubscriptionModal } from 'components/modal/subscription'
import Visibility from 'components/other/visibility'
import React, { Component } from 'react'
import { isMobile } from 'react-device-detect'
import { connect } from 'react-redux'
import { get_invoice_settings } from 'redux/document/actions'
import { getAPI } from 'services/jwt'

const { TabPane } = Tabs

const { Option } = Select

class InvoiceThemeForm extends Component {
  formRef = React.createRef()
  formRef2 = React.createRef()
  constructor(props) {
    super(props)
    this.state = {
      settingData: '',
      settingDataCustomColumns: '',
      displayColorPicker: false,
      color: '#fff',
      paid: false,
      num_invoices: false,
      invoice_templates: [],
      selected_invoice_template: 1,
      selected_purchase_template: 1,
      selected_estimate_template: 1,
      key: 0,
    }
  }

  async componentDidMount() {
    this.props.onRef(this)
  }

  componentWillUnmount() {
    this.props.onRef(null)
  }

  onOpen = () => {
    const { invoice_settings } = this.props
    this.setState(
      {
        settingData: { ...invoice_settings },
        color: invoice_settings.color,
        num_invoices: invoice_settings.num_invoices == 0 ? true : false,
        paid: invoice_settings.paid == 1 ? true : false,
        invoice_templates: invoice_settings.invoice_templates,
        selected_invoice_template: invoice_settings.invoice_template,
        selected_purchase_template: invoice_settings.purchase_template,
        selected_estimate_template: invoice_settings.estimate_template,
      },
      () => {
        this.setState({ settingsVisible: true })
      },
    )
  }

  handleChange = color => {
    this.setState({ color: color }, () => {
      this.formRef.current.setFieldsValue({
        color: color,
      })
    })
  }

  updateTemplate = async () => {
    var req = {
      invoice_template: this.state.selected_invoice_template,
      purchase_template: this.state.selected_purchase_template,
      estimate_template: this.state.selected_estimate_template,
    }
    var data = await getAPI('utils', 'update_template', req)
    if (data.success) {
      message.success(data.message)
      this.props.dispatch(get_invoice_settings())
    }
  }

  invoiceTemplateChange = e => {
    this.setState({ selected_invoice_template: e.target.value }, () => this.updateTemplate())
  }

  purchaseTemplateChange = e => {
    this.setState({ selected_purchase_template: e.target.value }, () => this.updateTemplate())
  }

  estimateTemplateChange = e => {
    this.setState({ selected_estimate_template: e.target.value }, () => this.updateTemplate())
  }

  render() {
    const formItemLayout = {
      labelCol: {
        xs: { span: 1 },
        sm: { span: 10 },
      },
      wrapperCol: {
        xs: { span: 2 },
        sm: { span: 20 },
      },
    }

    const popover = {
      position: 'absolute',
      zIndex: '2',
    }
    const cover = {
      position: 'fixed',
      top: '0px',
      right: '0px',
      bottom: '0px',
      left: '0px',
    }

    const antIcon = <LoadingOutlined style={{ fontSize: 24 }} spin />

    return (
      <div>
        {this.state.settingData == '' ? (
          <></>
        ) : (
          <Drawer
            title={
              <>
                <span className="mr-2">Awesome templates.</span>
                <span className="text-gray-500">Awesome impressions. 🚀🚀🚀</span>
              </>
            }
            placement="top"
            height={isMobile ? '100%' : '95%'}
            closable={true}
            closeIcon={<i className="fa-solid fa-lg fa-xmark"></i>}
            onClose={() => this.setState({ settingsVisible: false })}
            visible={this.state.settingsVisible}
            extra={
              <>
                <Button
                  onClick={() => this.setState({ settingsVisible: false })}
                  className="mr-2 float-right font-weight-bold"
                  type="transparent"
                >
                  Close
                </Button>
              </>
            }
          >
            {/*<h4 className="font-weight-bolder font-size-24 mb-4">
              <span className="mr-2">Awesome templates.</span>
              <span className="text-gray-10">
                Unparalleled choices for making a quality impression. 🚀🚀🚀
              </span>
            </h4>*/}

            <div className="">
              <Visibility type="templates" className="mb-4" visible="true" />
            </div>

            <Tabs defaultActiveKey="1" destroyInactiveTabPane={true}>
              <TabPane tab="Invoices" key="1">
                <Radio.Group
                  onChange={this.invoiceTemplateChange}
                  value={this.state.selected_invoice_template}
                  className="invoice-themes"
                >
                  {this.state.invoice_templates.map((item, index) => (
                    <Radio.Button
                      key={index}
                      value={item.id}
                      className={item.id == this.state.selected_invoice_template ? 'mt-2' : 'mt-2'}
                      style={{
                        // marginRight: '0.5rem',
                        margin: '1rem',
                        height: 'auto',
                        paddingBottom: '2rem',
                        borderRadius: '10px',
                        border: 'none',
                        fontSize: '0.9rem',
                      }}
                    >
                      <span className="font-size-16 template-name mb-2">{item.name}</span>
                      <div className="invoice-templates">
                        {item.id == this.state.selected_invoice_template ? (
                          <Image
                            style={{ border: '5px solid' }}
                            className="border-primary"
                            id={item.id}
                            src={item.image}
                            height={243}
                            width={200}
                          />
                        ) : (
                          <Image
                            className="mt-2"
                            id={item.id}
                            src={item.image}
                            height={243}
                            width={200}
                          />
                        )}
                      </div>
                    </Radio.Button>
                  ))}
                </Radio.Group>
              </TabPane>

              <TabPane tab="Purchases" key="2">
                <Radio.Group
                  onChange={this.purchaseTemplateChange}
                  value={this.state.selected_purchase_template}
                  className="invoice-themes"
                >
                  {this.state.invoice_templates.map((item, index) => (
                    <Radio.Button
                      key={index}
                      value={item.id}
                      className={
                        item.id == this.state.selected_purchase_template
                          ? 'mt-2 ant-btn-action-2'
                          : 'mt-2'
                      }
                      style={{
                        // marginRight: '0.5rem',
                        margin: '1rem',
                        height: 'auto',
                        paddingTop: '0.1rem',
                        paddingBottom: '2rem',
                        borderRadius: '10px',
                        border: 'none',
                        fontSize: '0.9rem',
                      }}
                    >
                      <span className="font-size-16  template-name">{item.name}</span>
                      <br />
                      {item.id == this.state.selected_purchase_template ? (
                        <Image
                          style={{ border: '5px solid pink' }}
                          className="mt-2"
                          id={item.id}
                          src={item.image}
                          height={243}
                          width={200}
                        />
                      ) : (
                        <Image
                          className="mt-2"
                          id={item.id}
                          src={item.image}
                          height={243}
                          width={200}
                        />
                      )}
                    </Radio.Button>
                  ))}
                </Radio.Group>
              </TabPane>

              <TabPane tab="Quotations" key="3">
                <Radio.Group
                  onChange={this.estimateTemplateChange}
                  value={this.state.selected_estimate_template}
                  className="invoice-themes"
                >
                  {this.state.invoice_templates.map((item, index) => (
                    <Radio.Button
                      key={index}
                      value={item.id}
                      className={
                        item.id == this.state.selected_estimate_template
                          ? 'mt-2 ant-btn-action-2'
                          : 'mt-2'
                      }
                      style={{
                        // marginRight: '0.5rem',
                        margin: '1rem',
                        height: 'auto',
                        paddingTop: '0.1rem',
                        paddingBottom: '2rem',
                        borderRadius: '10px',
                        border: 'none',
                        fontSize: '0.9rem',
                      }}
                    >
                      <span className="font-size-16  template-name">{item.name}</span>
                      <br />
                      {item.id == this.state.selected_estimate_template ? (
                        <Image
                          style={{ border: '5px solid pink' }}
                          className="mt-2"
                          id={item.id}
                          src={item.image}
                          height={243}
                          width={200}
                        />
                      ) : (
                        <Image
                          className="mt-2"
                          id={item.id}
                          src={item.image}
                          height={243}
                          width={200}
                        />
                      )}
                    </Radio.Button>
                  ))}
                </Radio.Group>
              </TabPane>
            </Tabs>

            <EnhancedSubscriptionModal onRef={ref => (this.subscription = ref)} />
          </Drawer>
        )}
      </div>
    )
  }
}
const mapStateToProps = (state, ownProps) => {
  return {
    invoice_settings: state.document.invoiceSettings,
  }
}
export default connect(mapStateToProps)(InvoiceThemeForm)
