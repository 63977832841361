export const amountFormatter = (amount, maximumFractionDigits = 2) => {
  const price_options = {
    minimumFractionDigits: 2,
    maximumFractionDigits: maximumFractionDigits,
  }

  return amount.toLocaleString('en-IN', price_options).replace(/(\.0+|0+)$/, '')
}
export const dispatch_to_doc_types = [
  'purchases',
  'sales_returns',
  'purchase_orders',
  'purchase',
  'sales_return',
  'purchase_order',
]
