import { Image, Text, View } from '@react-pdf/renderer'
import { breakName } from 'components/other/utility'
import { Fragment } from 'react'
import { HIDE_PRICES } from '../../../utils/constants'
import RichText from '../RichText'
import ItemBatchDetails from '../itemBatchDetails'
import { styles as getStyles } from './styles'

const addItemEvery = (str, item, every) => {
  for (let i = 0; i < str.length; i++) {
    if (!(i % (every + 1))) {
      str = str.substring(0, i) + item + str.substring(i)
    }
  }
  return str.substring(1)
}

const isNumeric = num => {
  var a = num.includes(' ')
  return !a
}

const InvoiceTableRow = ({ items, data, invoice, type }) => {
  var styles = getStyles(invoice)
  let locale = invoice.company.locale

  let font_style = invoice.invoice_settings.font_style
  if (locale == 'English') {
    locale = font_style
  }
  const hide_hsn = invoice.invoice_settings.hide_hsn
  var FONT_FACTOR = invoice.invoice_settings.font_size
  const options = { maximumFractionDigits: 2, minimumFractionDigits: 2 }
  const price_options = {
    maximumFractionDigits: invoice.invoice_settings.price_max_decimals,
    minimumFractionDigits: 2,
  }
  let no_of_default_cols = invoice.invoice_settings.hide_qty ? 7.1 : 8
  no_of_default_cols = hide_hsn ? no_of_default_cols - 0.8 : no_of_default_cols
  var single_col = (1 * 100) / (no_of_default_cols + invoice.custom_col_names.length)

  var dc_col = (1 * 100) / (invoice.invoice_settings.hide_qty ? 2 : 3)

  var font_size = (FONT_FACTOR * (8.1 * 9)) / (9 + invoice.custom_col_names.length)
  let conversion_factor = 1
  let currency_symbol = invoice.company.currency_symbol
  const is_export = invoice.is_export == 1
  if (
    (invoice.is_export == 1 || invoice.is_multi_currency) &&
    invoice.invoice_settings.show_both_currencies != true
  ) {
    currency_symbol = invoice.export_details[0].currency_symbol
    conversion_factor = invoice.export_details[0].conversion_factor
  }

  const rows = items.map((item, i) => (
    <Fragment key={i}>
      {(type == 'Delivery Challan' || type == HIDE_PRICES) && (
        <>
          {item.product_type == 'Product' && (
            <View style={styles.invoiceTableRow_row} key={(i + 1).toString()} wrap={false}>
              <Text
                hyphenationCallback={e => breakName(e)}
                style={{ ...styles.invoiceTableRow_slno_dc, width: `${0.2 * dc_col}%` }}
              >
                {i + 1}
              </Text>
              <View style={{ ...styles.invoiceTableRow_description_dc, width: `${1.8 * dc_col}%` }}>
                <Text style={styles.invoiceTableRow_bold}>
                  {item.name} {item.variant_name}
                </Text>
                <ItemBatchDetails item={item} invoice={invoice} />
                {!hide_hsn && item.hsn_code != '' && (
                  <>
                    <RichText
                      locale={locale}
                      note={
                        item.product_type == 'Product'
                          ? 'HSN: ' + item.hsn_code
                          : 'SAC: ' + item.hsn_code
                      }
                      FONT_FACTOR={0.9 * font_size}
                    />
                  </>
                )}
                {item.description != '' &&
                  item.description &&
                  data.show_description &&
                  item.description != '<p><br></p>' && (
                    <RichText
                      locale={locale}
                      note={item.description}
                      FONT_FACTOR={item.description.length > 800 ? font_size - 1 : font_size}
                    />
                  )}
              </View>
              {invoice.invoice_settings.hide_qty == 0 && (
                <Text style={{ ...styles.invoiceTableRow_qty_dc, width: `${dc_col}%` }}>
                  <Text>
                    {(item.qty * item.conversion_rate).toLocaleString('en-IN', {
                      maximumFractionDigits: 3,
                    })}
                  </Text>
                  {item.unit != '' &&
                    item.unit != 'OTH' &&
                    item.unit != 'NONE' &&
                    item.unit != 'UNDEFINED' && (
                      <Text> {item.item_unit == '' ? item.unit : item.item_unit}</Text>
                    )}
                  {invoice.invoice_settings.show_qty_conversion_rate == 1 &&
                    item.conversion_rate != 1 && (
                      <Text>
                        {'\n'}
                        <RichText
                          locale={locale}
                          note={'(' + parseFloat(item.qty).toFixed(3) + ' ' + item.unit + ')'}
                          FONT_FACTOR={font_size}
                        />
                      </Text>
                    )}
                </Text>
              )}
            </View>
          )}
          {item.product_type == 'Product' &&
            invoice.invoice_settings.show_images == 1 &&
            item.product_images[0].is_empty == 0 && (
              <View
                style={{
                  // flexDirection: 'row',
                  paddingLeft: '5%',
                }}
              >
                {item.product_images.map((image, index) => (
                  <Image
                    style={styles.invoiceTableRow_image}
                    src={{
                      uri: image.url,
                      method: 'GET',
                      headers: { 'Cache-Control': 'no-cache' },
                    }}
                    crossorigin="anonymous"
                  />
                ))}
              </View>
            )}
        </>
      )}

      {type != 'Delivery Challan' && type != HIDE_PRICES && (
        <Fragment key={i}>
          <View style={styles.invoiceTableRow_row} key={(i + 1).toString()}>
            <Text
              hyphenationCallback={e => breakName(e)}
              style={{
                ...styles.invoiceTableRow_cell,
                textAlign: 'left',
                fontSize: font_size,
                width: `${0.3 * single_col}%`,
              }}
            >
              {i + 1}
            </Text>

            <View
              style={{
                ...styles.invoiceTableRow_cell,
                fontSize: font_size,
                width: `${3.4 * single_col}%`,
              }}
            >
              <Text style={styles.invoiceTableRow_bold}>
                {item.name} {item.variant_name}
              </Text>
              <ItemBatchDetails item={item} invoice={invoice} />
              {item.description != '' &&
                item.description &&
                data.show_description &&
                item.description != '<p><br></p>' && (
                  <RichText locale={locale} note={item.description} FONT_FACTOR={font_size} />
                )}

              {invoice.invoice_settings.show_images == 1 && item.product_images[0].is_empty == 0 && (
                <View
                  style={{
                    flexDirection: 'row',
                  }}
                >
                  {item.product_images.map((image, index) => (
                    <Image
                      style={styles.invoiceTableRow_image}
                      src={{
                        uri: image.url,
                        method: 'GET',
                        headers: { 'Cache-Control': 'no-cache' },
                      }}
                      crossorigin="anonymous"
                    />
                  ))}
                </View>
              )}
            </View>

            {item.custom_col_values.map((each_item, index) => (
              <Text
                key={index}
                style={{
                  ...styles.invoiceTableRow_cell,
                  wordWrap: 'break-all',
                  textAlign: 'right',
                  fontSize: font_size,
                  width: `${single_col}%`,
                }}
              >
                {each_item == '' ? '-' : each_item}
              </Text>
            ))}

            {!hide_hsn && (
              <Text
                style={{
                  ...styles.invoiceTableRow_cell,
                  textAlign: 'right',
                  fontSize: FONT_FACTOR > 1 ? 0.9 * font_size : font_size,
                  width: `${0.8 * single_col}%`,
                }}
                hyphenationCallback={e => breakName(e)}
              >
                {item.hsn_code != '' ? (
                  <Text hyphenationCallback={e => breakName(e)}>{item.hsn_code}</Text>
                ) : (
                  '-'
                )}
              </Text>
            )}

            <Text
              style={{
                ...styles.invoiceTableRow_cell,
                ...styles.invoiceTableRow_italic,
                fontSize: font_size,
                textAlign: 'right',
                width: `${0.5 * single_col}%`,
              }}
            >
              {item.tax != 0 && invoice.with_tax == 1 ? (
                <Text>
                  {item.tax + '%'}
                  {item.cess != 0 && '\n+' + item.cess + '%'}
                </Text>
              ) : item.cess != 0 ? (
                '\n+' + item.cess + '%'
              ) : (
                '-'
              )}
              {item.cess_on_qty_value != 0 && '\n+' + item.cess_on_qty + '/' + item.item_unit}
            </Text>
            {invoice.invoice_settings.hide_qty == 0 && (
              <Text
                style={{
                  ...styles.invoiceTableRow_cell,
                  fontSize: font_size,
                  textAlign: 'right',
                  width: `${0.9 * single_col}%`,
                }}
              >
                <Text>
                  {(item.qty * item.conversion_rate).toLocaleString('en-IN', {
                    maximumFractionDigits: 3,
                  })}
                </Text>
                {item.unit != '' &&
                item.unit != 'OTH' &&
                item.unit != 'NONE' &&
                item.unit != 'UNDEFINED' ? (
                  <Text> {item.item_unit == '' ? item.unit : item.item_unit}</Text>
                ) : (
                  ' Nos'
                )}
                {invoice.invoice_settings.show_qty_conversion_rate == 1 &&
                  item.conversion_rate != 1 && (
                    <Text>
                      {'\n'}
                      {/* <RichText locale={locale}
                        note={'(' + parseFloat(item.qty).toFixed(3) + ' ' + item.unit + ')'}
                        FONT_FACTOR={font_size}
                        locale={locale}
                      /> */}
                    </Text>
                  )}
              </Text>
            )}
            <View
              style={{
                ...styles.invoiceTableRow_cell,
                textAlign: 'right',
                fontSize: font_size,
                width: `${1 * single_col}%`,
              }}
            >
              <Text style={styles.invoiceTableRow_med}>
                {((item.unit_price * (1 - item.discount / 100)) / conversion_factor).toLocaleString(
                  'en-IN',
                  price_options,
                )}
              </Text>
            </View>

            <Text
              style={{
                ...styles.invoiceTableRow_cell,
                textAlign: 'right',
                fontSize: font_size,
                borderRight: 'none',
                paddingRight: 4.5,
                width: `${1.1 * single_col}%`,
              }}
            >
              {(item.net_amount / conversion_factor).toLocaleString('en-IN', options)}
            </Text>
          </View>
        </Fragment>
      )}
    </Fragment>
  ))
  return <View style={[styles.invoiceTableRow_border, styles.globalContainer]}>{rows}</View>
}

export default InvoiceTableRow
