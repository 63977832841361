import { Document, Link, Page, StyleSheet, Text } from '@react-pdf/renderer'
import React from 'react'
import CompanyDetails from './company'
import DocumentTitle from './documentTitle'
import LedgerItems from './ledgerItems'
import Party from './party'

import { registerEnglishFont } from 'components/fonts'
const styles = StyleSheet.create({
  page: {
    fontSize: 10,
    paddingTop: 25,
    paddingHorizontal: 30,
    paddingBottom: 40,
    lineHeight: 1.3,
    flexDirection: 'column',
  },
  logo: {
    width: 'auto',
    height: '45',
    position: 'relative',
    marginRight: 'auto',
    textAlign: 'left',
    marginBottom: 5,
  },
  footer: {
    alignContent: 'bottom',
    position: 'absolute',
    fontFamily: 'helmed_English',
    bottom: 20,
    left: 30,
  },
  footerNote: {
    fontSize: 8.1,
    bottom: 10,
    left: 30,
    alignContent: 'bottom',
    position: 'absolute',
    fontFamily: 'helmed_English',
    marginTop: 0,
  },
})

export default function Ledger({ data }) {
  registerEnglishFont('English')

  return (
    <Document>
      <Page size="A4" style={styles.page}>
        <DocumentTitle title={'Ledger'} color={data.company_details.color} />
        <CompanyDetails invoice={data} />
        <Party invoice={data} />
        <LedgerItems invoice={data} />
        <Link
          href={data.company_details.website}
          target="_blank"
          fixed
          style={{ ...styles.footer, color: data.company_details.color }}
        >
          {data.company_details.invoice_footer}
        </Link>
        <Text fixed style={styles.footerNote}>
          This is a computer generated document and requires no signature.
        </Text>
      </Page>
    </Document>
  )
}
