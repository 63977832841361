import React, { Component } from 'react'

import { AgGridReact } from 'ag-grid-react' // AG Grid Component
import 'ag-grid-community/styles/ag-grid.css' // Mandatory CSS required by the grid
// import 'ag-grid-community/styles/ag-theme-quartz.css' // Optional Theme applied to the grid
import './styles/ag-grid-theme-builder.css'
// import './styles/ag-grid.scss'
import { ClientSideRowModelModule } from '@ag-grid-community/client-side-row-model'
import { ClipboardModule } from '@ag-grid-enterprise/clipboard'
import { MenuModule } from '@ag-grid-enterprise/menu'
import { RangeSelectionModule } from '@ag-grid-enterprise/range-selection'
import { RichSelectModule } from '@ag-grid-enterprise/rich-select'
import { ColumnsToolPanelModule } from '@ag-grid-enterprise/column-tool-panel'
import { FiltersToolPanelModule } from '@ag-grid-enterprise/filter-tool-panel'
import { ServerSideRowModelModule } from '@ag-grid-enterprise/server-side-row-model'
import { AdvancedFilterModule } from '@ag-grid-enterprise/advanced-filter'
import { SetFilterModule } from '@ag-grid-enterprise/set-filter'
import { ExcelExportModule } from '@ag-grid-enterprise/excel-export'
import richTextEditor from './richTextEditor'
import phoneEditor from './phoneEditor'
import { connect } from 'react-redux'

const dotOptions = { minimumFractionDigits: 2, maximumFractionDigits: 2 }

// check if mobile device
const isMobile = window.innerWidth <= 500

const cellEditors = {
  richTextEditor: richTextEditor,
  phoneEditor: phoneEditor,
  taxEditor: 'taxEditor',
  agTextCellEditor: 'agTextCellEditor',
  agLargeTextCellEditor: 'agLargeTextCellEditor',
  agSelectCellEditor: 'agSelectCellEditor',
  agRichSelectCellEditor: 'agRichSelectCellEditor',
  agNumberCellEditor: 'agNumberCellEditor',
  agDateStringCellEditor: 'agDateStringCellEditor',
  agDateCellEditor: 'agDateCellEditor',
  agCheckboxCellEditor: 'agCheckboxCellEditor',
}

class AgGridTable extends Component {
  gridRef = React.createRef()
  constructor(props) {
    super(props)

    this.state = {
      currentRowData: [],
      currentNode: {},
    }
  }

  componentDidMount() {
    this.props.onRef && this.props.onRef(this)
  }

  api = () => {
    return this.gridRef.current.api
  }

  getRowData() {
    let data = []
    this.gridRef.current.api.forEachNode((rowNode, index) => {
      data.push(rowNode.data)
    })
    return data
  }

  highlightErrorRows = (rowIds, columnField, errorMsg) => {
    rowIds.map(rowId => {
      const rowNode = this.gridRef.current.api.getRowNode(rowId)
      if (rowNode) {
        rowNode.setData({ ...rowNode.data, error: true, tooltipText: errorMsg })
      }
    })
  }

  updateRowValue = data => {
    const rowNode = this.state.currentNode.node
    if (rowNode) {
      rowNode.updateData(data)
    }
  }

  getCellEditor = field => {
    if (field != '') {
      let editor = this.cellEditors && this.cellEditors[field] ? this.cellEditors[field] : null
      if (editor) {
        return editor
      }

      Object.keys(cellEditors).map(key => {
        if (key.toLowerCase().includes(field.toLowerCase())) {
          editor = cellEditors[key]
        }
      })
      return editor
    }

    return null
  }
  getDataAsExcel = (resetFilters = false) => {
    if (resetFilters) {
      this.resetFilter()
    }

    let blob = this.gridRef.current.api.getDataAsExcel()

    return new File([blob], 'data.xlsx', {
      type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
    })
  }

  setFilter = async filters => {
    await this.gridRef.current.api.setFilterModel(filters)
    this.gridRef.current.api.onFilterChanged()
  }

  resetFilter = async () => {
    await this.gridRef.current.api.setFilterModel(null)
    await this.gridRef.current.api.setAdvancedFilterModel(null)
    // this.gridRef.current.api.onFilterChanged()
  }
  getSelectedRows = () => {
    return this.gridRef.current.api.getSelectedRows()
  }

  setAdvancedFilters = async filters => {
    this.gridRef.current.api.setAdvancedFilterModel(filters)
    // this.gridRef.current.api.onFilterChanged()
  }

  render() {
    const agTheme = this.props.theme != 'swipedark' ? 'ag-theme-custom' : 'ag-theme-custom-dark'

    const gridColumns = this.props.columns.map((column, index) => {
      column = {
        ...column,
        suppressMovable: false,
        field: column.field || column.dataIndex,
        headerName: column.title,
        cellEditor: this.getCellEditor(column.cellEditor ? column.cellEditor : ''),
        cellStyle: params => {
          if (
            params.data.error &&
            params.data.error_columns &&
            params.data.error_columns.includes(column.field || column.dataIndex)
          ) {
            return {
              border: '1px solid red',
              backgroundColor: 'rgba(255,0,0,0.1)',
              borderRadius: '5px',
            }
          }
          return null
        },
      }
      if (this.props.tooltipText == column.field || this.props.tooltipText == column.dataIndex) {
        column['editable'] = false
        column['cellClass'] = params => {
          if (params.data.error) {
            return 'text-danger text-bold'
          }
        }
      }

      if (column.cellEditor == 'taxEditor') {
        column['cellEditor'] = 'agRichSelectCellEditor'
        column['cellEditorParams'] = {
          values: this.props.tax_rates.map(item => parseFloat(item)),
          valueListGap: 10,
          valueListMaxHeight: 200,
          allowTyping: true,
          filterList: true,
          highlightMatch: true,
        }
      }

      return column
    })

    // Grid Properties
    const rowDragEntireRow =
      this.props.rowDragEntireRow == undefined ? false : this.props.rowDragEntireRow
    const rowDragManaged =
      this.props.rowDragManaged == undefined ? rowDragEntireRow : this.props.rowDragManaged

    return (
      <div
        style={{ width: '100%', height: '100%' }}
        className={this.props.cellSelection === false ? 'ag-no-select' : ''}
      >
        <div id="advancedFilterParent" className="my-2 d-none"></div>
        <div
          style={
            this.props.dimensions == undefined
              ? {
                  width: '100%',
                  height: '70vh',
                }
              : this.props.dimensions
          }
          className={agTheme}
        >
          <AgGridReact
            ref={this.gridRef}
            key={this.props.gridKey || 'ag-grid-table'}
            rowData={this.props.dataSource}
            rowStyle={{
              alignItems: 'center',
            }}
            domLayout={this.props.domLayout}
            animateRows={this.props.animateRows || true}
            getRowHeight={params => {
              this.props.rowHeight === undefined
                ? params.node.group
                  ? 50
                  : 35
                : this.props.rowHeight
            }}
            // getRowId sets the unique id for each row, cane give specific id from the data or by default it will be index if prop is not passed
            getRowId={params => params.data[this.props.rowId || 'id']}
            rowClassRules={
              this.props.classRules
                ? this.props.classRules
                : {
                    'bg-danger': 'data.error === true',
                    'bg-lgreen': "data.status === 'new'",
                    'bg-warning': "data.status === 'update'",
                  }
            }
            // domLayout={"autoHeight"}
            columnDefs={gridColumns}
            reactiveCustomComponents={true}
            suppressDragLeaveHidesColumns={true}
            defaultColDef={{
              flex: 1,
              minWidth: this.props.minRowWidth || 200,
              autoHeight: true,
              wrapText: false,
              editable: this.props.editable === undefined ? true : this.props.editable,
              filter: this.props.filter === undefined ? false : this.props.filter,
              enableCellChangeFlash: this.props.enableCellChangeFlash || true,
              lockVisible: this.props.lockVisible === undefined ? true : this.props.lockVisible,
              tooltipField: this.props.tooltipText || 'tooltipText',

              singleClickEdit: this.props.singleClickEdit || false,
              onCellValueChanged: event => {
                if (event.newValue === null || event.newValue === undefined) {
                  event.newValue = ''
                  if (!this.props.readOnlyEdit) {
                    event.node.setDataValue(event.colDef.field, event.newValue)
                  }
                }

                if (this.props.readOnlyEdit) {
                  this.setState({ currentNode: event }, () => {
                    this.props.onUpdateValue(event.data, event.colDef.field, event.newValue)
                  })
                }
              },
            }}
            // Edit type can be set to fullRow , fullRow will allow editing of full row [all the cells in the row are editable]
            editType={this.props.editType || ''}
            pagination={this.props.pagination === undefined ? true : this.props.pagination}
            paginationPageSize={this.props.num_records || 20}
            paginationPageSizeSelector={[10, 20, 30, 40, 50, 100]}
            onGridReady={event => {
              this.props.onReady(event)
            }}
            getRowStyle={this.props.getRowStyle}
            gridOptions={this.props.gridOptions || {}}
            pinnedBottomRowData={this.props.pinnedBottomRowData}
            onRowDataUpdated={event => {
              if (this.props.enableAdvancedFilter) {
                event.api.setGridOption(
                  'advancedFilterParent',
                  document.getElementById('advancedFilterParent'),
                )
              }
              this.props.onReady(event)
            }}
            // Read only edit will set the table property to read only, all the edit's will not be affected
            readOnlyEdit={this.props.readOnlyEdit === undefined ? false : this.props.readOnlyEdit}
            // On cell edit request will be called when the date is changed and saved
            // in tbale, for this to work, we need to set readonlyedit to true and onCellEditRequest to a function
            // which will be called when the cell is edited [Need to update from out end , table wll not updated any data
            // as we set read only mode]
            onCellEditRequest={event => {
              this.setState({ currentNode: event }, () => {
                this.props.onUpdateValue(event.data, event.colDef.field, event.newValue)
              })
            }}
            // Selection
            // checkboxSelection={
            //   this.props.checkboxSelection === undefined ? false : this.props.checkboxSelection
            // }
            isRowSelectable={this.props.isRowSelectable || null}
            onRowSelected={
              !this.props.rowDragEntireRow && this.props.onRowSelected
                ? this.props.onRowSelected
                : null
            }
            onSelectionChanged={
              !this.props.rowDragEntireRow && this.props.onSelectionChanged
                ? this.props.onSelectionChanged
                : null
            }
            rowMultiSelectWithClick={this.props.rowMultiSelectWithClick || false}
            enableRangeSelection={!this.props.rowDragEntireRow}
            // Range selection cannot be used with rowDragEntireRow
            // Ranges
            enableRangeHandle={!this.props.rowDragEntireRow}
            enableFillHandle={true}
            // rowSelection='multiple' lets you select multiple rows, if set to single, only one row can be selected
            rowSelection={
              this.props.rowSelectionType == undefined ? 'multiple' : this.props.rowSelectionType
            }
            // For undo redo cell editing, readOnlEdit should be set to false
            undoRedoCellEditing={true}
            undoRedoCellEditingLimit={5}
            tooltipMouseTrack={true}
            enableBrowserTooltips={false}
            // tooltipShowDelay will work on if browser tooltips are disabled
            tooltipShowDelay={500}
            // onCellKeyDown={console.log}
            // Modules are the additional features that can be added to the table
            enableAdvancedFilter={
              this.props.enableAdvancedFilter === undefined
                ? false
                : this.props.enableAdvancedFilter
            }
            includeHiddenColumnsInAdvancedFilter={true}
            // Row Dragging
            rowDragEntireRow={rowDragEntireRow}
            rowDragManaged={rowDragManaged}
            rowDragMultiRow={
              this.props.rowDragMultiRow == undefined ? true : this.props.rowDragMultiRow
            }
            onRowDragEnd={event => {
              if (!rowDragManaged) {
                this.props.onCommitDrag(event)
              }
            }}
            // rowModelType={'serverSide'}
            modules={[
              ClientSideRowModelModule,
              // ServerSideRowModelModule,
              ClipboardModule,
              MenuModule,
              RangeSelectionModule,
              RichSelectModule,
              // ColumnsToolPanelModule,
              // FiltersToolPanelModule,
              SetFilterModule,
              ExcelExportModule,
              AdvancedFilterModule,
            ]}
          />
        </div>
      </div>
    )
  }
}
function mapStateToProps(state, ownProps) {
  return {
    theme: state.settings.theme,
    tax_rates: state.document.tax_rates,
  }
}

export default connect(mapStateToProps, null, null, { forwardRef: true })(AgGridTable)

// On Commit Drag Default functionality
// Show Drag icon while add roDrag prop in the first column

// onCommitDrag = (event, type, ref) => {
//   this.setState({ statement: this.movedData(event, this.state.statement, ref) })
// }

// movedData = (event, data, gridRef) => {
//   var movingNode = event.node
//   var overNode = event.overNode
//   var rowNeedsToMove = movingNode !== overNode
//   if (rowNeedsToMove) {
//     // the list of rows we have is data, not row nodes, so extract the data
//     var movingData = movingNode.data
//     var overData = overNode.data
//     var fromIndex = data.indexOf(movingData)
//     var toIndex = data.indexOf(overData)
//     var newStore = data.slice()
//     moveInArray(newStore, fromIndex, toIndex)
//     gridRef.api().clearFocusedCell()
//     return newStore
//   }
//   function moveInArray(arr, fromIndex, toIndex) {
//     var element = arr[fromIndex]
//     arr.splice(fromIndex, 1)
//     arr.splice(toIndex, 0, element)
//   }
// }
