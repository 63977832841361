import React, { useState } from 'react'
import { IntercomProvider, useIntercom } from 'react-use-intercom'
import store from 'store'

const IntercomSupport1 = props => {
  const { boot, show, shutdown } = useIntercom()
  const [open, setOpen] = useState(false)
  const [tipVisible, setTipVisible] = useState(false)
  const handleIntercomButtonClick = async () => {
    let user_id = store.get('user_id') ? store.get('user_id') : ''
    let company_id = store.get('company_details') ? store.get('company_details').company_id : ''

    boot({
      customAttributes: {
        custom_attribute_key: 'Hi There,',
        user_id: 'C' + company_id + 'U' + user_id,
        email: store.get('email') ? store.get('email') : '',
        // created_at: 1234567890,
        name: store.get('user_details')
          ? store.get('user_details').name + '- ' + store.get('company_name')
          : store.get('company_name'),
        // name: store.get('company_name'),
        user_name: store.get('user_details') ? store.get('user_details').name : '',
        phone: store.get('user_details') ? store.get('user_details').mobile : '',
        paid: store.get('paid') ? 'Paid' : 'Free',
        billing: store.get('user_details') ? store.get('user_details').billing : '',
        company_id: company_id,
        owner: store.get('company_name'),
        gstin: store.get('company_details') ? store.get('company_details').gstin : '',
        firstPaymentDate: store.get('user_details') ? store.get('user_details').start_date : '',
      },
    })
    show()
    setOpen(true)
  }
  const handleIntercomClose = async () => {
    shutdown()
    setOpen(false)
  }
  const showMessage = () => {
    if (store.get('features_shown') == undefined) {
      store.set('features_shown', '')
    }

    setTipVisible(true)
    let user_id = store.get('user_id') ? store.get('user_id') : ''
    let company_id = store.get('company_details') ? store.get('company_details').company_id : ''

    boot({
      customAttributes: {
        custom_attribute_key: 'Hi There,',
        user_id: 'C' + company_id + 'U' + user_id,
        email: store.get('email') ? store.get('email') : '',
        // created_at: 1234567890,
        name: store.get('user_details')
          ? store.get('user_details').name + ' - ' + store.get('company_name')
          : store.get('company_name'),
        // name: store.get('company_name'),
        user_name: store.get('user_details') ? store.get('user_details').name : '',
        mobile: store.get('user_details') ? store.get('user_details').mobile : '',
        paid: store.get('paid') ? 'Paid' : 'Free',
        billing: store.get('user_details') ? store.get('user_details').billing : '',
        company_id: company_id,
        owner: store.get('company_name'),
        gstin: store.get('company_details') ? store.get('company_details').gstin : '',
        firstPaymentDate: store.get('user_details') ? store.get('user_details').start_date : '',
      },
    })
    show()
    store.set('support_shown', 'true')
    return
  }
  return (
    <>
      {/*{!open ? (
        <Button
          onClick={!store.get(`support_shown`) ? showMessage : handleIntercomButtonClick}
          size={props.size}
          type="primary"
          shape="round"
          className="font-weight-bolder px-4"
        >
          <i className="fas fa-comment-lines mr-2"></i>
          <span>Help?</span>
        </Button>
      ) : (
        <Button
          onClick={handleIntercomClose}
          size={props.size}
          type="primary"
          shape="round"
          className="font-weight-bolder px-4"
        >
          <i className="fas fa-close mr-2"></i>
          <span>Hide Chat</span>
        </Button>
      )}
      <FeatureTour
        type="support"
        onClick={() => {
          store.set('support_shown', 'true')
          setTipVisible(false)
          handleIntercomButtonClick()
        }}
        visible={tipVisible}
        show_for_new={false}
      />*/}
    </>
  )
}

const INTERCOM_APP_ID = 'be01q1gj'
// const IntercomSupportProvider = props => {
//   return (
//     <IntercomProvider
//       appId={INTERCOM_APP_ID}
//       apiBase={`https://${INTERCOM_APP_ID}.intercom-messenger.com`}
//     >
//       <IntercomSupport size={props.size} />
//     </IntercomProvider>
//   )
// }

const IntercomSupportProvider = props => {
  return (
    <IntercomProvider
      appId={INTERCOM_APP_ID}
      autoBoot
      autoBootProps={{
        customAttributes: {
          custom_attribute_key: 'Hi There,',
          user_id:
            'C' +
            (store.get('company_details') ? store.get('company_details').company_id : '') +
            'U' +
            (store.get('user_id') ? store.get('user_id') : ''),
          email: store.get('email') ? store.get('email') : '',
          name: store.get('user_details')
            ? store.get('user_details').name + ' - ' + store.get('company_name')
            : store.get('company_name'),
          // name: store.get('company_name'),
          user_name: store.get('user_details') ? store.get('user_details').name : '',
          mobile: store.get('user_details') ? store.get('user_details').mobile : '',
          paid: store.get('paid') ? 'Paid' : 'Free',
          billing: store.get('user_details') ? store.get('user_details').billing : '',
          companyId: store.get('company_details') ? store.get('company_details').company_id : '',
          owner: store.get('company_name'),
          gstin: store.get('company_details') ? store.get('company_details').gstin : '',
          firstPaymentDate: store.get('user_details') ? store.get('user_details').start_date : '',
        },
      }}
      apiBase={`https://${INTERCOM_APP_ID}.intercom-messenger.com`}
    >
      <IntercomSupport message={props.message} type={props.type} />
    </IntercomProvider>
  )
}

const IntercomSupport = props => {
  const { boot, shutdown, hide, show, showNewMessage, showSpace, update } = useIntercom()
  const { type, message } = props

  const handleIntercomButtonClick = async () => {
    // show()
    showNewMessage(message)
  }

  return (
    <>
      {type == 'error' && (
        <a className="font-weight-bold" onClick={handleIntercomButtonClick}>
          <i className="fa fa-whatsapp fa-lg mr-2"></i>
          Click here for Help/Support
        </a>
      )}
    </>
  )
}

export default IntercomSupportProvider
