import React, { Component } from 'react'

export class NewBadge extends Component {
  render() {
    return (
      <span className="badge badge-primary align-text-middle badge-collapsed-hidden font-italic font-weight-bolder ml-2">
        New
      </span>
    )
  }
}

export default NewBadge
