import { Text, View } from '@react-pdf/renderer'
import { Fragment } from 'react'
import { HIDE_PRICES } from '../../../utils/constants'
import { styles as getStyles } from './styles'

const borderColor = '#276EF1'

const InvoiceTableBlankSpace = ({ rowsCount, invoice, type }) => {
  var styles = getStyles(invoice)
  const hide_hsn = invoice.invoice_settings.hide_hsn
  var FONT_FACTOR = invoice.invoice_settings.font_size
  let no_of_default_cols = invoice.invoice_settings.hide_qty ? 8.2 : 9
  no_of_default_cols = hide_hsn ? no_of_default_cols - 0.7 : no_of_default_cols
  let no_of_with_out_tax_cols = invoice.invoice_settings.hide_qty ? 5 : 5.8
  no_of_with_out_tax_cols = hide_hsn ? no_of_with_out_tax_cols - 0.8 : no_of_with_out_tax_cols
  let no_of_with_out_tax_cols_gst = invoice.invoice_settings.hide_qty ? 5 : 6
  no_of_with_out_tax_cols_gst = hide_hsn
    ? no_of_with_out_tax_cols_gst - 1
    : no_of_with_out_tax_cols_gst
  let dc_col = 100 / invoice.invoice_settings.hide_qty ? 2 : 3
  var single_col = (1 * 100) / (no_of_default_cols + invoice.custom_col_names.length)
  var single_col2 = (1 * 100) / (no_of_with_out_tax_cols + invoice.custom_col_names.length)
  var single_col3 = (1 * 100) / (no_of_with_out_tax_cols_gst + invoice.custom_col_names.length)
  var font_size = (FONT_FACTOR * (9 * 9)) / (10 + invoice.custom_col_names.length)
  var font_size2 = (FONT_FACTOR * (9 * 15)) / (16 + invoice.custom_col_names.length)
  const blankRows = rowsCount > 0 ? Array(rowsCount).fill(0) : []
  const rows = blankRows.map((x, i) => (
    <Fragment key={i}>
      {type != 'Delivery Challan' && type != HIDE_PRICES && (
        <>
          {invoice.company.gstin.length == 15 ? (
            <>
              {invoice.with_tax == 0 ? (
                <View style={[styles.invoiceTableBlankSpace_row]}>
                  <Text
                    style={{
                      ...styles.invoiceTableBlankSpace_cell,
                      fontSize: font_size2,
                      textAlign: 'right',
                      width: `${0.2 * single_col2}%`,
                    }}
                  >
                    {'  '}
                  </Text>

                  <Text
                    style={{
                      ...styles.invoiceTableBlankSpace_cell,
                      textAlign: 'right',
                      fontSize: font_size2,
                      width: `${2 * single_col2}%`,
                    }}
                  >
                    {'  '}
                  </Text>
                  {!hide_hsn && (
                    <Text
                      style={{
                        ...styles.invoiceTableBlankSpace_cell,
                        textAlign: 'right',
                        width: `${0.8 * single_col2}%`,
                      }}
                    >
                      {'  '}
                    </Text>
                  )}
                  {invoice.custom_col_names.map((item, index) => (
                    <Text
                      key={index}
                      style={{
                        ...styles.invoiceTableBlankSpace_cell,
                        textAlign: 'right',
                        width: `${single_col2}%`,
                      }}
                    >
                      {'  '}
                    </Text>
                  ))}
                  <Text
                    style={{
                      ...styles.invoiceTableBlankSpace_cell,
                      textAlign: 'right',
                      width: `${single_col2}%`,
                    }}
                  >
                    {'  '}
                  </Text>
                  {invoice.invoice_settings.hide_qty == 0 && (
                    <Text
                      style={{
                        ...styles.invoiceTableBlankSpace_cell,
                        textAlign: 'right',
                        width: `${0.8 * single_col2}%`,
                      }}
                    >
                      {'  '}
                    </Text>
                  )}

                  <Text
                    style={{
                      ...styles.invoiceTableBlankSpace_cell,
                      textAlign: 'right',
                      width: `${single_col2}%`,
                    }}
                  >
                    {'  '}
                  </Text>
                </View>
              ) : (
                <View style={styles.invoiceTableBlankSpace_row}>
                  <Text
                    style={{
                      ...styles.invoiceTableBlankSpace_cell,
                      textAlign: 'left',
                      fontSize: font_size,
                      width: `${0.4 * single_col}%`,
                    }}
                  >
                    {'  '}
                  </Text>

                  <Text
                    style={{
                      ...styles.invoiceTableBlankSpace_cell,
                      fontSize: font_size,
                      textAlign: 'left',
                      width: `${2.5 * single_col}%`,
                    }}
                  >
                    {'  '}
                  </Text>

                  {invoice.custom_col_names.map((item, index) => (
                    <Text
                      key={index}
                      style={{
                        ...styles.invoiceTableBlankSpace_cell,
                        width: `${single_col}%`,
                        fontSize: font_size,
                      }}
                    >
                      {'  '}
                    </Text>
                  ))}

                  {!hide_hsn && (
                    <Text
                      style={{
                        ...styles.invoiceTableBlankSpace_cell,
                        textAlign: 'right',
                        fontSize: font_size,
                        width: `${0.7 * single_col}%`,
                      }}
                    >
                      {'  '}
                    </Text>
                  )}

                  <Text
                    style={{
                      ...styles.invoiceTableBlankSpace_cell,
                      textAlign: 'right',
                      fontSize: font_size,
                      width: `${1.5 * single_col}%`,
                    }}
                  >
                    {'  '}
                  </Text>

                  {invoice.invoice_settings.hide_qty == 0 && (
                    <Text
                      style={{
                        ...styles.invoiceTableBlankSpace_cell,
                        textAlign: 'right',
                        fontSize: font_size,
                        width: `${0.8 * single_col}%`,
                      }}
                    >
                      {'  '}
                    </Text>
                  )}

                  <Text
                    style={{
                      ...styles.invoiceTableBlankSpace_cell,
                      textAlign: 'right',
                      fontSize: font_size,
                      width: `${single_col}%`,
                    }}
                  >
                    {'  '}
                  </Text>

                  <Text
                    style={{
                      ...styles.invoiceTableBlankSpace_cell,
                      fontSize: font_size,
                      textAlign: 'right',
                      width: `${1.1 * single_col}%`,
                    }}
                  >
                    {'  '}
                  </Text>

                  <Text
                    style={{
                      ...styles.invoiceTableBlankSpace_cell,
                      textAlign: 'right',
                      paddingRight: 4.5,
                      borderRight: 'none',
                      fontSize: font_size,
                      width: `${single_col}%`,
                    }}
                  >
                    {'  '}
                  </Text>
                </View>
              )}
            </>
          ) : (
            <View style={[styles.invoiceTableBlankSpace_row]}>
              <Text
                style={{
                  ...styles.invoiceTableBlankSpace_cell,
                  textAlign: 'right',
                  fontSize: font_size2,
                  width: `${0.2 * single_col3}%`,
                }}
              >
                {'  '}
              </Text>

              <Text
                style={{
                  ...styles.invoiceTableBlankSpace_cell,
                  fontSize: font_size2,
                  width: `${1.8 * single_col3}%`,
                }}
              >
                {'  '}
              </Text>

              {invoice.custom_col_names.map((item, index) => (
                <Text
                  key={index}
                  style={{
                    ...styles.invoiceTableBlankSpace_cell,
                    textAlign: 'right',
                    width: `${single_col3}%`,
                    fontSize: font_size2,
                  }}
                >
                  {'  '}
                </Text>
              ))}

              {!hide_hsn && (
                <Text
                  style={{
                    ...styles.invoiceTableBlankSpace_cell,
                    textAlign: 'right',
                    fontSize: font_size2,
                    width: `${single_col3}%`,
                  }}
                >
                  <Text>{'  '}</Text>
                </Text>
              )}

              <Text
                style={{
                  ...styles.invoiceTableBlankSpace_cell,
                  textAlign: 'right',
                  fontSize: font_size2,
                  width: `${single_col3}%`,
                }}
              >
                {'  '}
              </Text>
              {invoice.invoice_settings.hide_qty == 0 && (
                <Text
                  style={{
                    ...styles.invoiceTableBlankSpace_cell,
                    textAlign: 'right',
                    fontSize: font_size2,
                    width: `${single_col3}%`,
                  }}
                >
                  {'  '}
                </Text>
              )}
              <Text
                style={{
                  ...styles.invoiceTableBlankSpace_cell,
                  textAlign: 'right',
                  borderRight: 'none',
                  fontSize: font_size2,
                  width: `${single_col3}%`,
                }}
              >
                {'  '}
              </Text>
            </View>
          )}
        </>
      )}
    </Fragment>
  ))
  return (
    <Fragment>
      <View style={styles.globalContainer}>{rows}</View>
      <View fixed style={styles.invoiceTableBlankSpace_block2}></View>
    </Fragment>
  )
}

export default InvoiceTableBlankSpace
