import {
  Button,
  Card,
  DatePicker,
  Descriptions,
  Divider,
  Input,
  Modal,
  Radio,
  Row,
  Select,
  Switch,
  message,
} from 'antd'
import AddressForm from 'components/forms/addressForm'
import EnhancedFrom from 'components/forms/customerForm'
import EditCompany from 'components/forms/editCompany'
import { EnhancedNICCreds } from 'components/forms/nicCreds'
import { Loader } from 'components/loaders'
import EnhancedAddresses from 'components/other/addresses'
import ProductsList from 'components/productslist'
import * as dayjs from 'dayjs'
import utc from 'dayjs/plugin/utc'
import _ from 'lodash'
import React, { Component } from 'react'
import { getAPI, getAPIData } from 'services/jwt'

const { Option } = Select
dayjs.extend(utc)
class CreateEwayBill extends Component {
  dateFormat = 'DD/MM/YYYY'
  shippingAddressRef = React.createRef()
  constructor(props) {
    super(props)
    this.state = {
      loading: false,
      visible: false,
      type: 'to',
      doc_count: -1,
      address_loading: false,
      sub_supply_type: '1',
      selectedAddress: {
        to_details: { line1: '', line2: '', city: '', state: '', country: '', pincode: '' },
        from_details: {
          line1: '',
          line2: '',
          city: '',
          state: '',
          country: '',
          pincode: '',
        },
      },
      document_type: 'invoice',
      section: '1',
      transporter_ids: [],
      vehicle_nos: [],
      selectedVehicleNo: undefined,
      selectedTransporter: undefined,
      shippingAddressDrawerVisible: false,
      isEdit: false,
    }
    this._onAddressFinish = this._onAddressFinish.bind(this)
    this.onAddressFinish = _.throttle(this._onAddressFinish, 2000)
  }

  async componentDidMount() {
    try {
      const data = await getAPIData('ewaybills', 'get_transporter_ids')
      if (data.success) {
        this.setState({
          transporter_ids: data.data,
          vehicle_nos: data.vehicle_nos,
        })
      }
    } catch (error) {
      console.log(error)
    }
  }

  componentWillUnmount() {
    this.onAddressFinish.cancel()
  }

  getNetAmount = record => {
    return record.price * record.qty - (record.price * record.qty * record.discount) / 100
  }

  getTaxAmount = record => {
    return (this.getNetAmount(record) * record.tax) / 100
  }
  getRowTotal = record => {
    return Math.round(this.getNetAmount(record) + this.getTaxAmount(record))
  }

  getTotal = () => {
    var sum = 0
    this.props.dataSource.map(item => {
      sum = sum + this.getRowTotal(item)
    })
    return Math.round(sum)
  }

  getTotalTax = () => {
    var sum = 0
    this.props.dataSource.map(item => {
      sum = sum + this.getTaxAmount(item)
    })
    return sum
  }

  showAddressModal = type => {
    this.setState({ type }, () => {
      this.address.showModal(
        type == 'to'
          ? this.props.data.invoice_details.to_details
          : {
              ...this.props.data.invoice_details.from_details,
              billing: this.props.data.invoice_details.from_details.billing_address,
              shipping: this.props.data.invoice_details.from_details.shipping_address,
              billing_address: this.props.data.invoice_details.from_details.billing_addresses,
              shipping_address: this.props.data.invoice_details.from_details.shipping_addresses,
            },
        this.state.type == 'to'
          ? this.props.data.invoice_details.customer_shipping_addr_id
          : this.props.data.invoice_details.company_shipping_addr_id,
        this.state.type,
      )
    })
  }

  onAddressChange = async (addr_id, _type = -1) => {
    this.setState({
      address_loading: true,
    })
    const req = {
      document_type: this.props.data.invoice_details.document_type,
      doc_count: this.props.data.invoice_details.doc_count,
      type: _type != -1 ? _type : this.state.type,
      addr_id: addr_id,
    }

    const data = await getAPI('v2/doc', 'update_document_shipping_address', req)
    if (data.success) {
      this.address.closeModal()
      message.loading("Please wait while we're updating the address")
      await this.props.getEwayBillData()

      this.setState({
        address_loading: false,
        customer_shipping_addr_id: _type == 'to' ? addr_id : this.state.customer_shipping_addr_id,
        company_shipping_addr_id: _type == 'from' ? addr_id : this.state.company_shipping_addr_id,
      })
      message.success('Address Updated Successfully')
    } else {
      message.error(data.message)
      this.address.closeModal()
      this.setState({
        address_loading: false,
      })
    }
  }

  verifyHSNCodes = async () => {
    //check hsn codes valid or not
    const req = {
      items: this.props.dataSource,
    }
    const valid = await getAPI('ewaybills', 'check_hsn_codes', req)
    if (!valid.valid) {
      message.warning(valid.message + '!! It may give error in Eway Bill Generation')
    } else {
      message.success('HSN Codes are valid')
    }
  }

  onChangeTransporterId = e => {
    //split the string and get the last value

    let transporter_id = e[e.length - 1]
    this.setState(
      {
        selectedTransporter: transporter_id,
      },
      async () => {
        let transporter = this.state.transporter_ids.filter(item => item.name == transporter_id)
        if (transporter && transporter.length > 0) {
          let transporter_name = transporter[0].transporter_name
          if (transporter_name) {
            this.props.handleChange(transporter_name, 'transporter_name')
          } else if (transporter_id?.length === 15) {
            await this.fetchTransporterName(transporter_id)
          } else {
            this.props.handleChange('', 'transporter_name')
          }
        } else if (transporter_id?.length === 15) {
          await this.fetchTransporterName(transporter_id)
        } else {
          this.props.handleChange('', 'transporter_name')
        }
      },
    )
    this.props.handleChange(transporter_id, 'trasporter_gstin')
  }

  async fetchTransporterName(transporter_id) {
    var req = {
      transporter_no: transporter_id,
    }
    var data = await getAPI('ewaybills', 'fetch_transporter_details', req)
    if (data.success) {
      this.props.handleChange(data.trade_name, 'transporter_name')
    } else {
      this.props.handleChange('', 'transporter_name')
    }
  }
  onCloseAddressDrawer = () => {
    this.setState({
      shippingAddressDrawerVisible: false,
    })
  }
  onCloseEditAddress = () => {
    this.setState({
      isEdit: false,
    })
  }
  _onAddressFinish = async (values, addressType, addr_id) => {
    values = { ...values, state: values.state1 }
    for (const key in values) {
      if (values.hasOwnProperty(key) && values[key] === undefined) {
        values[key] = ''
      }
    }

    if (addr_id >= 0) {
      var req = {
        addr_id: addr_id,
        customer_id: this.props.data.invoice_details.customer.customer_id,
        ...values,
      }
      var data = await getAPI('v2/customer', 'edit_shipping_address', req)
      if (data.success) {
        message.success(data.message)
        // onCloseAdressDrawer()
        // props.onCloseEditAddress()
      }
    } else {
      var req = {
        ...values,
        customer_id: this.props.data.invoice_details.customer.customer_id,
      }
      var data = await getAPI('v2/customer', 'add_shipping_address', req)
      if (data.success) {
        message.success(data.message)
      }
    }
    this.shippingAddressRef?.current?.onClose()
    this.props.getEwayBillData()
  }

  render() {
    const Notes = (
      <div className="mt-4">
        <h6 className="font-weight-bold font-size-16 mb-2 text-danger">* Notes</h6>
        <ul className="text-gray-700 pl-3">
          <li>
            <strong>HSN Codes</strong> are mandatory for all products in EwayBills.
          </li>
          <li>
            If you are getting <strong>Invalid HSN Code</strong> error, see if the HSN code exists
            in the GST Database.{' '}
            <a
              href="https://services.gst.gov.in/services/searchhsnsac"
              target="_blank"
              className="font-weight-bold"
            >
              Click here to Check HSN Codes.
            </a>
          </li>
          <li>
            <strong>Invoice number</strong> should be less than <strong>13 characters</strong>.
          </li>
          <li>
            From and To <strong>Pincodes</strong> are mandatory.
          </li>
          <li>
            If you do not have <strong>PART-B details</strong> (like Vehicle Number & mode of
            transportation), only enter <strong>TRANSPORTER ID</strong> and nothing else.
          </li>
          <li>
            Vehicle Number should be in <strong>KA99MH9999</strong> format.
          </li>
          <li>
            If you are getting <strong>Invalid Distance</strong> error, please calculate the exact
            distance by going to this{' '}
            <a
              href="https://ewaybillgst.gov.in/Others/P2PDistance.aspx"
              target="_blank"
              className="font-weight-bold"
            >
              Link
            </a>
          </li>
          <li>
            <strong>For Exports</strong> Select/Enter the port shipping address
          </li>
          <li>
            <strong>For Exports</strong> Enter distance upto the port
          </li>
          <li>
            <strong>For Long Form</strong> You can check in the ewaybill portal and you will get{' '}
            <strong>Short Form</strong> after creation in ewaybills section. Both are accepted while
            transporting goods.
          </li>
        </ul>
      </div>
    )

    return (
      <div>
        {' '}
        <Modal
          open={this.props.visible}
          onCancel={this.props.closeModal}
          centered
          width={'95vw'}
          bodyStyle={{ overflowY: 'auto', maxHeight: 'calc(100vh - 200px)' }}
          closeIcon={<i className="fa-solid fa-lg fa-xmark"></i>}
          title={
            <div className="d-flex  w-100 justify-content-between">
              <div className="d-flex flex-wrap  font-size-16">
                <h4 className="p-2">
                  {!this.props.edit && (
                    <span className="mr-3 font-weight-bold pt-5">Create Eway Bill</span>
                  )}
                  {this.props.edit && (
                    <span className="mr-3 font-weight-bold pt-5">Update Eway Bill</span>
                  )}
                </h4>

                <Button
                  loading={this.props.loadingSave}
                  onClick={() => this.props.save()}
                  size="default"
                  className="mr-2 font-weight-bold"
                >
                  Create EwayBill
                </Button>
              </div>
              <Button
                onClick={e => this.nic_creds.showDrawerLogin(true)}
                className="mr-5 font-size-12"
                type="action"
              >
                <i className="fa fa-truck mr-2" /> Re-Login to E-Way Bill Portal
              </Button>
            </div>
          }
          footer={
            <>
              <div className="text-right bg-white">
                <div
                  className=""
                  //style={{ borderTop: '1px solid #E3E8EE' }}
                >
                  {/*<div>
                    <p className="text-danger font-weight-bold font-weight-14 mr-3 mt-3">
                      *Read the notes carefully before creating Eway Bill.
                    </p>
                  </div>*/}
                  <div className="">
                    <span className="text-danger font-weight-bold font-weight-14 mr-3">
                      *Read the notes carefully before creating Eway Bill.
                    </span>
                    <Button
                      loading={this.props.loadingSave}
                      onClick={() => this.props.save()}
                      type="primary"
                      size="default"
                      className="font-weight-bold"
                    >
                      Create EwayBill
                    </Button>
                  </div>
                </div>
              </div>
            </>
          }
          maskClosable={false}
        >
          {this.props.loading && (
            <>
              <Loader visibleText={true} />
              {Notes}
            </>
          )}
          {!this.props.loading && (
            <>
              <div>
                <div className="card bg-gray-50">
                  <div className="card-body pb-1">
                    {this.props.show_ewaybill_header && (
                      <div className="mb-2">
                        <span className="font-weight-bold font-size-16 mr-2 align-middle">
                          Create E-Waybill using E-Invoice Details
                        </span>
                        <Switch
                          onChange={this.props.handleEInvoice}
                          checked={this.props.is_einvoice == 1}
                          title="Create E-Waybill With E-Invoice Details"
                        />
                      </div>
                    )}

                    <div className="row">
                      <div className="col-12 col-md-4 mb-2">
                        <div className="mb-4">
                          {!this.props.is_einvoice && (
                            <Descriptions bordered className="" column={1} size="small">
                              <Descriptions.Item label="Document No." className="font-size-14">
                                {/* <span className="d-inline-flex"> */}
                                <Input
                                  placeholder="Document No."
                                  value={this.props.data.invoice_details.serial_number}
                                  disabled
                                  className="w-100"
                                />
                                {/* </span> */}
                              </Descriptions.Item>
                              <Descriptions.Item label="Document Date" className="font-size-14">
                                <DatePicker
                                  style={{
                                    borderRadius: '5px',
                                    cursor: 'pointer',
                                    width: '100%',
                                  }}
                                  allowClear={false}
                                  format={this.dateFormat}
                                  value={dayjs(this.props.data.invoice_date, this.dateFormat)}
                                  onChange={this.props.onInvoiceDateChange}
                                  disabled={this.props.disabledDate}
                                />
                              </Descriptions.Item>
                              <Descriptions.Item label="Supply Type" className="font-size-14">
                                {this.props.supplyType.length && (
                                  <Select
                                    suffixIcon={<i className="fa-regular fa-chevron-down"></i>}
                                    defaultValue={this.props.supplyType[0]['supply_type']}
                                    style={{ width: '100%' }}
                                    onChange={e => {
                                      this.props.handleChange(e, 'supply_type')
                                    }}
                                    disabled
                                  >
                                    {this.props.supplyType.map((item, i) => (
                                      <Option key={i} value={item.value}>
                                        {item.supply_type}
                                      </Option>
                                    ))}
                                  </Select>
                                )}
                              </Descriptions.Item>
                              <Descriptions.Item label="Sub Supply" className="font-size-14">
                                {this.props.subSupplyType.length && (
                                  <Select
                                    suffixIcon={<i className="fa-regular fa-chevron-down"></i>}
                                    defaultValue={
                                      this.props.data.invoice_details.document_type == 'estimate'
                                        ? this.props.subSupplyType.filter(
                                            item => item.value == '8',
                                          )[0]['sub_supply_type']
                                        : this.props.data.invoice_details.document_type ==
                                          'delivery_challan'
                                        ? this.props.subSupplyType[11]['sub_supply_type']
                                        : this.props.data.invoice_details.is_export
                                        ? this.props.subSupplyType[1]['sub_supply_type']
                                        : this.props.subSupplyType[0]['sub_supply_type']
                                    }
                                    style={{ width: '100%' }}
                                    onChange={e => {
                                      this.setState({ sub_supply_type: e })
                                      this.props.handleChange(e, 'sub_supply_type')
                                    }}
                                  >
                                    {this.props.subSupplyType.map(
                                      (item, i) =>
                                        item.sub_supply_type != 'Import' && (
                                          <Option key={i} value={item.value}>
                                            {item.sub_supply_type}
                                          </Option>
                                        ),
                                    )}
                                  </Select>
                                )}
                              </Descriptions.Item>
                              {(this.state.sub_supply_type == '8' ||
                                this.props.data.invoice_details.document_type == 'estimate') && (
                                <Descriptions.Item
                                  label="Sub Supply Type Description"
                                  className="font-size-14"
                                >
                                  <Input
                                    placeholder="Sub Supply Type Description"
                                    onChange={e => {
                                      this.props.handleChange(e, 'sub_supply_type_desc')
                                    }}
                                  />
                                </Descriptions.Item>
                              )}
                              {!this.props.is_einvoice && (
                                <>
                                  <Descriptions.Item
                                    label="Transaction Type"
                                    className="font-size-14"
                                  >
                                    {this.props.transaction_types?.length && (
                                      <Select
                                        className="mt-2"
                                        style={{ width: '95%' }}
                                        onChange={e => {
                                          this.setState({ transaction_type: e })
                                          this.props.handleChange(e, 'transaction_type')
                                        }}
                                        value={this.props.selectedTransactionType}
                                      >
                                        {this.props.transaction_types.map((item, i) => (
                                          <Option key={i} value={item.value}>
                                            {item.transaction_type}{' '}
                                            {item.value == this.props.recommendedTransactionType &&
                                            this.props.recommendedTransactionType !=
                                              this.props.selectedTransactionType ? (
                                              <span className="text-white bg-primary p-1 font-weight-bold rounded ml-3 font-size-12 align-middle border-gray-2">
                                                Recommended
                                              </span>
                                            ) : (
                                              ''
                                            )}
                                          </Option>
                                        ))}
                                      </Select>
                                    )}
                                  </Descriptions.Item>
                                  <Descriptions.Item>
                                    <p className="font-size-14 text-danger">
                                      *Important: Considering your data, we advise the specified
                                      transaction type. Any changes to this option can solely be
                                      implemented before generating the Ewaybill under your
                                      discretion.
                                    </p>
                                  </Descriptions.Item>
                                </>
                              )}
                            </Descriptions>
                          )}
                        </div>
                        <div className="mb-4">
                          <Descriptions bordered className="" column={1} size="small">
                            <Descriptions.Item
                              label={
                                <div>
                                  <span>Dispatch From</span>
                                </div>
                              }
                            >
                              <Select
                                suffixIcon={<i className="fa-regular fa-chevron-down"></i>}
                                placeholder={'Select address'}
                                optionFilterProp="children"
                                style={{ minWidth: '100%', maxWidth: 300 }}
                                listHeight={200}
                                dropdownStyle={{ minWidth: '360px', maxWidth: '360px' }}
                                onChange={e => {
                                  if (!_.isNil(e)) {
                                    this.onAddressChange(e, 'from')
                                  }
                                }}
                                value={
                                  this.props.document_company_shipping_address.length > 0
                                    ? this.props.document_company_shipping_address[0].addr_id
                                    : undefined
                                }
                                filterOption={(input, option) =>
                                  option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                }
                                dropdownRender={menu => (
                                  <>
                                    {menu}
                                    <div className="bg-gray-50">
                                      <Button
                                        block
                                        type="link-2"
                                        className="font-weight-bolder text-left my-2 arrow-transition"
                                        onClick={e => {
                                          e.stopPropagation()
                                          this.EditCompanyForm.onAdd()
                                        }}
                                      >
                                        <i className="fa-solid fa-circle-plus mr-2"></i>
                                        Add Company Shipping address
                                        <i className="fa-solid fa-arrow-right ml-2 arrow-transition"></i>
                                      </Button>
                                    </div>
                                  </>
                                )}
                              >
                                {this.props.data.invoice_details.from_details.shipping_addresses
                                  ?.length > 0 && (
                                  <>
                                    {this.props.data.invoice_details.from_details.shipping_addresses.map(
                                      (item, i) =>
                                        item.is_delete == 0 && (
                                          <Option key={i} value={item.addr_id}>
                                            {item.line1}, {item.line2}, {item.city}, {item.state},{' '}
                                            {item.pincode}
                                          </Option>
                                        ),
                                    )}
                                  </>
                                )}
                              </Select>
                            </Descriptions.Item>

                            <Descriptions.Item
                              label={
                                <div>
                                  <span>Shipping To</span>
                                </div>
                              }
                            >
                              <Select
                                suffixIcon={<i className="fa-regular fa-chevron-down"></i>}
                                placeholder={'Select address'}
                                optionFilterProp="children"
                                style={{ minWidth: '100%', maxWidth: 300 }}
                                listHeight={200}
                                dropdownStyle={{ minWidth: '360px', maxWidth: '360px' }}
                                onChange={e => {
                                  if (!_.isNil(e)) {
                                    this.setState(this.onAddressChange(e, 'to'))
                                  }
                                }}
                                value={
                                  this.props.document_customer_shipping_address.length > 0
                                    ? this.props.document_customer_shipping_address[0].addr_id
                                    : undefined
                                }
                                filterOption={(input, option) =>
                                  option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                }
                                dropdownRender={menu => (
                                  <>
                                    {menu}
                                    <div className="bg-gray-50">
                                      <Button
                                        block
                                        type="link-2"
                                        className="font-weight-bolder text-left my-2 arrow-transition"
                                        onClick={e => {
                                          e.stopPropagation()
                                          this.shippingAddressRef?.current?.showAddressDrawer(
                                            this.props.data.invoice_details.to_details[
                                              'customer_id'
                                            ],
                                            'shipping',
                                            {
                                              addr_id: -1,
                                            },
                                          )
                                        }}
                                      >
                                        <i className="fa-solid fa-circle-plus mr-2"></i>
                                        Add Shipping address
                                        <i className="fa-solid fa-arrow-right ml-2 arrow-transition"></i>
                                      </Button>
                                    </div>
                                  </>
                                )}
                              >
                                {this.props.data.invoice_details.to_details.shipping_address
                                  ?.length > 0 && (
                                  <>
                                    {this.props.data.invoice_details.to_details.shipping_address.map(
                                      (item, i) =>
                                        item.is_delete == 0 && (
                                          <Option key={i} value={item.addr_id}>
                                            {item.line1}, {item.line2}, {item.city}, {item.state},{' '}
                                            {item.pincode}
                                          </Option>
                                        ),
                                    )}
                                  </>
                                )}
                              </Select>
                            </Descriptions.Item>
                          </Descriptions>
                        </div>

                        {/* {!this.props.is_einvoice && (
                          <div>
                            <Row>
                              <Card
                                size="small"
                                style={{
                                  width: '100%',
                                  borderRadius: '5px',
                                  cursor: 'pointer',
                                }}
                                className="my-2 border-gray-2"
                              >
                                <div>
                                  <h6 className="font-weight-bold font-size-16 mb-0 ml-1 mt-2">
                                    Transaction Type
                                  </h6>
                                  <p className="font-size-14 text-danger">
                                    *Important: Considering your data, we advise the specified
                                    transaction type. Any changes to this option can solely be
                                    implemented before generating the Ewaybill under your
                                    discretion.
                                  </p>
                                </div>
                                {this.props.transaction_types?.length && (
                                  <Select
                                    className="mt-2"
                                    style={{ width: '95%' }}
                                    onChange={e => {
                                      this.setState({ transaction_type: e })
                                      this.props.handleChange(e, 'transaction_type')
                                    }}
                                    value={this.props.selectedTransactionType}
                                  >
                                    {this.props.transaction_types.map((item, i) => (
                                      <Option key={i} value={item.value}>
                                        {item.transaction_type}{' '}
                                        {item.value == this.props.recommendedTransactionType &&
                                        this.props.recommendedTransactionType !=
                                          this.props.selectedTransactionType ? (
                                          <span className="text-white bg-primary p-1 font-weight-bold rounded ml-3 font-size-12 align-middle border-gray-2">
                                            Recommended
                                          </span>
                                        ) : (
                                          ''
                                        )}
                                      </Option>
                                    ))}
                                  </Select>
                                )}
                              </Card>
                            </Row>
                          </div>
                        )} */}
                        {Notes}
                      </div>

                      <div className="col-12 col-md-8 mb-0">
                        <Card size="medium" className="bg-card-forest border-radius-small">
                          {!this.props.is_einvoice && (
                            <div className="my-2">
                              <Radio.Group
                                value={this.state.section}
                                onChange={e => {
                                  this.setState({ section: e.target.value })
                                  if (e.target.value == '2') {
                                    this.props.handleChange('', 'transport_mode')
                                  } else if (this.props.transportMode.length > 0) {
                                    this.props.handleChange(
                                      this.props.transportMode[0]['value'],
                                      'transport_mode',
                                    )
                                  }
                                }}
                              >
                                <Radio value={'1'}>Part-A & Part-B</Radio>
                                <Radio value={'2'}>Part-A</Radio>
                                <Radio value={'3'}>Part-B</Radio>
                              </Radio.Group>
                            </div>
                          )}
                          {this.state.section != '3' && (
                            <div>
                              <h6 className="font-size-14 text-gray-400">PART-A</h6>
                              <div className="row">
                                <div className="col-12 col-md-6 mb-4">
                                  <h6>Transporter ID</h6>
                                  <span className="d-inline-flex w-100">
                                    {/* <Input
                                        placeholder="Transporter ID"
                                        onChange={e => {
                                          this.props.handleChange(e, 'trasporter_gstin')
                                        }}
                                      /> */}

                                    <Select
                                      style={{ width: '100%' }}
                                      mode="tags"
                                      onChange={e => {
                                        this.onChangeTransporterId(e)
                                      }}
                                      value={this.state.selectedTransporter}
                                      placeholder={
                                        <>
                                          <span className="text-gray-400">
                                            Enter Transporter ID or Select from Below
                                          </span>
                                        </>
                                      }
                                      maxTagCount={1}
                                      optionFilterProp="children"
                                      filterOption={(input, option) =>
                                        option.props.children
                                          .toLowerCase()
                                          .indexOf(input.toLowerCase()) >= 0
                                      }
                                    >
                                      {this.state.transporter_ids.map((item, i) => (
                                        <Option key={i} value={item.name}>
                                          {item.name}
                                        </Option>
                                      ))}
                                    </Select>
                                  </span>
                                  {!this.props.is_einvoice && (
                                    <p className="text-gray-500 font-size-12 ml-1 mb-0">
                                      If you do not have PART-B (Vehicle Number / Mode of
                                      transportation), fill only Transporter ID and create the
                                      Eway-Bill.
                                    </p>
                                  )}
                                </div>

                                <div className="col-12 col-md-6 mb-4">
                                  <h6 className="text-gray-500">Transporter Name (Optional)</h6>
                                  <span className="d-inline-flex" style={{ width: '100%' }}>
                                    <Input
                                      placeholder="Name of Transporter"
                                      value={this.props.transporterName}
                                      onChange={e => {
                                        this.props.handleChange(e, 'transporter_name')
                                      }}
                                    />
                                  </span>
                                </div>
                              </div>
                              <Divider />
                            </div>
                          )}
                          {this.state.section != '2' && (
                            <div>
                              <h6 className="font-size-14 text-gray-400">PART-B</h6>
                              {!this.props.is_einvoice && (
                                <p className="text-gray-500 font-size-12 ml-1 mb-2">
                                  If you do not have PART-A (Transporter ID), Fill the below details
                                  and create the Eway-Bill.
                                </p>
                              )}

                              <div className="row">
                                <div className="col-12 col-md-6 mb-4">
                                  <h6>Vehicle Number</h6>
                                  <span className="d-inline-flex w-100">
                                    {/* <Input
                                        placeholder="Vehicle Number like KA99MH9999"
                                        value={this.props.vehicleNumber}
                                        onChange={e => {
                                          this.props.handleChange(e, 'vehicle_number')
                                        }}
                                      /> */}

                                    <Select
                                      style={{ width: '100%' }}
                                      mode="tags"
                                      onChange={e => {
                                        let vehicle_no = e[e.length - 1]
                                        this.setState({
                                          selectedVehicleNo: vehicle_no,
                                        })
                                        this.props.handleChange(vehicle_no, 'vehicle_number')
                                      }}
                                      // value={this.props.vehicleNumber}
                                      placeholder={
                                        <>
                                          <span className="text-gray-400">
                                            Enter Vehicle Number like KA99MH9999 or Select from
                                            Below
                                          </span>
                                        </>
                                      }
                                      value={this.state.selectedVehicleNo}
                                      maxTagCount={1}
                                      optionFilterProp="children"
                                      filterOption={(input, option) =>
                                        option.props.children
                                          .toLowerCase()
                                          .indexOf(input.toLowerCase()) >= 0
                                      }
                                    >
                                      {this.state.vehicle_nos.map((item, i) => (
                                        <Option key={i} value={item.name}>
                                          {item.name}
                                        </Option>
                                      ))}
                                    </Select>
                                  </span>
                                </div>

                                <div className="col-12 col-md-6 mb-4">
                                  <h6>
                                    Approx. Distance in KMS
                                    <a
                                      href="https://ewaybillgst.gov.in/Others/P2PDistance.aspx"
                                      target="_blank"
                                      className="ml-2 font-size-12 font-weight-bold"
                                      style={{ color: '#276ef1' }}
                                    >
                                      Use Distance Calculator
                                    </a>
                                  </h6>
                                  <span className="w-100">
                                    <Input
                                      placeholder={this.props.approx_distance + ' KMS in approx. '}
                                      type={'number'}
                                      value={this.props.distance}
                                      onChange={e => {
                                        this.props.handleChange(e, 'distance')
                                      }}
                                    />
                                  </span>
                                  <p className="text-gray-500 font-size-12 ml-1 mb-0">
                                    Leave blank for auto calculation by govt portal.
                                  </p>
                                </div>

                                <div className="col-12 col-md-6 mb-4">
                                  <h6 className="text-gray-500">Transport Mode</h6>
                                  {this.props.transportMode.length && (
                                    <Select
                                      defaultValue={this.props.transportMode[0]['mode']}
                                      style={{ width: '100%' }}
                                      onChange={e => {
                                        this.props.handleChange(e, 'transport_mode')
                                      }}
                                      value={this.props.selectedTransportMode}
                                    >
                                      {this.props.transportMode.map((item, i) => (
                                        <Option key={i} value={item.value}>
                                          {item.mode}
                                        </Option>
                                      ))}
                                    </Select>
                                  )}
                                </div>

                                <div className="col-12 col-md-6 mb-4">
                                  <h6 className="text-gray-500">Transporter Doc No. (Optional)</h6>
                                  <span className="d-inline-flex" style={{ width: '100%' }}>
                                    <Input
                                      placeholder="Ex. 12345678 "
                                      value={this.props.transDocNo}
                                      onChange={e => {
                                        this.props.handleChange(e, 'transporter_doc_no')
                                      }}
                                    />
                                  </span>
                                </div>

                                <div className="col-6 col-md-6 mb-4">
                                  <h6 className="text-gray-500">Transporter Doc Date</h6>
                                  <DatePicker
                                    style={{
                                      borderRadius: '5px',
                                      cursor: 'pointer',
                                      width: '100%',
                                    }}
                                    allowClear={false}
                                    format={this.dateFormat}
                                    value={dayjs(this.props.transDocDate, this.dateFormat)}
                                    onChange={this.props.onTransporterDateChange}
                                  />
                                </div>
                              </div>
                            </div>
                          )}
                          <div className="row">
                            <div className="col-12 col-md-12">
                              <a
                                className="text-primary font-weight-bold"
                                onClick={() => {
                                  message.loading({
                                    content: 'Verifying HSN Codes...',
                                    duration: 0,
                                  })
                                  this.verifyHSNCodes()
                                }}
                              >
                                Verify HSN Codes
                              </a>
                              <ProductsList
                                data={this.props.dataSource}
                                extraDiscount={this.props.extraDiscount}
                                document_custom_additional_charges={
                                  this.props.document_custom_additional_charges
                                }
                                document={this.props.data.invoice_details}
                                refresh={() => this.props.getEwayBillData()}
                              />
                            </div>
                          </div>
                        </Card>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              {/*<Affix offsetBottom={0}>
                <div className="text-right bg-white">
                  <div
                    className="row pt-4 pb-3 d-flex justify-content-end align-items-center"
                    style={{ borderTop: '1px solid #E3E8EE' }}
                  >
                    <div>
                      <p className="text-danger font-weight-bold font-weight-14 mr-3 mt-3">
                        *Read the notes carefully before creating Eway Bill.
                      </p>
                    </div>
                    <div className="">
                      <Button
                        loading={this.props.loadingSave}
                        onClick={() => this.props.save()}
                        type="primary"
                        size="default"
                        className="mr-2 font-weight-bold"
                      >
                        Create EwayBill
                      </Button>
                    </div>
                  </div>
                </div>
              </Affix>*/}
              <EnhancedAddresses
                onRef={ref => (this.address = ref)}
                onAddressChange={addr_id => this.onAddressChange(addr_id)}
                onAPICalled={() => this.props.getEwayBillData()}
                dispatch={this.props.dispatch}
              />
            </>
          )}
        </Modal>
        <EnhancedNICCreds onRef={ref => (this.nic_creds = ref)} />
        <EditCompany
          onRef={ref => (this.EditCompanyForm = ref)}
          dispatch={this.props.dispatch}
          onAPICalled={() => this.props.getEwayBillData()}
        />
        <EnhancedFrom
          onRef={ref => (this.customer = ref)}
          onAPICalled={(a, b, c) => this.props.getEwayBillData()}
        />
        <AddressForm ref={this.shippingAddressRef} onFinish={this.onAddressFinish} />
      </div>
    )
  }
}

export class EnhancedCreateEwayBillModal extends React.Component {
  constructor(props) {
    super(props)
  }
  render() {
    return <CreateEwayBill {...this.props} />
  }
}
