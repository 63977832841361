import { Text, View } from '@react-pdf/renderer'
import { getTextColor } from 'components/other/utility'
import { HIDE_PRICES } from '../../../utils/constants'
import { styles as getStyles } from './styles'

const borderColor = '#142328'

const InvoiceTableHeader = ({ invoice, type }) => {
  var styles = getStyles(invoice)
  var FONT_FACTOR = invoice.invoice_settings.font_size
  const hide_hsn = invoice.invoice_settings.hide_hsn
  let no_of_default_cols = invoice.invoice_settings.hide_qty ? 6 : 7
  no_of_default_cols = hide_hsn ? no_of_default_cols - 0.8 : no_of_default_cols
  let no_of_with_out_tax_cols = invoice.invoice_settings.hide_qty ? 5 : 6
  no_of_with_out_tax_cols = hide_hsn ? no_of_with_out_tax_cols - 1 : no_of_with_out_tax_cols
  var single_col = (1 * 100) / (no_of_default_cols + invoice.custom_col_names.length)
  var single_col2 = (1 * 100) / (no_of_with_out_tax_cols + invoice.custom_col_names.length)
  var dc_col = (1 * 100) / (invoice.invoice_settings.hide_qty ? 2 : 3)
  var font_size = (FONT_FACTOR * (9 * 8)) / (8 + invoice.custom_col_names.length)
  const column_labels = invoice.column_labels

  const rows = (
    <>
      {(type == 'Delivery Challan' || type == HIDE_PRICES) && (
        <>
          <View style={styles.invoiceTableHeader_container}>
            <Text style={{ ...styles.invoiceTableHeader_slno_dc, width: `${0.2 * dc_col}%` }}>
              {column_labels.id}
            </Text>
            <Text
              style={{ ...styles.invoiceTableHeader_description_dc, width: `${1.8 * dc_col}%` }}
            >
              {column_labels.product_name}
            </Text>
            {invoice.invoice_settings.hide_qty == 0 && (
              <Text style={{ ...styles.invoiceTableHeader_qty_dc, width: `${dc_col}%` }}>Qty</Text>
            )}
          </View>
        </>
      )}

      {type != 'Delivery Challan' && type != HIDE_PRICES && (
        <>
          {(invoice.with_tax == 0 || invoice.company.gstin.length < 15) && (
            <View
              style={{
                ...styles.invoiceTableHeader_container,
                borderBottomColor: invoice.company.color,
                backgroundColor: invoice.company.color,
                color: getTextColor(invoice.company.color),
              }}
            >
              <Text style={{ paddingLeft: 4.5, textAlign: 'left', width: `${0.3 * single_col2}%` }}>
                {column_labels.id}
              </Text>
              <Text style={{ textAlign: 'left', width: `${1.7 * single_col2}%` }}>
                {column_labels.product_name}
              </Text>
              {invoice.custom_col_names.map((item, index) => (
                <Text key={index} style={{ width: `${single_col2}%` }}>
                  {item}
                </Text>
              ))}
              {!hide_hsn && (
                <Text style={{ width: `${single_col2}%` }}>
                  {invoice.invoice_settings.labels.hsn_code}
                </Text>
              )}
              <Text style={{ width: `${single_col2}%` }}>{column_labels.unit_price}</Text>
              {invoice.invoice_settings.hide_qty == 0 && (
                <Text style={{ width: `${single_col2}%` }}>{column_labels.qty}</Text>
              )}
              <Text style={{ paddingRight: 4.5, width: `${single_col2}%` }}>
                {column_labels.total_amount}
              </Text>
            </View>
          )}

          {invoice.with_tax == 1 && invoice.company.gstin.length == 15 && (
            <View
              style={{
                ...styles.invoiceTableHeader_container,
                fontSize: font_size,
                borderBottomColor: invoice.company.color,
                backgroundColor: invoice.company.color,
                color: getTextColor(invoice.company.color),
              }}
            >
              <Text style={{ paddingLeft: 4.5, textAlign: 'left', width: `${0.5 * single_col}%` }}>
                {column_labels.id}
              </Text>
              <Text style={{ textAlign: 'left', width: `${2.4 * single_col}%` }}>
                {column_labels.product_name}
              </Text>
              {invoice.custom_col_names.map((item, index) => (
                <Text key={index} style={{ width: `${single_col}%` }}>
                  {item}
                </Text>
              ))}
              {!hide_hsn && (
                <Text style={{ width: `${0.8 * single_col}%` }}>
                  {invoice.invoice_settings.labels.hsn_code}
                </Text>
              )}
              <Text style={{ width: `${1.1 * single_col}%` }}>{column_labels.unit_price}</Text>
              {invoice.invoice_settings.hide_qty == 0 && (
                <Text style={{ width: `${0.8 * single_col}%` }}>{column_labels.qty}</Text>
              )}
              <Text style={{ paddingRight: 4.5, width: `${1.4 * single_col}%` }}>
                {column_labels.total_amount}
              </Text>
              {/*<Text style={{ ...styles.invoiceTableHeader_taxAmount, width: `${1.1 * single_col}%` }}>
                    Tax Amount
                  </Text>
                  <Text style={{ ...styles.invoiceTableHeader_totalAmount, width: `${single_col}%` }}>Amount</Text>*/}
            </View>
          )}
        </>
      )}
    </>
  )
  return <View fixed>{rows}</View>
}

export default InvoiceTableHeader
