import { Button, Card, Checkbox, Drawer, Form, Input, InputNumber, Select, Space } from 'antd'
import TextArea from 'antd/lib/input/TextArea'
import axios from 'axios'
import SubscriptionPlanModal from 'components/modal/subscription/subscriptionPlan'
import React, {
  createRef,
  forwardRef,
  useEffect,
  useImperativeHandle,
  useRef,
  useState,
} from 'react'
import { isMobile } from 'react-device-detect'
import { useDispatch, useSelector } from 'react-redux'
import { select } from 'redux-saga/effects'
import store from 'store'
import { getCountryInfo } from 'redux/countries/actions'
import { FilterFilled } from '@ant-design/icons'
import { getPincodeDetails } from 'components/other/utility'

const AddressForm = forwardRef((props, ref) => {
  const countries = useSelector(state => state.document.countries)
  const states = useSelector(state => state.document.states)
  const country_info = useSelector(state => state.countries.info)
  const user = useSelector(state => state.user)
  const dispatch = useDispatch()

  const formItemLayout = {
    layout: 'vertical',
    labelCol: {
      span: 24,
    },
    wrapperCol: {
      span: 24,
    },
  }
  const formAdressRef = useRef()
  const subscriptionPlanRef = createRef()

  const [dataItems, setDataItems] = useState({
    export_customer: false,
    addressDrawerVisible: true,
  })
  const [loading, setLoading] = useState(false)
  const [addressDetails, setAddressDetails] = useState({
    addr_id: -1,
  })
  const [addressType, setAddressType] = useState('Shipping')
  const [partyId, setPartyId] = useState(-1)
  const [visible, setVisible] = useState(false)
  const onCloseAdressDrawer = () => {
    setAddressDetails({ addr_id: -1 })
    setPartyId(-1)
    setVisible(false)
  }
  const [isCustomerForm, setIsCustomerForm] = useState(false)
  const [isexportCustomer, setIsExportCustomer] = useState(false)
  const [selected_country_code, setSelectedCountryCode] = useState(
    user?.selectedCompany?.country_code,
  )

  const showAddressDrawer = (
    party_id,
    address_type,
    selectedAddress,
    export_customer = false,
    isCustomerForm = false,
  ) => {
    const current_country =
      selectedAddress.addr_id == -1 ? user?.selectedCompany?.country : selectedAddress.country
    const address = {
      ...selectedAddress,
      state1: selectedAddress.state ? selectedAddress.state : undefined,
      country: current_country,
    }

    setAddressDetails(address)
    setAddressType(address_type)
    setPartyId(party_id)
    setVisible(true)
    // setIsExportCustomer(export_customer)
    setIsExportCustomer(current_country != user?.selectedCompany?.country)
    setIsCustomerForm(isCustomerForm)

    // load states according to selected country
    var filteredCountry = countries?.filter(item => item.name == current_country)
    var filteredCountryCode = user?.selectedCompany?.country_code
    if (filteredCountry.length > 0) {
      filteredCountryCode = filteredCountry[0].code
    }
    setSelectedCountryCode(filteredCountryCode)
    dispatch(
      getCountryInfo({
        countryCode: filteredCountryCode,
      }),
    )
  }

  const onClose = () => {
    setAddressDetails({ addr_id: -1 })
    setPartyId(-1)
    setVisible(false)
  }

  useImperativeHandle(ref, () => ({
    showAddressDrawer,
    onClose,
  }))

  const onWritingPincode = async value => {
    if (selected_country_code != 'IN') return

    const [success, data] = await getPincodeDetails(value, states, countries)

    if (success) {
      formAdressRef.current.setFieldsValue({
        state1: data.state,
        city: data.city,
        country: data.country,
      })
    }
  }

  const onSelectCountry = countryCode => {
    if (store.get('is_export') != 1 && countryCode != user?.selectedCompany?.country_code) {
      subscriptionPlanRef.current.openModal('export')
      var item = countries?.filter((item, i) => item.code == selected_country_code)
      formAdressRef.current.setFieldsValue({
        country: item[0].name,
      })
      return
    }

    if (countryCode != selected_country_code) {
      formAdressRef.current.setFieldsValue({
        state1: undefined,
      })
    }

    var payload = {
      country_code: countryCode,
    }

    setIsExportCustomer(user?.selectedCompany?.country_code != countryCode)
    if (user?.selectedCompany?.country_code == 'IN') {
      if (user?.selectedCompany?.country_code != countryCode) {
        formAdressRef.current.setFieldsValue({
          state1: '97-OTHER TERRITORY',
        })
      }
    } else {
      setSelectedCountryCode(countryCode)
      dispatch(getCountryInfo(payload))
    }
  }

  return (
    <Drawer
      title={
        `${addressDetails.addr_id != -1 ? 'Update' : 'Add'} ${addressType
          .charAt(0)
          .toUpperCase()}` + `${addressType.slice(1)} Address`
      }
      width={isMobile ? '100%' : '50%'}
      height={isMobile ? '90%' : '100%'}
      placement={isMobile ? 'top' : 'right'}
      maskClosable={false}
      closable={true}
      closeIcon={<i className="fa-solid fa-lg fa-xmark"></i>}
      onClose={onCloseAdressDrawer}
      open={visible}
      bodyStyle={{ paddingBottom: 80 }}
      destroyOnClose={true}
      extra={
        <Space>
          <Button
            onClick={() => formAdressRef.current.submit()}
            type="primary"
            loading={loading}
            className="font-weight-bold"
          >
            Save and Update
          </Button>
        </Space>
      }
      footer={
        <>
          <Button
            onClick={() => formAdressRef.current.submit()}
            type="primary"
            loading={loading}
            className="font-weight-bold"
          >
            Save and Update
          </Button>
        </>
      }
    >
      <Form
        {...formItemLayout}
        labelAlign="left"
        ref={formAdressRef}
        name="customerForm"
        onFinish={values => {
          setLoading(true),
            props.onFinish({ ...values }, addressType, addressDetails.addr_id, isexportCustomer),
            setLoading(false)
        }}
        initialValues={{
          ...addressDetails,
        }}
        size="default"
      >
        {/* {isCustomerForm && (
          <div className="d-flex justify-content-end mb-2">
            <Checkbox
              // disabled={}
              name="export_customer"
              checked={isexportCustomer}
              onChange={e => {
                if (store.get('is_export') != 1) {
                  subscriptionPlanRef.current.openModal('export')
                  return
                }
                if (e.target.checked) {
                  formAdressRef.current.setFieldsValue({
                    state1: '97-OTHER TERRITORY',
                  })
                } else {
                  formAdressRef.current.setFieldsValue({
                    state1: undefined,
                  })
                }
                setIsExportCustomer(prev => !prev)
                // props.onChangeExportCustomer();
              }}
              className="float-right"
            >
              <span className="">Export?</span>
            </Checkbox>
          </div>
        )} */}

        <Card bordered={false} size="form" className="border-radius-small mb-4">
          <div>
            {user?.selectedCompany?.is_export && (
              <Form.Item name={'country'} label="Country" className="pb-2">
                <Select
                  showSearch
                  placeholder="Select Country"
                  suffixIcon={<i className="fa-regular fa-chevron-down"></i>}
                  optionFilterProp="children"
                  style={{ width: '100%' }}
                  filterOption={(input, option) =>
                    option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                  }
                  onSelect={(value, option) => {
                    onSelectCountry(option.code)
                  }}
                  value={selected_country_code}
                >
                  {countries?.map((item, i) => (
                    <Option key={i} value={item.name} code={item.code}>
                      {item.name}
                    </Option>
                  ))}
                </Select>
              </Form.Item>
            )}

            {addressType == 'shipping' && (
              <Form.Item
                name={'title'}
                label="Title"
                extra={
                  formAdressRef.current?.getFieldsValue()['company_name'] != '' && (
                    <span
                      onClick={() => {
                        formAdressRef?.current?.setFieldsValue({
                          title: formAdressRef.current?.getFieldsValue()['company_name'],
                        })
                      }}
                      className="cursor-pointer pl-2"
                    >
                      Auto fill company name
                    </span>
                  )
                }
              >
                <Input placeholder="Title" />
              </Form.Item>
            )}

            <Form.Item
              name={'line1'}
              label="Address Line 1"
              className=""
              rules={[{ required: true, message: 'Fill Address Line 1' }]}
            >
              <Input placeholder="Address Line 1" />
            </Form.Item>

            <Form.Item name={'line2'} label="Address Line 2">
              <Input placeholder="Address Line 2" />
            </Form.Item>

            <Form.Item name={'pincode'} label={user?.selectedCompany?.labels?.pincode}>
              <InputNumber
                placeholder={user?.selectedCompany?.labels?.pincode}
                style={{ width: '100%' }}
                maxLength={selected_country_code == 'IN' ? 6 : 20}
                onChange={onWritingPincode}
              />
            </Form.Item>

            <Form.Item name={'city'} label={'City'}>
              <Input placeholder={isexportCustomer ? 'eg. San Fransisco, California' : 'City'} />
            </Form.Item>

            <Form.Item
              name={'state1'}
              label={user?.selectedCompany?.labels?.state}
              extra={
                addressType == 'billing' && selected_country_code == 'IN'
                  ? 'Billing State (like 36-Telangana) is responsible for deciding CGST + SGST/UTGST or IGST calculation on the invoice. Please ignore this, if you do not have GST.'
                  : ''
              }
              rules={[{ required: selected_country_code == 'IN', message: 'State is required' }]}
            >
              <Select
                rules={[
                  {
                    required: true,
                    message: user?.selectedCompany?.labels?.state + ' is required',
                  },
                ]}
                suffixIcon={<i className="fa-regular fa-chevron-down"></i>}
                showSearch
                placeholder={`Select ${user?.selectedCompany?.labels?.state}`}
                optionFilterProp="children"
                style={{ width: '100%' }}
                filterOption={(input, option) =>
                  option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                }
                disabled={user?.selectedCompany?.country_code == 'IN' && isexportCustomer}
              >
                {country_info?.[selected_country_code]?.states?.map((item, i) => (
                  <Option key={i} value={item.name}>
                    {item.name}
                  </Option>
                ))}
              </Select>
            </Form.Item>

            {addressType == 'shipping' && (
              <Form.Item label="Notes" name={'notes'}>
                <TextArea
                  rows={4}
                  placeholder="Add Notes, Contact Person Details,etc..."
                  maxLength={200}
                />
              </Form.Item>
            )}
          </div>
        </Card>
      </Form>

      <SubscriptionPlanModal ref={subscriptionPlanRef} />
    </Drawer>
  )
})
export default AddressForm
