import { Text, View } from '@react-pdf/renderer'
import { Fragment } from 'react'
import { HIDE_PRICES } from '../../../utils/constants'
import { styles as getStyles } from './styles'

const borderColor = '#276EF1'

const InvoiceTableBlankSpace = ({ rowsCount, invoice, type }) => {
  var styles = getStyles(invoice)
  var FONT_FACTOR = invoice.invoice_settings.font_size
  //var single_col = ((1 * 100) / (9 + invoice.custom_col_names.length)).toFixed(2)
  let no_of_default_cols = invoice.invoice_settings.hide_qty ? 9 : 10
  const hide_hsn = invoice.invoice_settings.hide_hsn
  no_of_default_cols = hide_hsn ? no_of_default_cols - 0.85 : no_of_default_cols
  var single_col
  if (invoice.tax_type == 'CGST') {
    single_col = ((1 * 100) / (no_of_default_cols + invoice.custom_col_names.length)).toFixed(2)
  } else {
    single_col = ((1 * 100) / (no_of_default_cols - 1 + invoice.custom_col_names.length)).toFixed(2)
  }

  let no_of_default_cols2 = invoice.invoice_settings.hide_qty ? 5 : 6
  no_of_default_cols2 = hide_hsn ? no_of_default_cols2 - 0.85 : no_of_default_cols2

  var single_col2 = (1 * 100) / (no_of_default_cols2 + invoice.custom_col_names.length)
  var font_size = (FONT_FACTOR * (9 * 9)) / (10 + invoice.custom_col_names.length)
  var font_size2 = (FONT_FACTOR * (9 * 15)) / (16 + invoice.custom_col_names.length)
  const blankRows = rowsCount > 0 ? Array(rowsCount).fill(0) : []
  const rows = blankRows.map((x, i) => (
    <Fragment key={i}>
      {type != 'Delivery Challan' && type != HIDE_PRICES && (
        <>
          {(invoice.with_tax == 0 || invoice.company.gstin.length < 15) && (
            <View style={{ ...styles.invoiceTableBlankSpace_row, borderBottomColor: '#1d1d1f' }}>
              <Text
                style={{
                  ...styles.invoiceTableBlankSpace_cell,
                  textAlign: 'right',
                  width: `${0.2 * single_col2}%`,
                }}
              >
                {'  '}
              </Text>

              <Text
                style={{
                  ...styles.invoiceTableBlankSpace_cell,
                  textAlign: 'right',
                  width: `${1.95 * single_col2}%`,
                }}
              >
                {'  '}
              </Text>

              {!hide_hsn && (
                <Text
                  style={{
                    ...styles.invoiceTableBlankSpace_cell,
                    textAlign: 'right',
                    width: `${0.85 * single_col2}%`,
                  }}
                >
                  {'  '}
                </Text>
              )}

              {invoice.custom_col_names.map((item, index) => (
                <Text
                  key={index}
                  style={{
                    ...styles.invoiceTableBlankSpace_cell,
                    textAlign: 'right',
                    width: `${single_col2}%`,
                  }}
                >
                  {'  '}
                </Text>
              ))}

              <Text
                style={{
                  ...styles.invoiceTableBlankSpace_cell,
                  textAlign: 'right',
                  width: `${single_col2}%`,
                }}
              >
                {'  '}
              </Text>

              {invoice.invoice_settings.hide_qty == 0 && (
                <Text
                  style={{
                    ...styles.invoiceTableBlankSpace_cell,
                    textAlign: 'right',
                    width: `${single_col2}%`,
                  }}
                >
                  {'  '}
                </Text>
              )}

              <Text
                style={{
                  ...styles.invoiceTableBlankSpace_cell,
                  textAlign: 'right',
                  borderRight: 'none',
                  width: `${single_col2}%`,
                }}
              >
                {'  '}
              </Text>
            </View>
          )}

          {invoice.with_tax == 1 && invoice.company.gstin.length == 15 && (
            <View
              style={{
                ...styles.invoiceTableBlankSpace_row,
                borderBottomColor: '#1d1d1f',
              }}
            >
              <Text
                style={{
                  ...styles.invoiceTableBlankSpace_cell,
                  textAlign: 'left',
                  width: `${0.4 * single_col}%`,
                }}
              >
                {'  '}
              </Text>

              <Text
                style={{
                  ...styles.invoiceTableBlankSpace_cell,
                  textAlign: 'left',
                  width: `${2.6 * single_col}%`,
                }}
              >
                {'  '}
              </Text>

              {invoice.custom_col_names.map((item, index) => (
                <Text
                  key={index}
                  style={{ ...styles.invoiceTableBlankSpace_cell, width: `${single_col}%` }}
                >
                  {'  '}
                </Text>
              ))}

              {!hide_hsn && (
                <Text
                  style={{
                    ...styles.invoiceTableBlankSpace_cell,
                    textAlign: 'right',
                    width: `${0.85 * single_col}%`,
                  }}
                >
                  {'  '}
                </Text>
              )}

              <Text
                style={{
                  ...styles.invoiceTableBlankSpace_cell,
                  textAlign: 'right',
                  width: `${1.15 * single_col}%`,
                }}
              >
                {'  '}
              </Text>

              {invoice.invoice_settings.hide_qty == 0 && (
                <Text
                  style={{
                    ...styles.invoiceTableBlankSpace_cell,
                    textAlign: 'right',
                    width: `${0.9 * single_col}%`,
                  }}
                >
                  {'  '}
                </Text>
              )}

              <Text
                style={{
                  ...styles.invoiceTableBlankSpace_cell,
                  textAlign: 'right',
                  width: `${single_col}%`,
                }}
              >
                {'  '}
              </Text>

              {/* <Text
                style={{
                  ...styles.invoiceTableBlankSpace_cell,
                  textAlign: 'right',
                  width: `${1.1 * single_col}%`,
                }}
              >
                {'  '}
              </Text> */}
              {invoice.tax_type == 'CGST' ? (
                <>
                  <Text
                    style={{
                      ...styles.invoiceTableHeader_cell,
                      textAlign: 'right',
                      fontSize: font_size,
                      width: `${1.1 * single_col}%`,
                    }}
                  >
                    {'  '}
                  </Text>
                  <Text
                    style={{
                      ...styles.invoiceTableHeader_cell,
                      textAlign: 'right',
                      fontSize: font_size,
                      width: `${1.1 * single_col}%`,
                    }}
                  >
                    {'  '}
                  </Text>
                </>
              ) : (
                <Text
                  style={{
                    ...styles.invoiceTableHeader_cell,
                    fontSize: font_size,
                    textAlign: 'right',
                    width: `${1.1 * single_col}%`,
                  }}
                >
                  {'  '}
                </Text>
              )}
              <Text
                style={{
                  ...styles.invoiceTableBlankSpace_cell,
                  textAlign: 'right',
                  paddingRight: 4.5,
                  borderRight: 'none',
                  width: `${single_col}%`,
                }}
              >
                {'  '}
              </Text>
            </View>
          )}
        </>
      )}
    </Fragment>
  ))
  return (
    <Fragment>
      {rows}
      <View style={styles.invoiceTableBlankSpace_block2}></View>
    </Fragment>
  )
}

export default InvoiceTableBlankSpace
