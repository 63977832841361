import { Text, View } from '@react-pdf/renderer'
import { extraChargesAmount } from 'components/other/calculation'
import { Fragment } from 'react'
import { HIDE_PRICES } from '../../../utils/constants'
import { styles as getStyles } from './styles'

const borderColor = '#276EF1'

const InvoiceTableBlankSpace = ({ rowsCount, invoice, type, items, netAmount, total }) => {
  var styles = getStyles(invoice)
  var FONT_FACTOR = invoice.invoice_settings.font_size
  const options = { maximumFractionDigits: 2, minimumFractionDigits: 2 }
  const tax_options = { maximumFractionDigits: 2, minimumFractionDigits: 2 }
  const hide_hsn = invoice.invoice_settings.hide_hsn
  let no_of_default_cols = invoice.invoice_settings.hide_qty ? 7.1 : 8
  no_of_default_cols = hide_hsn ? no_of_default_cols - 0.8 : no_of_default_cols
  var single_col = (1 * 100) / (no_of_default_cols + invoice.custom_col_names.length)

  var font_size = (FONT_FACTOR * (9 * 9)) / (9 + invoice.custom_col_names.length)
  const blankRows = rowsCount > 0 ? Array(rowsCount).fill(0) : []
  let conversion_factor = 1
  let currency_symbol = invoice.company.currency_symbol
  const is_export = invoice.is_export == 1
  if (
    (invoice.is_export == 1 || invoice.is_multi_currency == 1) &&
    invoice.invoice_settings.show_both_currencies != true
  ) {
    currency_symbol = invoice.export_details[0].currency_symbol
    conversion_factor = invoice.export_details[0].conversion_factor
  }
  const rows = blankRows.map((x, i) => (
    <Fragment key={i}>
      {type != 'Delivery Challan' && type != HIDE_PRICES && (
        <View fixed style={styles.invoiceTableBlankSpace_row}>
          <Text
            style={{
              ...styles.invoiceTableBlankSpace_cell,
              textAlign: 'left',
              fontSize: font_size,
              width: `${0.3 * single_col}%`,
            }}
          >
            {'  '}
          </Text>

          <Text
            style={{
              ...styles.invoiceTableBlankSpace_cell,
              fontSize: font_size,
              textAlign: 'left',
              width: `${3.1 * single_col}%`,
            }}
          >
            {'  '}
          </Text>

          {invoice.custom_col_names.map((item, index) => (
            <Text
              key={index}
              style={{
                ...styles.invoiceTableBlankSpace_cell,
                width: `${single_col}%`,
                fontSize: font_size,
              }}
            >
              {'  '}
            </Text>
          ))}

          {!hide_hsn && (
            <Text
              style={{
                ...styles.invoiceTableBlankSpace_cell,
                textAlign: 'right',
                fontSize: font_size,
                width: `${0.8 * single_col}%`,
              }}
            >
              {'  '}
            </Text>
          )}

          <Text
            style={{
              ...styles.invoiceTableBlankSpace_cell,
              textAlign: 'right',
              fontSize: font_size,
              width: `${0.4 * single_col}%`,
            }}
          >
            {'  '}
          </Text>
          {invoice.invoice_settings.hide_qty == 0 && (
            <Text
              style={{
                ...styles.invoiceTableBlankSpace_cell,
                textAlign: 'right',
                fontSize: font_size,
                width: `${0.9 * single_col}%`,
              }}
            >
              {'  '}
            </Text>
          )}
          <Text
            style={{
              ...styles.invoiceTableBlankSpace_cell,
              textAlign: 'right',
              fontSize: font_size,
              width: `${single_col}%`,
            }}
          >
            {'  '}
          </Text>

          <Text
            style={{
              ...styles.invoiceTableBlankSpace_cell,
              textAlign: 'right',
              fontSize: font_size,
              width: `${0.4 * single_col}%`,
            }}
          >
            {'  '}
          </Text>

          {/*  <Text
            style={{
              ...styles.invoiceTableBlankSpace_cell,
              fontSize: font_size,
              textAlign: 'right',
              width: `${1.1 * single_col}%`,
            }}
          >
            {'  '}
          </Text>*/}

          <Text
            style={{
              ...styles.invoiceTableBlankSpace_cell,
              textAlign: 'right',
              paddingRight: 4.5,
              borderRight: 'none',
              fontSize: font_size,
              width: `${1.1 * single_col}%`,
            }}
          >
            {'  '}
          </Text>
        </View>
      )}
    </Fragment>
  ))

  let taxRows = items.map(
    (item, i) =>
      invoice.company.gstin.length == 15 &&
      item.tax_amount != 0 &&
      invoice.with_tax != 0 &&
      invoice.hide_totals == 0 && (
        <Fragment key={i}>
          {type != 'Delivery Challan' && type != HIDE_PRICES && (
            <View
              style={{
                ...styles.invoiceTableBlankSpace_row,
                fontSize: font_size,
                borderBottomColor: '#1d1d1f',
              }}
            >
              <Text
                style={{
                  ...styles.invoiceTableBlankSpace_cell,
                  textAlign: 'left',
                  fontSize: font_size,
                  width: `${0.3 * single_col}%`,
                }}
              >
                {'  '}
              </Text>

              <Text
                style={{
                  ...styles.invoiceTableBlankSpace_cell,
                  ...styles.invoiceTableBlankSpace_italic,
                  fontSize: font_size,
                  textAlign: 'right',
                  width: `${3.1 * single_col}%`,
                }}
              >
                <Text>
                  {invoice.rcm == 1 && '*'}
                  {item.tax_type}
                  {'\u00A0'}
                  {item.tax_percent}
                </Text>
              </Text>

              {invoice.custom_col_names.map((item, index) => (
                <Text
                  key={index}
                  style={{
                    ...styles.invoiceTableBlankSpace_cell,
                    width: `${single_col}%`,
                    fontSize: font_size,
                  }}
                >
                  {'  '}
                </Text>
              ))}

              {!hide_hsn && (
                <Text
                  style={{
                    ...styles.invoiceTableBlankSpace_cell,
                    textAlign: 'right',
                    fontSize: font_size,
                    width: `${0.8 * single_col}%`,
                  }}
                >
                  {'  '}
                </Text>
              )}

              <Text
                style={{
                  ...styles.invoiceTableBlankSpace_cell,
                  textAlign: 'right',
                  fontSize: font_size,
                  width: `${0.4 * single_col}%`,
                }}
              >
                {'  '}
              </Text>
              {invoice.invoice_settings.hide_qty == 0 && (
                <Text
                  style={{
                    ...styles.invoiceTableBlankSpace_cell,
                    textAlign: 'right',
                    fontSize: font_size,
                    width: `${0.9 * single_col}%`,
                  }}
                >
                  {'  '}
                </Text>
              )}
              <Text
                style={{
                  ...styles.invoiceTableBlankSpace_cell,
                  textAlign: 'right',
                  fontSize: font_size,
                  width: `${1 * single_col}%`,
                }}
              >
                {'  '}
              </Text>

              <Text
                style={{
                  ...styles.invoiceTableBlankSpace_cell,
                  textAlign: 'right',
                  fontSize: font_size,
                  width: `${0.4 * single_col}%`,
                }}
              >
                {'  '}
              </Text>

              <Text
                style={{
                  ...styles.invoiceTableBlankSpace_cell,
                  textAlign: 'right',
                  paddingRight: 4.5,
                  borderRight: 'none',
                  fontSize: font_size,
                  width: `${1.1 * single_col}%`,
                }}
              >
                <Text
                  style={[styles.invoiceTableBlankSpace_total, styles.invoiceTableBlankSpace_bold]}
                >
                  {/*{currency_symbol}*/}
                  {(item.tax_amount / conversion_factor).toLocaleString('en-IN', tax_options)}
                </Text>
              </Text>
            </View>
          )}
        </Fragment>
      ),
  )
  {
    invoice.company.gstin.length == 15 &&
      invoice.cess_amount - invoice.cess_on_qty_value > 0 &&
      invoice.with_tax != 0 &&
      taxRows.push(
        <Fragment>
          {type != 'Delivery Challan' && type != HIDE_PRICES && (
            <View
              style={{
                ...styles.invoiceTableBlankSpace_row,
                fontSize: font_size,
                borderBottomColor: '#1d1d1f',
              }}
            >
              <Text
                style={{
                  ...styles.invoiceTableBlankSpace_cell,
                  textAlign: 'left',
                  fontSize: font_size,
                  width: `${0.3 * single_col}%`,
                }}
              >
                {'  '}
              </Text>

              <Text
                style={{
                  ...styles.invoiceTableBlankSpace_cell,
                  ...styles.invoiceTableBlankSpace_italic,
                  fontSize: font_size,
                  textAlign: 'right',
                  width: `${3.1 * single_col}%`,
                }}
              >
                <Text style={[styles.invoiceTableBlankSpace_italic]}>{'Cess'}</Text>
              </Text>
              {invoice.custom_col_names.map((item, index) => (
                <Text
                  key={index}
                  style={{
                    ...styles.invoiceTableBlankSpace_cell,
                    width: `${single_col}%`,
                    fontSize: font_size,
                  }}
                >
                  {'  '}
                </Text>
              ))}

              {!hide_hsn && (
                <Text
                  style={{
                    ...styles.invoiceTableBlankSpace_cell,
                    textAlign: 'right',
                    fontSize: font_size,
                    width: `${0.8 * single_col}%`,
                  }}
                >
                  {'  '}
                </Text>
              )}

              <Text
                style={{
                  ...styles.invoiceTableBlankSpace_cell,
                  textAlign: 'right',
                  fontSize: font_size,
                  width: `${0.4 * single_col}%`,
                }}
              >
                {'  '}
              </Text>
              {invoice.invoice_settings.hide_qty == 0 && (
                <Text
                  style={{
                    ...styles.invoiceTableBlankSpace_cell,
                    textAlign: 'right',
                    fontSize: font_size,
                    width: `${0.9 * single_col}%`,
                  }}
                >
                  {'  '}
                </Text>
              )}
              <Text
                style={{
                  ...styles.invoiceTableBlankSpace_cell,
                  textAlign: 'right',
                  fontSize: font_size,
                  width: `${1 * single_col}%`,
                }}
              >
                {'  '}
              </Text>

              <Text
                style={{
                  ...styles.invoiceTableBlankSpace_cell,
                  textAlign: 'right',
                  fontSize: font_size,
                  width: `${0.4 * single_col}%`,
                }}
              >
                {'  '}
              </Text>

              <Text
                style={{
                  ...styles.invoiceTableBlankSpace_cell,
                  textAlign: 'right',
                  paddingRight: 4.5,
                  borderRight: 'none',
                  fontSize: font_size,
                  width: `${1.1 * single_col}%`,
                }}
              >
                <Text
                  style={[styles.invoiceTableBlankSpace_total, styles.invoiceTableBlankSpace_bold]}
                >
                  {/*{currency_symbol}*/}
                  {(
                    (invoice.cess_amount - invoice.cess_on_qty_value) /
                    conversion_factor
                  ).toLocaleString('en-IN', options)}
                </Text>
              </Text>
            </View>
          )}
        </Fragment>,
      )
  }
  {
    invoice.company.gstin.length == 15 &&
      invoice.cess_on_qty_value != 0 &&
      invoice.with_tax != 0 &&
      taxRows.push(
        <Fragment>
          {type != 'Delivery Challan' && type != HIDE_PRICES && (
            <View
              style={{
                ...styles.invoiceTableBlankSpace_row,
                fontSize: font_size,
                borderBottomColor: '#1d1d1f',
              }}
            >
              <Text
                style={{
                  ...styles.invoiceTableBlankSpace_cell,
                  textAlign: 'left',
                  fontSize: font_size,
                  width: `${0.3 * single_col}%`,
                }}
              >
                {'  '}
              </Text>

              <Text
                style={{
                  ...styles.invoiceTableBlankSpace_cell,
                  ...styles.invoiceTableBlankSpace_italic,
                  fontSize: font_size,
                  textAlign: 'right',
                  width: `${3.1 * single_col}%`,
                }}
              >
                <Text style={[styles.invoiceTableBlankSpace_italic]}>{'Cess on Quantity'}</Text>
              </Text>
              {invoice.custom_col_names.map((item, index) => (
                <Text
                  key={index}
                  style={{
                    ...styles.invoiceTableBlankSpace_cell,
                    width: `${single_col}%`,
                    fontSize: font_size,
                  }}
                >
                  {'  '}
                </Text>
              ))}

              {!hide_hsn && (
                <Text
                  style={{
                    ...styles.invoiceTableBlankSpace_cell,
                    textAlign: 'right',
                    fontSize: font_size,
                    width: `${0.8 * single_col}%`,
                  }}
                >
                  {'  '}
                </Text>
              )}

              <Text
                style={{
                  ...styles.invoiceTableBlankSpace_cell,
                  textAlign: 'right',
                  fontSize: font_size,
                  width: `${0.4 * single_col}%`,
                }}
              >
                {'  '}
              </Text>

              <Text
                style={{
                  ...styles.invoiceTableBlankSpace_cell,
                  textAlign: 'right',
                  fontSize: font_size,
                  width: `${0.9 * single_col}%`,
                }}
              >
                {'  '}
              </Text>

              <Text
                style={{
                  ...styles.invoiceTableBlankSpace_cell,
                  textAlign: 'right',
                  fontSize: font_size,
                  width: `${1 * single_col}%`,
                }}
              >
                {'  '}
              </Text>

              <Text
                style={{
                  ...styles.invoiceTableBlankSpace_cell,
                  textAlign: 'right',
                  fontSize: font_size,
                  width: `${0.4 * single_col}%`,
                }}
              >
                {'  '}
              </Text>

              <Text
                style={{
                  ...styles.invoiceTableBlankSpace_cell,
                  textAlign: 'right',
                  paddingRight: 4.5,
                  borderRight: 'none',
                  fontSize: font_size,
                  width: `${1.1 * single_col}%`,
                }}
              >
                <Text
                  style={[styles.invoiceTableBlankSpace_total, styles.invoiceTableBlankSpace_bold]}
                >
                  {/*{currency_symbol}*/}
                  {(invoice.cess_on_qty_value / conversion_factor).toLocaleString('en-IN', options)}
                </Text>
              </Text>
            </View>
          )}
        </Fragment>,
      )
  }

  return (
    <Fragment>
      <View wrap={false} style={styles.globalContainer}>
        {type != 'Delivery Challan' && type != HIDE_PRICES && (
          <View
            style={{
              ...styles.invoiceTableBlankSpace_row,
              fontSize: font_size,
              borderBottomColor: '#1d1d1f',
            }}
          >
            <Text
              style={{
                ...styles.invoiceTableBlankSpace_cell,
                textAlign: 'left',
                fontSize: font_size,
                width: `${0.3 * single_col}%`,
              }}
            >
              {'  '}
            </Text>

            <Text
              style={{
                ...styles.invoiceTableBlankSpace_cell,
                paddingTop: 27,
                paddingBottom: 9,
                fontSize: font_size,
                textAlign: 'right',
                width: `${3.1 * single_col}%`,
              }}
            >
              {invoice.document_custom_additional_charges.map((item, index) => (
                <>
                  {item.total_amount != 0 && invoice.hide_totals == 0 && (
                    <Text style={[styles.invoiceTableBlankSpace_italic]}>
                      {'\n'}
                      {item.name}
                    </Text>
                  )}
                </>
              ))}
              {invoice.with_tax == 1 &&
                invoice.hide_totals == 0 &&
                invoice.invoice_settings.hide_taxable_amount == 0 && (
                  <Text style={styles.invoiceTableBlankSpace_italic}> {'\n'}Taxable Amount</Text>
                )}
            </Text>

            {invoice.custom_col_names.map((item, index) => (
              <Text
                key={index}
                style={{
                  ...styles.invoiceTableBlankSpace_cell,
                  width: `${single_col}%`,
                  fontSize: font_size,
                }}
              >
                {'  '}
              </Text>
            ))}

            {!hide_hsn && (
              <Text
                style={{
                  ...styles.invoiceTableBlankSpace_cell,
                  paddingTop: 27,
                  paddingBottom: 9,
                  fontSize: font_size,
                  textAlign: 'right',
                  width: `${0.8 * single_col}%`,
                }}
              >
                {invoice.document_custom_additional_charges.map((item, index) => (
                  <>
                    {item.sac_code != '' && (
                      <Text>
                        {'\n'}
                        <Text>{item.sac_code}</Text>
                      </Text>
                    )}
                  </>
                ))}
              </Text>
            )}

            <Text
              style={{
                ...styles.invoiceTableBlankSpace_cell,
                paddingTop: 27,
                paddingBottom: 9,
                textAlign: 'right',
                fontSize: font_size,
                width: `${0.4 * single_col}%`,
              }}
            >
              {invoice.document_custom_additional_charges.map((item, index) => (
                <>
                  {item.tax != 0 && (
                    <Text style={[styles.invoiceTableBlankSpace_italic]}>
                      {'\n'}
                      {item.tax}%
                    </Text>
                  )}
                </>
              ))}
            </Text>
            {invoice.invoice_settings.hide_qty == 0 && (
              <Text
                style={{
                  ...styles.invoiceTableBlankSpace_cell,
                  textAlign: 'right',
                  fontSize: font_size,
                  width: `${0.9 * single_col}%`,
                }}
              >
                {'  '}
              </Text>
            )}
            <Text
              style={{
                ...styles.invoiceTableBlankSpace_cell,
                textAlign: 'right',
                fontSize: font_size,
                width: `${1 * single_col}%`,
              }}
            >
              {'  '}
            </Text>

            <Text
              style={{
                ...styles.invoiceTableBlankSpace_cell,
                textAlign: 'right',
                fontSize: font_size,
                width: `${0.4 * single_col}%`,
              }}
            >
              {'  '}
            </Text>

            <Text
              style={{
                ...styles.invoiceTableBlankSpace_cell,
                textAlign: 'right',
                paddingRight: 4.5,
                paddingTop: 27,
                paddingBottom: 9,
                borderRight: 'none',
                fontSize: font_size,
                width: `${1.1 * single_col}%`,
              }}
            >
              {invoice.document_custom_additional_charges.map((item, index) => (
                <>
                  {item.total_amount != 0 && invoice.hide_totals == 0 && (
                    <Text
                      style={[
                        styles.invoiceTableBlankSpace_description,
                        styles.invoiceTableBlankSpace_bold,
                      ]}
                    >
                      {'\n'}
                      <Text
                        style={[
                          styles.invoiceTableBlankSpace_total,
                          styles.invoiceTableBlankSpace_bold,
                        ]}
                      >
                        {/*{currency_symbol}*/}
                        {item.type == -1 ? '(-) ' : ''}
                        {Number(item.net_amount / conversion_factor).toFixed(2)}
                      </Text>
                    </Text>
                  )}
                </>
              ))}

              {invoice.with_tax == 1 &&
                invoice.hide_totals == 0 &&
                invoice.invoice_settings.hide_taxable_amount == 0 && (
                  <Text
                    style={[
                      styles.invoiceTableBlankSpace_total,
                      styles.invoiceTableBlankSpace_bold,
                    ]}
                  >
                    {/*{currency_symbol}*/}
                    {'\n'}
                    {Number(
                      (
                        (netAmount +
                          extraChargesAmount(
                            invoice.document_custom_additional_charges,
                            'net_amount',
                          )) /
                        conversion_factor
                      ).toFixed(2),
                    ).toLocaleString('en-IN', options)}
                  </Text>
                )}
            </Text>
          </View>
        )}

        {taxRows}

        {type != 'Delivery Challan' && type != HIDE_PRICES && (
          <View
            style={{
              ...styles.invoiceTableBlankSpace_row,
              fontSize: font_size,
            }}
          >
            <Text
              style={{
                ...styles.invoiceTableBlankSpace_cell,
                textAlign: 'left',
                fontSize: font_size,
                width: `${0.3 * single_col}%`,
              }}
            >
              {'  '}
            </Text>

            <Text
              style={{
                ...styles.invoiceTableBlankSpace_cell,
                ...styles.invoiceTableBlankSpace_italic,
                fontSize: font_size,
                textAlign: 'right',
                width: `${3.1 * single_col}%`,
              }}
            >
              {invoice.extra_discount != 0 && invoice.extra_discount != null && (
                <Text
                  style={[
                    styles.invoiceTableBlankSpace_description,
                    styles.invoiceTableBlankSpace_bold,
                  ]}
                >
                  Discount
                </Text>
              )}
              {invoice.roundoff == 1 &&
                invoice.roundoff_value != 0 &&
                invoice.is_export == 0 &&
                invoice.invoice_settings.show_round_off == 1 && (
                  <Text
                    style={[
                      styles.invoiceTableBlankSpace_description,
                      styles.invoiceTableBlankSpace_bold,
                    ]}
                  >
                    {'\n'}Round Off
                  </Text>
                )}
            </Text>

            {invoice.custom_col_names.map((item, index) => (
              <Text
                key={index}
                style={{
                  ...styles.invoiceTableBlankSpace_cell,
                  width: `${single_col}%`,
                  fontSize: font_size,
                }}
              >
                {'  '}
              </Text>
            ))}

            {!hide_hsn && (
              <Text
                style={{
                  ...styles.invoiceTableBlankSpace_cell,
                  textAlign: 'right',
                  fontSize: font_size,
                  width: `${0.8 * single_col}%`,
                }}
              >
                {'  '}
              </Text>
            )}

            <Text
              style={{
                ...styles.invoiceTableBlankSpace_cell,
                textAlign: 'right',
                fontSize: font_size,
                width: `${0.4 * single_col}%`,
              }}
            >
              {'  '}
            </Text>
            {invoice.invoice_settings.hide_qty == 0 && (
              <Text
                style={{
                  ...styles.invoiceTableBlankSpace_cell,
                  textAlign: 'right',
                  fontSize: font_size,
                  width: `${0.9 * single_col}%`,
                }}
              >
                {'  '}
              </Text>
            )}
            <Text
              style={{
                ...styles.invoiceTableBlankSpace_cell,
                textAlign: 'right',
                fontSize: font_size,
                width: `${1 * single_col}%`,
              }}
            >
              {'  '}
            </Text>

            <Text
              style={{
                ...styles.invoiceTableBlankSpace_cell,
                textAlign: 'right',
                fontSize: font_size,
                width: `${0.4 * single_col}%`,
              }}
            >
              {'  '}
            </Text>

            <Text
              style={{
                ...styles.invoiceTableBlankSpace_cell,
                textAlign: 'right',
                paddingRight: 4.5,
                borderRight: 'none',
                fontSize: font_size,
                width: `${1.1 * single_col}%`,
              }}
            >
              {invoice.extra_discount != 0 && invoice.extra_discount != null && (
                <Text
                  style={[styles.invoiceTableBlankSpace_total, styles.invoiceTableBlankSpace_bold]}
                >
                  -{Number(invoice.extra_discount.toFixed(2)).toLocaleString('en-IN', options)}
                </Text>
              )}
              {invoice.roundoff == 1 &&
                invoice.roundoff_value != 0 &&
                invoice.is_export == 0 &&
                invoice.invoice_settings.show_round_off == 1 && (
                  <Text
                    style={[
                      styles.invoiceTableBlankSpace_total,
                      styles.invoiceTableBlankSpace_bold,
                    ]}
                  >
                    {'\n'}
                    {(-1 * Number(invoice.roundoff_value.toFixed(2))).toLocaleString(
                      'en-IN',
                      options,
                    )}
                  </Text>
                )}
            </Text>
          </View>
        )}

        {type != 'Delivery Challan' && invoice.is_tcs == 1 && (
          <View
            style={{
              ...styles.invoiceTableBlankSpace_row,
              fontSize: font_size,
              borderBottomColor: '#1d1d1f',
            }}
          >
            <Text
              style={{
                ...styles.invoiceTableBlankSpace_cell,
                textAlign: 'left',
                fontSize: font_size,
                width: `${0.3 * single_col}%`,
              }}
            >
              {'  '}
            </Text>

            <Text
              style={{
                ...styles.invoiceTableBlankSpace_cell,
                ...styles.invoiceTableBlankSpace_italic,
                paddingTop: 5,
                paddingBottom: 9,
                fontSize: font_size,
                textAlign: 'right',
                width: `${3.1 * single_col}%`,
              }}
            >
              {invoice.with_tax == 1 && invoice.hide_totals == 0 && (
                <Text style={styles.invoiceTableBlankSpace_italic}>
                  TCS @ {invoice.tcs_details[0].tax}% {invoice.tcs_details[0].section}
                </Text>
              )}
            </Text>

            {invoice.custom_col_names.map((item, index) => (
              <Text
                key={index}
                style={{
                  ...styles.invoiceTableBlankSpace_cell,
                  width: `${single_col}%`,
                  fontSize: font_size,
                }}
              >
                {'  '}
              </Text>
            ))}

            {!hide_hsn && (
              <Text
                style={{
                  ...styles.invoiceTableBlankSpace_cell,
                  textAlign: 'right',
                  fontSize: font_size,
                  width: `${0.8 * single_col}%`,
                }}
              >
                {'  '}
              </Text>
            )}

            <Text
              style={{
                ...styles.invoiceTableBlankSpace_cell,
                textAlign: 'right',
                fontSize: font_size,
                width: `${0.4 * single_col}%`,
              }}
            >
              {'  '}
            </Text>
            {invoice.invoice_settings.hide_qty == 0 && (
              <Text
                style={{
                  ...styles.invoiceTableBlankSpace_cell,
                  textAlign: 'right',
                  fontSize: font_size,
                  width: `${0.9 * single_col}%`,
                }}
              >
                {'  '}
              </Text>
            )}
            <Text
              style={{
                ...styles.invoiceTableBlankSpace_cell,
                textAlign: 'right',
                fontSize: font_size,
                width: `${1 * single_col}%`,
              }}
            >
              {'  '}
            </Text>

            <Text
              style={{
                ...styles.invoiceTableBlankSpace_cell,
                textAlign: 'right',
                fontSize: font_size,
                width: `${0.4 * single_col}%`,
              }}
            >
              {'  '}
            </Text>

            <Text
              style={{
                ...styles.invoiceTableBlankSpace_cell,
                textAlign: 'right',
                paddingRight: 4.5,
                paddingTop: 5,
                paddingBottom: 9,
                borderRight: 'none',
                fontSize: font_size,
                width: `${1.1 * single_col}%`,
              }}
            >
              {invoice.with_tax == 1 && invoice.hide_totals == 0 && (
                <Text
                  style={[styles.invoiceTableBlankSpace_total, styles.invoiceTableBlankSpace_bold]}
                >
                  {'\u20B9'}
                  {'\u00A0'}
                  {invoice.tcs_details[0].tcs_amount}
                </Text>
              )}
            </Text>
          </View>
        )}
        {rowsCount > 0 && invoice.show_description == 0 && rows}
      </View>
      <View fixed style={styles.invoiceTableBlankSpace_block2}></View>
    </Fragment>
  )
}

export default InvoiceTableBlankSpace
