import { Image, Text, View } from '@react-pdf/renderer'
import { breakName } from 'components/other/utility'
import { Fragment } from 'react'
import { HIDE_PRICES } from '../../../utils/constants'
import RichText from '../RichText'
import ItemBatchDetails from '../itemBatchDetails'
import { amountFormatter } from '../utils'
import { styles as getStyles } from './styles'

const InvoiceTableRow = ({ items, data, invoice, type }) => {
  const options = { maximumFractionDigits: 2, minimumFractionDigits: 2 }
  const price_options = {
    maximumFractionDigits: invoice.invoice_settings.price_max_decimals,
    minimumFractionDigits: 2,
  }
  const hide_hsn = invoice.invoice_settings.hide_hsn
  const MAX_DECIMALS = invoice.invoice_settings.price_max_decimals

  var styles = getStyles(invoice)
  let locale = invoice.company.locale
  let font_style = invoice.invoice_settings.font_style
  if (locale == 'English') {
    locale = font_style
  }

  var FONT_FACTOR = invoice.invoice_settings.font_size

  let no_of_default_cols = invoice.invoice_settings.hide_qty ? 6 : 7
  no_of_default_cols = hide_hsn ? no_of_default_cols - 0.8 : no_of_default_cols
  var single_col = (1 * 100) / (no_of_default_cols + invoice.custom_col_names.length)

  let no_of_with_out_tax_cols = invoice.invoice_settings.hide_qty ? 5 : 6
  no_of_with_out_tax_cols = hide_hsn ? no_of_with_out_tax_cols - 1 : no_of_with_out_tax_cols
  var single_col2 = (1 * 100) / (no_of_with_out_tax_cols + invoice.custom_col_names.length)

  var dc_col = (1 * 100) / (invoice.invoice_settings.hide_qty ? 2 : 3)

  var font_size = (FONT_FACTOR * (9 * 9)) / (9 + invoice.custom_col_names.length)
  var font_size2 = (FONT_FACTOR * (9 * 15)) / (15 + invoice.custom_col_names.length)
  let conversion_factor = 1
  let currency_symbol = invoice.company.currency_symbol
  if (
    (invoice.is_export == 1 || invoice.is_multi_currency == 1) &&
    invoice.invoice_settings.show_both_currencies != true
  ) {
    currency_symbol = invoice.export_details[0].currency_symbol
    conversion_factor = invoice.export_details[0].conversion_factor
  }

  const rows = items.map((item, i) => (
    <Fragment key={i}>
      {(type == 'Delivery Challan' || type == HIDE_PRICES) && (
        <>
          {item.product_type == 'Product' && (
            <View style={styles.invoiceTableRow_border}>
              <View style={styles.invoiceTableRow_row} key={(i + 1).toString()} wrap={false}>
                <Text
                  hyphenationCallback={e => breakName(e)}
                  style={{ ...styles.invoiceTableRow_slno_dc, width: `${0.2 * dc_col}%` }}
                >
                  {i + 1}
                </Text>
                <Text
                  style={{
                    ...styles.invoiceTableRow_description_dc,
                    width: `${1.8 * dc_col}%`,
                  }}
                >
                  <Text style={styles.invoiceTableRow_bold}>
                    {item.name} {item.variant_name}
                  </Text>
                  <ItemBatchDetails item={item} invoice={invoice} />
                  {!hide_hsn && item.hsn_code != '' && (
                    <>
                      <RichText
                        locale={locale}
                        note={
                          item.product_type == 'Product'
                            ? 'HSN: ' + item.hsn_code
                            : 'SAC: ' + item.hsn_code
                        }
                        FONT_FACTOR={0.9 * font_size}
                      />
                    </>
                  )}
                </Text>
                {invoice.invoice_settings.hide_qty == 0 && (
                  <Text style={{ ...styles.invoiceTableRow_qty_dc, width: `${dc_col}%` }}>
                    <Text>
                      {(item.qty * item.conversion_rate).toLocaleString('en-IN', {
                        maximumFractionDigits: 3,
                      })}
                    </Text>
                    {item.unit != '' &&
                      item.unit != 'OTH' &&
                      item.unit != 'NONE' &&
                      item.unit != 'UNDEFINED' && (
                        <Text> {item.item_unit == '' ? item.unit : item.item_unit}</Text>
                      )}
                    {invoice.invoice_settings.show_qty_conversion_rate == 1 &&
                      item.conversion_rate != 1 && (
                        <Text>
                          {'\n'}
                          <RichText
                            locale={locale}
                            note={'(' + parseFloat(item.qty).toFixed(3) + ' ' + item.unit + ')'}
                            FONT_FACTOR={font_size}
                          />
                        </Text>
                      )}
                  </Text>
                )}
              </View>

              {data.show_description == 1 &&
                item.description != '' &&
                item.description != '<p><br></p>' && (
                  <View style={styles.invoiceTableRow_description} key={(i + 1).toString()}>
                    <View
                      style={{
                        width: `${2.8 * dc_col}%`,
                        textAlign: 'left',
                        flexBasis: '100%',
                        paddingLeft: `${0.2 * dc_col}%`,
                      }}
                    >
                      <RichText locale={locale} note={item.description} FONT_FACTOR={font_size} />
                    </View>
                  </View>
                )}

              {invoice.invoice_settings.show_images == 1 && item.product_images[0].is_empty == 0 && (
                <View style={styles.invoiceTableRow_description} key={(i + 1).toString()}>
                  <View
                    style={{
                      width: `${2.8 * dc_col}%`,
                      textAlign: 'left',
                      flexBasis: '100%',
                      paddingLeft: `${0.2 * dc_col}%`,
                    }}
                  >
                    <View
                      style={{
                        flexDirection: 'row',
                      }}
                    >
                      {item.product_images.map((image, index) => (
                        <Image
                          style={styles.invoiceTableRow_image}
                          src={{
                            uri: image.url,
                            method: 'GET',
                            headers: { 'Cache-Control': 'no-cache' },
                          }}
                          crossorigin="anonymous"
                        />
                      ))}
                    </View>
                  </View>
                </View>
              )}
            </View>
          )}
        </>
      )}

      {type != 'Delivery Challan' && type != HIDE_PRICES && (
        <>
          <Fragment key={i}>
            {(invoice.with_tax == 0 || invoice.company.gstin.length < 15) && (
              <View style={styles.invoiceTableRow_border}>
                <View style={styles.invoiceTableRow_row} key={(i + 1).toString()} wrap={false}>
                  <Text
                    hyphenationCallback={e => breakName(e)}
                    style={{
                      textAlign: 'left',
                      paddingLeft: 4.5,
                      fontSize: font_size2,
                      width: `${0.3 * single_col2}%`,
                    }}
                  >
                    {i + 1}
                  </Text>
                  <View
                    style={{
                      textAlign: 'left',
                      fontSize: font_size2,
                      paddingBottom: 1,
                      width: `${1.7 * single_col2}%`,
                    }}
                  >
                    <Text style={styles.invoiceTableRow_bold}>
                      {item.name} {item.variant_name}
                      <ItemBatchDetails item={item} invoice={invoice} />
                    </Text>
                  </View>

                  {item.custom_col_values.map((each_item, index) => (
                    <Text key={index} style={{ fontSize: font_size2, width: `${single_col2}%` }}>
                      {each_item == '' ? '-' : each_item}
                    </Text>
                  ))}
                  {!hide_hsn && (
                    <Text
                      style={{
                        fontSize: font_size,
                        width: `${single_col2}%`,
                      }}
                      hyphenationCallback={e => breakName(e)}
                    >
                      <Text hyphenationCallback={e => breakName(e)}>
                        {item.hsn_code != '' && item.hsn_code != null ? item.hsn_code : '-'}
                      </Text>
                    </Text>
                  )}

                  <View
                    style={{
                      fontSize: font_size,
                      width: `${single_col2}%`,
                    }}
                  >
                    <Text style={styles.invoiceTableRow_med}>
                      {(
                        (item.unit_price * (1 - item.discount / 100)) /
                        conversion_factor
                      ).toLocaleString('en-IN', price_options)}
                    </Text>
                    {item.discount > 0 && invoice.invoice_settings.hide_discount == 0 && (
                      <Text hyphenationCallback={e => breakName(e)}>
                        {(item.unit_price / conversion_factor).toLocaleString(
                          'en-IN',
                          price_options,
                        ) +
                          ' (-' +
                          amountFormatter(item.discount, MAX_DECIMALS) +
                          '%)'}
                      </Text>
                    )}
                  </View>
                  {invoice.invoice_settings.hide_qty == 0 && (
                    <Text style={{ fontSize: font_size2, width: `${single_col2}%` }}>
                      <Text style={styles.invoiceTableRow_unit}>
                        {(item.qty * item.conversion_rate).toLocaleString('en-IN', {
                          maximumFractionDigits: 3,
                        })}
                      </Text>
                      {item.unit != '' &&
                        item.unit != 'OTH' &&
                        item.unit != 'NONE' &&
                        item.unit != 'UNDEFINED' && (
                          <Text style={styles.invoiceTableRow_unit2}>
                            {' '}
                            {item.item_unit == '' ? item.unit : item.item_unit}
                          </Text>
                        )}
                      {invoice.invoice_settings.show_qty_conversion_rate == 1 &&
                        item.conversion_rate != 1 && (
                          <Text>
                            {'\n'}
                            <RichText
                              locale={locale}
                              note={'(' + parseFloat(item.qty).toFixed(3) + ' ' + item.unit + ')'}
                              FONT_FACTOR={font_size}
                            />
                          </Text>
                        )}
                    </Text>
                  )}
                  <Text
                    style={{
                      paddingRight: 4.5,
                      fontSize: font_size2,
                      width: `${single_col2}%`,
                    }}
                  >
                    {(item.net_amount / conversion_factor).toLocaleString('en-IN', options)}
                  </Text>
                </View>

                {data.show_description == 1 &&
                  item.description != '' &&
                  item.description != '<p><br></p>' && (
                    <View style={styles.invoiceTableRow_description} key={(i + 1).toString()}>
                      <View
                        style={{
                          width: `${4.7 * single_col2}%`,
                          textAlign: 'left',
                          paddingLeft: `${0.3 * single_col2}%`,
                        }}
                      >
                        <RichText locale={locale} note={item.description} FONT_FACTOR={font_size} />
                      </View>
                    </View>
                  )}

                {invoice.invoice_settings.show_images == 1 && item.product_images[0].is_empty == 0 && (
                  <View style={styles.invoiceTableRow_description} key={(i + 1).toString()}>
                    <View
                      style={{
                        width: `${4.7 * single_col2}%`,
                        textAlign: 'left',
                        paddingLeft: `${0.3 * single_col2}%`,
                      }}
                    >
                      <View
                        style={{
                          flexDirection: 'row',
                        }}
                      >
                        {item.product_images.map((image, index) => (
                          <Image
                            style={styles.invoiceTableRow_image}
                            src={{
                              uri: image.url,
                              method: 'GET',
                              headers: { 'Cache-Control': 'no-cache' },
                            }}
                            crossorigin="anonymous"
                          />
                        ))}
                      </View>
                    </View>
                  </View>
                )}
              </View>
            )}

            {invoice.with_tax == 1 && invoice.company.gstin.length == 15 && (
              <View style={styles.invoiceTableRow_border}>
                <View style={styles.invoiceTableRow_row} key={(i + 1).toString()} wrap={false}>
                  <Text
                    hyphenationCallback={e => breakName(e)}
                    style={{
                      paddingLeft: 4.5,
                      textAlign: 'left',
                      fontSize: font_size,
                      width: `${0.5 * single_col}%`,
                    }}
                  >
                    {i + 1}
                  </Text>

                  <View
                    style={{
                      textAlign: 'left',
                      fontSize: font_size,
                      paddingBottom: 1,
                      width: `${2.4 * single_col}%`,
                    }}
                  >
                    <Text style={styles.invoiceTableRow_bold}>
                      {item.name} {item.variant_name}
                    </Text>
                    <ItemBatchDetails item={item} invoice={invoice} />
                  </View>

                  {item.custom_col_values.map((each_item, index) => (
                    <Text
                      key={index}
                      style={{ flexWrap: 'wrap', fontSize: font_size, width: `${single_col}%` }}
                    >
                      {each_item == '' ? '-' : each_item}
                    </Text>
                  ))}

                  {!hide_hsn && (
                    <Text
                      style={{
                        fontSize: font_size,
                        width: `${0.8 * single_col}%`,
                      }}
                      hyphenationCallback={e => breakName(e)}
                    >
                      <Text hyphenationCallback={e => breakName(e)}>
                        {item.hsn_code != '' && item.hsn_code != null ? item.hsn_code : '-'}
                      </Text>
                    </Text>
                  )}

                  <View
                    style={{
                      fontSize: font_size,
                      width: `${1.1 * single_col}%`,
                    }}
                  >
                    <Text style={styles.invoiceTableRow_med}>
                      {(
                        (item.unit_price * (1 - item.discount / 100)) /
                        conversion_factor
                      ).toLocaleString('en-IN', price_options)}
                    </Text>
                    {item.discount > 0 && invoice.invoice_settings.hide_discount == 0 && (
                      <Text hyphenationCallback={e => breakName(e)}>
                        {(item.unit_price / conversion_factor).toLocaleString(
                          'en-IN',
                          price_options,
                        ) +
                          ' (-' +
                          amountFormatter(item.discount, MAX_DECIMALS) +
                          '%)'}
                      </Text>
                    )}
                  </View>
                  {invoice.invoice_settings.hide_qty == 0 && (
                    <Text style={{ fontSize: font_size, width: `${0.8 * single_col}%` }}>
                      <Text>
                        {(item.qty * item.conversion_rate).toLocaleString('en-IN', {
                          maximumFractionDigits: 3,
                        })}
                      </Text>
                      {item.unit != '' &&
                        item.unit != 'OTH' &&
                        item.unit != 'NONE' &&
                        item.unit != 'UNDEFINED' && (
                          <Text> {item.item_unit == '' ? item.unit : item.item_unit}</Text>
                        )}
                      {invoice.invoice_settings.show_qty_conversion_rate == 1 &&
                        item.conversion_rate != 1 && (
                          <Text>
                            {'\n'}
                            <RichText
                              locale={locale}
                              note={'(' + parseFloat(item.qty).toFixed(3) + ' ' + item.unit + ')'}
                              FONT_FACTOR={font_size}
                            />
                          </Text>
                        )}
                    </Text>
                  )}
                  {/*<Text style={{...styles.invoiceTableRow_disc, fontSize: font_size, width: `${single_col}%`}}></Text>*/}

                  <Text
                    style={{
                      paddingRight: 4.5,
                      fontSize: font_size,
                      width: `${1.4 * single_col}%`,
                    }}
                  >
                    {(item.net_amount / conversion_factor).toLocaleString('en-IN', options)}
                  </Text>
                </View>

                {data.show_description == 1 &&
                  item.description != '' &&
                  item.description != '<p><br></p>' && (
                    <View style={styles.invoiceTableRow_description} key={(i + 1).toString()}>
                      <View
                        style={{
                          width: `${6.5 * single_col}%`,
                          textAlign: 'left',
                          paddingLeft: `${0.5 * single_col}%`,
                        }}
                      >
                        <RichText locale={locale} note={item.description} FONT_FACTOR={font_size} />
                      </View>
                    </View>
                  )}

                {invoice.invoice_settings.show_images == 1 && item.product_images[0].is_empty == 0 && (
                  <View style={styles.invoiceTableRow_description} key={(i + 1).toString()}>
                    <View
                      style={{
                        width: `${6.5 * single_col}%`,
                        textAlign: 'left',
                        paddingLeft: `${0.5 * single_col}%`,
                      }}
                    >
                      <View
                        style={{
                          flexDirection: 'row',
                        }}
                      >
                        {item.product_images.map((image, index) => (
                          <Image
                            style={styles.invoiceTableRow_image}
                            src={{
                              uri: image.url,
                              method: 'GET',
                              headers: { 'Cache-Control': 'no-cache' },
                            }}
                            crossorigin="anonymous"
                          />
                        ))}
                      </View>
                    </View>
                  </View>
                )}
              </View>
            )}
          </Fragment>
        </>
      )}
    </Fragment>
  ))
  return <Fragment>{rows}</Fragment>
}

export default InvoiceTableRow
