import React, { Component } from 'react'

import { EnhancedSubscriptionModal } from 'components/modal/subscription'
import SubscriptionPlanModal from 'components/modal/subscription/subscriptionPlan'
import * as dayjs from 'dayjs'
import utc from 'dayjs/plugin/utc'
import { downloadAPI, getAPI } from 'services/jwt'
import store from 'store'

import { AutoComplete, Button, Card, DatePicker, Input, Popover, Table } from 'antd'

const { RangePicker } = DatePicker
dayjs.extend(utc)

import { LoadingOutlined } from '@ant-design/icons'
import ShowDateString from 'components/other/dateString'
import { ranges } from 'services/axios'
import { connect } from 'react-redux'

const isMobile = window.innerWidth <= 500
class ExpenseReport extends Component {
  dateFormat = 'DD-MM-YYYY'
  subscriptionPlanRef = React.createRef()
  constructor(props) {
    super(props)

    this.state = {
      transactions: [],
      num_records: 10,
      page: 0,
      total: 0,
      selectedCategory: { name: '', id: -1 },
      selectedItem: { name: '', id: -1, variant_name: '' },
      autocompleteData: [],
      itemAutoCompleteData: [],
      dates: store.get('reportsDateRange_' + this.props.company_details.company_id)
        ? store.get('reportsDateRange_' + this.props.company_details.company_id)
        : dayjs()
            .startOf('month')
            .format(this.dateFormat) +
          ' - ' +
          dayjs()
            .endOf('month')
            .format(this.dateFormat),
      search: '',
      item_search: '',
      data: [],
      columns: [],
      timeout: null,
      loading: false,
    }
  }

  callData = async () => {
    this.setState({ loading: true })
    var report = this.props.report
    var req = {
      page: this.state.page,
      num_records: this.state.num_records,
      date: this.state.dates,
      download: 0,
      search: this.state.selectedCategory.name,
      document_type: this.props.report.document_type,
      is_category_group: report.is_category_group,
    }

    const data = await getAPI('v2/reports', 'expense_report', req)
    if (data.success) {
      this.setState({
        data: data.transactions,
        columns: data.columns,
        total: data.total_records,
        loading: false,
      })
    }
  }

  download_excel = async download => {
    // if (download == 2 && this.state.columns.length > 15) {
    //   message.error('Too many columns selected. Please select less than 15 columns.')
    //   return
    // }

    if (!store.get('paid')) {
      this.subscriptionPlanRef.current.openModal('download_excel')
    } else {
      var report = this.props.report
      var req = {
        page: this.state.page,
        num_records: this.state.num_records,
        date: this.state.dates,
        download: download,
        search: this.state.selectedCategory.name,
        document_type: this.props.report.document_type,
        is_category_group: report.is_category_group,
      }

      const data = await downloadAPI('v2/reports', 'expense_report', req)
      if (data.success) {
        this.setState({
          data: data.transactions,
          columns: data.columns,
          total: 1,
        })
      }
    }
  }

  async componentDidMount() {
    await this.callData()
    await this.categorySearchAPI('')
  }
  async componentDidUpdate(prevProps, prevState) {
    if (
      prevProps.report.is_category_group != this.props.report.is_category_group ||
      prevProps.report.type != this.props.report.type
    ) {
      await this.callData()
    }
  }
  onCategorySelect = (data, option) => {
    this.setState(
      {
        selectedCategory: option,
        autocompleteData: [],
      },
      () => this.callData(),
    )
  }

  categorySearchAPI = async searchText => {
    if (searchText.length >= 0) {
      const req = { query: searchText, type: this.props.report.document_type }
      const data = await getAPI('expenses', 'get_categories', req)
      if (data) {
        var allData = []

        allData = data.categories.map(value => {
          return {
            value: value.category,
            name: value.category,
            id: value.category,
            key: value.category,
          }
        })

        this.setState({
          autocompleteData: [...allData],
        })
      }
    } else {
      this.setState(
        {
          selectedCategory: { name: '', id: -1 },
          autocompleteData: [],
        },
        () => this.callData(0, this.state.num_records),
      )
    }
  }

  render() {
    const dotOptions = { minimumFractionDigits: 2, maximumFractionDigits: 2 }

    return (
      <>
        {this.props.newReport ? (
          ''
        ) : (
          <div className="mt-2 mb-4 font-size-22 font-weight-bold">{this.props.report.name}</div>
        )}
        <div className="w-100 d-flex justify-content-between">
          <div className="d-inline-flex">
            <Input.Group compact>
              <AutoComplete
                options={this.state.autocompleteData}
                className="mt-2"
                style={{ width: 360, borderRadius: '5px' }}
                onSelect={this.onCategorySelect}
                onSearch={text => {
                  clearTimeout(this.state.timeout)
                  this.setState({
                    selectedCategory: { name: '', id: -1 },
                    categoryName: text,
                    timeout: setTimeout(() => this.categorySearchAPI(text), 1200),
                  })
                }}
                onClear={() => {
                  this.setState(
                    {
                      selectedCategory: { name: '', id: -1 },
                      autocompleteData: [],
                    },
                    () => this.callData(),
                  )
                }}
                allowClear
                // value={this.state.selectedCategory.name}
                placeholder={'Search Category'}
              />
              <RangePicker
                allowClear={false}
                ranges={ranges}
                className="mr-2 mt-2"
                onChange={(dates, dateStrings) =>
                  this.setState({ dates: dateStrings[0] + ' - ' + dateStrings[1], page: 0 }, () =>
                    this.callData(),
                  )
                }
                style={{
                  width: 300,
                  // borderRadius: '5px',
                  cursor: 'pointer',
                }}
                popupStyle={{
                  maxWidth: '95%',
                }}
                panelRender={panel => (
                  <Card
                    bodyStyle={{
                      padding: 0,
                      width: '100%',
                      overflowY: 'scroll',
                    }}
                  >
                    <div>{panel}</div>
                  </Card>
                )}
                placement="bottomRight"
                value={
                  this.state.dates
                    ? [
                        dayjs(this.state.dates.split(' - ')[0], this.dateFormat),
                        dayjs(this.state.dates.split(' - ')[1], this.dateFormat),
                      ]
                    : null
                }
                format={this.dateFormat}
              />
              {isMobile ? (
                <div className="d-inline-flex">
                  <Button
                    // type="action"
                    className=" ml-1 mt-2 "
                    style={{
                      width: 145,
                      borderRadius: '5px',
                      cursor: 'pointer',
                    }}
                    onClick={() => this.download_excel(1)}
                    size={isMobile ? 'small' : 'middle'}
                  >
                    <i className="fa-duotone fa-file-excel mr-2" />
                    Download Excel
                  </Button>
                  <Button
                    // type="action-3"
                    className=" ml-2 mt-2"
                    style={{
                      width: 145,
                      borderRadius: '5px',
                      cursor: 'pointer',
                    }}
                    onClick={() => this.download_excel(2)}
                    size={isMobile ? 'small' : 'middle'}
                  >
                    <i className="fa-duotone fa-file-pdf mr-2" />
                    Download PDF
                  </Button>
                </div>
              ) : (
                ''
              )}
            </Input.Group>
          </div>
          {!this.state.loading &&
            (window.innerWidth >= 1600 ? (
              <>
                <Button className="mx-2 mt-2" onClick={() => this.download_excel(1)}>
                  <i className="fa-duotone fa-file-excel mr-2" />
                  Download Excel
                </Button>
                <Button
                  // type="action-3"
                  className="mr-2 mt-2"
                  onClick={() => this.download_excel(2)}
                >
                  <i className="fa-duotone fa-file-pdf mr-2" />
                  Download PDF
                </Button>
              </>
            ) : (
              <>
                <div className="ml-auto mt-2 d-flex-inline flex-column">
                  <>
                    {isMobile ? (
                      ''
                    ) : (
                      <Popover
                        content={
                          <>
                            <div className="font-weight-bold">Download</div>
                            <div className="d-flex flex-column">
                              <Button
                                // type="action"
                                className="mx-2 my-2"
                                onClick={() => this.download_excel(1)}
                              >
                                <i className="fa-duotone fa-file-excel mr-2" />
                                Download Excel
                              </Button>
                              <Button
                                // type="action-3"
                                className="mx-2 my-2"
                                onClick={() => this.download_excel(2)}
                              >
                                <i className="fa-duotone fa-file-pdf mr-2" />
                                Download PDF
                              </Button>
                            </div>
                          </>
                        }
                        trigger={['click']}
                        placement="bottom"
                      >
                        <Button className="  mr-2">
                          <span className="d-none d-sm-inline mr-2">Download</span>
                          <span>
                            <i class="fa-duotone fa-down-to-line d   mt-1" />
                          </span>
                        </Button>
                      </Popover>
                    )}
                  </>
                </div>
              </>
            ))}
        </div>

        <div>
          <ShowDateString dateString={this.state.dates} />
          <hr />

          <Table
            bordered
            columns={this.state.columns}
            dataSource={this.state.data.map((value, index) => {
              if (value.is_bold) {
                return {
                  ...value,
                  key: index,
                  amount: (
                    <span className="font-weight-bold">
                      {value.amount.toLocaleString('en-US', dotOptions)}
                    </span>
                  ),
                  serial_number: <span className="font-weight-bold">{value.serial_number}</span>,
                }
              } else {
                return {
                  ...value,
                  key: index,
                  amount: value.amount.toLocaleString('en-US', dotOptions),
                }
              }
            }, this)}
            loading={{
              spinning: this.state.loading,
              indicator: (
                <LoadingOutlined
                  style={{
                    fontSize: 35,
                  }}
                  spin
                />
              ),
            }}
            rowClassName={(record, index) => (index % 2 === 0 ? 'bg-white' : 'bg-light')}
            size="large"
            className="mb-2 mt-2"
            scroll={{ x: 'max-content' }}
            pagination={{
              disabled: store.get('paid') != 1,
              current: this.state.page + 1,
              showSizeChanger: true,
              total: this.state.total,
              defaultPageSize: 10,
              pageSizeOptions: ['10', '15', '20', '25', '50'],
              position: isMobile ? ['topCenter', 'none'] : ['none', 'bottomRight'],
              size: 'small',
              // itemRender(current, type, originalElement) {
              //   if (type === 'prev') {
              //     return <a className="mr-1">{'< Previous'}</a>
              //   }
              //   if (type === 'next') {
              //     return <a className="mr-1">{'Next >'}</a>
              //   }
              //   return originalElement
              // },
              onChange: (page, num_records) => {
                this.setState(
                  {
                    page: page - 1,
                    num_records: num_records,
                  },
                  () => this.callData(),
                )
              },
            }}
          />
        </div>
        <EnhancedSubscriptionModal onRef={ref => (this.subscription = ref)} />
        <SubscriptionPlanModal ref={this.subscriptionPlanRef} />
      </>
    )
  }
}
const mapStateToProps = (state, ownProps) => {
  return {
    company_details: state.permissions.company_details,
  }
}

export default connect(mapStateToProps)(ExpenseReport)
