import actions from './actions'

const initialState = {
  id: '',
  name: '',
  mobile: '',
  role: 'admin',
  email: '',
  avatar: '',
  company: '',
  authorized: process.env.REACT_APP_AUTHENTICATED || false, // false is default value
  loading: false,
  mobileLoading: false,
  otpLoading: false,
  companies: [{ company_id: 0, organization_name: '' }],
  selectedCompany: '',
  document_settings: {},
  pageLoading: false,
  userDetails: {},
  pricing_details: {},
  status_response: {},
  subscription_features: [],
}

export default function userReducer(state = initialState, action) {
  switch (action.type) {
    case actions.SET_STATE:
      return { ...state, ...action.payload }
    case 'user/PAGE_LOADING':
      return { ...state, pageLoading: action.payload }
    case 'user/SET_USER_DETAILS':
      return { ...state, userDetails: { ...state.userDetails, ...action.payload } }
    default:
      return state
  }
}
