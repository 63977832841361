import { Button, Form, Modal, Tabs, Upload } from 'antd'
import { Component } from 'react'

import BetaBadge from 'components/badges/beta'
import * as dayjs from 'dayjs'
import utc from 'dayjs/plugin/utc'
import Barcode from 'react-barcode'
import { connect } from 'react-redux'
import ReactToPrint from 'react-to-print'
import store from 'store'
import BarcodeDetailsForm from '../../forms/BarcodeDetailsForm'
import './index.scss'

dayjs.extend(utc)

const { TabPane } = Tabs

class PrintBarcode extends Component {
  constructor(props) {
    super(props)
  }
  render() {
    var props = this.props
    var width = 12 / props.type

    const isCompact = props.type === 4
    const productNameFontSize = isCompact ? '10px' : store.get('product_name_font_size')
    const mrpFontSize = isCompact ? '10px' : store.get('mrp_font_size')
    const barcodeWidth = isCompact ? 2 : 3
    const barcodeHeight = isCompact ? 50 : 75
    const barcodeFontSize = isCompact ? 14 : 20

    return (
      <div className="barcode">
        <div className="row">
          {[...Array(props.type)].map((item, i) => (
            <div
              key={i}
              className={`col-${width} col-xs-${width} text-center m-0 px-1`}
              style={{ lineHeight: 1 }}
            >
              <p
                className="mb-1 text-truncate pt-1 text-center"
                style={{ fontSize: productNameFontSize }}
              >
                {props.barcodeData.product_name} {props.barcodeData.name}
              </p>
              {store.get('show_mrp') === 1 && (
                <p className="mb-0">
                  <span className="font-size-12" style={{ fontSize: mrpFontSize }}>
                    {this.props?.user?.selectedCompany?.labels?.mrp}
                  </span>
                  <span>
                    {props.barcodeData.discount != 0 && (
                      <span>
                        <del
                          className="text-gray-11 font-weight-bold ml-1"
                          style={{ fontSize: mrpFontSize }}
                        >
                          {props.barcodeData.price_with_tax}
                        </del>
                      </span>
                    )}
                    <span
                      className="font-weight-bold font-size-12"
                      style={{ fontSize: mrpFontSize }}
                    >
                      <span className="ml-2 mr-1">
                        {this.props?.user?.selectedCompany?.currency_symbol}
                      </span>
                      {!props.barcodeData.discount_amount
                        ? (
                            props.barcodeData.price_with_tax *
                            (1 - props.barcodeData.discount / 100)
                          ).toFixed(2)
                        : (
                            props.barcodeData.price_with_tax - props.barcodeData.discount_amount
                          ).toFixed(2)}
                    </span>
                  </span>
                </p>
              )}

              {store.get('show_pkg_date') && (
                <h6 className="mb-0" style={{ fontSize: mrpFontSize }}>
                  PKD ON: {props.barcodeData?.pkg_date ?? dayjs().format('DD-MMM-YY')}
                </h6>
              )}
              {props.barcodeData.barcode_id != null && (
                <Barcode
                  width={barcodeWidth}
                  height={barcodeHeight}
                  fontSize={barcodeFontSize}
                  value={props.barcodeData.barcode_id + ''}
                />
              )}
            </div>
          ))}
        </div>
      </div>
    )
  }
}

class PrintBarcode8x2 extends Component {
  constructor(props) {
    super(props)
  }
  render() {
    var props = this.props
    var width = 12 / props.type
    return (
      <div className="barcode">
        <div className="row pt-3 text-center">
          {[...Array(16)].map((item, i) => (
            <div key={i} className={`col-6 text-center`}>
              <h6
                className="mb-1 print-font-3"
                style={{ fontSize: store.get('product_name_font_size') }}
              >
                {props.barcodeData.product_name} {props.barcodeData.name}
              </h6>
              {store.get('show_mrp') == 1 && (
                <h6>
                  <span className="print-font-3" style={{ fontSize: store.get('mrp_font_size') }}>
                    {this.props?.user?.selectedCompany?.labels?.mrp}:{' '}
                  </span>
                  <span>
                    {props.barcodeData.discount != 0 && (
                      <span>
                        <del
                          className="text-gray-11 font-weight-bold ml-1"
                          style={{ fontSize: store.get('mrp_font_size') }}
                        >
                          {props.barcodeData.price_with_tax}
                        </del>
                      </span>
                    )}

                    <span
                      className="font-weight-bold font-size-12"
                      style={{ fontSize: store.get('mrp_font_size') }}
                    >
                      <span className="ml-2 mr-1">
                        {this.props?.user?.selectedCompany?.currency_symbol}
                      </span>
                      {!props.barcodeData.discount_amount
                        ? (
                            props.barcodeData.price_with_tax *
                            (1 - props.barcodeData.discount / 100)
                          ).toFixed(2)
                        : (
                            props.barcodeData.price_with_tax - props.barcodeData.discount_amount
                          ).toFixed(2)}
                    </span>
                  </span>
                </h6>
              )}
              {store.get('show_pkg_date') && (
                <h6 className="print-font-2 mb-0">PKD ON: {dayjs().format('DD-MMM-YY')}</h6>
              )}
              {props.barcodeData.barcode_id != null && (
                <Barcode
                  width={2}
                  height={75}
                  fontSize={21}
                  value={props.barcodeData.barcode_id + ''}
                />
              )}
            </div>
          ))}
        </div>

        {props.barcodeData.variants &&
          props.barcodeData.variants.map(
            (variant, v) =>
              variant.barcode_id != null &&
              variant.barcode_id != '' && (
                <div className="row pt-3 text-center">
                  {[...Array(16)].map((item, i) => (
                    <div key={i} className={`col-6 text-center`}>
                      <h6
                        className="mb-1 print-font-3"
                        style={{ fontSize: store.get('product_name_font_size') }}
                      >
                        {variant.name}
                      </h6>
                      {store.get('show_mrp') == 1 && (
                        <h6>
                          <span
                            className="print-font-3"
                            style={{ fontSize: store.get('mrp_font_size') }}
                          >
                            {this.props?.user?.selectedCompany?.labels?.mrp}:{' '}
                          </span>
                          <span>
                            <span className="print-font-2 mr-1">
                              {this.props?.user?.selectedCompany?.currency_symbol}
                            </span>
                            <span
                              className="print-font-4 font-weight-bold"
                              style={{ fontSize: store.get('mrp_font_size') }}
                            >
                              {variant.is_price_with_tax == true
                                ? variant.price_with_tax
                                : variant.price}
                            </span>
                          </span>
                        </h6>
                      )}
                      {store.get('show_pkg_date') && (
                        <h6 className="print-font-2 mb-0">PKD ON: {dayjs().format('DD-MMM-YY')}</h6>
                      )}
                      {variant.barcode_id != null && (
                        <Barcode
                          width={2}
                          height={75}
                          fontSize={21}
                          value={variant.barcode_id + ''}
                        />
                      )}
                    </div>
                  ))}
                </div>
              ),
          )}
      </div>
    )
  }
}

class PrintBarcode13x5 extends Component {
  constructor(props) {
    super(props)
  }
  render() {
    var props = this.props
    var width = 12 / props.type
    return (
      <div className="barcode">
        <div className="row mt-5 pt-3 px-3 row-cols-5 text-center">
          {[...Array(65)].map((item, i) => (
            <div
              key={i}
              className={`col text-center pt-2 px-0 mb-3`}
              style={{ lineHeight: 1, height: '1.044in' }}
            >
              <p className="mb-1 text-truncate font-size-12 pt-1 text-center">
                {props.barcodeData.product_name} {props.barcodeData.name}
              </p>
              {store.get('show_mrp') == 1 && (
                <p className="mb-0">
                  <span className="font-size-12">
                    {this.props?.user?.selectedCompany?.labels?.mrp}
                  </span>
                  {props.barcodeData.discount != 0 && (
                    <span>
                      <del
                        className="text-gray-11 font-weight-bold ml-1"
                        style={{ fontSize: store.get('mrp_font_size') }}
                      >
                        {props.barcodeData.price_with_tax}
                      </del>
                    </span>
                  )}

                  <span
                    className="font-weight-bold font-size-12"
                    style={{ fontSize: store.get('mrp_font_size') }}
                  >
                    <span className="ml-2 mr-1">
                      {this.props?.user?.selectedCompany?.currency_symbol}
                    </span>
                    {!props.barcodeData.discount_amount
                      ? (
                          props.barcodeData.price_with_tax *
                          (1 - props.barcodeData.discount / 100)
                        ).toFixed(2)
                      : (
                          props.barcodeData.price_with_tax - props.barcodeData.discount_amount
                        ).toFixed(2)}
                  </span>
                </p>
              )}
              {store.get('show_pkg_date') && (
                <p className="font-size-7 mt-0 mb-0">PKD ON: {dayjs().format('DD-MMM-YY')}</p>
              )}
              {props.barcodeData.barcode_id != null && (
                <Barcode
                  width={1.35}
                  height={35}
                  fontSize={10}
                  value={props.barcodeData.barcode_id + ''}
                />
              )}
            </div>
          ))}
        </div>

        {props.barcodeData.variants &&
          props.barcodeData.variants.map(
            (variant, v) =>
              variant.barcode_id != null &&
              variant.barcode_id != '' && (
                <div className="row mt-5 pt-3 px-3 row-cols-5 text-center">
                  {[...Array(65)].map((item, i) => (
                    <div
                      key={i}
                      className={`col text-center pt-2 px-0`}
                      style={{ lineHeight: 1, height: '1.044in' }}
                    >
                      <p className="mb-1 text-truncate font-size-12 pt-1 text-center">
                        {variant.name}
                      </p>
                      {store.get('show_mrp') == 1 && (
                        <p className="mb-0">
                          <span className="font-size-12">
                            {this.props?.user?.selectedCompany?.labels?.mrp}
                          </span>
                          <span>
                            <span className="ml-2 mr-1 font-size-12">
                              {this.props?.user?.selectedCompany?.currency_symbol}
                            </span>
                            <span className="font-weight-bold font-size-12">
                              {variant.is_price_with_tax == true
                                ? variant.price_with_tax
                                : variant.price}
                            </span>
                          </span>
                        </p>
                      )}
                      {store.get('show_pkg_date') && (
                        <p className="font-size-7 mt-0 mb-0">
                          PKD ON: {dayjs().format('DD-MMM-YY')}
                        </p>
                      )}
                      {variant.barcode_id != null && (
                        <Barcode
                          width={1.35}
                          height={35}
                          fontSize={10}
                          value={variant.barcode_id + ''}
                        />
                      )}
                    </div>
                  ))}
                </div>
              ),
          )}
      </div>
    )
  }
}

class BigBarcode extends Component {
  constructor(props) {
    super(props)
  }
  render() {
    var props = this.props
    var width = 12 / props.type
    return (
      <div className="barcode">
        <div className="row px-3">
          <div className={`col pt-2 px-3`}>
            <p
              className="mb-1 text-truncate pt-1 font-weight-bold"
              style={{ fontSize: store.get('product_name_font_size') }}
            >
              {props.barcodeData.product_name} {props.barcodeData.name}
            </p>
            {/*{props.barcodeData.description != '' && props.barcodeData.description != '<p><br></p>' && (
              <p className="mb-0">
                <span className="font-size-18">
                  {props.barcodeData.description
                    .replace(/<\s*br[^>]?>/, '\n')
                    .replace(/(<([^>]+)>)/g, '')}
                </span>
              </p>
            )}*/}
            {store.get('show_mrp') == 1 && (
              <p className="mb-0">
                <span className="font-weight-bold" style={{ fontSize: store.get('mrp_font_size') }}>
                  {this.props?.user?.selectedCompany?.labels?.mrp}
                </span>
                <span>
                  {props.barcodeData.discount != 0 && (
                    <span>
                      <del
                        className="text-gray-11 font-weight-bold ml-1"
                        style={{ fontSize: store.get('mrp_font_size') }}
                      >
                        {props.barcodeData.price_with_tax}
                      </del>
                    </span>
                  )}

                  <span
                    className="font-weight-bold font-size-12"
                    style={{ fontSize: store.get('mrp_font_size') }}
                  >
                    <span className="ml-2 mr-1">
                      {this.props?.user?.selectedCompany?.currency_symbol}
                    </span>
                    {!props.barcodeData.discount_amount
                      ? (
                          props.barcodeData.price_with_tax *
                          (1 - props.barcodeData.discount / 100)
                        ).toFixed(2)
                      : (
                          props.barcodeData.price_with_tax - props.barcodeData.discount_amount
                        ).toFixed(2)}
                  </span>
                </span>
              </p>
            )}
            {store.get('show_pkg_date') && (
              <p className="font-size-7 mt-0 mb-0">PKD ON: {dayjs().format('DD-MMM-YY')}</p>
            )}
            {props.barcodeData.barcode_id != null && (
              <Barcode
                width={3.6}
                height={81}
                fontSize={24}
                value={props.barcodeData.barcode_id + ''}
              />
            )}
            <p className="mb-0">
              <span className="font-size-18">By</span>
              <br />
              <span className="font-size-18">{store.get('organization_name')}</span>
            </p>
          </div>
        </div>

        {props.barcodeData.variants &&
          props.barcodeData.variants.map(
            (variant, v) =>
              variant.barcode_id != null &&
              variant.barcode_id != '' && (
                <div className="row px-3">
                  <div className={`col pt-2 px-3`}>
                    <p
                      className="mb-1 text-truncate pt-1 font-weight-bold"
                      style={{ fontSize: store.get('product_name_font_size') }}
                    >
                      {variant.name}
                    </p>
                    {/*{props.barcodeData.description != '' && props.barcodeData.description != '<p><br></p>' && (
               <p className="mb-0">
                 <span className="font-size-18">
                   {props.barcodeData.description
                     .replace(/<\s*br[^>]?>/, '\n')
                     .replace(/(<([^>]+)>)/g, '')}
                 </span>
               </p>
             )}*/}
                    {store.get('show_mrp') == 1 && (
                      <p className="mb-0">
                        <span
                          className="font-weight-bold"
                          style={{ fontSize: store.get('mrp_font_size') }}
                        >
                          {this.props?.user?.selectedCompany?.labels?.mrp}
                        </span>
                        <span>
                          {props.barcodeData.discount != 0 && (
                            <span>
                              <del
                                className="text-gray-11 font-weight-bold ml-1"
                                style={{ fontSize: store.get('mrp_font_size') }}
                              >
                                {props.barcodeData.price_with_tax}
                              </del>
                            </span>
                          )}

                          <span
                            className="font-weight-bold font-size-12"
                            style={{ fontSize: store.get('mrp_font_size') }}
                          >
                            <span className="ml-2 mr-1">
                              {this.props?.user?.selectedCompany?.currency_symbol}
                            </span>
                            {!props.barcodeData.discount_amount
                              ? (
                                  props.barcodeData.price_with_tax *
                                  (1 - props.barcodeData.discount / 100)
                                ).toFixed(2)
                              : (
                                  props.barcodeData.price_with_tax -
                                  props.barcodeData.discount_amount
                                ).toFixed(2)}
                          </span>
                        </span>
                      </p>
                    )}
                    {store.get('show_pkg_date') && (
                      <p className="font-size-7 mt-0 mb-0">PKD ON: {dayjs().format('DD-MMM-YY')}</p>
                    )}
                    {props.barcodeData.barcode_id != null && (
                      <Barcode
                        width={3.6}
                        height={81}
                        fontSize={24}
                        value={props.barcodeData.barcode_id + ''}
                      />
                    )}
                    <p className="mb-0">
                      <span className="font-size-18">By</span>
                      <br />
                      <span className="font-size-18">{store.get('organization_name')}</span>
                    </p>
                  </div>
                </div>
              ),
          )}
      </div>
    )
  }
}

class PrintBarcode8x4 extends Component {
  constructor(props) {
    super(props)
  }
  render() {
    var props = this.props
    var width = 12 / props.type
    return (
      <div className="barcode">
        <div className="row mt-1  px-1 text-center">
          {[...Array(40)].map((item, i) => (
            <div
              key={i}
              className={`col-3 text-center pt-1 px-0`}
              style={{ lineHeight: 1, height: '1.475in' }}
            >
              <p className="mb-1 font-size-16 pt-2 text-center text-truncate">
                {props.barcodeData.product_name} {props.barcodeData.name}
              </p>
              {store.get('show_mrp') == 1 && (
                <p className="mb-0">
                  <span className="font-size-16">
                    {this.props?.user?.selectedCompany?.labels?.mrp}
                  </span>
                  {props.barcodeData.discount != 0 && (
                    <span>
                      <del
                        className="text-gray-11 font-weight-bold ml-1"
                        style={{ fontSize: store.get('mrp_font_size') }}
                      >
                        {props.barcodeData.price_with_tax}
                      </del>
                    </span>
                  )}

                  <span
                    className="font-weight-bold font-size-12"
                    style={{ fontSize: store.get('mrp_font_size') }}
                  >
                    <span className="ml-2 mr-1">
                      {this.props?.user?.selectedCompany?.currency_symbol}
                    </span>

                    {!props.barcodeData.discount_amount
                      ? (
                          props.barcodeData.price_with_tax *
                          (1 - props.barcodeData.discount / 100)
                        ).toFixed(2)
                      : (
                          props.barcodeData.price_with_tax - props.barcodeData.discount_amount
                        ).toFixed(2)}
                  </span>
                </p>
              )}
              {store.get('show_pkg_date') && (
                <p className="font-size-12 mt-0 mb-0">PKD ON: {dayjs().format('DD-MMM-YY')}</p>
              )}
              {props.barcodeData.barcode_id != null && (
                <Barcode
                  width={1.9}
                  height={48}
                  fontSize={14}
                  value={props.barcodeData.barcode_id + ''}
                />
              )}
            </div>
          ))}
        </div>

        {props.barcodeData.variants &&
          props.barcodeData.variants.map(
            (variant, v) =>
              variant.barcode_id != null &&
              variant.barcode_id != '' && (
                <div className="row mt-1  px-1 text-center">
                  {[...Array(32)].map((item, i) => (
                    <div
                      key={i}
                      className={`col-3 text-center pt-1 px-0`}
                      style={{ lineHeight: 1, height: '1.475in' }}
                    >
                      <p className="mb-1 font-size-16 pt-2 text-center text-truncate">
                        {variant.name}
                      </p>
                      {store.get('show_mrp') == 1 && (
                        <p className="mb-0">
                          <span className="font-size-16">
                            {this.props?.user?.selectedCompany?.labels?.mrp}
                          </span>
                          <span>
                            <span className="ml-2 mr-1">
                              {this.props?.user?.selectedCompany?.currency_symbol}
                            </span>
                            <span className="font-weight-bold font-size-16">
                              {variant.is_price_with_tax == true
                                ? variant.price_with_tax
                                : variant.price}
                            </span>
                          </span>
                        </p>
                      )}
                      {store.get('show_pkg_date') && (
                        <p className="font-size-12 mt-0 mb-0">
                          PKD ON: {dayjs().format('DD-MMM-YY')}
                        </p>
                      )}
                      {variant.barcode_id != null && (
                        <Barcode
                          width={1.9}
                          height={48}
                          fontSize={14}
                          value={variant.barcode_id + ''}
                        />
                      )}
                    </div>
                  ))}
                </div>
              ),
          )}
      </div>
    )
  }
}

export class ProductBarcode extends Component {
  constructor(props) {
    super(props)
    this.state = {
      flag: false,
      ops: null,
      error: '',
      value: 2,
      barcodeData: { product_id: 123 + '' },
      showEditDetails: false,
    }
  }

  componentDidMount() {
    this.props.onRef(this)
  }

  componentWillUnmount() {
    this.props.onRef(null)
  }

  showModal = async data => {
    this.setState({ barcodeData: data, backupData: { ...data } }, () =>
      this.setState({ flag: true }),
    )
  }

  handleCancel = () => {
    this.setState({ flag: false })
  }

  onChange = e => {
    this.setState({ value: e })
  }

  setShowEditDetails = val => {
    this.setState({ showEditDetails: val })
  }

  updateData = data => {
    this.setState({ barcodeData: data })
  }

  render() {
    return (
      <>
        {this.state.flag && (
          <BarcodeDetailsForm
            showDrawer={this.state.showEditDetails}
            setShowDrawer={this.setShowEditDetails}
            data={this.state.barcodeData}
            backupData={this.state.backupData}
            updateData={this.updateData}
          />
        )}
        <Modal
          title={
            <>
              Barcode
              <BetaBadge />
            </>
          }
          width={'100vw'}
          open={this.state.flag}
          onCancel={() => this.setState({ flag: false, barcodeData: { product_id: 123 + '' } })}
          closeIcon={<i className="fa-solid fa-lg fa-xmark"></i>}
          footer={
            <div className="d-flex flex-row justify-content-end">
              <>
                <Button
                  onClick={() => this.setShowEditDetails(true)}
                  type="warning"
                  style={{ marginRight: 10 }}
                >
                  <i className="fa fa-edit mr-2" />
                  Edit
                </Button>
              </>
              {this.state.value == 1 && (
                <>
                  <ReactToPrint
                    key={1}
                    trigger={() => (
                      <button style={{ visibility: 'hidden', display: 'none' }} id="print-button1">
                        Print
                      </button>
                    )}
                    content={() => this.componentRef1}
                  ></ReactToPrint>
                  <Form
                    key="submit"
                    onFinish={() => document.getElementById('print-button1').click()}
                  >
                    <Button htmlType="submit" type="primary">
                      Print
                    </Button>
                  </Form>
                </>
              )}
              {this.state.value == 2 && (
                <>
                  <ReactToPrint
                    key={1}
                    trigger={() => (
                      <button style={{ visibility: 'hidden', display: 'none' }} id="print-button2">
                        Print
                      </button>
                    )}
                    content={() => this.componentRef2}
                  ></ReactToPrint>
                  <Form
                    key="submit"
                    onFinish={() => document.getElementById('print-button2').click()}
                  >
                    <Button htmlType="submit" type="primary">
                      Print
                    </Button>
                  </Form>
                </>
              )}
              {this.state.value == 3 && (
                <>
                  <ReactToPrint
                    key={1}
                    trigger={() => (
                      <button style={{ visibility: 'hidden', display: 'none' }} id="print-button3">
                        Print
                      </button>
                    )}
                    content={() => this.componentRef3}
                  ></ReactToPrint>
                  <Form
                    key="submit"
                    onFinish={() => document.getElementById('print-button3').click()}
                  >
                    <Button htmlType="submit" type="primary">
                      Print
                    </Button>
                  </Form>
                </>
              )}
              {this.state.value == 4 && (
                <>
                  <ReactToPrint
                    key={1}
                    trigger={() => (
                      <button style={{ visibility: 'hidden', display: 'none' }} id="print-button4">
                        Print
                      </button>
                    )}
                    content={() => this.componentRef4}
                  ></ReactToPrint>
                  <Form
                    key="submit"
                    onFinish={() => document.getElementById('print-button4').click()}
                  >
                    <Button htmlType="submit" type="primary">
                      Print
                    </Button>
                  </Form>
                </>
              )}

              {this.state.value == 5 && (
                <>
                  <ReactToPrint
                    key={1}
                    trigger={() => (
                      <button style={{ visibility: 'hidden', display: 'none' }} id="print-button5">
                        Print
                      </button>
                    )}
                    content={() => this.componentRef5}
                  ></ReactToPrint>
                  <Form
                    key="submit"
                    onFinish={() => document.getElementById('print-button5').click()}
                  >
                    <Button htmlType="submit" type="primary">
                      Print
                    </Button>
                  </Form>
                </>
              )}
              {this.state.value == 6 && (
                <>
                  <ReactToPrint
                    key={1}
                    trigger={() => (
                      <button style={{ visibility: 'hidden', display: 'none' }} id="print-button6">
                        Print
                      </button>
                    )}
                    content={() => this.componentRef6}
                  ></ReactToPrint>
                  <Form
                    key="submit"
                    onFinish={() => document.getElementById('print-button6').click()}
                  >
                    <Button htmlType="submit" type="primary">
                      Print
                    </Button>
                  </Form>
                </>
              )}
              {this.state.value == 7 && (
                <>
                  <ReactToPrint
                    key={1}
                    trigger={() => (
                      <button style={{ visibility: 'hidden', display: 'none' }} id="print-button7">
                        Print
                      </button>
                    )}
                    content={() => this.componentRef7}
                  ></ReactToPrint>
                  <Form
                    key="submit"
                    onFinish={() => document.getElementById('print-button7').click()}
                  >
                    <Button htmlType="submit" type="primary">
                      Print
                    </Button>
                  </Form>
                </>
              )}
              {this.state.value == 8 && (
                <>
                  <ReactToPrint
                    key={1}
                    trigger={() => (
                      <button style={{ visibility: 'hidden', display: 'none' }} id="print-button8">
                        Print
                      </button>
                    )}
                    content={() => this.componentRef8}
                  ></ReactToPrint>
                  <Form
                    key="submit"
                    onFinish={() => document.getElementById('print-button8').click()}
                  >
                    <Button htmlType="submit" type="primary">
                      Print
                    </Button>
                  </Form>
                </>
              )}
            </div>
          }
        >
          <div>
            <Tabs defaultActiveKey="2" destroyInactiveTabPane={true} onChange={this.onChange}>
              <TabPane tab="1 x 2" key="2">
                <PrintBarcode
                  barcodeData={this.state.barcodeData}
                  type={2}
                  user={this.props.user}
                  ref={el => (this.componentRef2 = el)}
                />
              </TabPane>
              <TabPane tab="1 x 4" key="4">
                <PrintBarcode
                  barcodeData={this.state.barcodeData}
                  type={4}
                  user={this.props.user}
                  ref={el => (this.componentRef4 = el)}
                />
              </TabPane>
              <TabPane tab="1 x 1" key="1">
                <PrintBarcode
                  barcodeData={this.state.barcodeData}
                  type={1}
                  user={this.props.user}
                  ref={el => (this.componentRef1 = el)}
                />
              </TabPane>
              <TabPane tab="1 x 3" key="3">
                <PrintBarcode
                  barcodeData={this.state.barcodeData}
                  type={3}
                  user={this.props.user}
                  ref={el => (this.componentRef3 = el)}
                />
              </TabPane>
              <TabPane tab="A4 (8 x 2)" key="5">
                <PrintBarcode8x2
                  barcodeData={this.state.barcodeData}
                  type={2}
                  user={this.props.user}
                  ref={el => (this.componentRef5 = el)}
                />
              </TabPane>
              <TabPane tab="A4 40 Labels (10 x 4)" key="6">
                <PrintBarcode8x4
                  barcodeData={this.state.barcodeData}
                  type={2}
                  user={this.props.user}
                  ref={el => (this.componentRef6 = el)}
                />
              </TabPane>
              <TabPane tab="A4  65 Lables (13 x 5)" key="7">
                <PrintBarcode13x5
                  barcodeData={this.state.barcodeData}
                  type={2}
                  user={this.props.user}
                  ref={el => (this.componentRef7 = el)}
                />
              </TabPane>
              <TabPane tab="Square Label" key="8">
                <BigBarcode
                  barcodeData={this.state.barcodeData}
                  type={2}
                  user={this.props.user}
                  ref={el => (this.componentRef8 = el)}
                />
              </TabPane>
            </Tabs>
          </div>
        </Modal>
      </>
    )
  }
}

function mapStateToProps(state) {
  return {
    user: state.user,
  }
}

export default connect(mapStateToProps)(ProductBarcode)
