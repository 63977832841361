import { all, call, put, takeLatest } from 'redux-saga/effects'
import { getAPIData } from 'services/jwt'
import { set_lead_options } from './actions'
import { GET_LEAD_OPTIONS } from './types'

const agent_login = async (name, password) => {}

export function* LOGIN({ payload }) {
  const { name, password } = payload
  const success = yield call(agent_login, name, password)
}

export function* LOAD_CURRENT_ACCOUNT() {}

export function* LOGOUT() {}

export function* get_lead_options() {
  const data = yield call(async () => await getAPIData('leads', 'options'))
  if (data.success) {
    yield put(set_lead_options(data))
  }
}

export default function* agents() {
  yield all([takeLatest(GET_LEAD_OPTIONS, get_lead_options)])
}
