import { LoadingOutlined, PlusOutlined } from '@ant-design/icons'
import {
  Affix,
  Button,
  Card,
  Col,
  Collapse,
  DatePicker,
  Drawer,
  Form,
  Input,
  InputNumber,
  Modal,
  Popconfirm,
  Radio,
  Row,
  Select,
  Space,
  Switch,
  Tooltip,
  Upload,
  message,
} from 'antd'
import TextArea from 'antd/lib/input/TextArea'
import NewBadge from 'components/badges/new'
import CustomColumnsForm from 'components/forms/customColumns'
import SaveChangesModal from 'components/modal/saveChangesModal'
import { EnhancedSubscriptionModal } from 'components/modal/subscription'
import SubscriptionPlanModal from 'components/modal/subscription/subscriptionPlan'
import CopyText from 'components/other/CopyText'
import SectionHeader from 'components/other/sectionHeader'
import { UserAvatar, getBase64 } from 'components/other/utility'
import Visibility from 'components/other/visibility'
import dayjs from 'dayjs'
import _ from 'lodash'
import React, { Component } from 'react'
import { isMobile } from 'react-device-detect'
import { FormattedMessage } from 'react-intl'
import { connect } from 'react-redux'
import { getCustomerColumns } from 'redux/bulkEdit/actions'
import {
  getCountries,
  getStates,
  getTdsSections,
  get_tcs_sections,
  set_all_doc_counts,
} from 'redux/document/actions'
import { getAPI, getAPIData, getMultipartAPI } from 'services/jwt'
import store from 'store'
import { CONSTANTS } from 'utils/constants'
import AddressForm from './addressForm'
const { Option } = Select
const { Panel } = Collapse

var emptyCustomerData = {
  customer_id: '',
  name: '',
  phone: '',
  gst: 0,
  email: '',
  discount: 0,
  gstin: '',
  company_name: '',
  opening_balance: undefined,
  balance: 0,
  is_same: false,
  credit_limit: 0,
  billing: {
    address_1: '',
    address_2: '',
    city: '',
    state1: undefined,
    pincode: '',
    country: undefined,
  },
  shipping: {
    is_same: false,
    address_1: '',
    address_2: '',
    city: '',
    state1: undefined,
    pincode: '',
    country: undefined,
  },
  billing_address: [],
  shipping_address: [],
  opening_balance_type: 1,
  dial_code: '91',
  profile_image: '',
}

const emptyAddress = {
  line1: '',
  line2: '',
  city: '',
  state1: '',
  pincode: '',
  is_same: false,
  country: undefined,
}

export class CustomerForm extends Component {
  formRef = React.createRef()
  formRefAddress = React.createRef()
  buttonRef = React.createRef()
  opening_balance = React.createRef()
  addressFormRef = React.createRef()
  deleteModalRef = React.createRef()
  dateFormat = 'DD-MM-YYYY'
  subscriptionPlanRef = React.createRef()
  saveChangesRef = React.createRef()
  constructor(props) {
    super(props)
    emptyCustomerData.dial_code = props?.user?.selectedCompany?.dial_code
    this.state = {
      shippingAddress: false,
      isFirstTime: false,
      isEdit: false,
      formFlag: false,
      states: [],
      selectedData: { ...emptyCustomerData },
      loading: false,
      fetchLoading: false,
      telNo: '',
      opening_balance_type: 1,
      opening_balance: undefined,
      selectedAddressType: 'billing',
      selectedAddrID: 0,
      phone: '',
      countries: [],
      dial_code: props?.user?.selectedCompany?.dial_code,
      custom_fields: [],
      tags: [],
      is_tds: false,
      cc_emails: [],
      selected_tags: [],
      is_tcs: false,
      addressDrawerVisible: false,
      export_customer: false,
      show_gstin_exist: false,
      add_customer_with_existing_gstin: false,
      existing_customers: [
        {
          name: '',
          phone: '',
          email: '',
          gstin: '',
        },
      ],
      initialData: {},
      billing_address_updated: false,
      shipping_address_updated: false,
      selected_country_code: undefined,
    }
    this._onSaveChanges = this._onSaveChanges.bind(this)
    this.onSaveChanges = _.throttle(this._onSaveChanges, 2000)
    this._onAddressFinish = this._onAddressFinish.bind(this)
    this.onAddressFinish = _.throttle(this._onAddressFinish, 2000)
  }

  onShipingChange = e => {
    this.setState({ shippingAddress: e.target.checked })
  }

  onChangeOpeningBalanceType = e => {
    this.setState({ opening_balance_type: e.target.value })
  }

  onChangeOpeningBalance = e => {
    this.setState({ opening_balance: e.target.value })
  }

  async componentDidMount() {
    this.props.onRef(this)

    if (this.props?.countries?.length == 0) {
      this.props?.dispatch(getCountries())
    }
    if (this.props?.states?.length == 0) {
      this.props?.dispatch(getStates())
    }
    // if (this.props?.custom_fields?.length == 0) {
    //   this.props?.dispatch(getCustomerColumns())
    // }
    if (this.props?.tds_sections?.length == 0) {
      this.props?.dispatch(getTdsSections())
    }
    if (this.props?.tcs_sections?.length == 0) {
      this.props?.dispatch(get_tcs_sections())
    }
  }

  componentWillUnmount() {
    this.props.onRef(null)
    this.onSaveChanges.cancel()
    this.onAddressFinish.cancel()
  }

  onRefreshCustomFields = async () => {
    this.props.dispatch(getCustomerColumns())
  }
  checkCustomValues = (obj1, obj2) => {
    if (this.get_columns_list().length == 0) {
      return false
    }
    const keys1 = Object.keys(obj1)
    const keys2 = Object.keys(obj2)

    if (keys1.length !== keys2.length) {
      return true
    }

    for (let key of keys1) {
      if (!obj2.hasOwnProperty(key)) {
        return true
      }
    }

    for (let key of keys2) {
      if (!obj1.hasOwnProperty(key)) {
        return true
      }
    }

    for (let key of keys1) {
      return obj1[key] !== obj2[key]
    }

    return false
  }
  checkChangedValues = values => {
    return (
      this.state.initialData &&
      (this.state.initialData.name != values.name ||
        this.state.initialData.email != values.email ||
        this.state.initialData.phone != this.state.phone ||
        this.state.initialData.company_name != values.company_name ||
        this.state.initialData.gstin != values.gstin ||
        this.state.initialData.dial_code != this.state.dial_code ||
        this.checkCustomValues(this.state.initialData.custom_values, values.custom_values) ||
        this.state.billing_address_updated ||
        this.state.shipping_address_updated)
    )
  }
  onFinish = values => {
    if (this.state.formFlag && this.checkChangedValues(values)) {
      this.saveChangesRef.current.showModal(values)
    } else {
      this.onSaveChanges(values)
    }
  }
  _onSaveChanges = async values => {
    this.setState({ loading: true })

    let tags = []

    for (let i = 0; i < this.state.selected_tags.length; i++) {
      var t = this.state.tags.find(tag => tag.tag_name == this.state.selected_tags[i])
      var tag = { tag_name: this.state.selected_tags[i], id: '' }
      if (t) {
        tag.id = t.tag_id
      } else {
        tag.id = -1
        if (!tag.tag_name) {
          tag.tag_name = ''
        }
      }

      tags.push(tag)
    }
    let custom_fields = {}
    this.get_columns_list().map(item => {
      if (item.field_type == 'date' || item.field_type == 'datetime') {
        custom_fields[item.custom_field_id] = values['custom_values'][item.custom_field_id]
          ? values['custom_values'][item.custom_field_id].format(
              item.field_type == 'date' ? this.dateFormat : 'DD-MM-YYYY HH:mm:ss',
            )
          : ''
      } else {
        custom_fields[item.custom_field_id] = values['custom_values'][item.custom_field_id]
          ? values['custom_values'][item.custom_field_id]
          : ''
      }
    })
    var reqData = {
      ...this.state.selectedData,
      ...values,
      tags: tags,
      phone: this.state.phone,
      name: values.name,
      company_name: values.company_name,
      opening_balance: this.state.opening_balance == undefined ? 0 : this.state.opening_balance,
      opening_balance_type: this.state.opening_balance_type,
      export_customer: this.state.export_customer ? 1 : 0,
      dial_code: this.state.dial_code,
      cc_emails: this.state.cc_emails.length > 0 ? this.state.cc_emails.join(',') : '',
      custom_values: custom_fields,
      custom_fields,
      profile_image: this.state.selectedData.profile_image,
    }

    var url = 'add'
    if (this.state.formFlag) {
      reqData = {
        id: this.state.selectedData.customer_id,
        ...reqData,
        is_tcs: this.state.is_tcs,
        is_tds: this.state.is_tds,
        tcs_section_id: this.state.is_tcs ? reqData.tcs_section_id : -1,
        tds_section_id: this.state.is_tds ? reqData.tds_section_id : 0,
      }
      url = 'update'
    }
    if (this.state.is_tcs && this.state.is_tds) {
      message.error('TCS and TDS cannot be applied together')
      this.setState({ loading: false })
      return
    }
    if (this.state.is_tds && !reqData.tds_section_id) {
      message.error('Please select TDS section')
      this.setState({ loading: false })
      return
    }
    if (this.state.is_tcs && !reqData.tcs_section_id) {
      message.error('Please select TCS section')
      this.setState({ loading: false })
      return
    }

    // check GSTIN existance
    if (
      reqData.gstin != undefined &&
      reqData.gstin != '' &&
      !this.state.add_customer_with_existing_gstin &&
      !this.state.formFlag
    ) {
      const gstin_exist = await getAPI('v2/customer', 'check_gstin', {
        gstin: reqData.gstin,
      })
      if (
        (gstin_exist.existing_customers.length > 0 && !this.state.formFlag) ||
        (this.state.formFlag && gstin_exist.existing_customers.length > 1)
      ) {
        message.error(`${this.props?.user?.selectedCompany?.tax_id_label} already exist`)
        this.setState({
          show_gstin_exist: true,
          loading: false,
          existing_customers: gstin_exist.existing_customers,
        })
        return
      }
    }

    const data = await getAPI('v2/customer', url, reqData)
    this.setState({ loading: false })
    if (data) {
      this.setState({
        visible: false,
        selectedData: { ...emptyCustomerData },
        cc_emails: [],
        add_customer_with_existing_gstin: false,
      })
      const customerData = await getAPI('v2/customer', 'get_details', { id: data.id })
      this.props.onAPICalled(data.id, values.name, customerData)
      message.success(
        url == 'add' ? 'Customer added successfully' : 'Customer updated successfully',
      )
      const payload = {
        ...this.props.all_doc_counts,
        customers: 2,
      }

      this.props.dispatch(set_all_doc_counts(payload))
    }
  }

  onAdd = (name = '') => {
    if (this.props?.custom_fields?.length == 0) {
      this.props?.dispatch(getCustomerColumns())
    }

    this.setState(
      {
        visible: true,
        formFlag: false,
        opening_balance: emptyCustomerData.opening_balance,
        opening_balance_type: emptyCustomerData.opening_balance_type,
        selectedData: { ...emptyCustomerData, billing_address: [], shipping_address: [] },
        cc_emails: [],
        phone: '',
        selected_tags: [],
        is_tcs: false,
        is_tds: false,
        dial_code: this.props?.user?.selectedCompany?.dial_code,
      },
      async () => {
        const tags = await getAPIData('v2/customer', 'tags')
        if (tags.success) {
          this.setState({ tags: tags.tags })
        }
        this.formRef.current.setFieldsValue({
          ...emptyCustomerData,
          name: name,
        })
      },
    )
  }

  onEdit = async id => {
    this.onFill(id)
  }

  onUpdate = async id => {
    if (this.props?.custom_fields?.length == 0) {
      this.props?.dispatch(getCustomerColumns())
    }
    var req = {
      id: id,
    }
    var data = await getAPI('v2/customer', 'get_details', req)
    const tags = await getAPIData('v2/customer', 'tags')
    if (tags.success) {
      this.setState({ tags: tags.tags })
    }
    this.setState(
      {
        visible: true,
        formFlag: true,
        selectedData: data.customer_details[0],
        opening_balance:
          data.customer_details[0].opening_balance < 0
            ? data.customer_details[0].opening_balance * -1
            : data.customer_details[0].opening_balance,
        opening_balance_type: data.customer_details[0].opening_balance > 0 ? 2 : 1,
        is_tds: data.customer_details[0].is_tds == 1,
        is_tcs: data.customer_details[0].is_tcs == 1,
      },
      () => {
        this.formRef.current.setFieldsValue({
          billing_address: data.customer_details[0]['billing_address'].filter(
            a => a.is_delete == 0,
          ),
          shipping_address: data.customer_details[0]['shipping_address'].filter(
            a => a.is_delete == 0,
          ),
          discount:
            data.customer_details[0].discount == null ? 0 : data.customer_details[0].discount,
          opening_balance_type: data.customer_details[0].opening_balance > 0 ? 2 : 1,
          // tags: data.customer_details[0].tags.map(item => item.tag_id.toString()),
        })
      },
    )
  }
  get_columns_list = () => {
    if (this.state.formFlag) return this.state.selectedData.custom_fields
    return this.props?.custom_fields?.filter(item => item.is_active == 1)
  }

  onFill = async id => {
    if (this.props?.custom_fields?.length == 0) {
      this.props?.dispatch(getCustomerColumns())
    }
    var req = {
      id: id,
    }
    var data = await getAPI('v2/customer', 'get_details', req)
    const tags = await getAPIData('v2/customer', 'tags')

    var selected_tags = data.customer_details[0].tags?.map(item => {
      let t = tags.tags?.find(tag => tag.tag_id == item.tag_id)
      if (t) {
        return t.tag_name
      }
    })

    let custom_fields = {}

    if (data.customer_details[0]['price_list_id'] == -1) {
      data.customer_details[0]['price_list_id'] = undefined
    }

    if (data.customer_details[0].tcs_section_id == -1) {
      data.customer_details[0].tcs_section_id = undefined
    }
    if (data.customer_details[0].tds_section_id == 0) {
      data.customer_details[0].tds_section_id = undefined
    }
    data.customer_details[0]['custom_fields'].map(item => {
      if (item.value == '') {
        item.value = undefined
      }
      if (item.field_type == 'date' || item.field_type == 'datetime') {
        // console.log(dayjs(item.value, { format: 'YYYY-MM-DD', strict: true }).isValid())
        const dateFormatRegex = /^\d{2}-\d{2}-\d{4}/
        if (item.value && !dateFormatRegex.test(item.value)) {
          item.value = undefined
        } else {
          item.value = item.value ? dayjs(item.value, this.dateFormat) : undefined
        }
      }
      custom_fields[item.custom_field_id] = item.value
    })
    data.customer_details[0]['custom_values'] = custom_fields
    this.setState(
      {
        visible: true,
        formFlag: true,
        selectedData: data.customer_details[0],
        opening_balance:
          data.customer_details[0].opening_balance < 0
            ? data.customer_details[0].opening_balance * -1
            : data.customer_details[0].opening_balance,
        opening_balance_type: data.customer_details[0].opening_balance > 0 ? 2 : 1,
        export_customer: data.customer_details[0].export_customer == 1,
        dial_code: data.customer_details[0].dial_code,
        phone: data.customer_details[0].phone,
        is_tds: data.customer_details[0].is_tds == 1,
        is_tcs: data.customer_details[0].is_tcs == 1,
        cc_emails:
          data.customer_details[0].cc_emails != ''
            ? data.customer_details[0].cc_emails?.split(',')
            : [],
        selected_tags: selected_tags,
        tags: tags.tags,
        initialData: data.customer_details[0],
        billing_address_updated: false,
        shipping_address_updated: false,
      },
      () => {
        this.formRef.current.setFieldsValue({
          ...data.customer_details[0],
          billing_address: data.customer_details[0]['billing_address'].filter(
            a => a.is_delete == 0,
          ),
          shipping_address: data.customer_details[0]['shipping_address'].filter(
            a => a.is_delete == 0,
          ),
          discount:
            data.customer_details[0].discount == null ? 0 : data.customer_details[0].discount,
          opening_balance_type: data.customer_details[0].opening_balance > 0 ? 2 : 1,
          phone: data.customer_details[0].dial_code + data.customer_details[0].phone,
          tags: selected_tags,
          cc_emails: this.state.cc_emails,
        })
        // this.props.onAPICalled(data.customer_details[0].id, data.customer_details[0].name)
      },
    )
  }

  onClose = () => {
    this.setState({
      visible: false,
      selectedData: { ...emptyCustomerData },
      phone: '',
      dial_code: '91',
      add_customer_with_existing_gstin: false,
      shipping_address_updated: false,
      billing_address_updated: false,
      initialData: {},
    })
  }

  fetchGSTDetails = async () => {
    this.setState({ fetchLoading: true })
    const gstin = this.formRef.current.getFieldsValue()['gstin']
    var req = {
      gstin: gstin,
    }
    const data = await getAPI('user', 'fetch_gst_details', req)
    if (data) {
      const gst_data = data.response

      var values = {}
      if (data.status != 'Cancelled') {
        values['line1'] = data.response.billing.address_1
        values['line2'] = data.response.billing.address_2
        values['city'] = data.response.billing.city
        values['state'] = data.response.billing.state
        values['pincode'] = data.response.billing.pincode

        // as billing address is already present, edit the existing address
        if (
          this.state.selectedData.billing_address.length > 0 &&
          this.state.selectedData.customer_id != ''
        ) {
          var req = {
            addr_id: this.state.selectedData.billing_address[0]['addr_id'],
            customer_id: this.state.selectedData.customer_id,
            ...values,
          }
          var data1 = await getAPI('v2/customer', 'edit_billing_address', req)
          if (data1.success) {
            message.success(data1.message)
            this.onUpdate(this.state.selectedData.customer_id)
          }
        } else {
          if (this.state.selectedData.customer_id == '') {
            this.setState({
              selectedData: { ...this.state.selectedData, billing_address: [values] },
            })
          } else {
            var req = {
              customer_id: this.state.selectedData.customer_id,
              ...values,
            }
            var data1 = await getAPI('v2/customer', 'add_billing_address', req)
            if (data1.success) {
              message.success(data1.message)
              this.onUpdate(this.state.selectedData.customer_id)
            }
          }
        }

        await this.formRef.current.setFieldsValue({
          company_name: gst_data['company_name'],
          pan: gstin.slice(2, 12),
        })

        // await this.formRef.current.submit()
      } else {
        message.error(
          `Billing address not found for this ${this.props?.user?.selectedCompany?.tax_id_label}`,
        )
      }
    }
    this.setState({ fetchLoading: false })
  }

  addressDelete = async (address_type, addr_id) => {
    var req = {
      address_type: address_type,
      addr_id: addr_id,
      customer_id: this.state.selectedData.customer_id,
    }

    var data = await getAPI('v2/customer', 'delete_address', req)
    if (data.success) {
      message.success(data.message)
      // update in the selectedData
      this.onUpdate(this.state.selectedData.customer_id)
    }
  }

  addressDeleteWhenAddingNewCustomer = async (address_type, addr_id) => {
    var req = {
      address_type: address_type,
      addr_id: addr_id,
      customer_id: this.state.selectedData.customer_id,
    }

    if (address_type == 'billing') {
      var remaining_billing_address = this.state.selectedData.billing_address.filter(
        item => item.addr_id !== addr_id,
      )
      this.setState({
        selectedData: {
          ...this.state.selectedData,
          billing_address: remaining_billing_address,
          addressDrawerVisible: false,
        },
      })
    } else {
      var remaining_shipping_address = this.state.selectedData.shipping_address.filter(
        item => item.addr_id !== addr_id,
      )
      this.setState({
        selectedData: {
          ...this.state.selectedData,
          shipping_address: remaining_shipping_address,
        },
        addressDrawerVisible: false,
      })
    }
  }

  onAddressFinishWhenAddingNewCustomer = async (values, addressType, addr_id, export_customer) => {
    if (values.state1 == undefined && !export_customer) {
      message.error('State is Required')
      this.setState({ loading: false })
      return true
    }

    if (values.line1 == undefined || values.line1 == '') {
      message.error('Address Line 1 is Required')
      this.setState({ loading: false })
      return true
    }
    for (const key in values) {
      if (values.hasOwnProperty(key) && values[key] === undefined) {
        values[key] = ''
      }
    }
    values = { ...values, state: values.state1 }

    if (addr_id >= 0) {
      if (addressType == 'billing') {
        var billing_address = this.state.selectedData.billing_address
        var objIndex = this.state.selectedData.billing_address.findIndex(
          obj => obj.addr_id === addr_id,
        )
        billing_address[objIndex] = values
        billing_address[objIndex]['addr_id'] = addr_id
        billing_address[objIndex]['is_edit'] = true
        this.setState({
          selectedData: { ...this.state.selectedData, billing_address: billing_address },
        })
      } else {
        var shipping_address = this.state.selectedData.shipping_address
        var objIndex = this.state.selectedData.shipping_address.findIndex(
          obj => obj.addr_id === addr_id,
        )
        shipping_address[objIndex] = values
        shipping_address[objIndex]['addr_id'] = addr_id
        shipping_address[objIndex]['is_edit'] = true
        this.setState({
          selectedData: { ...this.state.selectedData, shipping_address: shipping_address },
        })
      }
    } else {
      if (addressType == 'billing') {
        var billing_address = this.state.selectedData.billing_address
        var objIndex = this.state.selectedData.billing_address.findIndex(
          obj =>
            obj.addr_id === addr_id ||
            (obj.line1 == values.line1 &&
              obj.line2 == values.line2 &&
              obj.city == values.city &&
              obj.state == values.state &&
              obj.pincode == values.pincode),
        )
        if (objIndex != -1) {
          billing_address[objIndex] = values
          billing_address[objIndex]['addr_id'] = addr_id
          billing_address[objIndex]['is_edit'] = true
        } else {
          values['is_edit'] = false
          values['addr_id'] = billing_address.length
          billing_address.push(values)
        }

        this.setState({
          selectedData: { ...this.state.selectedData, billing_address: billing_address },
        })
      } else {
        var shipping_address = this.state.selectedData.shipping_address
        var objIndex = this.state.selectedData.shipping_address.findIndex(
          obj =>
            obj.addr_id === addr_id ||
            (obj.line1 == values.line1 &&
              obj.line2 == values.line2 &&
              obj.city == values.city &&
              obj.state == values.state &&
              obj.pincode == values.pincode),
        )
        if (objIndex != -1) {
          shipping_address[objIndex] = values
          shipping_address[objIndex]['addr_id'] = addr_id
          shipping_address[objIndex]['is_edit'] = true
        } else {
          values['is_edit'] = false
          values['addr_id'] = shipping_address.length
          shipping_address.push(values)
        }

        this.setState({
          selectedData: { ...this.state.selectedData, shipping_address: shipping_address },
        })
      }
    }
    this.addressFormRef?.current?.onClose()
    this.setState({ loading: false, addressDrawerVisible: false, export_customer: false })
  }

  _onAddressFinish = async (values, addressType, addr_id, export_customer) => {
    if (values.state1 == undefined && !export_customer) {
      message.error('State is Required')
      this.setState({ loading: false })
      return true
    }
    if (values.line1 == undefined || values.line1 == '') {
      message.error('Address Line 1 is Required')
      this.setState({ loading: false })
      return true
    }
    for (const key in values) {
      if (values.hasOwnProperty(key) && values[key] === undefined) {
        values[key] = ''
      }
    }
    values = { ...values, state: values.state1 }

    if (addr_id >= 0) {
      if (addressType == 'billing') {
        var billing_address = this.state.selectedData.billing_address
        var objIndex = this.state.selectedData.billing_address.findIndex(
          obj => obj.addr_id === addr_id,
        )
        billing_address[objIndex] = values
        billing_address[objIndex]['addr_id'] = addr_id
        billing_address[objIndex]['is_edit'] = true

        var req = {
          addr_id: addr_id,
          customer_id: this.state.selectedData.customer_id,
          export_customer: export_customer,
          ...values,
        }
        var data = await getAPI('v2/customer', 'edit_billing_address', req)
        if (data.success) {
          message.success(data.message)
          // this.onFill(this.state.selectedData.customer_id)
          this.setState({
            selectedData: { ...this.state.selectedData, billing_address },
            billing_address_updated: true,
          })
        }
      } else {
        var shipping_address = this.state.selectedData.shipping_address
        var objIndex = this.state.selectedData.shipping_address.findIndex(
          obj => obj.addr_id === addr_id,
        )
        shipping_address[objIndex] = values
        shipping_address[objIndex]['addr_id'] = addr_id
        shipping_address[objIndex]['is_edit'] = true
        var req = {
          addr_id: addr_id,
          customer_id: this.state.selectedData.customer_id,
          ...values,
        }
        var data = await getAPI('v2/customer', 'edit_shipping_address', req)
        if (data.success) {
          message.success(data.message)
          // this.onFill(this.state.selectedData.customer_id)
          this.setState({
            selectedData: { ...this.state.selectedData, shipping_address },
            shipping_address_updated: true,
          })
        }
      }
    } else {
      if (addressType == 'billing') {
        var billing_address = this.state.selectedData.billing_address
        values['is_edit'] = false

        var req = {
          ...values,
          customer_id: this.state.selectedData.customer_id,
        }
        var data = await getAPI('v2/customer', 'add_billing_address', req)
        if (data.success) {
          message.success(data.message)
          // this.onFill(this.state.selectedData.customer_id)
          billing_address.push({ ...values, addr_id: data.addr_id })
          this.setState({
            selectedData: {
              ...this.state.selectedData,
              billing_address: billing_address,
            },
          })
        }
      } else {
        var shipping_address = this.state.selectedData.shipping_address
        values['is_edit'] = false

        var req = {
          ...values,
          customer_id: this.state.selectedData.customer_id,
        }
        var data = await getAPI('v2/customer', 'add_shipping_address', req)
        if (data.success) {
          shipping_address.push({ ...values, addr_id: data.addr_id })
          message.success(data.message)
          // this.onFill(this.state.selectedData.customer_id)
          this.setState({
            selectedData: {
              ...this.state.selectedData,
              shipping_address: shipping_address,
            },
          })
        }
      }
    }
    this.addressFormRef?.current?.onClose()
    this.setState({ loading: false, addressDrawerVisible: false, export_customer: export_customer })
  }

  onCloseAddressDrawer = () => {
    this.setState({ addressDrawerVisible: false })
  }
  onChangeExportCustomer = () => {
    this.setState({
      export_customer: !this.state.export_customer,
    })
  }
  customRequest = async ({ onSuccess, onError, file }) => {
    if (!file.url && !file.preview) {
      file.preview = await getBase64(file)
    }
    if (file) {
      const form = new FormData()
      form.append('profile_image', file)
      form.append('upload', false)
      form.append('is_delete', false)
      const data = await getMultipartAPI('v2/customer', 'update_profile_image', form)
      if (data.success) {
        message.success(data.message)
        this.setState({
          selectedData: {
            ...this.state.selectedData,
            profile_image: data.profile_image,
          },
        })
      }
    }
  }
  beforeUpload = file => {
    const isJpgOrPng = file.type === 'image/jpeg' || file.type === 'image/png'
    if (!isJpgOrPng) {
      message.error('Only JPG/PNG files are allowed!')
    }
    const isLt1M = file.size / 1024 / 1024 <= 2
    if (!isLt1M) {
      message.error('Image size must be less than 3MB!')
    }
    return isJpgOrPng && isLt1M
  }

  render() {
    if (
      this.state.selectedData.profile_image != '' &&
      this.state.selectedData.profile_image != undefined
    ) {
      var fileList = [
        {
          uid: '-1',
          name: 'customer_profile_image.png',
          status: 'done',
          url: this.state.selectedData.profile_image,
        },
      ]
    } else {
      var fileList = []
    }
    const formItemLayout = {
      layout: 'vertical',
      labelCol: {
        span: 24,
      },
      wrapperCol: {
        span: 24,
      },
    }
    const uploadButton = (
      <div>
        {this.state.loading ? <LoadingOutlined /> : <PlusOutlined />}
        <div style={{ marginTop: 8 }}>
          {' '}
          <FormattedMessage id="button.upload" />{' '}
        </div>
      </div>
    )
    return (
      <>
        <Drawer
          destroyOnClose={true}
          title={this.state.formFlag ? 'Update Customer' : 'Add Customer'}
          width={isMobile ? '100%' : '54%'}
          height={isMobile ? '90%' : '100%'}
          placement={isMobile ? 'top' : 'right'}
          maskClosable={false}
          closable={true}
          closeIcon={<i className="fa-solid fa-lg fa-xmark"></i>}
          onClose={this.onClose}
          visible={this.state.visible}
          bodyStyle={{ paddingBottom: 80 }}
          extra={
            <Space>
              <Button
                onClick={() => this.formRef.current.submit()}
                type="primary"
                className="font-weight-bold arrow-transition"
                loading={this.state.loading}
              >
                Save
                <i className="fa-solid fa-arrow-right ml-2 arrow-transition"></i>
              </Button>
            </Space>
          }
          footer={
            <>
              <Button
                onClick={() => this.formRef.current.submit()}
                type="primary"
                className="font-weight-bold arrow-transition"
                loading={this.state.loading}
              >
                Save
                <i className="fa-solid fa-arrow-right ml-2 arrow-transition"></i>
              </Button>
              <Button className="ml-2" onClick={this.onClose} style={{ marginRight: 8 }}>
                Cancel
              </Button>
            </>
          }
        >
          <Form
            {...formItemLayout}
            labelAlign="left"
            ref={this.formRef}
            name="customerForm"
            onFinish={this.onFinish}
            initialValues={{
              ...this.state.selectedData,
            }}
            size="default"
          >
            <SectionHeader
              title="Basic Details"
              link={true}
              linkTo={e => {
                store.get('paid') == 0
                  ? this.subscriptionPlanRef.current.openModal('custom_fields')
                  : this.custom_column.onCustomerCustomFields('customer')
              }}
              linkText="Add Custom fields"
            />
            <Card size="form" bordered={true} className="border-radius-small mb-4">
              <Row gutter={18}>
                <Col span={24}>
                  <Form.Item
                    name="name"
                    label="Name"
                    rules={[
                      {
                        required: true,
                        message: () => (
                          <>
                            {'Please enter name'}
                            {message.error('Please enter name')}
                          </>
                        ),
                      },
                    ]}
                  >
                    <Input placeholder="Ratan Tata" autoFocus />
                  </Form.Item>
                </Col>

                <Col span={12}>
                  <Form.Item name="phone" label={<span>Phone</span>}>
                    <div className="d-flex">
                      <Select
                        showSearch
                        suffixIcon={<i className="fa-regular fa-chevron-down"></i>}
                        //placeholder="Select DialCode"
                        optionFilterProp="children"
                        style={{ width: '99px' }}
                        filterOption={(input, option) =>
                          (option.children[0].props.children + option.children[1].props.children)
                            .toLowerCase()
                            .indexOf(input.toLowerCase()) >= 0
                        }
                        trigger="click"
                        dropdownStyle={{ minWidth: '270px' }}
                        defaultValue={'+' + this.state.dial_code}
                        onChange={value => {
                          this.setState({ dial_code: value })
                        }}
                        value={this.state.dial_code == '' ? '91' : this.state.dial_code}
                      >
                        {this.props?.countries?.map((item, i) => (
                          <Option key={i} value={item.dial_code}>
                            <span className="font-weight-bold mr-2 w-50">
                              {'+' + item.dial_code}
                            </span>
                            <span className="text-gray-500 w-50">{item.name}</span>
                          </Option>
                        ))}
                      </Select>
                      <InputNumber
                        placeholder="Phone number"
                        className="w-100 ml-2"
                        type="number"
                        value={this.state.phone}
                        onChange={e => this.setState({ phone: e ? e : '' })}
                      />
                    </div>
                  </Form.Item>
                </Col>

                <Col span={12}>
                  <Form.Item name="email" label={<span>Email</span>}>
                    <Input
                      onInput={e => (e.target.value = e.target.value.toLowerCase())}
                      placeholder="name@example.com"
                    />
                  </Form.Item>
                </Col>
              </Row>
            </Card>

            <SectionHeader title="Company Details (Optional)" />
            <Card size="form" bordered={true} className="border-radius-small mb-4">
              <Row gutter={18}>
                <Col span={24}>
                  {this.props?.user?.selectedCompany?.country_code == 'IN' ? (
                    <Form.Item
                      name="gstin"
                      label={this.props?.user?.selectedCompany?.tax_id_label}
                      rules={[
                        ({ getFieldValue }) => ({
                          validator(_, value) {
                            if (
                              !value ||
                              value == 'NA' ||
                              new RegExp(CONSTANTS.GSTIN_REGEX).test(value)
                            ) {
                              return Promise.resolve()
                            }
                            return Promise.reject(new Error('Please enter a valid GSTIN'))
                          },
                        }),
                      ]}
                    >
                      <Input.Search
                        placeholder="29AABCT1332L000"
                        enterButton="Fetch Details"
                        loading={this.state.fetchLoading}
                        onChange={e => {
                          if (e.target.value.length == 15) {
                            this.fetchGSTDetails()
                          }
                        }}
                        onSearch={this.fetchGSTDetails}
                      />
                    </Form.Item>
                  ) : (
                    <Form.Item name="gstin" label={this.props?.user?.selectedCompany?.tax_id_label}>
                      <Input placeholder="100123456789012" />
                    </Form.Item>
                  )}
                </Col>

                <Col span={24}>
                  <Form.Item name="company_name" label="Company Name">
                    <Input placeholder="ABC Technologies Private Limited" />
                  </Form.Item>
                </Col>
              </Row>
            </Card>

            <Visibility type="custom_fields" className="mb-4" visible={this.state.visible} />

            {this.get_columns_list()?.length > 0 && (
              <>
                <SectionHeader title="Custom Fields" />
                <Card bordered={false} size="form" className="border-radius-small mb-4">
                  <Row gutter={18}>
                    {this.get_columns_list()?.map(
                      (item, index) =>
                        item.is_active == 1 && (
                          <Col span={12}>
                            <Form.Item
                              name={['custom_values', item.custom_field_id.toString()]}
                              label={item.name}
                              key={index}
                              rules={[
                                {
                                  required: item.is_required == 1,
                                  message: () => (
                                    <>
                                      {item.name + ' should not be empty'}
                                      {message.error(item.name + ' should not be empty')}
                                    </>
                                  ),
                                },
                              ]}
                            >
                              {item.field_type == 'bigtext' ? (
                                <TextArea rows={4} placeholder={item.name} className="w-100" />
                              ) : item.field_type == 'date' ? (
                                <DatePicker
                                  placeholder={item.name}
                                  className="w-100"
                                  format={this.dateFormat}
                                />
                              ) : item.field_type == 'number' ? (
                                <InputNumber placeholder={item.name} className="w-100" />
                              ) : (
                                <Input type={'text'} placeholder={item.name} className="w-100" />
                              )}
                            </Form.Item>
                          </Col>
                        ),
                    )}
                  </Row>
                </Card>
              </>
            )}

            <SectionHeader title="Billing Address" />
            <Row>
              {this.state.selectedData?.billing_address?.map((item, index) => (
                <Card
                  key={index}
                  size="small"
                  bordered={false}
                  style={{
                    width: 300,
                    cursor: 'pointer',
                  }}
                  className="mr-2 mb-4 mr-4 border-radius-small"
                >
                  <div className="">
                    <span>
                      <CopyText
                        label="Billing Address"
                        text={
                          `${item.line1}${item.line1 ? ',$' : ''}` + // Add newline if line1 is not empty
                          `${item.line2}${item.line2 ? ',$' : ''}` + // Add newline if line2 is not empty
                          `${item.city}${item.city ? ',$' : ''}` + // Add newline if city is not empty
                          `${
                            item.state
                              ? item.state + (item.pincode ? '-' + item.pincode : '') + ',$'
                              : item.pincode
                              ? item.pincode + ',$'
                              : ''
                          }` + // Add state and pincode with correct formatting
                          `${item.country ? item.country : ''}`
                        } // Add country at the end
                      />
                    </span>
                  </div>
                  <a
                    href="#"
                    onClick={e => {
                      this.addressFormRef?.current?.showAddressDrawer(
                        this.state.selectedData.customer_id,
                        'billing',
                        { ...item, state1: item.state },
                        this.state.export_customer,
                        true,
                      )
                    }}
                    className="card-link font-weight-bold text-action mr-3"
                  >
                    Edit
                  </a>
                  <Popconfirm
                    title="Sure to delete?"
                    onConfirm={e =>
                      this.state.formFlag
                        ? this.addressDelete('billing', item.addr_id)
                        : this.addressDeleteWhenAddingNewCustomer('billing', item.addr_id)
                    }
                    onClick={event => event.stopPropagation()}
                    onCancel={event => event.stopPropagation()}
                    className="mr-3"
                    placement="bottomLeft"
                  >
                    <span className="text-danger font-weight-bold" style={{ cursor: 'pointer' }}>
                      Delete
                    </span>
                  </Popconfirm>
                  <a
                    onClick={e => {
                      this.setState({ selectedAddressType: 'shipping', selectedAddrID: -1 }, () =>
                        this.state.formFlag
                          ? this.onAddressFinish({
                              line1: item.line1,
                              line2: item.line2,
                              city: item.city,
                              state1: item.state,
                              pincode: item.pincode,
                              country: item.country,
                            })
                          : this.onAddressFinishWhenAddingNewCustomer({
                              line1: item.line1,
                              line2: item.line2,
                              city: item.city,
                              state1: item.state,
                              pincode: item.pincode,
                              country: item.country,
                            }),
                      )
                    }}
                    className="card-link font-weight-bold text-green mr-3"
                  >
                    Copy to Shipping
                    <i className="fa fa-pencil-o" />
                  </a>
                </Card>
              ))}
            </Row>

            {this.state.selectedData?.billing_address?.length == 0 && (
              <Button
                type="add-new-1"
                size="large"
                className="mt-2 mb-4 px-4"
                onClick={e => {
                  this.addressFormRef?.current?.showAddressDrawer(
                    this.state.selectedData.customer_id,
                    'billing',
                    { addr_id: -1 },
                    this.state.export_customer,
                    true,
                  )
                }}
              >
                <span className="font-weight-bold font-size-16">
                  <i className="fa fa-plus-circle fa-lg mr-2" /> Billing Address
                </span>
              </Button>
            )}

            <SectionHeader title="Shipping Address" />
            <Row className="mb-2">
              {this.state.selectedData.shipping_address.map((item, index) => (
                <Card
                  key={index}
                  size="small"
                  bordered={false}
                  style={{
                    width: 300,
                    cursor: 'pointer',
                  }}
                  className="mr-2 mb-2 mr-4 border-radius-small"
                >
                  {console.log('item', item)}
                  <div className="">
                    <span>
                      <CopyText
                        label="Shipping Address"
                        text={
                          `${item.line1}${item.line1 ? ',$' : ''}` + // Add newline if line1 is not empty
                          `${item.line2}${item.line2 ? ',$' : ''}` + // Add newline if line2 is not empty
                          `${item.city}${item.city ? ',$' : ''}` + // Add newline if city is not empty
                          `${
                            item.state
                              ? item.state + (item.pincode ? '-' + item.pincode : '') + ',$'
                              : item.pincode
                              ? item.pincode + ',$'
                              : ''
                          }` + // Add state and pincode with correct formatting
                          `${item.country ? item.country : ''}`
                        } // Add country at the end
                      />
                      <p className="mb-1">{item.notes}</p>
                    </span>
                  </div>

                  <a
                    href="#"
                    onClick={e => {
                      this.setState({
                        selectedAddressType: 'shipping',
                        selectedAddrID: item.addr_id,
                      })
                      this.addressFormRef?.current?.showAddressDrawer(
                        this.state.selectedData.customer_id,
                        'shipping',
                        { ...item, state1: item.state },
                        this.state.export_customer,
                        true,
                      )
                    }}
                    className="card-link text-action font-weight-bold mr-3"
                  >
                    Edit
                  </a>
                  <Popconfirm
                    title="Sure to delete?"
                    onConfirm={e =>
                      this.state.formFlag
                        ? this.addressDelete('shipping', item.addr_id)
                        : this.addressDeleteWhenAddingNewCustomer('shipping', item.addr_id)
                    }
                    onClick={event => event.stopPropagation()}
                    onCancel={event => event.stopPropagation()}
                    placement="bottomLeft"
                  >
                    <span className="text-danger font-weight-bold" style={{ cursor: 'pointer' }}>
                      Delete
                    </span>
                  </Popconfirm>
                </Card>
              ))}
            </Row>

            <Button
              type="add-new-1"
              size="large"
              className="my-2 mb-4 px-4"
              onClick={e => {
                this.setState({
                  selectedAddressType: 'shipping',
                  selectedAddrID: -1,
                })
                this.addressFormRef?.current?.showAddressDrawer(
                  this.state.selectedData.customer_id,
                  'shipping',
                  { addr_id: -1 },
                  this.state.export_customer,
                  true,
                )
              }}
            >
              <span className="font-weight-bold font-size-16 text-pink">
                <i className="fa fa-plus-circle fa-lg mr-2" />
                Shipping Address
              </span>
            </Button>

            <SectionHeader title="Optional Details" />
            <Card size="form" bordered={true} className="border-radius-small mb-4">
              <Row gutter={18}>
                <Col span={24}>
                  {this.props.permissions?.show_customer_balance == 1 && (
                    <>
                      <Form.Item
                        label="Opening Balance"
                        className="mb-4"
                        hidden={this.props.permissions?.show_customer_balance == 0}
                      >
                        <Form.Item name="opening_balance_type">
                          <Radio.Group
                            onChange={this.onChangeOpeningBalanceType}
                            value={this.state.opening_balance_type}
                            className="mb-2"
                          >
                            <Radio value={1}>Debit</Radio>
                            <Radio value={2}>Credit</Radio>
                          </Radio.Group>
                        </Form.Item>
                        <Form.Item name="opening_balance" className="mb-4">
                          {this.state.opening_balance_type == 1 && (
                            <>
                              <Input
                                prefix={
                                  <span className="text-gray-500">
                                    {this.props?.user?.selectedCompany?.currency_symbol}
                                  </span>
                                }
                                placeholder="Enter Debit Amount"
                                onChange={this.onChangeOpeningBalance}
                                value={this.state.opening_balance}
                                className="text-danger font-weight-bold"
                                suffix={
                                  this.state.opening_balance != 0 &&
                                  this.state.opening_balance != 0 && (
                                    <label className="mt-2" style={{ color: 'red' }}>
                                      <span>
                                        {' '}
                                        Customer pays you{' '}
                                        <span className="ml-1 mr-1">
                                          {this.props?.user?.selectedCompany?.currency_symbol}
                                        </span>{' '}
                                        {this.state.opening_balance}{' '}
                                      </span>
                                    </label>
                                  )
                                }
                              />
                            </>
                          )}
                          {this.state.opening_balance_type == 2 && (
                            <>
                              <Input
                                prefix={
                                  <span className="text-gray-500">
                                    {this.props?.user?.selectedCompany?.currency_symbol}
                                  </span>
                                }
                                placeholder="Enter Credit Amount"
                                onChange={this.onChangeOpeningBalance}
                                value={this.state.opening_balance}
                                className="text-success font-weight-bold"
                                suffix={
                                  this.state.opening_balance != 0 &&
                                  this.state.opening_balance != '' && (
                                    <label className="mt-2" style={{ color: 'green' }}>
                                      <span>
                                        You pay the Customer{' '}
                                        <span className="ml-1 mr-1">
                                          {this.props?.user?.selectedCompany?.currency_symbol}
                                        </span>{' '}
                                        {this.state.opening_balance}{' '}
                                      </span>
                                    </label>
                                  )
                                }
                              />
                            </>
                          )}
                        </Form.Item>
                      </Form.Item>
                      <Form.Item
                        name="balance"
                        label="Balance"
                        hidden={
                          this.props.permissions?.show_customer_balance == 0 || this.state.formFlag
                        }
                      >
                        <Input placeholder="" disabled />
                      </Form.Item>
                    </>
                  )}
                </Col>
                {this.props?.user?.selectedCompany?.country_code == 'IN' && (
                  <>
                    <Col span={24}>
                      <Form.Item name="is_tds" label="TDS">
                        <Switch
                          className="ml-2"
                          size="large"
                          checkedChildren="Yes"
                          unCheckedChildren="No"
                          checked={this.state.is_tds}
                          onChange={e => {
                            if (e && this.state.is_tcs) {
                              message.error('TCS and TDS cannot be applied together')
                              return
                            }
                            this.setState({ is_tds: e })
                          }}
                        />
                      </Form.Item>
                    </Col>
                    <Col span={24}>
                      {this.state.is_tds && (
                        <Form.Item name={'tds_section_id'} label="TDS Percentage">
                          <Select
                            showSearch
                            suffixIcon={<i className="fa-regular fa-chevron-down"></i>}
                            placeholder="Select TDS Section"
                            optionFilterProp="children"
                            style={{ width: '100%', whiteSpace: 'nowrap' }}
                            filterOption={(input, option) =>
                              (
                                option.props.children.props.children[0].props.children +
                                ' ' +
                                option.props.children.props.children[1].props.children +
                                ' ' +
                                option.props.children.props.children[2].props.children
                              )
                                .toLowerCase()
                                .indexOf(input.toLowerCase()) >= 0
                            }
                          >
                            {this.props?.tds_sections?.map((item, i) => (
                              <Option key={i} value={item.tds_id}>
                                <div style={{ whiteSpace: 'normal' }}>
                                  <span className="text-gray-700 font-weight-bold mr-2">
                                    {item.tax + '%'}
                                  </span>
                                  <span className="mr-1 font-weight-bold text-gray-700">
                                    {item.section}
                                  </span>
                                  <span className="mr-2 text-gray-500">{item.name}</span>
                                </div>
                              </Option>
                            ))}
                          </Select>
                        </Form.Item>
                      )}
                    </Col>
                    <Col span={24}>
                      <Form.Item name="is_tcs" label="TCS">
                        <Switch
                          className="ml-2"
                          size="large"
                          checkedChildren="Yes"
                          unCheckedChildren="No"
                          checked={this.state.is_tcs}
                          onChange={e => {
                            if (e && this.state.is_tds) {
                              message.error('TCS and TDS cannot be applied together')
                              return
                            }
                            this.setState({ is_tcs: e })
                          }}
                        />
                      </Form.Item>
                    </Col>

                    <Col span={24}>
                      {this.state.is_tcs && (
                        <Form.Item name={'tcs_section_id'} label="TCS Percentage">
                          <Select
                            showSearch
                            suffixIcon={<i className="fa-regular fa-chevron-down"></i>}
                            placeholder="Select TCS Section"
                            optionFilterProp="children"
                            style={{ width: '100%', whiteSpace: 'nowrap' }}
                            filterOption={(input, option) =>
                              (
                                option.props.children.props.children[0].props.children +
                                ' ' +
                                option.props.children.props.children[1].props.children +
                                ' ' +
                                option.props.children.props.children[2].props.children
                              )
                                .toLowerCase()
                                .indexOf(input.toLowerCase()) >= 0
                            }
                          >
                            {this.props?.tcs_sections?.map((item, i) => (
                              <Option key={i} value={item.tds_id}>
                                <div style={{ whiteSpace: 'normal' }}>
                                  <span className="text-gray-700 font-weight-bold mr-2">
                                    {item.tax + '%'}
                                  </span>
                                  <span className="mr-1 font-weight-bold text-gray-700">
                                    {item.section}
                                  </span>
                                  <span className="mr-2 text-gray-500">{item.name}</span>
                                </div>
                              </Option>
                            ))}
                          </Select>
                        </Form.Item>
                      )}
                    </Col>
                  </>
                )}
              </Row>
            </Card>

            <Affix offsetBottom={72}>
              <div className="upward-collapse-wrapper">
                <Collapse
                  className="custom-upward-collapse bg-card-orange-dark mt-2"
                  bordered={false}
                  defaultActiveKey
                  expandIcon={({ isActive }) => (
                    <span className="">
                      <i
                        className={`fa-solid fa-chevron-down collapse-align-center ${!isActive &&
                          'fa-rotate-270'}`}
                      />
                    </span>
                  )}
                >
                  <Panel
                    header={
                      <>
                        <span className="font-size-16 font-weight-bold">More Details?</span>
                        <p className="text-gray-900 font-size-12 font-weight-normal mb-0">
                          Add Notes, Tags, Discount, CC Emails , Pricelist , Credit Limit{' '}
                        </p>
                      </>
                    }
                    key="1"
                    className="custom-collapse-panel"
                  >
                    <Row gutter={18}>
                      <Col span={12}>
                        <Form.Item name="profile_image" label={'Profile Image'}>
                          <Upload
                            name="image"
                            listType="picture-card"
                            className="avatar-uploader"
                            accept=".png, .jpg, .jpeg"
                            customRequest={this.customRequest}
                            fileList={fileList}
                            beforeUpload={this.beforeUpload}
                            previewFile={file => {
                              if (file.url) {
                                return file.url
                              }
                              return file.preview
                            }}
                            onChange={info => {
                              if (info.file.status === 'removed') {
                                this.setState({
                                  file: null,
                                  selectedData: {
                                    ...this.state.selectedData,
                                    profile_image: '',
                                  },
                                })
                              }
                            }}
                          >
                            {this.state.selectedData.profile_image != '' &&
                            this.state.selectedData.profile_image != undefined
                              ? null
                              : uploadButton}
                          </Upload>
                        </Form.Item>
                      </Col>
                      {this.props?.user?.selectedCompany?.country_code == 'IN' && (
                        <Col span={12}>
                          <Form.Item name="pan" label="Pan No.">
                            <Input placeholder="ABCDE1234F" />
                          </Form.Item>
                        </Col>
                      )}
                      <Col span={12}>
                        <Form.Item
                          name="discount"
                          label="Discount(%)"
                          rules={[
                            {
                              type: 'number',
                              max: 100,
                              message: 'Discount should be less than or equal to 100%',
                            },
                          ]}
                          extra="Set default discount to your customer"
                        >
                          <InputNumber placeholder="" className="w-100" />
                        </Form.Item>
                      </Col>
                      <Col span={12}>
                        <Form.Item
                          name="credit_limit"
                          label="Credit Limit"
                          rules={[
                            {
                              type: 'number',
                              min: 0,
                              message: 'Credit Limit should be a positive number',
                            },
                          ]}
                          extra="Warning will be shown when customer is exceeding the credit limit while creating invoice."
                        >
                          <InputNumber placeholder="" className="w-100" />
                        </Form.Item>
                      </Col>
                      <Col span={12}>
                        <Form.Item
                          name="price_list_id"
                          label="Price List"
                          extra="Price List will be automatically selected while creating the document."
                        >
                          <Select
                            placeholder="Select Price List"
                            className="w-100"
                            dropdownStyle={{
                              scrollBehavior: 'max-height',
                            }}
                          >
                            {this.props.price_lists?.map((item, index) => (
                              <Option value={item.price_list_id} key={index}>
                                {item.name}
                              </Option>
                            ))}
                          </Select>
                        </Form.Item>
                      </Col>
                      <Col span={12}>
                        <Form.Item name="notes" label="Notes">
                          <TextArea />
                        </Form.Item>
                      </Col>
                      <Col span={12}>
                        <Form.Item name="tags" label="Tags">
                          <Select
                            suffixIcon={<i className="fa-regular fa-magnifying-glass"></i>}
                            mode="tags"
                            onChange={e => this.setState({ selected_tags: e })}
                          >
                            {this.state.tags.map((item, index) => (
                              <Option value={item.tag_name} key={index}>
                                {item.tag_name}
                              </Option>
                            ))}
                          </Select>
                        </Form.Item>
                      </Col>
                      <Col span={12}>
                        <Form.Item
                          name="cc_emails"
                          label={
                            <span>
                              CC Emails <NewBadge />
                            </span>
                          }
                          extra="These emails will be added as CC in all the emails sent to this customer"
                        >
                          <Select
                            mode="tags"
                            suffixIcon={<i className="fa-regular fa-chevron-down"></i>}
                            value={this.state.cc_emails}
                            placeholder="CC"
                            style={{ width: '100%' }}
                            onChange={_emails => {
                              this.setState({ cc_emails: _emails })
                            }}
                            maxTagCount="responsive"
                            maxTagPlaceholder={omittedValues => (
                              <Tooltip title={omittedValues.map(({ label }) => label).join(', ')}>
                                <span>+{omittedValues.length}...</span>
                              </Tooltip>
                            )}
                          >
                            {this.state.cc_emails.map((email, index) => (
                              <Option key={index} value={email}>
                                {email}
                              </Option>
                            ))}
                          </Select>
                        </Form.Item>
                      </Col>
                    </Row>
                  </Panel>
                </Collapse>
              </div>
            </Affix>
          </Form>

          <AddressForm
            onFinish={
              this.state.formFlag ? this.onAddressFinish : this.onAddressFinishWhenAddingNewCustomer
            }
            selectedAddrID={this.state.selectedAddrID}
            selectedAddressType={this.state.selectedAddressType}
            selectedData={this.state.selectedData}
            isEdit={this.state.formFlag}
            ref={this.addressFormRef}
          />

          <EnhancedSubscriptionModal onRef={ref => (this.subscription = ref)} />
          <SubscriptionPlanModal ref={this.subscriptionPlanRef} />
          <CustomColumnsForm
            onRef={ref => (this.custom_column = ref)}
            onAPICalled={() => this.onRefreshCustomFields()}
          />
          <SaveChangesModal
            ref={this.saveChangesRef}
            onFinish={this.onSaveChanges}
            onClose={this.onClose}
          />
          <Modal
            centered
            title={
              <>
                <span className="text-danger font-weight-bold">Are you sure?</span>
              </>
            }
            visible={this.state.show_gstin_exist}
            onOk={() =>
              this.setState({ show_gstin_exist: false, add_customer_with_existing_gstin: true })
            }
            onCancel={() =>
              this.setState({ show_gstin_exist: false, showDrawer: false }, () =>
                this.props.onExistingCustomer(this.state.existing_customers[0].name),
              )
            }
            okText="Continue adding"
            cancelText="Discard"
            footer={[
              <Button
                type="text"
                className="mr-3 font-weight-bolder"
                onClick={() =>
                  this.setState({ show_gstin_exist: false, add_customer_with_existing_gstin: true })
                }
              >
                Add Anyway
              </Button>,
              <Button
                type="primary"
                onClick={() =>
                  this.setState({ show_gstin_exist: false, showDrawer: false }, () =>
                    this.props.onExistingCustomer(this.state.existing_customers[0].name),
                  )
                }
              >
                Use {this.state.existing_customers[0].name}
              </Button>,
            ]}
          >
            <p>
              <p className="text-danger font-weight-bold">
                {this.props?.user?.selectedCompany?.tax_id_label} already exists.
              </p>
              <div className="d-flex my-4">
                <UserAvatar
                  name={
                    this.state.existing_customers[0].company_name != '' &&
                    this.state.existing_customers[0].company_name != null
                      ? this.state.existing_customers[0].company_name
                      : this.state.existing_customers[0].name
                  }
                />
                <div>
                  <span
                    className="mt-0 mb-2 font-weight-medium text-gray-900"
                    style={{ textOverflow: 'ellipsis' }}
                  >
                    {this.state.existing_customers[0].company_name ||
                      this.state.existing_customers[0].name}
                  </span>
                  <span className="mb-2 mt-0" onClick={e => e.stopPropagation()}>
                    <p className="mt-0 mb-0 font-weight-medium">
                      {this.state.existing_customers[0].phone != '' &&
                        this.state.existing_customers[0].phone != null && (
                          <span>
                            <CopyText
                              label="Phone Number"
                              text={this.state.existing_customers[0].phone}
                            />
                          </span>
                        )}
                    </p>
                  </span>

                  {this.state.existing_customers[0].company_name != null && (
                    <span className="font-weight-normal" onClick={e => e.stopPropagation()}>
                      <p className="mt-0 mb-0 text-gray-75">
                        <CopyText
                          label="Company Name"
                          text={this.state.existing_customers[0].company_name}
                        />
                      </p>
                      <p className="mt-0 mb-0 text-gray-75">
                        <CopyText
                          label={this.props?.user?.selectedCompany?.tax_id_label}
                          text={this.state.existing_customers[0].gstin}
                        />
                      </p>
                    </span>
                  )}
                </div>
              </div>
              <>
                Do you want to continue adding customer with same
                {this.props?.user?.selectedCompany?.tax_id_label}?
              </>
            </p>
          </Modal>
        </Drawer>
      </>
    )
  }
}

class EnhancedFrom extends React.Component {
  constructor(props) {
    super(props)
  }
  render() {
    return <CustomerForm {...this.props} />
  }
}

function mapStateToProps(state, ownProps) {
  return {
    permissions: state.permissions.permission,
    states: state.document.states,
    countries: state.document.countries,
    custom_fields: state.bulkedit.customer_columns,
    tds_sections: state.document.tdsSections,
    price_lists: state.document.price_lists,
    tcs_sections: state.document.tcsSections,
    all_doc_counts: state.document.all_doc_counts,
    user: state.user,
  }
}

export default connect(mapStateToProps)(EnhancedFrom)
