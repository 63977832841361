import {
  GET_DEFAULT_URL,
  GET_INTEGRATIONS,
  GET_PERMISSIONS,
  SET_ALL_USERS,
  SET_BEACON,
  SET_COMPANY_DETAILS,
  SET_DEFAULT_URL,
  SET_INTEGRATIONS,
  SET_PERMISSIONS,
} from './types'

export const getPermissions = payload => {
  return {
    type: GET_PERMISSIONS,
    payload,
  }
}
export const setPermissions = payload => {
  return {
    type: SET_PERMISSIONS,
    payload,
  }
}

export const setDefaultUrl = payload => {
  return {
    type: SET_DEFAULT_URL,
    payload,
  }
}
export const getDefaultUrl = payload => {
  return {
    type: GET_DEFAULT_URL,
    payload,
  }
}
export const getIntegration = () => {
  return {
    type: GET_INTEGRATIONS,
  }
}
export const setIntegration = payload => {
  return {
    type: SET_INTEGRATIONS,
    payload,
  }
}
export const setAllUsers = payload => {
  return {
    type: SET_ALL_USERS,
    payload,
  }
}
export const setCompanyDetails = payload => {
  return {
    type: SET_COMPANY_DETAILS,
    payload,
  }
}

export const setBeacon = payload => {
  return {
    type: SET_BEACON,
    payload,
  }
}
