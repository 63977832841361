import MyEditor from 'components/other/editor'
import { EditorState, RichUtils } from 'draft-js'
import { stateToHTML } from 'draft-js-export-html'
import { stateFromHTML } from 'draft-js-import-html'
import React, { Component, useState } from 'react'

const RichTextEditor = params => {
  const editRef = React.createRef()
  const [record, setRecord] = useState(params.data)
  const [editorState, setEditorState] = useState(
    EditorState.createWithContent(stateFromHTML(record.description || '')),
  )

  const addNewLine = () => {
    const newState = RichUtils.insertSoftNewline(editRef.current.getEditorState())
    setEditorState(newState)
    editRef.current.updateEditorState(newState)
  }

  return (
    <>
      <div
        style={{ width: '400px' }}
        onKeyDownCapture={e => {
          if (e.key == 'Enter') {
            e.stopPropagation()
            e.preventDefault()
            addNewLine()
          }
        }}
      >
        <MyEditor
          ref={editRef}
          editorState={editorState}
          onChange={(text, editorState) => {
            setEditorState(editorState)
            setRecord({ ...record, description: stateToHTML(editorState.getCurrentContent()) })
          }}
          showFromState={true}
          showSaveButton={true}
          onSave={editorState => {
            params.onValueChange(stateToHTML(editorState.getCurrentContent()))
            params.stopEditing()
          }}
        />
      </div>
    </>
  )
}

export default RichTextEditor
