import { PDFViewer } from '@react-pdf/renderer'
import Payment from 'components/payment/index'
import React, { useEffect, useState } from 'react'

export function PaymentDocument({ data }) {
  const [isReady, setIsReady] = useState(false)

  useEffect(() => {
    setIsReady(true)
  }, [])

  return (
    <>
      {isReady ? (
        <PDFViewer className="col-12" style={{ height: '900px' }}>
          <Payment data={{ ...data }} />
        </PDFViewer>
      ) : (
        ''
      )}
    </>
  )
}
