import { Input, InputNumber, Modal } from 'antd'
import BetaBadge from 'components/badges/beta'
import React, { Component } from 'react'

export default class AdditionalCess extends Component {
  constructor(props) {
    super(props)

    this.state = {
      isModalVisible: false,
      record: '',
      cess_on_qty: 0,
      cess_on_qty_value: 0,
    }
  }

  componentDidMount() {
    this.props.onRef(this)
  }

  componentWillUnmount() {
    this.props.onRef(null)
  }

  showModal = record => {
    this.setState({
      isModalVisible: true,
      record: { ...record, cess_on_qty_value: record.cess_on_qty * record.qty },
      cess_on_qty: record.cess_on_qty,
      cess_on_qty_value: record.cess_on_qty * record.qty,
    })
  }

  handleOk = () => {
    const cess_on_qty_value = this.state.record.qty * this.state.cess_on_qty
    this.setState(
      {
        isModalVisible: false,
        record: {
          ...this.state.record,
          is_cess_on_qty_applicable: true,
          cess_on_qty: parseFloat(this.state.cess_on_qty),
          cess_on_qty_value: cess_on_qty_value,
        },
      },
      () => this.props.updateRecord(this.state.record),
    )
  }

  handleCancel = () => {
    this.setState({
      isModalVisible: false,
    })
  }

  handleAmountChange = e => {
    const cess_on_qty = e ? e : 0
    const cess_on_qty_value = this.state.record.qty * cess_on_qty
    this.setState({ cess_on_qty: cess_on_qty, cess_on_qty_value: cess_on_qty_value })
  }

  render() {
    return (
      <div>
        <Modal
          title={
            <div className="d-flex align-items-center">
              <span>Additional Cess</span>

              <BetaBadge />
            </div>
          }
          visible={this.state.isModalVisible}
          onOk={this.handleOk}
          onCancel={this.handleCancel}
        >
          <div>
            <label>Product: {this.state.record.product_name}</label>
          </div>

          <div className="mt-4">
            <label>Quantity (per {this.state.record.unit}):</label>
            <Input value={this.state.record.qty} disabled />
          </div>
          <div className="mt-4">
            <label>Cess on Qty per {this.state.record.unit}:</label>
            <InputNumber
              type="number"
              className="d-block w-100"
              value={this.state.cess_on_qty}
              onChange={this.handleAmountChange}
            />
          </div>
          <div className="mt-4">
            <label>Cess on Qty Value: </label>
            <span> Rs. {this.state.cess_on_qty_value}</span>
          </div>
        </Modal>
      </div>
    )
  }
}

export class EnhancedAdditionalCess extends React.Component {
  render() {
    return <AdditionalCess {...this.props} />
  }
}
