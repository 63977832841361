import {
  Affix,
  AutoComplete,
  Button,
  Card,
  Checkbox,
  Col,
  Collapse,
  DatePicker,
  Divider,
  Drawer,
  Empty,
  Input,
  InputNumber,
  Modal,
  Popconfirm,
  Popover,
  Row,
  Select,
  Space,
  Spin,
  Switch,
  Tabs,
  Tag,
  Tooltip,
  Upload,
  message,
  notification,
} from 'antd'
import {
  formatNumber,
  getCessAmountV2,
  getCessOnQtyValue,
  getItemNetAmount,
  getRowTotalV2,
  getTaxAmountV2,
  getTotalCessAmountV2,
  getTotalDiscountV2,
  getTotalNetAmountV2,
} from 'components/other/calculation'
import dayjs from 'dayjs'
import utc from 'dayjs/plugin/utc'
import { history } from 'index'
import React, { Fragment } from 'react'
import { getAPI, getAPIData, getMultipartAPI } from 'services/jwt'
const { TabPane } = Tabs

import CustomerFrom from 'components/forms/customerForm'
import InvoiceThemeForm from 'components/forms/invoiceTheme'
import BankForm from 'components/forms/onlyBankForm'
import ProductForm from 'components/forms/productForm'
import VendorFrom from 'components/forms/vendorform'
import EnhancedTable from 'components/other/productsTable'

import InvoiceSettingsForm from 'components/forms/InvoiceSettings'
import CustomColumnsForm from 'components/forms/customColumns'
import { EnhancedSignatureForm } from 'components/forms/onlySignatureForm'
import { EnhancedSubscriptionModal } from 'components/modal/subscription'
import SubscriptionPlanModal from 'components/modal/subscription/subscriptionPlan'
import {
  getCountries,
  getTdsSections,
  get_categories,
  get_tcs_sections,
  get_top_custom_fields,
} from 'redux/document/actions'

import { ExclamationCircleOutlined, LoadingOutlined } from '@ant-design/icons'
import BetaBadge from 'components/badges/beta'
import ProBadge from 'components/badges/pro'
import CustomNotes from 'components/forms/customNotesForm'
import { EnhancedEInvoiceNICCreds } from 'components/forms/eInvoiceNicCreds'
import { EnhancedNICCreds } from 'components/forms/nicCreds'
import InfoIcon from 'components/info/info_icon'
import CouponsList from 'components/modal/coupons/listCoupons'
import TutorialVideoModal from 'components/modal/tutorial'
import Visibility from 'components/other/visibility'
import animationData from 'lotties/file-loading.json'
import { EnhancedEwayBill } from 'pages/ewayBills/ewaybill'
import { FormattedMessage } from 'react-intl'
import { connect } from 'react-redux'
import store from 'store'

import EditCompany from 'components/forms/editCompany'
import LedgerView from 'components/modal/ledger'
import SectionHeader from 'components/other/sectionHeader'

import DocumentPrefixes from 'components/forms/documentPrefixes'
import FinancialYearModal from 'components/modal/financialYearModal'
import PurchaseConfirm from 'components/modal/purchaseConfirm'
import { dispatch_to_doc_types } from 'components/new_invoice/utils'
import { PartyType, getBase64 } from 'components/other/utility'
import { EditorState } from 'draft-js'
import { stateFromHTML } from 'draft-js-import-html'
import _ from 'lodash'
import { isMobile } from 'react-device-detect'
import { Helmet } from 'react-helmet'
import {
  add_invoice_data,
  get_payment_modes,
  remove_all_invoices_data,
  update_invoice_data,
} from 'redux/document/actions'
import { get_warehouses } from 'redux/warehouses/actions'
const { TextArea } = Input
const { Option } = Select
const { Panel } = Collapse
const dotOptions = { minimumFractionDigits: 2, maximumFractionDigits: 2 }
dayjs.extend(utc)
const initialItems = [
  {
    label: '',
    key: '1',
  },
]
class EditableTable extends React.Component {
  tutorialRef = React.createRef()
  dateFormat = 'DD-MM-YYYY'
  dateFormatRegex = /^\d{2}-\d{2}-\d{4}$/
  customNotesRef = React.createRef()
  documentPrefixesRef = React.createRef()
  purchaseConfirmRef = React.createRef()
  subscriptionPlanRef = React.createRef()
  financialYearRef = React.createRef()
  docNumberRef = React.createRef()

  constructor(props) {
    super(props)
    this.inputEl = React.createRef()
    this.timeout = 0
    this.draftTimeout = null
    this.newTabIndex = 1
    this.autoDraftInterval = null
    this.state = {
      loadingSave: false,
      loadingPrint: false,
      draftReqPending: false,
      flag: false,
      customerName: '',
      openDatePicker: false,
      description:
        store.get('show_invoice_description') == undefined
          ? true
          : store.get('show_invoice_description'),
      has_extra_charges: false,
      extraDiscount: 0,
      extraDiscountPer: 0,
      customerData: [],
      autocompleteData: [],
      selectedCustomer: '',
      states: [],
      invoiceData: '',
      flag: false,
      dataSource: [],
      is_pos: false,
      count: 1,
      doc_count: '',
      bank_details: [],
      items_reverse: false,
      selected_bank: { bank_name: '', bank_no: '', branch_name: '', ifsc: '', id: '' },
      signatures: [{ signature_name: 'No Signature', image: '' }],
      selectedSignature: { signature_name: '', id: '', image: '' },
      serial_number: '',
      document_date: dayjs()
        .utcOffset(330)
        .format(this.dateFormat),
      supplier_invoice_date: dayjs()
        .utcOffset(330)
        .format(this.dateFormat),
      supplier_invoice_serial_number: '',
      due_date: dayjs()
        .add(this.props?.invoice_settings?.default_due_date, 'days')
        .utcOffset(330)
        .format(this.dateFormat),
      reference: '',
      terms: '',
      notes: '',
      preview: false,
      showEwayBill: false,
      addFlag: false,
      edit: false,
      convertToInvoiceFromEstimate: false,
      convertToInvoiceFromOnlineOrders: false,
      multiple_gst: [],
      is_subscription: 0,
      subscription_document_type: 'invoice',
      prefix: '',
      doc_number: '',
      order_serial_number: '',
      sub_serial_number: '',
      rzp_order_id: '',
      rzp_payment_id: '',
      id: 0,
      company_id: 0,
      is_ewaybill: 0,
      is_create_einvoice: 0,
      priceListWarnShown: false,
      selectedPriceList: null,
      price_lists: [],
      taxes: [],
      attachments: [],
      amountReceived: 0,
      fullyPaid: false,
      paymentMethod: 'Cash',
      image_url: '',
      customer_details: {
        shipping_address: [],
        billing_address: [],
      },
      vendor_details: {
        shipping_address: [],
        billing_address: [],
      },
      selected_customer_shipping_address: {},
      custom_headers: [],
      loaded: false,
      roundoff: this.props.invoice_settings?.round_off,
      roundoff_value: 0,
      document_type: 'invoice',
      party_type: 'customer',
      document_title: 'Invoice',
      list_name: 'sales',
      rcm: 0,
      rcm_value: 0,
      with_tax: 1,
      hide_totals: 0,
      export_invoice: false,
      is_multi_currency: false,
      export_type: null,
      shipping_bill_number: '',
      shipping_bill_date: '',
      shipping_port_code: '',
      countries: [],
      currencies: [],
      country: 179,
      currency: 'USD',
      currency_symbol: '$',
      conversion_factor: '1',
      is_tds: false,
      is_tcs: false,
      tds_selected_section: undefined,
      tcs_selected_section: undefined,
      tcs_amount: 0,
      tcs_applicable_on: 'total_amount',
      tds_applicable_on: 'net_amount',
      tds_amount: 0,
      subscription_start_date: dayjs().format(this.dateFormat),
      subscription_end_date: dayjs().format(this.dateFormat),
      repeatType: 'days',
      repeat_every: '1',
      send_email: false,
      send_sms: false,
      product_categories: [],
      paymentNotes: '',
      payment_status: 'pending',
      document_notes: [],
      document_terms: [],
      selected_note: 0,
      selected_term: 0,
      coupons_visible: false,
      coupon_details: {
        coupon_id: -1,
        coupon_code: '',
        discount: 0,
        message: '',
      },
      total_amount: 0,
      searchVisible: false,
      exclusive_notes: '',
      exta_discount_type: 'discount',
      is_draft: false,
      prefixes: [],
      custom_headers_suggestions: [],
      warning: {
        message: '',
        show_warning: false,
        skip_warning: false,
      },
      notesLabel: '',
      termsLabel: '',
      updatedNotes: '',
      updatedTerms: '',
      isNotesEntered: false,
      isTermsEntered: false,
      showNotes: false,
      showTerms: false,
      isNotesLoading: false,
      isTermsLoading: false,
      page: 0,
      num_records: 25,
      old_items_before_edit: [],
      document_discount_type: 'total_amount',
      prev_total_amount: 0,
      credit_limit_warning_shown: false,
      product_update_warning_shown: false,
      immovable_tax_type: 0,
      warehouse_id: undefined,
      company_shipping_addr_id: -1,
      activeKey: initialItems[0].key,
      items: initialItems,
      isCreated: false,
      visible: false,
      isClosed: false,
      auto_draft: {
        id: 0,
        doc_count: 0,
      },
      showDraftMessage: false,
      lastDataSourceUpdated: dayjs(),
      saveDocument: false,
      keyClose: '',
      top_fields: [],
      suggested_fields: [],
      customerDiscount: 0,
      new_serial_number: '',
      new_doc_number: 0,
    }
    this.initialState = this.state
    this._save = this._save.bind(this)
    this.save = _.throttle(this._save, 2000)
  }

  onChangeTab = async newActiveKey => {
    if (!this.state.isCreated && this.state.dataSource.length > 0) {
      if (this.draftTimeout) {
        await this.save('1', true, true)
      }
    }

    const payload = {
      key: this.state.activeKey,
      data: { ...this.state },
    }
    await this.props.dispatch(update_invoice_data(payload))
    const isCreated = this.state.isCreated
    const activeKey = this.state.activeKey
    const invoiceState = this.props.invoice_tabs.filter(tab => tab.key == newActiveKey)[0]
    // new items array keep track of updated items
    let new_items = [...this.state.items]
    if (isCreated) {
      // if current active key tab was created then we will remove that tab
      new_items = this.state.items.filter(item => item.key != activeKey)
    }
    this.setState({
      ...invoiceState.data,
      loaded: true,
      items: isCreated ? new_items : this.state.items,
      showDraftMessage: false,
    })
    this.draftTimeout = null
  }

  addNewTab = async () => {
    if (!this.state.isCreated && this.state.dataSource.length > 0) {
      // on adding new tab if there is change in product and is not created yet then we will auto draft
      if (this.draftTimeout) {
        await this.save('1', true, true)
      }
    }
    const currentKey = this.state.activeKey
    const isCreated = this.state.isCreated
    const newActiveKey = `${++this.newTabIndex}`
    let newPanes = [...this.state.items]
    let new_doc_number = this.state.doc_number
    this.props.invoice_tabs.map(tab => {
      if (tab.data.prefix == this.state.prefix && tab.data.doc_number > new_doc_number) {
        new_doc_number = tab.data.doc_number
      }
    })
    new_doc_number = new_doc_number + 1

    newPanes.push({
      label: `${this.state.prefix + '' + new_doc_number}`,
      key: newActiveKey,
    })
    let new_items = [...this.state.items]
    const payload = {
      key: newActiveKey,
      data: {
        ...this.initialState,
        items: newPanes,
        activeKey: newActiveKey,
        loaded: true,
        prefixes: this.state.prefixes,
        prefix: this.state.prefix,
        new_prefix: this.state.new_prefix,
        is_pos: this.state.is_pos,
        doc_number: new_doc_number,
        auto_draft: {
          id: 0,
          doc_count: 0,
        },
      },
      items: newPanes,
    }
    // if(this.state.dataSource.length>0){
    //   await this.save('1',true,true)
    // }
    this.props.dispatch(
      update_invoice_data({
        key: this.state.activeKey,
        data: { ...this.state },
      }),
    )

    this.setState({
      ...this.initialState,
      activeKey: newActiveKey,
      items: newPanes,
      loaded: true,
      prefixes: this.state.prefixes,
      prefix: this.state.prefix,
      new_prefix: this.state.new_prefix,
      is_pos: this.state.is_pos,
      doc_number: new_doc_number,
      auto_draft: {
        id: 0,
        doc_count: 0,
      },
      showDraftMessage: false,
    })

    this.props.dispatch(add_invoice_data(payload))
    this.draftTimeout = null
  }

  removeTab = async targetKey => {
    let newActiveKey = this.state.activeKey
    let lastIndex = -1
    this.state.items.forEach((item, i) => {
      if (item.key === targetKey) {
        lastIndex = i - 1
      }
    })
    const newPanes = this.state.items.filter(item => item.key !== targetKey)
    if (newPanes.length && newActiveKey === targetKey) {
      if (lastIndex >= 0) {
        newActiveKey = newPanes[lastIndex].key
      } else {
        newActiveKey = newPanes[0].key
      }
    }
    await this.props.dispatch(
      update_invoice_data({
        data: {
          ...this.state,
          isClosed: true,
        },
        key: targetKey,
      }),
    )
    const invoiceState = this.props.invoice_tabs.filter(tab => tab.key == newActiveKey)[0]

    this.setState({
      ...invoiceState.data,
      loaded: true,
      activeKey: newActiveKey,
      items: newPanes,
      showDraftMessage: false,
    })
  }
  onEditTab = (targetKey, action) => {
    if (action === 'add') {
      if (store.get('paid') == 0) {
        // free users cannot create multiple tabs
        this.subscriptionPlanRef.current.openModal('doc_multiple_tabs')
      } else {
        this.addNewTab()
      }
    } else {
      this.removeTab(targetKey)
    }
  }
  getSuggestedColumns = async () => {
    const data = await getAPIData('v2/custom_fields', 'custom_headers')
    if (data) {
      let top_custom_fields = this.props.top_fields.filter(item => {
        if (item.type == 'header') {
          return true
        }
      })
      top_custom_fields = top_custom_fields.filter(item => {
        if (data.custom_headers.find(column => column.label == item.name)) {
          return false
        } else {
          return true
        }
      })
      this.setState({ suggested_fields: top_custom_fields })
    }
  }
  affix = x => {
    this.setState({ searchVisible: x })
  }

  onRCMChange = e => {
    // console.log(e)
    if (e) {
      var rcm_value =
        parseFloat(this.getTotalTax().toFixed(2)) + this.extraChargesAmount('tax_amount')
    } else {
      var rcm_value = 0
    }
    this.setState({ rcm_value: rcm_value, rcm: e }, () => {
      this.onChangeTcsApplicable(this.state.tcs_applicable_on)
      this.onRoundOffChange(this.state.roundoff)
    })
  }
  onWithTaxChange() {
    let dataSource = this.state.dataSource
    if (this.state.with_tax == 1) {
      dataSource = dataSource.map(item => {
        if (this.state.export_type?.includes('Deemed')) {
          item.tax = 0.1
        }
        item.price_with_tax = (item.unit_price * (item.tax + item.cess)) / 100 + item.unit_price

        item.discount = item.discount
        item.discount_value = ((item.price_with_tax * item.discount) / 100).toFixed(2)
        item.discount_price_with_tax_value = ((item.price_with_tax * item.discount) / 100).toFixed(
          2,
        )
        item.discount_net_value = ((item.price * item.discount) / 100).toFixed(2)
        item.discount_unit_price_value = ((item.price * item.discount) / 100).toFixed(2)
        item.total_amount = item.price_with_tax * item.qty * (1 - item.discount / 100)
        item.tax_amount = (
          (item.net_amount * (item.tax + item.cess)) / 100 +
          (item.cess_on_qty_value ? item.cess_on_qty_value : 0)
        ).toFixed(2)
        return item
      })
    } else {
      dataSource = dataSource.map(item => {
        item.price_with_tax = item.unit_price

        item.discount = item.discount
        item.discount_value = ((item.price_with_tax * item.discount) / 100).toFixed(2)
        item.discount_price_with_tax_value = ((item.price_with_tax * item.discount) / 100).toFixed(
          2,
        )
        item.discount_net_value = ((item.price * item.discount) / 100).toFixed(2)
        item.discount_unit_price_value = ((item.price * item.discount) / 100).toFixed(2)
        item.total_amount = item.price_with_tax * item.qty
        return item
      })

      if (!this.state.edit) {
        let document_custom_additional_charges = this.state.document_custom_additional_charges
        document_custom_additional_charges = document_custom_additional_charges.map(item => {
          item.tax = 0
          item.total_amount = item.net_amount
          return item
        })
        this.updateExtraCharges({
          document_custom_additional_charges,
          has_extra_charges: this.state.has_extra_charges,
        })
      }
    }
    this.setState({ dataSource: dataSource }, () => {
      this.onChangeFullyPaid(this.state.fullyPaid)
      this.onRoundOffChange(this.state.roundoff)
    })
  }

  onRoundOffChange = a => {
    if (a) {
      var decimal = 0
      if (this.state.with_tax) {
        decimal =
          this.getTotal() +
          Number(this.state.tcs_amount) +
          Number(this.extraChargesAmount('total_amount')) -
          Number(this.state.extraDiscount) -
          Number(this.state.rcm_value)
      } else {
        decimal =
          Number(getTotalNetAmountV2(this.state.dataSource)) +
          Number(this.state.tcs_amount) +
          Number(this.extraChargesAmount('total_amount')) -
          Number(this.state.extraDiscount) -
          Number(this.state.rcm_value)
      }
      decimal = decimal.toFixed(5)
      var remainder = decimal - parseInt(decimal)
    } else {
      var remainder = 0
    }

    if (remainder >= 0.5) {
      remainder = remainder - 1
    }
    this.setState({ roundoff_value: remainder, roundoff: a })
  }

  addLogo = async ({ onSuccess, onError, file }) => {
    if (!file.url && !file.preview) {
      file.preview = await getBase64(file)
    }

    const form = new FormData()
    form.append('file', file)

    const data = await getMultipartAPI('user', 'update_logo', form)
    if (data.success) {
      onSuccess(null, file)
      this.setState({ image_url: file.url || file.preview })
      message.success(data.message)
    } else {
      message.error(data.message)
      onError()
    }
  }

  refreshBanks = async () => {
    var data = await getAPIData('user', 'bank_details')
    var default_bank = data.bank_details.filter(bank => bank.is_default == 1)
    if (default_bank.length == 0) {
      var default_bank = data.bank_details[0]
    } else {
      var default_bank = default_bank[0]
    }
    this.setState({
      bank_details: data.bank_details,
      selected_bank: default_bank,
    })
  }
  disabledEndDate = current => {
    const start = dayjs(this.state.subscription_start_date, this.dateFormat)
    if (dayjs().add(-1, 'days') >= current) return true
    return current && current <= start.endOf('day')
  }
  refreshSignatures = async () => {
    var data = await getAPIData('signature', 'get')
    var default_signature = data.signatures.filter(signature => signature.is_default == 1)
    if (default_signature.length == 0) {
      var default_signature = data.signatures[data.signatures.length - 1]
    } else {
      var default_signature = default_signature[0]
    }
    this.setState({
      signatures: data.signatures,
      selectedSignature: data.signatures[data.signatures.length - 1],
    })
  }
  refreshNotesTerms = async () => {
    var data = await getAPI('v2/doc', 'get_company_details', {
      document_type: this.state.document_type,
      is_web: 1,
    })

    const find = element => element.is_default == 1
    if (data.success) {
      const selected_notes = data.custom_notes.findIndex(find)
      const selected_terms = data.custom_terms.findIndex(find)
      this.setState({
        selected_note: selected_notes == -1 ? 0 : selected_notes,
        selected_term: selected_terms == -1 ? 0 : selected_terms,
        document_notes: data.custom_notes,
        document_terms: data.custom_terms,
        notes:
          data.custom_notes.length == 0
            ? ''
            : data.custom_notes[selected_notes == -1 ? 0 : selected_notes].notes,
        terms:
          data.custom_terms.length == 0
            ? ''
            : data.custom_terms[selected_terms == -1 ? 0 : selected_terms].notes,
        prefixes: data.prefixes,
      })
    }
  }

  getUnitDetails = (units, unit_id, item_unit, conversion_rate) => {
    var unit_id = units.filter(
      unit_item =>
        (unit_item.unit_id == unit_id || unit_item.alternative_unit == item_unit) &&
        unit_item.conversion_rate == conversion_rate,
    )
    if (unit_id.length == 0) {
      return {
        unit_id: 0,
        unit: '',
      }
    }
    return {
      unit_id: unit_id[0].unit_id,
      unit: unit_id[0].alternative_unit,
    }
  }

  populateDocValues = async (data, duplicate, convert) => {
    this.setState({ loaded: false, prev_total_amount: data.invoice_details.total_amount })
    let received_party_type = PartyType(data.invoice_details.document_type)
    let batch_products = []
    let serial_number_batches = {}
    let results = data.invoice_details.items.map((row, index) => {
      var custom_cols = Object.fromEntries(
        row['custom_col_names'].map((key, index1) => [key, row['custom_col_values'][index1]]),
      )
      delete row.custom_col_names
      delete row.custom_col_values

      let unitobj = this.getUnitDetails(row.units, row.unit_id, row.item_unit, row.conversion_rate)

      //batch products
      if (row.has_batches == 1) {
        batch_products.push({
          ...row,
          batch_id: row.batch_id,
          added_qty: row.qty * row.conversion_rate,
          key: index + 1,
          unit_id: unitobj.unit_id,
          unit: unitobj.unit,
          conversion_rate: row.conversion_rate,
        })
      } else if (row.has_batches == 2 && row.batches) {
        let serial_key = String(row.product_id) + '_' + String(row.variant_id)

        if (!serial_number_batches[serial_key]) {
          serial_number_batches[serial_key] = []
        }
        serial_number_batches[serial_key].push(
          ...row.batches?.map(batch => ({
            ...batch,
            price_with_tax: row.price_with_tax,
            unit_price: row.unit_price,
            price: row.unit_price,
          })),
        )
      }

      return {
        key: index + 1,
        ...row,
        ...custom_cols,
        discount: row.discount === 'NA' ? 0 : Number(row.discount),
        discount_value:
          row.discount === 'NA' ? 0 : Number((row.discount * row.price_with_tax * row.qty) / 100),
        discount_price_with_tax_value:
          row.discount === 'NA' ? 0 : Number((row.price_with_tax * row.discount) / 100),
        discount_unit_price_value:
          row.discount === 'NA' ? 0 : Number((row.unit_price * row.discount) / 100),
        discount_net_value:
          row.discount === 'NA' ? 0 : Number((row.unit_price * row.discount * row.qty) / 100),
        id: String(row.product_id) + '_' + String(row.variant_id == undefined ? 0 : row.variant_id),
        value: row.product_name + (row.variant_name == '' ? '' : ' ' + row.variant_name),
        qty: row.qty * row.conversion_rate,
        unit_id: unitobj.unit_id,
        unit: unitobj.unit,
        selected_discount_type: row?.selected_discount_type || 'discount',
        is_cess_on_qty_applicable: row.cess_on_qty_value ? true : false,
        tax_amount: parseFloat(row.tax_amount) + row.cess_on_qty_value,
        product_discount: row.master_data.discount,
        selling_price: row.master_data.price_with_tax,
        batches: row.batches || [],
      }
    })

    //separate batches with same product id
    let batches = []

    batch_products.forEach(row => {
      let index = batches.findIndex(
        batch => batch.product_id == row.product_id && batch.variant_id == row.variant_id,
      )
      if (index == -1) {
        batches.push({
          product_id: row.product_id,
          variant_id: row.variant_id,
          batches: [
            {
              batch_id: row.batch_id,
              added_qty: row.added_qty,
              old_qty: row.added_qty,
              is_edit: this.state.edit ? 1 : 0,
              batch_uid: String(row.batch_id) + '_' + String(0),
              is_duplicate: false,
              price_with_tax: row.price_with_tax,
              unit_price: row.unit_price,
              price: row.unit_price,
              key: row.key,
              unit: row.item_unit,
              unit_id: row.unit_id,
              conversion_rate: row.conversion_rate,
            },
          ],
        })
      }
      if (index != -1) {
        //find the batch with same batch id and get uid
        let same_batches = batches[index].batches.filter(batch => batch.batch_id == row.batch_id)
        let uid = String(row.batch_id) + '_' + String(0)
        let is_duplicate = false
        if (same_batches.length > 0) {
          uid = same_batches[same_batches.length - 1].batch_uid
          uid = uid.split('_')
          uid = String(row.batch_id) + '_' + String(Number(uid[1]) + 1)
          is_duplicate = true
        }
        batches[index].batches.push({
          batch_id: row.batch_id,
          added_qty: row.added_qty,
          old_qty: row.added_qty,
          is_edit: this.state.edit ? 1 : 0,
          batch_uid: uid,
          is_duplicate: is_duplicate,
          key: row.key,
          price_with_tax: row.price_with_tax,
          unit_price: row.unit_price,
          price: row.unit_price,
          unit: row.item_unit,
          unit_id: row.unit_id,
          conversion_rate: row.conversion_rate,
        })
      }
    })
    if (!['estimate', 'purchase_order'].includes(data.invoice_details.document_type)) {
      results = results.map(item => {
        if (item.has_batches == 1) {
          item.batches = batches.find(
            batch => batch.product_id == item.product_id && batch.variant_id == item.variant_id,
          ).batches

          item.batch_uid = item.batches.find(batch => batch.key == item.key).batch_uid
        } else if (item.has_batches == 2) {
          item.batches =
            serial_number_batches[String(item.product_id) + '_' + String(item.variant_id)]

          item.batches = item.batches.map(batch => ({
            ...batch,
            added_qty: 1,
            batch_uid: String(batch.batch_id) + '_' + String(0),
            is_edit: this.state.edit ? 1 : 0,
            old_qty: 1,
          }))
        }

        return item
      })
    }

    if (data.invoice_details.is_export == 1 || data.invoice_details.is_multi_currency == 1) {
      results = results.map(item => {
        item.export_unit_price =
          item.unit_price / data.invoice_details.export_details[0].conversion_factor
        item.export_price_with_tax =
          item.price_with_tax / data.invoice_details.export_details[0].conversion_factor
        return item
      })
    }

    var selectedBankId = this.state.bank_details.find(
      ({ id }) => id === data.invoice_details.bank_id,
    )
    var default_bank = this.state.bank_details.filter(bank => bank.is_default == 1)
    if (default_bank.length == 0) {
      default_bank = this.state.bank_details[0]
    } else {
      default_bank = default_bank[0]
    }
    if (!window.location.href.includes('convert')) {
      default_bank = selectedBankId
    }

    if (this.state.party_type == 'customer' && received_party_type != 'vendor') {
      await this.updateCustomer({ id: data.invoice_details.customer_id })
    }
    if (data.invoice_details.customer.price_list_id != -1) {
      this.setState({ selectedPriceList: data.invoice_details.customer.price_list_id }, () => {
        if (this.table) {
          this.table.refreshAutocompleteProducts()
        }
      })
    }

    data.invoice_details.custom_headers.map((item, index) => {
      data.invoice_details.document_custom_headers.map((item2, index2) => {
        if (item2.header_id == item.header_id) {
          data.invoice_details.custom_headers[index]['value'] = item2.value
        }
      })
    })

    if (data.invoice_details.hasOwnProperty('export_details')) {
      if (data.invoice_details.export_details.length > 0) {
        const export_details = data.invoice_details.export_details[0]

        let shipping_bill_date =
          export_details.shipping_bill_date == '0000-00-00'
            ? ''
            : dayjs(export_details.shipping_bill_date, 'ddd, DD MMM YYYY HH:mm:ss')
                .add(5.5, 'hours')
                .format('DD-MM-YYYY')
        this.setState({
          export_invoice: data.invoice_details.is_export,
          is_multi_currency: data.invoice_details.is_multi_currency,
          shipping_bill_date: shipping_bill_date,
          shipping_port_code: export_details.shipping_port_code
            ? export_details.shipping_port_code
            : '',
          shipping_bill_number: export_details.shipping_bill_number
            ? export_details.shipping_bill_number
            : '',
          export_type: export_details.export_type,
          country: export_details.country_id,
          conversion_factor: formatNumber(export_details.conversion_factor),
          currency: export_details.currency_code,
          currency_symbol: export_details.currency_symbol,
        })
      }
    }
    const tds_condition =
      this.state.document_type == 'invoice' ||
      this.state.document_type == 'sales_return' ||
      this.state.document_type == 'purchase' ||
      this.state.document_type == 'purchase_return' ||
      this.state.document_type == 'subscription'

    let selectedCustomer = ''
    if (
      this.state.party_type == 'customer' &&
      received_party_type != 'vendor' &&
      data.invoice_details.customer.customer_id != 0
    ) {
      selectedCustomer = data.invoice_details.customer.customer_id
    } else if (
      this.state.party_type == 'vendor' &&
      received_party_type != 'customer' &&
      data.invoice_details.customer.vendor_id != 0
    ) {
      selectedCustomer = data.invoice_details.customer.vendor_id
    }

    let customerData = []
    if (
      this.state.party_type == 'customer' &&
      received_party_type != 'vendor' &&
      data.invoice_details.customer.customer_id != 0
    ) {
      customerData = [
        {
          ...data.invoice_details.customer,
          id: data.invoice_details.customer.customer_id,
          value: data.invoice_details.customer.name,
          notes: data.invoice_details.customer.name,
        },
      ]
    } else if (
      this.state.party_type == 'vendor' &&
      received_party_type != 'customer' &&
      data.invoice_details.customer.vendor_id != 0
    ) {
      customerData = [
        {
          ...data.invoice_details.customer,
          id: data.invoice_details.customer.vendor_id,
          value: data.invoice_details.customer.name,
          notes: data.invoice_details.customer.name,
        },
      ]
    }
    if (customerData.length > 0 && customerData[0]?.shipping_address == undefined) {
      customerData[0].shipping_address = []
    }
    let company_shipping_addr_id = data.invoice_details.company_shipping_addr_id
    if (window.location.href.includes('convert')) {
      company_shipping_addr_id = -1
    }
    this.setState(
      {
        customerData: customerData,
        customer_details: customerData.length > 0 ? customerData[0] : this.state.customer_details,
        name: data.invoice_details.customer.name,
        selectedCustomer: selectedCustomer,
        // customerName: data.invoice_details.customer.name,
        company_shipping_addr_id: data.invoice_details.company_shipping_addr_id,
        selected_company_shipping_address: this.state.companyDetails.shipping_address.find(
          ({ addr_id }) => addr_id === company_shipping_addr_id,
        ),
        customer_shipping_addr_id: data.invoice_details.customer_shipping_addr_id,
        selected_customer_shipping_address:
          customerData.length > 0 &&
          customerData[0].shipping_address.find(
            ({ addr_id }) => addr_id === data.invoice_details.customer_shipping_addr_id,
          ),
        is_tds: tds_condition ? data.invoice_details.is_tds : false,
        tds_amount:
          data.invoice_details.is_tds && tds_condition
            ? data.invoice_details.tds_details?.[0]?.tds_amount
            : 0,
        tds_selected_section:
          data.invoice_details.is_tds && tds_condition
            ? data.invoice_details.tds_details?.[0]?.tds_id
            : undefined,
        is_tcs: tds_condition ? data.invoice_details.is_tcs : false,
        tcs_amount:
          data.invoice_details.is_tcs && tds_condition
            ? data.invoice_details.tcs_details?.[0]?.tcs_amount
            : 0,
        tcs_selected_section:
          data.invoice_details.is_tcs && tds_condition
            ? data.invoice_details.tcs_details?.[0]?.tds_id
            : undefined,
        tds_applicable_on: data.invoice_details.is_tds
          ? data.invoice_details.tds_details?.[0]?.apply_on
          : 'net_amount',
        tcs_applicable_on: data.invoice_details.is_tcs
          ? data.invoice_details.tcs_details?.[0]?.apply_on
          : 'total_amount',
        warehouse_id:
          data.invoice_details.warehouse_id == -1 ? undefined : data.invoice_details.warehouse_id,
      },
      () => {
        this.setState(
          {
            dataSource: results,
            old_items_before_edit: [...results],
            edit: this.state.edit,
            count: results.length,
            serial_number:
              duplicate || convert ? data.new_serial_number : data.invoice_details.serial_number,
            sub_serial_number:
              duplicate || convert ? data.new_serial_number : data.invoice_details.serial_number,
            id: data.invoice_details.id,
            order_serial_number:
              this.state.from_document_type == 'online_order'
                ? data.invoice_details.serial_number
                : '',

            rzp_order_id:
              this.state.from_document_type == 'online_order'
                ? data.invoice_details.rzp_order_id
                : '',
            rzp_payment_id:
              this.state.from_document_type == 'online_order'
                ? data.invoice_details.rzp_payment_id
                : '',
            new_hash_id: data.invoice_details.new_hash_id,
            doc_count: data.invoice_details.doc_count,
            is_subscription:
              this.state.document_type == 'subscription' ? 1 : data.invoice_details.is_subscription,
            supplier_invoice_date:
              this.state.document_type == 'purchase'
                ? data.invoice_details.supplier_doc_date
                : dayjs().format(this.dateFormat),
            supplier_invoice_serial_number:
              this.state.document_type == 'purchase'
                ? data.invoice_details.supplier_invoice_serial_number
                : '',
            document_date:
              convert || duplicate
                ? dayjs().format(this.dateFormat)
                : data.invoice_details.start_date,
            due_date:
              convert || duplicate
                ? dayjs().format(this.dateFormat)
                : data.invoice_details.end_date,
            // document_title: data.invoice_details.document_title,
            reference: data.invoice_details.reference,
            terms: convert && !this.state.is_draft ? this.state.terms : data.invoice_details.terms,
            exclusive_notes:
              convert && !this.state.is_draft
                ? this.state.exclusive_notes
                : data.invoice_details.exclusive_notes,
            hide_totals: convert ? 0 : data.invoice_details.hide_totals,
            notes: convert && !this.state.is_draft ? this.state.notes : data.invoice_details.notes,
            name: data.invoice_details.customer.name,
            is_pos: data.invoice_details.invoice_type == 'b2c',
            description: data.invoice_details.show_description,
            selectedSignature:
              this.state.signatures.find(({ image }) => image === data.invoice_details.signature) ||
              this.state.selectedSignature,
            gst_details: data.invoice_details.gst_details,
            selected_bank: default_bank,
            prefix:
              convert || duplicate
                ? data.invoice_details.prefix
                : data.invoice_details.serial_number.replace(
                    new RegExp(
                      data.invoice_details.doc_number +
                        '(?![\\s\\S]*' +
                        data.invoice_details.doc_number +
                        ')',
                    ),
                    '',
                  ),
            new_prefix: convert
              ? ''
              : data.invoice_details.serial_number.replace(
                  new RegExp(
                    data.invoice_details.doc_number +
                      '(?![\\s\\S]*' +
                      data.invoice_details.doc_number +
                      ')',
                  ),
                  '',
                ),
            company_id: data.invoice_details.company_id,
            attachments: data.invoice_details.attachments,
            custom_headers: data.invoice_details.custom_headers,
            with_tax:
              convert &&
              this.state.document_type !== 'pro_forma_invoice' &&
              this.state.document_type !== 'sales_return' &&
              !this.state.is_draft &&
              data.invoice_details.company.is_composite == 0 &&
              !data.invoice_details.is_export
                ? 1
                : data.invoice_details.with_tax,

            roundoff: data.invoice_details.roundoff,
            roundoff_value: data.invoice_details.roundoff_value,
            rcm: data.invoice_details.rcm,
            rcm_value: data.invoice_details.rcm == 1 ? data.invoice_details.tax_amount : 0,
            doc_number:
              duplicate || convert
                ? data.invoice_details.new_doc_number
                : data.invoice_details.doc_number,

            coupon_details: data.invoice_details.coupon_details,
            invoiceData: data.invoice_details,
            immovable_tax_type: data.invoice_details.immovable_tax_type,
          },
          async () => {
            if (this.table) {
              await this.table.edit(this.state.edit)
            }
            // console.log(this.state.dataSource)
            let newData = this.state.dataSource.map((item, index) => {
              return this.table?.onCustomChange(
                item.qty,
                item.key,
                'Quantity',
                'qty',
                this.state.dataSource,
              )[index]
            })
            clearTimeout(this.draftTimeout)
            this.draftTimeout = null
            this.setState({ dataSource: newData })
            if (this.table) {
              await this.table?.dataUpdate(newData, this.state.list_name)
            }

            if (data.invoice_details.is_subscription) {
              this.setState({
                subscription_start_date: dayjs(data.invoice_details.subscription.start_time).format(
                  this.dateFormat,
                ),

                subscription_end_date: dayjs(data.invoice_details.subscription.end_time).format(
                  this.dateFormat,
                ),
                repeatType: data.invoice_details.subscription.repeat_unit,
                repeat_every: data.invoice_details.subscription.repeat_every,
                send_email: data.invoice_details.subscription.send_email,
                send_sms: data.invoice_details.subscription.send_sms,
              })
            }

            var custom_additional_charges = data.invoice_details.custom_additional_charges.map(
              (item, index) => {
                const index1 = data.invoice_details.document_custom_additional_charges.findIndex(
                  item1 => item1.additional_charge_id == item.additional_charge_id,
                )
                if (index1 != -1) {
                  return {
                    ...item,
                    ...data.invoice_details.document_custom_additional_charges[index1],
                    sac_code: item.sac_code,
                  }
                } else {
                  return {
                    ...item,
                    total_amount: 0,
                    tax: 0,
                    tax_amount: 0,
                    percent: 0,
                    net_amount: 0,
                  }
                }
              },
            )
            custom_additional_charges = custom_additional_charges.filter(
              item => !(item.total_amount == 0 && (item.is_delete == 1 || item.is_active == 0)),
            )
            this.setState(
              {
                extraDiscount: data.invoice_details.extra_discount,
                extraDiscountPer: (
                  (Number(data.invoice_details.extra_discount) * 100) /
                  (this.getTotal() ? this.getTotal() : 1)
                ).toFixed(2),
                has_extra_charges: data.invoice_details.has_extra_charges,
                document_custom_additional_charges: custom_additional_charges,
                custom_additional_charges: data.invoice_details.custom_additional_charges,
              },
              () => this.onRoundOffChange(this.state.roundoff),
            )
          },
        )
      },
    )
    this.setState({ loaded: true })
  }

  async fetchDetails() {
    // get document type
    if (!this.props.external_create) {
      if (window.location.href.includes('create')) {
        var url_items = window.location.href.split('/create/')
        var document_type = url_items[url_items.length - 1].split('/')[0]
        var from_document_type = ''
        var to_document_type = ''
        var create = true
        var edit = false
        var duplicate = false
        var convert = false
      } else if (window.location.href.includes('edit')) {
        var url_items = window.location.href.split('/edit/')
        var document_type = url_items[url_items.length - 1].split('/')[0]
        var from_document_type = ''
        var to_document_type = ''
        var create = false
        var edit = true
        var duplicate = false
        var convert = false
      } else if (window.location.href.includes('duplicate')) {
        var url_items = window.location.href.split('/duplicate/')
        var document_type = url_items[url_items.length - 1].split('/')[0]
        var from_document_type = ''
        var to_document_type = ''
        var create = false
        var edit = false
        var duplicate = true
        var convert = false
      } else if (window.location.href.includes('convert')) {
        var url_items = window.location.href.split('/convert/')
        var from_document_type = url_items[url_items.length - 1].split('/')[0]
        var to_document_type = url_items[url_items.length - 1].split('/')[1]
        var document_type = to_document_type
        var create = false
        var edit = false
        var duplicate = false
        var convert = true
      }
    } else {
      var document_type = this.props.document_type
      var from_document_type = ''
      var to_document_type = ''
      var create = true
      var edit = false
      var duplicate = false
      var convert = false
    }

    var req = { document_type: document_type, is_web: 1 }
    if (document_type == 'invoice') {
      var party_type = 'customer'
      var document_title = 'Invoice'
      var list_name = 'sales'
      var movement = 'out'
    } else if (document_type == 'purchase') {
      var party_type = 'vendor'
      var document_title = 'Purchase'
      var list_name = 'purchases'
      var movement = 'in'
    } else if (document_type == 'purchase_order') {
      var party_type = 'vendor'
      var document_title = 'Purchase Order'
      var list_name = 'purchase_orders'
      var movement = ''
    } else if (document_type == 'estimate') {
      var party_type = 'customer'
      var document_title = 'Quotation'
      var list_name = 'estimates'
      var movement = ''
    } else if (document_type == 'sales_return') {
      var party_type = 'customer'
      var document_title = 'Sales Return / Credit Note'
      var list_name = 'sales_returns'
      var movement = 'in'
    } else if (document_type == 'delivery_challan') {
      var party_type = 'customer'
      var document_title = 'Delivery Challan'
      var list_name = 'delivery_challans'
      var movement =
        this.props.document.invoiceSettings.track_stock_for_delivery_challan == 1 ? 'out' : ''
    } else if (document_type == 'purchase_return') {
      var party_type = 'vendor'
      var document_title = 'Purchase Return / Debit Note'
      var list_name = 'purchase_returns'
      var movement = 'out'
    } else if (document_type == 'pro_forma_invoice') {
      var party_type = 'customer'
      var document_title = 'Pro Forma Invoice'
      var list_name = 'pro_forma_invoices'
      var movement = ''
    } else if (document_type == 'subscription') {
      var party_type = 'customer'
      var document_title = 'Subscription'
      var list_name = 'subscriptions'
      var movement = 'in'
    } else if (document_type == 'online_order') {
      var party_type = 'customer'
      var document_title = 'Online Order'
      var list_name = 'onlineorders'
      var movement = 'in'
    }
    var data = await getAPI('v2/doc', 'get_company_details', req)

    let stock_in_warehouse_ids =
      this.props.stock_in_warehouses?.map(item => item.warehouse_id) ?? []
    let stock_out_warehouse_ids =
      this.props.stock_out_warehouses?.map(item => item.warehouse_id) ?? []
    let default_warehouse_id = undefined
    if (movement == 'in' && stock_in_warehouse_ids?.includes(this.props.default_warehouse_id)) {
      default_warehouse_id = this.props.default_warehouse_id
    }
    if (movement == 'out' && stock_out_warehouse_ids?.includes(this.props.default_warehouse_id)) {
      default_warehouse_id = this.props.default_warehouse_id
    }
    if (movement == '') {
      default_warehouse_id = this.props.default_warehouse_id
    }
    if (data.success) {
      // set default bank and signature
      var default_banks = data.bank_details.filter(bank => bank.is_default == 1)
      var default_bank = default_banks.length
        ? default_banks[0]
        : data.bank_details[data.bank_details.length - 1]

      // console.log(data.signatures)
      var default_signatures = data.signatures.filter(signature => signature.is_default == 1)
      var default_signature = default_signatures.length
        ? default_signatures[0]
        : data.signatures[data.signatures.length - 1]

      data.custom_headers.map((item, index) => {
        if (item.placeholder != '') {
          data.custom_headers[index]['value'] = item.placeholder
        }
      })
      const find = element => element.is_default == 1
      const selected_notes = data.custom_notes.findIndex(find)
      const selected_terms = data.custom_terms.findIndex(find)
      if (window.location.href.includes('create')) {
        data.company_details.shipping_address = data.company_details.shipping_address.filter(
          item => item.is_delete == 0,
        )
      }

      var document_custom_additional_charges = data.custom_additional_charges.map((item, index) => {
        const currentItem = this.state.document_custom_additional_charges?.find(
          item1 => item1.additional_charge_id == item.additional_charge_id,
        )

        return {
          name: item.name,
          additional_charge_id: item.additional_charge_id,
          total_amount: currentItem?.total_amount || 0,
          tax: currentItem?.tax || 0,
          tax_amount: currentItem?.tax_amount || 0,
          percent: currentItem?.percent || 0,
          net_amount: currentItem?.net_amount || 0,
          sac_code: item.sac_code,
          type: item.type,
        }
      })
      this.setState(
        {
          prefixes: data.prefixes,
          document_notes: data.custom_notes,
          document_terms: data.custom_terms,
          selected_note: selected_notes == -1 ? 0 : selected_notes,
          selected_term: selected_terms == -1 ? 0 : selected_terms,
          bank_details: data.bank_details,
          paymentMethod:
            default_bank?.bank_name != 'Cash'
              ? this.props?.document?.paymentModes?.[0]?.value
              : 'Cash',
          with_tax:
            data.company_details.gstin?.length != 15 ||
            (data.company_details.is_composite == 1 && !document_type.includes('purchase'))
              ? 0
              : 1,
          signatures: data.signatures,
          selected_bank: default_bank,
          serial_number: data.serial_number,
          sub_serial_number: data.serial_number,
          notes:
            data.custom_notes.length == 0
              ? ''
              : data.custom_notes[selected_notes == -1 ? 0 : selected_notes].notes,
          terms:
            data.custom_terms.length == 0
              ? ''
              : data.custom_terms[selected_terms == -1 ? 0 : selected_terms].notes,
          multiple_gst: data.multiple_gst,
          gst_details: data.multiple_gst.length > 0 ? data.multiple_gst[0] : {},
          prefix: create ? data.prefix : '',
          company_id: data.company_id,
          doc_number: create ? data.doc_number : '',
          taxes: data.taxes,
          selectedSignature: default_signature,
          image_url: data.logo,
          custom_headers: data.custom_headers,
          document_type: document_type,
          party_type: party_type,
          document_title: document_title,
          movement: movement,
          warehouse_id: default_warehouse_id,
          is_subscription: document_type == 'subscription' ? 1 : 0,
          list_name: list_name,
          create: create,
          edit: edit,
          duplicate: duplicate,
          convert: convert,
          from_document_type: from_document_type,
          to_document_type: to_document_type,
          is_draft: from_document_type == 'draft' ? true : false,
          companyDetails: data.company_details,
          document_discount_type: data.company_details.discount_type,
          autocompleteData: [],
          selected_company_shipping_address:
            data.company_details.shipping_address.length && document_type.includes('purchase')
              ? data.company_details.shipping_address[0]
              : {},
          autocompleteData: [],
          document_custom_additional_charges: document_custom_additional_charges,
          custom_additional_charges: data.custom_additional_charges,
          prev_total_amount: 0,
          credit_limit_warning_shown: false,
        },
        async () => {
          // console.log(this.state.prefix)
          var data = await getAPI('product', 'get_price_lists', { search: '' })
          if (data.success) {
            this.setState({ price_lists: data.price_lists })
          }

          if (this.props.external_create) {
            let customer_id = this.props.customer_id
            var req = {
              id: customer_id,
            }
            var data = await getAPI('v2/' + this.state.party_type, 'get_details', req)

            if (data.success) {
              if (
                data[this.state.party_type + '_details'][0].is_tds == 1 &&
                data[this.state.party_type + '_details'][0].tds_section_id != 0 &&
                (this.state.document_type == 'invoice' ||
                  this.state.document_type == 'sales_return' ||
                  this.state.document_type == 'purchase' ||
                  this.state.document_type == 'purchase_return' ||
                  this.state.document_type == 'subscription')
              ) {
                this.setState({ is_tds: true })
              }
              if (data[this.state.party_type + '_details'][0].tds_section_id != 0) {
                this.setState({
                  tds_selected_section: data[this.state.party_type + '_details'][0].tds_section_id,
                })
              }
              if (
                data[this.state.party_type + '_details'][0].is_tcs == 1 &&
                data[this.state.party_type + '_details'][0].tcs_section_id != -1 &&
                (this.state.document_type == 'invoice' ||
                  this.state.document_type == 'sales_return' ||
                  this.state.document_type == 'purchase' ||
                  this.state.document_type == 'purchase_return' ||
                  this.state.document_type == 'subscription')
              ) {
                this.setState({ is_tcs: true })
              }
              if (data[this.state.party_type + '_details'][0].tcs_section_id != -1) {
                this.setState({
                  tcs_selected_section: data[this.state.party_type + '_details'][0].tcs_section_id,
                })
              }
              if (data[this.state.party_type + '_details'][0]['price_list_id'] != -1) {
                this.setState(
                  {
                    selectedPriceList: data[this.state.party_type + '_details'][0]['price_list_id'],
                  },
                  () => {
                    this.table?.refreshAutocompleteProducts()
                  },
                )
              }

              this.setState({
                customer_details: data.customer_details[0],
                selected_customer_shipping_address:
                  data.customer_details[0]['shipping_address'].length > 0
                    ? data.customer_details[0]['shipping_address'][0]
                    : {},
              })

              this.setState({
                customerData: [
                  {
                    id:
                      this.state.party_type == 'customer'
                        ? data.customer_details[0].customer_id
                        : data.customer_details[0].vendor_id,
                    value: data.customer_details[0].name,
                    notes: data.customer_details[0].name,
                  },
                ],
                name: data.customer_details[0].name,
                selectedCustomer:
                  this.state.party_type == 'customer'
                    ? data.customer_details[0].customer_id
                    : data.customer_details[0].vendor_id,
              })
            }
          }
          this.setState({ loaded: false })

          // auto select customer/vendor from id
          if (window.location.href.includes('create')) {
            var url_items = window.location.href.split('/create/')
            if (url_items[url_items.length - 1].split('/').length > 1) {
              var customer_id = parseInt(url_items[url_items.length - 1].split('/')[1])
              var req = {
                id: customer_id,
              }

              var data = await getAPI('v2/' + this.state.party_type, 'get_details', req)
              if (data.success) {
                if (data[this.state.party_type + '_details'][0]['price_list_id'] != -1) {
                  this.setState(
                    {
                      selectedPriceList:
                        data[this.state.party_type + '_details'][0]['price_list_id'],
                    },
                    () => {
                      this.table?.refreshAutocompleteProducts()
                    },
                  )
                }

                this.setState({
                  customer_details: data.customer_details[0],
                  selected_customer_shipping_address:
                    data.customer_details[0]['shipping_address'].length > 0
                      ? data.customer_details[0]['shipping_address'][0]
                      : {},
                  customerData: [
                    {
                      id:
                        this.state.party_type == 'customer'
                          ? data.customer_details[0].customer_id
                          : data.customer_details[0].vendor_id,
                      value: data.customer_details[0].name,
                      notes: data.customer_details[0].name,
                    },
                  ],
                  name: data.customer_details[0].name,
                  selectedCustomer:
                    this.state.party_type == 'customer'
                      ? data.customer_details[0].customer_id
                      : data.customer_details[0].vendor_id,
                })
              }
            }
          }

          if (window.location.href.includes('edit')) {
            var url_items = window.location.href.split('/edit/')
            var document_type = url_items[url_items.length - 1].split('/')[0]
            var new_hash_id = url_items[url_items.length - 1].split('/')[1]

            const req = {
              document_type: document_type,
              new_hash_id: new_hash_id,
              is_pdf: false,
            }

            var data = await getAPI('v2/doc', 'get_invoice', req)

            if (data.success) {
              this.setState(
                {
                  loaded: true,
                  payment_status: data.invoice_details.payment_status,
                  document_title: data.invoice_details.document_title,
                  selected_note: undefined,
                  selected_term: undefined,
                  document_discount_type: data.invoice_details.discount_type,
                  company_shipping_addr_id: data.invoice_details.company_shipping_addr_id,
                },
                async () => {
                  await this.populateDocValues(data, false, false)
                },
              )
            }
          } else if (window.location.href.includes('duplicate')) {
            var url_items = window.location.href.split('/duplicate/')
            var document_type = url_items[url_items.length - 1].split('/')[0]
            var new_hash_id = url_items[url_items.length - 1].split('/')[1]

            const req = {
              document_type: document_type,
              new_hash_id: new_hash_id,
              duplicate: this.state.duplicate,
              convert: this.state.convert,
              convert_from: this.state.from_document_type,
              convert_to: this.state.to_document_type,
              is_pdf: false,
            }

            var data = await getAPI('v2/doc', 'get_invoice', req)

            if (data.success) {
              this.setState(
                { loaded: true, document_discount_type: data.invoice_details.discount_type },
                async () => {
                  await this.populateDocValues(data, true, false)
                },
              )
            }
          } else if (window.location.href.includes('convert')) {
            var url_items = window.location.href.split('/convert/')
            var document_type = url_items[url_items.length - 1].split('/')[0]
            var new_hash_id = url_items[url_items.length - 1].split('/')[2]

            const req = {
              document_type: from_document_type,
              new_hash_id: new_hash_id,
              duplicate: this.state.duplicate,
              convert: this.state.convert,
              convert_from: this.state.from_document_type,
              convert_to: this.state.to_document_type,
              is_pdf: false,
            }

            var data = await getAPI('v2/doc', 'get_invoice', req)

            if (data.success) {
              this.setState(
                { loaded: true, document_discount_type: data.invoice_details.discount_type },
                async () => {
                  await this.populateDocValues(data, false, true)
                },
              )
            }
          }
          this.setState({ loaded: true })
          this.customerSearchAPI('')
        },
      )
    }

    // console.log(this.props)
    if (this.props?.tds_sections?.length == 0) {
      await this.props.dispatch(getTdsSections())
    }
    if (this.props?.tcs_sections?.length == 0) {
      await this.props.dispatch(get_tcs_sections())
    }
    if (this.props?.countries?.length == 0) {
      await this.props.dispatch(getCountries())
    }
    if (this.props?.product_categories?.length == 0) {
      await this.props.dispatch(get_categories())
    }
    if (this.props?.warehouses == undefined) {
      await this.props.dispatch(get_warehouses())
    }
    if (this.props?.document?.paymentModes?.length == 0) {
      await this.props.dispatch(get_payment_modes())
    }
    // if (this.props?.port_codes?.length == 0) {
    //   await this.props.dispatch(get_port_codes())
    // }

    if (this.props.invoice_settings?.show_custom_header_suggestions) {
      const custom_headers_suggestions = await getAPI('utils', 'get_suggestions', {
        type: 'custom_headers',
        document_type,
      })
      if (custom_headers_suggestions?.success) {
        this.setState({ custom_headers_suggestions: custom_headers_suggestions?.suggestions })
      }
    }

    this.setState({
      items: this.state.items.map(item =>
        item.key == this.state.activeKey
          ? {
              ...item,
              label: this.state.prefix + this.state.doc_number,
            }
          : item,
      ),
    })
    const payload = {
      key: '1',
      data: this.state,
    }
    this.initialState = this.state
    await this.props.dispatch(add_invoice_data(payload))
  }

  async componentDidMount() {
    this.fetchDetails()
    // Change to cookies
    // this.financialYearRef.current.showModal()

    if (this.props.top_fields.length == 0) {
      this.props.dispatch(get_top_custom_fields())
    }
    await this.getSuggestedColumns()
    if (
      window.location.pathname.includes('create')
      //  || window.location.pathname.includes('edit')
    ) {
      // this.startAutoDrafting()
    }
  }

  onChangeFullyPaid = e => {
    if (e) {
      this.setState({
        fullyPaid: e,
        amountReceived: this.getTotalAmount(),
      })
    } else {
      this.setState({
        fullyPaid: e,
        amountReceived: 0,
      })
    }
  }

  onChangePaymentMethod = e => {
    if (e == 'Cash') {
      const selectedBank = this.state.bank_details.find(({ bank_name }) => bank_name == 'Cash')
      this.setState({
        selected_bank: selectedBank,
      })
    } else if (this.state.selected_bank.bank_name == 'Cash') {
      message.error('Please select a bank')
      return
    }
    this.setState({ paymentMethod: e })
  }

  onChangeAmountReceived = e => {
    this.setState({ amountReceived: e })
  }

  componentWillUnmount() {
    // fix Warning: Can't perform a React state update on an unmounted component
    clearTimeout(this.draftTimeout)
    this.save.cancel()
    this.draftTimeout = null
    this.setState = (state, callback) => {
      return
    }
    // this.stopAutoDrafting()
  }

  disabledDate = current => {
    return current && current < dayjs(this.state.document_date, this.dateFormat)
  }

  onSubscriptionDateChange = (date, string, type) => {
    if (type == 'start') {
      this.setState({ subscription_start_date: string, subscription_end_date: string })
    } else if (type == 'end') {
      this.setState({ subscription_end_date: string })
    }
  }

  renderTitle = title => {
    return (
      <Button
        block
        type="action-o"
        className="font-weight-bolder"
        onClick={() =>
          this.state.party_type == 'customer'
            ? this.customer.onAdd(this.state.customerName)
            : this.vendor.onAdd(this.state.customerName)
        }
      >
        <i className="fa fa-plus-circle mr-2" />
        {title}
      </Button>
    )
  }

  onCustomerSelect = async (data, option) => {
    var req = {
      id: option.id,
    }
    var data = await getAPI('v2/' + this.state.party_type, 'get_details', req)
    if (data.success) {
      if (
        data.customer_details[0]?.gstin &&
        (this.state.export_type == 'Export under bond/LUT' ||
          this.state.export_type == 'Export with IGST')
      ) {
        message.error(`${this.state.export_type} is not allowed with GSTIN Customer`)
        return
      }
      if (data.customer_details[0].shipping_address.length) {
        this.setState({
          customer_details: data.customer_details[0],
          selected_customer_shipping_address: data.customer_details[0]['shipping_address'][0],
        })
      } else {
        this.setState({
          customer_details: data.customer_details[0],
          selected_customer_shipping_address: {},
        })
      }
      if (data.customer_details[0].export_customer == 1) {
        this.setState(
          {
            export_invoice: true,
            with_tax: !(
              this.state.export_type?.includes('LUT') || this.state.export_type?.includes('without')
            ),
          },
          () => this.onWithTaxChange(),
        )
      }
      if (this.state.party_type == 'customer' && data.customer_details[0]['price_list_id'] != -1) {
        // this.onPriceListChange(data.customer_details[0]['price_list_id'])
        this.setState({ selectedPriceList: data.customer_details[0]['price_list_id'] }, () => {
          this.table?.refreshAutocompleteProducts()
        })
      }
      if (
        data.customer_details[0].is_tds == 1 &&
        data.customer_details[0].tds_section_id != 0 &&
        (this.state.document_type == 'invoice' ||
          this.state.document_type == 'sales_return' ||
          this.state.document_type == 'purchase' ||
          this.state.document_type == 'purchase_return')
      ) {
        this.setState({ is_tds: true })
      }
      if (data.customer_details[0].tds_section_id != 0) {
        this.setState({ tds_selected_section: data.customer_details[0].tds_section_id })
      }
      if (
        data.customer_details[0].is_tcs == 1 &&
        data.customer_details[0].tcs_section_id != -1 &&
        (this.state.document_type == 'invoice' ||
          this.state.document_type == 'sales_return' ||
          this.state.document_type == 'purchase' ||
          this.state.document_type == 'purchase_return' ||
          this.state.document_type == 'subscription')
      ) {
        this.setState({ is_tcs: true })
      }
      if (data.customer_details[0].tcs_section_id != -1) {
        this.setState({ tcs_selected_section: data.customer_details[0].tcs_section_id })
      }
    }

    const index = this.state.customerData.findIndex(item => item.id === option.id)
    if (index == -1) {
      option = { ...data.customer_details[0], ...option }
      this.setState(
        {
          selectedCustomer: option.id,
          customerName: '',
          customerData: this.state.edit ? [option] : [...this.state.customerData, option],
          autocompleteData: [],
        },
        () => {
          let customerDiscount = 0
          if (this.state.customerData.length == 1) {
            customerDiscount = this.state.customerData[0].discount
          }
          this.setState({ customerDiscount: customerDiscount })
          this.onCouponsApply(false)
        },
      )
    } else {
      message.warning('Customer already added.')
      this.setState({
        autocompleteData: [],
      })
    }
    this.customerSearchAPI('')
  }

  onVendorSelect = async (data, option) => {
    var req = {
      id: option.id,
    }
    var data = await getAPI('v2/' + this.state.party_type, 'get_details', req)
    // console.log(data);
    if ('success' in data) {
      if (data.success) {
        this.setState({ vendor_details: data.vendor_details[0] })
        option = { ...data.vendor_details[0], ...option }
        if (
          data.vendor_details[0].is_tds == 1 &&
          data.vendor_details[0].tds_section_id != 0 &&
          (this.state.document_type == 'invoice' ||
            this.state.document_type == 'sales_return' ||
            this.state.document_type == 'purchase' ||
            this.state.document_type == 'purchase_return')
        ) {
          this.setState({ is_tds: true })
        }
        if (data.vendor_details[0].tds_section_id != 0) {
          this.setState({ tds_selected_section: data.vendor_details[0].tds_section_id })
        }
        if (
          data.vendor_details[0].is_tcs == 1 &&
          data.vendor_details[0].tcs_section_id != -1 &&
          (this.state.document_type == 'invoice' ||
            this.state.document_type == 'sales_return' ||
            this.state.document_type == 'purchase' ||
            this.state.document_type == 'purchase_return')
        ) {
          this.setState({ is_tcs: true })
        }
        if (data.vendor_details[0].tcs_section_id != -1) {
          this.setState({ tcs_selected_section: data.vendor_details[0].tcs_section_id })
        }
        if (data.vendor_details[0]['price_list_id'] != -1) {
          this.setState({ selectedPriceList: data.vendor_details[0]['price_list_id'] }, () => {
            this.table?.refreshAutocompleteProducts()
          })
        }
      }
    }

    const index = this.state.customerData.findIndex(item => item.id === option.id)
    if (index == -1) {
      this.setState(
        {
          selectedCustomer: option.id,
          customerName: '',
          customerData:
            this.state.document_type == 'purchase' || this.state.document_type == 'purchase_return'
              ? [option]
              : [...this.state.customerData, option],
          autocompleteData: [],
        },
        () => {
          let customerDiscount = 0
          if (this.state.customerData.length == 1) {
            customerDiscount = this.state.customerData[0].discount
          }
          this.setState({ customerDiscount: customerDiscount })
        },
      )
    } else {
      message.warning('Vendor already added.')
      this.setState({
        autocompleteData: [],
      })
    }
    this.customerSearchAPI('')
  }

  updateCustomer = async option => {
    var req = {
      id: option.id,
    }
    var data = await getAPI('v2/customer', 'get_details', req)
    if (data.success) {
      let selected_customer_shipping_address = data.customer_details[0].shipping_address.length
        ? data.customer_details[0]['shipping_address'][0]
        : {}

      this.setState({
        customer_details: data.customer_details[0],
        selected_customer_shipping_address: selected_customer_shipping_address,
        customerDiscount: data.customer_details[0].discount,
      })
    }
  }

  getStatusClass = data => {
    if (data < 0) {
      return '#e11900'
    } else if (data == 0) {
      return '#757575'
    } else {
      return '#09864A'
    }
  }

  customerSearchAPI = async (searchText, isSearch = true) => {
    const { page, num_records } = this.state

    const req = {
      query: searchText,
      page: this.state.page,
      num_records: num_records,
    }
    // try {
    //   if (JSON.stringify(req) === JSON.stringify(this.state.prevReq) && searchText != '') {
    //     return
    //   } else {
    //     this.setState({ prevReq: req })
    //   }
    // } catch (error) {
    //   // console.log(error)
    // }

    const data = await getAPI(this.state.party_type, 'search', req)
    if (data) {
      var parties = this.state.party_type == 'customer' ? data.customers : data.vendors
      const allData = parties.map(value => {
        return {
          value: (
            <span>
              <span>{value.name + (value.phone != '' ? ' (' + value.phone + ')' : '')}</span>
              {this.props.permissions.ledgers == 1 && (
                <span
                  className="font-size-12 float-right font-weight-bold"
                  style={{ color: this.getStatusClass(value.balance + value.opening_balance) }}
                >
                  <span className="font-size-12 mr-1">
                    {this.props?.user?.selectedCompany?.currency_symbol}
                  </span>
                  {Math.round(Math.abs(value.balance + value.opening_balance)).toLocaleString(
                    'en-IN',
                    dotOptions,
                  )}
                </span>
              )}
              <p className="font-size-12 mb-0">
                <span className="text-gray-500">{value.gstin}</span> {value.company_name}
                <span className="float-right text-gray-500">
                  {value.tags && value.tags != '' ? 'Tags: ' + value.tags : ''}
                </span>
              </p>
            </span>
          ),
          id: this.state.party_type == 'customer' ? value.customer_id : value.vendor_id,
          key: this.state.party_type == 'customer' ? value.customer_id : value.vendor_id,
          notes: value.name,
          discount: value.discount,
        }
      })

      if (isSearch) {
        this.setState({
          autocompleteData: [...allData],
          page: 0,
        })
      } else {
        this.setState({
          autocompleteData: [...this.state.autocompleteData, ...allData],
        })
      }
    }
  }

  onCustomerSearch = async searchText => {
    if (this.timeout) clearTimeout(this.timeout)
    this.setState({ selectedCustomer: '', customerName: searchText })
    this.timeout = setTimeout(() => {
      this.customerSearchAPI(searchText)
    }, 700)
  }

  allowBatches = document_type => {
    return document_type == 'estimate' ||
      document_type == 'purchase_order' ||
      document_type == 'pro_forma_invoice'
      ? false
      : true
  }

  allowWarehouses = () => {
    let document_types = {
      invoice: true,
      sales_return: true,
      purchase: true,
      purchase_return: true,
      subscription: true,
      delivery_challan: this.props.document.invoiceSettings.track_stock_for_delivery_challan == 1,
      pro_forma_invoice: false,
      purchase_order: false,
      estimate: false,
    }

    return document_types[this.state.document_type]
  }
  onPurchaseUpdateConfirm = selectedProducts => {
    let dataSource = [...this.state.dataSource]
    dataSource = dataSource.map(item => {
      item.is_update_purchase_columns = selectedProducts.includes(item.id)
      return item
    })
    this.setState({ dataSource, product_update_warning_shown: true }, () => {
      this.save(this.state.redirect)
    })
  }

  validateDeductions = items => {
    const deductionsTaxWise = {}
    this.state.document_custom_additional_charges.forEach(charge => {
      if (charge.type == -1 && charge.total_amount > 0 && charge.tax != 0) {
        deductionsTaxWise[charge.tax] = (deductionsTaxWise[charge.tax] || 0) + charge.total_amount
      }
    })

    if (Object.keys(deductionsTaxWise).length == 0) {
      return true
    }

    const itemTaxWise = {}
    items.forEach(item => {
      itemTaxWise[item.tax] = Math.max(itemTaxWise[item.tax] || 0, item.total_amount)
    })

    const allTaxRatesPresent = Object.keys(deductionsTaxWise).every(tax => tax in itemTaxWise)

    if (!allTaxRatesPresent) {
      message.error('Tax rate of the deduction is not present in the items')
      return false
    }

    const anyDeductionAmount = Object.keys(deductionsTaxWise).some(
      tax => deductionsTaxWise[tax] > itemTaxWise[tax],
    )
    if (anyDeductionAmount) {
      notification.open({
        message: 'Error',
        description:
          'Deduction amount must be less than or equal to the maximum item amount of the same tax rate',
        duration: 10,
        icon: <i className="fa fa-exclamation-circle text-danger mr-2" />,
        style: {
          borderRadius: '10px',
          backgroundColor: '#ffffff',
        },
      })
      return false
    }
    return true
  }
  scrollToElement = id => {
    const element = document.getElementById(id)
    if (element) {
      element.scrollIntoView({ behavior: 'smooth', block: 'center' })
    }
  }
  validateCustomHeaders = () => {
    let has_custom_header_error = false
    const verified_custom_headers = this.state.custom_headers.map(header => {
      header.is_error = false
      if (header.is_required && (header.value == '' || header.value == undefined)) {
        header.is_error = true
        has_custom_header_error = true
      }
      return header
    })
    if (has_custom_header_error) {
      this.setState({ custom_headers: verified_custom_headers }, () => {
        this.scrollToElement('document_custom_headers')
      })
      return false
    }
    return true
  }
  docValidations = (is_draft, redirect, save_draft) => {
    if (!is_draft) {
      if (this.state.export_invoice && this.state.conversion_factor == '') {
        return [false, 'Conversion Factor cannot be empty']
      }

      if (this.state.export_invoice && !this.state.export_type) {
        return [false, 'Please select Export Type']
      }

      if (this.state.doc_number == '' || this.state.doc_number == null) {
        return [false, this.state.document_title + ' Number cannot be empty']
      }
      if (this.state.customerData.length == 0 && !this.state.is_pos) {
        if (this.state.customerName != '') {
          document.body.scrollIntoView({ behavior: 'smooth' })
          if (this.state.party_type == 'customer') {
            this.customer.onAdd(this.state.customerName)
          } else {
            this.vendor.onAdd(this.state.customerName)
          }
        } else {
          document.body.scrollIntoView({ behavior: 'smooth' })
          if (this.inputEl.current) {
            this.inputEl.current.focus()
          }
        }
        return [false, 'Please Add ' + this.state.party_type]
      }

      if (
        this.props.invoice_settings?.supplier_invoice_serial_number &&
        this.state.document_type == 'purchase'
      ) {
        if (!this.state.supplier_invoice_serial_number) {
          return [false, 'Please add Supplier Invoice Serial No.']
        }
      }

      if (this.state.is_tds && this.state.tds_selected_section == undefined) {
        return [false, 'Please select TDS section']
      }
      if (this.state.is_tcs && this.state.tcs_selected_section == undefined) {
        return [false, 'Please select TCS section']
      }
      if (
        this.state.is_tds &&
        (this.state.tds_amount == undefined || this.state.tds_amount == '')
      ) {
        return [false, 'TDS amount cannot be empty']
      }
      if (
        this.state.is_tcs &&
        (this.state.tcs_amount == undefined || this.state.tcs_amount == '')
      ) {
        return [false, 'TCS amount cannot be empty']
      }
      if (this.state.repeat_every == '') {
        return [false, 'Repeat Unit cannot be empty']
      }
      if (this.state.export_invoice && isNaN(this.state.conversion_factor)) {
        return [false, 'Conversion Factor cannot be empty']
      }
      if (
        this.state.amountReceived > 0 &&
        this.state.paymentMethod != 'Cash' &&
        this.state.selected_bank.id == 0
      ) {
        return [false, 'Please select a bank account']
      }
      if (this.state.amountReceived < 0) {
        return [false, 'Amount Received cannot be negative']
      }
      if (
        this.props.warehouses.length > 0 &&
        this.state.warehouse_id == undefined &&
        this.allowWarehouses() &&
        this.state.document_type != 'online_order'
      ) {
        return [false, 'Please select a warehouse']
      }

      if (!this.validateCustomHeaders() && this.state.document_type != 'online_order') {
        return [false, '']
      }
      let items = [...this.state.dataSource]
      if (items.length == 0) {
        return [false, 'Please add at least one product to proceed']
      }

      if (this.allowBatches(this.state.document_type)) {
        let filterdItems = items.filter(
          item =>
            (item.batch_id == 0 && item.has_batches == 1) ||
            (item.has_batches == 2 && item?.serial_numbers?.length == 0),
        )
        if (filterdItems.length > 0) {
          this.table.openSelectBatches()
          return [false, '']
        }
      }

      for (var i = 0; i < items.length; i++) {
        if (items[i].price < 0 && !is_draft) {
          return [false, 'Negative values are not allowed for Unit Price.']
        }
      }
    }
    if (
      !this.state.product_update_warning_shown &&
      this.props.document_settings.purchase_update_columns.length > 0 &&
      this.state.document_type == 'purchase' &&
      !save_draft &&
      !is_draft
    ) {
      // this.showPurchaseConfirmationModal(redirect)
      this.setState({ redirect: redirect }, () => {
        this.purchaseConfirmRef.current.showModal()
      })
      return [false, '']
    }

    return [true, '']
  }

  _save = async (redirect, is_draft = false, save_draft = false, save_duplicate = false) => {
    let limit_exceeded_customers = []

    const [isValid, msg] = this.docValidations(is_draft, redirect, save_draft)
    if (!isValid) {
      if (msg != '') {
        message.error(msg)
      }
      return
    }

    let items = [...this.state.dataSource]
    items = items.map(item => {
      if (this.state.document_type == 'purchase' && !is_draft) {
        item['purchase_update_columns'] = {}
        if (
          this.props.document_settings?.purchase_update_columns
            .split(',')
            .includes('price_with_tax')
        ) {
          item.purchase_update_columns['price_with_tax'] = item['selling_price']
        }
        if (this.props.document_settings?.purchase_update_columns.split(',').includes('discount')) {
          item.purchase_update_columns['discount'] = item['product_discount']
        }
        if (
          this.props.document_settings?.purchase_update_columns
            .split(',')
            .includes('purchase_price')
        ) {
          item.purchase_update_columns['purchase_price'] =
            item['cess_on_qty_value'] > 0
              ? item.price_with_tax - item.cess_on_qty
              : item.price_with_tax

          item.purchase_update_columns['purchase_unit_price'] = item.unit_price
        }
        if (
          this.props.document_settings?.purchase_update_columns
            .split(',')
            .includes('custom_columns')
        ) {
          item.purchase_update_columns['custom_columns'] = {}
          this.props.custom_columns
            .filter(obj => obj.is_active)
            .map(product_custom_column => {
              item.purchase_update_columns.custom_columns[product_custom_column.name] =
                item[product_custom_column.name]
            })
        }
      }
      item.net_amount = Object.keys(item).includes('net_amount')
        ? parseFloat(item.net_amount)
        : parseFloat(getItemNetAmount(item))

      return {
        ...item,
        tax_amount: this.state.with_tax == 1 ? (item.net_amount * item.tax) / 100 : 0,
        total_amount:
          this.state.with_tax == 1
            ? parseFloat(getRowTotalV2(item))
            : parseFloat(getItemNetAmount(item)),
        price_with_tax: this.state.with_tax == 1 ? item.price_with_tax : item.unit_price,
        cess_amount: this.state.with_tax == 1 ? getCessAmountV2(item) : 0,
      }
    })
    // return

    if (!this.validateDeductions(items) && !is_draft) {
      return
    }

    /* if product has serial numbers and doing purchase */
    let serialNumberItems = []
    for (let i = 0; i < items.length; i++) {
      if (items[i].has_batches == 2 && this.allowBatches(this.state.document_type)) {
        for (let j = 0; j < items[i].serial_numbers.length; j++) {
          serialNumberItems.push({
            ...items[i],
            discount_amount: items[i].discount_amount / items[i].qty,
            net_amount: items[i].net_amount / items[i].qty,
            total_amount: items[i].total_amount / items[i].qty,
            tax_amount: items[i].tax_amount / items[i].qty,
            batch_id: items[i].serial_numbers[j].batch_id,
            qty: 1,
            batch_no: items[i].serial_numbers[j].batch_no,
          })
        }
        items.splice(i, 1)
        i--
      }
    }
    items = [...items, ...serialNumberItems]

    if (serialNumberItems.length > 0) {
      //sort using keys to sustain the order of items
      items.sort((a, b) => a.key - b.key)
    }

    if (
      !this.state.credit_limit_warning_shown &&
      // this.state.party_type == 'customer' &&
      (this.state.document_type == 'invoice' || this.state.document_type == 'purchase') &&
      !save_draft
    ) {
      this.state.customerData.map(customer => {
        let customer_condition =
          customer.credit_limit != 0 &&
          customer.balance +
            this.state.prev_total_amount +
            this.state.amountReceived +
            customer.credit_limit -
            this.getTotalAmount() <
            0
        let vendor_condition =
          customer.credit_limit != 0 &&
          customer.balance -
            this.state.prev_total_amount -
            customer.credit_limit +
            this.getTotalAmount() >
            0

        if (this.state.party_type == 'customer' ? customer_condition : vendor_condition) {
          limit_exceeded_customers.push(<span className="font-weight-bold"> {customer.name} </span>)
          limit_exceeded_customers.push(', ')
        }
      })
      if (limit_exceeded_customers.length > 0) {
        limit_exceeded_customers.pop()
      }
    }

    if (limit_exceeded_customers.length > 0 && !is_draft) {
      Modal.confirm({
        title: this.state.party_type == 'customer' ? 'Credit' : 'Debit' + ' Limit Exceeded',
        content: (
          <>
            <p>Are you sure you want to proceed?</p>
            <p>
              Limit exceeded for:
              {limit_exceeded_customers.map((customer, index) => {
                return customer
              })}
            </p>
          </>
        ),
        icon: <ExclamationCircleOutlined />,
        okText: 'Confirm',
        onOk: () => {
          this.setState(
            {
              credit_limit_warning_shown: true,
            },
            () => this.save(redirect),
          )
        },
        onCancel: () => {},
      })
      return
    }

    if (!save_draft) {
      // don't load save buttons if its auto drafting
      this.setState({ loadingSave: true })
    }
    let with_tax = this.state.with_tax
    if (
      this.state.export_invoice &&
      (this.state.export_type == 'Export with IGST' ||
        this.state.export_type == 'SEZ with IGST Payment' ||
        this.state.export_type == 'Deemed Export')
    ) {
      with_tax = 1
    }
    if (
      this.state.export_invoice &&
      (this.state.export_type == 'Export under bond/LUT' ||
        this.state.export_type == 'SEZ without IGST Payment')
    ) {
      with_tax = 0
    }
    if (save_draft || is_draft) {
      if (
        this.state.is_tds &&
        (this.state.tds_selected_section == undefined ||
          this.state.tds_amount == undefined ||
          this.state.tds_amount == '')
      ) {
        this.setState({ is_tds: 0 })
      }
      if (
        this.state.is_tcs &&
        (this.state.tcs_selected_section == undefined ||
          this.state.tcs_amount == undefined ||
          this.state.tcs_amount == '')
      ) {
        this.setState({ is_tcs: 0 })
      }
    }
    const req = {
      customer_id: this.state.customerData.map(item => {
        return item.id
      }),

      notes: this.state.notes,

      bank_id:
        this.state.document_type == 'purchase_return' ||
        this.state.document_type == 'purchase_order'
          ? 0
          : this.state.selected_bank.id,
      due_date: this.state.due_date,
      document_date: this.state.document_date,
      supplier_invoice_date:
        this.state.document_type == 'purchase'
          ? this.state.supplier_invoice_date
          : dayjs().format(this.dateFormat),
      supplier_invoice_serial_number:
        this.state.document_type == 'purchase' ? this.state.supplier_invoice_serial_number : '',
      reference:
        this.state.from_document_type == 'online_order'
          ? this.state.order_serial_number
          : this.state.reference,
      order_serial_number:
        this.state.from_document_type == 'online_order' ? this.state.order_serial_number : '',
      rzp_order_id: this.state.from_document_type == 'online_order' ? this.state.rzp_order_id : '',
      rzp_payment_id:
        this.state.from_document_type == 'online_order' ? this.state.rzp_payment_id : '',
      has_extra_charges: this.state.has_extra_charges,
      show_description: this.state.description,
      serial_number:
        save_duplicate && this.state.new_serial_number != ''
          ? this.state.new_serial_number
          : String(this.state.prefix) + String(this.state.doc_number),
      terms: this.state.terms,
      exclusive_notes: this.state.exclusive_notes,
      tax_amount:
        with_tax == 1
          ? parseFloat(this.getTotalTax(items).toFixed(2)) + this.extraChargesAmount('tax_amount')
          : this.extraChargesAmount('tax_amount'),
      net_amount: parseFloat(getTotalNetAmountV2(items)),
      total_discount:
        Number(this.state.extraDiscount) +
        getTotalDiscountV2(items, this.state.document_discount_type),
      extra_discount: Number(this.state.extraDiscount),
      total_amount: parseFloat(
        (
          this.getTotal(items) +
          Number(this.state.tcs_amount) +
          this.extraChargesAmount('total_amount') -
          Number(this.state.extraDiscount) -
          Number(this.state.roundoff_value) -
          Number(this.state.rcm_value)
        ).toFixed(2),
      ),
      items: items,
      signature: this.state.selectedSignature.image,
      multiple_gst: this.state.gst_details.id == undefined ? 0 : this.state.gst_details.id,
      id:
        is_draft && this.state.auto_draft.id != 0
          ? this.state.auto_draft.id
          : // : this.state.edit && save_draft
            // ? 0
            this.state.id,
      attachments: this.state.attachments,
      payment_method: this.state.paymentMethod,
      amount_received: parseFloat(this.state.amountReceived),
      paymentNotes: this.state.paymentNotes,
      payment_notes: this.state.paymentNotes,
      customer_shipping_addr_id: this.state.selected_customer_shipping_address
        ? 'addr_id' in this.state.selected_customer_shipping_address
          ? this.state.selected_customer_shipping_address.addr_id
          : -1
        : -1,
      company_shipping_addr_id: this.state.selected_company_shipping_address
        ? 'addr_id' in this.state.selected_company_shipping_address
          ? this.state.selected_company_shipping_address.addr_id
          : -1
        : -1,
      custom_headers: this.state.custom_headers,
      roundoff: this.state.roundoff,
      roundoff_value: this.state.roundoff_value,
      document_type:
        (is_draft && this.state.convert && this.state.from_document_type == 'draft') ||
        (is_draft && this.state.auto_draft.id != 0)
          ? //  || (save_draft && this.state.edit)
            'draft'
          : this.state.document_type,
      doc_number:
        save_duplicate && this.state.new_doc_number != 0
          ? this.state.new_doc_number
          : this.state.doc_number,
      rcm: this.state.rcm,
      with_tax: with_tax,
      hide_totals: this.state.hide_totals,
      document_title: this.state.document_title,
      document_custom_additional_charges: this.state.document_custom_additional_charges,
      is_export: this.state.export_invoice ? 1 : 0,
      is_multi_currency: this.state.is_multi_currency ? 1 : 0,
      export_invoice_details: {
        shipping_bill_date: this.state.shipping_bill_date,
        shipping_bill_number: this.state.shipping_bill_number,
        shipping_port_code: this.state.shipping_port_code,
        export_type: this.state.is_multi_currency ? 'Multi Currency' : this.state.export_type,
        conversion_factor: parseFloat(this.state.conversion_factor),
        country_id: this.state.country,
        currency_id: this.props?.countries?.filter(
          item => item.currency_code == this.state.currency,
        )[0].country_id,
      },
      is_tds: this.state.is_tds,

      tds_details: {
        tds_amount: this.state.tds_amount,
        ...this.props.tds_sections.filter(
          item => item.tds_id == this.state.tds_selected_section,
        )[0],
        apply_on: this.state.tds_applicable_on,
      },
      is_tcs: this.state.is_tcs,
      tcs_details: {
        tcs_amount: this.state.tcs_amount,
        ...this.props.tcs_sections.filter(
          item => item.tds_id == this.state.tcs_selected_section,
        )[0],
        apply_on: this.state.tcs_applicable_on,
      },
      cess_amount: this.state.with_tax == 1 ? parseFloat(getTotalCessAmountV2(items)) : 0,
      cess_on_qty_value: this.state.with_tax == 1 ? parseFloat(getCessOnQtyValue(items)) : 0,
      convert: {
        convert_from:
          window.location.pathname.includes('create') && !is_draft && this.state.auto_draft.id != 0
            ? 'draft'
            : this.state.from_document_type,
        doc_count:
          window.location.pathname.includes('create') && !is_draft && this.state.auto_draft.id != 0
            ? this.state.auto_draft.doc_count
            : this.state.doc_count,
      },
      is_subscription: this.state.is_subscription,
      subscription: {
        start_time: this.state.subscription_start_date,
        end_time: this.state.subscription_end_date,
        // serial_number: this.state.selected_serial_number,
        sub_serial_number: this.state.sub_serial_number,
        document_type: this.state.document_type,
        subscription_document_type: this.state.subscription_document_type,
        // doc_count: this.state.doc_count,
        repeat: Math.abs(parseInt(this.state.repeat_every)),
        repeat_type: this.state.repeatType.toLowerCase(),
        send_email: this.state.send_email,
        send_sms: this.state.send_sms,
      },
      payment_status: this.state.payment_status,
      coupon_details: this.state.coupon_details,
      source: 0, // source is used to identify the invoice is created from web or mobile
      is_draft,
      skip_warning: this.state.warning.skip_warning,
      discount_type: this.state.document_discount_type,
      immovable_tax_type: this.state.immovable_tax_type,
      warehouse_id: this.state.warehouse_id,
      identification: {
        save_draft,
        save_duplicate,
        is_draft,
      },
    }

    var data = {}
    if (save_draft) {
      // if its auto draft then display message
      this.setState({ showDraftMessage: true })
    }
    if (
      this.state.edit ||
      (this.state.convert && this.state.from_document_type == 'draft' && is_draft) ||
      (is_draft && this.state.auto_draft.id != 0)
    ) {
      data = await getAPI('v2/doc', 'edit', req)
      clearTimeout(this.draftTimeout)
      this.draftTimeout = null

      if (!data) {
        this.setState({ loadingSave: false, loadingPrint: false })
        return
      }

      if (!data.success) {
        message.error(data.message)
        return
      }
      if (data.warning && this.state.edit) {
        this.setState({
          warning: {
            ...this.state.warning,
            message: data.message,
            show_warning: true,
          },
          new_serial_number: data.new_serial_number,
          new_doc_number: data.new_doc_number,
          loadingSave: false,
          loadingPrint: false,
        })
        return
      }

      if (window.location.pathname.includes('create')) {
        if (!save_draft) {
          if (is_draft) {
            // user clicked save as draft button
            message.success('Saved as draft successfully!')
          }
          this.setState(
            {
              isCreated: true,
            },
            async () => {
              await this.props.dispatch(
                update_invoice_data({
                  key: this.state.activeKey,
                  data: { ...this.state, showDraftMessage: false },
                }),
              )
            },
          )
        }
      } else {
        message.success(data.message)
      }
    } else if (this.state.convertToInvoiceFromOnlineOrders) {
      const order_req = {
        ...req,
        type: 'invoice',
        reseller_id: this.state.reseller_id,
        order_serial_number: this.state.serial_number,
      }
      data = await getAPI('store', 'create_invoice', order_req)
    } else {
      data = await getAPI('v2/doc', 'create', req)
      clearTimeout(this.draftTimeout)
      this.draftTimeout = null

      if (!data) {
        this.setState({ loadingSave: false, loadingPrint: false })

        return
      }

      if (!data.success) {
        message.error(data.message)
        this.setState({ loadingSave: false, loadingPrint: false })
        return
      }

      if (data.warning) {
        this.setState({
          warning: {
            ...this.state.warning,
            message: data.message,
            show_warning: true,
          },
          new_serial_number: data.new_serial_number,
          new_doc_number: data.new_doc_number,
        })
        return
      }

      if (!save_draft) {
        if (is_draft) {
          // user clicked save as draft button
          message.success('Saved as draft successfully!')
        } else {
          // user clicked save button
          message.success(data.message)
        }
        this.setState(
          {
            isCreated: true,
          },
          async () => {
            await this.props.dispatch(
              update_invoice_data({
                key: this.state.activeKey,
                data: { ...this.state, showDraftMessage: false },
              }),
            )
          },
        )
      } else {
        this.setState(
          {
            auto_draft: {
              id: data.id,
              doc_count: data.doc_count,
            },
          },
          async () => {
            await this.props.dispatch(
              update_invoice_data({
                key: this.state.activeKey,
                data: {
                  ...this.state,
                  showDraftMessage: false,
                },
              }),
            )
          },
        )
      }
    }

    if (save_draft) {
      // hide saving as draft message after timeout
      setTimeout(() => {
        this.setState({ showDraftMessage: false })
      }, 1000)
    }

    this.setState({ loadingPrint: false, loadingSave: false })

    if (this.state.edit && data) {
      if (data.exceeded) {
        notification.open({
          message: <span className="text-danger">Error</span>,
          description: data.message,
          icon: <i className="fa fa-exclamation-circle text-danger mr-2" />,
          style: {
            borderRadius: '10px',
            backgroundColor: '#ffe6e6',
          },
          duration: 10,
        })
        return
      }
    }

    if (data.success && !save_draft) {
      if (redirect == '1') {
        if (this.state.is_ewaybill && !this.state.is_create_einvoice) {
          message.success('Invoice created successfully! Fill E-Waybill details')
          this.handleEwayBill(data.new_hash_id, this.state.document_type)
        } else if (this.state.is_create_einvoice && !this.state.is_ewaybill) {
          message.success('Invoice created successfully! Creating E-Invoice Now')
          await this.handleEInvoice(data.new_hash_id, this.state.document_type)
          this.changeRoute()
        } else if (this.state.is_ewaybill && this.state.is_create_einvoice) {
          message.success('Invoice created successfully! Creating E-Invoice Now')
          let create_einvoice = await this.handleEInvoice(
            data.new_hash_id,
            this.state.document_type,
          )
          if (!create_einvoice) {
            message.error({
              content:
                'Error while creating E-Invoice! Please check the details and try again. Redirecting to E-Waybill Creation.',
              duration: 5,
            })
          }
          setTimeout(() => {
            this.handleEwayBill(data.new_hash_id, this.state.document_type)
          }, 5000)
        } else {
          if (!this.props.invoices_created) {
            this.props.dispatch({
              type: 'welcome/COMPANY_DETAILS_ADDED',
            })
            this.changeRoute()
          } else {
            if (
              !save_draft &&
              (!window.location.pathname.includes('create') || this.state.items.length == 1)
            ) {
              this.changeRoute()
            }
          }
        }
      } else {
        history.push('/save_print/' + this.state.document_type + '/' + data.new_hash_id)
      }
    }
  }
  changeRoute = () => {
    let route = '/' + this.state.list_name
    if (this.state.document_type != 'online_order') {
      route = '/list' + route
    }
    history.push(route)
  }

  onWarehouseChange = warehouse_id => {
    this.setState({ warehouse_id }, () => {
      this.onPriceListChange(this.state.selectedPriceList, 'warehouse_change')
    })
  }

  beforeUpload = file => {
    const isLt3M = file.size / 1024 / 1024 < 3
    if (!isLt3M) {
      message.error('File must smaller than 3MB!')
    }
    return isLt3M
  }

  customRequest = async ({ onSuccess, onError, file }) => {
    if (!file.url && !file.preview) {
      file.preview = await getBase64(file)
    }

    const form = new FormData()
    form.append('file', file)

    const data = await getMultipartAPI('utils', 'attach_files', form)
    if (data.success) {
      this.setState({ attachments: [...this.state.attachments.slice(-2), ...data.attachments] })
      message.success(data.message)
      onSuccess(null, file)
    } else {
      message.error(data.message)
      onError()
    }
  }

  handleChange = file => {
    if (file.status == 'removed') {
      const newFiles = this.state.attachments.filter(file1 => file1.uid !== file.uid)
      this.setState({ attachments: newFiles })
    }
  }

  onDueDateChange = (date, string) => {
    this.setState({ due_date: string, openDatePicker: false })
  }

  onDocumentDateChange = (date, string) => {
    this.setState({
      document_date: string,
      supplier_invoice_date:
        this.state.document_title == 'Purchase' &&
        dayjs(this.state.document_date, this.dateFormat).isSame(dayjs(), 'day')
          ? string
          : this.state.supplier_invoice_date,
    })
  }

  onSupplierInvoiceDateChange = (date, string) => {
    this.setState({ supplier_invoice_date: string })
  }

  onShippingBillDateChange = (daste, string) => {
    this.setState({ shipping_bill_date: string })
  }

  onChange = e => {
    const { name, value } = e.target

    if (name == 'notes' || name == 'terms') {
      const is_note_term_entered = name === 'terms' ? 'isTermsEntered' : 'isNotesEntered'
      this.setState(
        {
          [name]: value,
          [is_note_term_entered]: value.trim() !== '',
          updatedTerms: value,
        },
        () => {
          if (!this.state[is_note_term_entered]) {
            this.setState(name == 'notes' ? { showNotes: false } : { showTerms: false })
          }
        },
      )
    } else {
      this.setState({ [name]: value })
    }
  }

  tagDelete = (e, itemTag) => {
    e.preventDefault()
    const newData = this.state.customerData.filter(data => data.id != itemTag.id)
    this.setState({
      customerData: [...newData],
      customer_details:
        newData?.length > 0
          ? { ...newData[newData?.length - 1] }
          : { billing_address: [], shipping_address: [] },
      selected_customer_shipping_address: {},
    })
  }

  handleEInvoice = async (new_hash_id, document_type = 'invoice') => {
    const hide = message.loading({
      content: 'Generating EInvoice...',
      duration: 0,
    })
    var data = await getAPIData('user', 'einvoice_verify_nic_creds')
    if (data.redirect) {
      let einvoice_create = await this.einvoice_nic_creds.createEInvoiceNew(
        new_hash_id,
        document_type,
      )
      hide()
      return einvoice_create
    } else {
      this.einvoice_nic_creds.showDrawer(true, new_hash_id, document_type)
      hide()
    }
    return true
  }

  handleEwayBill = async (new_hash_id, document_type = 'invoice') => {
    var data = await getAPIData('user', 'verify_nic_creds')
    if (data.redirect) {
      this.setState({ showEwayBill: true }, () => {
        this.ewaybill.showModal(document_type, new_hash_id)
        message.success('Enter E-Waybill details')
      })
    } else {
      this.nic_creds.showDrawer(true, new_hash_id, this.state.document_type)
    }
  }
  onExportChange = () => {
    const dataSource = this.state.dataSource.map(item => {
      item.export_unit_price = item.unit_price / parseFloat(this.state.conversion_factor)
      item.export_price_with_tax = item.price_with_tax / parseFloat(this.state.conversion_factor)
      return item
    })
    if (this.state.document_type == 'purchase' || this.state.document_type == 'purchase_order') {
      this.setState({ export_type: 'Import' })
    }
    this.setState({ dataSource, immovable_tax_type: 0 })
  }

  getTdsAmount(products = []) {
    let items = products.length > 0 ? products : this.state.dataSource
    let tds_amount = this.state.tds_amount
    if (
      this.state.is_tds &&
      this.state.tds_selected_section != undefined &&
      this.state.payment_status == 'pending'
    ) {
      // console.log(this.props.tds_sections, this.state.tds_selected_section)
      let tds_selected_section_data = this.props.tds_sections.filter(
        item => item.tds_id == this.state.tds_selected_section,
      )[0]
      tds_amount =
        this.state.tds_applicable_on == 'net_amount'
          ? (
              (getTotalNetAmountV2(items) + this.extraChargesAmount('net_amount')) *
              (tds_selected_section_data.tax / 100)
            ).toFixed(2)
          : this.getTotalAmount() * (tds_selected_section_data.tax / 100)
    }

    return tds_amount
  }

  dataUpdate = (data, just_data_update = false) => {
    if (just_data_update) {
      this.setState({ dataSource: data })
      return
    }
    var rcm_value = this.state.rcm
      ? parseFloat(this.getTotalTax(data).toFixed(2)) + this.extraChargesAmount('tax_amount')
      : 0
    var extraDiscount = ((Number(this.state.extraDiscountPer) / 100) * this.getTotal(data)).toFixed(
      2,
    )

    /*tcs amount*/
    let tcs_amount = 0
    if (
      this.state.is_tcs &&
      this.state.tcs_selected_section != undefined &&
      this.state.payment_status == 'pending'
    ) {
      tcs_amount = this.onChangeTcsApplicable(this.state.tcs_applicable_on, data, false)
    }

    /* round off  */
    var decimal = this.state.with_tax
      ? this.getTotal(data) +
        Number(tcs_amount) +
        this.extraChargesAmount('total_amount') -
        Number(extraDiscount) -
        Number(rcm_value)
      : getTotalNetAmountV2(data) +
        Number(tcs_amount) +
        this.extraChargesAmount('total_amount') -
        Number(extraDiscount) -
        Number(rcm_value)

    if (this.state.roundoff) {
      var remainder = decimal - parseInt(decimal)
      if (remainder >= 0.5) {
        remainder = remainder - 1
      }
    } else {
      var remainder = 0
    }
    decimal = decimal - remainder

    this.setState(
      {
        dataSource: data,
        roundoff_value: remainder,
        amountReceived: this.state.fullyPaid ? decimal : this.state.amountReceived,
        tds_amount: this.getTdsAmount(data),
        extraDiscount: extraDiscount,
        rcm_value: rcm_value,
        tcs_amount: tcs_amount,
        lastDataSourceUpdated: dayjs(),
      },
      () => {
        this.onCouponsApply(true)
        this.onChangeFullyPaid(this.state.fullyPaid)
        if (this.table) {
          this.table.updateAdditionalCharges()
        }
        this.savedataSourceUpdatedTime()
      },
    )
  }

  savedataSourceUpdatedTime = async () => {
    if (!window.location.pathname.includes('create') || this.state.is_subscription) return
    const currentTime = dayjs()
    if (
      currentTime.diff(this.state.lastDataSourceUpdated, 'seconds') < 10 ||
      this.state.draftReqPending
    ) {
      clearTimeout(this.draftTimeout)
      this.draftTimeout = setTimeout(this.savedataSourceUpdatedTime, 10000)
    } else {
      if (!this.state.isCreated) {
        // this.setState({showDraftMessage:true})
        this.setState({ draftReqPending: true })
        await this._save('1', true, true)
        this.setState({ draftReqPending: false })

        // setTimeout(()=>{
        //   this.setState({showDraftMessage:false})
        // },2000)
      }
    }
  }
  extraChargesAmount = name => {
    let result = this.state.document_custom_additional_charges.map(a => a[name] * a.type)
    if (result.length) {
      return result.reduce((previous_value, current_value) => previous_value + current_value)
    } else return 0
  }
  onDiscountTypeChange = e => {
    let data_source = this.state.dataSource
    let selected_discount_value = 'discount_value'
    if (e == 'unit_price') {
      selected_discount_value = 'discount_unit_price_value'
    } else if (e == 'price_with_tax') {
      selected_discount_value = 'discount_price_with_tax_value'
    } else if (e == 'net_amount') {
      selected_discount_value = 'discount_net_value'
    }
    data_source.map(item => {
      if (item.selected_discount_type != 'discount') {
        item.selected_discount_type = selected_discount_value
      }
    })
    this.setState({
      companyDetails: { ...this.state.companyDetails, discount_type: e },
      dataSource: data_source,
      document_discount_type: e,
    })
  }

  updateExtraCharges = object => {
    // console.log(object)
    this.setState(
      {
        document_custom_additional_charges: object.document_custom_additional_charges,
        has_extra_charges: object.has_extra_charges,
      },
      () => {
        var rcm_value = this.state.rcm
          ? parseFloat(this.getTotalTax().toFixed(2)) + this.extraChargesAmount('tax_amount')
          : 0
        this.setState({ rcm_value: rcm_value }, () => {
          if (
            this.state.is_tcs &&
            this.state.tcs_selected_section != undefined &&
            this.state.payment_status == 'pending'
          ) {
            this.onChangeTcsApplicable(this.state.tcs_applicable_on)
          }
          if (
            this.state.is_tds &&
            this.state.tds_selected_section != undefined &&
            this.state.payment_status == 'pending'
          ) {
            this.onChangeTdsTcsSection(this.state.tds_selected_section, 1)
          }

          this.onRoundOffChange(this.state.roundoff)
        })
      },
    )
  }

  getTotal = (products = []) => {
    var sum = 0
    let items = products.length > 0 ? products : [...this.state.dataSource]
    items.map(item => {
      sum = sum + getRowTotalV2(item)
    })

    return Number(sum.toFixed(2))
  }

  getTotalTax = (products = []) => {
    var sum = 0
    let items = products.length > 0 ? products : [...this.state.dataSource]
    items.map(item => {
      sum = sum + getTaxAmountV2(item)
    })
    return sum
  }

  onPriceListChange = async (value, origin = 'price_change', updated_product_id = -1) => {
    if (value == undefined) {
      value = 0
    }
    this.setState({ selectedPriceList: value == 0 ? null : value }, () => {
      this.table?.refreshAutocompleteProducts()
    })
    // change the prices of already added items
    var req = {
      items: this.state.dataSource,
      origin: origin,
      selected_price_list: value,
      warehouse_id: this.state.warehouse_id,
    }

    let originalDataSource = [...this.state.dataSource]

    if (updated_product_id != -1) {
      req.items = req.items.filter(
        item => item.product_id == updated_product_id && item.variant_id == 0,
      )
    }

    var data = await getAPI('product', 'search', req)
    let column_names = []
    if (this.state.dataSource.length > 0) {
      column_names = this.state.dataSource[0]?.custom_column_names?.split('::::')
    }

    const results = data.products.map((row, index) => {
      let custom_column_values = []
      column_names?.map(col => {
        custom_column_values.push(this.state.dataSource[index][col])
        row[col] = this.state.dataSource[index][col]
      })
      let prev_product_data = this.state.dataSource[index]
      // if (prev_product_data.discount > 0) {
      //   row.discount = prev_product_data.discount
      // }
      custom_column_values = custom_column_values.join('::::')
      return {
        key: index + 1,
        ...row,
        editorState: EditorState.createWithContent(stateFromHTML(prev_product_data.description)),
        description: prev_product_data.description,
        has_batches: prev_product_data.has_batches,
        batches: prev_product_data.batches,
        serial_numbers: prev_product_data.serial_numbers,
        batch_id: prev_product_data.batch_id,
        units: prev_product_data.units,
        unit_id: prev_product_data.unit_id || row.unit_id,
        unit: prev_product_data.unit,
        custom_column_values: custom_column_values,
        custom_column_names: this.state.dataSource[index].custom_column_names,
        qty: this.state.dataSource[index].qty,
        conversion_rate: this.state.dataSource[index].conversion_rate,
        qtyinstock: row.qty,
        selling_price: this.state.dataSource[index].selling_price,
        product_discount: this.state.dataSource[index].product_discount,
        discount: row.discount === 'NA' ? 0 : Number(row.discount),
        discount_value:
          row.discount === 'NA'
            ? 0
            : Number((row.discount * row.price_with_tax * this.state.dataSource[index].qty) / 100),
        id: String(row.product_id) + '_' + String(row.variant_id == undefined ? 0 : row.variant_id),
        actual_price_with_tax: row.price_with_tax,
        actual_unit_price: row.unit_price,
        price:
          this.state.document_type.includes('purchase') && row.purchase_price != 0 && value == 0
            ? row.purchase_price / (1 + row.tax / 100)
            : row.price,
        price_with_tax:
          this.state.document_type.includes('purchase') && row.purchase_price != 0 && value == 0
            ? row.purchase_price
            : row.price_with_tax,
        discount_price_with_tax_value: (
          (this.state.document_type.includes('purchase') && row.purchase_price != 0 && value == 0
            ? row.purchase_price
            : row.price_with_tax * row.discount) / 100
        ).toFixed(2),
        discount_unit_price_value: (
          ((this.state.document_type.includes('purchase') && row.purchase_price != 0 && value == 0
            ? row.purchase_price / (1 + row.tax / 100)
            : row.price) *
            row.discount) /
          100
        ).toFixed(2),
        discount_net_value: (
          ((this.state.document_type.includes('purchase') && row.purchase_price != 0 && value == 0
            ? (row.purchase_price * row.qty) / (1 + row.tax / 100)
            : row.price * row.qty) *
            row.discount) /
          100
        ).toFixed(2),
        selected_discount_type: row.selected_discount_type || 'discount',
        value:
          row.product_name +
          (row.variant_name == '' || row.variant_name == null ? '' : ' ' + row.variant_name),
        master_data: {
          price_with_tax: row.actual_price_with_tax,
          unit_price: row.unit_price,
          discount: row.discount,
          cess: row.cess,
          tax: row.tax,
          purchase_price: row.purchase_price,
          purchase_unit_price: row.purchase_unit_price,
        },
      }
    })

    let updatedDataSource
    if (updated_product_id != -1) {
      updatedDataSource = originalDataSource.map(item => {
        const updatedItem = results.find(result => result.product_id === item.product_id)
        return updatedItem ? updatedItem : item
      })
    } else {
      updatedDataSource = results
    }

    this.setState({ dataSource: updatedDataSource }, () => {
      let newData = this.state.dataSource.map((item, index) => {
        return this.table.onCustomChange(
          item.qty,
          item.key,
          'Quantity',
          'qty',
          this.state.dataSource,
        )[index]
      })
      this.setState({ dataSource: newData })
    })
  }

  priceListChangeHandler = async (value, origin = 'price_change') => {
    if (this.state.priceListWarnShown || !this.state.dataSource.length) {
      this.onPriceListChange(value, origin)
      return
    }
    Modal.confirm({
      title: 'Are you sure you want change PriceList ?',
      content: (
        <>
          <p className="font-size-18">
            <span>
              Item prices, discounts will be updated on changing price list
              <br />
            </span>
          </p>
        </>
      ),
      width: '30%',
      icon: <i className="fad fa-info-circle fa-beat mr-3 mt-2 text-danger"></i>,
      okText: 'Confirm',
      onOk: async () => {
        this.setState(
          {
            priceListWarnShown: true,
          },
          () => {
            this.onPriceListChange(value, origin)
          },
        )
      },
      onCancel: () => {},
    })
  }

  onFocus = () => {
    // console.log('focus')
    // message.warning("This will change the prices of all the items added, based on the selected Price List")
  }

  onCustomHeaderChange = async (e, header_id) => {
    // console.log(e.target.value, header_id)
    var selected_header_index = this.state.custom_headers.findIndex(
      x => x['header_id'] == header_id,
    )
    var new_custom_headers = [...this.state.custom_headers]
    if (selected_header_index != -1) {
      new_custom_headers[selected_header_index]['value'] = e
      if (e != '' && new_custom_headers[selected_header_index].is_error) {
        new_custom_headers[selected_header_index].is_error = false
      }
    }
    this.setState({ custom_headers: [...new_custom_headers] }, () => {
      // console.log(this.state.custom_headers)
    })
  }

  onDocumentTitleChange = value => {
    if (value == 'Bill of Supply') {
      if (this.state.export_invoice) {
        this.setState({ document_title: value })
      } else {
        this.setState({ document_title: value }, () => {
          this.onChangeWithTax('withoutTax')
        })
      }
    } else if (value == 'Invoice') {
      if (this.state.companyDetails.gstin.length == 15) {
        this.setState({ document_title: value }, () => {
          this.onChangeWithTax('withTax')
        })
      } else {
        this.setState({ document_title: value }, () => {
          this.onChangeWithTax('withoutTax')
        })
      }
    } else {
      this.setState({ document_title: value })
    }
  }

  onChangeWithTax = value => {
    let with_tax = value == 'withTax' ? true : false
    if (this.state.export_invoice == 1) {
      if (
        this.state.export_type?.includes('with IGST') ||
        this.state.export_type?.includes('Deemed')
      ) {
        with_tax = true
      } else {
        with_tax = false
      }
    }
    if (with_tax == false) {
      this.onRCMChange(false)
    }
    this.setState({ with_tax: with_tax }, () => {
      this.onWithTaxChange()
    })
  }

  generateTaxLabel() {
    if (this.props?.user?.selectedCompany?.country_code == 'AE') {
      return 'VAT'
    } else {
      if (this.state.immovable_tax_type == 1) {
        return `${this.state.companyDetails.tax_labels.SGST} + ${this.state.companyDetails.tax_labels.CGST}`
      }
      const billing_address = this.state.companyDetails?.billing_address?.filter(
        x => x.is_delete == 0,
      )
      const party_state =
        this.state.party_type == 'customer'
          ? this.state.customer_details?.billing_address[0]?.state
          : this.state.vendor_details?.billing?.state
      if (
        (this.state.export_invoice == 1 &&
          (this.state.export_type?.includes('with IGST') ||
            this.state.export_type?.includes('Deemed'))) ||
        this.state.companyDetails.sez == 1
      ) {
        return this.state.companyDetails.tax_labels.IGST
      } else {
        if (
          this.state.customerData.length != 1 ||
          billing_address.length == 0 ||
          party_state == undefined
        ) {
          return 'Total Tax'
        } else if (party_state != billing_address[0].state) {
          return this.state.companyDetails.tax_labels.IGST
        } else {
          return `${this.state.companyDetails.tax_labels.SGST} + ${this.state.companyDetails.tax_labels.CGST}`
        }
      }
    }
  }

  onCouponsVisible = async () => {
    if (this.state.customerData.length == 0) {
      message.error('Please select a customer first')
      return
    }
    if (this.state.dataSource.length == 0) {
      message.error('Please add items first')
      return
    }
    let total_amount = this.getTotalAmount()
    this.setState({ coupons_visible: true, total_amount: total_amount + this.state.extraDiscount })
  }

  onCouponsApply = async (is_edit = false) => {
    if (this.state.coupon_details.coupon_id > 0) {
      const req = {
        coupon: this.state.coupon_details,
        amount: this.getTotalAmount() - this.state.extraDiscount,
        is_edit: is_edit,
        customers: this.state.customerData.map(item => {
          return {
            ...item,
            customer_id: item.id,
            type: this.state.party_type,
          }
        }),
      }
      const verify = await getAPI('v2/company', 'verify_applied_coupon', req)
      if (verify.success) {
        if (verify.valid) {
          this.onApplyCoupon(this.state.coupon_details, verify.discount, verify.message)
        } else {
          message.error(verify.message)
          this.setState({
            coupon_details: {
              coupon_id: -1,
              coupon_code: '',
              discount: 0,
              message: '',
              is_edit: is_edit,
            },
            extraDiscount: 0,
            extraDiscountPer: 0,
          })
        }
      }
    }
  }
  onExtraDiscountChange = e => {
    let total_amount = this.getTotal() - this.state.rcm_value
    let discountPercent =
      this.state.exta_discount_type == 'discount'
        ? e
        : ((Number(e) * 100) / (total_amount ? total_amount : 1)).toFixed(2)
    let discountAmount =
      this.state.exta_discount_type == 'discount'
        ? ((Number(e) / 100) * (total_amount ? total_amount : 0)).toFixed(2)
        : e
    if (discountAmount > total_amount) {
      message.error('Discount cannot be greater than total amount')
      return
    }
    this.setState(
      {
        extraDiscountPer: discountPercent,
        extraDiscount: discountAmount,
      },
      () => this.onChangeTcsApplicable(this.state.tcs_applicable_on),
    )
  }

  getTotalAmount = (items = []) => {
    let products = items.length > 0 ? items : [...this.state.dataSource]
    var total = 0
    if (this.state.with_tax == 1) {
      total =
        Number(this.getTotal(products)) +
        this.extraChargesAmount('total_amount') -
        Number(this.state.extraDiscount) -
        Number(this.state.roundoff_value) -
        Number(this.state.rcm_value) +
        Number(this.state.tcs_amount)
    }
    if (this.state.with_tax == 0) {
      total =
        Number(getTotalNetAmountV2(products)) +
        this.extraChargesAmount('total_amount') -
        Number(this.state.extraDiscount) -
        Number(this.state.roundoff_value) -
        Number(this.state.rcm_value) +
        Number(this.state.tcs_amount)
    }
    return total
  }

  onApplyCoupon = async (coupon, discount, message) => {
    this.setState({
      coupon_details: {
        ...coupon,
        coupon_id: coupon.coupon_id,
        coupon_code: coupon.coupon_code,
        discount: discount,
        message: message,
      },
      extraDiscount: discount,
      coupons_visible: false,
    })
  }
  onChangeTdsTcsSection = (value, is_tds) => {
    let selected_section = value
    let section_details = []
    // console.log('is_tds', is_tds, value, this.props.tcs_sections)
    // let tcs_sections = this.props.tcs_sections
    // console.log('tcs_sections', tcs_sections)
    if (is_tds) {
      section_details = this.props.tds_sections.filter(item => item.tds_id == value)
    } else {
      section_details = this.props.tcs_sections.filter(item => item.tds_id == value)
    }

    section_details = section_details[0]

    let amount = 0
    if (section_details.apply_on == 'net_amount') {
      amount = parseFloat(
        (Number(
          getTotalNetAmountV2(this.state.dataSource)
            ? getTotalNetAmountV2(this.state.dataSource) + this.extraChargesAmount('net_amount')
            : 0,
        ) *
          section_details.tax) /
          100,
      ).toFixed(2)
    } else {
      amount = parseFloat(
        ((Number(
          this.getTotalAmount(this.state.dataSource)
            ? this.getTotalAmount(this.state.dataSource)
            : 0,
        ) -
          this.state.tcs_amount) *
          section_details.tax) /
          100,
      ).toFixed(2)
    }
    if (is_tds == 0) {
      this.setState(
        {
          tcs_selected_section: selected_section,
          tcs_amount: amount,
          tcs_applicable_on: section_details.apply_on,
        },
        () => {
          this.onRoundOffChange(this.state.roundoff)
          this.onChangeFullyPaid(this.state.fullyPaid)
        },
      )
    } else {
      this.setState(
        {
          tds_selected_section: selected_section,
          tds_amount: amount,
          tds_applicable_on: section_details.apply_on,
        },
        () => {
          this.onChangeFullyPaid(this.state.fullyPaid)
        },
      )
    }
  }
  renderCustomHeaderOptions = header_id => {
    let options = []
    this.state.custom_headers_suggestions?.[header_id]?.map((item, index) => {
      options.push({
        value: item,
        label: (
          <div
            style={{
              display: 'flex',
              justifyContent: 'space-between',
            }}
          >
            {item}
          </div>
        ),
      })
    })
    return options
  }
  onChangeTcsApplicable = (value, products = [], state_update = true) => {
    let amount = 0

    if (this.state.is_tcs && this.state.tcs_selected_section != undefined) {
      let items = products.length > 0 ? products : [...this.state.dataSource]
      if (this.state.tcs_selected_section != undefined) {
        let section_details = this.props.tcs_sections.filter(
          item => item.tds_id == this.state.tcs_selected_section,
        )[0]
        if (value == 'net_amount') {
          amount = parseFloat(
            (Number(getTotalNetAmountV2(items) ? getTotalNetAmountV2(items) : 0) *
              section_details.tax) /
              100,
          ).toFixed(2)
        } else {
          amount = parseFloat(
            ((Number(this.getTotalAmount(items) ? this.getTotalAmount(items) : 0) +
              Number(this.state.roundoff_value) -
              this.state.tcs_amount) *
              section_details.tax) /
              100,
          ).toFixed(2)
        }
      }
      if (state_update) {
        this.setState({ tcs_applicable_on: value, tcs_amount: amount }, () => {
          this.onRoundOffChange(this.state.roundoff)
          this.onChangeFullyPaid(this.state.fullyPaid)
        })
      }
    }
    return amount
  }
  get_prefix_doc_number = async prefix => {
    this.setState({ prefix: prefix, new_prefix: '' })
    if (!this.state.edit) {
      const data = await getAPI('utils', 'get_prefix_seral_number', {
        prefix: prefix,
        document_type: this.state.document_type,
      })
      if (data) {
        this.setState({
          doc_number: data.doc_number,
          items: this.state.items.map(item =>
            item.key == this.state.activeKey
              ? {
                  ...item,
                  label: `${prefix}${data.doc_number}`,
                }
              : item,
          ),
        })
      }
    }
  }
  refreshPrefixes = async () => {
    const data = await getAPI('utils', 'get_document_prefix', {
      document_type: this.state.document_type,
    })
    if (data) {
      this.setState({ prefixes: data.prefixes })
    }
  }

  refreshNotesOrTerms = async refreshType => {
    try {
      const isNotes = refreshType === 'notes' ? 1 : 0
      const data = await getAPI('user', 'get_document_notes', {
        is_notes: isNotes,
        document_type: this.state.document_type,
      })
      if (data.success) {
        const refresh_type = refreshType === 'notes' ? 'document_notes' : 'document_terms'

        this.setState({
          [refresh_type]: data.data,
        })
      }
    } catch (error) {
      console.error('Error fetching data:', error)
    }
  }

  handleLabelChange = (type, value) => {
    const labelType = type === 'terms' ? 'termsLabel' : 'notesLabel'
    this.setState({ [labelType]: value })
  }

  handleSaveNotesAndTerms = async (type, is_new = false) => {
    if (is_new) {
      const { notesLabel, termsLabel } = this.state

      this.setState({
        showNotesLabelError: type === 'notes' && notesLabel.trim() === '',
        showTermsLabelError: type === 'terms' && termsLabel.trim() === '',
        isNotesLoading: type === 'notes',
        isTermsLoading: type === 'terms',
      })

      if (type === 'notes' && notesLabel.trim() === '') {
        this.setState({ isNotesLoading: false })
        return
      }
      if (type === 'terms' && termsLabel.trim() === '') {
        this.setState({ isTermsLoading: false })
        return
      }

      try {
        const isNotes = type === 'notes'

        await getAPI('user', 'edit_document_notes', {
          document_type: this.state.document_type,
          is_active: 1,
          is_default: 0,
          is_delete: 0,
          is_notes: isNotes,
          label: isNotes ? notesLabel : termsLabel,
          note_id: -1,
          notes: isNotes ? this.state.notes : this.state.terms,
        })

        const updateFlags = isNotes
          ? {
              notesLabel: '',
              showNotes: false,
              isNotesLoading: false,
              showNotesLabelError: false,
            }
          : {
              termsLabel: '',
              showTerms: false,
              isTermsLoading: false,
              showTermsLabelError: false,
            }

        this.refreshNotesOrTerms(type)

        this.setState(updateFlags)
      } catch (error) {
        // console.error(error)
      }
    } else {
      const { selected_note, selected_term, document_notes, document_terms } = this.state

      const isLoadingKey = type === 'notes' ? 'isNotesLoading' : 'isTermsLoading'
      this.setState({
        [isLoadingKey]: true,
      })

      try {
        const documentList = type === 'notes' ? document_notes : document_terms
        const selectedItem = type === 'notes' ? selected_note : selected_term
        const showType = type === 'notes' ? 'showNotes' : 'showTerms'
        const dataKey = type === 'notes' ? 'notes' : 'terms'

        if (documentList.length === 0) {
          this.setState({ [showType]: true })
        } else {
          const selectedData = documentList[selectedItem]
          await getAPI('user', 'edit_document_notes', {
            ...selectedData,
            notes: this.state[dataKey],
          })
          this.refreshNotesOrTerms(type)
        }

        this.setState({
          [isLoadingKey]: false,
        })
      } catch (error) {
        console.error(error)
      }
    }
  }

  handleScroll = e => {
    const bottom =
      parseInt(e.target.scrollHeight - e.target.scrollTop) - parseInt(e.target.clientHeight) <= 1

    if (bottom) {
      this.setState({ page: this.state.page + 1 }, () => {
        this.customerSearchAPI(this.state.customerName, false)
      })
    }
  }
  leaveChanges = async () => {
    if (this.state.edit || window.location.href.includes('draft')) {
      this.changeRoute()
      await this.props.dispatch(remove_all_invoices_data())
      return
    }
    const invoices = this.props.invoice_tabs.filter(
      tab => !tab.data.isClosed && !tab.data.isCreated,
    )

    // change the below condition when multiple tabs are allowed
    if (invoices.length == 0 || this.state.dataSource.length == 0) {
      this.changeRoute()
      await this.props.dispatch(remove_all_invoices_data())
    } else {
      this.setState({ visible: true })
    }
  }

  deleteDraft = async doc_count => {
    var req = {
      document_type: 'draft',
      doc_count: doc_count,
    }
    await getAPI('doc', 'burn', req)
    return
  }

  deleteAllDrafts = async () => {
    const activeKey = this.state.activeKey

    const unSavedInvoices = this.props.invoice_tabs.filter(tab => !tab.data.isCreated)

    if (unSavedInvoices.length > 0) {
      const item = unSavedInvoices.map(invoice => {
        const doc_count = invoice.data.auto_draft.doc_count
        if (doc_count) {
          this.deleteDraft(doc_count)
        }
      })
    }

    await this.props.dispatch(remove_all_invoices_data())
    this.changeRoute()
  }

  saveAsDraft = async (saveAll = true) => {
    this.setState({ loadingSave: true })
    if (!saveAll) {
      await this.save('1', true)
      this.setState({ saveDocument: false })
      this.setState({ loadingSave: false })

      return
    } else {
      const activeKey = this.state.activeKey
      if (!this.state.isCreated) {
        await this.save('1', true, true)
      }

      const unSavedInvoices = this.props.invoice_tabs.filter(
        tab => !tab.data.isCreated && tab.key != activeKey,
      )

      if (unSavedInvoices.length > 0) {
        const item = unSavedInvoices.map(invoice => {
          const invoiceData = invoice.data
          this.setState({ ...invoiceData }, async () => {
            await this.save('1', true, true)
          })
        })
        await this.props.dispatch(remove_all_invoices_data())
      }
      this.setState({ loadingSave: false })

      this.changeRoute()
    }
  }
  startAutoDrafting = () => {
    this.autoDraftInterval = setInterval(() => {
      this.saveToDatabase()
    }, 15000)
  }
  stopAutoDrafting = () => {
    // Clear the interval when component unmounts or when not needed
    clearInterval(this.autoDraftInterval)
  }
  saveToDatabase = async () => {
    if (!this.state.isCreated) {
      // if (this.state.isDrafted) {
      //   this.setState(
      //     {
      //       document_type: 'draft',
      //     },
      //     async () => {
      //       await this.save('1', true, true)
      //     },
      //   )
      // } else {
      //   await this.save('1', true, true)
      // }
      await this.save('1', true, true)
    }
  }

  render() {
    const { notesLabel, termsLabel, showNotes, showTerms } = this.state
    const allDueDates = [
      {
        name: 'Today',
        value: dayjs(this.state.document_date, this.dateFormat).format(this.dateFormat),
      },
      {
        name: 'Net 15',
        value: dayjs(this.state.document_date, this.dateFormat)
          .add(15, 'days')
          .format(this.dateFormat),
      },
      {
        name: 'Net 30',
        value: dayjs(this.state.document_date, this.dateFormat)
          .add(30, 'days')
          .format(this.dateFormat),
      },
      {
        name: 'Net 60',
        value: dayjs(this.state.document_date, this.dateFormat)
          .add(60, 'days')
          .format(this.dateFormat),
      },
      {
        name: 'Net 90',
        value: dayjs(this.state.document_date, this.dateFormat)
          .add(90, 'days')
          .format(this.dateFormat),
      },
    ]
    const discount_type_items = [
      {
        key: '1',
        label: ' Price With Tax',
      },
      {
        key: '2',
        label: 'Unit Price',
      },
      {
        key: '3',
        label: 'Net Amount',
      },
      {
        key: '4',
        label: 'Total Amount',
      },
    ]

    const dateFooter = (
      <React.Fragment>
        {allDueDates.map((item, i) => {
          return (
            <Tag
              key={i}
              className="m-1"
              onClick={() => this.setState({ due_date: item.value, openDatePicker: false })}
              color="success"
              style={{ cursor: 'pointer' }}
            >
              <span className="font-weight-medium">{item.name}</span>
            </Tag>
          )
        })}

        <InputNumber
          className="ml-1"
          placeholder="Net Days"
          onChange={e =>
            this.setState({
              due_date: dayjs(this.state.document_date, this.dateFormat)
                .add(e, 'days')
                .format(this.dateFormat),
            })
          }
          size="small"
          onClick={e => e.target.select()}
          onFocus={() => this.setState({ openDatePicker: true })}
          onBlur={() => this.setState({ openDatePicker: false })}
        />
      </React.Fragment>
    )

    const defaultOptions = {
      loop: true,
      autoplay: true,
      animationData: animationData,
      rendererSettings: {
        preserveAspectRatio: 'xMidYMid slice',
      },
    }

    const invoiceComponent = (
      <>
        {this.state.loaded == true && (
          <div className="mb-5">
            <div className="d-flex flex-row flex-wrap mb-4">
              <div className="mr-4">
                <Space align="center">
                  <div className="d-flex flex-column align-items-start">
                    <SectionHeader title={this.state.document_title + ' #'} />
                    {this.state.multiple_gst.length == 0 && (
                      <Input.Group compact>
                        <Select
                          suffixIcon={<i className="fa-regular fa-chevron-down"></i>}
                          value={this.state.prefix}
                          onChange={e => this.get_prefix_doc_number(e)}
                          onBlur={e => {
                            if (e.target.value != '') {
                              this.get_prefix_doc_number(e.target.value)
                            }
                          }}
                          disabled={this.state.is_pos}
                          style={{
                            width: 135,
                            textAlign: 'right',
                          }}
                          dropdownStyle={{ minWidth: '300px' }}
                          dropdownRender={menu => (
                            <>
                              <div className="d-flex p-2 mt-2">
                                <Input
                                  placeholder="Add Custom Prefix"
                                  className="w-100"
                                  value={this.state.new_prefix}
                                  onChange={e =>
                                    this.setState({
                                      prefix: e.target.value,
                                      new_prefix: e.target.value,
                                    })
                                  }
                                  // onBlur={() => this.get_prefix_doc_number(this.state.prefix)}
                                />
                              </div>
                              <Divider />
                              {menu}
                              <div className="bg-gray-75">
                                <Button
                                  block
                                  size=""
                                  type="link-2"
                                  className="font-weight-bolder text-left my-2 arrow-transition"
                                  onMouseDown={e => e.preventDefault()}
                                  onClick={() =>
                                    this.documentPrefixesRef.current.show_drawer(
                                      this.state.document_type,
                                    )
                                  }
                                >
                                  <i1 className="fa-solid fa-circle-plus mr-2"></i1>
                                  Add New Prefix
                                  <i className="fa-solid fa-arrow-right ml-2 arrow-transition"></i>
                                </Button>
                              </div>
                            </>
                          )}
                        >
                          {this.state.prefixes.map((item, i) => {
                            return (
                              <Option key={i} value={item.prefix}>
                                {item.prefix}
                              </Option>
                            )
                          })}
                        </Select>
                        {/*<span className="m-1 mt-1"></span>*/}
                        <InputNumber
                          ref={this.docNumberRef}
                          min={1}
                          className="font-weight-bolder"
                          disabled={this.state.is_pos}
                          style={{
                            minWidth: 108,
                            //backgroundColor: '#f8faff',
                          }}
                          value={this.state.doc_number}
                          onChange={e =>
                            this.setState({
                              doc_number: e,
                              items: this.state.items.map(item =>
                                item.key == this.state.activeKey
                                  ? {
                                      ...item,
                                      label:
                                        e == null ? this.state.prefix + '' : this.state.prefix + e,
                                    }
                                  : item,
                              ),
                            })
                          }
                          onFocus={() => {
                            if (this.docNumberRef.current) {
                              this.docNumberRef.current.style.border = ''
                            }
                          }}
                        />
                      </Input.Group>
                    )}
                  </div>
                </Space>
              </div>

              {/*due date will be changed to this place*/}
              {/*<div className="mr-4">
                <Space align="center">
                  <div className="d-flex flex-column align-items-start">
                    <SectionHeader title={this.state.document_title + ' Date'} />
                    <DatePicker
                      allowClear={false}
                      format={this.dateFormat}
                      value={dayjs(this.state.document_date, this.dateFormat)}
                      onChange={this.onDocumentDateChange}
                    />
                  </div>
                </Space>
              </div>

              <div className="mr-4">
                <Space align="center">
                  <div className="d-flex flex-column align-items-start">
                    <SectionHeader title={this.state.document_title + ' Due Date'} />
                    <DatePicker
                      format={this.dateFormat}
                      onClick={() => this.setState({ openDatePicker: true })}
                      onBlur={() => this.setState({ openDatePicker: false })}
                      allowClear={false}
                      showToday={false}
                      open={this.state.openDatePicker}
                      value={dayjs(this.state.due_date, this.dateFormat)}
                      renderExtraFooter={() => dateFooter}
                      size="default"
                      disabledDate={this.disabledDate}
                      onChange={this.onDueDateChange}
                    />
                  </div>
                </Space>
              </div>*/}

              <div className="ml-auto mt-3">
                <div className="d-flex flex-wrap font-size-14">
                  <div className="d-inline-flex align-items-center float-right">
                    {this.getTotal() > 0 && (
                      <>
                        {this.state.document_type != 'subscription' &&
                          !(this.state.is_ewaybill || this.state.is_create_einvoice) &&
                          !this.state.edit &&
                          this.state.customerData.length < 2 && (
                            <Button
                              className="d-none d-sm-block font-weight-bold mr-2"
                              loading={this.state.loadingSave}
                              type="secondary"
                              onClick={() => this.save('1', true)}
                            >
                              Save as Draft <BetaBadge />
                            </Button>
                          )}
                        {this.state.customerData.length < 2 &&
                          !(this.state.is_ewaybill || this.state.is_create_einvoice) &&
                          this.state.document_type != 'subscription' && (
                            <Button
                              className="d-none d-sm-block font-weight-bold"
                              loading={this.state.loadingSave}
                              onClick={() => this.save('2')}
                              type="secondary"
                            >
                              Save and Print
                            </Button>
                          )}
                        <Button
                          loading={this.state.loadingSave}
                          onClick={() => this.save('1')}
                          type="primary"
                          className="font-weight-bold ml-3 ml-2 arrow-transition"
                        >
                          Save
                          <i className="fa-solid fa-arrow-right ml-2 arrow-transition"></i>
                        </Button>
                      </>
                    )}
                  </div>
                </div>
              </div>
            </div>
            {/*</Card>*/}
            {/* {this.state.showDraftMessage ? (
              <p className="ml-4 mb-0 text-gray-500 font-weight-medium font-size-14 text-center">
                Saving as draft ...
              </p>
            ) : (
              <p className="ml-4 mb-0 text-gray-500 font-weight-medium font-size-14 text-center">
                {' '}
              </p>
            )} */}

            <div className="d-flex mb-1">
              {this.state.document_type == 'invoice' &&
                this.props?.user?.selectedCompany?.country_code == 'IN' && (
                  <div className="pr-2 pl-2">
                    <Checkbox
                      onChange={e => {
                        this.onDocumentTitleChange(
                          e.target.checked == true ? 'Bill of Supply' : 'Invoice',
                        )
                      }}
                      disabled={
                        this.state.companyDetails.is_composite ||
                        this.state.export_type == 'Export with IGST' ||
                        this.state.export_type == 'SEZ with IGST Payment'
                      }
                      checked={
                        this.state.document_title == 'Invoice'
                          ? this.state.companyDetails.is_composite
                            ? this.onDocumentTitleChange('Bill of Supply')
                            : this.state.document_title == 'Bill of Supply'
                          : this.state.document_title == 'Bill of Supply'
                      }
                      className="font-size-14"
                    >
                      Bill of Supply
                    </Checkbox>
                  </div>
                )}
              {this.state.document_type == 'estimate' && (
                <div className="pr-2 mb-1">
                  <span className="select-borderless">
                    <Select
                      suffixIcon={<i className="fa-regular fa-chevron-down"></i>}
                      size="small"
                      value={this.state.document_title}
                      onChange={e => this.onDocumentTitleChange(e)}
                    >
                      <Option value="Quotation">Quotation</Option>
                      <Option value="Estimate">Estimate</Option>
                    </Select>
                  </span>
                </div>
              )}
              {(this.state.document_type == 'estimate' ||
                this.state.document_type == 'purchase' ||
                this.state.document_type == 'purchase_order' ||
                this.state.document_type == 'purchase_return' ||
                this.state.document_type == 'sales_return' ||
                this.state.document_type == 'delivery_challan' ||
                this.state.document_type == 'pro_forma_invoice') &&
                this.state.companyDetails.gstin.length == 15 &&
                this.state.companyDetails.is_composite == 0 && (
                  <div className="pl-2">
                    <Checkbox
                      onChange={e => {
                        this.setState({ with_tax: e.target.checked == false }, () => {
                          this.onWithTaxChange()
                        })
                      }}
                      checked={this.state.with_tax == false}
                      className="font-size-14"
                    >
                      Create {this.state.document_title} Without{' '}
                      {this.props?.user?.selectedCompany?.labels?.gst}{' '}
                    </Checkbox>
                  </div>
                )}

              {(this.state.document_type == 'invoice' ||
                this.state.document_type == 'sales_return' ||
                this.state.document_type == 'pro_forma_invoice' ||
                this.state.document_type == 'estimate' ||
                this.state.document_type == 'purchase' ||
                this.state.document_type == 'purchase_order') &&
                this.props?.user?.selectedCompany?.country_code == 'IN' && (
                  <div className="pl-2">
                    <Checkbox
                      disabled={store.get('is_export') != 1}
                      checked={this.state.export_invoice}
                      onChange={e => {
                        // console.log(e)
                        if (e.target.checked) {
                          this.onExportChange()
                        } else {
                          this.setState({ conversion_factor: 1, export_type: null })
                        }
                        this.setState(
                          {
                            export_invoice: !this.state.export_invoice,
                            with_tax:
                              this.state.document_type == 'purchase'
                                ? this.state.with_tax
                                : !e.target.checked,
                            is_multi_currency: e.target.checked
                              ? false
                              : this.state.is_multi_currency,
                          },
                          () => {
                            this.onWithTaxChange()
                          },
                        )
                      }}
                      className="font-size-14"
                    >
                      {store.get('is_export') == 1 && (
                        <span>
                          {this.state.document_type == 'purchase' ||
                          this.state.document_type == 'purchase_order'
                            ? 'Import'
                            : 'Export/ SEZ'}
                        </span>
                      )}
                      {store.get('is_export') != 1 && (
                        <Tooltip
                          title={
                            <span>
                              <br />
                              <p className="font-size-16">
                                This is part of our{' '}
                                <span className="text-jet font-weight-bold font-italic">Rise</span>{' '}
                                plan. 🚀
                                <br />
                                Upgrade today and start using!
                              </p>
                              <hr />
                              <p className="font-size-14">
                                Feel free to reach us at <br />
                                <b>+91 81213 35436</b>.<br /> We will be happy to assist you.
                              </p>
                              <span>
                                <Button
                                  type="action"
                                  className="mt-2 mb-2"
                                  onClick={() => history.push('/pricing')}
                                >
                                  Subscribe Now! 🚀
                                </Button>
                              </span>
                            </span>
                          }
                        >
                          <span>Export/ SEZ/ Deemed Export / Imports</span>
                        </Tooltip>
                      )}

                      <BetaBadge />
                    </Checkbox>
                  </div>
                )}

              <div className="pl-2">
                {store.get('is_multi_currency') == 1 && this.state.document_type != 'online_order' && (
                  <Checkbox
                    disabled={this.state.export_invoice}
                    checked={this.state.is_multi_currency}
                    onChange={e => {
                      if (e.target.checked) {
                        this.onExportChange()
                      } else {
                        this.setState({ conversion_factor: 1 })
                      }
                      this.setState(
                        {
                          is_multi_currency: !this.state.is_multi_currency,
                        },
                        () => {
                          if (this.table) {
                            this.table.edit(this.state.edit)
                          }
                        },
                      )
                    }}
                    className="text-gray-500 mb-1 font-size-14"
                  >
                    <span>Multi Currency</span>
                    {store.get('is_multi_currency') != 1 && (
                      <Tooltip
                        title={
                          <span>
                            <br />
                            <p className="font-size-16">
                              This is part of our{' '}
                              <span className="text-jet font-weight-bold font-italic">Rise</span>{' '}
                              plan. 🚀
                              <br />
                              Upgrade today and start using!
                            </p>
                            <hr />
                            <p className="font-size-14">
                              Feel free to reach us at <br />
                              <b>+91 81213 35436</b>.<br /> We will be happy to assist you.
                            </p>
                            <span>
                              <Button
                                type="action"
                                className="mt-2 mb-2"
                                onClick={() => history.push('/pricing')}
                              >
                                Subscribe Now! 🚀
                              </Button>
                            </span>
                          </span>
                        }
                      >
                        <span>Multi Currency</span>
                      </Tooltip>
                    )}
                  </Checkbox>
                )}
              </div>

              {this.state.companyDetails.shipping_address.length > 0 && (
                <div className="pl-2 select-borderless d-none d-sm-block">
                  <span className="text-gray-500 font-size-14 font-weight-medium ml-2">
                    {dispatch_to_doc_types.includes(this.state.document_type)
                      ? 'Dispatch To'
                      : 'Dispatch From'}
                  </span>
                  <span className="select-borderless">
                    <Select
                      size={'small'}
                      suffixIcon={<i className="fa-regular fa-chevron-down"></i>}
                      placeholder={'Select address'}
                      optionFilterProp="children"
                      style={{ maxWidth: 360 }}
                      listHeight={200}
                      dropdownStyle={{ minWidth: '360px' }}
                      onChange={e => {
                        this.setState({
                          selected_company_shipping_address: this.state.companyDetails.shipping_address.find(
                            ({ addr_id }) => addr_id === e,
                          ),
                        })
                      }}
                      allowClear
                      value={
                        this.state.selected_company_shipping_address
                          ? 'addr_id' in this.state.selected_company_shipping_address
                            ? this.state.selected_company_shipping_address.addr_id
                            : undefined
                          : undefined
                      }
                      filterOption={(input, option) =>
                        option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                      }
                      dropdownRender={menu => (
                        <>
                          {menu}
                          <div className="bg-gray-50">
                            {/*<Divider className="my-0" />*/}
                            <Button
                              block
                              size=""
                              type="link-2"
                              className="font-weight-bolder text-left my-2 arrow-transition"
                              onClick={e => {
                                e.stopPropagation()
                                this.EditCompanyForm.onAdd()
                              }}
                            >
                              <i1 className="fa-solid fa-circle-plus mr-2"></i1>
                              Add Company Shipping address
                              <i className="fa-solid fa-arrow-right ml-2 arrow-transition"></i>
                            </Button>
                          </div>
                        </>
                      )}
                    >
                      {this.state.companyDetails.shipping_address.map((item, i) => {
                        if (
                          item.is_delete == 0 ||
                          item.addr_id == this.state.selected_company_shipping_address?.addr_id
                        ) {
                          return (
                            <Option key={i} value={item.addr_id}>
                              <span>
                                {(item.title != '' ? item.title + ' ' : '') +
                                  item.line1 +
                                  ' ' +
                                  item.line2 +
                                  ' ' +
                                  item.city +
                                  ' ' +
                                  item.state +
                                  ' ' +
                                  item.pincode}
                              </span>
                            </Option>
                          )
                        }
                      })}
                    </Select>
                  </span>
                </div>
              )}

              {/* <div className="ml-auto d-none d-sm-block">
                <a_link
                  onClick={e => {
                    store.get('paid') == 0
                      ? this.subscriptionPlanRef.current.openModal('custom_fields')
                      : this.custom_column.onAddCustomHeaders()
                  }}
                  className="font-weight-medium"
                >
                  <i className="fa-solid fa-plus-circle mr-2" />
                  Custom Headers
                  {store.get('paid') == 0 && <ProBadge />}
                </a_link>
                <a_link
                  onClick={() => this.invoiceSettingsForm.onOpen()}
                  className="font-weight-medium ml-4 pr-2"
                >
                  <i className="fa-solid fa-gear mr-2" />
                  Settings
                </a_link>
              </div>*/}
            </div>

            <Card
              size="medium"
              bordered={false}
              className="border-radius-small bg-card-azure card-shadow mb-4"
            >
              <div className="row">
                {this.state.payment_status == 'pending' &&
                  this.state.from_document_type != 'online_order' && (
                    <div className="col-12 col-lg-4 col-md-6">
                      <h6 className="">
                        <span className="mr-1">
                          Select {this.state.party_type == 'customer' ? 'Customer' : 'Vendor'}
                        </span>
                        <span className="mr-3">
                          <InfoIcon
                            text={
                              <span>
                                <span>
                                  Search your existing customers using the search box below.
                                </span>
                                <br />
                                <span>
                                  Please click on <strong>Add New {this.state.party_type}</strong>{' '}
                                  to add a new customer.
                                </span>
                              </span>
                            }
                          />
                        </span>
                        <Button
                          type="outline"
                          onClick={() =>
                            this.state.party_type == 'customer'
                              ? this.customer?.onAdd(this.state.customerName)
                              : this.vendor?.onAdd(this.state.customerName)
                          }
                          className="font-weight-bold xsmall d-none d-sm-inline"
                        >
                          <i class="fa-solid fa-circle-plus mr-1"></i>
                          Add New {this.state.party_type == 'customer' ? 'Customer' : 'Vendor'}
                        </Button>
                      </h6>
                      <span>
                        <AutoComplete
                          options={this.state.autocompleteData}
                          className="mr-2 pb-2"
                          autoFocus
                          style={{ width: '100%', height: '32px' }}
                          dropdownMatchSelectWidth={isMobile ? '100%' : 640}
                          dropdownMenuStyle={{ top: 18 }}
                          trigger="hover"
                          onKeyDown={e => {
                            if (e.key === 'Enter') {
                              e.preventDefault()
                              if (
                                e.target.value != '' &&
                                this.state.autocompleteData?.length == 0
                              ) {
                                // open add customer modal
                                this.state.party_type == 'customer'
                                  ? this.customer?.onAdd(this.state.customerName)
                                  : this.vendor?.onAdd(this.state.customerName)
                              }
                            }
                          }}
                          dropdownRender={menu => (
                            <>
                              {menu}
                              <div className="bg-gray-75">
                                {/*<Divider className="my-0" />*/}
                                <Button
                                  block
                                  size=""
                                  type="link-2"
                                  className="font-weight-bolder text-left my-2 arrow-transition"
                                  onClick={() =>
                                    this.state.party_type == 'customer'
                                      ? this.customer?.onAdd(this.state.customerName)
                                      : this.vendor?.onAdd(this.state.customerName)
                                  }
                                >
                                  <i1 className="fa-solid fa-circle-plus mr-2"></i1>
                                  Add New
                                  {this.state.party_type == 'customer' ? ' Customer' : ' Vendor'}
                                  <i className="fa-solid fa-arrow-right ml-2 arrow-transition"></i>
                                </Button>
                              </div>
                            </>
                          )}
                          notFoundContent={
                            <>
                              <div
                                className="px-4 py-2 text-gray-500 font-weight"
                                onClick={() =>
                                  this.state.party_type == 'customer'
                                    ? this.customer.onAdd(this.state.customerName)
                                    : this.vendor.onAdd(this.state.customerName)
                                }
                              >
                                {/*No {this.state.party_type === 'customer' ? ' customers' : ' vendors'}{' '}
                              found.*/}
                                <span className="font-weight-bold">No search results found</span>
                                <p className="mb-0">
                                  Please try again with a different search query or{' '}
                                </p>
                              </div>
                            </>
                          }
                          onSelect={
                            this.state.party_type == 'customer'
                              ? this.onCustomerSelect
                              : this.onVendorSelect
                          }
                          onSearch={this.onCustomerSearch}
                          value={this.state.customerName}
                          placeholder={`Search ${
                            this.state.party_type == 'customer' ? 'Customers' : 'Vendors'
                          }, Company Name, ${
                            this.props?.user?.selectedCompany?.tax_id_label
                          }, tags...`}
                          ref={this.inputEl}
                          prefix={<i className="fa-solid fa-magnifying-glass text-gray-300"></i>}
                          onPopupScroll={this.handleScroll}
                          id={'addParty'}
                        ></AutoComplete>

                        {this.state.customerData.map((itemTag, i) => (
                          <Popover
                            placement="bottomLeft"
                            title={
                              <div>
                                <span className="d-block font-weight-bold text-center">
                                  {this.state.customerData[i]['notes']}
                                </span>
                                <span className="d-block font-weight-bold text-center">
                                  {this.state.customerData[i]['company_name']}
                                </span>
                                <span className="d-block font-weight-bold text-center">
                                  {this.state.customerData[i]['gstin']}
                                </span>
                                <span className="d-block font-weight-bold text-center">
                                  {this.state.customerData[i]['billing']?.state}
                                </span>
                                {this.props.permissions.ledgers == 1 && (
                                  <Button
                                    onClick={e => {
                                      e.stopPropagation()

                                      this.ledger.showModal({
                                        ...this.state.customerData[i],
                                        id: this.state.customerData[i]['id'],
                                        type: this.state.party_type,
                                      })
                                    }}
                                    className="mt-2 mb-1"
                                    type="check-ledger"
                                    size="small"
                                    shape="round"
                                  >
                                    <span className="font-weight-bold">
                                      Check <FormattedMessage id="button.ledger" />
                                    </span>
                                  </Button>
                                )}
                              </div>
                            }
                          >
                            <Tag
                              key={i}
                              closable
                              closeIcon={<i className="fa-solid fa-xmark fa-lg ml-1"></i>}
                              onClose={e => this.tagDelete(e, itemTag)}
                              onClick={e => {
                                let has_permission = this.props.permissions[
                                  this.state.party_type + '_edit'
                                ]
                                if (has_permission == 1) {
                                  this.state.party_type == 'customer'
                                    ? this.customer.onEdit(itemTag.id)
                                    : this.vendor.onFill({ vendor_id: itemTag.id })
                                }
                              }}
                              style={{ cursor: 'pointer' }}
                              className="tag-large mt-2"
                            >
                              <span className="font-weight-bolder">{itemTag.notes}</span>
                            </Tag>
                          </Popover>
                        ))}
                      </span>
                    </div>
                  )}

                {((this.state.edit && this.state.payment_status != 'pending') ||
                  this.state.from_document_type == 'online_order') && (
                  <div className="col-12 col-lg-4 col-md-6">
                    <h6>Customer</h6>
                    <span className="d-inline-flex" style={{ width: '100%' }}>
                      <Input
                        style={{ width: '100%', borderRadius: '5px' }}
                        placeholder="Customer Name"
                        value={this.state.name}
                        disabled
                      />
                    </span>
                  </div>
                )}

                {this.state.document_type != 'subscription' && (
                  <div className="col-6 col-lg-2 col-md-6">
                    <h6>{this.state.document_title} Date</h6>
                    <DatePicker
                      style={{
                        borderRadius: '5px',
                        cursor: 'pointer',
                        width: '100%',
                      }}
                      allowClear={false}
                      format={this.dateFormat}
                      value={dayjs(this.state.document_date, this.dateFormat)}
                      onChange={this.onDocumentDateChange}
                    />
                  </div>
                )}

                {this.state.document_type != 'subscription' && (
                  <div className="col-6 col-lg-2 col-md-6">
                    <h6 className="">
                      Due Date
                      <span>
                        <InfoIcon text=" The invoice due date is the date on which you expect to receive payment from your customer." />
                      </span>
                    </h6>
                    <DatePicker
                      format={this.dateFormat}
                      allowClear={false}
                      showToday={false}
                      value={dayjs(this.state.due_date, this.dateFormat)}
                      renderExtraFooter={() => dateFooter}
                      size="default"
                      style={{
                        borderRadius: '5px',
                        cursor: 'pointer',
                        width: '100%',
                      }}
                      disabledDate={this.disabledDate}
                      onChange={this.onDueDateChange}
                    />
                  </div>
                )}

                <div className="col-12 col-lg-4 col-md-6">
                  <h6 className="">
                    Reference
                    <InfoIcon text="Reference is commonly used to save information like Purchase Order Number, Eway Bill Number, Sales Person names, Shipment Number etc..." />
                  </h6>
                  <TextArea
                    rows={1}
                    type="text"
                    className="pr-4 mr-2"
                    placeholder="Any text, PO Number etc... (Optional)"
                    name="reference"
                    style={{ width: '100%', borderRadius: '5px' }}
                    onChange={e => this.onChange(e)}
                    value={this.state.reference}
                  />
                </div>

                {!dispatch_to_doc_types.includes(this.state.document_type) &&
                  this.state.customer_details.shipping_address.length > 0 &&
                  this.state.customerData.length == 1 &&
                  this.state.document_type != 'purchase' &&
                  this.state.document_type != 'purchase_order' &&
                  this.state.document_type != 'purchase_return' && (
                    <div className="col-12 col-lg-4 col-md-6 mb-2 mt-2">
                      <h6>Select Customer Shipping Address</h6>
                      <Select
                        suffixIcon={<i className="fa-regular fa-chevron-down"></i>}
                        placeholder="Select Customer Shipping Address"
                        optionFilterProp="children"
                        style={{ width: '100%' }}
                        className="mb-2"
                        showSearch
                        allowClear
                        onChange={e => {
                          this.setState({
                            selected_customer_shipping_address: this.state.customer_details.shipping_address.find(
                              ({ addr_id }) => addr_id === e,
                            ),
                          })
                        }}
                        value={
                          this.state.selected_customer_shipping_address
                            ? 'addr_id' in this.state.selected_customer_shipping_address
                              ? this.state.selected_customer_shipping_address.addr_id
                              : undefined
                            : undefined
                        }
                        filterOption={(input, option) =>
                          option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                        }
                      >
                        {this.state.customer_details.shipping_address.map((item, i) => (
                          <Option key={i} value={item.addr_id}>
                            {(item.title != '' ? item.title + ' ' : '') +
                              item.line1 +
                              ' ' +
                              item.line2 +
                              ' ' +
                              item.city +
                              ' ' +
                              (item.state == '97-OTHER TERRITORY' ? '' : item.state) +
                              ' ' +
                              item.pincode}
                          </Option>
                        ))}
                      </Select>
                    </div>
                  )}

                {this.state.document_type == 'purchase' && (
                  <div className="col-12 col-lg-4 col-md-6 mt-2 mb-2">
                    <h6>Supplier Invoice Date</h6>
                    <DatePicker
                      style={{
                        borderRadius: '5px',
                        cursor: 'pointer',
                        width: '100%',
                      }}
                      allowClear={false}
                      format={this.dateFormat}
                      value={dayjs(this.state.supplier_invoice_date, this.dateFormat)}
                      onChange={this.onSupplierInvoiceDateChange}
                    />
                  </div>
                )}

                {this.state.document_type == 'purchase' && (
                  <div className="col-12 col-lg-4 col-md-6 mt-2 mb-2">
                    <h6 className="">Supplier Invoice Serial No.</h6>
                    <TextArea
                      rows={1}
                      type="text"
                      className="pr-4 mr-2"
                      placeholder={
                        this.props.invoice_settings?.supplier_invoice_serial_number
                          ? 'Supplier Invoice Serial No.'
                          : '(Optional)'
                      }
                      name="supplier_invoice_serial_number"
                      style={{ width: '100%', borderRadius: '5px' }}
                      onChange={e => this.onChange(e)}
                      value={this.state.supplier_invoice_serial_number}
                    />
                  </div>
                )}
              </div>
            </Card>

            {this.state.custom_headers.length > 0 && this.state.document_type != 'online_order' && (
              <div id="document_custom_headers">
                <SectionHeader title="Custom Headers" />
                <Card
                  size="medium"
                  bordered={false}
                  className="border-radius-small bg-gray-75 mb-4"
                >
                  <div className="row">
                    {this.state.custom_headers.map((item, index) => (
                      <div className="col-12 col-md-4 col-lg-3 mb-2">
                        <h6 className="">{item.label}</h6>
                        {item.field_type == 'number' ? (
                          <InputNumber
                            className="w-100"
                            value={item.value}
                            status={item.is_error ? 'error' : ''}
                            placeholder={item.label}
                            onChange={e => {
                              // console.log(e)
                              this.onCustomHeaderChange(e, item.header_id)
                            }}
                          />
                        ) : item.field_type == 'date' ? (
                          <DatePicker
                            style={{ width: '100%' }}
                            placeholder={item.label}
                            status={item.is_error ? 'error' : ''}
                            value={
                              item.value != '' && this.dateFormatRegex.test(item.value)
                                ? dayjs(item.value, 'DD-MM-YYYY')
                                : undefined
                            }
                            format={this.dateFormat}
                            onChange={value => {
                              this.onCustomHeaderChange(
                                value == null ? '' : value.format(this.dateFormat),
                                item.header_id,
                              )
                            }}
                          />
                        ) : (
                          <AutoComplete
                            popupClassName="certain-category-search-dropdown"
                            style={{
                              width: '100%',
                            }}
                            options={
                              this.renderCustomHeaderOptions(item.header_id).length > 0
                                ? [
                                    {
                                      label: <span>Recently Used </span>,
                                      options: [...this.renderCustomHeaderOptions(item.header_id)],
                                    },
                                  ]
                                : []
                            }
                            value={item.value}
                            onChange={e => {
                              this.onCustomHeaderChange(e, item.header_id)
                            }}
                          >
                            <TextArea
                              rows={1}
                              className="w-100"
                              placeholder={item.label}
                              status={item.is_error ? 'error' : ''}
                              name={item.name}
                              onChange={e => {
                                this.onCustomHeaderChange(e.target.value, item.header_id)
                              }}
                              onKeyDown={e => {
                                if (e.key === 'Enter') {
                                  e.preventDefault()
                                  e.stopPropagation()
                                  this.onCustomHeaderChange(e.target.value + '\n', item.header_id)
                                }
                              }}
                              value={item.value}
                            />
                          </AutoComplete>
                        )}
                        {item.is_error && (
                          <span className="text-danger font-size-12">
                            Please enter {item.label}
                          </span>
                        )}
                      </div>
                    ))}
                  </div>
                </Card>
              </div>
            )}
            {store.get('paid') == 0 && this.state.custom_headers.length == 0 && (
              <div className="mt-3 mb-4">
                <SectionHeader title="Custom Headers" />

                {this.state.suggested_fields.map((item, index) => (
                  <Tag
                    icon={<i className="fa fa-plus mr-2 text-primary" />}
                    className="cursor-pointer tag-large bg-white"
                    onClick={() => this.subscriptionPlanRef.current.openModal('custom_fields')}
                  >
                    {item.name}
                  </Tag>
                ))}
              </div>
            )}
            {/* subscriptions */}
            {(this.state.is_subscription == 1 || this.state.is_subscription == true) && (
              <>
                <SectionHeader title="Recurring schedule" />
                <Card
                  size="medium"
                  bordered={false}
                  className="border-radius-small bg-gray-50 mb-4"
                >
                  <div className="row">
                    <div className="col-6 col-md-3 mb-2 mt-2">
                      <h6>Start Date</h6>
                      <DatePicker
                        style={{
                          borderRadius: '5px',
                          cursor: 'pointer',
                          width: '100%',
                        }}
                        allowClear={false}
                        format={this.dateFormat}
                        value={dayjs(this.state.subscription_start_date, this.dateFormat)}
                        onChange={(date, string) => {
                          this.onSubscriptionDateChange(date, string, 'start')
                        }}
                      />
                    </div>
                    <div className="col-6 col-md-3 mb-2 mt-2">
                      <h6>End Date</h6>
                      <DatePicker
                        style={{
                          borderRadius: '5px',
                          cursor: 'pointer',
                          width: '100%',
                        }}
                        disabledDate={this.disabledEndDate}
                        allowClear={false}
                        format={this.dateFormat}
                        value={dayjs(this.state.subscription_end_date, this.dateFormat)}
                        onChange={(date, string) => {
                          this.onSubscriptionDateChange(date, string, 'end')
                        }}
                      />
                    </div>

                    <div className="col-6 col-md-3 mb-2 mt-2">
                      <h6>Repeat Type</h6>
                      <Select
                        suffixIcon={<i className="fa-regular fa-chevron-down"></i>}
                        onChange={value => this.setState({ repeatType: value })}
                        defaultValue={this.state.repeatType}
                        value={this.state.repeatType}
                        className="w-100"
                      >
                        <Option key="1" value="days">
                          Days
                        </Option>
                        <Option key="2" value="weeks">
                          Weeks
                        </Option>
                        <Option key="3" value="months">
                          Months
                        </Option>
                        <Option key="4" value="years">
                          Years
                        </Option>
                      </Select>
                    </div>
                    <div className="col-6 col-md-3 mb-2 mt-2">
                      <h6>Repeat Every</h6>
                      <Input
                        type="integer"
                        prefix="Every"
                        value={this.state.repeat_every}
                        suffix={this.state.repeatType}
                        onChange={e => this.setState({ repeat_every: e.target.value })}
                      />
                    </div>
                    <div className="col-6 col-md-3 mb-2 mt-2 d-none">
                      <h6>Document Type</h6>
                      <Select
                        suffixIcon={<i className="fa-regular fa-chevron-down"></i>}
                        onChange={value => this.setState({ subscription_document_type: value })}
                        defaultValue={this.state.subscription_document_type}
                        className="w-100"
                      >
                        <Option key="1" value="invoice">
                          Invoice
                        </Option>
                      </Select>
                    </div>
                    <div className="col-6 col-md-3 mb-2 mt-2">
                      <h6>Send E-mail</h6>
                      <Switch
                        checked={this.state.send_email}
                        onChange={checked => this.setState({ send_email: checked })}
                      />
                    </div>
                    <div className="col-6 col-md-3s mb-2 mt-2">
                      <h6>Send SMS</h6>
                      <Switch
                        checked={this.state.send_sms}
                        onChange={checked => this.setState({ send_sms: checked })}
                      />
                    </div>
                  </div>
                </Card>
              </>
            )}

            {(this.state.export_invoice == true || this.state.is_multi_currency == true) && (
              <>
                {this.state.export_invoice == true && (
                  <SectionHeader
                    title={
                      this.state.document_type == 'purchase' ||
                      this.state.document_type == 'purchase_order'
                        ? 'Import Details'
                        : 'Export Details'
                    }
                  />
                )}

                {this.state.is_multi_currency == true && (
                  <SectionHeader title={'Select Currency'} />
                )}
                <Card
                  size="medium"
                  bordered={false}
                  className="border-radius-small bg-gray-75 mb-4"
                >
                  <div className="row">
                    {this.state.export_invoice == 1 && (
                      <div className="col-12 col-md-3 mb-4 mt-2">
                        <h6 className="text-gray-500">
                          {this.state.document_type == 'purchase' ||
                          this.state.document_type == 'purchase_order'
                            ? 'Select Import Type'
                            : 'Select Export Type'}
                        </h6>
                        <Select
                          suffixIcon={<i className="fa-regular fa-chevron-down fa-lg"></i>}
                          style={{ width: '100%' }}
                          placeholder={
                            this.state.document_type == 'purchase' ||
                            this.state.document_type == 'purchase_order'
                              ? 'Select Import Type'
                              : 'Select Export Type'
                          }
                          disabled={
                            this.state.document_type == 'purchase' ||
                            this.state.document_type == 'purchase_order'
                          }
                          onChange={e => {
                            if (
                              this.state.export_invoice &&
                              this.state.document_title == 'Bill of Supply'
                            ) {
                              if (e == 'Export with IGST' || e == 'SEZ with IGST Payment') {
                                message.error(`${e} is not allowed with Bill of Supply`)
                                return
                              }
                            }
                            if (this.state.customerData?.some(val => val?.gstin?.length)) {
                              if (e == 'Export under bond/LUT' || e == 'Export with IGST') {
                                message.error(`${e} is not allowed with GSTIN Customer`)
                                return
                              }
                            }
                            if (e.includes('SEZ')) {
                              this.setState(
                                {
                                  conversion_factor: 1,
                                  country: 75,
                                  currency_symbol: `${this.props.user.selectedCompany.currency_symbol}`,
                                  currency: `${this.props.user.selectedCompany.currency_code}`,
                                  with_tax: !(e.includes('LUT') || e.includes('without')),
                                },
                                () => {
                                  this.onWithTaxChange()
                                },
                              )
                            }
                            this.setState(
                              {
                                export_type: e,
                                with_tax: !(e.includes('LUT') || e.includes('without')),
                              },
                              () => {
                                this.onWithTaxChange()
                              },
                            )
                          }}
                          value={this.state.export_type}
                        >
                          <Option value="Export under bond/LUT">Export under bond/LUT</Option>
                          <Option value="Export with IGST">Export with IGST</Option>
                          <Option value="SEZ with IGST Payment">SEZ with IGST Payment</Option>
                          <Option value="SEZ without IGST Payment">SEZ without IGST Payment</Option>
                          <Option value="Deemed Export">Deemed Export</Option>
                          {this.state.document_type == 'purchase' && (
                            <Option value="Import">Import</Option>
                          )}
                        </Select>
                      </div>
                    )}
                    {this.state.export_invoice == 1 && (
                      <div className="col-12 col-md-3 mb-4 mt-2">
                        <h6 className="text-gray-500">Country of Supply</h6>
                        <Select
                          suffixIcon={<i className="fa-regular fa-chevron-down fa-lg"></i>}
                          showSearch
                          disabled={this.state.export_type?.includes('SEZ')}
                          placeholder="Select Country"
                          optionFilterProp="children"
                          style={{ width: '100%' }}
                          value={this.state.country}
                          defaultValue="178"
                          filterOption={(input, option) =>
                            option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                          }
                          onChange={e =>
                            this.setState({
                              country: e,
                              currency: this.props?.countries.filter(
                                item => item.country_id == e,
                              )[0].currency_code,
                              currency_symbol: this.props?.countries.filter(
                                item => item.country_id == e,
                              )[0].currency_symbol,
                            })
                          }
                        >
                          {this.props?.countries.map((item, i) => (
                            <Option key={i} value={item.country_id}>
                              {item.name}
                            </Option>
                          ))}
                        </Select>
                      </div>
                    )}
                    <div className="col-12 col-md-3 mb-4 mt-2">
                      <h6 className="text-gray-500">Currency</h6>
                      <Select
                        suffixIcon={<i className="fa-regular fa-chevron-down"></i>}
                        showSearch
                        disabled={this.state.export_type?.includes('SEZ')}
                        placeholder="Select Currency"
                        optionFilterProp="children"
                        style={{ width: '100%' }}
                        value={this.state.currency}
                        // defaultValue="177"
                        filterOption={(input, option) =>
                          option.props.children.props.children[0]
                            .toLowerCase()
                            .indexOf(input.toLowerCase()) >= 0
                        }
                        onChange={e => {
                          const symbol = this.props?.countries.filter(
                            item => item.currency_code === e,
                          )[0].currency_symbol

                          this.setState({ currency: e, currency_symbol: symbol })
                        }}
                      >
                        {this.props?.currencies?.map((item, i) => (
                          <Option key={i} value={item.currency_code}>
                            <div>
                              {item.currency_code}
                              <span className="float-right font-weight-bold">
                                {item.currency_symbol}
                              </span>
                            </div>
                          </Option>
                        ))}
                      </Select>
                    </div>
                    <div className="col-12 col-md-3 mb-4 mt-2">
                      <h6 className="text-gray-500">Conversion Rate</h6>
                      <InputNumber
                        style={{
                          width: '100%',
                        }}
                        prefix={'1 ' + this.state.currency_symbol + ' = '}
                        rules={[{ required: true }]}
                        disabled={this.state.export_type?.includes('SEZ')}
                        value={
                          this.state.export_type?.includes('SEZ') ? 1 : this.state.conversion_factor
                        }
                        onBlur={e => {
                          if (e.target.value == '') {
                            e = 1
                            this.setState({ conversion_factor: parseFloat(e) }, () =>
                              this.onExportChange(),
                            )
                          }
                        }}
                        onChange={e => {
                          if (this.state.export_type?.includes('SEZ')) {
                            this.setState({ conversion_factor: 1 }, () => this.onExportChange())
                          } else {
                            // if (e == null) {
                            //   e = 1
                            // }
                            this.setState({ conversion_factor: parseFloat(e) }, () =>
                              this.onExportChange(),
                            )
                          }
                        }}
                        suffix="INR"
                      />
                    </div>
                    {this.state.export_invoice == 1 &&
                      this.state.document_type != 'purchase' &&
                      !this.state.export_type?.includes('SEZ') && (
                        <div className="col-12 col-md-3 mb-4 mt-2">
                          <h6 className="text-gray-500">Shipping Bill Date</h6>
                          <DatePicker
                            style={{
                              borderRadius: '5px',
                              cursor: 'pointer',
                              width: '100%',
                            }}
                            onChange={this.onShippingBillDateChange}
                            allowClear={true}
                            format={this.dateFormat}
                            value={
                              this.state.shipping_bill_date != ''
                                ? dayjs(this.state.shipping_bill_date, this.dateFormat)
                                : null
                            }
                          />
                        </div>
                      )}
                    {this.state.export_invoice == 1 &&
                      this.state.document_type != 'purchase' &&
                      !this.state.export_type?.includes('SEZ') && (
                        <div className="col-12 col-md-3 mb-4 mt-2">
                          <h6 className="text-gray-500">Shipping bill #</h6>
                          <Input
                            placeholder="Optional"
                            value={this.state.shipping_bill_number}
                            onChange={e => {
                              this.setState({ shipping_bill_number: e.target.value })
                            }}
                            style={{ width: '100%' }}
                          />
                        </div>
                      )}
                    {this.state.export_invoice == 1 &&
                      this.state.document_type != 'purchase' &&
                      !this.state.export_type?.includes('SEZ') && (
                        <div className="col-12 col-md-3 mb-4 mt-2">
                          <h6 className="text-gray-500">Shipping Port Code</h6>
                          <Input
                            placeholder="Optional"
                            value={this.state.shipping_port_code}
                            onChange={e => {
                              this.setState({ shipping_port_code: e.target.value })
                            }}
                            style={{ width: '100%' }}
                          />
                        </div>
                      )}
                  </div>
                </Card>
              </>
            )}

            <div className="d-flex mb-1">
              <div className="pl-2 d-none d-sm-block">
                <Checkbox
                  className=""
                  checked={this.state.description}
                  onChange={e =>
                    this.setState({ description: e.target.checked }, async () => {
                      await store.set('show_invoice_description', e.target.checked)
                    })
                  }
                >
                  <span className="font-size-14">Show description</span>
                </Checkbox>
              </div>
              <div className="pl-3 d-none d-sm-block">
                <Checkbox
                  checked={this.state.items_reverse || store.get('items_reverse')}
                  onChange={e => {
                    store.set('items_reverse', e.target.checked)
                    const reversedDataSource = [...this.state.dataSource].reverse()
                    this.setState(
                      {
                        items_reverse: e.target.checked,
                        dataSource: reversedDataSource,
                      },
                      () => {
                        if (this.table) {
                          this.table.dataUpdate(this.state.dataSource, this.state.list_name)
                        }
                      },
                    )
                  }}
                >
                  <span className="font-size-14">Show items in reverse order</span>
                </Checkbox>
              </div>

              <div className="pr-2 ml-auto d-flex">
                {this.props.warehouses != undefined &&
                  this.state.document_type != 'online_order' &&
                  this.allowWarehouses() &&
                  this.props[
                    this.state.movement == 'in' ? 'stock_in_warehouses' : 'stock_out_warehouses'
                  ]?.length > 0 && (
                    <div className="pl-3 select-borderless d-none d-sm-block">
                      <Select
                        suffixIcon={<i className="fa-regular fa-chevron-down"></i>}
                        showSearch
                        size={'small'}
                        allowClear
                        style={{ maxWidth: 360 }}
                        className="font-size-14"
                        dropdownStyle={{ minWidth: '180px' }}
                        placeholder="Change Warehouse"
                        optionFilterProp="children"
                        onChange={this.onWarehouseChange}
                        value={this.state.warehouse_id}
                        filterOption={(input, option) =>
                          option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                        }
                      >
                        {this.props[
                          this.state.movement == 'in'
                            ? 'stock_in_warehouses'
                            : 'stock_out_warehouses'
                        ].map((item, index) => (
                          <Option value={item.warehouse_id}>{item.name}</Option>
                        ))}
                      </Select>
                    </div>
                  )}
                {this.state.price_lists.length > 0 && this.state.document_type != 'online_order' && (
                  <div className="pl-3 select-borderless d-none d-sm-block">
                    <Select
                      suffixIcon={<i className="fa-regular fa-chevron-down"></i>}
                      showSearch
                      size={'small'}
                      style={{ maxWidth: 360 }}
                      dropdownStyle={{ minWidth: '180px' }}
                      className="font-size-14"
                      placeholder="Change PriceList"
                      optionFilterProp="children"
                      onChange={e => this.priceListChangeHandler(e, 'price_change')}
                      onFocus={this.onFocus}
                      onSearch={this.onSearch}
                      value={this.state.selectedPriceList}
                      filterOption={(input, option) =>
                        option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                      }
                      allowClear
                    >
                      {this.state.price_lists.map((item, index) => (
                        <Option value={item.price_list_id}>{item.name}</Option>
                      ))}
                    </Select>
                  </div>
                )}
                <div className="d-none d-sm-flex">
                  <span className="pl-3 select-borderless d-none d-sm-block">
                    <Select
                      suffixIcon={<i className="fa-regular fa-chevron-down"></i>}
                      size={'small'}
                      allowClear={false}
                      style={{ maxWidth: 360 }}
                      dropdownStyle={{ minWidth: '180px' }}
                      placeholder="Change Discount Type"
                      onChange={e => {
                        const key = e
                        let discount_type = ''
                        if (key === '1') {
                          discount_type = 'price_with_tax'
                        } else if (key === '2') {
                          discount_type = 'unit_price'
                        } else if (key === '3') {
                          discount_type = 'net_amount'
                        } else if (key === '4') {
                          discount_type = 'total_amount'
                        }
                        this.onDiscountTypeChange(discount_type)
                      }}
                    >
                      {discount_type_items.map((item, index) => (
                        <Option value={item.key}>{item.label}</Option>
                      ))}
                    </Select>
                  </span>
                  {/*<BetaBadge />*/}
                </div>
              </div>
            </div>
            <Card size="medium" bordered={false} className="border-radius-small bg-card-azure mb-4">
              <div className="row">
                <div className="col-12 col-lg-12">
                  <EnhancedTable
                    onRef={ref => (this.table = ref)}
                    dataUpdate={this.dataUpdate}
                    data={this.state.dataSource}
                    edit={this.state.edit}
                    convert={this.state.convert}
                    duplicate={this.state.duplicate}
                    showDescription={this.state.description}
                    items_reverse={store.get('items_reverse') || this.state.items_reverse}
                    selectedPriceList={this.state.selectedPriceList || 0}
                    updateExtraCharges={this.updateExtraCharges}
                    custom_additional_charges={this.state.custom_additional_charges}
                    document_custom_additional_charges={
                      this.state.document_custom_additional_charges
                    }
                    country={
                      this.props?.countries.filter(
                        item => item.country_id === this.state.country,
                      )[0]
                    }
                    conversion_factor={this.state.conversion_factor}
                    is_export={this.state.export_invoice}
                    is_multi_currency={this.state.is_multi_currency}
                    with_tax={this.state.with_tax}
                    currency_symbol={this.state.currency_symbol}
                    export_type={this.state.export_type}
                    product_categories={this.props.product_categories}
                    discount_type={this.state.document_discount_type}
                    document_settings={this.props.document_settings}
                    onChangeWithTax={this.onChangeWithTax}
                    document_type={this.state.document_type}
                    loadingSave={this.state.loadingSave}
                    old_items_before_edit={this.state.old_items_before_edit}
                    warehouse_id={this.state.warehouse_id}
                    onAPICalled={() => {
                      this.fetchDetails()
                    }}
                    onUpdateItems={(value, origin, updated_product_id) =>
                      this.onPriceListChange(value, origin, updated_product_id)
                    }
                    // For Analytics
                    // PartyData={{
                    //   ...this.state.customer_details,
                    //   party_type: this.state.party_type,
                    //   id: this.state.customer_details[this.state.party_type + '_id'],
                    // }}
                    PartyData={{
                      ...this.state.customerData[this.state.customerData.length - 1],
                      party_type: this.state.party_type,
                      menu_name: this.state.list_name,
                    }}
                    customerDiscount={this.state.customerDiscount}
                  />
                </div>
              </div>
            </Card>
            {/*   </div>
  </div>*/}

            <div className="row mb-2 pl-1">
              <div className="col-12 col-md-6">
                <Collapse
                  accordion
                  className="bg-gray-50 collapse-small"
                  defaultActiveKey={'1'}
                  expandIcon={({ isActive }) => (
                    <span className="">
                      <i
                        className={`fa-solid fa-chevron-down collapse-align-center ${!isActive &&
                          'fa-rotate-270'}`}
                      />
                    </span>
                  )}
                >
                  <Panel
                    header={
                      <>
                        <span className="font-weight-bold">
                          Notes{' '}
                          <InfoIcon
                            text="  For the same notes to reflect on all invoices, fill in the notes
                  in Settings --&gt; Notes and Terms --&gt; Customer notes"
                          />
                        </span>
                      </>
                    }
                    extra={
                      <>
                        <span className="font-weight-bold"></span>

                        {this.state.document_notes.length == 0 ? (
                          <Button
                            type="secondary"
                            size="small"
                            className="font-weight-bolder"
                            onClick={e => {
                              e.stopPropagation()

                              store.get('paid') == 0
                                ? this.subscriptionPlanRef.current.openModal('new_notes')
                                : this.customNotesRef.current.showDrawer(
                                    this.state.document_type,
                                    1,
                                  )
                            }}
                          >
                            <i className="fa fa-plus mr-1" />
                            New Notes{store.get('paid') == 0 && <ProBadge />}
                          </Button>
                        ) : (
                          <span>
                            <Select
                              suffixIcon={<i className="fa-regular fa-chevron-down"></i>}
                              style={{ width: '300px' }}
                              onChange={e => {
                                let notes = ''
                                if (e) {
                                  notes = this.state.document_notes[e].notes
                                }
                                this.setState({
                                  selected_note: e,
                                  notes,
                                })
                              }}
                              onClick={e => {
                                e.stopPropagation()
                              }}
                              dropdownStyle={{ minWidth: isMobile ? '250px' : '500px' }}
                              placement="bottomRight"
                              size={'small'}
                              placeholder="Select Notes"
                              className="widthres"
                              value={
                                this.state.document_notes.length == 0
                                  ? ''
                                  : this.state.selected_note
                              }
                              allowClear={true}
                              dropdownRender={menu => (
                                <>
                                  {menu}
                                  <div className="bg-gray-75">
                                    <Button
                                      block
                                      size=""
                                      type="link-2"
                                      className="font-weight-bolder text-left my-2 arrow-transition"
                                      onClick={e => {
                                        e.stopPropagation()
                                        store.get('paid') == 0
                                          ? this.subscriptionPlanRef.current.openModal('new_notes')
                                          : this.customNotesRef.current.showDrawer(
                                              this.state.document_type,
                                              1,
                                            )
                                      }}
                                    >
                                      <i1 className="fa-solid fa-circle-plus mr-2"></i1>
                                      Add New Notes{store.get('paid') == 0 && <ProBadge />}
                                      <i className="fa-solid fa-arrow-right ml-2 arrow-transition"></i>
                                    </Button>
                                  </div>
                                </>
                              )}
                            >
                              {this.state.document_notes.map((item, index) => {
                                return (
                                  <Option key={index} value={index}>
                                    <span>
                                      <span className="font-weight-bold">{item.label}</span>
                                      <span className="text-gray-500 ml-2">{item.notes}</span>
                                    </span>
                                  </Option>
                                )
                              })}
                            </Select>
                          </span>
                        )}
                      </>
                    }
                    key="1"
                  >
                    <div className="mb-3">
                      <TextArea
                        placeholder="Thanks for your business!"
                        rows={5}
                        name="notes"
                        value={this.state.notes}
                        onChange={e => this.onChange(e)}
                      />
                      {this.state.convert && (
                        <span className="mt-1 font-size-14 text-gray-500">
                          To Show {this.state.from_document_type.replace('_', ' ')}{' '}
                          {this.state.invoiceData.serial_number} notes instead of{' '}
                          {this.state.document_title} notes in document conversion
                          <Popconfirm
                            title="Are you sure want to change notes? Current Changes will be lost"
                            onConfirm={() => this.setState({ notes: this.state.invoiceData.notes })}
                            okText="Yes"
                            cancelText="No"
                          >
                            <Button type="" size="small" className="ml-2">
                              Click Here
                            </Button>
                          </Popconfirm>
                        </span>
                      )}
                    </div>
                    {this.state.isNotesEntered &&
                      !this.state.edit &&
                      this.state.notes !== '' &&
                      this.state.document_notes.every(item => this.state.notes !== item.notes) && (
                        <>
                          {this.state.document_notes.length === 0 ? (
                            <span className="mt-2 text-gray-400">
                              To use the notes in the future{'   '}
                              <a
                                className="text-primary"
                                onClick={() => this.handleSaveNotesAndTerms('notes')}
                              >
                                Click here
                              </a>
                            </span>
                          ) : (
                            <span className="mt-2 text-gray-400">
                              To update notes for selected label{'    '}
                              <a
                                className="text-primary"
                                type="link"
                                loading={this.state.isNotesLoading}
                                onClick={() => this.handleSaveNotesAndTerms('notes')}
                              >
                                {this.state.isNotesLoading ? 'Updating your Notes' : 'Click here'}
                              </a>
                            </span>
                          )}
                        </>
                      )}
                    {showNotes && this.state.isNotesEntered && (
                      <div className="d-flex flex-row my-3">
                        <span className="d-flex flex-column">
                          <Input
                            placeholder="Enter Label"
                            value={notesLabel}
                            onChange={e => this.handleLabelChange('notes', e.target.value)}
                            style={{ width: '300px', marginRight: '10px' }}
                          />
                          {this.state.showNotesLabelError && (
                            <span className="text-danger mt-1 font-size-14">
                              Label is mandatory
                            </span>
                          )}
                        </span>
                        <Button
                          type="primary"
                          onClick={() => this.handleSaveNotesAndTerms('notes', true)}
                          loading={this.state.isNotesLoading}
                        >
                          {this.state.isNotesLoading ? 'Saving Your Changes' : 'Save'}
                        </Button>
                      </div>
                    )}
                  </Panel>
                </Collapse>
                <Collapse
                  accordion
                  className="my-4 bg-gray-50 collapse-small"
                  expandIcon={({ isActive }) => (
                    <span className="">
                      <i
                        className={`fa-solid fa-chevron-down collapse-align-center ${!isActive &&
                          'fa-rotate-270'}`}
                      />
                    </span>
                  )}
                >
                  <Panel
                    header={
                      <>
                        <span className="font-weight-bold">
                          Terms & Conditions{' '}
                          <InfoIcon
                            text="For the same terms to reflect on all invoices, fill in the terms
                          in Settings --&gt; Notes and Terms --&gt; Customer Terms and
                          Conditions"
                          />
                        </span>
                      </>
                    }
                    extra={
                      <>
                        {this.state.document_terms.length == 0 ? (
                          <Button
                            type="secondary"
                            size="small"
                            className="font-weight-bolder"
                            onClick={e => {
                              e.stopPropagation()
                              store.get('paid') == 0
                                ? this.subscriptionPlanRef.current.openModal('new_terms')
                                : this.customNotesRef.current.showDrawer(
                                    this.state.document_type,
                                    0,
                                  )
                            }}
                          >
                            <i className="fa fa-plus mr-1" />
                            New Terms{store.get('paid') == 0 && <ProBadge />}
                          </Button>
                        ) : (
                          <span>
                            <Select
                              suffixIcon={<i className="fa-regular fa-chevron-down"></i>}
                              style={{ width: '300px' }}
                              onChange={e => {
                                let terms = ''
                                if (e) {
                                  terms = this.state.document_terms[e].notes
                                }
                                this.setState({
                                  selected_term: e,
                                  terms,
                                })
                              }}
                              onClick={e => {
                                e.stopPropagation()
                              }}
                              size={'small'}
                              placeholder="Select Terms"
                              className="widthres"
                              dropdownStyle={{ minWidth: isMobile ? '250px' : '500px' }}
                              placement="bottomRight"
                              value={
                                this.state.document_terms.length == 0
                                  ? ''
                                  : this.state.selected_term
                              }
                              allowClear={true}
                              dropdownRender={menu => (
                                <>
                                  {menu}
                                  <div className="bg-gray-75">
                                    <Button
                                      block
                                      size=""
                                      type="link-2"
                                      className="font-weight-bolder text-left my-2 arrow-transition"
                                      onClick={e => {
                                        e.stopPropagation()
                                        store.get('paid') == 0
                                          ? this.subscriptionPlanRef.current.openModal('new_terms')
                                          : this.customNotesRef.current.showDrawer(
                                              this.state.document_type,
                                              0,
                                            )
                                      }}
                                    >
                                      <i1 className="fa-solid fa-circle-plus mr-2"></i1>
                                      Add New Terms{store.get('paid') == 0 && <ProBadge />}
                                      <i className="fa-solid fa-arrow-right ml-2 arrow-transition"></i>
                                    </Button>
                                  </div>
                                </>
                              )}
                            >
                              {this.state.document_terms.map((item, index) => {
                                return (
                                  <Option key={index} value={index}>
                                    <span>
                                      <span className="font-weight-bold">{item.label}</span>
                                      <span className="text-gray-500 ml-2">{item.notes}</span>
                                    </span>
                                  </Option>
                                )
                              })}
                            </Select>
                          </span>
                        )}
                      </>
                    }
                    key="2"
                  >
                    <div className="mb-3">
                      <TextArea
                        rows={5}
                        placeholder="1. ..."
                        value={this.state.terms}
                        name="terms"
                        onChange={e => this.onChange(e)}
                      />
                      {this.state.convert && (
                        <span className="mt-1 font-size-14 text-gray-500">
                          To Show {this.state.from_document_type.replace('_', ' ')}{' '}
                          {this.state.invoiceData.serial_number} terms instead of{' '}
                          {this.state.document_title} terms in document conversion
                          <Popconfirm
                            title="Are you sure want to change terms? Current Changes will be lost"
                            onConfirm={() => this.setState({ terms: this.state.invoiceData.terms })}
                            okText="Yes"
                            cancelText="No"
                          >
                            <Button type="" size="small" className="ml-2">
                              Click Here
                            </Button>
                          </Popconfirm>
                        </span>
                      )}
                    </div>
                    {this.state.isTermsEntered &&
                      !this.state.edit &&
                      this.state.terms !== '' &&
                      this.state.document_terms.every(item => this.state.terms !== item.notes) && (
                        <>
                          {this.state.document_terms.length === 0 ? (
                            <span className="mt-2 text-gray-400">
                              To use the terms in the future{'   '}
                              <a
                                className="text-primary"
                                onClick={() => this.handleSaveNotesAndTerms('terms')}
                              >
                                click here
                              </a>
                            </span>
                          ) : (
                            <span className="mt-2 text-gray-400">
                              To update terms for selected label{'   '}
                              <a
                                className="text-primary"
                                loading={this.state.isTermsLoading}
                                onClick={() => this.handleSaveNotesAndTerms('terms')}
                              >
                                {this.state.isTermsLoading ? 'Updating Your Changes' : 'Click here'}
                              </a>
                            </span>
                          )}
                        </>
                      )}
                    {showTerms && this.state.isTermsEntered && (
                      <div className="d-flex flex-row my-3">
                        <span className="d-flex flex-column">
                          <Input
                            placeholder="Enter Label"
                            value={termsLabel}
                            onChange={e => this.handleLabelChange('terms', e.target.value)}
                            style={{ width: '300px', marginRight: '10px' }}
                          />
                          {this.state.showTermsLabelError && (
                            <span className="text-danger mt-1 font-size-14">
                              Label is mandatory
                            </span>
                          )}
                        </span>
                        <Button
                          type="primary"
                          onClick={() => this.handleSaveNotesAndTerms('terms', true)}
                          loading={this.state.isTermsLoading}
                        >
                          {this.state.isTermsLoading ? 'Saving Your Changes' : 'Save'}
                        </Button>
                      </div>
                    )}
                  </Panel>
                </Collapse>

                {this.props?.user?.selectedCompany?.country_code == 'IN' &&
                  (this.state.document_type == 'invoice' ||
                    (this.state.document_type == 'purchase' && !this.state.export_invoice)) &&
                  this.state.with_tax == 1 && (
                    <div className="d-flex align-items-center justify-content-start">
                      <span className="mt-3 mb-3">
                        <Switch
                          className="mr-2"
                          size="small"
                          checked={this.state.rcm}
                          onChange={this.onRCMChange}
                        />
                        <span className="text-gray-700">
                          Reverse Charge Mechanism applicable? <BetaBadge />
                        </span>
                      </span>
                    </div>
                  )}

                {/* <div className="">
        <Visibility type="custom_fields" className="my-4" visible="true" />
      </div>*/}

                {this.props?.user?.selectedCompany?.country_code == 'IN' && (
                  <div className="">
                    <Visibility type="einvoice+ewaybill" className="my-4" visible="true" />
                  </div>
                )}

                {this.props?.user?.selectedCompany?.country_code == 'IN' &&
                  (this.state.document_type == 'invoice' ||
                    this.state.document_type == 'estimate' ||
                    this.state.document_type == 'delivery_challan') &&
                  store.get('ewaybill') == 1 && (
                    <div className="d-flex align-items-center justify-content-start">
                      <span className="mb-3">
                        <Switch
                          className="mr-2"
                          size="small"
                          checked={this.state.is_ewaybill}
                          onChange={e => {
                            this.setState({ is_ewaybill: e })
                          }}
                        />
                        <span className="text-gray-700">Create E-Waybill</span>
                      </span>
                    </div>
                  )}

                {this.props?.user?.selectedCompany?.country_code == 'IN' &&
                  (this.state.document_type == 'invoice' ||
                    this.state.document_type == 'sales_return' ||
                    this.state.document_type == 'purchase_return') &&
                  store.get('einvoice') == 1 && (
                    <div className="d-flex align-items-center justify-content-start">
                      <span className="mb-3">
                        <Switch
                          className="mr-2"
                          size="small"
                          checked={this.state.is_create_einvoice}
                          onChange={e => {
                            this.setState({ is_create_einvoice: e })
                          }}
                        />
                        <span className="text-gray-700">Create E-Invoice</span>
                      </span>
                    </div>
                  )}

                {this.state.document_type != 'online_order' && (
                  <div className="mb-3 mt-4">
                    <h6>
                      <span className="text-gray-500">Attach files</span>
                      <span>
                        <InfoIcon
                          text=" You can attach up to 3 files (3 MB each) to each transaction you
                  create."
                        />
                      </span>
                    </h6>

                    <Upload
                      customRequest={this.customRequest}
                      // onChange={this.handleChange}
                      beforeUpload={this.beforeUpload}
                      fileList={this.state.attachments}
                      maxCount={3}
                      multiple={false}
                      onRemove={this.handleChange}
                    >
                      <Button
                        type="dashed"
                        size="large"
                        disabled={this.state.attachments.length < 3 ? false : true}
                      >
                        <i className="fa-regular fa-arrow-up-from-bracket mr-2"></i>
                        Attach Files (Max: 3)
                      </Button>
                    </Upload>
                  </div>
                )}

                {store.get('online_store') == 1 && (
                  <div className="font-weight-medium mt-4">
                    <Button
                      type="transparent"
                      onClick={() => {
                        if (store.get('paid') == 0) {
                          this.subscriptionPlanRef.current.openModal('coupons')
                          return
                        }
                        this.onCouponsVisible()
                      }}
                    >
                      <i className="fa-light fa-badge-percent mr-2"></i>
                      <span className="text-gray-500">
                        Use Coupons
                        {store.get('online_store') == 0 ? <ProBadge /> : <BetaBadge />}
                      </span>
                    </Button>
                  </div>
                )}
                {/*</Card>
      {/*</div>
      </div>*/}
              </div>

              {(this.state.dataSource.length > -1 || this.state.signatures.length > -1) && (
                <div className="col-12 col-md-6 text-right">
                  {this.state.dataSource.length > -1 && (
                    <Card
                      size="medium"
                      bordered={false}
                      className="border-radius-small bg-card-forest  mb-4"
                    >
                      {/*<div className="card bg-lgreen">
              <div className="card-body">*/}
                      <div className="row mt-2 mb-2">
                        <div className="col-12 col-md-12">
                          <div className="row">
                            {this.state.coupon_details.coupon_id <= 0 ? (
                              <div className="col-12 d-inline-flex flex-row-reverse">
                                <span className="">
                                  <h6 className="font-size-12 text-gray-500 mb-0">
                                    Extra Discount
                                    <InfoIcon
                                      text={
                                        <span>
                                          Extra discount is directly deducted to the total invoice
                                          amount. It does not affect the{' '}
                                          <strong> tax calculations</strong>. To affect tax
                                          calculation give product level discount.
                                        </span>
                                      }
                                    />
                                  </h6>

                                  <InputNumber
                                    addonAfter={
                                      <span className="select-borderless">
                                        <Select
                                          suffixIcon={
                                            <i className="fa-regular fa-chevron-down"></i>
                                          }
                                          value={this.state.exta_discount_type}
                                          onChange={e => this.setState({ exta_discount_type: e })}
                                        >
                                          <Option value="discount">%</Option>
                                          <Option value="amount">
                                            {this.props.user.selectedCompany.currency_symbol}
                                          </Option>
                                        </Select>
                                      </span>
                                    }
                                    onChange={this.onExtraDiscountChange}
                                    style={{
                                      width: '200px',
                                    }}
                                    className="mb-2"
                                    min={0}
                                    value={
                                      this.state.exta_discount_type == 'discount'
                                        ? this.state.extraDiscountPer
                                        : this.state.extraDiscount
                                    }
                                  />
                                </span>
                              </div>
                            ) : (
                              // show coupon applied and amount deducted in text format
                              <div className="col-12 d-flex justify-content-end d-none">
                                <div className="px-3 py-2 border-1  border-success bg-white rounded">
                                  <div>
                                    <span className="text-success font-bold">
                                      <i className="fa-solid fa-badge-percent mr-1"></i>
                                      {this.state.coupon_details.coupon_code}
                                    </span>
                                    {/* change or remove  */}
                                    {
                                      <a
                                        className="text-primary ml-2 font-size-14"
                                        onClick={() => this.onCouponsVisible()}
                                      >
                                        Change
                                      </a>
                                    }
                                    {
                                      <a
                                        className="text-danger ml-2 font-size-14"
                                        onClick={() =>
                                          this.setState({
                                            coupon_details: {
                                              coupon_id: -1,
                                              coupon_code: '',
                                              discount: 0,
                                              message: '',
                                            },
                                            extraDiscount: 0,
                                            extraDiscountPer: 0,
                                          })
                                        }
                                      >
                                        Remove
                                      </a>
                                    }
                                  </div>
                                  <div className="font-size-14 text-muted text-truncate ">
                                    {this.state.coupon_details.message}
                                  </div>
                                </div>
                              </div>
                            )}
                          </div>
                        </div>
                      </div>

                      <div className="row">
                        <div className="col-6 col-md-5 text-left">
                          {
                            <>
                              {this.state.document_custom_additional_charges.map(
                                (item, index) =>
                                  item.total_amount > 0 && (
                                    <h6>
                                      <span className="text-gray-500 ">
                                        {item.name} {item.tax != 0 && '(' + item.tax + '%)'}:
                                      </span>
                                    </h6>
                                  ),
                              )}
                            </>
                          }

                          {this.state.companyDetails.is_composite == 0 && (
                            <h6>
                              <span className="text-gray-500 ">Taxable Amount:</span>
                            </h6>
                          )}

                          {this.state.with_tax == true && (
                            <>
                              <h6>
                                <span className="text-gray-500 ">{this.generateTaxLabel()}</span>

                                {this.props.permissions.is_immovable == 1 &&
                                (this.state.document_type == 'invoice' ||
                                  this.state.document_type == 'sales_return') &&
                                this.state.export_invoice == false ? (
                                  ((this.generateTaxLabel() == 'IGST' &&
                                    this.state.immovable_tax_type == 0) ||
                                    this.state.immovable_tax_type == 1) && (
                                    <Tooltip
                                      title={
                                        this.state.immovable_tax_type == 0
                                          ? `Change to ${this.state.companyDetails.tax_labels.SGST} + ${this.state.companyDetails.tax_labels.CGST}`
                                          : `Change to ${this.state.companyDetails.tax_labels.IGST}`
                                      }
                                    >
                                      <Switch
                                        className="ml-2"
                                        size="small"
                                        checked={this.state.immovable_tax_type == 1}
                                        onChange={e =>
                                          this.setState({
                                            immovable_tax_type: e ? 1 : 0,
                                          })
                                        }
                                      />
                                    </Tooltip>
                                  )
                                ) : (
                                  <></>
                                )}
                              </h6>
                              {getTotalCessAmountV2(this.state.dataSource) != 0 && (
                                <h6>
                                  <span className="text-gray-500 ">Total Cess:</span>
                                </h6>
                              )}
                            </>
                          )}
                          <h6 className="mt-2 mb-2">
                            {this.state.rcm == true && (
                              <span className="mb-4 text-pink">Tax under Reverse Charge:</span>
                            )}
                          </h6>

                          <h6 className="mb-2">
                            <span className="text-gray-500">
                              Round Off{' '}
                              <Switch
                                className="ml-2"
                                size="small"
                                checked={this.state.roundoff}
                                onChange={this.onRoundOffChange}
                              />
                            </span>
                          </h6>
                          {this.state.is_tcs == 1 && (
                            <h6 className="mb-2">
                              <span className="text-gray-500">TCS Amount </span>
                            </h6>
                          )}

                          <h6>
                            <span className="text-gray-900 font-weight-bolder font-size-21">
                              Total Amount:
                            </span>
                          </h6>
                          {this.state.conversion_factor != '' &&
                            this.state.country != 75 &&
                            (this.state.export_invoice || this.state.is_multi_currency) && (
                              <h6>
                                <span className="text-gray-900 font-weight-bolder font-size-15">
                                  Total Amount(
                                  {this.state.currency}):
                                </span>
                              </h6>
                            )}

                          <h6>
                            <span className="text-gray-500">Total Discount:</span>
                          </h6>
                          {this.state.document_type == 'estimate' && (
                            <div className="d-inline-flex align-items-center ml-2 pb-1">
                              <label className="pt-2 font-size-14 font-weight-normal">
                                Hide Totals
                              </label>
                              <Checkbox
                                className="ml-2"
                                style={{ borderColor: '#000' }}
                                checked={this.state.hide_totals}
                                onChange={e => this.setState({ hide_totals: e.target.checked })}
                              ></Checkbox>
                            </div>
                          )}
                        </div>

                        <div className="col-6 col-md-7 text-right">
                          {
                            <>
                              {this.state.document_custom_additional_charges.map(
                                (item, index) =>
                                  item.total_amount > 0 && (
                                    <h6>
                                      <span className=" text-gray-500">
                                        {item.type == -1 ? '(-) ' : ''}
                                        <span className="mr-1 text-gray-500">
                                          {this.props?.user?.selectedCompany?.currency_symbol}
                                        </span>
                                        {Number(item.net_amount).toLocaleString(
                                          'en-IN',
                                          dotOptions,
                                        )}
                                      </span>
                                    </h6>
                                  ),
                              )}
                            </>
                          }

                          {this.state.companyDetails.is_composite == 0 && (
                            <h6>
                              <span className=" text-gray-500">
                                <span className="mr-1 text-gray-500">
                                  {this.props?.user?.selectedCompany?.currency_symbol}
                                </span>
                                {(
                                  getTotalNetAmountV2(this.state.dataSource) +
                                  this.extraChargesAmount('net_amount')
                                ).toLocaleString('en-IN', dotOptions)}
                              </span>
                            </h6>
                          )}

                          {this.state.with_tax == true && (
                            <>
                              <h6>
                                <span className=" text-gray-500">
                                  <span className="mr-1 text-gray-500">
                                    {this.props?.user?.selectedCompany?.currency_symbol}
                                  </span>
                                  {Number(
                                    parseFloat(this.getTotalTax()) +
                                      this.extraChargesAmount('tax_amount') -
                                      getTotalCessAmountV2(this.state.dataSource),
                                  ).toLocaleString('en-IN', dotOptions)}
                                </span>
                              </h6>
                              {getTotalCessAmountV2(this.state.dataSource) != 0 && (
                                <h6>
                                  <span className=" text-gray-500">
                                    <span className="mr-1 text-gray-500">
                                      {this.props?.user?.selectedCompany?.currency_symbol}
                                    </span>
                                    {Number(
                                      parseFloat(getTotalCessAmountV2(this.state.dataSource)),
                                    ).toLocaleString('en-IN', dotOptions)}
                                  </span>
                                </h6>
                              )}
                            </>
                          )}

                          <h6 className="mt-2 mb-2">
                            {this.state.rcm == true && (
                              <span className=" mb-4 text-pink">
                                <span className="mr-1">
                                  {this.props?.user?.selectedCompany?.currency_symbol}
                                </span>
                                {this.state.rcm_value.toLocaleString('en-IN', dotOptions)}
                              </span>
                            )}
                          </h6>

                          <h6 className="mb-2">
                            {this.state.roundoff_value != 0 && (
                              <span className="text-gray-500 ">
                                {-1 *
                                  Number(this.state.roundoff_value).toLocaleString(
                                    'en-IN',
                                    dotOptions,
                                  )}
                              </span>
                            )}
                            {this.state.roundoff_value == 0 && (
                              <span className="text-gray-500 ">0.00</span>
                            )}
                          </h6>
                          {this.state.is_tcs == 1 && (
                            <h6 className="mb-2 text-gray-500">
                              {this.state.tcs_amount.toLocaleString('en-IN', dotOptions)}
                            </h6>
                          )}
                          <h6>
                            <span className="font-size-21 text-gray-900 font-weight-bolder">
                              {' '}
                              <span className="mr-1">
                                {this.props?.user?.selectedCompany?.currency_symbol}
                              </span>
                              {this.getTotalAmount().toLocaleString('en-IN', dotOptions)}
                            </span>
                          </h6>
                          {this.state.conversion_factor != '' &&
                            this.state.country != 75 &&
                            (this.state.export_invoice || this.state.is_multi_currency) && (
                              <h6>
                                <span className="text-gray-900 font-weight-bolder">
                                  {' '}
                                  {this.state.currency_symbol}
                                  {(
                                    this.getTotalAmount() /
                                    (this.getTotalAmount() != 0
                                      ? parseFloat(this.state.conversion_factor)
                                      : 1)
                                  ).toFixed(2)}
                                </span>
                              </h6>
                            )}

                          <h6>
                            <span className=" text-gray-500 font-weight-bolder">
                              <span className="fa mr-1 text-gray-500">
                                {this.props?.user?.selectedCompany?.currency_symbol}
                              </span>
                              {(
                                Number(this.state.extraDiscount) +
                                getTotalDiscountV2(
                                  this.state.dataSource,
                                  this.state.document_discount_type,
                                )
                              ).toLocaleString('en-IN', dotOptions)}
                            </span>
                          </h6>
                        </div>
                      </div>
                    </Card>
                  )}

                  {this.state.document_type != 'online_order' && (
                    <>
                      <SectionHeader
                        title={
                          this.state.document_type == 'invoice' ||
                          this.state.document_type == 'purchase'
                            ? 'Add payment (Payment Notes, Amount and Mode)'
                            : 'Add Bank Details'
                        }
                      />
                      <Card
                        size="medium"
                        bordered={false}
                        className="bg-card-indigo border-radius-small mb-4"
                      >
                        <div className="mb-3 text-left">
                          <>
                            <h6>
                              <span className="mr-1">Select Bank</span>
                              <span className="mr-2">
                                <InfoIcon
                                  text={
                                    <span>
                                      <span>
                                        Select existing Bank details using the below dropdown.
                                      </span>
                                      <br />
                                      <span>
                                        Please click on <strong>Add New Bank</strong> to add new
                                        Bank details.
                                      </span>
                                    </span>
                                  }
                                />
                              </span>
                            </h6>
                            {this.state.bank_details.length > 1 && (
                              <Select
                                suffixIcon={<i className="fa-regular fa-chevron-down"></i>}
                                placeholder="Select Bank"
                                optionFilterProp="children"
                                style={{ width: '100%' }}
                                onChange={e => {
                                  const selectedBank = this.state.bank_details.find(
                                    ({ id }) => id === e,
                                  )
                                  this.setState({
                                    selected_bank: selectedBank,
                                  })
                                  if (selectedBank.bank_name == 'Cash') {
                                    this.setState({
                                      paymentMethod: 'Cash',
                                    })
                                  } else if (this.state.paymentMethod == 'Cash') {
                                    this.setState({
                                      paymentMethod: this.props?.document?.paymentModes?.[0]?.value,
                                    })
                                  }
                                }}
                                value={this.state.selected_bank?.id}
                                defaultValue={this.state.selected_bank?.id}
                                filterOption={(input, option) =>
                                  option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                }
                                dropdownRender={menu => (
                                  <>
                                    {menu}
                                    <div className="bg-gray-75">
                                      <Button
                                        block
                                        size=""
                                        type="link-2"
                                        className="font-weight-bolder text-left my-2 arrow-transition"
                                        onMouseDown={e => e.preventDefault()}
                                        onClick={() => this.bankForm.onAddFromInvoicePage()}
                                      >
                                        <i1 className="fa-solid fa-circle-plus mr-2"></i1>
                                        Add New Bank
                                        <i className="fa-solid fa-arrow-right ml-2 arrow-transition"></i>
                                      </Button>
                                    </div>
                                  </>
                                )}
                                id="selectBank"
                              >
                                {this.state.bank_details.map((item, i) => (
                                  <Option key={i} value={item.id}>
                                    {item.bank_name + ' '}{' '}
                                    {item.bank_no != '' && '(' + item.bank_no + ')'}
                                  </Option>
                                ))}
                              </Select>
                            )}
                          </>

                          {this.state.bank_details.length <= 1 &&
                            this.props.permissions.bank_details == 1 && (
                              <Button
                                type="dashed"
                                primary
                                large
                                onClick={() => this.bankForm.onAddFromInvoicePage()}
                                block
                                style={{
                                  color: '#FC3654',
                                  whiteSpace: 'normal',
                                  height: 'auto',
                                }}
                              >
                                <br />
                                <i
                                  className="fa fa-university mr-2 font-size-18"
                                  style={{ color: '#FC3654' }}
                                />
                                <span
                                  className="font-size-18 font-weight-bold"
                                  style={{ color: '#FC3654' }}
                                >
                                  {' '}
                                  Add Bank to Invoice (Optional)
                                </span>
                                <br />
                                <br />
                              </Button>
                            )}
                        </div>

                        {!this.state.edit &&
                          (this.state.document_type == 'invoice' ||
                            this.state.document_type == 'purchase') &&
                          this.props.permissions.record_payment == 1 && (
                            <>
                              <div>
                                <span className="font-weight-medium float-right mb-3">
                                  <Checkbox
                                    disabled={this.state.rzp_payment_id != ''}
                                    className="mr-2"
                                    onChange={e => this.onChangeFullyPaid(e.target.checked)}
                                  ></Checkbox>
                                  <span>Mark as fully paid</span>
                                </span>
                                <div className="">
                                  <Input.Group>
                                    <Row
                                      gutter={{
                                        sm: 4,
                                        md: 6,
                                      }}
                                    >
                                      <Col span={6}>
                                        <InputNumber
                                          className="text-right mr-2"
                                          style={{ width: '100%' }}
                                          placeholder="Enter Amount"
                                          disabled={
                                            this.state.fullyPaid || this.state.rzp_payment_id != ''
                                          }
                                          onChange={this.onChangeAmountReceived}
                                          onBlur={e => {
                                            if (e.target.value < 0) {
                                              this.setState({
                                                amountReceived: 0,
                                              })
                                              message.error('Amount cannot be negative')
                                            }
                                          }}
                                          value={
                                            this.state.amountReceived == 0
                                              ? undefined
                                              : this.state.amountReceived
                                          }
                                        />
                                      </Col>
                                      <Col span={6}>
                                        <Select
                                          suffixIcon={
                                            <i className="fa-regular fa-chevron-down"></i>
                                          }
                                          className="text-left"
                                          defaultValue={this.state.paymentMethod}
                                          style={{ width: '100%' }}
                                          onChange={this.onChangePaymentMethod}
                                          value={this.state.paymentMethod}
                                        >
                                          {this.props?.document?.paymentModes.map(item => {
                                            if (item.value != 'TDS') {
                                              return (
                                                <Option value={item.value}>{item.value}</Option>
                                              )
                                            }
                                          })}
                                        </Select>
                                      </Col>
                                      <Col span={12}>
                                        <Input
                                          onChange={e =>
                                            this.setState({ paymentNotes: e.target.value })
                                          }
                                          style={{ width: '100%' }}
                                          className="text-left"
                                          placeholder={
                                            'Notes like Advance Received, UTR Number etc..'
                                          }
                                        />
                                      </Col>
                                    </Row>
                                  </Input.Group>
                                  <p className="font-size-14 text-gray-500">
                                    <span className="mr-2 ml-1">Balance Amount</span>
                                    <span className="mr-2">
                                      <span>
                                        <span className="mr-1 font-size-12">
                                          {this.props?.user?.selectedCompany?.currency_symbol}
                                        </span>
                                        {(
                                          Number(this.getTotalAmount()) -
                                          (Number(this.state.amountReceived) +
                                            Number(this.state.tds_amount))
                                        ).toLocaleString('en-IN', dotOptions)}
                                      </span>
                                    </span>
                                  </p>
                                </div>
                              </div>
                            </>
                          )}
                        {this.props?.user?.selectedCompany?.country_code == 'IN' &&
                          (this.state.document_type == 'invoice' ||
                            this.state.document_type == 'sales_return' ||
                            this.state.document_type == 'purchase' ||
                            this.state.document_type == 'purchase_return' ||
                            this.state.document_type == 'subscription') && (
                            <div className="d-flex align-items-center justify-content-start">
                              <span className="mb-3">
                                <Switch
                                  // disabled={this.state.payment_status != 'pending'}
                                  className="mr-2"
                                  size="small"
                                  checked={this.state.is_tds}
                                  onChange={e => {
                                    if (e && this.state.is_tcs) {
                                      message.error('TCS and TDS cannot be applied together')
                                      return
                                    }
                                    this.setState({ is_tds: e })
                                    if (!e) {
                                      this.setState(
                                        {
                                          tds_amount: 0,
                                          tds_selected_section: undefined,
                                        },
                                        () => this.onChangeFullyPaid(this.state.fullyPaid),
                                      )
                                    }
                                  }}
                                />
                                <span className="text-gray-700">TDS applicable?</span>
                              </span>
                            </div>
                          )}

                        {this.props?.user?.selectedCompany?.country_code == 'IN' &&
                          this.state.is_tds == 1 &&
                          (this.state.document_type == 'invoice' ||
                            this.state.document_type == 'sales_return' ||
                            this.state.document_type == 'purchase' ||
                            this.state.document_type == 'purchase_return' ||
                            this.state.document_type == 'subscription') && (
                            <Input.Group>
                              <Row
                                gutter={{
                                  sm: 4,
                                  md: 6,
                                }}
                                className="mb-3"
                              >
                                <Col span={16}>
                                  <Select
                                    // disabled={this.state.payment_status != 'pending'}
                                    showSearch
                                    className="text-left"
                                    placeholder="Select TDS Section"
                                    optionFilterProp="children"
                                    style={{ width: '100%', whiteSpace: 'nowrap' }}
                                    value={this.state.tds_selected_section}
                                    filterOption={(input, option) =>
                                      (
                                        option.props.children.props.children[0].props.children +
                                        ' ' +
                                        option.props.children.props.children[1].props.children +
                                        ' ' +
                                        option.props.children.props.children[2].props.children
                                      )
                                        .toLowerCase()
                                        .indexOf(input.toLowerCase()) >= 0
                                    }
                                    onChange={e => this.onChangeTdsTcsSection(e, 1)}
                                  >
                                    {this.props?.tds_sections?.map((item, i) => (
                                      <Option key={i} value={item.tds_id}>
                                        <div style={{ whiteSpace: 'normal' }}>
                                          <span className="text-gray-700 font-weight-bold mr-1">
                                            {item.tax + '%'}
                                          </span>
                                          <span className="mr-2 font-weight-bold text-g ray-700">
                                            {item.section}
                                          </span>
                                          <span className="text-gray-500">{item.name}</span>
                                        </div>
                                      </Option>
                                    ))}
                                  </Select>
                                </Col>
                                <Col span={8}>
                                  <Input
                                    disabled={true}
                                    placeholder={'TDS amount'}
                                    type={'number'}
                                    value={this.state.tds_amount}
                                  />
                                </Col>
                              </Row>
                            </Input.Group>
                          )}
                        {this.props?.user?.selectedCompany?.country_code == 'IN' &&
                          (this.state.document_type == 'invoice' ||
                            this.state.document_type == 'sales_return' ||
                            this.state.document_type == 'purchase' ||
                            this.state.document_type == 'purchase_return' ||
                            this.state.document_type == 'subscription') && (
                            <div className="d-flex align-items-center justify-content-between">
                              <span className="mb-3">
                                <Switch
                                  // disabled={this.state.payment_status != 'pending'}
                                  className="mr-2"
                                  size="small"
                                  checked={this.state.is_tcs}
                                  onChange={e => {
                                    if (e && this.state.is_tds) {
                                      message.error('TCS and TDS cannot be applied together')
                                      return
                                    }
                                    this.setState({ is_tcs: e })
                                    if (!e) {
                                      this.setState(
                                        {
                                          tcs_amount: 0,
                                          tcs_selected_section: undefined,
                                        },
                                        () => this.onChangeFullyPaid(this.state.fullyPaid),
                                      )
                                    }
                                  }}
                                />
                                <span className="text-gray-700">TCS applicable?</span>
                              </span>
                              {this.state.is_tcs == 1 && (
                                <span>
                                  <Select
                                    placeholder="Apply On"
                                    className="text-left"
                                    style={{ width: '130px' }}
                                    value={this.state.tcs_applicable_on}
                                    onChange={e => this.onChangeTcsApplicable(e)}
                                  >
                                    <Option value={'net_amount'}>Net Amount</Option>
                                    <Option value={'total_amount'}>Total Amount</Option>
                                  </Select>
                                </span>
                              )}
                            </div>
                          )}
                        {this.props?.user?.selectedCompany?.country_code == 'IN' &&
                          this.state.is_tcs == 1 &&
                          (this.state.document_type == 'invoice' ||
                            this.state.document_type == 'sales_return' ||
                            this.state.document_type == 'purchase' ||
                            this.state.document_type == 'purchase_return' ||
                            this.state.document_type == 'subscription') && (
                            <Input.Group>
                              <Row
                                gutter={{
                                  sm: 4,
                                  md: 6,
                                }}
                                className="my-3"
                              >
                                <Col span={16}>
                                  <Select
                                    // disabled={this.state.payment_status != 'pending'}
                                    showSearch
                                    className="text-left"
                                    placeholder="Select TCS Section"
                                    optionFilterProp="children"
                                    style={{
                                      width: '100%',
                                      whiteSpace: 'nowrap',
                                    }}
                                    value={this.state.tcs_selected_section}
                                    filterOption={(input, option) =>
                                      (
                                        option.props.children.props.children[0].props.children +
                                        ' ' +
                                        option.props.children.props.children[1].props.children +
                                        ' ' +
                                        option.props.children.props.children[2].props.children
                                      )
                                        .toLowerCase()
                                        .indexOf(input.toLowerCase()) >= 0
                                    }
                                    onChange={e => this.onChangeTdsTcsSection(e, 0)}
                                  >
                                    {this.props?.tcs_sections?.map((item, i) => (
                                      <Option key={i} value={item.tds_id}>
                                        <div style={{ whiteSpace: 'nowrap' }}>
                                          <span className="text-gray-700 font-weight-bold mr-1">
                                            {item.tax + '%'}
                                          </span>
                                          <span className="mr-2 font-weight-bold text-gray-700">
                                            {item.section}
                                          </span>
                                          <span className="text-gray-500">{item.name}</span>
                                        </div>
                                      </Option>
                                    ))}
                                  </Select>
                                </Col>
                                <Col span={8}>
                                  <Input
                                    disabled={true}
                                    placeholder={'TCS amount'}
                                    type={'number'}
                                    value={this.state.tcs_amount}
                                  />
                                </Col>
                              </Row>
                            </Input.Group>
                          )}
                      </Card>
                    </>
                  )}

                  {/*Select signature*/}
                  {this.state.document_type != 'online_order' && (
                    <>
                      <SectionHeader
                        title="Select Signature"
                        link={true}
                        linkTo={() => this.signatureForm.onAdd()}
                        linkText="Add New Signature"
                        video_name={'add_signature'}
                      />
                      <Card
                        size="medium"
                        bordered={false}
                        className="border-radius-small bg-card-magenta  mb-4"
                      >
                        <div className="row">
                          <div className="col-12 col-md-12 text-left">
                            <div className="mb-3">
                              {this.state.signatures.length > 1 && (
                                <Select
                                  suffixIcon={<i className="fa-regular fa-chevron-down"></i>}
                                  placeholder="Select Signature"
                                  optionFilterProp="children"
                                  style={{ width: '100%' }}
                                  onChange={e =>
                                    this.setState({
                                      selectedSignature: this.state.signatures.find(
                                        ({ id }) => id === e,
                                      ),
                                    })
                                  }
                                  value={this.state.selectedSignature.signature_name}
                                  defaultValue={this.state.selectedSignature.id}
                                  filterOption={(input, option) =>
                                    option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                  }
                                  dropdownRender={menu => (
                                    <>
                                      {menu}
                                      <div className="bg-gray-75">
                                        <Button
                                          block
                                          size=""
                                          type="link-2"
                                          className="font-weight-bolder text-left my-2 arrow-transition"
                                          onClick={() => this.signatureForm.onAdd()}
                                        >
                                          <i1 className="fa-solid fa-circle-plus mr-2"></i1>
                                          Add New Signature
                                          <i className="fa-solid fa-arrow-right ml-2 arrow-transition"></i>
                                        </Button>
                                      </div>
                                    </>
                                  )}
                                >
                                  {this.state.signatures.map((item, i) => (
                                    <Option key={i} value={item.id}>
                                      {item.signature_name}
                                    </Option>
                                  ))}
                                </Select>
                              )}

                              {this.state.signatures.length <= 1 && (
                                <Button
                                  type="dashed"
                                  primary
                                  large
                                  onClick={() => this.signatureForm.onAdd()}
                                  block
                                  style={{
                                    color: '#FC3654',
                                    whiteSpace: 'normal',
                                    height: 'auto',
                                  }}
                                >
                                  <br />
                                  <i
                                    className="fa fa-pencil mr-2 font-size-18"
                                    style={{ color: '#FC3654' }}
                                  />
                                  <span
                                    className="font-size-18 font-weight-bold"
                                    style={{ color: '#FC3654' }}
                                  >
                                    {' '}
                                    Add Signature to Invoice (Optional)
                                  </span>
                                  <br />
                                  <br />
                                </Button>
                              )}
                            </div>
                            <div className="text-right">
                              <h6>Signature on the document</h6>
                              <img
                                src={this.state.selectedSignature.image}
                                style={{ height: '100px' }}
                              />
                            </div>
                          </div>
                        </div>
                        {/*</div>
        </div>*/}
                      </Card>
                    </>
                  )}
                </div>
              )}
            </div>

            <TutorialVideoModal ref={this.tutorialRef} video_name={'add_signature'} />

            <Affix offsetBottom={9} onChange={this.affix}>
              <div className="px-5 mx-2">
                {/*<div className="row mt-3 border-t-gray-100"></div>*/}
                <Card
                  size="medium"
                  bordered={true}
                  className="border-radius-small bg-card-white card-shadow-3 mb-4"
                >
                  <div className={`d-flex align-items-center justify-content-between`}>
                    {' '}
                    {/*${
            this.state.searchVisible ? 'bg-white' : 'bg-white'
          }*/}
                    <ul className="list-group">
                      {this.getTotal() > 0 && (
                        <li className="list-group-item d-flex justify-content-between align-items-center mb-0 bg-none">
                          <span className="text-gray-500 font-weight-bold font-size-18 mr-5">
                            TOTAL
                          </span>
                          <span className="text-gray-900 font-size-18 font-weight-bolder text-right">
                            {this.state.export_invoice || this.state.is_multi_currency ? (
                              this.state.currency_symbol
                            ) : (
                              <span className="mr-1">
                                {this.props?.user?.selectedCompany?.currency_symbol}
                              </span>
                            )}
                            {(this.getTotalAmount() / this.state.conversion_factor).toLocaleString(
                              'en-IN',
                              dotOptions,
                            )}
                          </span>
                        </li>
                      )}
                      {this.state.with_tax == true && this.getTotalTax() > 0 && (
                        <li className="list-group-item d-flex justify-content-between align-items-center mb-0">
                          <span className="text-gray-500 font-weight-bold font-size-12 mr-2">
                            INCL. {this.generateTaxLabel()}
                          </span>
                          <span className="text-gray-500 font-size-12 font-weight-bolder">
                            {this.state.export_invoice || this.state.is_multi_currency ? (
                              this.state.currency_symbol
                            ) : (
                              <span className="fa mr-1 text-gray-500">
                                {this.props?.user?.selectedCompany?.currency_symbol}
                              </span>
                            )}
                            {Number(
                              (parseFloat(this.getTotalTax()) +
                                this.extraChargesAmount('tax_amount') -
                                getTotalCessAmountV2(this.state.dataSource)) /
                                this.state.conversion_factor,
                            ).toLocaleString('en-IN', dotOptions)}
                          </span>
                        </li>
                      )}
                      {this.state.with_tax == true &&
                        getTotalCessAmountV2(this.state.dataSource) != 0 && (
                          <li className="list-group-item d-flex justify-content-between align-items-center mb-0">
                            <span className="text-gray-500 font-weight-bold font-size-12 mr-2 ">
                              Total Cess:
                            </span>
                            <span className="text-gray-500 font-size-12 font-weight-bolder">
                              {this.state.export_invoice || this.state.is_multi_currency ? (
                                this.state.currency_symbol
                              ) : (
                                <span className="fa mr-1 text-gray-500">
                                  {this.props?.user?.selectedCompany?.currency_symbol}
                                </span>
                              )}

                              {Number(
                                parseFloat(getTotalCessAmountV2(this.state.dataSource)) /
                                  this.state.conversion_factor,
                              ).toLocaleString('en-IN', dotOptions)}
                            </span>
                          </li>
                        )}
                    </ul>
                    <div className="text-center ml-auto">
                      {this.state.showDraftMessage ? (
                        <p className="ml-4 mb-0 text-gray-500 font-weight-medium font-size-14 text-center">
                          <LoadingOutlined />
                          <span className="ml-2">Saving as draft ...</span>
                        </p>
                      ) : (
                        <p className="ml-4 mb-0 text-gray-500 font-weight-medium font-size-14 text-center">
                          {' '}
                        </p>
                      )}
                    </div>
                    <div className="ml-auto float-right">
                      <Button
                        loading={this.state.loadingSave}
                        onClick={() => this.save('1')}
                        type="primary"
                        size="large"
                        className="font-weight-bold float-right arrow-transition"
                        id="saveDocumentButton"
                      >
                        Save
                        <i className="fa-solid fa-arrow-right ml-2 arrow-transition"></i>
                      </Button>

                      {this.state.customerData.length < 2 &&
                        !(this.state.is_ewaybill || this.state.is_create_einvoice) &&
                        this.state.document_type != 'subscription' && (
                          <Button
                            loading={this.state.loadingSave}
                            onClick={() => this.save('2')}
                            type="secondary"
                            size="large"
                            className="font-weight-bold float-right d-none d-sm-block mx-3"
                            id="saveAndPrintDocumentButton"
                          >
                            Save and Print
                          </Button>
                        )}
                      {this.state.document_type != 'subscription' &&
                        !(this.state.is_ewaybill || this.state.is_create_einvoice) &&
                        !this.state.edit &&
                        this.state.customerData.length < 2 && (
                          <Button
                            loading={this.state.loadingSave}
                            type="secondary"
                            size="large"
                            className="font-weight-bold float-right d-none d-sm-block"
                            onClick={() => this.save('1', true)}
                            id="saveAsDraftButton"
                          >
                            Save as Draft <BetaBadge />
                          </Button>
                        )}
                    </div>
                  </div>
                </Card>
              </div>
            </Affix>
          </div>
        )}
      </>
    )
    const invoiceModal = (
      <>
        <Modal
          title={
            <>
              <i className="fad fa-info-circle text-danger font-size-22 mr-3"></i>Confirm
            </>
          }
          open={this.state.visible}
          closable
          destroyOnClose
          closeIcon={<i className="fa-solid fa-lg fa-xmark"></i>}
          onCancel={() =>
            this.setState({
              visible: false,
            })
          }
          footer={
            <div className="d-flex w-100 justify-content-end">
              <Button
                type="danger font-weight-bold"
                onClick={() => this.deleteAllDrafts()}
                loading={this.state.loadingSave}
              >
                Don't Save
              </Button>
              <Button
                className="d-none d-sm-block font-weight-bold ml-2"
                type="primary"
                loading={this.state.loadingSave}
                onClick={() => this.saveAsDraft()}
              >
                Save as Draft
              </Button>
            </div>
          }
        >
          <div className="ml-4 pl-3 mt-4">
            <p className="mb-4 mt-4 pb-4 font-size-18 font-weight-medium">
              Any unsaved changes will be lost. <br />
              Do you want to save them in drafts?
            </p>
          </div>
        </Modal>
      </>
    )
    return (
      <Fragment>
        <Helmet title={this.state.document_title + ' - Swipe'} />
        {/* {this.state.loaded == false && <Lottie options={defaultOptions} height={400} width={400} />} */}

        {/* warning popup */}
        <div>
          <Modal
            title={
              <>
                <i className="fa fa-exclamation-triangle text-warning mr-1" />
                Warning
              </>
            }
            open={this.state.warning.show_warning}
            onOk={() =>
              this.setState(
                {
                  warning: { ...this.state.warning, show_warning: false, skip_warning: true },
                },
                () => this.save('1', false, false, true),
              )
            }
            onCancel={() =>
              this.setState(
                {
                  warning: { ...this.state.warning, show_warning: false },
                  loadingSave: false,
                  loadingPrint: false,
                },
                () => {
                  if (this.state.new_serial_number != '') {
                    if (this.docNumberRef.current) {
                      this.docNumberRef.current.style.border = '2px solid red'
                    }
                  }
                },
              )
            }
            okText="Continue"
            cancelText={
              this.state.new_serial_number != '' && this.state.new_doc_number != 0
                ? 'Change Manually'
                : 'Close'
            }
            closable={false}
            maskClosable={false}
            cancelButtonProps={{ type: 'danger' }}
          >
            <div>
              <p className="font-weight-medium">{this.state.warning.message}</p>
              <p className="text-danger">
                Click on <b>Continue</b> if you still want to save this document{' '}
                {this.state.new_doc_number != 0 ? 'with the new serial number' : ''}.
              </p>
            </div>
          </Modal>
        </div>

        {this.state.loaded == false && (
          <Spin
            indicator={
              <LoadingOutlined
                spin
                className="align-center text-center font-weight-bolder"
                style={{ position: 'absolute', top: '50%', left: '50%', fontSize: '48px' }}
              />
            }
          />
        )}
        {this.state.edit ||
        window.location.href.includes('draft') ||
        window.location.href.includes('convert') ? (
          <>
            {this.state.loaded && (
              <>
                <Space align="center" className="mb-2">
                  <i
                    className="fa-solid fa-sharp fa-chevron-left cursor-pointer"
                    onClick={() => this.leaveChanges()}
                  />
                  <h5 className="mb-0">
                    {!this.state.edit && (
                      <span className="font-weight-bold">Create {this.state.document_title}</span>
                    )}
                    {this.state.edit && (
                      <span className="font-weight-bold">Update {this.state.document_title}</span>
                    )}
                  </h5>
                </Space>
                <Card size="document" className="" bordered={true}>
                  {invoiceComponent}
                </Card>
              </>
            )}
          </>
        ) : (
          <>
            {this.state.loaded && (
              <Tabs
                // hideAdd={this.state.items.length >= 5}
                hideAdd={true}
                type="editable-card"
                tabBarExtraContent={{
                  left: (
                    <Space align="center" className="mx-3">
                      <i
                        className="fa-solid fa-sharp fa-chevron-left cursor-pointer"
                        onClick={() => this.leaveChanges()}
                      />
                      <h5 className="mb-0">
                        {!this.state.edit && (
                          <span className="font-weight-bold">
                            Create {this.state.document_title}
                          </span>
                        )}
                        {this.state.edit && (
                          <span className="font-weight-bold">
                            Update {this.state.document_title}
                          </span>
                        )}
                      </h5>
                    </Space>
                  ),
                  right: (
                    <div className="ml-auto d-none d-sm-block">
                      <a_link
                        onClick={e => {
                          store.get('paid') == 0
                            ? this.subscriptionPlanRef.current.openModal('custom_fields')
                            : this.custom_column.onAddCustomHeaders()
                        }}
                        className="font-weight-medium"
                      >
                        <i className="fa-solid fa-plus-circle mr-2" />
                        Custom Headers
                        {store.get('paid') == 0 && <ProBadge />}
                      </a_link>
                      <a_link
                        onClick={() => this.invoiceSettingsForm.onOpen()}
                        className="font-weight-medium ml-4 pr-2"
                      >
                        <i className="fa-solid fa-gear mr-2" />
                        Settings
                      </a_link>
                    </div>
                  ),
                }}
                onChange={this.onChangeTab}
                activeKey={this.state.activeKey}
                onEdit={this.onEditTab}
                className="mb-0 multi-tab"
              >
                {this.state.items.map((item, index) => (
                  <TabPane tab={item.label} key={item.key} closable={this.state.items.length > 1}>
                    <Card size="document" className="" bordered={true}>
                      {this.state.isCreated && !this.state.is_draft ? (
                        <div
                          style={{
                            display: 'flex',
                            height: '100%',
                            width: '100%',
                            justifyContent: 'center',
                            alignItems: 'center',
                          }}
                        >
                          <Empty
                            className="m-4"
                            image="https://gw.alipayobjects.com/zos/antfincdn/ZHrcdLPrvN/empty.svg"
                            imageStyle={{
                              height: 400,
                            }}
                            description={
                              <span className="font-size-21 font-weight-bold">
                                {this.state.document_title} Created Successfully!{' '}
                              </span>
                            }
                          >
                            <Button
                              type="action"
                              size="large"
                              onClick={() => this.leaveChanges()}
                              loading={this.state.loadingSave}
                            >
                              {' '}
                              <i className="fa fa-credit-card mr-2" /> Go to{' '}
                              {this.state.document_title}
                            </Button>
                          </Empty>
                        </div>
                      ) : (
                        invoiceComponent
                      )}
                    </Card>
                  </TabPane>
                ))}
              </Tabs>
            )}
            {this.state.loaded && (
              <FinancialYearModal
                ref={this.financialYearRef}
                serial_number={this.state.prefix}
                onFinish={this.refreshPrefixes}
              />
            )}
          </>
        )}
        {invoiceModal}
        <ProductForm
          onRef={ref => (this.form = ref)}
          onAPICalled={data => {
            this.table.onAddNew(data)
          }}
        />
        <EnhancedSignatureForm
          onRef={ref => (this.signatureForm = ref)}
          refreshSignatures={this.refreshSignatures}
        />
        <CustomColumnsForm
          onRef={ref => (this.custom_column = ref)}
          onAPICalled={() => {
            this.fetchDetails()
          }}
        />

        <InvoiceSettingsForm onRef={ref => (this.invoiceSettingsForm = ref)} />
        <InvoiceThemeForm onRef={ref => (this.invoiceThemeForm = ref)} />
        <BankForm refreshBanks={this.refreshBanks} onRef={ref => (this.bankForm = ref)} />
        {/* <EnhancedCompanyForm onRef={ref => (this.companyForm = ref)} /> */}
        <EnhancedSubscriptionModal onRef={ref => (this.subscription = ref)} />
        <SubscriptionPlanModal ref={this.subscriptionPlanRef} />
        <EnhancedNICCreds onRef={ref => (this.nic_creds = ref)} />
        <EnhancedEInvoiceNICCreds
          onRef={ref => (this.einvoice_nic_creds = ref)}
          onApiCalled={e => {}}
        />
        <CustomerFrom
          onRef={ref => (this.customer = ref)}
          onAPICalled={(id, name, customerDetails) => {
            let index = this.state.customerData.findIndex((item, index) => item.id == id)
            let customerData = this.state.customerData
            if (index != -1) {
              customerData[index].value = name
              customerData[index].notes = name
              customerData[index].discount = customerDetails.customer_details[0].discount
              customerData[index].phone = ''
            } else {
              customerData.push({
                value: name,
                id: id,
                notes: name,
                phone: '',
                discount: customerDetails.customer_details[0].discount,
              })
            }
            let customerDiscount = 0
            if (customerData.length == 1) {
              customerDiscount = customerData[0].discount
            }
            this.setState({
              customerName: name,
              selectedCustomer: id,
              customerData: customerData,
              customer_details: customerDetails.customer_details[0],
              customerDiscount: customerDiscount,
            })
          }}
          onExistingCustomer={name => {
            this.customer.onClose()
            this.setState({ customerName: name }, () => {
              this.onCustomerSearch(name)
            })
          }}
        />
        <CustomNotes
          ref={this.customNotesRef}
          refreshNotesTerms={() => {
            this.refreshNotesTerms()
          }}
        />
        <DocumentPrefixes ref={this.documentPrefixesRef} onFinish={this.refreshPrefixes} />

        <VendorFrom
          onRef={ref => (this.vendor = ref)}
          onAPICalled={(id, name) => {
            let index = this.state.customerData.findIndex((item, index) => item.id == id)
            let customerData = this.state.customerData
            if (index != -1) {
              customerData[index].value = name
              customerData[index].notes = name
            } else {
              customerData.push({ value: name, id: id, notes: name, phone: '' })
            }

            this.setState({
              customerName: name,
              selectedCustomer: id,
              customerData: customerData,
            })
          }}
          onExistingVendor={name => {
            this.vendor.onClose()
            this.setState({ customerName: name }, () => {
              this.onCustomerSearch(name)
            })
          }}
        />
        {this.state.showEwayBill && (
          <EnhancedEwayBill
            onRef={ref => (this.ewaybill = ref)}
            onCancel={() => {
              this.setState({ showEwayBill: false })
              this.changeRoute()
            }}
          />
        )}

        <EditCompany
          onRef={ref => (this.EditCompanyForm = ref)}
          dispatch={this.props.dispatch}
          onAPICalled={() => {}}
        />
        <LedgerView
          onRef={ref => (this.ledger = ref)}
          apiName={this.state.party_type}
          onAPICalled={() => {}}
          callData={() => {}}
        />
        <PurchaseConfirm
          ref={this.purchaseConfirmRef}
          purchase_update_columns={this.props.document_settings.purchase_update_columns}
          products={this.state.dataSource}
          onPurchaseUpdateConfirm={this.onPurchaseUpdateConfirm}
        />

        <Drawer
          title="Coupons"
          open={this.state.coupons_visible}
          closable={true}
          closeIcon={<i className="fa-solid fa-lg fa-xmark" />}
          onClose={() => this.setState({ coupons_visible: false })}
          width={500}
          destroyOnClose
          bodyStyle={{
            backgroundColor: '#f5f5f5',
          }}
        >
          <div>
            <CouponsList
              onApply={this.onApplyCoupon}
              customers={this.state.customerData}
              total_amount={this.state.total_amount}
            />
          </div>
        </Drawer>
      </Fragment>
    )
  }
}

EditableTable.defaultProps = {
  external_create: false,
}

function mapStateToProps(state, ownProps) {
  const stock_in_warehouses =
    state.warehouse.warehouses?.filter(
      item => state.warehouse.warehouse_permissions?.[item.warehouse_id]?.['stock_in'] == 1,
    ) ?? []
  const stock_out_warehouses =
    state.warehouse.warehouses?.filter(
      item => state.warehouse.warehouse_permissions?.[item.warehouse_id]?.['stock_out'] == 1,
    ) ?? []

  return {
    companyDetailsAdded: state.welcome.company_details_added,
    bankDetailsAdded: state.welcome.bank_details_added,
    productsAdded: state.welcome.products_added,
    invoices_created: state.welcome.invoices_created,
    permissions: state.permissions.permission,
    document: state.document,
    tds_sections: state.document.tdsSections,
    tcs_sections: state.document.tcsSections,
    countries: state.document.countries,
    currencies: state.document.currencies,
    product_categories: state.document.categories,
    port_codes: state.document.portCodes,
    document_settings: state.user.document_settings,
    invoice_settings: state.document.invoiceSettings,
    warehouses: state.warehouse.warehouses,
    custom_columns: state.document.custom_column_data.custom_columns,
    invoice_tabs: state.document.invoice_tabs,
    top_fields: state.document.top_custom_fields,
    user: state.user,
    stock_in_warehouses,
    stock_out_warehouses,
    default_warehouse_id: state.warehouse?.default_warehouse_id,
  }
}

export default connect(mapStateToProps)(EditableTable)
