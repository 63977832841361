import React from 'react'
import { Text, View, Image } from '@react-pdf/renderer'
import { export_terms } from '../export_condition'
import { styles as getStyles } from './styles'
import { EnhancedQRCode } from 'components/other/qrCodeFile'

const InvoiceNotes = ({ invoice, type }) => {
  var styles = getStyles(invoice)

  return (
    <View style={styles.invoiceNotes_blockC}>
      <View style={styles.invoiceNotes_blockCA}>
        {invoice.rcm == 1 && (
          <View>
            <Text style={styles.invoiceNotes_heading}>
              *Amount of Tax subject to Reverse Charge
            </Text>
          </View>
        )}
        {invoice.immovable_tax_type == 1 && (
          <View>
            <Text style={styles.invoiceNotes_heading}>
              *Place of Supply is determined u/s 12(3) of the IGST Act 2017.
            </Text>
          </View>
        )}

        {invoice.is_export == 1 && invoice.company.gstin.length == 15 && (
          <View>
            <Text style={styles.invoiceNotes_heading}>
              *{export_terms[invoice.export_details[0].export_type]}
            </Text>
          </View>
        )}

        {invoice.notes != '' && invoice.notes != 'None' && (
          <View>
            <Text style={styles.invoiceNotes_heading}>Notes:</Text>
            <Text style={styles.invoiceNotes_customerNotes}>{invoice.notes}</Text>
          </View>
        )}

        {invoice.terms != '' && invoice.terms != 'None' && type != 'Delivery Challan' && (
          <View>
            <Text style={styles.invoiceNotes_heading}>Terms and Conditions:</Text>
            <Text style={styles.invoiceNotes_terms}>{invoice.terms}</Text>
          </View>
        )}

        {invoice.invoice_settings.show_receivers_signature == 1 && (
          <View style={styles.invoiceNotes_receiveSignBlock}>
            <Text
              style={{
                ...styles.invoiceNotes_receiveSignText,
              }}
            >
              Receiver's Signature{' '}
            </Text>
          </View>
        )}

        {Object.keys(invoice).includes('einvoice') && invoice.einvoice.length > 0 && (
          <View style={styles.invoiceBankDetails_einvoice_block}>
            <View style={{ width: '24%' }}>
              <View style={styles.invoiceBankDetails_qrcode}>
                <EnhancedQRCode value={`${invoice.einvoice[0].qrcode}`} level="L" />
              </View>
            </View>
            <View style={{ width: '72%' }}>
              <Text style={styles.invoiceBankDetails_label2}>IRN: {invoice.einvoice[0].irn}</Text>
              <Text style={styles.invoiceBankDetails_label2}>
                Acknowledgement Number: {invoice.einvoice[0].ack_no}
              </Text>
            </View>
          </View>
        )}
      </View>

      <View style={styles.invoiceNotes_blockCB} wrap={false}>
        <Text
          style={[
            invoice.company.company_name.length > 40
              ? { fontSize: (6.66 * 40) / invoice.company.company_name.length }
              : styles.invoiceNotes_signatureText,
          ]}
        >
          For {invoice.company.company_name}
        </Text>

        {invoice.signature != '' && invoice.signature != null && (
          <Image
            style={styles.invoiceNotes_signature}
            src={{
              uri: invoice.signature,
              method: 'GET',
              headers: { 'Cache-Control': 'no-cache' },
            }}
            crossorigin="anonymous"
          />
        )}

        <Text
          style={{
            ...styles.invoiceBankDetails_label2,
            marginTop: invoice.signature != '' ? 10 : 40,
          }}
        >
          Authorized Signatory{' '}
        </Text>
      </View>
    </View>
  )
}

export default InvoiceNotes
