import {
  Affix,
  AutoComplete,
  Button,
  Collapse,
  DatePicker,
  Empty,
  Form,
  Input,
  InputNumber,
  Modal,
  Popconfirm,
  Radio,
  Row,
  Select,
  Spin,
  Switch,
  Table,
  Tooltip,
  message,
  notification,
} from 'antd'
import { EnhancedSubscriptionModal } from 'components/modal/subscription'
import { LoadingOutlined } from '@ant-design/icons'
import SubscriptionPlanModal from 'components/modal/subscription/subscriptionPlan'
import {
  getCessAmount,
  getCustomRowNetAmount,
  getCustomRowTotal,
  getDiscountNetValue,
  getDiscountPriceWithTaxValue,
  getDiscountUnitPriceValue,
  getDiscountValue,
  getDiscount_with_tax,
  getItemNetAmount,
  getNetAmount_unit_price,
  getPrice,
  getPriceWithTax,
  getRowTotal,
  getTaxAmount,
  getTotalQty,
} from 'components/other/calculation'
import React, { Component, useRef } from 'react'
import { getAPI, getAPIData } from 'services/jwt'
const { TextArea } = Input

import { CaretRightOutlined, ExclamationCircleOutlined } from '@ant-design/icons'
import CustomColumnsForm from 'components/forms/customColumns'
import ProductForm from 'components/forms/productForm'
import ProductBarcode from 'components/modal/barcode'
import EnhancedProductAnalysis from 'components/modal/productAnalysis'
import MyEditor from 'components/other/editor'
import { EditorState } from 'draft-js'
import { stateToHTML } from 'draft-js-export-html'
import { stateFromHTML } from 'draft-js-import-html'
import 'draft-js/dist/Draft.css'

import { isMobile } from 'react-device-detect'
import store from 'store'

const { Panel } = Collapse

import BatchingForm from 'components/forms/batchingForm'
import InfoIcon from 'components/info/info_icon'
import AddHsn from 'components/modal/addHsn'
import ProductBatches from 'components/modal/batches'
import SelectBatchConvert from 'components/modal/SelectBatchConvert'
import { EnhancedDelete } from 'components/modal/delete'
import TutorialVideoModal from 'components/modal/tutorial'
import dayjs from 'dayjs'
import update from 'immutability-helper'
import { DndProvider, useDrag, useDrop } from 'react-dnd'
import { HTML5Backend } from 'react-dnd-html5-backend'
import { connect } from 'react-redux'
import { EnhancedAdditionalCess } from './additionalCess'
import { capitalizeFirstLetters, get_auto_complete_product_format } from './utility'
import { get_product_options } from 'redux/document/actions'
import { debounce } from 'lodash'

// import { reduceRight } from 'lodash'
// import { object } from 'prop-types'
const type = 'DraggableBodyRow'

const DraggableBodyRow = ({ index, moveRow, className, style, ...restProps }) => {
  // console.log(Array.isArray(restProps.children), 'restProps')
  //canDrag is used to disable drag and drop for the row
  const ref = useRef()
  const [{ isOver, dropClassName }, drop] = useDrop({
    accept: type,
    collect: monitor => {
      const { index: dragIndex } = monitor.getItem() || {}
      if (dragIndex === index) {
        return {}
      }
      return {
        isOver: monitor.isOver(),
        dropClassName: dragIndex < index ? ' drop-over-downward' : ' drop-over-upward',
      }
    },
    drop: item => {
      if (moveRow) moveRow(item.index, index)
    },
  })
  const [, drag] = useDrag({
    type,
    canDrag: Array.isArray(restProps.children),
    item: { index },
    collect: monitor => ({
      isDragging: monitor.isDragging(),
    }),
  })
  drop(drag(ref))

  return (
    <tr
      key={index}
      ref={ref}
      className={`${className}${isOver ? dropClassName : ''}`}
      style={{ cursor: 'move', ...style }}
      {...restProps}
    />
  )
}

const autoCompleteStyle = {
  position: 'relative',
  display: 'inline-block',
}

export class ProductsTable extends Component {
  tutorialRef = React.createRef()
  formRef = React.createRef()
  listInnerRef = React.createRef()
  productBatchesRef = React.createRef()
  serialNumberRef = React.createRef()
  addHsn = React.createRef()
  dateFormat = 'DD-MM-YYYY'
  dateTimeFormat = 'DD-MM-YYYY hh:mm a'
  dateFormatRegex = /^\d{2}-\d{2}-\d{4}$/
  subscriptionPlanRef = React.createRef()
  constructor(props) {
    super(props)
    this.state = {
      count: 0,
      // products: [{ key: this.renderTitle('Add New Product'), value: 'Add New Product' }],
      products: [],
      last_updated_products: [],
      id: -1,
      selectedData: {},
      barcodeData: {},
      edit: false,
      selected: false,
      custom_columns: [],
      // customerDiscount: 0,
      editorState: EditorState.createEmpty(),
      type: 'sales',
      packingChargesTax: 0,
      packingCharges: 0,
      packingChargesWithoutTax: 0,
      packingChargesPer: 0,
      packingChargesTaxAmount: 0,
      transportChargesTax: 0,
      transportCharges: 0,
      transportChargesWithoutTax: 0,
      transportChargesPer: 0,
      transportChargesTaxAmount: 0,
      has_extra_charges: false,
      searchVisible: false,
      searchText: '',
      autoCompleteOpen: false,
      page: 0,
      num_records: 25,
      selected_category: 'All',
      showExportCurrency: false,
      columns_order: [],
      column_id_names: {},
      total_amount_name: 'Total Amount',
      net_amount_name: 'Net Amount',
      qty_name: 'Quantity',
      is_column_linking_failed: false,
      selected_discount_type: 'discount',
      is_discount_percent: 1,
      loading: false,
      product_search_loading: false,
      prevReq: {},
      show_add_products: false,
      search: '',
      default_charges_populated: false,
      purchase_warning_visible: false,
      currentKey: '',
      showSelectBatches: false,
    }
    this.onChange = debounce(this._onChange, 500)
  }

  check_purchase_price_warning = (sale_price, type, data) => {
    var purchase_price = type == 'price_with_tax' ? data.purchase_price : data.purchase_unit_price
    purchase_price = (purchase_price / data.conversion_rate).toFixed(2)
    sale_price = parseFloat(sale_price).toFixed(2)
    let diff = purchase_price - sale_price

    if (
      this.props.document_type == 'invoice' &&
      this.props.document_settings?.restrict_invoice_price_less_than_purchase_price == 1 &&
      !this.props.edit
    ) {
      if (diff > 0 && this.state.purchase_warning_visible == false) {
        this.setState({ purchase_warning_visible: true })
        Modal.confirm({
          title: (
            <>
              <p>{data.product_name + ' ' + data.variant_name}</p>
            </>
          ),
          content: (
            <>
              <p>
                <span className="font-weight-medium mr-2">Selling Price:</span>
                <span className="font-weight-bolder font-size-18">
                  <span className="mr-1 font-size-12">
                    {this.props?.user?.selectedCompany?.currency_symbol}
                  </span>
                  {sale_price}
                </span>
              </p>
              <p>
                <span className="font-weight-medium mr-2">Purchase Price:</span>
                <span className="font-weight-bolder font-size-18">
                  <span className="mr-1 font-size-12">
                    {this.props?.user?.selectedCompany?.currency_symbol}
                  </span>
                  {purchase_price}
                </span>
              </p>
              <p className="text-danger">You are selling it lesser than the purchase price.</p>
              <p className="text-danger">Do you still want to proceed?</p>
            </>
          ),
          icon: <ExclamationCircleOutlined />,
          okText: 'Proceed',
          cancelButtonProps: { style: { display: 'none' } },
          onOk: () => {
            this.setState({ purchase_warning_visible: false })
          },
          onCancel: () => {},
        })
      }
    }
  }
  openSelectBatches = () => {
    this.setState({ showSelectBatches: true })
  }
  closeSelectBatches = () => {
    this.setState({ showSelectBatches: false })
  }

  async componentDidMount() {
    this.setState({
      loading: true,
      is_column_linking_failed: this.props.permissions.custom_columns_link == 0,
    })

    var type = 'sales'
    this.props.onRef(this)
    if (window.location.pathname.includes('purchase')) {
      type = 'purchases'
    }
    if (window.location.pathname.includes('estimate')) {
      type = 'estimates'
    }
    if (this.props.taxes.length == 0) {
      this.props.dispatch(get_product_options())
    }

    // if (this.props.is_column_linking_failed ) {
    //   message.error('Error in Linking of Custom Columns: ' + this.props.failed_names.join(', '))
    // }

    // var resData = await getAPIData('utils', 'custom_columns_v2')
    // let custom_columns = structuredClone(this.props.custom_columns)

    // // if (resData.success) {
    // let total_amount_name = ''
    // let net_amount_name = ''
    // let qty_name = ''
    // custom_columns = custom_columns.map(item => {
    //   if (item.default_key == 'total_amount') {
    //     total_amount_name = item.name
    //   } else if (item.default_key == 'net_amount') {
    //     net_amount_name = item.name
    //   } else if (item.default_key == 'qty') {
    //     qty_name = item.name
    //   }
    //   return item.default_key == 'qty'
    //     ? { ...item, id: 'qty' }
    //     : item.default_key == 'net_amount'
    //     ? { ...item, id: 'net_amount' }
    //     : item.default_key == 'total_amount'
    //     ? { ...item, id: 'total_amount' }
    //     : item
    // })
    // let columns = structuredClone(custom_columns)
    // let remaining_columns = structuredClone(custom_columns)
    // let order = []
    // let column_id_names = {}
    // for (let i = 0; i < columns.length; i++) {
    //   if (columns[i].is_link == 0) {
    //     order.push(String(columns[i].id))
    //   }
    // }

    // remaining_columns = remaining_columns.filter(item => item.is_link == 1)

    // while (remaining_columns.length > 0) {
    //   let len = remaining_columns.length

    //   for (let i = 0; i < remaining_columns.length; i++) {
    //     let expression_ids = remaining_columns[i].expression.split(',')
    //     expression_ids = expression_ids.filter(
    //       item =>
    //         item != '+' &&
    //         item != '-' &&
    //         item != '*' &&
    //         item != '/' &&
    //         item != 'total_amount' &&
    //         item != 'net_amount',
    //     )
    //     const result = expression_ids.every(val => order.includes(val))
    //     if (result) {
    //       order.push(String(remaining_columns[i].id))
    //       remaining_columns = remaining_columns.filter(item => item.id != remaining_columns[i].id)
    //     }
    //   }
    //   if (len == remaining_columns.length) {
    //     let names = remaining_columns.map(item => item.name)
    //     message.error('Error in Linking of Custom Columns: ' + names.join(', '))
    //     this.setState({ is_column_linking_failed: true })
    //     break
    //   }
    // }
    // columns.map(item => {
    //   column_id_names[item.id] = item.name
    // })
    // order = order.map(item =>
    //   item != 'qty' && item != 'net_amount' && item != 'total_amount' ? parseInt(item) : item,
    // )
    // console.log(order)

    this.setState({
      // custom_columns: custom_columns,
      type: type,
      custom_additional_charges: this.props.custom_additional_charges,
      // columns_order: order,
      // column_id_names: column_id_names,
      // total_amount_name: total_amount_name,
      // net_amount_name: net_amount_name,
      // qty_name: qty_name,
    })

    // console.log(order, qty_name, total_amount_name, net_amount_name, 'order')
    // }

    this.onProductSearch('')
    this.setState({ loading: false })
  }

  addProductHsn = async (product_id, hsn_code) => {
    var req = {
      product_id: product_id,
      hsn_code: hsn_code,
    }
    let dataSource = [...this.props.data]
    let index = dataSource.findIndex(item => item.product_id == product_id)
    dataSource[index].hsn_code = hsn_code
    this.props.dataUpdate(dataSource)
    var data = await getAPI('utils', 'add_hsn_code', req)

    message.success(data.message)
    // this.props?.refresh()
    this.onProductSearch(this.state.searchText)
  }

  dataUpdate = (data, type = 'sales') => {
    data.map((item1, index1) => {
      if (item1.custom_column_values) {
        var custom_column_values = item1.custom_column_values.split('::::')
      } else {
        var custom_column_values = []
      }

      if (item1.custom_column_names) {
        var custom_column_names = item1.custom_column_names.split('::::')
      } else {
        var custom_column_names = []
      }

      this.props.custom_columns.forEach((item, index) => {
        if (item.default_value != '') {
          item.name in data[index1]
            ? ''
            : (data[index1][item.name] = data[index1][item.default_value])
        } else {
          if (custom_column_names.includes(item.name)) {
            data[index1][item.name] = custom_column_values[custom_column_names.indexOf(item.name)]
          } else {
            item.name in data[index1] ? console.log('') : (data[index1][item.name] = '')
          }
        }
      })

      try {
        data[index1]['editorState'] = EditorState.createWithContent(
          stateFromHTML(data[index1]['description']),
        )
      } catch (ee) {
        data[index1]['editorState'] = EditorState.createWithContent(stateFromHTML(''))
      }
    })
    this.setState({ data: data, count: data.length })
  }

  updateCustomColumns = data => {
    this.setState({ custom_columns: data })
  }

  edit = async is_edit => {
    this.setState({
      edit: is_edit,
      showExportCurrency: this.props.is_export || this.props.is_multi_currency,
    })
  }

  componentWillUnmount() {
    this.props.onRef(null)
  }

  onProductSelect = (value, object) => {
    if (
      this.props.document_settings.allow_negative_qty == 0 &&
      object.qtyinstock <= 0 &&
      (this.props.document_type == 'invoice' ||
        (this.props.document_settings.track_stock_for_delivery_challan &&
          this.props.document_type == 'delivery_challan')) &&
      ((object['product_type'] == 'Service' &&
        this.props.document_settings.track_stock_for_services == 1) ||
        object['product_type'] != 'Service')
    ) {
      message.error('Billing for this product is not allowed as the quantity is less than 0')
      this.auto.blur()
      this.auto.focus()
    } else {
      if (object.value === 'Add New Product') {
        this.form.onAdd(object['product_name'])
      } else {
        if (object.key != 'null') {
          this.setState({
            selectedData: object,
            searchText: object['product_name'],
            selected: true,
            products: [],
          })

          if (object.has_batches != 0 && this.allowBatches(this.props.document_type)) {
            //check in data
            let product = this.props.data.find(
              item => item.product_id == object.product_id && item.variant_id == object.variant_id,
            )
            if (
              object.has_batches == 1 ||
              (this.props.document_type != 'purchase' && object.has_batches == 2)
            ) {
              if (product) {
                if (product.has_batches == 2) {
                  let serial_numbers = product.serial_numbers.map(item => item.batch_id)
                  product.batches = product.batches.map(item => {
                    if (serial_numbers.includes(item.batch_id)) {
                      item.changed = true
                      item.added_qty = 1
                    }
                    return item
                  })
                }

                this.productBatchesRef.current.showModal(product, this.props.edit)
              } else {
                this.productBatchesRef.current.showModal(object, this.props.edit)
              }
            }
            if (object.has_batches == 2 && this.props.document_type == 'purchase') {
              if (product) {
                this.serialNumberRef.onAdd(product, true, true)
              } else {
                this.serialNumberRef.onAdd(object, true)
              }
            }
            return
          }

          this.qtyInput.focus()
          this.formRef.current.setFieldsValue({
            ...object,
            qty: '',
          })
          if (this.state.show_add_products) {
            this.formRef.current.submit()
          }
        } else {
          // console.log('here')
        }
      }
    }
  }

  renderTitle = title => {
    return <span onClick={() => this.form.onAdd()}>{title}</span>
  }

  doSearch = text => {
    this.setState({
      searchText: text,
      autoCompleteOpen: text == '' ? false : true,
    })
    if (this.timeout) {
      clearTimeout(this.timeout)
    }

    this.timeout = setTimeout(() => {
      this.onProductSearch(typeof text == 'string' ? text : text.target.value)
    }, 500)
  }

  getUnitDetails = (units, unit, unit_id) => {
    let final_unit = ''
    let conversion_rate = 1
    if (unit == null) {
      final_unit = ''
    } else {
      if (units.length > 0) {
        let unit_details = units.filter(item => item.unit_id == unit_id)
        if (unit_details.length > 0) {
          final_unit = unit_details[0].alternative_unit
        } else {
          final_unit = unit
        }
      }
    }

    if (unit_id == 0 || unit_id == null) {
      conversion_rate = 1
    } else {
      if (units.length > 0) {
        let unit_details = units.filter(item => item.unit_id == unit_id)
        if (unit_details.length > 0) {
          conversion_rate = unit_details[0].conversion_rate
        }
      }
    }

    return {
      conversion_rate: conversion_rate,
      unit: final_unit,
    }
  }

  async getProducts(req) {
    const data = await getAPI('product', 'search', req)
    if (data) {
      // console.log(data)
      let allData = data.products.map(value => {
        if (this.props.document_type.includes('purchase') && value.discount != 0) {
          value.discount = 0
        }
        value.actual_price_with_tax = value.price_with_tax
        value.actual_unit_price = value.unit_price
        if (
          this.state.type == 'purchases' &&
          value.purchase_price != 0 &&
          this.props.selectedPriceList == 0
        ) {
          value.price = value.purchase_unit_price
          value.unit_price = value.price
          value.price_with_tax = value.purchase_price
        }
        value.tax = this.props.export_type?.includes('Deemed') ? 0.1 : value.tax
        let unitobj = this.getUnitDetails(value.units, value.unit, value.unit_id)
        return {
          ...value,
          value:
            value.product_name +
            (value.variant_name == '' || value.variant_name == null
              ? ''
              : ' ' + value.variant_name),
          // id: value.product_id,
          id:
            String(value.product_id) +
            '_' +
            String(value.variant_id == undefined ? 0 : value.variant_id),
          description: value.description,
          tax: value.tax,
          qty: 1,
          qtyinstock: value.qty,
          product_name: value.product_name,
          discount: value.discount,
          discount_value: ((value.price_with_tax * value.discount) / 100).toFixed(2),
          discount_price_with_tax_value: ((value.price_with_tax * value.discount) / 100).toFixed(2),
          discount_net_value: ((value.price * value.discount) / 100).toFixed(2),
          discount_unit_price_value: ((value.price * value.discount) / 100).toFixed(2),
          barcode_id: value.barcode_id,
          variant_name: value.variant_name,
          variant_id: value.variant_id ? value.variant_id : 0,
          unit_id: value.unit_id ? value.unit_id : 0,
          unit: unitobj.unit,
          conversion_rate: unitobj.conversion_rate,
        }
      })
      allData = get_auto_complete_product_format(allData, this.props.document_settings)
      this.setState({
        products: [
          ...allData,
          // { key: this.renderTitle('Add New Product'), value: 'Add New Product' },
        ],
        last_updated_products: data.products,
      })
    }
  }

  onProductSearch = async (searchText, item) => {
    if (typeof searchText != 'string') {
      this.formRef.current.setFieldsValue({
        product_name: '',
        value: '',
        qty: '',
      })
      return true
    }

    if (searchText.length > 0) {
      if (searchText.includes('$')) {
        this.onBarCodeScan(searchText, true, {})
      } else {
        const req = {
          query: searchText,
          selected_price_list: this.props.selectedPriceList,
          page: 0,
          num_records: this.state.num_records,
          category: this.state.selected_category,
          document_type: this.props.document_type,
          products_for_sale:
            this.props.document_type == 'invoice' || this.props.document_type == 'sales_return'
              ? true
              : false,
          warehouse_id: this.props.warehouse_id,
        }
        this.setState({ product_search_loading: true, search: searchText })
        const resData = await getAPI('product', 'barcode', req)
        this.setState({ product_search_loading: false })

        if (!('message' in Object(resData))) {
          this.onBarCodeScan(searchText, false, resData)
        } else {
          this.getProducts(req)
        }
      }
    } else {
      const req = {
        query: searchText,
        selected_price_list: this.props.selectedPriceList,
        page: 0,
        num_records: this.state.num_records,
        category: this.state.selected_category,
        document_type: this.props.document_type,
        products_for_sale:
          this.props.document_type == 'invoice' || this.props.document_type == 'sales_return'
            ? true
            : false,
        warehouse_id: this.props.warehouse_id,
      }
      this.getProducts(req)
    }
    this.setState({ page: 0 })
    // this.auto.focus()
  }
  refreshAutocompleteProducts = () => {
    this.onProductSearch(this.state.searchText)
  }

  updateRecord = record => {
    record.price_with_tax = getPriceWithTax(record)
    record.total_amount = getRowTotal(record)
    record.cess_amount = getCessAmount(record)
    record.tax_amount = getTaxAmount(record)

    record['discount_value'] = getDiscountValue(record)
    record['discount_net_value'] = getDiscountNetValue(record)
    record['discount_unit_price_value'] = getDiscountUnitPriceValue(record)
    record['discount_price_with_tax_value'] = getDiscountPriceWithTaxValue(record)

    const dataSource = [...this.props.data]
    const index = dataSource.findIndex(item => item.key === record.key)

    dataSource[index] = record
    this.props.dataUpdate(dataSource)
  }

  updateProductInTable = product => {
    if (product?.variants?.length > 0) {
      return
    }
    Modal.confirm({
      title: 'Do you want to update the prices of this product in the bill?',
      content: 'This will update the prices of the all the occurences of this product in the bill',
      icon: <ExclamationCircleOutlined />,
      okText: 'Yes',

      cancelText: 'Cancel',
      onOk: () => {
        this.props.onUpdateItems(0, 'price_change', product.product_id)
      },
    })
  }

  onBarCodeScan = async (searchText, call_api, resData) => {
    const req = {
      query: searchText.replace('$', ''),
      selected_price_list: this.props.selectedPriceList,
      page: 0,
      num_records: this.state.num_records,
      category: this.state.selected_category,
    }

    if (call_api) {
      var resData = await getAPI('product', 'barcode', req)
    }

    if (resData.success) {
      const { count } = this.state
      const { data } = this.props
      const index = data.findIndex(
        item =>
          item.id ==
          String(resData.products.product_id) +
            '_' +
            String(resData.products.variant_id == undefined ? 0 : resData.products.variant_id),
      )
      if (index == -1) {
        resData.products.tax = this.props.export_type?.includes('Deemed')
          ? 0.1
          : resData.products.tax
        let item = resData.products
        const final_discount = this.props.document_type.includes('purchase')
          ? 0
          : this.state.total_discount != 0 && this.state.total_discount != undefined
          ? this.state.total_discount
          : this.props.customerDiscount == 0
          ? item.discount
          : this.props.customerDiscount
        item.actual_price_with_tax = item.price_with_tax
        item.actual_unit_price = item.unit_price

        if (
          this.state.type == 'purchases' &&
          item.purchase_price != 0 &&
          this.props.selectedPriceList == 0
        ) {
          item.price = item.purchase_price / (1 + (item.tax + item.cess) / 100)
          item.unit_price = item.price
          item.price_with_tax = item.purchase_price
        }

        item.master_data = {
          price_with_tax: item.actual_price_with_tax,
          unit_price: item.unit_price,
          discount: item.discount,
          cess: item.cess,
          tax: item.tax,
          purchase_price: item.purchase_price,
          purchase_unit_price: item.purchase_unit_price,
        }

        if (item.has_batches == 2 && this.props.document_type == 'purchase') {
          this.serialNumberRef.onAdd(item, true)
          return
        }

        if (item.match_reason == 'batch' && this.allowBatches(this.props.document_type)) {
          let serial_numbers = []
          item.batches.map(batch => {
            if (batch.batch_id == item.batch_id) {
              item.batch_no = batch.batch_no
              batch.added_qty = 1
              batch.changed = true
              serial_numbers.push(batch)
            }
          })
          item.serial_numbers = serial_numbers
        }

        const newData = {
          key: count + 1,
          ...item,
          qty: 1,
          id:
            String(item.product_id) +
            '_' +
            String(item.variant_id == undefined ? 0 : item.variant_id),
          value:
            item.product_name +
            (item.variant_name == '' || item.variant_name == null ? '' : ' ' + item.variant_name),
          editorState: EditorState.createWithContent(stateFromHTML(item.description)),
          expanded: true,
          cess_amount: item.unit_price * (item.cess / 100),
          qtyinstock: item.qty,
          discount: final_discount,
          discount_value: ((item.price_with_tax * final_discount) / 100).toFixed(2),
          conversion_rate:
            item.unit_id == 0 || item.unit_id == null
              ? 1
              : item.units
              ? item.units.filter(item => item.unit_id == item.unit_id)?.[0].conversion_rate
              : 1,
          unit:
            item.unit == '' || item.unit == null
              ? ''
              : item.units
              ? item.units.filter(item => item.unit_id == item.unit_id)?.[0].alternative_unit
              : item.unit,
          discount_price_with_tax_value: ((item.price_with_tax * final_discount) / 100).toFixed(2),
          discount_net_value: ((item.unit_price * final_discount) / 100).toFixed(2),
          discount_unit_price_value: ((item.unit_price * final_discount) / 100).toFixed(2),
          selected_discount_type: 'discount',
          is_discount_percent: 1,
          is_cess_on_qty_applicable: false,

          selling_price: item.actual_price_with_tax,
          product_discount: item.discount,
          master_data: {
            price_with_tax: item.actual_price_with_tax,
            unit_price: item.unit_price,
            discount: item.discount,
            cess: item.cess,
            tax: item.tax,
            purchase_price: item.purchase_price,
            purchase_unit_price: item.purchase_unit_price,
          },
        }

        if (item.custom_column_values) {
          var custom_column_values = item.custom_column_values.split('::::')
        } else {
          var custom_column_values = []
        }

        if (item.custom_column_names) {
          var custom_column_names = item.custom_column_names.split('::::')
        } else {
          var custom_column_names = []
        }

        this.props.custom_columns.forEach((item, index) => {
          if (item.default_value != '') {
            item.name in newData
              ? console.log('')
              : (newData[item.name] = newData[item.default_value])
          } else {
            if (custom_column_names.includes(item.name)) {
              newData[item.name] = custom_column_values[custom_column_names.indexOf(item.name)]
            } else {
              item.name in newData ? console.log('') : (newData[item.name] = '')
            }
          }
        })
        if (this.props.is_export || this.props.is_multi_currency) {
          if (isNaN(parseFloat(this.props.conversion_factor))) {
            message.error('Enter conversion factor')
            return true
          }
          newData['export_unit_price'] =
            newData['unit_price'] / parseFloat(this.props.conversion_factor)
          newData['export_price_with_tax'] =
            newData['price_with_tax'] / parseFloat(this.props.conversion_factor)
        }
        let allproducts = [...this.props.data, newData]
        if (this.props.items_reverse) {
          allproducts = [newData, ...this.props.data]
        }

        this.setState({
          count: count + 1,
          // products: [],
          selected: false,
          currentKey: count + 1,
          searchText: '',
          autoCompleteOpen: false,
        })
        this.props.dataUpdate(allproducts)
        this.auto.focus()
      } else {
        if (data[index].has_batches && this.allowBatches(this.props.document_type)) {
          data[index].batches.map(batch => {
            if (batch.batch_id == resData.products.batch_id) {
              batch.added_qty = (batch.added_qty ? batch.added_qty : 0) + 1
              batch.changed = true
            }
          })
          this.onFinishBatchesQty(data[index], data[index].batches)
        } else {
          this.onChange(data[index].qty + 1, data[index].key, 'qty')
        }
        this.setState({
          // products: [],
          selected: false,
          searchText: '',
          autoCompleteOpen: false,
        })
        // this.props.dataUpdate([...data])
        this.auto.focus()
      }

      this.formRef.current.setFieldsValue({
        product_name: '',
        qty: '',
        price: '',
        price_with_tax: '',
        discount: '',
        discount_value: '',
        discount_net_value: '',
        discount_unit_price_value: '',
        discount_price_with_tax_value: '',
        description: '',
        value: '',
      })
    }
  }
  onInputFocus = e => {
    e.target.select()
  }

  onDescriptionChange = (value, key) => {
    const dataSource = [...this.props.data]
    const index = dataSource.findIndex(item => key === item.key)
    dataSource[index].editorState = value
    dataSource[index].description = stateToHTML(value.getCurrentContent())
    this.props.dataUpdate(dataSource, true)
  }

  onCustomChange = (value, key, name, id, productsData = []) => {
    if (name == 'Quantity') {
      name = this.props.qty_name
    }
    const dataSource = productsData.length == 0 ? [...this.props.data] : productsData
    const index = dataSource.findIndex(item => key === item.key)
    dataSource[index][name] = value
    let data = { ...dataSource[index] }

    if (this.props.is_column_linking_failed || this.props.permissions.custom_columns_link == 0) {
      data['net_amount'] = getNetAmount_unit_price(data)
      data['total_amount'] = getRowTotal(data)
      data['tax_amount'] = getTaxAmount(data)
      dataSource[index] = data
      if (productsData.length == 0) {
        this.props.dataUpdate(dataSource)
      }
      return dataSource
    }

    data[this.props.qty_name] = data['qty']
    for (var i = 0; i < this.props.columns_order.length; i++) {
      let selected_column = this.props.custom_columns.filter(
        item => String(item.id) == this.props.columns_order[i],
      )[0]

      let expression = selected_column.expression
      if (expression == '' || this.props.columns_order[i] == id) {
        continue
      }

      let matches = expression.match(/\{[^\}]+\}/g)
      matches.map(item => {
        let id = item.replace('{', '').replace('}', '')
        if (id == 'qty' || id == 'unit_price' || id == 'price_with_tax') {
          expression = expression.replace(item, data[id])
        } else if (id == 'total_amount') {
          let temp_total_amount = 0
          if (selected_column.default_key == 'total_amount') {
            temp_total_amount =
              this.props.with_tax == 1
                ? getCustomRowTotal(data, this.props.discount_type)
                : getCustomRowNetAmount(data, this.props.discount_type)
          } else {
            temp_total_amount =
              this.props.with_tax == 1 ? getRowTotal(data) : getNetAmount_unit_price(data)
          }
          expression = expression.replace(item, temp_total_amount)
        } else if (id == 'net_amount') {
          let temp_net_amount = 0

          if (selected_column.default_key == 'net_amount') {
            temp_net_amount = getCustomRowNetAmount(data, this.props.discount_type)
          } else {
            temp_net_amount = getNetAmount_unit_price(data)
          }
          expression = expression.replace(item, temp_net_amount)
        } else {
          expression = expression.replace(item, data[this.props.column_id_names[id]])
        }
      })

      if (expression != '') {
        try {
          let evaluated_value = eval(expression)
          if (isNaN(evaluated_value)) {
            throw new Error('Invalid expression')
          }

          data[selected_column.name] = evaluated_value

          if (selected_column.name == this.props.qty_name) {
            data['qty'] = data[selected_column.name]
          }
        } catch (err) {
          if (selected_column.default_key == 'total_amount') {
            data[this.props.total_amount_name] =
              this.props.with_tax == 1 ? getRowTotal(data) : getNetAmount_unit_price(data)
          }
          if (selected_column.default_key == 'net_amount') {
            data[this.props.net_amount_name] = getNetAmount_unit_price(data)
          }
        }

        if (selected_column.default_key == 'total_amount') {
          if (typeof data[this.props.total_amount_name] == 'string') {
            data[this.props.total_amount_name] =
              this.props.with_tax == 1
                ? getCustomRowTotal(data, this.props.discount_type)
                : getCustomRowNetAmount(data, this.props.discount_type)
          }
          if (this.props.discount_type == 'net_amount') {
            data[this.props.net_amount_name] =
              data[this.props.total_amount_name] / (1 + (data['tax'] + data['cess']) / 100)
            data['tax_amount'] = (
              (data[this.props.net_amount_name] * (data['tax'] + data['cess'])) /
              100
            ).toFixed(2)
          } else {
            if (this.props.discount_type == 'total_amount') {
              data[this.props.total_amount_name] =
                data[this.props.total_amount_name] * (1 - data['discount'] / 100)
            }
            data[this.props.net_amount_name] =
              data[this.props.total_amount_name] / (1 + (data['tax'] + data['cess']) / 100)
            data['tax_amount'] = (
              (data[this.props.net_amount_name] * (data['tax'] + data['cess'])) /
              100
            ).toFixed(2)
          }
        }

        if (selected_column.default_key == 'net_amount') {
          if (typeof data[this.props.net_amount_name] == 'string') {
            data[this.props.net_amount_name] = getCustomRowNetAmount(data, this.props.discount_type)
          }
          if (this.props.discount_type == 'net_amount') {
            data[this.props.net_amount_name] =
              data[this.props.net_amount_name] * (1 - data['discount'] / 100)
          }

          data[this.props.total_amount_name] =
            data[this.props.net_amount_name] * (1 + (data['tax'] + data['cess']) / 100)

          data['tax_amount'] = (
            (data[this.props.net_amount_name] * (data['tax'] + data['cess'])) /
            100
          ).toFixed(2)
          if (this.props.discount_type == 'total_amount') {
            data[this.props.total_amount_name] =
              data[this.props.total_amount_name] * (1 - data['discount'] / 100)
          }
        }
      }
    }

    data['qty'] = data[this.props.qty_name]

    let is_total_amount = false
    let is_net_amount = false

    this.props.custom_columns.map(item => {
      if (item.name == this.props.total_amount_name && item.expression != '') {
        is_total_amount = true
      }
    })
    this.props.custom_columns.map(item => {
      if (item.name == this.props.net_amount_name && item.expression != '') {
        is_net_amount = true
      }
    })
    if (!is_total_amount && !is_net_amount) {
      data[this.props.total_amount_name] =
        this.props.with_tax == 1 ? getRowTotal(data) : getNetAmount_unit_price(data)
      data[this.props.net_amount_name] = getNetAmount_unit_price(data)
    }

    data['tax_amount'] =
      (data[this.props.net_amount_name] * (data['tax'] + data['cess'])) / 100 +
      (data['cess_on_qty_value'] ? data['cess_on_qty_value'] : 0)
    if (this.props.discount_type == 'net_amount') {
      data['discount_net_value'] = (
        data[this.props.net_amount_name] / (1 - data['discount'] / 100) -
        data[this.props.net_amount_name]
      ).toFixed(2)
    } else if (this.props.discount_type == 'total_amount') {
      data['discount_value'] =
        data[this.props.total_amount_name] / (1 - data['discount'] / 100) -
        data[this.props.total_amount_name]
    } else if (this.props.discount_type == 'unit_price') {
      data['discount_unit_price_value'] = (data['price'] * data['discount']) / 100
    } else {
      data['discount_price_with_tax_value'] = (data['price_with_tax'] * data['discount']) / 100
    }
    data['total_amount'] = data[this.props.total_amount_name].toFixed(2)
    data['net_amount'] = data[this.props.net_amount_name]
    dataSource[index] = data
    let newDataSource = [...dataSource]
    if (productsData.length == 0) {
      this.setState({ data: newDataSource })
      this.props.dataUpdate(newDataSource)
    }
    return newDataSource
  }

  additionalChargeCalculation = (item, name, value) => {
    const total_net_amount = this.getTotalNetAmount() || 1
    item[name] = parseFloat(value)

    let tax = item['tax'] || 0
    let netAmount = item['net_amount'] || 0
    if (name == 'percent') {
      netAmount = parseFloat((total_net_amount * item['percent']) / 100)
      item['net_amount'] = netAmount
    } else if (name == 'total_amount') {
      netAmount = parseFloat(item['total_amount'] / (1 + tax / 100))
      item['net_amount'] = netAmount
    }
    item['tax_amount'] = parseFloat((netAmount * tax) / 100)
    item['total_amount'] = parseFloat(netAmount * (1 + tax / 100))
    if (name != 'percent') {
      item['percent'] = parseFloat((100 * netAmount) / total_net_amount)
    }

    return item
  }
  onChangeAdditionalCharge = (e, additional_charge_id, name) => {
    if (e == null || isNaN(e)) {
      message.error('Enter a valid Number')
      return
    }
    if (name == 'net_amount' || name == 'total_amount') {
      if ((this.props.is_export || this.props.is_multi_currency) && this.state.showExportCurrency) {
        e = e * parseFloat(this.props.conversion_factor)
      }
    }
    const dataSource = [...this.props.document_custom_additional_charges]
    const index = dataSource.findIndex(item => additional_charge_id === item.additional_charge_id)
    dataSource[index] = this.additionalChargeCalculation(dataSource[index], name, e)

    this.props.updateExtraCharges?.({
      document_custom_additional_charges: dataSource,
      has_extra_charges: this.state.has_extra_charges,
    })
  }

  populateChargesDefaultValues = () => {
    const total_net_amount = this.getTotalNetAmount() || 1

    var document_custom_additional_charges = this.props.custom_additional_charges.map(
      (item, index) => {
        return {
          name: item.name,
          additional_charge_id: item.additional_charge_id,
          total_amount: item.amount,
          tax: item.tax,
          tax_amount: item.amount - item.unit_price,
          percent: parseFloat((100 * item.unit_price) / total_net_amount),
          net_amount: item.unit_price,
          sac_code: item.sac_code,
          type: item.type,
          is_active: item.is_active,
        }
      },
    )

    let active_charges = document_custom_additional_charges.filter(item => item.is_active == 1)
    this.setState(
      {
        default_charges_populated: true,
      },
      () => {
        if (active_charges && active_charges.length > 0) {
          this.props.updateExtraCharges?.({
            document_custom_additional_charges: active_charges,
            has_extra_charges: this.state.has_extra_charges,
          })
        }
      },
    )
  }

  updateBatchPrices = product => {
    if (product['has_batches'] == 2 && this.allowBatches(this.props.document_type)) {
      let serial_numbers = product['serial_numbers']?.map(item => item.batch_id)
      product['batches'].map(item => {
        if (serial_numbers.includes(item.batch_id)) {
          item.unit_price = product['unit_price']
          item.price = product['price']
          item.price_with_tax = product['price_with_tax']
        }
      })
    }

    if (product['has_batches'] == 1 && this.allowBatches(this.props.document_type)) {
      product['batches'].map(item => {
        if (product.batch_uid == item.batch_uid) {
          item.unit_price = product['unit_price']
          item.price = product['price']
          item.price_with_tax = product['price_with_tax']
          item.unit = product['unit']
          item.unit_id = product['unit_id']
          item.conversion_rate = product['conversion_rate']
        }
      })
    }

    return product
  }

  _onChange = async (value, key, name) => {
    if (isNaN(value)) {
      message.error('Enter a valid Number')
    } else {
      const dataSource = [...this.props.data]
      const index = dataSource.findIndex(item => key === item.key)
      if (name == 'qty') {
        if (
          dataSource[index]['is_cess_on_qty_applicable'] &&
          dataSource[index]['cess_on_qty_value'] > 0
        ) {
          notification.warning({
            message:
              'Additional Cess will be reset to 0 as quantity is changed. Please re-enter additional cess if applicable.',
            duration: 5,
          })
        }
        dataSource[index][name] = value
      } else {
        if (name == 'discount') {
          if (value > 100) {
            message.error('Discount cannot be greater than 100%')
            return
          }
        }
        if (name == 'discount_unit_price_value') {
          if (value > dataSource[index].unit_price) {
            message.error('Discount amount cannot be greater than ' + dataSource[index].unit_price)
            return
          }
        }
        if (name == 'discount_price_with_tax_value') {
          if (value > dataSource[index].price_with_tax) {
            message.error(
              'Discount amount cannot be greater than ' + dataSource[index].price_with_tax,
            )
            return
          }
        }

        dataSource[index][name] = Number(value)
      }

      if (name == 'price') {
        dataSource[index]['price_with_tax'] = getPriceWithTax(dataSource[index])
        dataSource[index]['discount_value'] = getDiscountValue(dataSource[index])
        dataSource[index]['discount_net_value'] = getDiscountNetValue(dataSource[index])
        dataSource[index]['discount_unit_price_value'] = getDiscountUnitPriceValue(
          dataSource[index],
        )
        dataSource[index]['discount_price_with_tax_value'] = getDiscountPriceWithTaxValue(
          dataSource[index],
        )
        if (this.props.is_export || this.props.is_multi_currency) {
          if (this.state.showExportCurrency) {
            dataSource[index]['export_unit_price'] = Number(value)
            dataSource[index]['unit_price'] = Number(value) * this.props.conversion_factor
            dataSource[index]['price'] = Number(value) * this.props.conversion_factor
            dataSource[index]['price_with_tax'] = getPriceWithTax(dataSource[index])
          } else {
            dataSource[index]['unit_price'] = Number(value)
            dataSource[index]['price'] = Number(value)
            dataSource[index]['export_unit_price'] = Number(value) / this.props.conversion_factor
          }
          dataSource[index]['export_price_with_tax'] =
            dataSource[index]['price_with_tax'] / this.props.conversion_factor
        } else {
          dataSource[index]['unit_price'] = Number(value)
          dataSource[index]['price'] = Number(value)
        }
        dataSource[index] = this.updateBatchPrices(dataSource[index])
      } else if (name == 'price_with_tax') {
        if (this.props.is_export || this.props.is_multi_currency) {
          if (this.state.showExportCurrency) {
            dataSource[index]['price_with_tax'] = Number(value) * this.props.conversion_factor
            dataSource[index]['export_price_with_tax'] = Number(value)
          } else {
            dataSource[index]['export_price_with_tax'] =
              Number(value) / this.props.conversion_factor
          }
        }

        dataSource[index]['price'] = getPrice(dataSource[index])
        dataSource[index]['unit_price'] = getPrice(dataSource[index])
        dataSource[index]['discount_value'] = getDiscountValue(dataSource[index])
        dataSource[index]['discount_net_value'] = getDiscountNetValue(dataSource[index])
        dataSource[index]['discount_unit_price_value'] = getDiscountUnitPriceValue(
          dataSource[index],
        )
        dataSource[index]['discount_price_with_tax_value'] = getDiscountPriceWithTaxValue(
          dataSource[index],
        )
        if (this.props.is_export || this.props.is_multi_currency) {
          dataSource[index]['export_unit_price'] =
            dataSource[index]['unit_price'] / this.props.conversion_factor
        }

        dataSource[index] = this.updateBatchPrices(dataSource[index])
      } else if (name == 'discount_value') {
        dataSource[index]['discount'] = getDiscount_with_tax(
          dataSource[index],
          this.props.with_tax,
          name,
        )
        dataSource[index]['discount_net_value'] = getDiscountNetValue(dataSource[index])
        dataSource[index]['discount_unit_price_value'] = getDiscountUnitPriceValue(
          dataSource[index],
        )
        dataSource[index]['discount_price_with_tax_value'] = getDiscountPriceWithTaxValue(
          dataSource[index],
        )
      } else if (name == 'discount_net_value') {
        dataSource[index]['discount'] = getDiscount_with_tax(
          dataSource[index],
          this.props.with_tax,
          name,
        )
        dataSource[index]['discount_value'] = getDiscountValue(dataSource[index])
        dataSource[index]['discount_unit_price_value'] = getDiscountUnitPriceValue(
          dataSource[index],
        )
        dataSource[index]['discount_price_with_tax_value'] = getDiscountPriceWithTaxValue(
          dataSource[index],
        )
        // dataSource[index]['price_with_tax'] = getPriceWithTax(dataSource[index])
      } else if (name == 'discount_unit_price_value') {
        dataSource[index]['discount'] = getDiscount_with_tax(
          dataSource[index],
          this.props.with_tax,
          name,
        )
        dataSource[index]['discount_value'] = getDiscountValue(dataSource[index])
        dataSource[index]['discount_net_value'] = getDiscountNetValue(dataSource[index])
        dataSource[index]['discount_price_with_tax_value'] = getDiscountPriceWithTaxValue(
          dataSource[index],
        )
        // dataSource[index]['price_with_tax'] = getPriceWithTax(dataSource[index])
      } else if (name == 'discount_price_with_tax_value') {
        dataSource[index]['discount'] = getDiscount_with_tax(
          dataSource[index],
          this.props.with_tax,
          name,
        )
        dataSource[index]['discount_value'] = getDiscountValue(dataSource[index])
        dataSource[index]['discount_unit_price_value'] = getDiscountUnitPriceValue(
          dataSource[index],
        )
        dataSource[index]['discount_net_value'] = getDiscountNetValue(dataSource[index])
        // dataSource[index]['price_with_tax'] = getPriceWithTax(dataSource[index])
      } else if (name == 'discount') {
        dataSource[index]['discount_value'] = getDiscountValue(dataSource[index])
        dataSource[index]['discount_net_value'] = getDiscountNetValue(dataSource[index])
        dataSource[index]['discount_unit_price_value'] = getDiscountUnitPriceValue(
          dataSource[index],
        )
        dataSource[index]['discount_price_with_tax_value'] = getDiscountPriceWithTaxValue(
          dataSource[index],
        )
        // dataSource[index]['price_with_tax'] = getPriceWithTax(dataSource[index])
      } else if (name == 'qty') {
        dataSource[index]['discount_value'] = getDiscountValue(dataSource[index])
        dataSource[index]['discount_net_value'] = getDiscountNetValue(dataSource[index])
        dataSource[index]['qty'] = dataSource[index]['qty'] ? dataSource[index]['qty'] : 0
        dataSource[index][this.props.qty_name] = dataSource[index]['qty']
        if (dataSource[index]['is_cess_on_qty_applicable']) {
          dataSource[index]['cess_on_qty_value'] = 0
          dataSource[index]['cess_amount'] = getCessAmount(dataSource[index])
        }
        dataSource[index]['price_with_tax'] = getPriceWithTax(dataSource[index])
      } else if (name == 'unit_id') {
        let prev_conversion_rate = dataSource[index]['conversion_rate']
        dataSource[index]['unit_id'] = value
        if (dataSource[index]['unit_id'] == 0 || dataSource[index]['unit_id'] == null) {
          dataSource[index]['conversion_rate'] = 1
        } else {
          dataSource[index]['conversion_rate'] = dataSource[index]['units']
            ? dataSource[index]['units'].find(item => item.unit_id == value)?.conversion_rate
            : 1
        }
        if (dataSource[index]['is_cess_on_qty_applicable']) {
          dataSource[index]['cess_on_qty_value'] = 0
          dataSource[index]['cess_amount'] = getCessAmount(dataSource[index])
        }
        dataSource[index]['price'] =
          (dataSource[index]['price'] * prev_conversion_rate) / dataSource[index]['conversion_rate']
        dataSource[index]['unit_price'] = dataSource[index]['price']
        dataSource[index]['price_with_tax'] = getPriceWithTax(dataSource[index])
        dataSource[index]['discount_value'] = getDiscountValue(dataSource[index])
        if (this.props.is_export || this.props.is_multi_currency) {
          dataSource[index]['export_unit_price'] =
            dataSource[index]['unit_price'] / this.props.conversion_factor
        }

        dataSource[index]['unit'] = dataSource[index]['units']
          ? dataSource[index]['units'].filter(
              item => item.unit_id == dataSource[index]['unit_id'],
            )?.[0].alternative_unit
          : dataSource[index]['unit']

        dataSource[index] = this.updateBatchPrices(dataSource[index])
      }

      this.props.dataUpdate(dataSource)
      this.onCustomChange(dataSource[index]['qty'], key, this.props.qty_name, 'qty')
    }
  }
  updateAdditionalCharges = () => {
    if (this.props.document_custom_additional_charges?.length > 0) {
      let change_detected = false
      let additionalChargesData = [...this.props.document_custom_additional_charges]
      additionalChargesData.map((item, i) => {
        if (item.total_amount != 0) {
          change_detected = true
          return {
            ...this.additionalChargeCalculation(item, 'total_amount', item.total_amount),
          }
        }
        return item
      })
      if (change_detected) {
        this.props.updateExtraCharges?.({
          document_custom_additional_charges: additionalChargesData,
          has_extra_charges: this.state.has_extra_charges,
        })
      }
    }
  }

  allowBatches = document_type => {
    return document_type == 'estimate' ||
      document_type == 'purchase_order' ||
      document_type == 'pro_forma_invoice'
      ? false
      : true
  }

  onAddNew = item => {
    if (item?.variants?.length > 0) {
      this.doSearch(item.product_name)
      return
    }
    const { count } = this.state

    // when adding new product from purchases, change price with tax to purchase price
    item.actual_price_with_tax = item.price_with_tax
    item.actual_unit_price = item.unit_price
    if (this.state.type == 'purchases') {
      item.price_with_tax = item.purchase_price ? item.purchase_price : item.price_with_tax
      item.price = item.price_with_tax / (1 + (item.tax + item.cess) / 100)
      item.unit_price = item.price
    }
    if (this.props.with_tax == 0) {
      item.price_with_tax = item.unit_price
    }
    const final_discount =
      this.props.document_type.includes('purchase') && item.discount != 0
        ? 0
        : this.state.total_discount != 0 && this.state.total_discount != undefined
        ? this.state.total_discount
        : this.props.customerDiscount == 0
        ? item.discount
        : this.props.customerDiscount

    const newData = {
      ...item,
      key: count + 1,
      qty: 1,
      selling_price: item.actual_price_with_tax,
      product_discount: item.discount,
      variant_name: '',
      value: item.product_name,
      tax: item.tax,
      qtyinstock: item.qtyinstock,
      product_name: item.product_name,
      discount: final_discount,
      discount_value: ((item.price_with_tax * final_discount) / 100).toFixed(2),
      discount_price_with_tax_value: ((item.price_with_tax * final_discount) / 100).toFixed(2),
      discount_net_value: ((item.unit_price * final_discount) / 100).toFixed(2),
      discount_unit_price_value: ((item.unit_price * final_discount) / 100).toFixed(2),
      selected_discount_type: 'discount',
      is_discount_percent: 1,
      export_unit_price: item.unit_price / this.props.conversion_factor,
      is_cess_on_qty_applicable: false,
      master_data: {
        price_with_tax: item.actual_price_with_tax,
        unit_price: item.unit_price,
        purchase_price: item.purchase_price,
        purchase_unit_price: item.purchase_unit_price,
        tax: item.tax,
        cess: item.cess,
        discount: item.discount,
      },
      batches: [],
    }

    if (
      item.has_batches == 1 ||
      (this.props.document_type != 'purchase' &&
        item.has_batches == 2 &&
        this.allowBatches(this.props.document_type))
    ) {
      this.productBatchesRef.current.showModal({ ...newData, variant_id: 0 }, this.props.edit)
      return
    }
    if (
      item.has_batches == 2 &&
      this.props.document_type == 'purchase' &&
      this.allowBatches(this.props.document_type)
    ) {
      this.serialNumberRef.onAdd({ ...newData, variant_id: 0 }, true)
      return
    }

    let allproducts = [...this.props.data, newData]
    if (this.props.items_reverse) {
      allproducts = [newData, ...this.props.data]
    }
    this.setState(
      {
        count: count + 1,
        currentKey: count + 1,
        barcodeData: { ...item, key: count + 1, qty: 1 },
      },
      () => {
        this.props.dataUpdate(allproducts)
      },
    )
  }

  onDelete = key => {
    const dataSource = [...this.props.data]

    let item = dataSource.filter(item => item.key == key)[0]

    if (item.has_batches == 2 && this.allowBatches(this.props.document_type)) {
      let serial_numbers = item.serial_numbers?.map(item => item.batch_id)
      item.batches = item.batches.map(batch => {
        if (serial_numbers.includes(batch.batch_id)) {
          batch.added_qty = 0
          batch.changed = false
        }
        return batch
      })
      this.onFinishBatchesQty(item, item.batches)
      return
    }

    if (item.has_batches == 1 && this.allowBatches(this.props.document_type)) {
      item.batches = item.batches.map(batch => {
        if (item.batch_id == batch.batch_id) {
          batch.added_qty = 0
          batch.changed = false
        }
        return batch
      })
      this.onFinishBatchesQty(item, item.batches)
      return
    }

    // change the key of each item to maintain the
    // unique ids for each product added
    const newData = dataSource.filter(item => item.key !== key)
    var result = newData.map((item, index) => ({
      ...item,
      key: index + 1,
    }))
    this.setState({ count: result.length })
    this.props.dataUpdate(result)
  }

  qtyOnChange = e => {
    const value = e
    if (isNaN(value) || value == '') {
      message.error('Enter correct Qty')
    } else {
      const data = this.state.selectedData
      data.qty = value
      this.formRef.current.setFieldsValue({
        ...data,
      })
    }
  }
  onSelectedDiscountChange = (key, value) => {
    let data = [...this.props.data]
    data.map((item, index) => {
      if (item.key == key) {
        item.selected_discount_type = value
        if (value == 'discount') {
          item.is_discount_percent = 1
        } else {
          item.is_discount_percent = 0
        }
      }
    })
    this.props.dataUpdate(data)
  }

  handleSubmit = values => {
    if (this.state.selected) {
      const { count, selectedData } = this.state
      const { data } = this.props
      const index = data.findIndex(item => item.id == selectedData.id)
      values.qty = values.qty == '' || values.qty == null ? 1 : values.qty
      const final_discount =
        this.state.total_discount != 0 && this.state.total_discount != undefined
          ? this.state.total_discount
          : this.props.customerDiscount == 0
          ? selectedData.discount
          : this.props.customerDiscount

      if (this.props.with_tax == 0) {
        selectedData.price_with_tax = selectedData.unit_price
      }
      const newData = {
        ...selectedData,
        ...values,
        key: count + 1,
        discount: final_discount,
        discount_value: ((values.qty * selectedData.price_with_tax * final_discount) / 100).toFixed(
          2,
        ),
        discount_price_with_tax_value: (
          (1 * selectedData.price_with_tax * final_discount) /
          100
        ).toFixed(2),
        discount_net_value: ((values.qty * selectedData.price * final_discount) / 100).toFixed(2),
        discount_unit_price_value: ((1 * selectedData.price * final_discount) / 100).toFixed(2),
        description: selectedData.description,
        editorState: EditorState.createWithContent(stateFromHTML(selectedData.description)),
        expanded: true,
        cess_amount: values.qty * selectedData.unit_price * (selectedData.cess / 100),
        selected_discount_type: 'discount',
        is_discount_percent: 1,
        is_cess_on_qty_applicable: false,
        master_data: {
          price_with_tax: selectedData.actual_price_with_tax,
          unit_price: selectedData.unit_price,
          purchase_price: selectedData.purchase_price,
          purchase_unit_price: selectedData.purchase_unit_price,
          tax: selectedData.tax,
          cess: selectedData.cess,
          discount: selectedData.discount,
        },
        product_discount: selectedData.discount,
        selling_price: selectedData.actual_price_with_tax,
      }
      // if (newData.is_price_with_tax) {
      //   newData.unit_price = newData.price_with_tax / (1 + (newData.tax + newData.cess) / 100)
      //   newData.price = newData.unit_price
      // } else {
      //   newData.price_with_tax = newData.unit_price * (1 + (newData.tax + newData.cess) / 100)
      // }

      // console.log(newData)

      if (selectedData.custom_column_values) {
        var custom_column_values = selectedData.custom_column_values.split('::::')
      } else {
        var custom_column_values = []
      }

      if (selectedData.custom_column_names) {
        var custom_column_names = selectedData.custom_column_names.split('::::')
      } else {
        var custom_column_names = []
      }

      this.props.custom_columns.forEach((item, index) => {
        if (item.default_value != '') {
          item.name in newData
            ? console.log('')
            : (newData[item.name] = selectedData[item.default_value])
        } else {
          if (custom_column_names.includes(item.name)) {
            newData[item.name] = custom_column_values[custom_column_names.indexOf(item.name)]
          } else {
            item.name in newData ? console.log('') : (newData[item.name] = '')
          }
        }
      })
      if (this.props.is_export || this.props.is_multi_currency) {
        if (isNaN(parseFloat(this.props.conversion_factor))) {
          message.error('Enter conversion factor')
          return true
        }
        newData['export_unit_price'] =
          newData['unit_price'] / parseFloat(this.props.conversion_factor)
        newData['export_price_with_tax'] =
          newData['price_with_tax'] / parseFloat(this.props.conversion_factor)
      }
      let allproducts = [...this.props.data, newData]
      if (this.props.items_reverse) {
        allproducts = [newData, ...this.props.data]
      }

      this.setState(
        { count: count + 1, selected: false, selectedData: {}, currentKey: count + 1 },
        async () => {
          this.formRef.current.setFieldsValue({
            product_name: '',
            qty: '',
            price: '',
            price_with_tax: '',
            discount: '',
            discount_value: '',
            discount_net_value: '',
            discount_unit_price_value: '',
            discount_price_with_tax_value: '',
            description: '',
            value: '',
          })

          await this.props.dataUpdate(allproducts)
          this.auto.focus()
          this.onCustomChange(newData['qty'], newData['key'], this.props.qty_name, 'qty')
          if (
            this.state.count == 1 &&
            !this.props.edit &&
            !this.state.default_charges_populated &&
            !this.props.convert &&
            !this.props.duplicate
          ) {
            this.populateChargesDefaultValues()
          }
        },
      )
      if (index != -1) {
        message.warning('Same Product already in Table')
      }
    } else {
      this.formRef.current.setFieldsValue({
        product_name: '',
        value: '',
        qty: '',
      })
      this.form.onAdd(values.value)
    }
    this.setState({ searchText: '', page: 0 })

    this.doSearch('')
  }

  onTotalDiscountChange = value => {
    const newData = this.props.data
    const dataSource = []
    newData.map(item => {
      item.discount = value == null ? 0 : value
      item.discount_value = getDiscountValue(item)
      item.discount_net_value = getDiscountNetValue(item)
      item.discount_unit_price_value = getDiscountUnitPriceValue(item)
      item.discount_price_with_tax_value = getDiscountPriceWithTaxValue(item)
    })
    newData.map((item, index) => {
      let data = this.onCustomChange(item['qty'], item['key'], this.props.qty_name, 'qty')
      dataSource.push(data[index])
    })

    this.setState({ total_discount: value })
    this.props.dataUpdate([...dataSource])
  }

  getQtyClass = data => {
    if (data < 0) {
      return '#e11900'
    } else if (data == 0 && data < 5) {
      return '#757575'
    } else {
      return '#09864A'
    }
  }

  getTotalNetAmount = () => {
    var sum = 0
    this.props.data.map(item => {
      sum = sum + getItemNetAmount(item)
    })
    return sum
  }

  getTotalCessAmount = () => {
    var cessSum = 0
    this.props.data.map(item => {
      cessSum = cessSum + getCessAmount(item)
    })
    return Math.round(cessSum)
  }

  onChangePackingChargesTax = e => {
    this.setState({ packingChargesTax: parseFloat(e) }, () => {
      this.setState(
        {
          packingCharges: (
            this.state.packingChargesWithoutTax *
            (1 + this.state.packingChargesTax / 100)
          ).toFixed(2),
        },
        () => {
          this.setState(
            {
              packingChargesTaxAmount: (
                (this.state.packingChargesWithoutTax * this.state.packingChargesTax) /
                100
              ).toFixed(2),
            },
            () => {
              this.props.updateExtraCharges?.(this.state)
            },
          )
        },
      )
    })
  }

  onChangeTransportChargesTax = e => {
    this.setState({ transportChargesTax: parseFloat(e) }, () => {
      this.setState(
        {
          transportCharges: (
            this.state.transportChargesWithoutTax *
            (1 + this.state.transportChargesTax / 100)
          ).toFixed(2),
        },
        () => {
          this.setState(
            {
              transportChargesTaxAmount: (
                (this.state.transportChargesWithoutTax * this.state.transportChargesTax) /
                100
              ).toFixed(2),
            },
            () => {
              this.props.updateExtraCharges?.(this.state)
            },
          )
        },
      )
    })
  }

  affix = x => {
    this.setState({ searchVisible: x })
  }

  // handleOnHover = (result) => {
  //   console.log(result);
  // };

  formatResult = item => {
    return (
      <div>
        {/* <span style={{ display: 'block', textAlign: 'left' }}>id: {item.product_id}</span> */}
        <span style={{ display: 'block', textAlign: 'left' }}>{item.product_name}</span>
      </div>
    )
  }

  handleEnter = event => {
    if (event.key.toLowerCase() === 'enter') {
      const form = event.target.form
      const index = [...form].indexOf(event.target)
      form.elements[index + 1].click()
      event.preventDefault()
    }
  }

  findEndProductIndex = async () => {
    const req = {
      query: this.state.searchText,
      selected_price_list: this.props.selectedPriceList,
      page: this.state.page + 1,
      num_records: this.state.num_records,
      category: this.state.selected_category,
      warehouse_id: this.props.warehouse_id,
    }
    try {
      if (JSON.stringify(req) === JSON.stringify(this.state.prevReq)) {
        return
      } else {
        this.setState({ prevReq: req })
      }
    } catch (error) {
      console.log(error)
    }

    const data = await getAPI('product', 'search', req)
    if (data) {
      let allData = data.products.map(value => {
        if (this.props.document_type.includes('purchase') && value.discount != 0) {
          value.discount = 0
        }
        value.actual_price_with_tax = value.price_with_tax
        value.actual_unit_price = value.unit_price
        if (this.state.type == 'purchases' && value.purchase_price != 0) {
          value.price = value.purchase_price / (1 + (value.tax + value.cess) / 100)
          value.unit_price = value.price
          value.price_with_tax = value.purchase_price
        }
        return {
          ...value,
          value:
            value.product_name +
            (value.variant_name == '' || value.variant_name == null
              ? ''
              : ' ' + value.variant_name),
          // id: value.product_id,
          id:
            String(value.product_id) +
            '_' +
            String(value.variant_id == undefined ? 0 : value.variant_id),
          description: value.description,
          tax: value.tax,
          qty: 1,
          qtyinstock: value.qty,
          product_name: value.product_name,
          discount: value.discount,
          discount_value: ((value.price_with_tax * value.discount) / 100).toFixed(2),
          discount_price_with_tax_value: ((value.price_with_tax * value.discount) / 100).toFixed(2),
          discount_net_value: ((value.price * value.discount) / 100).toFixed(2),
          discount_unit_price_value: ((value.price * value.discount) / 100).toFixed(2),
          barcode_id: value.barcode_id,
          variant_name: value.variant_name,
          is_cess_on_qty_applicable: false,
        }
      })
      allData = get_auto_complete_product_format(allData, this.props.document_settings)
      this.setState({
        products: [
          ...this.state.products,
          ...allData,
          // { key: this.renderTitle('Add New Product'), value: 'Add New Product' },
        ],
        page: this.state.page + 1,
        last_updated_products: data.products,
      })
    }
  }

  onFinishBatchesQty = async (product, batches) => {
    this.onFinishSerialNumbers(
      product,

      batches.filter(batch => batch.added_qty > 0),
      batches,
    )
  }

  onFinishSerialNumbers = async (product, added_batches, batches = []) => {
    const { count } = this.state
    const { data } = this.props
    const matchedItem = data.filter(
      item => item.product_id == product.product_id && item.variant_id == product.variant_id,
    )

    let updated_batches = [...batches]

    let old_item_dict = new Map()

    if (matchedItem.length > 0) {
      matchedItem.forEach(item => {
        let key = product.has_batches == 2 ? item.price_with_tax : item.batch_uid

        old_item_dict.set(key, item)

        if (product.has_batches == 1) {
          batches.forEach(async (batch, index2) => {
            if (item.batch_id == batch.batch_id) {
              old_item_dict.get(key).qty = batch.added_qty > 0 ? batch.added_qty : -1

              await old_item_dict.get(key).batches.map((batchItem, index3) => {
                if (batchItem.batch_id == batch.batch_id) {
                  old_item_dict.get(key).batches[index3].added_qty = batch.added_qty
                }
              })
            }
          })
        }
      })
    }

    const final_discount =
      this.state.total_discount != 0 && this.state.total_discount != undefined
        ? this.state.total_discount
        : this.props.customerDiscount == 0
        ? product.discount
        : this.props.customerDiscount

    let batch_wise_items_grouped = new Map()
    added_batches.forEach(num => {
      let price_with_tax = this.props.document_type.includes('purchase')
        ? product.purchase_price
        : num.price_with_tax

      let key = product.has_batches == 2 ? price_with_tax : num.batch_uid
      if (batch_wise_items_grouped.has(key)) {
        batch_wise_items_grouped.get(key).push(num)
      } else {
        batch_wise_items_grouped.set(key, [num])
      }
    })

    let newProducts = []
    let items_count = 1

    for (const [key, serial_list] of batch_wise_items_grouped) {
      let qty =
        product.has_batches == 2
          ? serial_list.length
          : serial_list.reduce((a, b) => a + b.added_qty, 0)

      let unit_price =
        this.props.document_type.includes('purchase') && product.has_batches == 2
          ? product.purchase_unit_price == 0
            ? product.unit_price
            : product.purchase_unit_price
          : serial_list[0].unit_price

      let price_with_tax =
        this.props.document_type.includes('purchase') && product.has_batches == 2
          ? product.purchase_price == 0
            ? product.price_with_tax
            : product.purchase_price
          : serial_list[0].price_with_tax

      if (old_item_dict.has(key)) {
        old_item_dict.get(key).exists = true
        old_item_dict.get(key).qty = qty
        old_item_dict.get(key).price_with_tax = price_with_tax
        old_item_dict.get(key).price = unit_price
        old_item_dict.get(key).unit_price = unit_price
        old_item_dict.get(key).serial_numbers = product.has_batches == 2 ? serial_list : []
        old_item_dict.get(key).batches = updated_batches
        old_item_dict.get(key)[this.props.qty_name] = qty
        old_item_dict.get(key).unit_id = product.has_batches == 2 ? 0 : serial_list[0].unit_id
        old_item_dict.get(key).unit = product.has_batches == 2 ? '' : serial_list[0].unit
        old_item_dict.get(key).conversion_rate =
          product.has_batches == 2 ? 1 : serial_list[0].conversion_rate
        continue
      }

      let no_iterations = product.has_batches == 2 ? 1 : serial_list.length

      for (let i = 0; i < no_iterations; i++) {
        //BATCH CUSTOM COLUMNS
        let batch_custom_columns = serial_list[i].custom_fields.map(item_col => {
          return {
            name: item_col.name,
            value: item_col.value,
          }
        })

        const newData = {
          ...product,
          key: count + items_count,
          qty: qty,
          price_with_tax: price_with_tax,
          unit_price: unit_price,
          price: unit_price,
          discount: final_discount,
          discount_value: ((qty * price_with_tax * final_discount) / 100).toFixed(2),
          discount_price_with_tax_value: ((1 * price_with_tax * final_discount) / 100).toFixed(2),
          discount_net_value: ((qty * unit_price * final_discount) / 100).toFixed(2),
          discount_unit_price_value: ((1 * unit_price * final_discount) / 100).toFixed(2),
          description: product.description,
          editorState: EditorState.createWithContent(stateFromHTML(product.description)),
          expanded: true,
          cess_amount: qty * unit_price * (product.cess / 100),
          selected_discount_type: 'discount',
          is_discount_percent: 1,
          is_cess_on_qty_applicable: false,
          serial_numbers: product.has_batches == 2 ? serial_list : [],
          batches: updated_batches,
          batch_id: product.has_batches == 2 ? 0 : serial_list[i].batch_id,
          batch_uid: product.has_batches == 2 ? '' : serial_list[i].batch_uid,
          batch_no: product.has_batches == 2 ? '' : serial_list[i].batch_no,
          unit_id: product.has_batches == 2 ? 0 : serial_list[i].unit_id,
          unit: product.has_batches == 2 ? '' : serial_list[i].unit,
          conversion_rate: product.has_batches == 2 ? 1 : serial_list[i].conversion_rate,
          purchase_price:
            product.has_batches == 2 ? product.purchase_price : serial_list[i].purchase_price,
          purchase_unit_price:
            product.has_batches == 2
              ? product.purchase_unit_price
              : serial_list[i].purchase_unit_price,
          [this.props.qty_name]: qty,
          master_data: {
            price_with_tax: product.actual_price_with_tax || product.price_with_tax,
            unit_price: product.unit_price,
            purchase_price: product.purchase_price,
            purchase_unit_price: product.purchase_unit_price,
            tax: product.tax,
            cess: product.cess,
            discount: product.discount,
          },

          ...batch_custom_columns.reduce((a, b) => ({ ...a, [b.name]: b.value }), {}),
        }

        newData['net_amount'] = getNetAmount_unit_price(newData)
        newData['total_amount'] = getRowTotal(newData)
        newData['tax_amount'] = getTaxAmount(newData)
        newData['cess_amount'] = getCessAmount(newData)

        if (this.props.is_export || this.props.is_multi_currency) {
          if (isNaN(parseFloat(this.props.conversion_factor))) {
            message.error('Enter conversion factor')
            return true
          }
          newData['export_unit_price'] =
            newData['unit_price'] / parseFloat(this.props.conversion_factor)
          newData['export_price_with_tax'] =
            newData['price_with_tax'] / parseFloat(this.props.conversion_factor)
        }

        newProducts.push(newData)
        items_count += 1
      }
    }

    let final_products = []

    if (product.has_batches == 1) {
      final_products = newProducts
    }

    let custom_column_keys = this.props.custom_columns
      .filter(item => item.applicable_for == 'batch')
      .map(item => item.name)

    if (product.has_batches == 2 && custom_column_keys.length > 0) {
      // group newProducts by custom fields
      newProducts.forEach(item => {
        let item_serial_numbers = [...item.serial_numbers]

        // group by serial number custom field values
        let groupedProducts = new Map()
        item_serial_numbers.forEach(serial => {
          let customFields = serial.custom_fields.reduce(
            (a, b) => ({ ...a, [b.name]: b.value }),
            {},
          )
          let key = JSON.stringify(customFields)
          if (groupedProducts.has(key)) {
            groupedProducts.get(key).push({
              ...serial,
              modified_custom_fields: customFields,
            })
          } else {
            groupedProducts.set(key, [
              {
                ...serial,
                modified_custom_fields: customFields,
              },
            ])
          }
        })

        groupedProducts.forEach((serials, key) => {
          if (serials?.length > 0) {
            let new_item = {
              ...item,
              ...serials[0]?.modified_custom_fields,
              qty: serials.length,
              serial_numbers: serials,
            }
            new_item.net_amount = getNetAmount_unit_price(new_item)
            new_item.cess_amount = getCessAmount(new_item)
            new_item.tax_amount = getTaxAmount(new_item)
            new_item.total_amount = getRowTotal(new_item)
            new_item['discount_value'] = getDiscountValue(new_item)
            new_item['discount_net_value'] = getDiscountNetValue(new_item)
            new_item['discount_unit_price_value'] = getDiscountUnitPriceValue(new_item)
            new_item['discount_price_with_tax_value'] = getDiscountPriceWithTaxValue(item)
            final_products.push(new_item)
          }
        })
      })
    } else {
      final_products = newProducts
    }

    let allproducts = [...this.props.data, ...final_products]

    for (const [price_incl_tax, item] of old_item_dict) {
      if (item.exists == undefined || !item.exists || added_batches.length == 0) {
        allproducts = allproducts.filter(
          item2 => item2.key != old_item_dict.get(price_incl_tax).key,
        )
      } else {
        let index = allproducts.findIndex(
          item3 => item3.key == old_item_dict.get(price_incl_tax).key,
        )
        allproducts[index] = old_item_dict.get(price_incl_tax)
        allproducts[index].net_amount = getNetAmount_unit_price(allproducts[index])
        allproducts[index].cess_amount = getCessAmount(allproducts[index])
        allproducts[index].tax_amount = getTaxAmount(allproducts[index])

        allproducts[index].total_amount = getRowTotal(allproducts[index])

        allproducts[index]['discount_value'] = getDiscountValue(allproducts[index])
        allproducts[index]['discount_net_value'] = getDiscountNetValue(allproducts[index])
        allproducts[index]['discount_unit_price_value'] = getDiscountUnitPriceValue(
          allproducts[index],
        )
        allproducts[index]['discount_price_with_tax_value'] = getDiscountPriceWithTaxValue(
          allproducts[index],
        )

        this.onCustomChange(
          allproducts[index]['qty'],
          allproducts[index]['key'],
          this.props.qty_name,
          'qty',
        )
      }
    }

    if (this.props.items_reverse) {
      allproducts = [...newProducts, ...this.props.data]
    }

    allproducts = allproducts.map((item, index) => ({
      ...item,
      key: index + 1,
      exists: false,
    }))
    let filterd_products = allproducts.filter(
      item =>
        (item.has_batches == 1 && item.batch_id == 0) ||
        (item.has_batches == 2 && item?.serial_numbers?.length == 0),
    )
    let showSelectBatches = this.state.showSelectBatches
    if (filterd_products.length == 0) {
      showSelectBatches = false
    }

    this.setState(
      {
        count: count + newProducts.length,
        selected: false,
        selectedData: {},
        searchText: '',
        page: 0,
        showSelectBatches: showSelectBatches,
      },
      async () => {
        this.formRef.current.setFieldsValue({
          product_name: '',
          qty: '',
          price: '',
          price_with_tax: '',
          discount: '',
          discount_value: '',
          discount_net_value: '',
          discount_unit_price_value: '',
          discount_price_with_tax_value: '',
          description: '',
          value: '',
        })

        await this.props.dataUpdate(allproducts)
        this.auto.focus()
      },
    )

    this.doSearch('')
  }
  changeBatchQty = (productData, is_edit) => {
    if (
      productData.has_batches == 1 ||
      (this.props.document_type != 'purchase' && productData.has_batches == 2)
    ) {
      this.productBatchesRef.current.showModal(productData, is_edit)
    } else {
      this.serialNumberRef.onAdd(productData, is_edit)
    }
  }

  render() {
    const handleScroll = e => {
      const bottom =
        parseInt(e.target.scrollHeight - e.target.scrollTop) - parseInt(e.target.clientHeight) <= 1
      if (bottom) {
        this.findEndProductIndex()
      }
    }

    const selectUnits = data => (
      <span className="select-borderless">
        <Select
          suffixIcon={<i className="fa-regular fa-chevron-down"></i>}
          placeholder="Unit"
          optionFilterProp="children"
          style={{ maxWidth: '90px' }}
          dropdownStyle={{ minWidth: '300px' }}
          filterOption={(input, option) =>
            option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
          }
          defaultValue={data.units?.filter(item => item?.unit_id == data?.unit_id)?.[0].unit_id}
          value={data?.unit_id == 0 || data?.unit_id == null ? 0 : data?.unit_id}
          onChange={value => {
            this.onChange(value, data.key, 'unit_id')
          }}
          // disabled={}
        >
          {data.units?.map((e, i) => (
            <Option key={i} value={e.unit_id}>
              {e.alternative_unit}{' '}
              {data.unit != '' && e.primary_unit == undefined
                ? ` - (1 ${data.units?.[0].alternative_unit} = ${e.conversion_rate} ${e.alternative_unit})`
                : ''}
            </Option>
          ))}
        </Select>
      </span>
    )

    var document_custom_additional_charges = [
      {
        title: '',
        width: '20%',
        dataIndex: 'name',
        render: (text, data) => (
          <span className="font-weight-bold font-size-14">
            {data.name}
            <Tooltip title={data.type == -1 ? 'Deduction' : 'Addtion'} placement="top">
              {data.type == -1 ? ' (-)' : ' (+)'}
            </Tooltip>
          </span>
        ),
      },
      {
        title: 'Tax',
        width: '10%',
        align: 'right',
        render: (text, data) => (
          <Select
            suffixIcon={<i className="fa-regular fa-chevron-down"></i>}
            style={{ width: 70 }}
            showSearch
            placeholder="Tax"
            optionFilterProp="children"
            value={data.tax}
            className="font-size-12"
            filterOption={(input, option) =>
              option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
            }
            onSelect={e => this.onChangeAdditionalCharge(e, data.additional_charge_id, 'tax')}
            disabled={!this.props.with_tax}
          >
            {this.props.taxes.map((e, i) => (
              <Option key={i} value={e}>
                {e}
              </Option>
            ))}
          </Select>
        ),
      },
      {
        title: 'in (%)',
        width: '12%',
        align: 'right',
        render: (text, data) => (
          <InputNumber
            type="text"
            onChange={e => this.onChangeAdditionalCharge(e, data.additional_charge_id, 'percent')}
            min={0}
            className=""
            value={data.percent}
          />
        ),
      },
      {
        title: `withoutTax in (${this.props.user.selectedCompany.currency_symbol})`,
        width: '18%',
        align: 'right',
        render: (text, data) => (
          <InputNumber
            type="text"
            style={{ width: '60%', marginRight: 0 }}
            onChange={e =>
              this.onChangeAdditionalCharge(e, data.additional_charge_id, 'net_amount')
            }
            min={0}
            value={
              (this.props.is_export || this.props.is_multi_currency) &&
              this.state.showExportCurrency
                ? data.net_amount / parseFloat(this.props.conversion_factor)
                : data.net_amount
            }
          />
        ),
      },
      {
        title: `withTax in (${this.props.user.selectedCompany.currency_symbol})`,
        width: '18%',
        align: 'right',
        render: (text, data) => (
          <InputNumber
            type="text"
            style={{ width: '60%', marginRight: 0 }}
            onChange={e =>
              this.onChangeAdditionalCharge(e, data.additional_charge_id, 'total_amount')
            }
            min={0}
            value={
              (this.props.is_export || this.props.is_multi_currency) &&
              this.state.showExportCurrency
                ? data.total_amount / parseFloat(this.props.conversion_factor)
                : data.total_amount
            }
          />
        ),
      },
    ]

    var tableColumn = [
      {
        title: 'Product | Qty  | Total',
        responsive: ['xs'],
        render: (text, data) => (
          <>
            <Collapse
              bordered={false}
              expandIcon={({ isActive }) => (
                <CaretRightOutlined className="mt-2" rotate={isActive ? 90 : 0} />
              )}
              className="site-collapse-custom-collapse"
              expandIconPosition={'right'}
            >
              <Panel
                header={
                  <div className="font-size-13">
                    <span className="font-weight-bold font-size-14 mr-2 mb-2">
                      {data.value && data.value.length > 35 ? (
                        <>{data.value.slice(0, 34)}...</>
                      ) : (
                        data.value
                      )}
                    </span>
                    <br />
                    {' x '}
                    {Number(data.qty).toFixed(3)} {' = '}{' '}
                    {parseInt(String(data.id).split('_')[0]) > -1
                      ? getRowTotal(data).toFixed(2)
                      : 0}
                    <Popconfirm title="Sure to delete?" onConfirm={() => this.onDelete(data.key)}>
                      <a className="ml-2">
                        <i className="fa fa-trash font-size-13" style={{ color: 'red' }} />
                      </a>
                    </Popconfirm>
                  </div>
                }
                key={data.id}
                className="site-collapse-custom-panel"
              >
                <div className="row">
                  <div className="col-4">
                    <p className="mb-1 font-size-10">Qty</p>
                    <InputNumber
                      size="small"
                      onChange={value => {
                        this.onChange(value, data.key, 'qty')
                      }}
                      value={data.qty != 0 ? Number(data.qty).toFixed(3) : ''}
                      min={0}
                    />
                  </div>
                  <div className="col-4">
                    <p className="mb-1 font-size-10">Price</p>
                    <InputNumber
                      size="small"
                      onChange={value => {
                        this.onChange(value, data.key, 'price')
                      }}
                      value={data.price}
                      min={0}
                    />
                  </div>
                  <div className="col-4">
                    <p className="mb-1 font-size-10">
                      {this.props?.user?.selectedCompany?.labels?.mrp}
                    </p>
                    <InputNumber
                      size="small"
                      onChange={value => {
                        this.onChange(value, data.key, 'price_with_tax')
                      }}
                      value={getPriceWithTax(data).toFixed(2)}
                      min={0}
                    />
                  </div>
                  <div className="col-4 font-size-10">
                    <p className="mb-1 mt-1">Disc. %</p>
                    <InputNumber
                      size="small"
                      onChange={value => {
                        this.onChange(value, data.key, 'discount')
                      }}
                      value={Number(data.discount).toFixed(2)}
                      min={0}
                      max={100}
                    />
                  </div>
                  <div className="col-4 font-size-10">
                    <p className="mb-1 mt-1">
                      Disc. {this.props.user.selectedCompany.currency_symbol}
                    </p>
                    <InputNumber
                      size="small"
                      onChange={value => {
                        this.onChange(value, data.key, 'discount_value')
                      }}
                      value={((data.price_with_tax * data.discount) / 100).toFixed(2)}
                      min={0}
                    />
                  </div>

                  <div className="col-4 font-size-10">
                    <p className="mb-1 mt-1 font-size-10">Tax</p>
                    <span>
                      {parseInt(String(data.id).split('_')[0]) > -1
                        ? getTaxAmount(data).toFixed(2)
                        : 0}
                    </span>
                    <span> ({data.tax}%)</span>
                  </div>

                  <div className="col-4">
                    <p className="mb-1 mt-2 font-size-10">Net Amt.</p>
                    {parseInt(String(data.id).split('_')[0]) > -1
                      ? getNetAmount_unit_price(data).toFixed(2)
                      : 0}
                  </div>

                  <div className="col-4 font-size-11">
                    <p className="mb-1 mt-2 font-size-10">Total</p>
                    {parseInt(String(data.id).split('_')[0]) > -1 ? getRowTotal(data) : 0}
                  </div>
                </div>
              </Panel>
            </Collapse>
          </>
        ),
      },
      {
        title: 'Product Name',
        dataIndex: 'product_name',
        responsive: ['md'],
        width: '15%',
        render: (text, selectedData) => (
          <span>
            <a>
              <span
                onClick={e => {
                  if (selectedData.new_variant_id != 0) {
                    this.productAnalysis.showModal(
                      selectedData,
                      selectedData.product_id,
                      selectedData,
                      this.props?.PartyData,
                    )
                  } else {
                    this.productAnalysis.showModal(
                      selectedData,
                      selectedData.product_id,
                      {},
                      this.props?.PartyData,
                    )
                  }
                }}
                className="font-weight-bold"
              >
                <span
                  className="hover-underline"
                  onClick={e => {
                    e.stopPropagation()
                    if (selectedData.new_variant_id != 0) {
                      this.productAnalysis.showModal(
                        selectedData,
                        selectedData.product_id,
                        selectedData,
                        this.props?.PartyData,
                      )
                    } else {
                      this.productAnalysis.showModal(
                        selectedData,
                        selectedData.product_id,
                        {},
                        this.props?.PartyData,
                      )
                    }
                  }}
                >
                  {selectedData.product_name + ' ' + selectedData.variant_name}
                </span>

                {selectedData.unit != 'OTH' && selectedData.unit != 'NONE' && (
                  <span className="font-size-10 text-purple ml-2">{selectedData.unit}</span>
                )}
                {selectedData.batch_id > 0 ? (
                  <p className="m-0 p-0 font-size-12 text-gray-500">{selectedData.batch_no}</p>
                ) : (
                  ''
                )}
              </span>

              <p className="m-0 p-0">
                <span className="font-size-11 text-gray-500 mr-1">#{selectedData.key}</span>

                {!this.props.edit && (
                  <span className="font-weight-bold font-size-11">
                    {selectedData.qtyinstock !== '' &&
                      selectedData.qtyinstock !== undefined &&
                      ((selectedData['product_type'] == 'Service' &&
                        this.props.document_settings.track_stock_for_services === 1) ||
                        selectedData['product_type'] != 'Service') && (
                        <span
                          className="mr-2"
                          style={{ color: this.getQtyClass(selectedData.qtyinstock) }}
                        >
                          Avl qty: {selectedData.qtyinstock.toFixed(2)}
                        </span>
                      )}
                    {selectedData.hsn_code != '' && selectedData.hsn_code != undefined ? (
                      <span className="text-gray-10 mr-2">
                        {this.props?.user?.selectedCompany?.labels?.hsn_code}:{' '}
                        {selectedData.hsn_code}
                      </span>
                    ) : (
                      <Button
                        type="add-field"
                        className="mr-2"
                        shape="round"
                        // onClick={() => console.log(this.addHsn)}
                        onClick={() => this.addHsn.current.showModal(selectedData)}
                      >
                        <i className="fa-regular fa-plus mr-1"></i>{' '}
                        {this.props?.user?.selectedCompany?.labels?.hsn_code}
                      </Button>
                    )}
                    {selectedData.barcode_id != '' && selectedData.barcode_id != undefined && (
                      <Tooltip title="Print Barcode">
                        <Button
                          type="yellow-2"
                          size="small"
                          onClick={() => {
                            this.barcode.showModal({
                              ...selectedData,
                              name: selectedData.variant_name,
                            })
                          }}
                          className=""
                        >
                          <i className="fa fa-barcode" />
                        </Button>
                      </Tooltip>
                    )}
                  </span>
                )}
                {store.get('role') == 'admin' && (
                  <span className="font-weight-bold font-size-11">
                    {this.props.document_type.includes('purchase') &&
                      this.props.document_type != 'purchase_return' &&
                      this.props.document_settings.show_selling_price == 1 && (
                        <span>
                          Selling Price:{' '}
                          {selectedData.actual_price_with_tax
                            ? selectedData.actual_price_with_tax
                            : 0}
                        </span>
                      )}
                    {this.props.document_type.includes('purchase') &&
                    this.props.document_type != 'purchase_return' &&
                    this.props.document_settings.show_purchase_margin ? (
                      <Tooltip
                        title={
                          <>
                            <p className="font-weight-bold">
                              Margin = (Selling Price - Purchase Price ) * Qty (Excl.Tax)
                            </p>
                            <p>
                              {parseFloat(
                                parseFloat(
                                  selectedData.actual_unit_price / selectedData.conversion_rate,
                                ).toFixed(2) *
                                  parseFloat(selectedData.qty) -
                                  parseFloat(getNetAmount_unit_price(selectedData)),
                              ).toFixed(2)}{' '}
                              = ({' '}
                              {(
                                parseFloat(selectedData.actual_unit_price) /
                                selectedData.conversion_rate
                              ).toFixed(2)}
                              -{' '}
                              {parseFloat(getNetAmount_unit_price(selectedData)) /
                                parseFloat(selectedData.qty)}{' '}
                              ) * {selectedData.qty}
                            </p>
                          </>
                        }
                      >
                        <span
                          className={
                            parseFloat(
                              selectedData.actual_unit_price / selectedData.conversion_rate,
                            ) *
                              parseFloat(selectedData.qty) -
                              parseFloat(getNetAmount_unit_price(selectedData)) >=
                            0
                              ? 'text-forest'
                              : 'text-danger'
                          }
                        >
                          {' ( Margin:'}{' '}
                          {parseFloat(
                            parseFloat(
                              selectedData.actual_unit_price / selectedData.conversion_rate,
                            ) *
                              parseFloat(selectedData.qty) -
                              parseFloat(getNetAmount_unit_price(selectedData)),
                          ).toFixed(2)}{' '}
                          {')'}
                        </span>
                      </Tooltip>
                    ) : (
                      <></>
                    )}
                    {!this.props.document_type.includes('purchase') &&
                      this.props.document_type != 'sales_return' &&
                      this.props.document_settings.show_purchase_price == 1 && (
                        <span>Purchase Price: {selectedData.purchase_price}</span>
                      )}
                    {!this.props.document_type.includes('purchase') &&
                    this.props.document_type != 'sales_return' &&
                    this.props.document_settings.show_sales_margin ? (
                      <Tooltip
                        title={
                          <>
                            <p className="font-weight-bold">
                              Margin = ( Selling Price - Purchase Price ) * Qty (Excl.Tax)
                            </p>
                            <p>
                              {(
                                parseFloat(getNetAmount_unit_price(selectedData)) -
                                parseFloat(
                                  selectedData.purchase_unit_price / selectedData.conversion_rate,
                                ) *
                                  parseFloat(selectedData.qty)
                              ).toFixed(2)}{' '}
                              = ({' '}
                              {(
                                parseFloat(getNetAmount_unit_price(selectedData)) / selectedData.qty
                              ).toFixed(2)}{' '}
                              -{' '}
                              {parseFloat(
                                selectedData.purchase_unit_price / selectedData.conversion_rate,
                              ).toFixed(2)}{' '}
                              ) * {selectedData.qty}
                            </p>
                          </>
                        }
                      >
                        <span
                          className={
                            parseFloat(getNetAmount_unit_price(selectedData)) -
                              parseFloat(
                                selectedData.purchase_unit_price / selectedData.conversion_rate,
                              ) *
                                parseFloat(selectedData.qty) >=
                            0
                              ? 'text-forest'
                              : 'text-danger'
                          }
                        >
                          {' ( Margin:'}{' '}
                          {(
                            parseFloat(getNetAmount_unit_price(selectedData)) -
                            parseFloat(
                              selectedData.purchase_unit_price / selectedData.conversion_rate,
                            ) *
                              parseFloat(selectedData.qty)
                          ).toFixed(2)}{' '}
                          {')'}
                        </span>
                      </Tooltip>
                    ) : (
                      <></>
                    )}
                  </span>
                )}
              </p>
            </a>
          </span>
        ),
      },
      Table.EXPAND_COLUMN,
      {
        title: 'Quantity',
        dataIndex: 'qty',
        // width: '14%',
        responsive: ['md'],
        render: (text, data) => (
          <>
            <InputNumber
              onFocus={this.onInputFocus}
              onChange={value => {
                if (data.has_batches == 0 || !this.allowBatches(this.props.document_type)) {
                  this.onChange(value, data.key, 'qty')
                } else {
                  if (
                    data.has_batches == 1 ||
                    (this.props.document_type != 'purchase' && data.has_batches == 2)
                  ) {
                    this.productBatchesRef.current.showModal(
                      data,
                      this.props.edit || this.props.convert || this.props.duplicate,
                    )
                  } else if (data.has_batches == 2 && this.props.document_type == 'purchase') {
                    this.serialNumberRef.onAdd(data, true, true)
                  }
                }
              }}
              onBlur={value => {
                if (data.has_batches) {
                  return
                }
                value.target.value == ''
                  ? this.onChange(0, data.key, 'qty')
                  : this.onChange(data.qty, data.key, 'qty')
              }}
              style={{ width: '200px' }}
              value={data.qty == null ? '' : Number(data.qty.toFixed(3))}
              min={0}
              disabled={this.props.permissions.product_edit == 0}
              addonAfter={data.has_alternative_units == 0 ? data.unit : selectUnits(data)}
            />
          </>
        ),
      },
      {
        title: (
          <>
            <span>
              Unit Price{' '}
              {((this.props.is_export && !this.props.export_type?.includes('SEZ')) ||
                this.props.is_multi_currency) && (
                <Switch
                  size="small"
                  className="ml-2"
                  checked={this.state.showExportCurrency}
                  unCheckedChildren={this.props.user.selectedCompany.currency_symbol}
                  checkedChildren={this.props.currency_symbol}
                  onChange={value => this.setState({ showExportCurrency: value })}
                />
              )}
            </span>
          </>
        ),
        dataIndex: 'price',
        // width: '12%',
        render: (text, data) => (
          <span>
            <InputNumber
              onFocus={this.onInputFocus}
              onChange={value => {
                this.onChange(value, data.key, 'price')
              }}
              style={{ minWidth: '100%' }}
              value={
                (this.props.is_export || this.props.is_multi_currency) &&
                this.state.showExportCurrency
                  ? data.export_unit_price
                  : data.price
              }
              disabled={this.props.permissions.product_edit == 0}
              min={0}
              onBlur={e => this.check_purchase_price_warning(e.target.value, 'unit_price', data)}
            />
            {data.discount_unit_price_value != undefined && data.discount_unit_price_value != 0 && (
              <p className="m-0 p-0 text-forest position-absolute font-size-9 w-100p right-zero">
                after disc. {this.props.user.selectedCompany.currency_symbol}
                {(data.price - data.discount_unit_price_value).toFixed(2)}
              </p>
            )}
          </span>
        ),
        responsive: ['md'],
      },
      {
        title: 'Price with Tax',
        hidden: !this.props.with_tax,
        dataIndex: 'price_with_tax',
        // width: '12%',
        responsive: ['md'],
        render: (text, data) => (
          <span>
            <InputNumber
              onFocus={this.onInputFocus}
              onChange={value => {
                this.onChange(value, data.key, 'price_with_tax')
              }}
              style={{ minWidth: '100%' }}
              value={
                (this.props.is_export || this.props.is_multi_currency) &&
                this.state.showExportCurrency
                  ? data.export_price_with_tax
                  : data.price_with_tax
              }
              disabled={this.props.permissions.product_edit == 0}
              min={0}
              onBlur={e =>
                this.check_purchase_price_warning(e.target.value, 'price_with_tax', data)
              }
            />
            {data.discount_price_with_tax_value != undefined &&
              data.discount_price_with_tax_value != 0 && (
                <p className="m-0 p-0 text-forest position-absolute font-size-9 w-100p right-zero">
                  after disc. {this.props.user.selectedCompany.currency_symbol}
                  {(data.price_with_tax - data.discount_price_with_tax_value).toFixed(2)}
                </p>
              )}
          </span>
        ),
      },

      {
        title:
          'Discount (' +
          capitalizeFirstLetters(this.props.discount_type.split('_').join(' ')) +
          ')',
        // dataIndex: 'discount',
        // width: '7%',
        responsive: ['md'],
        render: (text, data) => {
          return (
            <span>
              <InputNumber
                onFocus={this.onInputFocus}
                addonAfter={
                  <span className="select-borderless">
                    <Select
                      suffixIcon={<i className="fa-regular fa-chevron-down"></i>}
                      value={data.selected_discount_type}
                      style={{ maxWidth: 125, minWidth: 55 }}
                      onChange={e => this.onSelectedDiscountChange(data.key, e)}
                    >
                      <Option value="discount">%</Option>
                      {this.props.discount_type == 'unit_price' && (
                        <Option value="discount_unit_price_value">
                          {this.props.user.selectedCompany.currency_symbol}
                        </Option>
                      )}
                      {this.props.discount_type == 'total_amount' && (
                        <Option value="discount_value">
                          {this.props.user.selectedCompany.currency_symbol}
                        </Option>
                      )}
                      {this.props.discount_type == 'net_amount' && (
                        <Option value="discount_net_value">
                          {this.props.user.selectedCompany.currency_symbol}
                        </Option>
                      )}
                      {this.props.discount_type == 'price_with_tax' && (
                        <Option value="discount_price_with_tax_value">
                          {this.props.user.selectedCompany.currency_symbol}
                        </Option>
                      )}
                    </Select>
                  </span>
                }
                onChange={value => this.onChange(value, data.key, data.selected_discount_type)}
                style={{ width: '150px' }}
                value={Number(data[data.selected_discount_type])}
                min={0}
                disabled={this.props.permissions.product_edit == 0}
                // max={100}
              />
            </span>
          )
        },
      },
      {
        title: (
          <>
            <span>
              <span className="font-size-12 mt-0 mb-0">Net Amount</span>
              {this.props.with_tax == 1 && (
                <p className="font-size-10 mb-0 mt-0">Tax (%) + Cess (%)</p>
              )}
            </span>
          </>
        ),
        align: 'right',
        // width: '12%',
        responsive: ['md'],
        render: (_, record) => (
          <>
            <span className="font-size-15">
              {parseInt(String(record.id).split('_')[0]) > -1
                ? Object.keys(record).includes('net_amount')
                  ? Number(record.net_amount).toFixed(2)
                  : getNetAmount_unit_price(record).toFixed(2)
                : 0}
            </span>
            {this.props.with_tax == 1 && (
              <p className="font-size-11 mt-0 mb-0 text-gray-10">
                {/* {console.log(record)} */}
                <span>
                  {parseInt(String(record.id).split('_')[0]) > -1
                    ? Object.keys(record).includes('tax_amount')
                      ? parseFloat(record.tax_amount).toFixed(2)
                      : getTaxAmount(record).toFixed(2)
                    : 0}
                </span>
                <span className="text-gray-10">
                  {' '}
                  {record.cess == 0 && (
                    <span>
                      {parseFloat(record.cess_on_qty_value) ? (
                        <span>
                          ({record.tax}% + {record.cess_on_qty}/{record.unit})
                        </span>
                      ) : (
                        <span>({record.tax}%)</span>
                      )}
                    </span>
                  )}
                  {record.cess != 0 && (
                    <span>
                      {parseFloat(record.cess_on_qty_value) ? (
                        <span>
                          ({record.tax}% + {record.cess}% + {record.cess_on_qty}/{record.unit})
                        </span>
                      ) : (
                        <span>
                          ({record.tax}% + {record.cess}%)
                        </span>
                      )}
                    </span>
                  )}
                </span>
                {this.props.permissions.additional_cess != 0 && (
                  <>
                    <br />
                    <a
                      onClick={() => this.additional_cess.showModal(record)}
                      className="font-size-9 text-primary"
                    >
                      {parseFloat(record.cess_on_qty_value) > 0 ? 'Change' : '+ Additional Cess'}
                    </a>
                  </>
                )}
              </p>
            )}
            {(this.props.is_export || this.props.is_multi_currency) &&
              this.props.conversion_factor != '' && (
                <>
                  <p className="font-size-11 mt-0 mb-0 text-gray-10">
                    {' '}
                    {this.props.currency_symbol}
                    {parseInt(String(record.id).split('_')[0]) > -1
                      ? (
                          getNetAmount_unit_price(record) / parseFloat(this.props.conversion_factor)
                        ).toFixed(2)
                      : 0}{' '}
                  </p>
                </>
              )}
          </>
        ),
      },
      {
        title: (
          <>
            <span>
              <span className="font-size-12 mt-0 mb-0">Total</span>
            </span>
          </>
        ),
        hidden: !this.props.with_tax,
        align: 'right',
        responsive: ['md'],
        render: (_, record) => (
          <>
            <span className="font-weight-bold font-size-16">
              {parseInt(String(record.id).split('_')[0]) > -1
                ? Object.keys(record).includes('total_amount')
                  ? parseFloat(record.total_amount).toFixed(2)
                  : parseFloat(getRowTotal(record)).toFixed(2)
                : 0}
            </span>
            {(this.props.is_export || this.props.is_multi_currency) &&
              this.props.conversion_factor != '' &&
              this.props.country.country_id != 75 && (
                <>
                  <p className="font-size-11 mt-0 mb-0 text-gray-10">
                    {' '}
                    {this.props.currency_symbol}
                    {parseInt(String(record.id).split('_')[0]) > -1
                      ? (getRowTotal(record) / parseFloat(this.props.conversion_factor)).toFixed(2)
                      : 0}{' '}
                  </p>
                </>
              )}
          </>
        ),
      },
      {
        title: '',
        align: 'right',
        responsive: ['md'],
        render: (_, record) => (
          <>
            <span>
              <Button
                type="danger"
                size="small"
                className="float-right"
                onClick={() => this.delete.showModal(record)}
              >
                <i className="fa fa-sm fa-trash" />
              </Button>
            </span>
          </>
        ),
      },
    ].filter(item => !item.hidden)

    const components = {
      body: {
        row: DraggableBodyRow,
      },
    }

    const moveRow = (dragIndex, hoverIndex) => {
      // console.log(dragIndex, hoverIndex)
      const dragRow = this.props.data[dragIndex]
      if (dragRow != undefined) {
        let data = [...this.props.data]
        data = update(this.props.data, {
          $splice: [
            [dragIndex, 1],
            [hoverIndex, 0, dragRow],
          ],
        })
        // Reassign keys based on the index
        const reassignKeysData = data.map((item, index) => ({
          ...item,
          key: index + 1,
        }))

        this.props.dataUpdate([...reassignKeysData])
      }
    }

    var active_cols = []
    if (this.props.document_type == 'purchase') {
      if (
        this.props.document_settings?.purchase_update_columns.split(',').includes('price_with_tax')
      ) {
        active_cols.push({
          name: 'Selling Price',
          field_type: 'number',
          is_active: true,
          update_key: 'selling_price',
        })
      }
      if (this.props.document_settings?.purchase_update_columns.split(',').includes('discount')) {
        active_cols.push({
          name: 'Product Discount',
          field_type: 'number',
          is_active: true,
          update_key: 'product_discount',
        })
      }
    }
    active_cols = [
      ...active_cols,
      ...this.props.custom_columns.filter(
        obj =>
          obj.is_active &&
          (obj.document_types.includes('All') ||
            obj.document_types.includes(this.props.document_type)),
      ),
    ]

    var result = active_cols.map(item => ({
      title: item.name,
      dataIndex: item.update_key == undefined ? item.name : item.update_key,
      width: '8%',
      minWidth: '500px',
      render: (text, data) => {
        if (item.field_type == 'number' || item.field_type == 'currency') {
          return (
            <InputNumber
              style={{ minWidth: '100px' }}
              placeholder={item.name}
              value={text}
              onChange={value => {
                if (item.update_key == 'product_discount' && value > 100) {
                  message.error("Discount can't be greater than 100")
                  return
                }
                this.onCustomChange(
                  value,
                  data.key,
                  item.update_key == undefined ? item.name : item.update_key,
                )
              }}
            />
          )
        } else if (item.field_type == 'date') {
          return (
            <DatePicker
              style={{ width: '100%', minWidth: '100px' }}
              placeholder={item.name}
              value={
                text != '' && this.dateFormatRegex.test(text)
                  ? dayjs(text, 'DD-MM-YYYY')
                  : undefined
              }
              format={this.dateFormat}
              onChange={value => {
                this.onCustomChange(
                  value == null ? '' : value.format(this.dateFormat),
                  data.key,
                  item.name,
                )
              }}
            />
          )
        } else if (item.field_type == 'datetime') {
          return (
            <DatePicker
              showTime
              style={{ width: '100%', minWidth: '100px' }}
              placeholder={item.name}
              format={this.dateTimeFormat}
              value={text != '' ? dayjs(text) : undefined}
              onChange={value => {
                this.onCustomChange(value.format('DD-MM-YYYY HH:mm:ss'), data.key, item.name)
              }}
            />
          )
        } else if (item.field_type == 'bigtext') {
          return (
            <TextArea
              placeholder={item.name}
              value={text}
              type="text"
              rows={1}
              className="pr-4 mr-2"
              onChange={value => {
                this.onCustomChange(value.target.value, data.key, item.name)
              }}
              disabled={
                (this.props.permissions.product_edit == 0 || item.is_link == 1) &&
                !this.props.is_column_linking_failed
              }
              style={{ width: '100%', minWidth: '100px' }}
            />
          )
        } else {
          return (
            <Input
              onChange={value => {
                this.onCustomChange(value.target.value, data.key, item.name)
              }}
              placeholder={item.name}
              style={{ width: '100%', minWidth: '100px' }}
              disabled={
                (this.props.permissions.product_edit == 0 || item.is_link == 1) &&
                !this.props.is_column_linking_failed
              }
              value={text}
            />
          )
        }
      },

      responsive: ['md'],
    }))
    var insertionIndex = 3,
      startIndex = 0
    tableColumn = tableColumn
      .slice(startIndex, insertionIndex)
      .concat(result)
      .concat(tableColumn.slice(insertionIndex))

    return (
      <div className="">
        <Affix offsetTop={48} onChange={this.affix}>
          <Row
            type="flex"
            justify="left"
            align="middle"
            className={
              !this.state.searchVisible
                ? 'bg-card-azure'
                : 'bg-card-azure shadow-sm px-4 pt-2 rounded-bottom'
            }
          >
            <Form
              className="center mb-2"
              layout="inline"
              onFinish={this.handleSubmit}
              ref={this.formRef}
              size="default"
            >
              <div className="">
                <h6 className="">
                  <span className="mr-1 font-weight-bold">Select Products</span>
                  <span className="mr-3">
                    <InfoIcon
                      text={
                        <span>
                          <span>Search your existing products using the search box below.</span>
                          <br />
                          <span>
                            Please click on <strong>Add New Product</strong> to add a new product.
                          </span>
                          <br />
                        </span>
                      }
                    />
                  </span>
                  {!this.state.searchVisible && (
                    <Button
                      type="text"
                      onClick={() => this.form.onAdd(this.formRef.current.getFieldValue('value'))}
                      className={'font-weight-bold xsmall'}
                    >
                      <i class="fa-solid fa-circle-plus mr-1"></i>
                      Add New Product?
                    </Button>
                  )}
                </h6>

                <Form.Item name="value" rules={[{ required: true, message: 'Fill Product Name' }]}>
                  <span className="d-flex">
                    <span className="d-none d-sm-block">
                      <Select
                        suffixIcon={<i className="fa-regular fa-chevron-down"></i>}
                        // disabled={true}
                        showSearch
                        allowClear={true}
                        className="responsive-select product-autocomplete-select"
                        placeholder="All Categories"
                        optionFilterProp="children"
                        onChange={e => {
                          this.setState({ selected_category: e })
                          this.auto.focus()
                          this.doSearch(this.formRef.current.getFieldValue('value') || '')
                        }}
                        filterOption={(input, option) =>
                          option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                        }
                        listHeight={200}
                        dropdownStyle={{ minWidth: '300px' }}
                      >
                        <Option value={'All'} key={0}>
                          {'All'}
                        </Option>
                        {this.props.product_categories.map(item => (
                          <Option value={item.category_name} key={item.category_name}>
                            {item.category_name}
                          </Option>
                        ))}
                      </Select>
                    </span>

                    <AutoComplete
                      ref={input => {
                        this.auto = input
                      }}
                      value={this.state.searchText}
                      onSearch={this.doSearch}
                      onSelect={this.onProductSelect}
                      options={this.state.products}
                      className="mr-2 pb-2 product-autocomplete"
                      style={{ width: '500px', height: '32px' }}
                      popupMatchSelectWidth={640}
                      dropdownMenuStyle={{ top: 18 }}
                      trigger="hover"
                      dropdownRender={menu => (
                        <>
                          {menu}
                          <div style={{ position: 'sticky', bottom: 0 }}>
                            <div className="bg-gray-75">
                              <Button
                                block
                                type="link-2"
                                className="font-weight-bolder text-left my-2 arrow-transition"
                                onMouseDown={e => e.preventDefault()}
                                onClick={() =>
                                  this.form.onAdd(this.formRef.current.getFieldValue('value'))
                                }
                              >
                                <i1 className="fa-solid fa-circle-plus mr-2"></i1>
                                Add New Product
                                <i className="fa-solid fa-arrow-right ml-2 arrow-transition"></i>
                              </Button>
                            </div>
                          </div>
                        </>
                      )}
                      placeholder="Search or scan barcode for existing products"
                      onPopupScroll={handleScroll}
                      id="addProduct"
                      onFocus={this.onInputFocus}
                    ></AutoComplete>
                  </span>
                </Form.Item>
              </div>

              <div className="">
                <h6 className="">Quantity</h6>
                <Form.Item name="qty" className="mt-0 pt-0">
                  <InputNumber
                    placeholder="Qty"
                    min={0}
                    onChange={this.qtyOnChange}
                    onKeyDown={this.handleEnter}
                    style={{ width: 135 }}
                    ref={input => {
                      this.qtyInput = input
                    }}
                    enterButton={
                      <>
                        <Button
                          type="primary"
                          className="font-weight-bold"
                          onClick={() => {
                            if (
                              this.state.selectedData.has_batches == 0 ||
                              !this.allowBatches(this.props.document_type)
                            ) {
                              this.formRef.current.submit()
                              this.setState({ searchText: '' })
                            } else {
                              if (
                                this.state.selectedData.has_batches == 1 ||
                                (this.props.document_type != 'purchase' &&
                                  this.state.selectedData.has_batches == 2)
                              ) {
                                this.productBatchesRef.current.showModal(
                                  this.state.selectedData,
                                  this.props.edit || this.props.convert || this.props.duplicate,
                                )
                              } else if (
                                this.state.selectedData.has_batches == 2 &&
                                this.props.document_type == 'purchase'
                              ) {
                                this.serialNumberRef.onAdd(this.state.selectedData, true, true)
                              }
                            }
                          }}
                        >
                          <i className="fa fa-plus mr-1" /> Add to Bill
                        </Button>
                      </>
                    }
                  />
                </Form.Item>
              </div>

              <div className="pt-1">
                <h6 className="font-size-12 text-white">{'.'}</h6>
                <Form.Item>
                  <Button
                    type="primary"
                    className="font-weight-bold"
                    onClick={() => {
                      if (
                        this.state.selectedData.has_batches == 0 ||
                        !this.allowBatches(this.props.document_type) ||
                        !this.state.selected
                      ) {
                        this.formRef.current.submit()
                        this.setState({ searchText: '' })
                      } else {
                        if (
                          this.state.selectedData.has_batches == 1 ||
                          (!this.props.document_type == 'purchase' &&
                            this.state.selectedData.has_batches == 2)
                        ) {
                          this.productBatchesRef.current.showModal(
                            this.state.selectedData,
                            this.props.edit || this.props.convert || this.props.duplicate,
                          )
                        } else if (
                          this.state.selectedData.has_batches == 2 &&
                          this.props.document_type == 'purchase'
                        ) {
                          this.serialNumberRef.onAdd(this.state.selectedData, true, true)
                        }
                      }
                    }}
                  >
                    <i className="fa fa-plus mr-1" />
                    <span className="d-none d-sm-inline">Add to Bill</span>
                  </Button>
                </Form.Item>
              </div>
            </Form>
          </Row>
        </Affix>
        <AddHsn ref={this.addHsn} addProductHsn={this.addProductHsn} />

        {this.props.data.length != -1 && (
          <DndProvider backend={HTML5Backend}>
            <Table
              size=""
              className="mb-1"
              dataSource={this.props.data}
              rowClassName={(record, index) =>
                this.state.currentKey == record.key ? 'new-row' : ''
              }
              columns={tableColumn}
              locale={{
                emptyText: (
                  <Empty
                    className="m-4"
                    image="https://gw.alipayobjects.com/zos/antfincdn/ZHrcdLPrvN/empty.svg"
                    //image="../../../welcome.png"
                    imageStyle={{
                      height: 115,
                    }}
                    description={
                      <span className="font-size-18 font-weight-medium">
                        Search existing products to add to this list or add new product to get
                        started! 🚀🚀🚀
                      </span>
                    }
                  >
                    <Button
                      type="action"
                      className="mr-2"
                      onClick={() => this.form.onAdd(this.formRef.current.getFieldValue('value'))}
                    >
                      <i className="fa fa-plus mr-1" /> Add New Product
                    </Button>
                  </Empty>
                ),
              }}
              expandIcon={({ expanded, onExpand, record }) =>
                expanded
                  ? this.props.showDescription && (
                      <Button
                        size="small"
                        type="transparent"
                        className="text-red"
                        onClick={e => onExpand(record, e)}
                      >
                        <span className="text-gray-500">
                          <i className="fa-solid fa-circle-chevron-up"></i>
                        </span>
                      </Button>
                    )
                  : this.props.showDescription && (
                      <Button
                        size="small"
                        type="transparent"
                        className="font-weight-medium"
                        onClick={e => onExpand(record, e)}
                      >
                        <span className="text-gray-500 d-flex align-items-center">
                          <i className="fa-solid fa-circle-plus mr-1"></i>
                          <span className="show-description">Desc</span>
                        </span>
                      </Button>
                    )
              }
              expandable={{
                onExpand: (expanded, record) => {
                  const dataSource = [...this.props.data]
                  const index = dataSource.findIndex(item => record.key === item.key)
                  dataSource[index].expanded = expanded
                  // this.setState({ data: dataSource })
                  this.props.dataUpdate(dataSource)
                },
                expandedRowRender: record => (
                  <MyEditor
                    editorState={record.editorState}
                    onChange={(text, editorState) =>
                      this.onDescriptionChange(editorState, record.key)
                    }
                  />
                ),
                rowExpandable: record => this.props.showDescription,
              }}
              pagination={false}
              components={components}
              onRow={(record, index) => ({
                index,
                moveRow,
              })}
              scroll={!isMobile && this.props.data.length > 0 && { x: 900 }}
              loading={{
                spinning: this.state.loading || this.props.loadingSave,
                indicator: (
                  <LoadingOutlined
                    style={{
                      fontSize: 30,
                    }}
                    spin
                  />
                ),
              }}
              footer={() => (
                <>
                  <div className="row">
                    <div className="col-12 col-md-12 text-right">
                      <div className="mr-2">
                        <span className="font-size-12 text-gray-500">Items / Qty </span>
                        <span className="font-size-12 text-gray-500">
                          {this.props.data.length + ' / ' + getTotalQty(this.props.data)}
                        </span>
                      </div>
                    </div>
                    <div className="col-12 col-md-4">
                      <h6 className="font-size-12 mt-2 mb-2 ml-2">
                        Apply discount(%) to all items?
                        <span>
                          <InfoIcon
                            text={
                              'This discount % will be applied to all products and overwrite any individual product discount.'
                            }
                          />
                        </span>
                      </h6>

                      <InputNumber
                        value={this.state.total_discount}
                        min={0}
                        max={100}
                        className="mb-2 ml-2 w-20p"
                        style={{
                          borderColor: '#05a357',
                          borderWidth: '2px',
                        }}
                        onChange={e => this.onTotalDiscountChange(e)}
                      />
                    </div>
                    <div className="col-12 col-md-8">
                      <div>
                        <Button
                          type="action-o"
                          className="mt-2 mr-2 mb-2 float-right font-weight-bold"
                          onClick={() =>
                            this.setState(
                              { has_extra_charges: !this.state.has_extra_charges },
                              () => {
                                if (!this.state.has_extra_charges)
                                  this.setState({ transportCharges: 0, packingCharges: 0 })
                              },
                            )
                          }
                        >
                          {this.state.has_extra_charges ? (
                            <i className="fa fa-minus-circle mr-2" />
                          ) : (
                            <i className="fa fa-plus-circle mr-2" />
                          )}{' '}
                          Additional Charges
                        </Button>
                      </div>
                      {this.state.has_extra_charges == true && (
                        <Table
                          size="small"
                          className="mb-1 pt-4 mr-2"
                          dataSource={this.props.document_custom_additional_charges}
                          columns={document_custom_additional_charges}
                          pagination={false}
                          footer={() => (
                            <div className="d-flex w-100 justify-content-end">
                              <Button
                                onClick={e => {
                                  store.get('paid') == 0
                                    ? this.subscriptionPlanRef.current.openModal('custom_fields')
                                    : this.custom_column.onAddCustomAdditionalCharges()
                                }}
                                type="transparent"
                                className="pt-2 font-weight-medium text-right"
                              >
                                <i className="fa fa-lg fa-plus-circle mr-2" />
                                <span className="mr-2">Add New Charges</span>
                                {/*<span onClick={() => this.tutorialRef.current.showModal()}>
                        <PlayIcon />
                      </span>*/}
                              </Button>
                            </div>
                          )}
                        />
                      )}
                    </div>
                  </div>
                </>
              )}
            />
          </DndProvider>
        )}

        <CustomColumnsForm
          onRef={ref => (this.custom_column = ref)}
          onAPICalled={() => {
            this.props.onAPICalled()
          }}
        />
        <EnhancedAdditionalCess
          onRef={ref => (this.additional_cess = ref)}
          updateRecord={record => this.updateRecord(record)}
        />

        <EnhancedProductAnalysis
          onRef={ref => (this.productAnalysis = ref)}
          menu="product"
          onProductUpdate={newData => this.updateProductInTable(newData)}
        />
        <ProductBatches
          ref={this.productBatchesRef}
          onFinishBatchesQty={(product, batches) => this.onFinishBatchesQty(product, batches)}
          document_type={this.props.document_type}
          old_items_before_edit={this.props.old_items_before_edit}
        />
        <ProductForm
          onRef={ref => (this.form = ref)}
          onAPICalled={newData => this.onAddNew(newData)}
        />
        <ProductBarcode onRef={ref => (this.barcode = ref)} menu="barcode" />
        <EnhancedDelete
          onRef={ref => (this.delete = ref)}
          document_type={'product'}
          handleDelete={this.onDelete}
        />
        <EnhancedSubscriptionModal onRef={ref => (this.subscription = ref)} />
        <SubscriptionPlanModal ref={this.subscriptionPlanRef} />

        <BatchingForm
          onRef={ref => (this.serialNumberRef = ref)}
          onAPICalled={(product, serialNumbers) =>
            this.onFinishSerialNumbers(product, serialNumbers)
          }
        />
        <SelectBatchConvert
          products={this.props.data}
          changeBatchQty={this.changeBatchQty}
          open={this.state.showSelectBatches}
          onClose={this.closeSelectBatches}
        />
        <TutorialVideoModal ref={this.tutorialRef} video_name={'add_additional_charges'} />
      </div>
    )
  }
}

function mapStateToProps(state, ownProps) {
  return {
    companyDetailsAdded: state.welcome.company_details_added,
    bankDetailsAdded: state.welcome.bank_details_added,
    productsAdded: state.welcome.products_added,
    invoices_created: state.welcome.invoices_created,
    permissions: state.permissions.permission,
    document: state.document,
    custom_columns: state.document.custom_column_data.custom_columns,
    columns_order: state.document.custom_column_data.order,
    total_amount_name: state.document.custom_column_data.total_amount_name,
    net_amount_name: state.document.custom_column_data.net_amount_name,
    qty_name: state.document.custom_column_data.qty_name,
    is_column_linking_failed: state.document.custom_column_data.is_column_linking_failed,
    column_id_names: state.document.custom_column_data.column_id_names,
    failed_names: state.document.custom_column_data.failed_names,
    taxes: state.document.tax_rates,
    user: state.user,
  }
}
class EnhancedTable extends React.Component {
  constructor(props) {
    super(props)
  }
  render() {
    return <ProductsTable {...this.props} />
  }
}

export default connect(mapStateToProps)(EnhancedTable)
