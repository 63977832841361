import { Badge, Button, Card, Dropdown, Image, List, Menu, Tooltip } from 'antd'
import dayjs from 'dayjs'
import React, { Component } from 'react'
import store from 'store'
const { Meta } = Card

class Actions extends Component {
  constructor(props) {
    super(props)
    this.state = {
      show: false,
      data: { updates: [] },
      last_updated_time: '',
      isDropdownVisible: false,
      isHovered: false,
    }
  }

  componentDidMount() {
    this.hideBadge(50)
  }

  get_state_code = state => {
    if (!state) {
      return ''
    }
    const stateParts = state.split('-')
    if (stateParts?.length <= 1) {
      return ''
    }
    const stateName = stateParts[1]
      .toLowerCase()
      .trim()
      .replace(/\s+/g, '')

    switch (stateName) {
      case 'andhrapradesh':
        return 'AP'
      case 'arunachalpradesh':
        return 'AR'
      case 'assam':
        return 'AS'
      case 'bihar':
        return 'BR'
      case 'chhattisgarh':
        return 'CG'
      case 'goa':
        return 'GA'
      case 'gujarat':
        return 'GJ'
      case 'haryana':
        return 'HR'
      case 'himachalpradesh':
        return 'HP'
      case 'jharkhand':
        return 'JH'
      case 'karnataka':
        return 'KA'
      case 'kerala':
        return 'KL'
      case 'madhyapradesh':
        return 'MP'
      case 'maharashtra':
        return 'MH'
      case 'manipur':
        return 'MN'
      case 'meghalaya':
        return 'ML'
      case 'mizoram':
        return 'MZ'
      case 'nagaland':
        return 'NL'
      case 'odisha':
        return 'OR'
      case 'punjab':
        return 'PB'
      case 'rajasthan':
        return 'RJ'
      case 'sikkim':
        return 'SK'
      case 'tamilnadu':
        return 'TN'
      case 'telangana':
        return 'TG'
      case 'tripura':
        return 'TR'
      case 'uttarpradesh':
        return 'UP'
      case 'uttarakhand':
        return 'UT'
      case 'westbengal':
        return 'WB'
      default:
        return ''
    }
  }

  async loadData() {
    let url =
      'https://gist.githubusercontent.com/nishanta96/b51dbc15eb0e6919104b3c85338fcc67/raw/product_updates.json'
    try {
      let res = await fetch(url)
      return await res.json()
    } catch (error) {
      console.log(error)
      return {
        updates: [],
      }
    }
  }

  is_paid = item => {
    if (
      item.is_paid === 'ALL' ||
      (item.is_paid === 'FREE' && (this.props.is_paid === 0 || this.props.is_paid === false)) ||
      (item.is_paid === 'PAID' && (this.props.is_paid === 1 || this.props.is_paid === true))
    ) {
      return true
    }
    return false
  }

  state_check = item => {
    try {
      if (
        item.state.includes('ALL') ||
        item.state.includes(this.get_state_code(this.props.state))
      ) {
        return true
      }
    } catch (err) {
      console.log(err)
    }

    return false
  }

  filter_item(item) {
    return this.is_paid(item) && this.state_check(item)
  }

  handleDropdownClick = () => {
    store.set('notification_seen_time', this.state.last_updated_time)
    this.setState({ show: false, isDropdownVisible: false })
  }

  hideBadge = async sec => {
    let data = await this.loadData()
    const filteredData = data.updates.filter(item => this.filter_item(item))
    data.updates = filteredData
    var show = false

    if (store.get('notification_seen_time')) {
      // Parse the date strings into Day.js objects
      var notification_seen_time = store.get('notification_seen_time')
      const notificationSeenTime = dayjs(notification_seen_time, 'DD-MM-YYYY hh:mm A')
      const lastUpdatedTime = dayjs(data.last_updated_time, 'DD-MM-YYYY hh:mm A')

      if (notificationSeenTime.isBefore(lastUpdatedTime)) {
        show = true
      } else {
        show = false
        store.set('notification_seen_time', data.last_updated_time)
      }
    } else {
      store.set('notification_seen_time', dayjs().format('DD-MM-YYYY hh:mm A'))
    }

    this.setState({ data, show, last_updated_time: data.last_updated_time })
  }

  handleDropdownVisibleChange = visible => {
    if (visible) {
      this.setState({ isHovered: false })
    }
    this.setState({ isDropdownVisible: visible })
  }

  menu = data => (
    <Menu>
      <Menu.Item>
        <a
          href="https://getswipe.in/product-updates"
          target="_blank"
          rel="noopener noreferrer"
          className="font-weight-bolder"
        >
          <Image
            preview={false}
            alt="what's new"
            src="https://vx-erp-resources.s3.ap-south-1.amazonaws.com/whatsnew.webp"
            className="text-center"
            style={{ borderRadius: '5px' }}
          />
        </a>
      </Menu.Item>
      <Menu.Divider />

      {data.updates.map((item, index) => (
        <>
          <Menu.Item key={index}>
            <List
              size="small"
              // bordered={false}
              itemLayout="horizontal"
              dataSource={item.body}
              renderItem={item1 => (
                <List.Item
                  onClick={e => window.open(item.link, item.new_tab ? '' : '_self')}
                  className="mb-0"
                >
                  <List.Item.Meta
                    title={
                      <a
                        href={item.link}
                        target={item.new_tab ? `_blank` : ''}
                        className="ml-0 pl-0"
                      >
                        <span className="font-size-18 font-weight-bold">{item.title}</span>
                      </a>
                    }
                    description={
                      <span className="font-weight-normal text-gray-500">
                        {item1}
                        {item.image != '' && (
                          <Image
                            preview={false}
                            alt={item.title}
                            src={item.image}
                            className="mt-3 text-center"
                            style={{ maxWidth: 500, borderRadius: '5px', border: '1px solid #bbb' }}
                          />
                        )}
                      </span>
                    }
                  />
                </List.Item>
              )}
            />
          </Menu.Item>
          <Menu.Divider />
        </>
      ))}
    </Menu>
  )

  handleTooltipVisibleChange = visible => {
    this.setState({ isHovered: visible })
  }

  render() {
    const tooltipVisible = this.state.isHovered

    return (
      <Tooltip
        trigger={['hover']}
        title="Announcements"
        placement="top"
        visible={tooltipVisible}
        onVisibleChange={this.handleTooltipVisibleChange}
      >
        <Dropdown
          overlay={this.menu(this.state.data)}
          trigger={['click']}
          placement="bottomRight"
          onVisibleChange={this.handleDropdownVisibleChange}
          overlayStyle={{
            maxWidth: 500,
            maxHeight: '90vh',
            overflowY: 'auto',
          }}
          onClick={this.handleDropdownClick}
        >
          <Button type="text" className="px-2 ml-1">
            <span className="font-weight-medium">
              <Badge dot={this.state.show}>
                <i className="fa-solid fa-bullhorn px-1 fa-fw text-gray-500"></i>
              </Badge>
            </span>
          </Button>
        </Dropdown>
        {this.state.isDropdownVisible && (
          <div
            style={{
              position: 'fixed',
              top: 0,
              left: 0,
              width: '100%',
              height: '100%',
              background: 'rgba(0, 0, 0, 0.25)', // Adjust the opacity as needed
              zIndex: 999, // Ensure the overlay appears below the dropdown
            }}
          />
        )}
      </Tooltip>
    )
  }
}

export default Actions
