import React, { Component } from 'react'

export class NoAccess extends Component {
  render() {
    return (
      <div
        className="d-flex flex-column justify-content-center align-items-center"
        style={{ minHeight: '70vh', opacity: 0.7 }}
      >
        <img src="../resources/images/noaccess.gif" alt="No Access" width="10%" />
        <p className="text-secondary text-center mt-3 h4">
          You don't have an access to this section. <br></br>Please contact the admin for more info.
        </p>
      </div>
    )
  }
}

export default NoAccess
