import { Button, Modal } from 'antd'
import React, { forwardRef, useEffect, useImperativeHandle, useState } from 'react'
import { getAPIPDF } from '../../services/jwt'

const EwaybillPdf = forwardRef((props, ref) => {
  const [viewPdf, setViewPdf] = useState(false)
  const [loading, setLoading] = useState(false)
  const [pdfData, setPdfData] = useState('')
  const closePdf = () => {
    setPdfData('')
    setViewPdf(false)
  }
  useImperativeHandle(ref, () => ({
    getEwayBillDocument,
  }))
  const arrayBufferToBase64 = buffer => {
    var binary = ''
    var bytes = new Uint8Array(buffer)
    var len = bytes.byteLength
    for (var i = 0; i < len; i++) {
      binary += String.fromCharCode(bytes[i])
    }
    return window.btoa(binary)
  }
  useEffect(() => {
    if (pdfData != '') {
      let base64Str = arrayBufferToBase64(pdfData)
      var obj = document.createElement('object')
      obj.style.width = '100%'
      obj.style.height = '842pt'
      obj.type = 'application/pdf'
      obj.data = 'data:application/pdf;base64,' + base64Str
      document.getElementById('ewaybillpdf').innerHTML = ''
      document.getElementById('ewaybillpdf').appendChild(obj)
    }
  }, [pdfData])

  const getEwayBillDocument = async object => {
    if (!loading) {
      setLoading(true)
      setPdfData('')
      const req = {
        invoice_serial_number: object.invoice_serial_number,
        serial_number: object.serial_number,
        document_type: object.document_type,
        new_hash_id: object.invoice_new_hash_id,
      }
      const data = await getAPIPDF('ewaybills', 'get_ewaybill_document', req)
      setPdfData(data.data)
      setViewPdf(true)
      setLoading(false)
    }
  }
  return (
    <Modal
      title={'E-Way Bill'}
      open={viewPdf}
      style={{ top: 20 }}
      width={900}
      onOk={() => closePdf()}
      onCancel={() => closePdf()}
      closeIcon={<i className="fa-solid fa-lg fa-xmark"></i>}
      footer={[
        <Button key="back" type="default" onClick={() => closePdf()}>
          Cancel
        </Button>,
      ]}
    >
      <div id="ewaybillpdf"></div>
    </Modal>
  )
})
export default EwaybillPdf
