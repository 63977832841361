import React from 'react'
import style from './style.module.scss'
import { connect, useSelector } from 'react-redux'

const mapStateToProps = state => {
  return {
    logo: state.settings.logo,
  }
}

const Footer = ({ dispatch, logo }) => {
  return (
    <div className={style.footer}>
      <div className={style.footerInner}>
        <a href="https://getswipe.in" target="_blank" rel="noopener noreferrer">
          <span>
            <img src={logo} className="mr-2 pb-1 height-22" alt="Swipe" />
          </span>
        </a>
        <br />
        <p className="mb-0">
          <span className="font-size-12">
            ©2024 NextSpeed Technologies Private Limited. All rights reserved.{' '}
          </span>
          <span className="font-size-12 text-primary">
            <i className="fa-solid fa-lock mr-1 ml-2"></i> Data is secured via 'bank-grade' security
          </span>
        </p>
      </div>
    </div>
  )
}

export default connect(mapStateToProps)(Footer)
