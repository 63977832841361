import {
  Button,
  Card,
  Col,
  Collapse,
  DatePicker,
  Divider,
  Empty,
  Modal,
  Popover,
  Row,
  Select,
  Table,
  Tabs,
  Timeline,
} from 'antd'

import EnhancedCustomerFrom from 'components/forms/customerForm'
import EnhancedInvoiceForm from 'components/forms/invoiceDetails'
import EnhancedVendorFrom from 'components/forms/vendorform'
import * as dayjs from 'dayjs'
import Ledger from 'pages/payment/ledger'
import React, { Component } from 'react'
import { TabPane } from 'react-bootstrap'
import { ranges } from 'services/axios'
import { downloadAPI, getAPI, getAPIData } from 'services/jwt'

import AutoFinish from 'components/autoFinish'
import { EnhancedSubscriptionModal } from 'components/modal/subscription'
import SubscriptionPlanModal from 'components/modal/subscription/subscriptionPlan'
import { FormattedMessage } from 'react-intl'
import { connect } from 'react-redux'
import { UserAvatar } from './utility'
import InfoComponent from 'components/badges/InfoComponent'
import { LoadingOutlined } from '@ant-design/icons'
import queryString from 'query-string'
import AuditHistory from './Activity'
const { Panel } = Collapse
const { RangePicker } = DatePicker
class EnhancedPartyAnalysis extends Component {
  dateFormat = 'DD-MM-YYYY'
  subscriptionPlanRef = React.createRef()
  constructor(props) {
    super(props)
    this.state = {
      loading: false,
      party_type: 'customer',
      party_id: -1,
      table_loading: false,
      details_collapse: false,
      data: {
        party_details: {},
        products: [],
      },
      bill_transactions: {
        num_records: 5,
        page: 0,
        pagination: {
          current: 1,
          pageSize: 5,
          defaultPageSize: 5,
          pageSizeOptions: ['5', '10', '20', '50', '100'],
        },
        columns: [
          'serial_number',
          'product_name2',
          'variant_name',
          'unit_price',
          'price_with_tax',
          'qty',
          'discount',
          'net_amount',
          'total_amount',
        ],
      },
      party_details: {},
      products: [],
      transactions: {
        num_records: 5,
        page: 0,
        pagination: {
          current: 1,
          pageSize: 5,
          defaultPageSize: 5,
          pageSizeOptions: ['5', '10', '20', '50', '100'],
        },
        columns: [
          'serial_number',
          'document_date',
          'net_amount',
          'tax_amount',
          'total_amount',
          'payment_status',
          'amount_paid',
          'amount_pending',
        ],
      },
      tab: '1',
      visualization: '1',
      transactions_data: [],
      tr_columns: [],
      start_date: dayjs()
        .startOf('month')
        .format('DD-MM-YYYY'),
      end_date: dayjs()
        .endOf('month')
        .format('DD-MM-YYYY'),
      transactions_type: 'All',
      request_type: 'Transactions',
      download: 0,
      tabs: ['Transactions', 'Bill-Wise Transactions'],
      menu_name: 'sales',
      doc_transactions: [
        {
          title: 'Sales',
          document_type: 'invoice',
          menu_name: 'sales',
          party_type: 'customer',
        },
        {
          title: 'Sales Returns',
          document_type: 'sales_return',
          menu_name: 'sales_returns',
          party_type: 'customer',
        },
        {
          title: 'Estimates',
          document_type: 'estimate',
          menu_name: 'estimates',
          party_type: 'customer',
        },
        {
          title: 'Purchases',
          document_type: 'purchase',
          menu_name: 'purchases',
          party_type: 'vendor',
        },
        {
          title: 'Purchase Returns',
          document_type: 'purchase_return',
          menu_name: 'purchase_returns',
          party_type: 'vendor',
        },
        {
          title: 'Purchase Orders',
          document_type: 'purchase_order',
          menu_name: 'purchase_orders',
          party_type: 'vendor',
        },
        {
          title: 'Delivery Challans',
          document_type: 'delivery_challan',
          menu_name: 'delivery_challans',
          party_type: 'customer',
        },
        {
          title: 'Pro Forma Invoices',
          document_type: 'pro_forma_invoice',
          menu_name: 'pro_forma_invoices',
          party_type: 'customer',
        },
      ],
      columns: [],
      dataSource: [],
      sorter: {},
      sort_type: '',
      search: '',
      product_id: -1,
      variant_name: '',
      variant_id: -1,
      timelineData: [],
      loading: false,
    }
  }

  componentDidMount() {
    this.props.onRef(this)
  }
  showModal(party_data, party_type) {
    this.setState(
      {
        party_details: party_data,
        party_id: party_data.id,
        party_type: party_type,
        loading: true,
        flag: true,
        menu_name: party_type == 'customer' ? 'sales' : 'purchases',
        timelineData: [],
      },
      () => this.callData(),
    )
  }
  callData = async () => {
    this.setState({ table_loading: true, loading: true })

    if (this.state.request_type === 'Bill-Wise Transactions') {
      const req = {
        date_range: `${this.state.start_date} - ${this.state.end_date}`,
        download: this.state.download,
        menu_name: this.state.menu_name || 'sales',
        num_records: this.state.bill_transactions.num_records,
        page: this.state.bill_transactions.page,
        party_id: this.state.party_id,
        product_id: this.state.product_id || -1,
        sorter: this.state.sorter,
        sort_type: this.state.sort_type,
        variant_name: this.state.variant_name,
        variant_id: this.state.variant_id,
      }
      const data = await downloadAPI('v2/reports', 'transaction_wise_items', req)
      if (data.success) {
        this.setState({
          bill_transactions: {
            ...this.state.bill_transactions,
            data: data.items,
            pagination: {
              ...this.state.bill_transactions.pagination,
              current: this.state.bill_transactions.page + 1,
              pageSize: this.state.bill_transactions.num_records,
              total: data.tr?.length,
            },
          },
          columns: data.columns
            .filter(col => this.state.bill_transactions.columns.includes(col.dataIndex))
            .sort(
              (a, b) =>
                this.state.bill_transactions.columns.indexOf(a.dataIndex) -
                this.state.bill_transactions.columns.indexOf(b.dataIndex),
            )
            .map(col => {
              col.fixed = ''
              return col
            }),
          dataSource: data.items,
        })
      }
    } else if (this.state.request_type === 'Transactions') {
      const req = {
        menu_name: this.state.menu_name,
        page: this.state.transactions.page,
        num_records: this.state.transactions.num_records,
        date_range: `${this.state.start_date} - ${this.state.end_date}`,
        party_id: this.state.party_id,
        download: this.state.download,
        sorter: this.state.sorter,
        sort_type: this.state.sort_type,
      }

      const data = await downloadAPI('v2/reports', 'transactions', req)
      if (data) {
        if (data.success) {
          this.setState(prev => ({
            transactions: {
              ...prev.transactions,
              data: data.transactions,
              pagination: {
                ...prev.transactions.pagination,
                current: prev.transactions.page + 1,
                pageSize: prev.transactions.num_records,
                total: data.tr?.[0]?.count,
              },
            },
            columns: data.columns
              .filter(col => prev.transactions.columns.includes(col.dataIndex))
              .sort(
                (a, b) =>
                  prev.transactions.columns.indexOf(a.dataIndex) -
                  prev.transactions.columns.indexOf(b.dataIndex),
              )
              .map(col => {
                col.fixed = ''
                return col
              }),
            dataSource: data.transactions,
          }))
        }
      }
    } else if (this.state.request_type === 'audit_history') {
      let query_params = queryString.stringify({
        [this.state.party_type + '_id']: this.state.party_id,
      })
      const data = await getAPIData('v2/' + this.state.party_type, 'get_history?' + query_params)

      if (data.success) {
        this.setState({
          timelineData: data.audit_history,
        })
      }
    }

    this.setState({
      loading: false,
      table_loading: false,
      download: 0,
    })
  }

  handleTableChange = (pagination, filters, sorter) => {
    const page = pagination.current - 1
    const num_records = pagination.pageSize

    if (!('order' in sorter && sorter.order)) {
      sorter = {
        order: 'ascend',
        field: '',
      }
    }

    if (this.state.request_type === 'Bill-Wise Transactions') {
      this.setState(
        {
          bill_transactions: {
            ...this.state.bill_transactions,
            num_records: num_records,
            page: page,
            pagination: pagination,
          },
          sorter: sorter.order == 'ascend' ? 'asc' : 'desc',
          sort_type: sorter.field || '',
        },
        () => {
          this.callData()
        },
      )
    } else if (this.state.request_type === 'Transactions') {
      this.setState(
        {
          transactions: {
            ...this.state.transactions,
            num_records: num_records,
            page: page,
            pagination: pagination,
            sorter: sorter,
          },
          sorter: sorter.order == 'ascend' ? 'asc' : 'desc',
          sort_type: sorter.field || '',
        },
        () => {
          this.callData()
        },
      )
    }
  }

  handleTabChange = key => {
    if (key != 'Ledger') {
      this.setState({ request_type: key, download: 0 }, () => this.callData())
    } else {
      try {
        setTimeout(() => {
          this.ledger.callData(0, 5)
        }, 2000)
      } catch (e) {
        console.log(e)
        this.ledger.callData(0, 5)
      }
    }
  }

  render() {
    const { request_type } = this.state

    let pagination = ''
    if (request_type == 'Bill-Wise Transactions') {
      pagination = this.state.bill_transactions.pagination
    } else if (request_type == 'Transactions') {
      pagination = this.state.transactions.pagination
    }

    return (
      <div className="">
        {this.state.flag && (
          <Modal
            title={
              <>
                <Row>
                  <Col span={18}>{this.state.party_details['name']}</Col>
                  <Col span={6}>
                    {((this.state.party_type == 'customer' &&
                      this.props.permissions.customer_edit == 1 &&
                      this.state.party_data?.shopify_party_id?.length == 0) ||
                      (this.state.party_type == 'vendor' &&
                        this.props.permissions.vendor_edit == 1)) && (
                      <Button
                        type="warning"
                        size="medium"
                        onClick={event => {
                          event.stopPropagation()
                          this.state.party_type == 'customer'
                            ? this.customer.onFill(this.state.party_id)
                            : this.vendor.onFill(this.state.party_details)
                        }}
                        className="float-right mr-5"
                      >
                        <i className="fa fa-edit mr-1 " />
                        <span className="d-none d-sm-inline font-weight-bold">
                          <FormattedMessage id="button.edit" />
                        </span>
                      </Button>
                    )}
                  </Col>
                </Row>
              </>
            }
            open={this.state.flag}
            onCancel={e => this.setState({ flag: false, search: '', request_type: 'Transactions' })}
            closeIcon={<i className="fa-solid  fa-xmark"></i>}
            wrapStyle={{ backdropFilter: 'blur(3px)' }}
            width={'80%'}
            minHeight={'90vh'}
            centered={true}
            destroyOnClose
            footer={[]}
            loading={this.state.loading}
          >
            <div className="mb-2">
              {/* Analysis Card */}
              <Tabs
                defaultActiveKey="Transactions"
                className="mt-2"
                style={{ width: '100%' }}
                onChange={e => {
                  this.handleTabChange(e)
                }}
              >
                {this.state.tabs.map((tab, index) => (
                  <TabPane tab={tab} key={tab}>
                    <div className="mt-0">
                      <div className="d-flex align-items-center row mx-1">
                        <RangePicker
                          allowClear={false}
                          ranges={ranges}
                          className="mb-3 mr-2"
                          disabled={this.state.loading}
                          onChange={(date, dateStrings) => {
                            this.setState(
                              {
                                start_date: dateStrings[0],
                                end_date: dateStrings[1],
                              },
                              () => this.callData(),
                            )
                          }}
                          defaultValue={[dayjs().startOf('month'), dayjs().endOf('month')]}
                          style={{
                            width: '30%',
                            borderRadius: '5px',
                            cursor: 'pointer',
                          }}
                          format={this.dateFormat}
                        />

                        {(tab == 'Transactions' || tab == 'Bill-Wise Transactions') && (
                          <Select
                            showSearch
                            allowClear
                            placeholder="Select Transaction Type"
                            className="mb-3 mr-2"
                            disabled={this.state.loading}
                            value={this.state.menu_name}
                            onChange={value => {
                              let doc_type = this.state.doc_transactions.filter(
                                (type, index) => type.menu_name == value,
                              )[0]
                              this.setState(
                                {
                                  menu_name: value,
                                  party_type: doc_type?.party_type || 'customer',
                                },
                                () => {
                                  this.callData()
                                },
                              )
                            }}
                            style={{
                              width: '20%',
                              borderRadius: '5px',
                              cursor: 'pointer',
                            }}
                          >
                            {this.state.doc_transactions
                              .filter(type => type.party_type == this.state.party_type)
                              .map((type, index) => (
                                <Option key={type.document_type} value={type.menu_name}>
                                  {type.title}
                                </Option>
                              ))}
                          </Select>
                        )}
                        {tab == 'Bill-Wise Transactions' && (
                          <AutoFinish
                            className="mb-3 mr-2"
                            style={{ width: '30%' }}
                            placeholder={'Search Products'}
                            autoFocus={true}
                            searchType={'product'}
                            onRef={ref => (this.autoFinish = ref)}
                            callData={(value, option) => {
                              this.setState(
                                {
                                  product_id: option.product_id,
                                  variant_name: option.variant_name,
                                  variant_id: option.variant_id,
                                  search: option.name,
                                },
                                () => this.callData(),
                              )
                            }}
                            dropdownMatchSelectWidth={true}
                            onChange={value => {
                              if (value == '') {
                                this.setState(
                                  { product_id: -1, variant_name: '', variant_id: -1 },
                                  () => this.callData(),
                                )
                              }
                              this.setState({ search: value })
                            }}
                            searchText={this.state.search}
                          />
                        )}

                        <div className=" ml-auto d-flex-inline flex-column">
                          <>
                            <Popover
                              content={
                                <>
                                  <div className="font-weight-bold">Download</div>
                                  <div className="d-flex flex-column">
                                    <Button
                                      type="action"
                                      className="mx-2 my-2"
                                      onClick={() =>
                                        this.setState({ download: 1 }, () => this.callData())
                                      }
                                    >
                                      <i className="fa-duotone fa-file-excel mr-2" />
                                      Download Excel
                                    </Button>
                                    <Button
                                      type="action-3"
                                      className="mx-2 my-2"
                                      onClick={() =>
                                        this.setState({ download: 2 }, () => this.callData())
                                      }
                                    >
                                      <i className="fa-duotone fa-file-pdf mr-2" />
                                      Download PDF
                                    </Button>
                                  </div>
                                </>
                              }
                              trigger={['click']}
                              placement="bottom"
                            >
                              <Button className="  mr-2">
                                <span className="d-none d-sm-inline mr-2">Download</span>
                                <span>
                                  <i class="fa-duotone fa-down-to-line d   mt-1" />
                                </span>
                              </Button>
                            </Popover>
                          </>
                        </div>
                      </div>

                      {/* table Details */}
                      {this.state.visualization == '1' &&
                        (this.state.dataSource?.length > 0 ? (
                          <Table
                            columns={this.state.columns}
                            dataSource={this.state.dataSource}
                            pagination={pagination}
                            size="small"
                            onChange={this.handleTableChange}
                            bordered={false}
                            loading={this.state.table_loading}
                            scroll={{ x: 'max-content' }}
                            onRow={(record, rowIndex) => {
                              return {
                                className: 'cursor-pointer',
                                onClick: event => {
                                  this.invoiceForm.showDrawer(this.state.menu_name, {
                                    ...record,
                                    new_hash_id: record.hash_id,
                                  })
                                },
                              }
                            }}
                          />
                        ) : (
                          <Empty />
                        ))}
                    </div>
                  </TabPane>
                ))}

                {this.props.permissions.ledgers == 1 && (
                  <TabPane tab="Ledger" key="Ledger">
                    <div>
                      <div className="row">
                        <div className="col-12">
                          {this.state.party_details.id != -1 && (
                            <Ledger
                              selectedUser={this.state.party_details}
                              // onAPICalled={this.ledgerPDFView}
                              onAPICalled={e => {}}
                              type={this.state.party_type}
                              onRef={ref => (this.ledger = ref)}
                              callData={e => {}}
                            />
                          )}
                        </div>
                      </div>
                    </div>
                  </TabPane>
                )}
                {this.props.permissions.history == 1 && (
                  <TabPane tab="Activity" key="audit_history">
                    {this.state.loading ? (
                      <p className="w-100 d-flex justify-content-center my-5">
                        <LoadingOutlined style={{ fontSize: 24 }} className="text-primary" spin />
                      </p>
                    ) : (
                      <AuditHistory auditHistory={this.state.timelineData} />
                    )}
                  </TabPane>
                )}
              </Tabs>
            </div>
            <EnhancedSubscriptionModal onRef={ref => (this.subscription = ref)} />
            <SubscriptionPlanModal ref={this.subscriptionPlanRef} />
            <EnhancedCustomerFrom
              onRef={ref => (this.customer = ref)}
              onAPICalled={() => console.log('')}
            />
            <EnhancedVendorFrom
              onRef={ref => (this.vendor = ref)}
              onAPICalled={() => console.log('')}
            />
            <EnhancedInvoiceForm onRef={ref => (this.invoiceForm = ref)} callData={this.callData} />
          </Modal>
        )}
      </div>
    )
  }
}
const mapStateToProps = state => ({
  permissions: state.permissions.permission,
})
export default connect(mapStateToProps, {})(EnhancedPartyAnalysis)
