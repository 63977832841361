import { Button, Dropdown, Input, Menu, Tooltip } from 'antd'
import CustomerForm from 'components/forms/customerForm'
import ProductForm from 'components/forms/productForm'
import { VendorForm } from 'components/forms/vendorform'
import CustomPricing from 'components/modal/pricing'
import { EnhancedSubscriptionModal } from 'components/modal/subscription'
import SubscriptionPlanModal from 'components/modal/subscription/subscriptionPlan'
import ChangeCompany from 'components/other/ChangeCompany'
import { displayRazorpay } from 'components/other/utility'
import dayjs from 'dayjs'
import { history } from 'index'
import { GetBeaconValue, GetLogo } from 'pages/firebasejs'
import IntercomSupportProvider from 'pages/support/interCom'
import React, { useEffect, useRef, useState } from 'react'
import { connect, useSelector } from 'react-redux'
import { Link, withRouter } from 'react-router-dom'
import { useIntercom } from 'react-use-intercom'
import { config } from 'services/axios'
import { currentAccount, getAPI, getAPIData } from 'services/jwt'
import store from 'store'
import { v4 as uuidv4 } from 'uuid'
import Actions from './Actions'
import UserMenu from './UserMenu'
import style from './style.module.scss'

import { Beacon } from '@beacon.li/bar'
import InventoryForm from 'components/forms/inventoryForm'
import PayInForm from 'components/forms/payForm'
import { setBeacon } from 'redux/permissions/actions'
import Notifications from './Notifications'
import { update_notification_count, set_notification_count } from 'redux/document/actions'

const mapStateToProps = state => {
  const { menu, settings, user, permissions, welcome } = state
  return {
    menuData: menu.menuData,
    isMenuCollapsed: settings.isMenuCollapsed,
    isMobileView: settings.isMobileView,
    isMenuUnfixed: settings.isMenuUnfixed,
    isMenuShadow: settings.isMenuShadow,
    leftMenuWidth: settings.leftMenuWidth,
    menuColor: settings.menuColor,
    logo: settings.logo,
    theme: settings.theme,
    role: user.role,
    companies: user.companies,
    user: user,
    states: state.document.states,
    tds_sections: state.document.tdsSections,
    countries: state.document.countries,
    companyDetailsAdded: welcome.company_details_added,
    bankDetailsAdded: welcome.bank_details_added,
    productsAdded: welcome.products_added,
    invoices_created: welcome.invoices_created,
    permissions: permissions.permission,
    notification_count: state.document.notification_count,
  }
}

const TopBar = ({ dispatch, user, theme, logo, ...props }) => {
  const [tour, setTour] = useState(false)
  const permissions = useSelector(state => state.permissions.permission)
  const is_beacon_loaded = useSelector(state => state.permissions.is_beacon_loaded)
  const [selectedCompany, setCompany] = useState('')
  const [stateCompanies, setStateCompanies] = useState(user)
  const [visible, setVisible] = useState(false)
  // const [logo, setLogo] = useState(logo)
  const [loading, setLoading] = useState(false)
  const [currentPage, setCurrentPage] = useState(1)
  const [filteredCompanies, setFilteredCompanies] = useState([])
  const [companiesList, setCompaniesList] = useState([])
  const [totalCompanies, setTotalCompanies] = useState(0)
  const [tipVisible, setTipVisible] = useState(false)
  const customPricingRef = useRef(null)
  const subscriptionRef = useRef(null)
  const [is_renewal, setIsRenewal] = useState(false)
  const [renewal_dates, setRenewalDates] = useState({})
  const [downloadList, setdownloadList] = useState([])
  const [show_beacon_search, setShowBeaconSearch] = useState(false)
  const [isCreateDropdownVisible, setIsDropdownVisible] = useState(false)

  var show_one_day_timer = false
  var formatted_end_time = ''
  if (document.cookie.indexOf('one_time_offer') >= 0) {
    /*
    // cookie exists
    */
    var cookieValue = document.cookie.replace(
      /(?:(?:^|.*;\s*)one_time_offer\s*\=\s*([^;]*).*$)|^.*$/,
      '$1',
    )
    if (cookieValue) {
      var cookie_data = JSON.parse(cookieValue)
      var currentTime = new Date()
      var endTime = new Date(cookie_data.end_time)

      const options = {
        year: 'numeric',
        month: 'short',
        day: 'numeric',
        hour: 'numeric',
        minute: '2-digit',
        second: '2-digit',
        hour12: false,
      }

      formatted_end_time = endTime.toLocaleString('en-US', options)

      if (currentTime < endTime) {
        show_one_day_timer = true
      }
    }
  }

  // useEffect(() => {
  //   setTimeout(() => {
  //     Beacon.load(config.url.BEACON_ID)
  //     Beacon.loadUser(store.get('user_id'), {
  //       paid: store.get('paid'),
  //     })
  //   }, 2000)
  // }, [])

  const { boot, shutdown, hide, show, showNewMessage, update } = useIntercom()

  useEffect(() => {
    const BeaconSupportCallback = () => {
      if (props.permissions.intercom == 1) {
        showNewMessage()
      } else {
        window.open(
          'https://api.whatsapp.com/send/?phone=918121335436&text=Hello%20Swipe! I am looking for ',
          '_blank',
        )
      }
    }

    Beacon.addCallback('beacon_support_callback', BeaconSupportCallback)

    setStateCompanies(user)
    let current_company = user.companies.filter(item => item.current_company == 1)[0]
      ?.organization_name
    setCompany(current_company)

    if ((props.companyDetailsAdded || props.bankDetailsAdded) && props.invoices_created) {
      store.set('app.settings.tutorialTouched', true)
    }

    // const FetchRemoteDetails = async () => {
    //   const logo = await GetLogo()
    //   if (logo != null && logo != undefined && logo != '') {
    //     setLogo(logo)
    //   }

    //   const beacon_value = await GetBeaconValue()
    //   if (beacon_value != null && beacon_value != undefined && beacon_value != '') {
    //     if (props.permissions.company_id % 100 < beacon_value && beacon_value != 0) {
    //       setShowBeaconSearch(true)
    //     }
    //   }
    // }

    // FetchRemoteDetails()
  }, [user])

  const setTheme = nextTheme => {
    dispatch({
      type: 'settings/SET_THEME',
      payload: {
        theme: nextTheme,
      },
    })
    dispatch({
      type: 'settings/CHANGE_SETTING',
      payload: {
        setting: 'menuColor',
        value: nextTheme === 'swipedark' ? 'swipedark' : 'light',
      },
    })
    dispatch({
      type: 'settings/CHANGE_SETTING',
      payload: {
        setting: 'logo',
        value:
          nextTheme === 'swipedark'
            ? '/resources/images/logo4-dark.svg'
            : '/resources/images/logo4.svg',
      },
    })
  }

  const updateRenewalDates = async () => {
    const renewal_dates = await getAPIData('sales', 'check_renewal')
    if (renewal_dates) {
      setIsRenewal(true)
      setRenewalDates({
        start_date: dayjs(renewal_dates.start_date).format('DD MMM YYYY'),
        end_date_1_year: dayjs(renewal_dates.end_date_1_year).format('DD MMM YYYY'),
        end_date_3_years: dayjs(renewal_dates.end_date_3_years).format('DD MMM YYYY'),
      })
    } else {
      setIsRenewal(false)
    }
  }

  useEffect(() => {
    if (store.get('days_left') <= 15) {
      updateRenewalDates()
    } else {
      setIsRenewal(false)
    }
  }, [])

  // useEffect(() => {
  //   const data = await getAPIData('utils', 'get_download_files')
  //   if (data) {
  //     setdownloadList(data.data)
  //   }
  // }, [])

  useEffect(() => {
    if (store.get('features_shown') == undefined) {
      store.set('features_shown', '')
    }
    if (store.get(`app.settings.tutorialTouched`) && stateCompanies?.companies.length <= 2) {
      if (stateCompanies.companies.length > 0 && stateCompanies.companies[0].company_id != 0) {
        setTipVisible(true)
      }
    }
  })

  const customerRef = useRef()
  const vendorRef = useRef()
  const productRef = useRef()
  const inventoryFormRef = useRef(null)
  const payInRef = useRef()
  const subscriptionPlanRef = useRef()
  const handleRef = ref => {
    subscriptionRef.current = ref
  }

  const handleMenuClick = e => {
    // setVisible(false)
    setLoading(true)
    stateCompanies.companies.every(async (element, index, array) => {
      if (element['company_id'] == e) {
        let req = {
          company_id: element.company_id,
        }

        if (!store.get('device_hash')) {
          const device_hash = uuidv4()
          await store.set('device_hash', device_hash)
        }

        const data = await getAPI('user', 'get_token', req)
        if (data.success) {
          store.set('accessToken', data.token)
          store.set('selectedCompany', element)
        }
        const data2 = await currentAccount()
        await dispatch({
          type: 'user/SET_STATE',
          payload: {
            selectedCompany: element,
          },
        })
        store.set('page', 0)
        window.location.reload()
        return false
      }
      return true
    })
  }

  const dropdownMenu = (
    <Menu label="Quick Create">
      <Menu.Item disabled>
        <span className="font-size-18 font-weight-bold text-gray-900">Create</span>
      </Menu.Item>
      <Menu.Divider />
      {permissions.sales == 1 && (
        <Menu.Item>
          <Link to="/create/invoice" className="arrow">
            {/*<i className="fa-regular fa-file-invoice-dollar mr-3 fa-fw" />*/}
            <span className="d-flex justify-content-between align-items-center">
              <span>Invoice</span>
              <i className="fa-solid fa-arrow-right ml-2"></i>
            </span>
          </Link>
        </Menu.Item>
      )}
      {permissions.purchases == 1 && (
        <Menu.Item>
          <Link to="/create/purchase" className="arrow">
            {/*<i className="fa-regular fa-file-plus mr-3 fa-fw" />*/}
            <span className="d-flex justify-content-between align-items-center">
              <span>Purchase Invoice</span>
              <i className="fa-solid fa-arrow-right ml-2"></i>
            </span>
          </Link>
        </Menu.Item>
      )}
      {permissions.purchase_orders == 1 && (
        <Menu.Item>
          <Link to="/create/purchase_order" className="arrow">
            {/*<i className="fa-regular fa-file-invoice mr-3 fa-fw" />*/}
            <span className="d-flex justify-content-between align-items-center">
              <span>Purchase Order</span>
              <i className="fa-solid fa-arrow-right ml-2"></i>
            </span>
          </Link>
        </Menu.Item>
      )}
      {permissions.estimates == 1 && (
        <Menu.Item>
          <Link to="/create/estimate" className="arrow">
            {/*<i className="fa-regular fa-memo mr-3 fa-fw" />*/}
            <span className="d-flex justify-content-between align-items-center">
              <span>Quotation</span>
              <i className="fa-solid fa-arrow-right ml-2"></i>
            </span>
          </Link>
        </Menu.Item>
      )}
      {permissions.pro_forma_invoices == 1 && (
        <Menu.Item>
          <Link to="/create/pro_forma_invoice" className="arrow">
            {/*<i className="fa-regular fa-memo-circle-check mr-3 fa-fw" />*/}
            <span className="d-flex justify-content-between align-items-center">
              <span>Pro Forma Invoice</span>
              <i className="fa-solid fa-arrow-right ml-2"></i>
            </span>
          </Link>
        </Menu.Item>
      )}
      {user?.selectedCompany?.country_code == 'IN' && permissions.delivery_challans == 1 && (
        <Menu.Item>
          <Link to="/create/delivery_challan" className="arrow">
            {/*<i className="fa-regular fa-truck mr-3 fa-fw" />*/}
            <span className="d-flex justify-content-between align-items-center">
              <span>Delivery Challan</span>
              <i className="fa-solid fa-arrow-right ml-2"></i>
            </span>
          </Link>
        </Menu.Item>
      )}
      {permissions.sales_returns == 1 && (
        <Menu.Item>
          <Link to="/create/sales_return" className="arrow">
            {/*<i className="fa-regular fa-file-circle-xmark mr-3 fa-fw" />*/}
            <span className="d-flex justify-content-between align-items-center">
              <span>Sales Return</span>
              <i className="fa-solid fa-arrow-right ml-2"></i>
            </span>
          </Link>
        </Menu.Item>
      )}
      {permissions.purchase_returns == 1 && (
        <Menu.Item>
          <Link to="/create/purchase_return" className="arrow">
            {/*<i className="fa-regular fa-file-minus mr-3 fa-fw" />*/}
            <span className="d-flex justify-content-between align-items-center">
              <span>Purchase Return</span>
              <i className="fa-solid fa-arrow-right ml-2"></i>
            </span>
          </Link>
        </Menu.Item>
      )}
      {permissions.expenses == 1 && (
        <>
          <Menu.Item>
            <Link to="/expenses" className="arrow">
              {/*<i className="fa-regular fa-file-circle-minus mr-3 fa-fw" />*/}
              <span className="d-flex justify-content-between align-items-center">
                <span>Expense</span>
                <i className="fa-solid fa-arrow-right ml-2"></i>
              </span>
            </Link>
          </Menu.Item>
          <Menu.Divider />
        </>
      )}
      {permissions.customers == 1 && (
        <Menu.Item>
          {/* <Link onClick={event => customerRef.current.onAdd()} className="arrow">
            <span className="d-flex justify-content-between align-items-center">
              <span>Customer</span>
              <i className="fa-solid fa-arrow-right ml-2"></i>
            </span>
          </Link> */}
          <div
            className="arrow"
            onClick={event => {
              closeDropdownSlowly()
              customerRef.current.onAdd()
            }}
          >
            <span className="d-flex justify-content-between align-items-center">
              <span>Customer</span>
              <i className="fa-solid fa-arrow-right ml-2"></i>
            </span>
          </div>
        </Menu.Item>
      )}
      {permissions.vendors == 1 && (
        <Menu.Item>
          {/* <Link onClick={event => vendorRef.current.onAdd()} className="arrow">
            <span className="d-flex justify-content-between align-items-center">
              <span>Vendor</span>
              <i className="fa-solid fa-arrow-right ml-2"></i>
            </span>
          </Link> */}
          <div className="arrow" onClick={event => vendorRef.current.onAdd()}>
            <span className="d-flex justify-content-between align-items-center">
              <span>Vendor</span>
              <i className="fa-solid fa-arrow-right ml-2"></i>
            </span>
          </div>
        </Menu.Item>
      )}
      <Menu.Divider />
      <Menu.Item>
        <div
          className="arrow"
          onClick={event => {
            closeDropdownSlowly()
            payInRef.current.onAdd({}, undefined, true)
          }}
        >
          <span className="d-flex justify-content-between align-items-center">
            <span>Pay In</span>
            <i className="fa-solid fa-arrow-right ml-2"></i>
          </span>
        </div>
      </Menu.Item>
      <Menu.Item>
        <div
          className="arrow"
          onClick={event => {
            closeDropdownSlowly()
            payInRef.current.onAdd({}, undefined, false)
          }}
        >
          <span className="d-flex justify-content-between align-items-center">
            <span>Pay Out</span>
            <i className="fa-solid fa-arrow-right ml-2"></i>
          </span>
        </div>
      </Menu.Item>
      <Menu.Divider />
      {permissions.products == 1 && (
        <Menu.Item>
          {/* <Link onClick={event => productRef.current.onAdd()} className="arrow">
            <span className="d-flex justify-content-between align-items-center">
              <span>Product</span>
              <i className="fa-solid fa-arrow-right ml-2"></i>
            </span>
          </Link> */}
          <div
            className="arrow"
            onClick={event => {
              closeDropdownSlowly()
              productRef.current.onAdd()
            }}
          >
            <span className="d-flex justify-content-between align-items-center">
              <span>Product</span>
              <i className="fa-solid fa-arrow-right ml-2"></i>
            </span>
          </div>
        </Menu.Item>
      )}

      {!permissions.serial_number == 1 && (
        <>
          <Menu.Item>
            <div
              className="arrow"
              onClick={event => {
                closeDropdownSlowly()
                inventoryFormRef.current && inventoryFormRef.current.onFill({}, true, undefined)
              }}
            >
              <span className="d-flex justify-content-between align-items-center">
                <span>Stock In</span>
                <i className="fa-solid fa-arrow-right ml-2"></i>
              </span>
            </div>
          </Menu.Item>
          <Menu.Item>
            <div
              className="arrow"
              onClick={event => {
                closeDropdownSlowly()
                inventoryFormRef.current && inventoryFormRef.current.onFill({}, false, undefined)
              }}
            >
              <span className="d-flex justify-content-between align-items-center">
                <span>Stock Out</span>
                <i className="fa-solid fa-arrow-right ml-2"></i>
              </span>
            </div>
          </Menu.Item>
        </>
      )}
    </Menu>
  )

  // const downloadMenu = (
  //   <Menu>
  //     {downloadList.map((item, index) => (
  //       <Menu.Item>
  //         <Link onClick={event => item.download_url} className="arrow">
  //           {/*<i className="fa-regular fa-file mr-3 fa-fw" />*/}
  //           <span className="d-flex justify-content-between align-items-center">
  //             <span>
  //               {item.type.toUpperCase()} - {item.date}
  //             </span>
  //             <i className="fa-solid fa-arrow-right ml-2"></i>
  //           </span>
  //         </Link>
  //       </Menu.Item>
  //     ))}
  //   </Menu>
  //   // <div className="d-flex flex-column">
  //   //   <List
  //   //     itemLayout="horizontal"
  //   //     dataSource={[
  //   //       {
  //   //         title: 'Ant Design Title 1',
  //   //       },
  //   //       {
  //   //         title: 'Ant Design Title 2',
  //   //       },
  //   //       {
  //   //         title: 'Ant Design Title 3',
  //   //       },
  //   //       {
  //   //         title: 'Ant Design Title 4',
  //   //       },
  //   //     ]}
  //   //     renderItem={(item, index) => (
  //   //       <List.Item>
  //   //         <List.Item.Meta
  //   //           avatar={''}
  //   //           title={<a href="https://ant.design">{item.title}</a>}
  //   //           description="Ant Design, a design language for background applications, is refined by Ant UED Team"
  //   //         />
  //   //       </List.Item>
  //   //     )}
  //   //   />
  //   // </div>
  // )

  const downloadMenu = (
    <Menu>
      <Menu.Item>
        <span className="ml-0 pl-0 font-size-18 font-weight-bold">Downloads</span>
      </Menu.Item>

      {downloadList.map((item, index) => (
        <Menu.Item key={index}>
          <div>
            <a href={item.download_url} target={`_blank`} className="ml-0 pl-0 mt-1">
              <div className="font-weight-bold">
                {item.type
                  .split('_')
                  .join(' ')
                  .toUpperCase()}{' '}
              </div>
              <div className="font-weight-normal text-gray-900 font-size-12">{item.date_range}</div>
            </a>
          </div>
        </Menu.Item>
      ))}
    </Menu>
  )

  const handleVisibleChange = flag => {
    setTipVisible(false)
    setFilteredCompanies(stateCompanies.companies)
    setTotalCompanies(stateCompanies.companies.length)
    setCompaniesList(stateCompanies.companies.slice(0, 9))
    setVisible(flag)
  }
  const handlePageChange = page => {
    const startIndex = (page - 1) * 9
    const endIndex = startIndex + 9
    setCurrentPage(page)
    setCompaniesList(filteredCompanies.slice(startIndex, endIndex))
  }
  const handleSearch = value => {
    let list = stateCompanies.companies.filter(
      item =>
        item.company_name.toLowerCase().includes(value.toLowerCase()) ||
        item.organization_name.toLowerCase().includes(value.toLowerCase()),
    )
    setTotalCompanies(list.length)
    setFilteredCompanies(list)
    setCompaniesList(list.slice(0, 9))
    setCurrentPage(1)
  }

  const handleDropdownVisibleChange = visible => {
    setIsDropdownVisible(visible)
  }

  const closeDropdownSlowly = () => {
    setTimeout(() => {
      setIsDropdownVisible(false)
    }, 300)
  }

  const addNewBussiness = () => {
    if (store.get('paid') === 0) {
      // if (subscriptionRef.current) {
      //   subscriptionRef.current.showModal()
      // }
      subscriptionPlanRef.current.openModal('ewaybill', 4)
    } else {
      setVisible(false)
      if (store.get('add_companies') <= 0) {
        window.open(
          'https://api.whatsapp.com/send/?phone=918121335436&text=Hello%20Swipe! I want to add New Company. Please give me details for Business Plan.',
          '_self',
        )
      } else {
        history.push('/add_new_company')
      }
    }
  }
  const handleInventoryRef = ref => {
    inventoryFormRef.current = ref
  }
  const handleCustomerRef = ref => {
    customerRef.current = ref
  }
  useEffect(() => {
    return () => {
      inventoryFormRef.current = null
      customerRef.current = null
    }
  }, [])

  // useEffect(() => {
  //   const handleKeyPress = event => {
  //     if (event.ctrlKey && event.key === 'k') {
  //       event.preventDefault()
  //       loadBeacon()
  //     }
  //   }
  //   document.addEventListener('keydown', handleKeyPress)
  //   return () => {
  //     document.removeEventListener('keydown', handleKeyPress)
  //   }
  // }, [])

  const loadBeacon = async () => {
    if (is_beacon_loaded) {
      Beacon.open()
    }
    if (!is_beacon_loaded) {
      await Beacon.load(config.url.BEACON_ID)
      await Beacon.loadUser(store.get('user_id'), {
        paid: store.get('paid'),
      })
      const id = setTimeout(() => {
        Beacon.open()
        clearTimeout(id)
      }, 500)
      dispatch(setBeacon(true))
    }
  }

  return (
    <div className={style.topbar}>
      <div className="mr-3">
        <span className="mr-3">
          <a href="/list/sales">
            <img
              src={logo}
              style={{
                height: '24px',
              }}
              alt="Swipe"
            />
          </a>
        </span>
      </div>

      <ChangeCompany />

      <div className="mr-auto"></div>

      <CustomPricing
        ref={customPricingRef}
        onSelectPlan={(plan, years, addons) => displayRazorpay(plan, years, addons)}
        is_renewal={is_renewal}
        renewal_dates={renewal_dates}
      />

      {/*{store.get('paid') == 0 && store.get('days_left') > 15 && !isMobile && (
        <div className="mr-auto">
          <div className="text-warning">
            <span className="text-primary font-weight-medium font-size-14 mr-2">
              Looking for e-Invoicing? Get 25% OFF!
            </span>
            <Button
              onClick={event => customPricingRef.current.showModal(6)}
              size="small"
              type="primary"
              shape="round"
              className="font-weight-bold"
            >
              {CONSTANTS.PRICING_BUTTON}
              Subscribe Now!
            </Button>
          </div>
        </div>
      )}*/}

      {/* <div className="mr-3">
        <Button
          size="small"
          type="action-o"
          className="font-weight-bold mx-1"
          onClick={() => {
            setTour(true)
          }}
        >
          <span className="ml-1">Tour</span>
          <i className="fa-light fa-circle-play ml-1"></i>
        </Button>
      </div>*/}
      {/*<div className="mr-3"><LanguageSwitcher /></div>*/}

      <div className="mr-2">
        {/*{props.permissions.intercom == 0 && (
          <Button
            onClick={event =>
              window.open(
                'https://api.whatsapp.com/send?phone=918121335436&text=Hello%20Swipe! I need help with',
              )
            }
            size="small"
            type="help"
            shape="round"
            className="t-20 font-weight-bolder"
          >
            <i className="fa-brands fa-lg font-weight-bold fa-whatsapp mr-2" />
            <span>Help?</span>
          </Button>
        )}*/}
        {props.permissions.intercom == 1 && <IntercomSupportProvider size="small" />}
      </div>
      {/*<div className="mr-2 d-xs-none d-sm-none d-m-block d-lg-block d-xl-block align-items-center">
        <a href="https://play.google.com/store/apps/details?id=in.swipe.app" target="_blank">
          <img src="/resources/images/google-play-badge.png" height="36" />
        </a>
      </div>*/}

      {/* <div className="mr-1 align-items-center ">
        {show_beacon_search && (
          <Input
            className=""
            onClick={e => {
              loadBeacon()
            }}
            placeholder="Search for help"
            suffix={<span className="text-gray-300">cmd/ctrl + k</span>}
            prefix={<i className="fa-solid fa-magnifying-glass text-gray-300"></i>}
            style={{ width: '256px', top: '-3.5px' }}
          />
        )}
      </div> */}
      {/*<Tooltip title="Referral" placement="top" trigger={['hover']}>
        <Button
          type="text"
          className="px-2 ml-1"
          onClick={() => history.push('/user?tab=referral')}
        >
          <i class="fa fa-gift"></i>
        </Button>
      </Tooltip>*/}

      {/*<div className="ml-1">
        <Tooltip title="Switch Dark/Light Mode">
          <Button
            type="text"
            className="px-2"
            onClick={() => setTheme(theme === 'default' ? 'swipedark' : 'default')}
          >
            {theme == 'default' ? (
              <i class="fa fa-moon text-gray-500"></i>
            ) : (
              <i class="fa fa-sun text-gray-500"></i>
            )}
          </Button>
        </Tooltip>
      </div>*/}
      <div className="ml-1">
        <Tooltip title="Create" trigger={['hover']} placement="top">
          <Dropdown
            trigger={['click']}
            overlay={dropdownMenu}
            visible={isCreateDropdownVisible}
            onVisibleChange={handleDropdownVisibleChange}
            overlayStyle={{ minWidth: 256 }}
          >
            <Button type="text" className="px-2 ml-1">
              <i className="fa-solid fa-bolt fa-fw px-1"></i>
              {/*<span className="ml-1 ">Create</span>*/}
            </Button>
          </Dropdown>
          {isCreateDropdownVisible && (
            <div
              style={{
                position: 'fixed',
                top: 0,
                left: 0,
                width: '100%',
                height: '100%',
                background: 'rgba(0, 0, 0, 0.25)', // Adjust the opacity as needed
                // zIndex: 999, // Ensure the overlay appears below the dropdown
              }}
            />
          )}
        </Tooltip>
      </div>
      {/* <div className="float-right">
        <Dropdown overlay={downloadMenu} overlayStyle={{ minWidth: 243 }}>
          <i className="fa-regular fa-arrow-down fa-fw fa-lg" />
        </Dropdown>
      </div> */}

      {/* Notifications */}
      <div className="float-right">
        <Notifications
          count={props.notification_count}
          state={props}
          setNotificationCount={count => {
            dispatch(set_notification_count(count))
          }}
          updateNotificationCount={count => {
            dispatch(update_notification_count(count))
          }}
        />
      </div>
      <div className="float-right">
        <Actions state={user.selectedCompany.state} is_paid={user.paid} />
      </div>
      <div className="float-right">
        <UserMenu />
      </div>
      {/* {tour && <TutorialComponent onClose={() => setTour(false)} />} */}
      <CustomerForm
        onRef={handleCustomerRef}
        ref={customerRef}
        onAPICalled={(a, b, c) => {}}
        permissions={permissions}
        states={props?.states}
        tds_sections={props?.tds_sections}
        countries={props?.countries}
        dispatch={dispatch}
      />
      <VendorForm
        onRef={() => {}}
        ref={vendorRef}
        onAPICalled={(a, b) => {}}
        permissions={permissions}
        states={props?.states}
        tds_sections={props?.tds_sections}
        countries={props?.countries}
        dispatch={dispatch}
      />
      <ProductForm
        onRef={() => {}}
        ref={productRef}
        onAPICalled={(a, b) => {}}
        dispatch={dispatch}
      />

      <PayInForm onRef={() => {}} ref={payInRef} show_party_selection={true} />
      <InventoryForm onRef={handleInventoryRef} onAPICalled={(a, b) => {}} />
      <EnhancedSubscriptionModal onRef={handleRef} />
      <SubscriptionPlanModal ref={subscriptionPlanRef} />
      {/* {selectedCompany && (
        <FeatureTour
          type="change_company"
          onClick={() => {
            handleVisibleChange(true)
          }}
          visible={tipVisible}
          show_for_new={true}
        />
      )} */}
    </div>
  )
}

export default withRouter(connect(mapStateToProps)(TopBar))
