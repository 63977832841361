import { Button, Checkbox, Dropdown, Menu } from 'antd'
import React, { useEffect, useRef, useState } from 'react'

const MultiSelectDropdown = ({
  list,
  title,
  label,
  label_id,
  onChange,
  default_value = undefined,
  showLeftBorder = true,
  showCount = false,
}) => {
  const [selectedList, setSelectedList] = useState([])
  const [open, setOpen] = useState(false)
  const dropdownRef = useRef()
  const handleClick = event => {
    if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
      setOpen(false)
    }
  }

  useEffect(() => {
    document.body.addEventListener('click', handleClick)
    if (default_value) {
      setSelectedList([default_value])
    }
    return () => {
      document.body.removeEventListener('click', handleClick)
    }
  }, [])

  return (
    <div ref={dropdownRef}>
      <Dropdown
        overlay={
          <Menu style={{ maxHeight: 400 }}>
            {selectedList.map(item_id => {
              const selectedItem = list.find(item => item[label_id] === item_id)
              return (
                <Menu.Item key={selectedItem[label_id]} onClick={e => e.domEvent.stopPropagation()}>
                  <Checkbox
                    onChange={e => {
                      const updatedSelectedList = selectedList.filter(id => id !== item_id)
                      setSelectedList([...updatedSelectedList])
                      onChange(updatedSelectedList)
                    }}
                    className="w-100"
                    checked={true}
                  >
                    {selectedItem[label]}
                  </Checkbox>
                </Menu.Item>
              )
            })}
            {list
              .filter(item => !selectedList.includes(item[label_id]))
              .map(item => (
                <Menu.Item key={item[label_id]} onClick={e => e.domEvent.stopPropagation()}>
                  <Checkbox
                    onChange={e => {
                      const updatedSelectedList = e.target.checked
                        ? [...selectedList, item[label_id]]
                        : selectedList.filter(each_item => each_item !== item[label_id])
                      setSelectedList([...updatedSelectedList])
                      onChange(updatedSelectedList)
                    }}
                    className="w-100"
                    checked={selectedList.includes(item[label_id])}
                  >
                    {item[label]}
                  </Checkbox>
                </Menu.Item>
              ))}
            {/* {list.map(item => (
              <Menu.Item key={item[label_id]} onClick={e => e.domEvent.stopPropagation()}>
                <Checkbox
                  onChange={e => {
                    let selectedItems = []
                    if (e.target.checked) {
                      selectedItems = [...selectedList, item[label_id]]
                    } else {
                      selectedItems = selectedList.filter(each_item => each_item != item[label_id])
                    }
                    // console.log(selectedList)
                    setSelectedList([...selectedItems])
                    onChange(selectedItems)
                  }}
                  className="w-100"
                  checked={selectedList.includes(item[label_id])}
                >
                  {item[label]}
                </Checkbox>
              </Menu.Item>
            ))} */}
            {list.length == 0 && <Menu.Item>No Data</Menu.Item>}
          </Menu>
        }
        open={open}
      >
        <Button
          className="mr-2 btn-secondary-font-normal"
          style={
            showLeftBorder
              ? {}
              : {
                  borderTopLeftRadius: 0,
                  borderBottomLeftRadius: 0,
                }
          }
          type="input-button"
          onClick={() => setOpen(!open)}
        >
          <span style={{ color: selectedList.length > 0 ? 'blue' : '' }}>
            {title}
            {showCount ? ' (' + String(selectedList.length) + '/' + String(list.length) + ')' : ''}
          </span>
          <i
            className="fa-solid fa-chevron-down ml-2"
            style={{ color: selectedList.length > 0 ? 'blue' : '' }}
          />
        </Button>
      </Dropdown>
    </div>
  )
}

export default MultiSelectDropdown
