import {
  SET_ALL_USERS,
  SET_DEFAULT_WAREHOUSE,
  SET_WAREHOUSES,
  SET_WAREHOUSE_PERMISSIONS,
  SET_WAREHOUSE_ROLES,
} from './types'
const initialState = {
  warehouses: undefined,
  all_users: [],
  warehouse_roles: [],
  labels: [],
  warehouse_permissions: {},
  default_warehouse_id: undefined,
}
export default function wareHouseReducer(state = initialState, action) {
  switch (action.type) {
    case SET_WAREHOUSES:
      return {
        ...state,
        warehouses: action.payload,
      }
    case SET_ALL_USERS:
      return {
        ...state,
        all_users: action.payload,
      }
    case SET_WAREHOUSE_ROLES:
      return {
        ...state,
        warehouse_roles: action.payload.roles,
        labels: action.payload.labels,
      }

    case SET_WAREHOUSE_PERMISSIONS:
      return {
        ...state,
        warehouse_permissions: action.payload,
      }
    case SET_DEFAULT_WAREHOUSE:
      return {
        ...state,
        default_warehouse_id: action.payload,
      }
    default:
      return { ...state }
  }
}
