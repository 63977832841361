import { Layout } from 'antd'
import classNames from 'classnames'
import PageLoading from 'components/loaders/PageLoading'
import React from 'react'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import style from './style.module.scss'

const mapStateToProps = ({ settings, ...state }) => {
  return {
    logo: settings.logo,
    isGrayTopbar: settings.isGrayTopbar,
    isCardShadow: settings.isCardShadow,
    isSquaredBorders: settings.isSquaredBorders,
    isBorderless: settings.isBorderless,
    authPagesColor: settings.authPagesColor,
    loading: state.user.pageLoading,
  }
}

const AuthLayout = ({
  children,
  logo,
  isGrayTopbar,
  isCardShadow,
  isSquaredBorders,
  isBorderless,
  authPagesColor,
  loading,
}) => {
  return !loading ? (
    <Layout>
      <Layout.Content className="m-0 bg-gray-75">
        <div
          className={classNames(`${style.container}`)}
          style={{
            backgroundImage: 'url(https://vx-erp-resources.s3.ap-south-1.amazonaws.com/login.png)',
          }}
        >
          <div className={style.containerInner}>{children}</div>
        </div>
      </Layout.Content>
    </Layout>
  ) : (
    <PageLoading />
  )
}

export default withRouter(connect(mapStateToProps)(AuthLayout))
