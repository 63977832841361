import { Button, Card, Spin, Tabs } from 'antd'
import CouponModal from 'components/modal/coupons'
import React, { useEffect } from 'react'
import { getAPI, getAPIData } from 'services/jwt'
import { useSelector } from 'react-redux'

const { TabPane } = Tabs

function CouponsList(props) {
  const [data, setData] = React.useState([])
  const [loading, setLoading] = React.useState(false)
  const coupon = React.useRef()
  const user = useSelector(state => state.user)

  const getCoupons = async () => {
    const coupons_data = await getAPIData('v2/company', 'get_active_coupons')
    if (coupons_data.success) {
      let couponsData = coupons_data.coupons.map((item, index) => {
        return {
          ...item,
          key: index,
          disabled: item.minimum_order_value > props.total_amount,
          error:
            item.minimum_order_value > props.total_amount
              ? `Add ${user.selectedCompany.currency_symbol}${parseFloat(
                  item.minimum_order_value - props.total_amount,
                ).toFixed(2)} more to avail this coupon`
              : '',
        }
      })
      //sort the coupons data based on the disabled
      couponsData = couponsData.sort((a, b) => {
        return a.disabled - b.disabled
      })

      setData(couponsData)
    }
    setLoading(false)
  }
  useEffect(() => {
    setLoading(true)
    getCoupons()
  }, [])

  const onApply = async coupon => {
    const req = {
      coupon: coupon,
      amount: props.total_amount,
      customers: props.customers.map(item => {
        return {
          ...item,
          customer_id: item.id,
          type: 'customer',
        }
      }),
    }
    const verify = await getAPI('v2/company', 'verify_applied_coupon', req)
    if (verify.success) {
      if (verify.valid) {
        props.onApply(coupon, verify.discount, verify.message)
      } else {
        let coupons = data.map(item => {
          if (item.coupon_id == coupon.coupon_id) {
            return {
              ...item,
              error: verify.message,
            }
          } else {
            return item
          }
        })

        setData(coupons)
      }
    }
  }

  return (
    <div>
      {/* <div className="d-flex flex-wrap">
        <div className="mr-auto pr-3">
          <div className="text-nowrap d-inline-block">
            <div className="kit__utils__heading">
              <h4>
                <span className=" font-weight-bolder">Discount Coupons</span>{' '}
                <span className="align-middle"></span>
              </h4>
            </div>
          </div>
        </div>
        <div className="d-flex flex-wrap font-size-16">
          <div className="d-inline-flex align-items-center float-right">
            <Button
              className="mr-2 font-weight-bold"
              type="action"
              onClick={() => {
                console.log(coupon)
                coupon.current.showModal()
              }}
            >
              <i className="fa fa-badge-percent mr-2" />
              Create a Coupon
            </Button>
          </div>
        </div>
      </div> */}
      <div className="col-12 col-lg-12 px-0">
        <div className="pb-2">
          <div>
            {loading && (
              <Spin
                size="large"
                style={{
                  position: 'absolute',
                  top: '50%',
                  left: '50%',
                  transform: 'translate(-50%, -50%)',
                }}
                indicator={<i className="fa fa-spinner fa-spin fa-3x fa-fw" />}
              />
            )}
            {!loading && (
              <div className="row mt-2 py-2">
                {data.map((item, index) => {
                  return (
                    <div className="col-12 mx-auto">
                      <CouponCard coupon={item} onApply={onApply} />
                    </div>
                  )
                })}
              </div>
            )}
          </div>
        </div>
      </div>

      <CouponModal ref={coupon} onApiCalled={getCoupons} />
    </div>
  )
}

export default CouponsList

export function CouponCard(props) {
  const { coupon, onApply } = props
  return (
    <Card
      className={`mb-4 py-2 w-80 shadow-sm border-0 rounded ${
        coupon.status == 'expired' ? 'bg-gray-100' : ''
      }`}
    >
      <div className="d-flex justify-content-between">
        <div className="font-size-18 font-weight-bold">
          <span>
            <i
              class={`fa-regular fa-badge-percent mr-1 ${
                coupon.status == 'expired' ? 'text-danger' : 'text-success'
              }`}
            />
          </span>
          {coupon.coupon_code}
        </div>
      </div>
      <div className="d-flex flex-column">
        <div className="font-size-14 text-muted text-truncate">{coupon.description}</div>
      </div>

      <div className="d-flex justify-content-between mt-3">
        <div>
          {props.name != '4' && (
            <div className="font-size-18 text-danger font-weight-bold">
              <span style={{ cursor: 'pointer' }}>
                <Button
                  type={coupon.disabled ? 'default' : 'primary'}
                  onClick={() => onApply(coupon)}
                  className="mr-2 font-weight-bold"
                  disabled={coupon.disabled}
                >
                  Apply
                </Button>
              </span>
            </div>
          )}
          {coupon.error != '' && <div className="font-size-14 text-danger">{coupon.error}</div>}
        </div>
      </div>
    </Card>
  )
}
