import { Text, View } from '@react-pdf/renderer'
import { Fragment } from 'react'
import { HIDE_PRICES } from '../../../utils/constants'
import { styles as getStyles } from './styles'

const borderColor = '#142328'

const InvoiceTableHeader = ({ invoice, type }) => {
  var styles = getStyles(invoice)
  let no_of_default_cols = invoice.invoice_settings.hide_qty ? 8 : 9
  let no_of_dc_cols = invoice.invoice_settings.hide_qty ? 2 : 3
  let no_of_with_out_tax_cols = invoice.invoice_settings.hide_qty ? 5 : 6
  const hide_hsn = invoice.invoice_settings.hide_hsn
  // console.log(hide_hsn);
  no_of_default_cols = hide_hsn ? no_of_default_cols - 0.8 : no_of_default_cols
  no_of_with_out_tax_cols = hide_hsn ? no_of_with_out_tax_cols - 0.8 : no_of_with_out_tax_cols
  var FONT_FACTOR = invoice.invoice_settings.font_size
  var single_col = (1 * 100) / (no_of_default_cols + invoice.custom_col_names.length)
  var single_col2 = (1 * 100) / (no_of_with_out_tax_cols + invoice.custom_col_names.length)
  var dc_col = (1 * 100) / (no_of_dc_cols + invoice.custom_col_names.length)
  var font_size = (FONT_FACTOR * (8 * 8)) / (9 + invoice.custom_col_names.length)
  var font_size2 = (FONT_FACTOR * (9 * 15)) / (16 + invoice.custom_col_names.length)
  const column_labels = invoice.column_labels

  const rows = (
    <>
      {(type == 'Delivery Challan' || type == HIDE_PRICES) && (
        <>
          <View style={[styles.invoiceTableHeader_container, styles.globalContainer]}>
            <Text
              style={{
                ...styles.invoiceTableHeader_cell,
                textAlign: 'left',
                paddingLeft: 4.5,
                width: `${0.2 * dc_col}%`,
              }}
            >
              {column_labels.id}
            </Text>
            <Text
              style={{
                ...styles.invoiceTableHeader_cell,
                textAlign: 'left',
                width: `${1.8 * dc_col}%`,
              }}
            >
              {column_labels.product_name}
            </Text>
            {invoice.custom_col_names.map((item, index) => (
              <Text
                key={index}
                style={{
                  borderRightWidth: 0.36,
                  borderRightColor: '#333333',
                  paddingRight: 3.6,
                  paddingVertical: 1.8,
                  wordWrap: 'break-all',
                  textAlign: 'right',
                  width: `${dc_col}%`,
                }}
              >
                {item}
              </Text>
            ))}
            {invoice.invoice_settings.hide_qty == 0 && (
              <Text
                style={{
                  ...styles.invoiceTableHeader_cell,
                  ...styles.invoiceTableHeader_med,
                  textAlign: 'right',
                  paddingRight: 4.5,
                  width: `${1 * dc_col}%`,
                }}
              >
                {column_labels.qty}
              </Text>
            )}
          </View>
        </>
      )}

      {type != 'Delivery Challan' && type != HIDE_PRICES && (
        <>
          {(invoice.with_tax == 0 || invoice.company.gstin.length < 15) && (
            <View fixed style={[styles.invoiceTableHeader_container, styles.globalContainer]}>
              <Text
                style={{
                  ...styles.invoiceTableHeader_cell,
                  textAlign: 'left',
                  fontSize: font_size2,
                  width: `${0.2 * single_col2}%`,
                }}
              >
                {column_labels.id}
              </Text>

              <Text
                style={{
                  ...styles.invoiceTableHeader_cell,
                  textAlign: 'left',
                  fontSize: font_size2,
                  width: `${2 * single_col2}%`,
                }}
              >
                {column_labels.product_name}
              </Text>

              {!hide_hsn && (
                <Text
                  style={{
                    ...styles.invoiceTableHeader_cell,
                    textAlign: 'right',
                    paddingRight: 4.5,
                    fontSize: font_size2,
                    width: `${0.8 * single_col2}%`,
                  }}
                >
                  {invoice.invoice_settings.labels.hsn_code}
                </Text>
              )}

              {invoice.custom_col_names.map((item, index) => (
                <Text
                  key={index}
                  style={{
                    ...styles.invoiceTableHeader_cell,
                    fontSize: font_size2,
                    textAlign: 'right',
                    paddingRight: 4.5,
                    width: `${single_col2}%`,
                  }}
                >
                  {item}
                </Text>
              ))}

              <Text
                style={{
                  ...styles.invoiceTableHeader_cell,
                  fontSize: font_size2,
                  paddingRight: 4.5,
                  textAlign: 'right',
                  width: `${single_col2}%`,
                }}
              >
                {column_labels.unit_price}
              </Text>

              {invoice.invoice_settings.hide_qty == 0 && (
                <Text
                  style={{
                    ...styles.invoiceTableHeader_cell,
                    paddingRight: 4.5,
                    fontSize: font_size2,
                    textAlign: 'right',
                    width: `${single_col2}%`,
                  }}
                >
                  {column_labels.qty}
                </Text>
              )}

              <Text
                style={{
                  ...styles.invoiceTableHeader_cell,
                  paddingRight: 4.5,
                  borderRight: 'none',
                  fontSize: font_size2,
                  textAlign: 'right',
                  width: `${single_col2}%`,
                }}
              >
                {column_labels.total_amount}
              </Text>
            </View>
          )}

          {invoice.with_tax == 1 && invoice.company.gstin.length == 15 && (
            <View fixed style={[styles.invoiceTableHeader_container, styles.globalContainer]}>
              <Text
                style={{
                  ...styles.invoiceTableHeader_cell,
                  textAlign: 'left',
                  fontSize: font_size,
                  width: `${0.4 * single_col}%`,
                }}
              >
                {column_labels.id}
              </Text>

              <Text
                style={{
                  ...styles.invoiceTableHeader_cell,
                  fontSize: font_size,
                  textAlign: 'left',
                  width: `${2.6 * single_col}%`,
                }}
              >
                {column_labels.product_name}
              </Text>

              {invoice.custom_col_names.map((item, index) => (
                <Text
                  key={index}
                  style={{
                    ...styles.invoiceTableHeader_cell,
                    textAlign: 'right',
                    paddingRight: 4.5,
                    width: `${single_col}%`,
                    fontSize: font_size,
                  }}
                >
                  {item}
                </Text>
              ))}

              {!hide_hsn && (
                <Text
                  style={{
                    ...styles.invoiceTableHeader_cell,
                    textAlign: 'right',
                    paddingRight: 4.5,
                    fontSize: font_size,
                    width: `${0.8 * single_col}%`,
                  }}
                >
                  {invoice.invoice_settings.labels.hsn_code}
                </Text>
              )}

              <Text
                style={{
                  ...styles.invoiceTableHeader_cell,
                  textAlign: 'right',
                  paddingRight: 4.5,
                  fontSize: font_size,
                  width: `${1.2 * single_col}%`,
                }}
              >
                {column_labels.unit_price}
              </Text>

              {invoice.invoice_settings.hide_qty == 0 && (
                <Text
                  style={{
                    ...styles.invoiceTableHeader_cell,
                    textAlign: 'right',
                    paddingRight: 4.5,
                    fontSize: font_size,
                    width: `${0.9 * single_col}%`,
                  }}
                >
                  {column_labels.qty}
                </Text>
              )}

              <Text
                style={{
                  ...styles.invoiceTableHeader_cell,
                  textAlign: 'right',
                  fontSize: font_size,
                  paddingRight: 4.5,
                  width: `${single_col}%`,
                }}
              >
                {column_labels.net_amount}
              </Text>

              <Text
                style={{
                  ...styles.invoiceTableHeader_cell,
                  fontSize: font_size,
                  textAlign: 'right',
                  paddingRight: 4.5,
                  width: `${1.1 * single_col}%`,
                }}
              >
                {column_labels.tax_amount}
              </Text>

              <Text
                style={{
                  ...styles.invoiceTableHeader_cell,
                  textAlign: 'right',
                  borderRight: 'none',
                  paddingRight: 4.5,
                  fontSize: font_size,
                  width: `${single_col}%`,
                }}
              >
                {column_labels.total_amount}
              </Text>
            </View>
          )}
        </>
      )}
    </>
  )
  return <Fragment>{rows}</Fragment>
}

export default InvoiceTableHeader
