import { Badge, Button, Card, Col, Collapse, Drawer, Image, Row } from 'antd'
import React, { Component } from 'react'

import ProductForm from 'components/forms/productForm'
import ProductBarcode from 'components/modal/barcode'
import { isMobile } from 'react-device-detect'
import ProductAnalysis from './productAnalysis'
import { connect } from 'react-redux'

const { Panel } = Collapse

class ProductDetailsView extends Component {
  dateFormat = 'DD-MM-YYYY'
  formRef = React.createRef()
  constructor(props) {
    super(props)
    this.state = {
      viewDetails: false,
      product: '',
      product_id: '',
    }
  }

  async componentDidMount() {
    this.props.onRef(this)
  }

  componentWillUnmount() {
    this.props.onRef(null)
  }

  showDrawer = async record => {
    this.setState({ viewDetails: true, product: record, product_id: record.id })
  }
  onClose = () => {
    this.setState({
      viewDetails: false,
    })
  }

  getPaidClass = data => {
    switch (data) {
      case 'Cash':
        return 'cash'
      case 'Card':
        return 'card'
      case 'UPI':
        return 'upi'
      default:
        return 'card'
    }
  }

  getStatusClass = data => {
    switch (data) {
      case 'pending':
        return 'warning'
      case 'paid':
        return 'success'
      case 'cancelled':
        return 'danger'
      case 'partially paid':
        return 'partial'
      default:
        return 'danger'
    }
  }

  render() {
    const dotOptions = { minimumFractionDigits: 2, maximumFractionDigits: 2 }
    return (
      <>
        <Drawer
          title={
            <>
              <div className="d-flex align-items-center">
                <div className="mr-auto">
                  <span className="font-weight-bold text-gray-700 font-size-21 mr-2">
                    Product Details
                  </span>
                </div>
                <div>
                  <Button
                    type="warning"
                    size="small"
                    className="mr-2 font-size-12 font-weight-bold"
                    onClick={event => {
                      event.stopPropagation()
                      this.form.onFill(this.state.product)
                    }}
                  >
                    <i className="fa fa-edit fa-md mr-1 ml-1" />
                    Edit
                    <span className="d-none d-sm-inline font-size-12"></span>
                  </Button>
                </div>
              </div>
            </>
          }
          width={isMobile ? '80%' : '50%'}
          height={isMobile ? '90%' : '100%'}
          placement={isMobile ? 'top' : 'right'}
          closeIcon={<i className="fa-solid fa-xmark"></i>}
          closable={true}
          onClose={this.onClose}
          visible={this.state.viewDetails}
          destroyOnClose
          footer={
            <div
              style={{
                textAlign: 'left',
              }}
            >
              <Button type="danger" onClick={this.onClose} style={{ marginLeft: 0 }}>
                Close
              </Button>
            </div>
          }
        >
          {this.state.product && (
            <div>
              <div>
                {/* <div>
                  <span className="font-weight-bold text-gray-700 font-size-21 mr-2">
                    Product Details
                  </span>
                </div> */}

                <div>
                  <Collapse
                    defaultActiveKey={[]}
                    bordered={false}
                    expandIcon={({ isActive }) =>
                      isActive ? (
                        <span className="text-right text-primary">
                          Close
                          <i className="fa fa-chevron-down ml-1" />
                        </span>
                      ) : (
                        <span className="text-right text-primary">
                          Details
                          <i className="fa fa-chevron-right ml-1" />
                        </span>
                      )
                    }
                    expandIconPosition="right"
                    className="mb-2 site-collapse-custom-collapse bg-light pl-0 pr-0"
                    style={{
                      borderRadius: '10px',
                      borderBottom: '0px',
                    }}
                  >
                    <Panel
                      header={
                        <>
                          <Row>
                            <Col className="mr-3">
                              <div>
                                <div>
                                  <span className="text-orangeproduct font-size-14 font-weight-bold mt-0 mb-2">
                                    {this.state.product.product_category}
                                  </span>
                                </div>
                                <div className="">
                                  <span className="text-gray-700 font-size-22 font-weight-bold mt-0 mb-0">
                                    {this.state.product.product_name}
                                  </span>
                                </div>
                                <div>
                                  <span className="text-gray-500 font-size-14  mr-1">
                                    {this.state.product.product_type}
                                  </span>
                                  {this.state.product.hsn_code != '' && (
                                    <span className="text-purple font-size-14">
                                      {this.state.product.hsn_code}
                                    </span>
                                  )}
                                </div>
                                <div>
                                  <Card
                                    size="small"
                                    style={{ width: 270, borderRadius: '5px' }}
                                    className="mr-2 my-2 mr-4 border-primary"
                                  >
                                    <span>
                                      <span>
                                        <p className="mb-0">
                                          <span>
                                            {this.state.product.product_name}
                                            {this.state.product.show_online == 1 ? (
                                              <Badge
                                                status="processing"
                                                color="green"
                                                className="ml-1"
                                              />
                                            ) : (
                                              ''
                                            )}
                                          </span>
                                          <span
                                            className={`badge ${
                                              this.state.product.qty > 0
                                                ? 'badge-success'
                                                : 'badge-danger'
                                            } float-right text-black badge-collapsed-hidden ml-2`}
                                          >
                                            {' '}
                                            <span>Avl Qty</span>{' '}
                                            <span className="font-weight-bolder">
                                              {this.state.product.qty}
                                            </span>
                                          </span>
                                        </p>
                                        <h6 className="mb-0 mt-2">Selling Price</h6>
                                        <p className="mb-0">
                                          <span className="text-gray-10 font-size-14 mr-2">
                                            without Tax
                                          </span>
                                          <span className="font-weight-bold font-size-14 float-right">
                                            <span className="mr-1">
                                              {this.props?.user?.selectedCompany?.currency_symbol}
                                            </span>
                                            {this.state.product.unit_price}
                                          </span>
                                        </p>
                                        <p className="mb-0">
                                          <span className="text-gray-10 font-size-14 mr-2">
                                            with Tax
                                          </span>
                                          <span className="font-weight-bold font-size-14 float-right">
                                            <span className="mr-1">
                                              {this.props?.user?.selectedCompany?.currency_symbol}
                                            </span>
                                            {this.state.product.price_with_tax}
                                          </span>
                                        </p>
                                        <p className="mb-0">
                                          <span className="text-gray-10 font-size-14 mr-2">
                                            Purchase Price
                                          </span>
                                          <span className="font-weight-bold font-size-14 float-right">
                                            <span className="mr-1">
                                              {this.props?.user?.selectedCompany?.currency_symbol}
                                            </span>
                                            {this.state.product.purchase_price}
                                          </span>
                                        </p>
                                        {this.state.product.discount > 0 && (
                                          <p className="mb-0">
                                            <span className="text-gray-10 font-size-14 mr-2">
                                              Discount
                                            </span>
                                            <span className="font-weight-bold text-green font-size-14 float-right">
                                              {this.state.product.discount}%
                                            </span>
                                          </p>
                                        )}
                                        {this.state.product.barcode_id != '' && (
                                          <p className="mb-1 ml-1">
                                            <a
                                              onClick={() => {
                                                this.barcode.showModal(this.state.product)
                                              }}
                                            >
                                              <i className="fa fa-lg fa-barcode" />
                                            </a>
                                          </p>
                                        )}
                                      </span>
                                    </span>
                                  </Card>
                                </div>
                              </div>
                            </Col>
                          </Row>
                        </>
                      }
                      key="1"
                      className="font-weight-bold pl-0"
                    >
                      <div className="">
                        <div className="d-flex align-items-center">
                          {this.state.product.variants.length > 0 && (
                            <Col className="mt-1">
                              <div>
                                <h6 className="">Product Variants</h6>
                                <div>
                                  <Row className="d-flex">
                                    {this.state.product.variants.map((item, index) => (
                                      <>
                                        <Card
                                          size="small"
                                          style={{ width: 270, borderRadius: '5px' }}
                                          className="mr-2 my-2 mr-4 border-gray-100"
                                        >
                                          <span>
                                            <span>
                                              <p className="mb-0">
                                                <span>
                                                  {item.name}
                                                  {item.show_online == 1 ? (
                                                    <Badge
                                                      status="processing"
                                                      color="green"
                                                      className="ml-1"
                                                    />
                                                  ) : (
                                                    ''
                                                  )}
                                                </span>
                                                <span
                                                  className={`badge ${
                                                    item.qty > 0 ? 'badge-success' : 'badge-danger'
                                                  } float-right text-black badge-collapsed-hidden ml-2`}
                                                >
                                                  {' '}
                                                  <span>Avl Qty</span>{' '}
                                                  <span className="font-weight-bolder">
                                                    {item.qty}
                                                  </span>
                                                </span>
                                              </p>
                                              <h6 className="mb-0 mt-2">Selling Price</h6>
                                              <p className="mb-0">
                                                <span className="text-gray-10 font-size-14 mr-2">
                                                  without Tax
                                                </span>
                                                <span className="font-weight-bold font-size-14 float-right">
                                                  <span className="mr-1">
                                                    {
                                                      this.props?.user?.selectedCompany
                                                        ?.currency_symbol
                                                    }
                                                  </span>
                                                  {item.unit_price}
                                                </span>
                                              </p>
                                              <p className="mb-0">
                                                <span className="text-gray-10 font-size-14 mr-2">
                                                  with Tax
                                                </span>
                                                <span className="font-weight-bold font-size-14 float-right">
                                                  <span className="mr-1">
                                                    {
                                                      this.props?.user?.selectedCompany
                                                        ?.currency_symbol
                                                    }
                                                  </span>
                                                  {item.price_with_tax}
                                                </span>
                                              </p>
                                              <p className="mb-0">
                                                <span className="text-gray-10 font-size-14 mr-2">
                                                  Purchase Price
                                                </span>
                                                <span className="font-weight-bold font-size-14 float-right">
                                                  <span className="mr-1">
                                                    {
                                                      this.props?.user?.selectedCompany
                                                        ?.currency_symbol
                                                    }
                                                  </span>
                                                  {item.purchase_price}
                                                </span>
                                              </p>
                                              {item.discount > 0 && (
                                                <p className="mb-0">
                                                  <span className="text-gray-10 font-size-14 mr-2">
                                                    Discount
                                                  </span>
                                                  <span className="font-weight-bold text-green font-size-14 float-right">
                                                    {item.discount}%
                                                  </span>
                                                </p>
                                              )}
                                              {item.barcode_id != '' && (
                                                <p className="mb-1 ml-1">
                                                  <a
                                                    onClick={() => {
                                                      this.barcode.showModal(item)
                                                    }}
                                                  >
                                                    <i className="fa fa-lg fa-barcode" />
                                                  </a>
                                                </p>
                                              )}
                                            </span>
                                          </span>
                                        </Card>
                                      </>
                                    ))}
                                  </Row>
                                </div>
                              </div>
                            </Col>
                          )}
                        </div>
                        {this.state.product.product_images?.length > 0 && (
                          <div>
                            <Col className="mt-4 mb-5">
                              <h6 className="">Product Images</h6>
                              <div className="">
                                <Image.PreviewGroup>
                                  {this.state.product.product_images.map((item, index) => (
                                    <Image
                                      width={120}
                                      src={item.url}
                                      style={{
                                        marginRight: '10px',
                                      }}
                                      key={index}
                                    />
                                  ))}
                                </Image.PreviewGroup>
                              </div>
                            </Col>
                          </div>
                        )}
                        <div className="mt-3">
                          <ProductAnalysis
                            onRef={ref => (this.product_analysis = ref)}
                            product_id={this.state.product_id}
                          />
                        </div>
                      </div>
                    </Panel>
                  </Collapse>
                </div>
              </div>
              <ProductBarcode onRef={ref => (this.barcode = ref)} menu="barcode" />
            </div>
          )}
          <ProductForm
            onRef={ref => (this.form = ref)}
            onAPICalled={this.props.onAPICalled}
            dispatch={this.props.dispatch}
          />
        </Drawer>
      </>
    )
  }
}

export class EnhancedProductDetailsView extends React.Component {
  constructor(props) {
    super(props)
  }
  render() {
    return <ProductDetailsView {...this.props} />
  }
}

function mapStateToProps(state) {
  return {
    user: state.user,
  }
}

export default connect(mapStateToProps)(ProductDetailsView)
